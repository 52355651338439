import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SupportService } from './services/support.service';
import { AddServiceRequestComponent } from './view/add-service-request/add-service-request.component';
import { CustomerFeedbackComponent } from './view/customer-feedback/customer-feedback.component';
import { ListServiceRequestComponent } from './view/list-service-request/list-service-request.component';
import { ServiceRequestDetailsComponent } from './view/service-request-details/service-request-details.component';
import { SupportComponent } from './view/support/support.component';

const routes = [
  {
    path: 'support',
    component: SupportComponent,
    data: { animation: 'SupportComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'customerFeedback',
    component: CustomerFeedbackComponent,
    data: { animation: 'CustomerFeedbackComponent' },
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'listServiceRequest',
    component: ListServiceRequestComponent,
    data: { animation: 'ListServiceRequestComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'addServiceRequest',
    component: AddServiceRequestComponent,
    data: { animation: 'AddServiceRequestComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'ticketDetails',
    component: ServiceRequestDetailsComponent,
    data: { animation: 'ServiceRequestDetailsComponent' },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    SupportComponent,
    CustomerFeedbackComponent,
    ListServiceRequestComponent,
    AddServiceRequestComponent,
    ServiceRequestDetailsComponent,
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    NgSelectModule,
    PerfectScrollbarModule,
  ],
  exports: [
    SupportComponent,
    CustomerFeedbackComponent,
    ListServiceRequestComponent,
    AddServiceRequestComponent,
    ServiceRequestDetailsComponent,
    PerfectScrollbarModule,
  ],
  providers: [
    SupportService,
  ]
})
export class SupportRoutingModule { }
