<loader *ngIf="showLoader"></loader>
<loader *ngIf="showLoaderForCardDetails"></loader>

<div class="content-wrapper container-xxl p-0" [hidden]="showBuyCard">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <loader *ngIf="showLoader"></loader>
                <div class="row ">
                    <div class="col-md-3 col-xl-2 col-12 mt-10 mb-10 ">
                        <img src="assets/images/smartfx-portal-assets/buy-forex/card.png" style="width:80% ">
                    </div>

                    <div class="col-md-3 col-xs-12 col-5 mt-10 mb-10 ">
                        <div class="fs-11 text-black ">Card Type</div>
                        <div class="fs-12 text-black mt-5 fw-600 "> {{ cardDetails?.cardType }}</div>

                        <div class="fs-11 text-black mt-20 ">Card Status</div>
                        <div class="fs-12 text-black mt-5 fw-600  "> {{ cardDetails?.cardStatus }}</div>
                        <!-- <div class="fs-12 text-black mt-5 fw-600 " *ngIf="cardDetails?.cardLockFlag == 'L'"> Deactivated</div> -->
                    </div>

                    <div class="col-md-3 col-7 mt-10 mb-10 ">
                        <div class="fs-11 text-black ">Card Number</div>
                        <div class="fs-12  text-black mt-5 fw-600 ">{{ cardDetails?.cardNumber }}</div>

                        <div class="fs-11 text-black mt-20 ">Valid Till</div>
                        <div class="fs-12 text-black mt-5 fw-600 ">{{ cardDetails?.expiryDate }}</div>
                    </div>

                    <div class="col-md-3 col-12 mt-10 mb-10 text-right">
                        <div class="btn btn-primary btn-gradient link fs-11 w-100" (click)="goToStateMentPage()">Statement
                        </div>
                        <div class="btn btn-primary btn-gradient mt-10 fs-11 link w-100" (click)="goToManageCard()">Manage Card
                        </div>
                        <div *ngIf="!isCorporateCustomer" class="btn btn-primary btn-gradient mt-10 fs-11 link w-100" (click)="goToCashout()">Cashout
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card " >
            <div class="card-body ">
                <div class="row ">
                    <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill col-lg-5 col-md-6 col-sm-12 col-12 fs-12">
                        <li ngbNavItem>
                            <a ngbNavLink>Currency Wallets</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin ">
                                    <ng-container>
                                        <div class="row p-10 ">
                                            <div class="col-md-9">
                                                <div class="text-right "></div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="text-right">
                                                    <button *ngIf="inrWalletStatus == false && !isCorporateCustomer" type="button" rippleEffect class="btn btn-primary fs-9" style="padding:7px !important" (click)="openModalForActiveINRWallet(activeINRWalletModal)">
                                                        Activate INR Wallet
                                                    </button>

                                                    <button *ngIf="inrWalletStatus == true && !isCorporateCustomer" type="button" rippleEffect class="btn btn-primary fs-9" style="padding:7px !important" (click)="cashoutToINR()">
                                                        Cashout to INR Wallet
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-1">
                                                <div class="sky-blue-text text-right ">View All</div>
                                            </div> -->
                                        </div>
                                        <div class="row p-10 border-bottom-dashed " *ngFor="let singleCurrency of cardDetails?.cardWallet ">
                                            <div class="col-md-2 col-6 mt-10 ">
                                                <img src="assets/images/currencyFlag/{{ singleCurrency.currencyCode }}.png " style="height:15px ">
                                                <span class="fs-12 fw-600 text-black ml5 ">
                                                    {{ singleCurrency.currencyCode }}
                                                </span>
                                            </div>
                                            <div class="col-md-2 col-6 mt-10 ">
                                                <div class="fs-12 fw-600 text-black ">
                                                    {{ singleCurrency.currencyBalance }}
                                                </div>
                                            </div>
                                            <div *ngIf="singleCurrency.currencyCode != 'INR' && !isCorporateCustomer" class="col-md-2 col-6 mt-5 link" (click)="navigateAddBalanceToCard(singleCurrency)">
                                               
                                                <button   type="submit" class="btn btn-primary btn-next fs-10" rippleEffect>
                                                    <span class="align-middle">Add Funds</span>
                                                </button>
                                            </div>
                                            <div *ngIf="singleCurrency.currencyCode != 'INR' && singleCurrency.currencyBalance != 0 && !isCorporateCustomer" class="col-md-3 col-6 mt-5 link" (click)="gotoTansferBalance(singleCurrency)">
                                                <!-- <span><img src="/assets/images/icon/transfer-button.png" class="w-h-27">
                                                </span>
                                                <span class="text-black fs-10"> Transfer Balance </span> -->
                                                <button   type="submit" class="btn btn-primary btn-next fs-10 transferBal" title="Transfer funds to another Currency Wallet" rippleEffect>
                                                    <span class="align-middle">Transfer Funds</span>
                                                </button>
                                            </div>
                                        
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem *ngIf="!isCorporateCustomer">
                            <a ngbNavLink>Recent Transactions</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin ">
                                    <ng-container>
                                        <div class="row mt-20" *ngFor="let singleTransaction of recentTransactionList;let i = index">
                                            <div class="col-md-8 col-8 mb-10">
                                                <span class="card-title fs-12 text-black fw-600">Date :
                                                    {{singleTransaction?.time | date:'MMM dd yyyy' }} </span>
                                            </div>

                                            <div class="col-md-8 col-8">
                                                <span class="card-text fs-11 text-black">Details : </span>
                                                <span class="fs-11 text-black"> {{ singleTransaction?.beneficiaryName
                                                    }}</span>
                                            </div>

                                            <div class="col-md-8 col-8">
                                                <span class="card-text fs-11 text-black">Reference No : </span>
                                                <span class="fs-11 text-black"> {{singleTransaction?.txRef}}</span>
                                            </div>

                                            <div class="col-md-8 col-8">
                                                <span class="card-text fs-11 text-black">Amount : </span>
                                                <span class="fs-11 text-black"> {{singleTransaction?.amount}} </span>
                                            </div>

                                            <div class="col-md-8 col-8">
                                                <span class="card-text fs-11 text-black">Balance : </span>
                                                <span class="fs-11 text-black"> {{singleTransaction?.balance}}</span>
                                            </div>

                                            <div class="col-md-8 col-8">
                                                <span class="card-text fs-11 text-black">Type : </span>
                                                <span class="fs-11 text-black"> {{singleTransaction?.type}} </span>
                                            </div>

                                            <div class="col-md-12 mt-20" [ngClass]="(recentTransactionList.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>

                    </ul>
                    <div [ngbNavOutlet]="navFilled " class="col-md-12 fs-11 text-black20 ">
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="card" id="manageCard" *ngIf="showManageCardSection">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="fs-14 fw-600 mb-20 text-black">
                            <button type="button" rippleEffect class="btn btn-primary fs-9" style="padding:7px !important" (click)="showManageCardSection = false">
                            </button> &nbsp;&nbsp; Manage Card</div>
                    </div>
                    <div class="col-md-12 mb-30">
                        <div class="content-wrapper container-xxl p-0">
                            <section id="wishlist" class="grid-view wishlist-items-formanage-card">
                                <div class="ecommerce-card dashboard-card" *ngFor="let singleItem of manageCardListItems">
                                    <div class="custom-control custom-control-primary custom-switch mt-10 text-right">
                                        <input type="checkbox" style="color: black;" [checked]="singleItem.status" class="custom-control-input" id="{{singleItem.title}}" (change)="changeCardOptionStatus(singleItem)" [disabled]="disableCardFunctionality" />
                                        <label class=" custom-control-label " for={{singleItem.title}}></label>
                                    </div>
                                    <div style=" padding-top: 10px;" class=" mb-10">
                                        <div style=" padding-top:7px; ">
                                            <img src={{singleItem.icon}} style=" width: 30px;margin-left: 18px;display: block; ">
                                        </div>
                                    </div>

                                    <div class=" card-body mr-37" style=" padding:10px !important;">
                                        <div class=" fw-600 text-black fs-11 mb-10 ml-7 ">
                                            {{singleItem.title}}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                            <li class="nav-item" ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="payer-details" data-toggle="pill" aria-expanded="false" role="tab" (click)="navChanged('tab2')">
                                    <i data-feather="unlock" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold fs-11">Lock/Unlock Card Temporarily</span>
                                </a>

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-payer-details" role="tabpanel" aria-labelledby="payer-details" aria-expanded="false">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="unlock" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0">Lock/Unlock Card Temporarily</h5>
                                                <span></span>
                                            </div>
                                        </div>

                                        <div class="row  mt-20">
                                            <div class="col-md-12 mb-20">
                                                <section class=" card card-body">
                                                    <h5 class="content-header-title text-center mb-20 fs-12">
                                                        Lock your Card Temporarily
                                                    </h5>
                                                    <section class="row text-center">
                                                        <div class="col-md-12 text-black fs-12 mb-20">
                                                            Do you wish to lock your card temporarily?
                                                        </div>
                                                        <div class="col-md-12 text-black fs-12 mb-20">
                                                            Please note: this action will disable the usage of your WSFx Smart Currency Card temporarily.
                                                        </div>
                                                        <div class=" col-md-12 text-black fs-12 mb-20">
                                                            You can unlock your card anytime.
                                                        </div>
                                                        <div class="col-md-12 text-black fs-12">
                                                            <button *ngIf="cardLockFlag == 'UL'" type="button" rippleEffect class="btn btn-primary fs-12" (click)="temporaryBlockCard('BLOCK')">
                                                                Lock Card
                                                            </button>

                                                            <button *ngIf="cardLockFlag == 'L'" type="button" rippleEffect class="btn btn-primary fs-12" (click)="temporaryBlockCard('UNBLOCK')">
                                                                Unlock Card
                                                            </button>
                                                        </div>
                                                    </section>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="lock-card-perm" data-toggle="pill" aria-expanded="false" role="tab" (click)="navChanged('tab2')">
                                    <i data-feather="lock" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold fs-11">Lock Card Permanently</span>
                                </a>

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-lock-card-perm" role="tabpanel" aria-labelledby="lock-card-perm" aria-expanded="false" *ngIf="!disableCardFunctionality">

                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="lock" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0">Lock Card Permanently</h5>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div class="row mt-20">
                                            <div class="col-md-12 mb-20">
                                                <section class=" card card-body">
                                                    <h5 class="content-header-title text-center mb-20 fs-12">
                                                        Lock Card Permanently
                                                    </h5>
                                                    <section class="row text-center">
                                                        <div class="col-md-12 text-black fs-12 mb-10">
                                                            Do you wish to block your card permanently?
                                                        </div>
                                                        <div class="col-md-8 offset-md-2 text-black fs-12 mb-10">
                                                            In order to enable this action you will need to call us at 080-45651100 or e-mail us with your request at wsfxsmartcard@yesbank.in or customersupport@wsfx.in.
                                                        </div>
                                                        <div class="col-md-8 offset-md-2  text-black fs-12 mb-10">
                                                            Please note : this action will disable the usage of your WSFx Smart Currency Card permanently.
                                                        </div>
                                                    </section>
                                                </section>

                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="set-daily-limit" data-toggle="pill" aria-expanded="false" role="tab" (click)="navChanged('tab3')">
                                    <i data-feather="trending-up" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold fs-11">Set Daily Transaction Limit</span>
                                </a>

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon " id="faq-set-daily-limit" role="tabpanel" aria-labelledby="set-daily-limit" aria-expanded="false" *ngIf="!disableCardFunctionality">

                                        <div class="d-flex align-items-center mb-20">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="trending-up" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0">Set Daily Transaction Limit</h5>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 ">
                                                <set-daily-limit></set-daily-limit>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li class="nav-item" ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="set-pin" data-toggle="pill" aria-expanded="false" role="tab" (click)="navChanged('tab4')">
                                    <i data-feather="monitor" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold fs-11">Set Pin</span>
                                </a>

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-set-pin" role="tabpanel" aria-labelledby="set-pin" aria-expanded="false" *ngIf="!disableCardFunctionality">
                                        <div class="d-flex align-items-center mb-20">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="monitor" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0">Set a new PIN for your WSFx Smart Currency Card</h5>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <section class="  card card-body">
                                                    <div class="row mb-20">
                                                        <div class="col-md-12">
                                                            <h5 class="content-header-title float-left mb-0 fs-14">
                                                                Set a new PIN for your WSFx Smart Currency Card
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <label for="">New PIN (4 Digit)</label>
                                                            <div class="input-group input-group-merge form-password-toggle mb-2">
                                                                <input [type]="newPinShow ? 'text' : 'password'" class="form-control fs-11" name="pin" [(ngModel)]="setPinRequest.newPin" id="basic-default-password1" placeholder="New PIN (4 Digit)" maxlength="4" (keypress)="keyPressNumbers($event)" />
                                                                <div class="input-group-append" (click)="newPinShow = !newPinShow">
                                                                    <span class="input-group-text cursor-pointer" style="border: 1px solid #d8d6de !important; height: 38px !important; "><i
                                                                      class="fa"
                                                                      [ngClass]="{
                                                                        'fa-eye-slash': newPinShow,
                                                                        'fa-eye': !newPinShow
                                                                      }"
                                                                    ></i
                                                                  ></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12">
                                                            <label for="">Confirm new PIN</label>
                                                            <div class="input-group input-group-merge form-password-toggle mb-2">
                                                                <input [type]="confirmPinShow ? 'text' : 'password'" class="form-control fs-11" name="pin" [(ngModel)]="setPinRequest.newPinConfirm" placeholder="Confirm new PIN" maxlength="4" (keypress)="keyPressNumbers($event)" />
                                                                <div class="input-group-append" (click)="confirmPinShow = !confirmPinShow">
                                                                    <span class="input-group-text cursor-pointer" style="border: 1px solid #d8d6de !important; height: 38px !important; "><i
                                                                      class="fa"
                                                                      [ngClass]="{
                                                                        'fa-eye-slash': confirmPinShow,
                                                                        'fa-eye': !confirmPinShow
                                                                      }"
                                                                    ></i
                                                                  ></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 mb-20">
                                                            <label for="">Card Expiry Date(MMYY)</label>
                                                            <input type="text" (keypress)="keyPressNumbers($event)" class="form-control fs-11" maxlength="4"
                                                                [(ngModel)]="setPinRequest.expDate" name="card-expiry" placeholder="Card Expiry Date(MMYY)" />
                                                        </div>
                                                        <div class="col-md-12 mb-20">
                                                            <label for="">Date of Birth</label>
                                                            <div class="input-group">
                                                                <input class="form-control fs-11" placeholder="Select Date of Birth" (click)="startDatePicker.toggle()" name="Start Date Picker" [(ngModel)]="setPinRequest.dob" ngbDatepicker #startDatePicker="ngbDatepicker" readonly [maxDate]="setPINDobMaxDate" [minDate]="setPINMinDate">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="startDatePicker.toggle()" type="button" rippleEffect></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 mb-20">
                                                            <label for="">OTP</label>
                                                            <input type="text" class="form-control fs-11" name="otp" [(ngModel)]="setPinRequest.otp" placeholder="Enter OTP" maxlength="6" name="otp" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                            />
                                                            <div class="row">
                                                                <small class="col-md-6 form-text text-left green-text fs-8" *ngIf="otpReceived">
                                                                    An OTP is sent on {{ consumerData?.mobile }}
                                                                </small>
                                                                <small *ngIf="!otpReceived" [ngClass]="{'col-lg-6 col-md-6 col-sm-6':otpReceived, 'col-lg-12 col-md-12 col-sm-12':!otpReceived}" class=" col-md-6 form-text text-right blue-text fs-11 " style="cursor:pointer; " (click)="sendOTP() ">
                                                                    Send OTP
                                                                </small>
                                                                <small *ngIf="otpReceived" class=" col-md-6 form-text text-right blue-text fs-11 " style="cursor:pointer; " (click)="sendOTP() ">
                                                                    Resend OTP
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 text-right mb-20 ">
                                                            <button type="submit " rippleEffect class="btn btn-primary fs-12 " (click)="setPin() ">
                                                                Set PIN
                                                            </button>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item " ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="replace-card" data-toggle="pill" aria-expanded="false" role="tab " (click)="navChanged('tab5')">
                                    <i data-feather="credit-card" class="font-medium-3 mr-1 "></i>
                                    <span class="font-weight-bold fs-11 ">Replace Card</span>
                                </a>

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-replace-card" role="tabpanel" aria-labelledby="replace-card" aria-expanded="false " *ngIf="!disableCardFunctionality">
                                        <div class="d-flex align-items-center mb-20">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="credit-card" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0">Replace Card</h5>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <section class=" card card-body">
                                                    <div class="row mb-20">
                                                        <div class="col-md-12">
                                                            <h5 class="content-header-title mb-0 fs-14 ">
                                                                Replace Card
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <section class="row ">
                                                        <div class="col-md-12 fs-10">
                                                            <div class="card-title fs-12 text-black ">Please let us know the reason to replace your WSFx Smart Currency Card:
                                                            </div>
                                                            <div class="text-black mb-10">
                                                                <input type="radio" (change)="setReplacingReason('Card Expired')" name="radios" id="radio" />
                                                                <span class="card-title fs-12 text-black ml-1">
                                                                    Card Expired
                                                                </span>
                                                            </div>
                                                            <div class="text-black mb-10 ">
                                                                <input type="radio" (change)="setReplacingReason('Card Hot Listed')" name="radios" id="radio" />
                                                                <span class="card-title fs-12 text-black ml-1">
                                                                    Card Hot Listed
                                                                </span>
                                                            </div>
                                                            <div class="text-black mb-10 ">
                                                                <input type="radio" (change)="setReplacingReason('Card Damaged')" name="radios" id="radio" />
                                                                <span class="card-title fs-12 text-black ml-1">
                                                                    Card Damaged
                                                                </span>
                                                            </div>
                                                            <div class="text-black mb-10 ">
                                                                <input type="radio" (change)="setReplacingReason('Other')" name="radios" id="radio" [checked]="showCommentInput" />
                                                                <span class="card-title fs-12 text-black ml-1">
                                                                    Other
                                                                </span>
                                                            </div>
                                                            <div class="text-black mb-10 " *ngIf="showCommentInput">
                                                                <input type="text" class="form-control fs-11" name="comments" placeholder="Please provide more details" [(ngModel)]="replaceCardOthersComment" />
                                                            </div>
                                                            <button type="submit" rippleEffect class="btn btn-primary fs-12 mt-20 " (click)="replacingYourCard() ">Submit</button>
                                                        </div>
                                                    </section>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li class="nav-item " ngbNavItem>
                                <button type="button" rippleEffect class="btn btn-primary fs-9" style="    margin-left: 20px; margin-top: 20px;" (click)="scrollToTopFunction()">
                                    <i class="fa fa-arrow-left" ></i>  Back
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div [ngbNavOutlet]="navVertical " class="col-12 col-md-8 "></div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="card mt-30" [hidden]="!showBuyCard">
    <div class="card-body">
        <div class="row">
            <div class="col-md-7">
                <div class="fw-600 fs-18">Buy Forex</div>
                <div class="fs-12">Here are some recommended actions for you </div>

                <div class="col-md-9 mt-20">
                    <section>
                        <div class="row card-border" (click)="showBuyForex()" [ngClass]="activeBuyForexCard ? 'blue-card-selected' : 'dash-card'">
                            <div class="col-md-4">
                                <img src="assets/images/smartfx-portal-assets/buy-forex/buy-new-card.png" class="w-100">
                            </div>
                            <div class="col-md-8 mt-20">
                                <div class="fs-18 fw-600">Buy New Card</div>
                                <div class="fs-11 mt-5">Buy the WSFx Smart Currency Card and leave your money management worries at home.</div>
                            </div>
                        </div>
                    </section>
                    <section class="mt-20">
                        <div class="row  card-border dash-card" routerLink="/link-existing-card">
                            <div class="col-md-4">
                                <img src="assets/images/smartfx-portal-assets/buy-forex/link-existing-card.png" class="w-100">
                            </div>
                            <div class="col-md-8 mt-20">
                                <div class="fs-18 fw-600">Link Existing Card</div>
                                <div class="fs-11 mt-5">Link your WSFx Smart Currency Card and manage it from anywhere in the world.</div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-md-1 dashed-border"></div>
            <div class="col-md-4">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills" style="margin-bottom: 0px !important; ">
                    <li class="nav-item w-100" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#buyForex" data-toggle="tab">Buy Forex</a>
                        <ng-template ngbNavContent>
                            <section id="buyForex">
                                <div class="row">
                                    <div class="col-md-12">
                                        <select placeholder="Select" style="height: 36.54px;" class="form-control card-shadow fs-12" readonly>
                                        <option selected> Forex Card </option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <div class="row">
                                            <div class="col-md-12 col-12">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="currencySelected" change='getCurrencyFields()' [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <input type="number" class="form-control" placeholder="Amount" [(ngModel)]="currencyAmount" name="currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187 && event.keyCode !== 190"
                                                        (keyup)="callQuotation()" />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 ">
                                                <div class="input-group  mt-20">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag"  style="height:15px;width: 15px;">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" value="{{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}" readonly />
                                                </div>
                                                <small class="form-text blue-text fs-9 text-right fw-600" *ngIf="showPricing && quotationResponse?.quotationCurrencyResponses[0].rateString !=null"> 
                                                    1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}}

                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-10">
                                        <span class="fs-18 fw-600">You Pay -  <span class="bold-amount"> ₹ {{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}
                                        </span></span>

                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-12 mt-20">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-10 w-100" (click)="proceedToBuySelectedCard()">
                                            Book Now
                                        </button>
                                    </div>

                                </div>
                            </section>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="card p-20"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #activeINRWalletModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black" id="myModalLabel1">
            Activate INR Wallet
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-black fw-500 mt-10 mb-10 fs-12">
            <label class="form-label fs-9">Enter OTP</label>
            <input type="text" class="form-control fs-12" name="enterOTP" placeholder="Enter OTP" [(ngModel)]="walletOTP" />
            <small class="form-text text-right  blue-text link fs-9" (click)="getOTPForINRWallet()">
                Resend OTP
            </small>
        </div>
        <div class="mt-20">
            <div class="fs-8 note-bg">
                <span class="text-black"> Kindly check OTP sent to your mobile number linked with WSFx Multi Currency
                    Card</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-10 text-center blue-text btn btn-primary" style="cursor: pointer;" (click)="validateOTP()">
            Validate OTP
        </div>
    </div>
</ng-template>

<ng-template #disableCardModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">
            {{disableCardModalMsg}}
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 fw-500 fs-10 text-center blue-text btn btn-primary" (click)="modal.dismiss('Cross click')" style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>
<ng-template #disableINRWallet let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center">
            The INR wallet facility is temporarily unavailable. 
        </div>
        <div class="text-center">
             We regret the inconvenience.
        </div>
        <div class="text-center">
            For any further queries, you can contact us at <a class="card-subtitle text-black mb-1" href="tel:8976707222" style="color : #0062ff">+91-8976707222 </a> or write to us at <a class="card-subtitle text-black  mb-1" href="mailto:customersupport@wsfx.in"
                style="color : #0062ff">customersupport@wsfx.in</a>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-2 fw-500 fs-10 text-center blue-text btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>