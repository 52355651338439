import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { SetCardLimitModel } from "app/modules/card/models/card.model";
import { MasterDataService } from "app/shared/services/master-data.service";
import { ToastrService } from "ngx-toastr";
import { CardService } from "../../../services/card.service";
import { ReqGetCurrencyMaster } from "app/shared/models/shared-models";
import { Constants } from "app/shared/constant/constants";
import { CryptoUtils } from "app/shared/utility/crypto.utils";


@Component({
  selector: 'set-daily-limit',
  templateUrl: './set-daily-limit.component.html',
  styleUrls: [
    '../../../../../../@core/scss/angular/libs/noui-slider.component.scss',
    // '../../../../../../assets/scss/select.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SetDailyLimitComponent implements OnInit {

  public atmLimit: number = 300;
  public ecomLimit: number = 430;
  public posLimit: number = 660;
  public currencyItems:any = [];
  public currencyCode: any;

  ///SET DAILY LIMIT
  public settingList = [];
  public currencySelected: any;
  public currencyCountryList: any;
  public cardLimitList = [];
  public cryptoUtils: CryptoUtils = new CryptoUtils();


  public setCardLimitModel = new SetCardLimitModel();
  public cardDetailsObject = [];

  public isOther = false;
  public dailyAtmLimitValue: string;
  public dailyPosLimitValue: string;
  public dailyEcomLimitValue: string;
  public mySelections: any;

  public valueAtm: any;
  public valueEcom: any;
  public valuePos: any;
  public loading = false;

  public tempDailyATMLimit :any = 100;
  public tempDailyPOSLimit :any = 100;
  public tempDailyEcomLImit : any = 100;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();

  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private _router: Router,
    public masterDataService: MasterDataService,
  ) {
    this.currencyCode = 'USD';
  }

  ngOnInit() {
    this.setCardLimitModel.dailyAtmLimitValue = "0";
    this.setCardLimitModel.dailyPosLimitValue = "0";
    this.setCardLimitModel.dailyEcomLimitValue = "0";
    this.getCurrencies();
    this.getCurrencyRate();
    this.getCardLimit();
  }

  getCurrencyRate() {
    this.loading = true;
    this.cardService.getCurrentRate().subscribe((respRate) => {
      var currentResult = respRate.body;
      this.loading = false;
      this.currencyCountryList = currentResult[0]['rates'];
      for (var i = 0; i < this.currencyCountryList.length; i++) {
        if (this.currencyCountryList[i]['currencyCode'] == 'USD') {
          this.cardDetailsObject[0] = this.currencyCountryList[i];
        }
      }

      this.currencySelected = this.currencyCountryList[0];
      console.log("this.currencySelected >>" + JSON.stringify(this.currencySelected));
    });
  }

  getCardLimit() {
    this.loading = true;
    this.cardService.getCardLimit(this.currencyCode).subscribe((respLimit) => {
      this.loading = false;

      /* this.tempDailyATMLimit = this.setCardLimitModel.dailyAtmLimitValue;
      this.tempDailyPOSLimit = this.setCardLimitModel.dailyPosLimitValue;
      this.tempDailyEcomLImit = this.setCardLimitModel.dailyEcomLimitValue; */
    }, error => {
            this.loading = false;

      if (error.status == 200) { 
            this.cardLimitList = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
             console.log(this.cardLimitList[0]['upperLimitAtm']);
              this.setCardLimitModel.dailyAtmLimitValue = this.cardLimitList[0]['actualLimitAtm'];
              this.setCardLimitModel.dailyPosLimitValue = this.cardLimitList[0]['actualLimitPos'];
              this.setCardLimitModel.dailyEcomLimitValue = this.cardLimitList[0]['actualLimitEcom'];

      
                this.tempDailyATMLimit = this.cardLimitList[0]['upperLimitAtm'];
                this.tempDailyPOSLimit = this.cardLimitList[0]['upperLimitPos'];
                this.tempDailyEcomLImit = this.cardLimitList[0]['upperLimitEcom'];

                console.log("this.setCardLimitModel >>", this.setCardLimitModel);


      } else {
        let errObj = error.error.message;
        this.toast.error(errObj.shortMessage);
      }

      
    });
  }

  setCardLimit() {
    this.loading = true;
    this.setCardLimitModel.currencyCode = this.currencyCode; //this.currencySelected.currencyCode;
    this.cardService.setCardLimit(this.setCardLimitModel).subscribe(data => {
      this.loading = false;
      // this._router.navigate(['/manageCard']);
    }, err => {
            this.loading = false;
      if (err.status == 200) {
        this.toast.success('Card daily limit has been set successfully.');

      } else {
        this.toast.error(err.error.message);
      }
      
    });
  }

  getCurrencies() {
    this.loading = true;
    this.reqGetCurrencyMaster.cartType = "CARD_PURCHASE",
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[0];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      console.log(result.body);
      this.currencyItems = result.body;
    });
  }

  onCurrencyChange(evt) {
    this.getCardLimit();
  }

}
