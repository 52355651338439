import { RequestQuotationModel } from "app/modules/dashboard/models/quotation.models";
import { SinglePurposeItem } from "app/shared/models/shared-models";
import { OfacFatfModel } from "./cart.model";

export class StepperDataModel {
    orderDetails: any;
    purposeItem: SinglePurposeItem;
    quotationRequest: RequestQuotationModel;
    quotationResponse: any;
    cartItemsResponse: any;
    step01Data: Step01Data;
    step02Data: Step02Data;
    step03Data: Step03Data;
    ofacData: OfacData = new OfacData();
}


export class OfacData {
    isOfacRequired: boolean;
    addOfacRequestModel: OfacFatfModel;
}

export class Step01Data {
    beneficiary: any;
    beneficairyStatus: any;
}

export class Step02Data {
    selfResPaxItemSingle: any;
    sourceofFunds: any;
    tcsPaymentSourceDetails: any;
    paymentSourceDetails: any;
    tcsPaymentSource: any;
    loanCOnfirmationCheckBox: any;
    loanDocumentUploaded: any;
}

export class Step03Data {
    checkboxChecked: any;
    otpValue: any;
    signatureUpload: boolean;
}

///step01Data = {"beneficairy":benficairy cha obj,}