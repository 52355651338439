import { Component, OnInit} from '@angular/core'

@Component({
  selector: 'isic-card',
  templateUrl: './isic-card.component.html',
  // styleUrls:['../../../../../@core/scss/base/pages/app-ecommerce-details.scss']
})
export class IsicCardComponent implements OnInit {
 
  public showMoreContents : boolean = false;
  public buttonText : string = 'View More';
  constructor() {}
  

  ngOnInit() {
  }

  // showContents(){
  //   this.showMoreContents = true;
  //   this.buttonText = 'View Less';
  // }

  // hideContents(){
  //   this.showMoreContents = false;
  //   this.buttonText = 'View More';
  // }

}
