<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <loader *ngIf="showLoader"></loader>
                <form class="form-horizontal" (ngSubmit)="applyForISIC(ApplyForISICForm.form.valid)" #ApplyForISICForm="ngForm">

                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Name*</label>
                            <input type="text" name="name" class="form-control fs-12" placeholder="Name*" [(ngModel)]="isicRegisterModel.fullName" #nameRef="ngModel" [class.error]="ApplyForISICForm.submitted && nameRef.invalid" required (keypress)="letterOnly($event)">
                            <span *ngIf="ApplyForISICForm.submitted && nameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="nameRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Gender*</label>
                            <ng-select class="fs-12" [clearable]="false" name="genderListItems" [items]="genderListItems" bindLabel="genderListItems" bindValue="genderListItems" [(ngModel)]="isicRegisterModel.gender" #genderRef="ngModel" [class.error]="ApplyForISICForm.submitted && genderRef.invalid"
                                [searchable]="false" placeholder="Select Gender" required>
                            </ng-select>
                            <span *ngIf="ApplyForISICForm.submitted && genderRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="genderRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Address*</label>
                            <input type="text" name="address" class="form-control fs-12" placeholder="Enter Address*" [(ngModel)]="isicRegisterModel.address" #addressRef="ngModel" [class.error]="ApplyForISICForm.submitted && addressRef.invalid" required>
                            <span *ngIf="ApplyForISICForm.submitted && addressRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="addressRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Email ID*</label>
                            <input type="text" name="email" class="form-control fs-12" placeholder="Enter Email ID*" [(ngModel)]="isicRegisterModel.email" #emailRef="ngModel" [class.error]="ApplyForISICForm.submitted && emailRef.invalid" required>
                            <span *ngIf="ApplyForISICForm.submitted && emailRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="emailRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Mobile No*</label>
                            <input type="text" name="mobile_no" class="form-control fs-12" placeholder="Enter Mobile No*" [(ngModel)]="isicRegisterModel.mobile" #mobile_noRef="ngModel" [class.error]="ApplyForISICForm.submitted && mobile_noRef.invalid" required (keypress)="numberOnly($event)"
                                maxlength="10" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                            <span *ngIf="ApplyForISICForm.submitted && mobile_noRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="mobile_noRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Nationality*</label>
                            <input type="text" name="nationality" class="form-control fs-12" placeholder="Enter Nationality*" [(ngModel)]="isicRegisterModel.nationality" #nationalityRef="ngModel" [class.error]="ApplyForISICForm.submitted && nationalityRef.invalid" required>
                            <span *ngIf="ApplyForISICForm.submitted && nationalityRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="nationalityRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Purpose of Visiting*</label>
                            <input type="text" name="purpose_of_visiting" class="form-control fs-12" placeholder="Enter Purpose of Visiting*" [(ngModel)]="isicRegisterModel.purposeOfVisit" #purposeOfVisitRef="ngModel" [class.error]="ApplyForISICForm.submitted && purposeOfVisitRef.invalid"
                                required (keypress)="letterOnly($event)">
                            <span *ngIf="ApplyForISICForm.submitted && purposeOfVisitRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="purposeOfVisitRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Visiting Country*</label>
                            <ng-select name="countryListItems" class="fs-12" [clearable]="false" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="isicRegisterModel.visitingCountry" #visitingCountryRef="ngModel" [class.error]="ApplyForISICForm.submitted && visitingCountryRef.invalid"
                                [searchable]="false" placeholder="Select Country" required>
                            </ng-select>
                            <span *ngIf="ApplyForISICForm.submitted && visitingCountryRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="visitingCountryRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">Passport Number*</label>
                            <input type="text" name="passport_number" class="form-control fs-12" placeholder="Enter Passport Number*" [(ngModel)]="isicRegisterModel.passportNumber" #passportNoRef="ngModel" [class.error]="ApplyForISICForm.submitted && passportNoRef.invalid" (keypress)="restrictSepcialCharacters($event)"
                                maxlength="8" required>
                            <span *ngIf="ApplyForISICForm.submitted && passportNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="passportNoRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">PAN Number*</label>
                            <input type="text" name="pan_number" class="form-control fs-12" placeholder="Enter PAN Number*" [(ngModel)]="isicRegisterModel.pan" #panRef="ngModel" [class.error]="ApplyForISICForm.submitted && panRef.invalid " (keypress)="restrictSepcialCharacters($event)"
                                maxlength="10" required>
                            <span *ngIf="ApplyForISICForm.submitted && panRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="panRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">DOB*</label>
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="Select Date of Birth" name="Basic Date Picker" [(ngModel)]="dateofBirth" required ngbDatepicker #dob="ngModel" #basicDP="ngbDatepicker" placeholder="Select Date of Birth" [maxDate]="maxDate" [minDate]="minDate"
                                    [class.error]="ApplyForISICForm.submitted && dob.invalid">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                            <span *ngIf="ApplyForISICForm.submitted && dob.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="dob.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">WSFx Invoice No*</label>
                            <input type="text" name="wsfx_invoice_no" class="form-control fs-12" placeholder="Enter WSFx Invoice No*" [(ngModel)]="isicRegisterModel.wsfxInvoiceNumber" #wsfxInvoiceRef="ngModel" [class.error]="ApplyForISICForm.submitted && wsfxInvoiceRef.invalid"
                                required>
                            <span *ngIf="ApplyForISICForm.submitted && wsfxInvoiceRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="wsfxInvoiceRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label text-black fs-11">School/ University Name*</label>
                            <input type="text" name="school_name" class="form-control fs-12" placeholder="Enter School/ University Name*" [(ngModel)]="isicRegisterModel.schoolUniversityName" #schoolNameRef="ngModel" [class.error]="ApplyForISICForm.submitted && schoolNameRef.invalid"
                                required (keypress)="letterOnly($event)">
                            <span *ngIf="ApplyForISICForm.submitted && schoolNameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="schoolNameRef.errors.required">This field is required!</small>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-20">
                        <div class="col-md-3 col-3">
                            <button type="button" rippleEffect class="btn btn-outline-primary fs-12" routerLink="/isicCard">Back</button>
                        </div>
                        <div class="col-md-9 col-9 text-right">
                            <button type="submit" rippleEffect class="btn btn-primary  fs-12">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>