import { Component, OnInit, ViewEncapsulation } from '@angular/core' 
import { Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'zolve-credit-card',
  templateUrl: './zolve-credit-card.component.html',
  styleUrls: ['./zolve-credit-card.component.scss'],
  providers:[],
  encapsulation: ViewEncapsulation.None
})
export class ZolveCreditCardComponent implements OnInit {
  public showLoader: boolean = false;
  constructor(
     public masterDataService : MasterDataService,
     public router : Router
  ) {}

  ngOnInit() { 
  }

  openAccountForm(title){
    this.router.navigate(['/open-account-form',title]);
  }
 
}
