import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "../constant/constants";
import { BaseProvider } from "../helper/http/BaseProvider";
import { ReqGetCurrencyMaster } from "../models/shared-models";

@Injectable()
export class MasterDataService extends BaseProvider {

    public assetUrl = 'assets/data';
    public configExtension = '.json'

    constructor(public http: HttpClient) {
        super(http);
    }

    geConfigJSON() {
        var jsonUrl = this.assetUrl + "/wsfx_config" + this.configExtension;
        // var jsonUrl = Constants.CONFIG_s3_BASE_URL + "/wsfx_config.json";
        return this.http.get(jsonUrl);
    }

    getCountryList() {
        var resourceUrl = "countries";
        return this.makeGetCall(resourceUrl);
    }

    getCurrenciesList(reqGetCurrencyMaster: ReqGetCurrencyMaster) {
        var resourceUrl = "currencies";
        return this.makePostCall(resourceUrl, reqGetCurrencyMaster);
    }

    getRateRange(product, currencyCode, sellType) {
        var resourceUrl = "pricing/rateRange/?product=" + product + '&currencyCode=' + currencyCode + "&partnerId=" + Constants.partnerId + '&sellType=' +
            sellType;
        return this.makeGetCall(resourceUrl);
    }

    getCurrentRateListRemittance() {
        var resourceUrl = "rates/current?partnerId=p_wsfxConsumer&products=REMITTANCE";
        return this.makeGetCall(resourceUrl);
    }

    getCurrentRateListCard() {
        var resourceUrl = "rates/current?partnerId=p_wsfxConsumer&products=FOREXCARD&isMultiCurrencyCard=true";
        return this.makeGetCall(resourceUrl);
    }

    getCurrentRateSingleCurrencyCard(currencyCode: string) {
        var resourceUrl = "pricing/rateRange?partnerId=p_wsfxConsumer&product=FOREXCARD&sellType=SELL&currencyCode=" + currencyCode;
        return this.makeGetCall(resourceUrl);
    }

    getUniversityList(cartType, countryCode) {
        var resourceUrl = "beneficiaryMaster?cartType=" + cartType + "&countryCode=" + countryCode;
        return this.makeGetCall(resourceUrl);
    }

    rateCalculatorConvertor(currencyAmount, currencyCode, sellType,product) {
        var resourceUrl = "rateCalculator?requiredAmount=" +currencyAmount+ "&requiredCurrency=" +currencyCode+ "&sellType="+ sellType +"&product="+ product;
        return this.makeGetCall(resourceUrl);
    }


    rateCalculator(product, requiredCurrency, requiredAmount) {
        var resourceUrl = "wsfx/rateCalculator?product=" + product + '&requiredCurrency=' + requiredCurrency + "&requiredAmount=" + requiredAmount + '&sellType=SELL';
        return this.makeGetCallGrid(resourceUrl);
        // https://apiqa.wsfx.in/wsfx/rateCalculator?product=FOREXCARD&requiredAmount=1200&requiredCurrency=AUD&sellType=SELL
    }

}