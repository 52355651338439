import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { AddAddressModel } from 'app/modules/address/models/address.model';
import { AddressService } from 'app/modules/address/services/address.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-address-card',
  templateUrl: './add-address-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AddAddressCardComponent implements OnInit {

  public addAddressModel = new AddAddressModel();
  public showLoader : boolean = false;
  public addressTypeList = Constants.locationTypeList;
  public cityItemList = Constants.addressCityList;
  public consumerId : any;
  public editAddressDetails;
  @Output() childCallbackForAddAddressForm = new EventEmitter<string>();

  constructor(
    public toast: ToastrService,
    public router  : Router,
    public addressService : AddressService,
    public route: ActivatedRoute,
    ) { 
     this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    }

  ngOnInit() {
  }

  addAddress(data){
    if(data == true){
      this.showLoader = true;
      this.addAddressModel.consumerId = this.consumerId;
      this.addAddressModel.default = true;
      this.addAddressModel.isDefault = true;
      let localAddAddressRequests: Array<AddAddressModel> = [];
      localAddAddressRequests.push(this.addAddressModel);
      this.addressService.createConsumerAddress(localAddAddressRequests).subscribe((resp => {
        this.showLoader = false;
        this.toast.success("Address added successfully !");
        this.childCallbackForAddAddressForm.emit('ADDRESS_ADDED');
      }),error=>{
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }    
  }
   

  goToBackStep(){
    this.childCallbackForAddAddressForm.emit('ADDRESS_ADDED');
  }
}
