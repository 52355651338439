import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BaseProvider } from 'app/shared/helper/http/BaseProvider';
import { CartPrepareRequest } from '../models/dashboard.model';

@Injectable()
export class DashboardService extends BaseProvider {
  
  constructor(public http: HttpClient) {
    super(http);
  }
 
}
