import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { RequestQuotationModel, QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { SinglePurposeItem, SingleRateResItem } from 'app/shared/models/shared-models';
import { StepperDataModel } from '../../models/stepperdata.model';
import { RemittanceService } from '../../services/remittance.service';
import { QuotationCurrencyResponsesModel } from 'app/modules/card/models/card.model';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['../../stepper.scss','./stepper-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperFooterComponent implements OnInit {

  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public quotationResponse: any;
  public selectedPurpose: SinglePurposeItem;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public selectedPurposeItem: any;
  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();


  @Input() refreshQuotationCountFooter:number = 0; 

  constructor(
    private router: Router,
    private remittanceService: RemittanceService,
  ) {
    //this.timer();
  }

  ngOnInit(): void {
    this.getSessionData();
    // console.log("Footer in ngOnInit");
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      // console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }
    this.getSessionData();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.requestQuotationModel = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
      this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];

    } else {
      this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        });
    }
  }

  callQuotation() {
    this.remittanceService.getQuotationEncrypted(this.requestQuotationModel).subscribe(resp => {
      this.stepperDataModel.purposeItem = this.selectedPurposeItem;
      this.stepperDataModel.quotationRequest = this.requestQuotationModel;
      this.stepperDataModel.quotationResponse = resp.body;
      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
      this.getSessionData();
    }, error => {
        if (error.status == 200) { 
                 this.stepperDataModel.purposeItem = this.selectedPurposeItem;
                    this.stepperDataModel.quotationRequest = this.requestQuotationModel;
                    this.stepperDataModel.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
                    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
                    this.getSessionData();
          }
      //  this.showLoader = false;
      //  this.toast.error(JSON.stringify(error.error.message));
    });
  }

  // getQuotationRequestBody() {
  //   let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];

  //   /// prepare quotation request
  //   this.quotationCurrencyItem.product = Constants.productsEnum[2];
  //   this.quotationCurrencyItem.bidRate = this.singleRateResItem.minimumRateInINR;
  //   this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
  //     ? this.selectedPurposeItem.purposeCode
  //     : "UNIVERSITY_FEES";
  //   listQuotationCurrencies.push(this.quotationCurrencyItem);

  //   this.requestQuotationModel.sellType = Constants.sellTypes[0];
  //   this.requestQuotationModel.partnerId = Constants.partnerId;
  //   this.requestQuotationModel.nostroChargeType = 'REMITTER';
  //   this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  // }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
        // this.callQuotation();
      }
    }, 1000);
  }
}
