import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { PasswordResetPatchRequest, PasswordResetRequest } from "../models/smartFxLandingModels";

@Injectable()
export class SmartFxLandingService extends BaseProvider {
  resource = "rates";

  constructor(public http: HttpClient) {
    super(http);
  }

  
  getCurrentRate() {
    var resourceUrl = this.resource + "/current?partnerId=" + 'p_wsfxConsumer' + '&products=FOREXCARD' + '&isMultiCurrencyCard=true';
    return this.makeGetCall(resourceUrl);
  }
  
  getProductCurrentRate(product) {
    var resourceUrl = this.resource +
        "/current?partnerId=" +
        'p_wsfxConsumer' +
        '&products=' + product +
        '&isMultiCurrencyCard=true'; 
    return this.makeGetCall(resourceUrl);
  }

  getCaptcha() {
    return this.makeGetCall('captcha');
  }

  login(loginRequest) {
    var resourceUrl =  "consumers/login";
    return this.makePostCall(resourceUrl, loginRequest);
  }

  otpForLogin(mobileNo) {
    var resourceUrl = "otpForMobileLogin?mobileNo=" + mobileNo;
    return this.makeGetCall(resourceUrl);
  }

  otpSignUp(signUpRequestModel) {
    var resourceUrl = "otpSignup";
    return this.makePostCall(resourceUrl, signUpRequestModel)
  }

  
  getMobileOTPLoginForSignup(mobileNo) {
    var resourceUrl = 'otpForMobileSignup?mobileNo=' + mobileNo;
    return this.makeGetCall(resourceUrl);
}

passwordResetPatch(passwordResetPatchModel: PasswordResetPatchRequest) {
  var resourceUrl = "consumers/passwordThroughOtp";
  return this.makePostCall(
      resourceUrl,
      passwordResetPatchModel
  );
}

passwordReset(passwordResetModel: PasswordResetRequest) {
  var resourceUrl = "consumers/forgotPassword";
  return this.makePostCall(resourceUrl, passwordResetModel);
}

}
 