<div class="content-wrapper container-xxl p-0 row">
    <div class="content-body col-md-6">
        <div class="card">
            <div class="card-body">
                <loader *ngIf="showLoader"></loader>
                <div class="row">
                    <table class="table table-borderless fs-11">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center bg-white fs-12 text-black fw-600"> Ticket Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ticket ID</td>
                                <td>:</td>
                                <td>{{ticketDetails.id}}</td>
                            </tr>
                            <tr>
                                <td>Subject</td>
                                <td>:</td>
                                <td>{{ticketDetails.subject}}</td>
                            </tr>
                            <tr>
                                <td>Comment</td>
                                <td>:</td>
                                <td>{{ticketDetails.comment}}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>:</td>
                                <td>{{ticketDetails.status}}</td>
                            </tr>
                            <tr>
                                <td>Updated Date</td>
                                <td>:</td>
                                <td>{{ticketDetails.updatedAt | date:'MMM dd yyyy , HH:mm '}}</td>
                            </tr>
                            <tr>
                                <td>Created Date</td>
                                <td>:</td>
                                <td>{{ticketDetails.createdAt | date:'MMM dd yyyy , HH:mm '}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class=" content-body  col-md-6">
        <section class="chat-app-window">
            <div class="active-chat">
                <div class="user-chats" [perfectScrollbar] [scrollTop]="scrolltop">
                    <div class="chats" *ngFor="let singleMessage of ticketDetail">
                        <div class="chat" [ngClass]="{ 'chat-left': singleMessage.requesterType === 'USER' }">
                            <div class="chat-avatar">
                                <span class="avatar box-shadow-1 cursor-pointer">
                                    <img *ngIf="singleMessage.requesterType === 'CONSUMER'" src="assets/images/avatars/profile.png"  height="36" width="36" />
                                    <img *ngIf="singleMessage.requesterType === 'USER'" src="assets/images/logo/logo.png"  height="36" width="36" />
                                </span>
                            </div>
                            <div class="chat-body">
                                <div class="chat-content">
                                    <p>{{singleMessage.comment}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="chat-app-form" action="javascript:void(0);">
                    <div class="input-group input-group-merge mr-1 form-send-message">

                        <input type="text" class="form-control message" placeholder="Type your message" [(ngModel)]="chatMessage" name="chat-message" />
                        <div class="input-group-append">
                            <span class="input-group-text"></span>
                            <label for="attach-doc" class="attachment-icon mb-0"></label>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary send" rippleEffect (click)="sendMessage()">
                        <i data-feather="send" class="d-lg-none"></i>
                        <span class="d-none d-lg-block" >Send</span>
                            </button>
                </form>
            </div>
        </section>
    </div>
</div>