export class RequestOrderFeedbackModel {
    orderId: string;
    onboarding: string;
    transaction: string;
    transparency: string;
    support: string;
    overall: string;
    comment: string;
}

export class UpdatePaymentDetailsRequestModel {
    consumerId: string;
    orderId: string;
    referenceNumber: string;
    manualPaymentType: string;
    amount: string;
    paymentDate: string;
}

export class IncompleteDisplayOrderModel {
    ioId: string;
    product: string;
    currencyCode: string;
    currencyAmount: string;
    createdAt: string;
    consumerId: string;
    beneficiaryName: string;
}

export class SsOrderDataModel {
    beneficiaryId: string;
} 