import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { Step04DataPayment, StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {

  public modalReference: any;
  public paymentMode: any;
  public paymentInstruction: any;
  public bankAccountId: any;
  public bankAccountList: any;

  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;

  ///SESSION DATA
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public cartType: string;
  public step04DataPayment: Step04DataPayment = new Step04DataPayment();

  public showLoader: boolean = false;
  public showAddBankForm: boolean = false;
  public ALLOW_NEFT_PAYMENT = Constants.ALLOW_NEFT_PAYMENT;
  public selectedPaymentType : any;
  @Output() childCallbackSender = new EventEmitter<string>();

  public redeemableCredits: any;
  public listOffers: any;


  constructor(
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    private cardService: CardService,
    public router: Router,
    public toast: ToastrService,

  ) { }

  ngOnInit() {
    this.getSessionData();
    this.getBankAccountList();
    this.getRedeemableCredits();
    this.getOffers();

  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
      this.cartType = this.stepperDataCardModel.cartType;
    }
  }

  openModalForPaymentInfo(paymentInstructionModal) {
    this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
    if (this.paymentMode == PaymentMode.NET_BANKING) {
      this.paymentInstruction = "Please note, you are requested to upload a proof of your payment in the Order Details section of My Orders, if you do choose to proceed with this mode of payment.\n";
    } else {
      this.paymentInstruction = "Please note, if a payment is not received within 4 hours, your order will stand cancelled. Please do contact customer care at +91 89767 07222 for any queries.\n";
    }
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
    this.step04DataPayment.bankAccountId = this.bankAccountId;
    this.updateSessionData();
  }

  getSelectedPaymentMode(paymentMode) {
    this.stepperDataCardModel.step04Data.paymentMode = paymentMode;
    this.updateSessionData();
  }

  getSelectedPaymentType(paymentType){
    this.selectedPaymentType = paymentType;
    this.updateSessionData();
  }

  updateSessionData() {
    this.getSessionData();
    this.stepperDataCardModel.step04Data = this.step04DataPayment;
    this.stepperDataCardModel.isPartial = this.selectedPaymentType;
    console.log("partial flag>>> ",this.stepperDataCardModel);
    
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    console.log("STEP 4 DATA >>>>>> ",this.stepperDataCardModel.step04Data);
    
  }

  getBankAccountList() {
    this.cardService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
      console.log("this.bankAccountList >>", this.bankAccountList);
    });
  }

  goToAddBankAccount() {
    this.router.navigate(['/add-bank-account']);
  }


  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }

  setPaymentModeAsManual(){
    this.stepperDataCardModel.isPartial = false;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    console.log("STEP 4 DATA >>>>>> ",this.stepperDataCardModel.step04Data);
  }

  getRedeemableCredits() {
    this.remittanceService.getRedeemableCredits().subscribe(resp => {
      this.redeemableCredits = resp.body;
      console.log("this.redeemableCredits >>", this.redeemableCredits);
    }, error => {
      console.log("Unable to get credit points!", error);
    });
  }

  async applyCreditPointToQuote() {
    if (this.quotationRequest.pointsToRedeem) {
      if (this.quotationRequest.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("Entered Points are more than available credit points");
      } else if (this.quotationRequest.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("You can redeem maximum upto " + this.redeemableCredits?.maximumRedeemableCredit + "points!");
      } else {
        // await this.callQuotation();
        this.getSessionData();
        this.toast.success("Credit points applied");
      }
    } else {
      this.toast.error("Please enter redeemable points");
    }
  }

  getOffers() {
    this.listOffers = [];
    this.remittanceService.getOffers().subscribe(resp => {
      var offerListAll = JSON.parse(JSON.stringify(resp.body));
      for (let singleOffer of offerListAll) {
        if (singleOffer.status == "ACTIVE" && (singleOffer.scope == "PRIVATE" || singleOffer.scope == "PUBLIC")) {
          this.listOffers.push(singleOffer);
        }
      }
      console.log('OFFERS >>', this.listOffers);

    }, error => {
      this.toast.error(error.error.message);
    });
  }

  async applyPromoCode() {
    if (this.quotationRequest.promoCode) {
      // await this.callQuotation();
      this.getSessionData();
      this.toast.success("Promocode applied successfully!");
    } else {
      this.toast.error("Please enter promocode!");
    }
  }
  
}
