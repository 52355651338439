import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { RelationshipService } from 'app/modules/relationship/services/relationship.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PagesList } from 'app/shared/constant/page-list';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { ReqGetCurrencyMaster, SinglePurposeItem, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { Utils } from 'app/shared/utility/utils';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { CartItemModel, RequestAadhaarEsignDetail, RequestCartMapPayer, RequestMapBeneficiaryModel } from '../../models/cart.model';
import { Step02Data, StepperDataModel } from '../../models/stepperdata.model';
import { RemittanceService } from '../../services/remittance.service';
import { CardService } from 'app/modules/card/services/card.service';
import { LocationStrategy } from '@angular/common';
import { EsignMode } from 'app/shared/constant/esign-mode';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QuotationCurrencyResponsesModel } from 'app/modules/card/models/card.model';
import { UpdateProfileModel } from 'app/modules/update-profile/models/update-profile.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { AddBankAccountRequest } from 'app/modules/bank-account/models/bank-account.model';
import { BankAccountService } from 'app/modules/bank-account/services/bank-account.service';
import { LRSDeclarationRequestModel } from 'app/modules/card/models/lrs.model';
import { DateUtils } from 'app/shared/utility/date-utils';
import { NgSelectComponent } from '@ng-select/ng-select/lib/ng-select.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['../../stepper.scss', './stepper.component.scss',

  ],
  encapsulation: ViewEncapsulation.None
})
export class StepperComponent implements OnInit {

  public contentHeader: string;
  public horizontalWizardStepper: Stepper;
  public showSelfDeclarationPage: boolean = false;
  public selectedSourceofFund: any;
  public showFinancialOptions: boolean = false;
  public selectedPurpose: SinglePurposeItem;
  public countryListItems: any;
  public universityListItems = [];
  public selectedCountry: string;
  public selectedUniversity: string;
  isAddress:string;
  public productList: any;
  public selectedProduct: any;
  @ViewChild('kycForm', { static: true })kycForm: NgForm;


  public bankAccountList: any;

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public updateProfileModel = new UpdateProfileModel;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public quotationResponse: any;
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;

  public currentStep: string;
  public isPdf: boolean = false;

  public sourceofFundItems = Constants.sourceOfFundsItemList;
  public productTypeList = Constants.productItemList;
  public selectedProductType: String = "FOREX CARD";
  public currencyItems: any;
  public currencyCodeSelected: any;
  public productEnum: any;
  public isCartEmpty: boolean = false;
  public financialInstitutionItem: any;
  public selectedfinancialInstitution: any;
  public selectedInstitution: any;
  public showLoader: boolean = false;
  public showStepper: boolean = true;
  public isCalculateTCS: boolean = true;
  public consumerDetail: any;
  paxId: any;

  public documentListResponse: any;
  public transactionDocListAwaiting: any;
  public transactionDocListUploaded: any;
  public addBankAccountRequest = new AddBankAccountRequest();


  public modalReference: any;
  public documentUrl: any;
  public documentTitle: any;
  public navigateToFourthStep: boolean = false;
  public bankAccountId: any;

  public activeCartModelStep: any;
  public paymentMode: any;
  public paymentInstruction: any;
  public currencyCode: any;

  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;

  public showTick: boolean = false;
  public fileUrl: any;
  public isChecked: boolean = false;
  public loanDocumentUploaded: boolean = false;
  public isPDFDocument: boolean = false;

  public sanctionedCountry: any;
  public countryCodeForDueDelligence: string;
  public showCrimerRegionModal: any;
  public showDueDeligenceModal: any;
  public showDueDeligenceForm: boolean = false;
  public loanDocumentUploadedUrl: string;
  public showAddBankForm: boolean = false;
  public hideProceedButton: boolean = false;
  public isTCSApplicable: boolean = true;
  //public quotationCurrencyResponsesList = [];
  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();


  public StepTwo: boolean = false;
  public StepThree: boolean = false;
  public StepFour: boolean = false;

  public ALLOW_NEFT_PAYMENT = Constants.ALLOW_NEFT_PAYMENT;

  @ViewChild('crimeaRegionDailog') crimeaRegionDailog: ElementRef;
  @ViewChild('dueDiligenceDialog') dueDiligenceDialog: ElementRef;
  @ViewChild('noTransactionAllowedDialog') noTransactionAllowedDialog: ElementRef;
  @ViewChild('EditCardModal') EditCardModal: TemplateRef<any>;
  @ViewChild('AmountLimitModal') AmountLimitModal: TemplateRef<any>;

  public modalRef: any;
  public isPartial;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();

  public reqCurrencyAmount: any;
  public editAmount: any;
  public refreshQuotationCount: number = 0;
  public signatureMode = EsignMode.activeEsignMode;
  public showESignIframe: boolean = false;
  public esignResp: any;
  public esignUrl: any;
  public url: any;
  public urlSafe: SafeResourceUrl;
  public creditPointApplied: boolean = false;
  public promoCodeApplied: boolean = false;
  public inrAmountZero: boolean = false;
  public isGICorBlockAccount: boolean = false;
  public bankDropdownItemList:any;
  public consumerId :any;
  public product: any;
  public clockDisplay: string;
  public isTimer = true;
  public interval;
  public defaultTimerLimitInSec = 300;
  public duration: any;
  public seconds = "00";
  public minutes = "00";
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public bankRelationshipList = Constants.payerList;
  public isPromoCodeAdded: boolean = false
  @ViewChild('refPromoCodeList') refPromoCodeList: NgSelectComponent;

  lrsForm: FormGroup;
  localLRSArray: Array<{ transactionDate: string; lrsDeclarationAmount: string; institudeName: string }> = [];
  public lrsDeclarationAmount: any;
  public instituteName: any;
  public showLocalTransactionDetails: boolean = false;
  public dateOfTransaction: any;
  public selectedDateOfTransaction: any;
  public showLRSForm: boolean = false;
  public showTransactionDetails: boolean = false;
  public transactionList: any;
  public selectedOption: string;
  public lrsDeclarationRequestModel: LRSDeclarationRequestModel = new LRSDeclarationRequestModel();
  public maxLRSDate: any;
  public minLRSDate: any;
  public isLRSformSubmit: boolean = false;
  public isLRSSave: boolean = false;
  
  constructor(
    private router: Router,
    public masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    public relationshipService: RelationshipService,
    public toast: ToastrService,
    public sanitizer: DomSanitizer,
    public bankAccountService: BankAccountService,
    public updateProfileService: UpdateProfileService,
    public cardService: CardService,private fb: FormBuilder,
  ) {
    this.getSessionData();
    this.reqCurrencyAmount = this.quotationRequest.quotationCurrencies[0].currencyAmount;
    this.quotationCurrencyItem.currencyCode = "EUR";
    this.timer();
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.getAllOrderLrsDetail();
    this.lrsForm = this.fb.group({
      instituteName: ['', Validators.required],
      lrsDeclarationAmount: ['', Validators.required],
      selectedDateOfTransaction: ['', Validators.required]
    });
    this.maxLRSDate =  { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.minLRSDate = { year: new Date().getFullYear(), month: 4, day: 1 };

  }

  ngOnInit() {
    this.getMenuItemFromConfig();
    this.getCountryList();
    this.getBankAccountList();
    this.getCurrencies();
    this.getRedeemableCredits();
    this.getOffers();
    this.getProfileDetails();
    this.getBankList();
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this.contentHeader = this.selectedPurpose.title;
  }
  openModalForEditCart() {
    this.modalReference = this.modalService.open(this.EditCardModal, { centered: true, size: 'sm' });
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
        // this.callQuotation();
      }
    }, 1000);
  }

  startTimer() {
    if (this.duration > 0) {
      this.interval = setInterval(() => {
        this.duration = this.duration - 1;
        localStorage.setItem('clockDisplayPer', JSON.stringify(this.duration));

        if (this.duration <= 0) {
          //this.callQuotation();
          this.hitGetQuotationCall();
        }

        if (this.duration % 60 < 10) this.seconds = "0" + this.duration % 60
        else this.seconds = (this.duration % 60).toString()

        if (this.duration / 60 < 10) {
          this.minutes = "0" + parseInt("" + this.duration / 60, 10)
        } else {
          this.minutes = "" + parseInt((this.duration / 60).toString(), 10)
        }
        // this.clockDisplayPer = (this.defaultTimerLimitInSec - this.duration) / 3;
        this.clockDisplay = this.minutes + " " + "minutes" + " : " + this.seconds + " " + "seconds";
      }, 1000);
    }
  }
  getBankList() {
    var singleObject;
    var localBankDropdownItemList;
    this.bankAccountService.getPaymentModes().subscribe(res => {

      localBankDropdownItemList = res.body;
      for (let i = 0; i < localBankDropdownItemList.length; i++) {
        singleObject = localBankDropdownItemList[i];
        if (singleObject.categoryName == "Net Banking") {
          this.bankDropdownItemList = singleObject.paymentModeCategories;
        }
      }

    });
  }
  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  onPaste(e) {
    e.preventDefault();
    return false;
  }
  productDropdownClicked(value) {
    console.log("product>>>>" + value);
    this.selectedProductType = value;
    if (value == this.productTypeList[0]) {
      //this.rateCalculatorResponse = this.rateCalculatorAllResponse["cardRateResponse"];
    } else {
      //this.rateCalculatorResponse = this.rateCalculatorAllResponse["cashRateResponse"];

    }

  }
  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    this.paxId = this.consumerDetail.paxResponse.id;
    
    if(this.consumerDetail?.paxResponse!=null && this.consumerDetail?.paxResponse?.address){
      this.isAddress='true';
    }else{
      this.isAddress='false';

    }

    return true;
  }
  getRateRange() {
    let productEnum: any;
    this.product = localStorage.getItem(ConstantsKey.PRODUCT);

    if (this.product == 'SEND-MONEY') {
      productEnum = Constants.productsEnum[2]
    } else if (this.product == 'BUY-FOREX') {
      productEnum = Constants.productsEnum[0];
    } else {
      productEnum = Constants.productsEnum[1];
    }
    this.showLoader = true;
    this.masterDataService.getRateRange(
      productEnum,
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      console.log(this.singleRateResItem);

    }, error => {
      this.showLoader = false;
    });
  }
  parentCallbackReceiverForListBeneficiary($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
      this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
      this.currencyCode = this.quotationResponse.quotationCurrencyResponses[0].currencyCode;
    }
  }

  parentCallbackForRelationship($event) {
    if ($event == 'RELATIONSHIP_DATA_ADDED') {
      this.getSessionData();
      this.getQuotationRequestBody();
    }
  }

  parentCallbackForFooter($event) {
    console.log("$event >>", $event);

    if ($event == 'UPDATE_QUOTATION') {
      this.getSessionData();
      this.hitGetQuotationCall();
    }
  }

  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
      
      if(this.stepperDataModel.quotationResponse.quotationCurrencyResponses[0].purpose=='GIC' || this.stepperDataModel.quotationResponse.quotationCurrencyResponses[0].purpose=='BLOCK_ACCOUNT'){
        this.isGICorBlockAccount=true;
        console.log("isGICorBlockAccount>>"+this.isGICorBlockAccount);

      }
    }
    console.log("GET session data >>>", this.stepperDataModel);

  }

  parentCallbackFormChild($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.hideProceedButton = this.stepperDataCardModel.step01Data.hideProceedButton;
    }

    if ($event == 'REFRESH_DATA_UPDATE_QUOTATION') {
     // this.callQuotation();
     this.hitGetQuotationCall();

    }
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  async getUniversityList(selectedCountry) {
    if (this.checkCountryDueDelligenceStatus(selectedCountry)) {
      this.showLoader = true;
      this.universityListItems = [];
      this.masterDataService.getUniversityList(this.selectedPurpose.remittanceType, selectedCountry).subscribe(resp => {
        this.universityListItems = JSON.parse(JSON.stringify(resp.body));
        var universityListItemsLocal = JSON.parse(JSON.stringify(resp.body));
        universityListItemsLocal.forEach((universitySingleItems, index) => {
          universitySingleItems['name'] = Utils.toTitleCase(universitySingleItems.name);
          this.universityListItems[index] = universitySingleItems;
        });
        this.showLoader = false;
      });
    }
  }

  addNewUniversity = (beneficiaryName: string) => {
    this.navigateToAddBeneficiary(beneficiaryName, this.selectedCountry);
  }

  navigateToAddBeneficiary(beneficiaryName, countryCode) {
    var sharedQueryParams = this.selectedPurpose;
    sharedQueryParams['sourcePage'] = PagesList.REMITTANCE_STEPPER;
    sharedQueryParams['beneficiaryName'] = beneficiaryName;
    sharedQueryParams['countryCode'] = countryCode;
    sharedQueryParams['currencyCode'] = this.quotationResponse.quotationCurrencyResponses[0].currencyCode;
    console.log("currencycode", sharedQueryParams['currencyCode']);
    this.router.navigate(['/add-beneficiary'], { skipLocationChange: false, queryParams: sharedQueryParams });
  }

  getLoanProviderList() {
    this.remittanceService.getLoanProviderList().subscribe(resp => {
      this.financialInstitutionItem = resp.body;
    });
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    });
  }

  async getSelectedSourceFund(selectedItem) {
    if (selectedItem == 'Loan') {
      this.showFinancialOptions = true;
      this.getLoanProviderList();
    } else {
      this.showFinancialOptions = false;
    }
    this.getSessionData();
    this.getQuotationRequestBody();
    await this.hitGetQuotationCall();
    this.refreshQuotationCount = this.refreshQuotationCount + 1;
    console.log("refreshQuotationCount >>", this.refreshQuotationCount);
  }

  horizontalWizardStepperNext(data) {
    if (data.form.valid === true) {
      this.horizontalWizardStepper.next();
    }
  }

  async step01CreateCartItems() {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;

    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step01Data?.beneficiary) {
        this.showLoader = true;
        this.currentStep = 'BENEFICIARY_STEP1';
        this.getSessionData();
        if (await this.checkCountryDueDelligenceStatus(this.stepperDataModel?.step01Data?.beneficiary.countryCode)) {
          let cartItemModel: CartItemModel = new CartItemModel();
          cartItemModel.currencyAmount = this.quotationResponse.quotationCurrencyResponses[0].currencyAmount;
          cartItemModel.bidRate =
            this.quotationResponse.quotationCurrencyResponses[0].rate;
          cartItemModel.purpose =
            this.quotationResponse.quotationCurrencyResponses[0].purpose;
          cartItemModel.currencyCode =
            this.quotationResponse.quotationCurrencyResponses[0].currencyCode;
          cartItemModel.product = "REMITTANCE";
          cartItemModel.nostroChargeType = "REMITTER";
          cartItemModel.branchId = Constants.wsflReferenceBranchId;
          cartItemModel.sellType = Constants.sellTypes[0];

          cartItemModel.countryCode = this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
          cartItemModel.beneficiaryName = this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
          let cartItemMapRequestList = [cartItemModel];

          ///cart item port
          this.remittanceService.cartItemPost(cartItemMapRequestList).subscribe((resp) => {
            let requestMapBeneficiaryModel: RequestMapBeneficiaryModel = new RequestMapBeneficiaryModel();
            requestMapBeneficiaryModel.beneficiaryId = this.stepperDataModel.step01Data.beneficiary.id;

            ///map beneficiary
            this.remittanceService.mapBeneficiary(requestMapBeneficiaryModel).subscribe(async (respMapBeneficiary) => {
              ///refresh quotation
              this.getQuotationRequestBody();
              await this.hitGetQuotationCall();
              await this.updateStepNo(1);
              await this.getDocumentList();

              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });

              this.showLoader = false;
              this.horizontalWizardStepper.next(); // move to step 2

            }, error => {
              this.showLoader = false;
              this.toast.error(error.error.message);
            });
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        } else {
          this.showLoader = false;
        }
      } else {
        this.toast.error('Please select beneficiary');
      }
    }
  }

  async step02StudentDetails(data) {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step02Data?.selfResPaxItemSingle) {
        if (this.selectedSourceofFund) {
          if (this.selectedSourceofFund == "Own" || (this.selectedSourceofFund === "Loan" && this.stepperDataModel?.step02Data?.loanDocumentUploaded && this.stepperDataModel?.step02Data?.loanCOnfirmationCheckBox)) {
            this.showLoader = true;
            if (data.form.valid === true) {
              this.currentStep = 'STUDENT_STEP2';
              this.StepTwo = true;
              this.getSessionData();
              this.getQuotationRequestBody();
              await this.hitGetQuotationCall();
              await this.updateStepNo(2);
              await this.mapPayerToCart();

              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.showLoader = false;
              this.horizontalWizardStepper.next();

            } else {
              this.showLoader = false;
            }
          } else {
            this.toast.error('Please upload loan document or Kindly confirm if loan is from certifed instituition or please mark the checkbox!');
          }
        } else {
          this.toast.error('Please select source of fund');
        }
      } else {
        this.toast.error('Please select relation.');
      }
    }
  }

  async step03GetDocumentProceed(data) {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;
    this.showLoader = true;
    if (data.form.valid === true) {
      this.currentStep = 'DOCUMENT_STEP03_1';
      this.StepThree = true;
      if (this.validateOfacData()) { }

      if (this.selectedPurpose.remittanceType == "OTHERS") {

        await this.getRelationshipList();
        await this.updateStepNo(2);
        await this.mapPayerToCart();

        this.getQuotationRequestBody();
        await this.hitGetQuotationCall();
         await this.getDocumentForSign();
        await this.updateStepNo(3);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.horizontalWizardStepper.next();

      } else {
         await this.getDocumentForSign();
        await this.updateStepNo(3);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.horizontalWizardStepper.next();
      }
    }
  }

  validateOfacData() {
    this.getSessionData();
    return true;
  }

  async step03EsignOrOtpValidate() {
    if (this.signatureMode == 'AADHAR_ESIGN') {
      let requestAadhaarEsignDetail = new RequestAadhaarEsignDetail();
      requestAadhaarEsignDetail.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);;
      requestAadhaarEsignDetail.refOrderId = this.activeCartModelStep.cartDetails.refOrderId;
      this.remittanceService.getA2FormSignedDoc(requestAadhaarEsignDetail).subscribe(async resp => {
        var resAadhaarEsignDetail = JSON.parse(JSON.stringify(resp.body));
        if (resAadhaarEsignDetail.esignFileUrl == null || resAadhaarEsignDetail.esignFileUrl == "") {
          this.toast.error("Please esign document!.");
        } else {
          this.aadharEsignDocumentDetails();
        }
      });
    } else {
      this.otpVerification();
    }

  }

  async getDocumentForSign() {
    this.showLoader = true;
    let orderId = this.activeCartModelStep.cartDetails.refOrderId;
    let cartType = this.selectedPurpose.remittanceType;

    const respEsign = await this.remittanceService.getDocumentSigned(orderId, cartType).toPromise();
    this.esignResp = JSON.parse(JSON.stringify(respEsign.body));
    this.esignUrl = this.esignResp.result.url;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.esignUrl);
    this.showLoader = false;
  }



  async getRelationshipList() {
    var respRelationShip = await this.relationshipService.getRelationshipList().toPromise();

    var relationshipMemberListItems: Array<any> = JSON.parse(JSON.stringify(respRelationShip.body));
    if (relationshipMemberListItems.length > 0) {
      relationshipMemberListItems.forEach(singlePaxItem => {
        if (singlePaxItem.relationship.toLowerCase() == "self") {
          if (singlePaxItem.pan != null && singlePaxItem.pan.length == 10) {
            let step02Data = new Step02Data();
            step02Data.selfResPaxItemSingle = singlePaxItem;
            this.stepperDataModel.step02Data = step02Data;
          }
        }
      });
    }
  }

  async hitGetQuotationCall() {
    console.log("quotationSnapshotId>>>>"+JSON.stringify (this.stepperDataModel.quotationResponse));

    if(this.stepperDataModel.quotationResponse!=null){
      this.requestQuotationModel.quotationSnapshotId = this.stepperDataModel.quotationResponse.quotationSnapshotId;
    }
    // var respQuotation = await this.remittanceService.getQuotation(this.requestQuotationModel).toPromise();
    // this.stepperDataModel.quotationResponse = respQuotation.body;
    // this.stepperDataModel.quotationRequest = this.requestQuotationModel;
   
    // this.duration = this.defaultTimerLimitInSec;
    // clearInterval(this.interval);
    // this.startTimer();
    // sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    // this.refreshQuotationCount = this.refreshQuotationCount + 1;
    // this.getSessionData();

    
    return new Promise((resolve, reject) => {
      this.remittanceService.getQuotationEncrypted(this.requestQuotationModel).subscribe(resp => {

      }, error => {
        this.showLoader = false;
          if (error.status == 200) { 
                this.stepperDataModel.quotationResponse= JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
                this.stepperDataModel.quotationRequest = this.requestQuotationModel;
                this.duration = this.defaultTimerLimitInSec;
                clearInterval(this.interval);
                this.startTimer();
                sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
                this.refreshQuotationCount = this.refreshQuotationCount + 1;
                this.getSessionData();
                resolve("success");
          } else {
                this.toast.error(error.error.message);
                reject("fail");
          }
      });
    });

  }

  async updateStepNo(stepNo: number) {
    const response = await this.remittanceService.getCartToUpdateStepNumber(stepNo).toPromise();
    this.activeCartModelStep = response.body;

    //[start] update session data
    this.getSessionData();
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.stepperDataModel.cartItemsResponse = this.activeCartModelStep;
    }
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    //[end] update session data

    this.showLoader = false;
    return response;
  }

  gotoDashboard() {
    this.router.navigate(['/new-dashboard'])
      .then(() => {
        window.location.reload();
      });
  }

  ngAfterViewChecked() {
    // console.log("this.horizontalWizardStepper >>>",this.horizontalWizardStepper['_currentIndex']);
  }

  horizontalWizardStepperPrevious() {
    this.horizontalWizardStepper.previous();
  }

  openModalForPaymentInfo(paymentInstructionModal) {
    this.hitGetQuotationCall();
    sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'true');
    sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
    sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
    if (this.isPartial) {
      if (this.paymentMode) {
        if (this.paymentMode == 'MANUAL' || (this.paymentMode == 'NB' && this.bankAccountId)) {
          this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
          if (this.paymentMode == PaymentMode?.NET_BANKING) {
            this.paymentInstruction = "Please note, you are requested to upload a proof of your payment in the Order Details section of My Orders, if you do choose to proceed with this mode of payment.\n";
          } else if (this.paymentMode == PaymentMode?.NEFT_RTGS || this.paymentMode == PaymentMode?.TPV) {
            this.paymentInstruction = "Please note, if a payment is not received within 4 hours, your order will stand cancelled. Please do contact customer care at +91 89767 07222 for any queries.\n";
          }
        } else {
          this.toast.error('Please select your bank account.');
        }
      } else {
        this.toast.error('Please select your payment method.');
      }
    } else {
      this.toast.error('Please select payment type.');
    }
  }

  step04Payment() {
    var paymentMode = this.paymentMode;
    this.refreshQuotationCount = this.refreshQuotationCount + 1;
    this.modalReference.close();
    this.showLoader = true;
    this.remittanceService.cartPlaceOrder(this.bankAccountId, paymentMode).subscribe(respPayment => {
      var respPaymentBody = JSON.parse(JSON.stringify(respPayment.body));
      this.showLoader = false;

      if (paymentMode == PaymentMode.NET_BANKING) {
        this.getPaymentGatewayLink(respPaymentBody.orderId);
      } else {
        this.router.navigate(['/payment-success', respPaymentBody.orderId]);
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    })
  }

  async mapPayerToCart() {
    let requestCartMapPayer = new RequestCartMapPayer();

    requestCartMapPayer.remitterType = this.selectedPurpose.remittanceType;
    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPan = this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;

    requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    if (this.selectedPurpose.remittanceType != "OTHERS") {
      requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    }

    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;

    // Map requestCartMapPayerJson = requestCartMapPayer.toJson();
    if (this.selectedPurpose.remittanceType == "OTHERS") {
      // requestCartMapPayer.remove('paidBy');
      // requestCartMapPayer.remove('paxId');
    }

    await this.remittanceService.cartPayerMap(requestCartMapPayer).toPromise();
  }

  getQuotationRequestBody() {
    this.requestQuotationModel = this.quotationRequest;

    if (this.currentStep == 'STUDENT_STEP2' || this.selectedSourceofFund) {
      this.requestQuotationModel.pan = this.stepperDataModel.step02Data?.selfResPaxItemSingle?.pan;
      this.requestQuotationModel.tcsPaymentSource = this.selectedSourceofFund.toUpperCase();
      if (this.selectedSourceofFund == 'Loan') {
        this.requestQuotationModel.tcsPaymentSourceDetails = (this.selectedInstitution != 'Other') ? this.selectedInstitution : this.instituteName;
      } else {
        this.requestQuotationModel.tcsPaymentSourceDetails = '';
      }
      this.requestQuotationModel.paymentSourceDetails = this.selectedSourceofFund;
      this.requestQuotationModel.taxResidentOfIndia = true;
      // this.requestQuotationModel.otherSourcePaymentDetails = '';
    }

    if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS" &&
      this.stepperDataModel?.step02Data?.selfResPaxItemSingle?.pan != null) {
      this.requestQuotationModel.calculateTcs = true;
      this.requestQuotationModel.tcsPaymentSource = "OWN";
      this.requestQuotationModel.taxResidentOfIndia = true;
      this.requestQuotationModel.pan =
        this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;
      this.requestQuotationModel.paymentSourceDetails = "";
    }
  }

  fileUploadCartDocument(event, docType) {
    this.showLoader = true;
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        if (file.type == 'application/pdf') {
          this.isPdf = true;
        }
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "CONSUMER");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
        formData.append('documentPurpose', "TRANSACTION");

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.remittanceService.uploadDocument(formData).subscribe((respDoc) => {
            this.showLoader = false;
            this.showTick = true;
            // if (docType != "LOAN_DOCUMENT") {
            //   this.getDocumentList();
            // }
            this.getDocumentList();

            if (this.selectedSourceofFund == 'Loan' && docType == "LOAN_DOCUMENT") {
              this.setLoanDocumentUrl(respDoc.body);

              this.loanDocumentUploaded = true;
              this.getSessionData();

              let step02Data = new Step02Data();
              if (this.stepperDataModel.step02Data) {
                step02Data = this.stepperDataModel.step02Data;
              }
              step02Data.loanDocumentUploaded = true;
              this.stepperDataModel.step02Data = step02Data;
              sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));

              this.toast.success("Document uploaded successfully!");
            }
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
      this.showLoader = false;
    }
  }

  async getDocumentList() {
    this.remittanceService.getDocuments().subscribe((resp => {
      this.documentListResponse = resp.body;
      this.transactionDocListAwaiting = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.AWAITING;
      this.transactionDocListUploaded = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.UPLOADED;
      if (this.documentListResponse.skipDocumentUpload == true && this.isAddress=='true') {
        this.navigateToFourthStep = true;
      }
    }), error => {
      this.toast.error(error.error.message);
    });
  }

  showToast() {
    if (this.documentListResponse.skipDocumentUpload == true && this.isAddress=='false' ) {
      if(this.updateProfileModel.address!=null){
        this.updateProfileAddress();
      }else{
        this.toast.error("Please Add your Address");
      }
    }else{
      this.toast.error("Please upload mandatory document");

    }
  }
  updateProfileAddress() {
    this.showLoader = true;
    
    console.log("address model>>>> ", JSON.stringify(this.updateProfileModel));
    this.updateProfileService.updateProfileDetails(this.paxId, this.updateProfileModel).subscribe(async resp => {
      this.showLoader = false;
      this.navigateToFourthStep = true;
      this.step03GetDocumentProceed(this.kycForm);
    }, error => {
      this.showLoader = false;
      if (error.status == 200) {
        this.navigateToFourthStep = true;
        this.step03GetDocumentProceed(this.kycForm);
      } else {
        this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }

  openModalForTransactionDocumentView(transactionDocumentViewModal, documentDetails) {
    this.modalReference = this.modalService.open(transactionDocumentViewModal, { centered: true });
    this.documentUrl = documentDetails.location;
    this.documentTitle = documentDetails.documentType;
  }
  openModalForAddBankAccount(addBankModal) {
    this.modalReference = this.modalService.open(addBankModal, { size: 'lg', centered: true });

  }
  addBankAccount(data) {
    if (data === true) {
      this.showLoader = true;
      if (this.addBankAccountRequest.number === this.addBankAccountRequest.confirmAccNo) {
        this.addBankAccountRequest.consumerId = this.consumerId;
        this.addBankAccountRequest.default = true;
        this.addBankAccountRequest.type = 'SAVINGS';
        this.addBankAccountRequest.isDefault = true;
        console.log(JSON.stringify(this.addBankAccountRequest));

        this.bankAccountService.addMyBankAccount(this.addBankAccountRequest).subscribe(resp => {
          this.showLoader = false;
          this.toast.success("Bank account added successfully", "Success !!");
          this.modalReference.close();

          this.getBankAccountList();
        }, error => {
          this.showLoader = false;
          console.log(error);
          this.toast.error(error.error.message);
        });
      } else {
        this.showLoader = false;
        this.toast.error("Account no and confirm account no. must be same");
      }

    }
  }

  downloadUploadedDoc(documentDetails) {
    this.documentUrl = documentDetails.location;
    window.location.assign(this.documentUrl);
  }

  openModalForFinancialDocumentView(financialDocumentViewModal, fileUrl) {
    this.modalReference = this.modalService.open(financialDocumentViewModal, { centered: true });
    this.documentUrl = fileUrl;
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getPaymentGatewayLink(orderId: string) {
    this.showLoader = true;
    this.getSessionData();
    this.remittanceService.getPaymentGatewayLink(orderId, this.isPartial,this.bankAccountId).subscribe(respPaymentLink => {
      this.showLoader = false;
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      window.location.href = responsePaymentLinkBody.payment_link;
    }, error => {
      this.showLoader = false;
      console.log("Error in generating payment link", error);
    });
  }

  public redeemableCredits: any;
  public listOffers: any;
  getRedeemableCredits() {
    this.remittanceService.getRedeemableCredits().subscribe(resp => {
      this.redeemableCredits = resp.body;
      console.log("this.redeemableCredits >>", this.redeemableCredits);
    }, error => {
      console.log("Unable to get credit points!", error);
    });
  }

  async applyCreditPointToQuote() {
    if (this.requestQuotationModel.pointsToRedeem) {
      if (this.requestQuotationModel.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("Entered Points are more than available credit points");
      } else if (this.requestQuotationModel.pointsToRedeem > this.redeemableCredits?.availableCredit) {
        this.toast.error("You can redeem maximum upto " + this.redeemableCredits?.maximumRedeemableCredit + "points!");
      } else {
        await this.hitGetQuotationCall();
        this.getSessionData();
        this.creditPointApplied = true;
        this.toast.success("Credit points applied");
        this.getRedeemableCredits();
      }
    } else {
      this.toast.error("Please enter redeemable points");
    }
  }


  async removeCreditPoint() {
    this.requestQuotationModel.pointsToRedeem = null;
    await this.hitGetQuotationCall();
    this.getSessionData();
    this.creditPointApplied = false;
    this.toast.success("Credit points removed");
    this.getRedeemableCredits();
  }


  getOffers() {
    this.listOffers = [];
    let listOfferLocal = [];
    this.remittanceService.getOffers().subscribe(resp => {
      var offerListAll = JSON.parse(JSON.stringify(resp.body));
      for (let singleOffer of offerListAll) {
        if (singleOffer.status == "ACTIVE" && (singleOffer.scope == "PRIVATE" || singleOffer.scope == "PUBLIC")) {
          listOfferLocal.push(singleOffer);
        }
      }
      console.log('OFFERS >>', this.listOffers);
      this.listOffers = listOfferLocal;
    }, error => {
      this.toast.error(error.error.message);
    });
  }

  async applyPromoCode() {
    if (this.requestQuotationModel.promoCode) {
      this.isPromoCodeAdded = true;
      await this.hitGetQuotationCall().then((res) => {
        if(res == "success") {
          this.getSessionData();
          this.toast.success("Promocode applied successfully!");
          this.promoCodeApplied = true;
          this.getRedeemableCredits();          
        }
      }).catch(err => {
        if(err == "fail") {
          this.refPromoCodeList.handleClearClick();
        }
      });
    } else {
      this.toast.error("Please enter promocode!");
    }
  }

  async removePromoCode() {
    this.requestQuotationModel.promoCode = null;
    await this.hitGetQuotationCall();
    this.getSessionData();
    this.toast.success("Promocode removed successfully!");
    this.promoCodeApplied = false;
    this.getRedeemableCredits();
  }

  checkLoanConfirmationCheckboxvalue() {
    this.getSessionData();
    let step02Data = new Step02Data();
    if (this.stepperDataModel.step02Data) {
      step02Data = this.stepperDataModel.step02Data;
    }
    step02Data.loanCOnfirmationCheckBox = this.isChecked;
    this.stepperDataModel.step02Data = step02Data;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
  }

  openPDFDocument() {
    window.open(this.fileUrl, '_blank');
  }

  async checkCountryDueDelligenceStatus(countryCode) {
    this.countryCodeForDueDelligence = countryCode;

    const respData = await this.remittanceService.getDueDelligenceStatus(countryCode).toPromise()
      .then((respData) => {
        return respData;
      }).catch((error) => {
        return error;
      });

    if (respData.status == 200) {
      this.sanctionedCountry = respData.body;
      if (countryCode == "UA") {
        this.openCrimeaRegionDailog();
      }

      if (countryCode != "UA" && this.sanctionedCountry.fatfSanctions == true) {
        this.openDueDeligenceDailog();
      }

      if (this.sanctionedCountry.enhanceDueDiligence == true) {
        this.showDueDeligenceForm = true;
      }
      return true;

    } else if (respData.status == 400) {
      this.openNoTransactionAllowedDailog();
      return false;
    }
  }

  openCrimeaRegionDailog() {
    this.modalRef = this.modalService.open(this.crimeaRegionDailog, {
    });
  }

  openDueDeligenceDailog() {
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }

    this.modalRef = this.modalService.open(this.dueDiligenceDialog, {
    });
  }

  openNoTransactionAllowedDailog() {
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }
    this.modalRef = this.modalService.open(this.noTransactionAllowedDialog, {
    });
  }

  agreeDueDiligence() {
    this.modalRef.close();
    this.showDueDeligenceForm = true;
    this.getSessionData();

    console.log("this.stepperDataModel.ofacData >>", this.stepperDataModel.ofacData);

    this.stepperDataModel.ofacData.isOfacRequired = true;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
  }

  setLoanDocumentUrl(respDoc) {
    var cartDocumentsList = respDoc['cartDocuments'];
    cartDocumentsList.forEach(singleDoc => {
      if (singleDoc.type == "LOAN_DOCUMENT") {
        this.loanDocumentUploadedUrl = singleDoc.location;
      }
    });
  }

  callQuotation() {
    this.getSessionData();

    var quotationRequest = this.stepperDataCardModel.quotationRequest;
    quotationRequest.paymentSourceDetails = this.stepperDataCardModel?.step02Data?.paymentSourceDetails;
    quotationRequest.calculateTcs = this.isCalculateTCS;
    console.log("quotationSnapshotId>>>>"+JSON.stringify (this.stepperDataCardModel.quotationResponse));
    if(this.stepperDataCardModel.quotationResponse!=null){
      quotationRequest.quotationSnapshotId = this.stepperDataCardModel.quotationResponse.quotationSnapshotId;

    }

    this.cardService.getQuotation(quotationRequest).subscribe((respQuote) => {
      var quotationResponse = JSON.parse(JSON.stringify(respQuote.body));
      this.quotationCurrencyResponsesList = quotationResponse["quotationCurrencyResponses"];
      this.stepperDataCardModel.quotationResponse = quotationResponse;

      if (this.isCalculateTCS && quotationResponse["tcs"]["tcsAmountString"] != "0.00") {
        this.isTCSApplicable = true;
      } else {
        this.isTCSApplicable = false;
      }
      this.duration = this.defaultTimerLimitInSec;
      clearInterval(this.interval);
      this.startTimer();
      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

    }, (error) => {
      console.log("Error callQuotation >", error);
    });
  }

  downloadUploadedLoanDoc() {
    window.open(this.loanDocumentUploadedUrl, '_blank');
  }

  getCurrencies() {
    this.reqGetCurrencyMaster.cartType = this.selectedPurpose.remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
      console.log('curency items >>', this.currencyItems);
      if (this.inrAmountZero) {

      }
    });
  }

  async editCart() {
    this.getRateRange();
    if (this.reqCurrencyAmount && this.reqCurrencyAmount != '') {
      this.modalReference.close();
      this.showLoader = true;
      this.inrAmountZero = false;
      // this.getSessionData();
      this.quotationRequest.quotationCurrencies[0].currencyAmount = this.reqCurrencyAmount;
      this.getQuotationRequestBody();
      await this.hitGetQuotationCall();
      this.getSessionData();
      this.refreshQuotationCount = this.refreshQuotationCount + 1;
      console.log("refreshQuotationCount >>", this.refreshQuotationCount);

      this.showLoader = false;
    } else {
      this.inrAmountZero = true;
      this.toast.error('Please enter amount');
    }
  }

  async updateCurrencyAmount() {
    if (this.reqCurrencyAmount && this.reqCurrencyAmount != '') {
      // if(this.reqCurrencyAmount < 25000) {
      this.modalReference.close();
      this.showLoader = true;
      this.inrAmountZero = false;
      this.quotationRequest.quotationCurrencies[0].currencyAmount = this.reqCurrencyAmount;
      this.getQuotationRequestBody();
      await this.hitGetQuotationCall();
      this.refreshQuotationCount = this.refreshQuotationCount + 1;
      console.log("refreshQuotationCount >>", this.refreshQuotationCount);
      this.showLoader = false;
      // } else {
      //   this.modalReference = this.modalService.open(this.AmountLimitModal, { centered: true, size: 'sm' });
      // }
    } else {
      this.inrAmountZero = true;
      this.toast.error('Please enter amount');
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  setPaymentModeAsFullPayment() {
    this.showNEFT = true;
    this.showNetBanking = true;
  }

  setPaymentMethodAsPartial() {
    this.showNetBanking = true;
    this.showNEFT = false;
  }


  async aadharEsignDocumentDetails() {
    let requestAadhaarEsignDetail = new RequestAadhaarEsignDetail();
    requestAadhaarEsignDetail.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);;
    requestAadhaarEsignDetail.refOrderId = this.activeCartModelStep.cartDetails.refOrderId;

    this.remittanceService.getA2FormSignedDoc(requestAadhaarEsignDetail).subscribe(async resp => {
      var resAadhaarEsignDetail = JSON.parse(JSON.stringify(resp.body));
      if (resp.status == 200) {
        if (resAadhaarEsignDetail.esignFileUrl && resAadhaarEsignDetail.esignFileUrl != null &&
          resAadhaarEsignDetail.esignFileUrl != "") {
          await this.updateStepNo(4);
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

          this.showLoader = false;
          this.horizontalWizardStepper.next();
        } else {
          this.toast.error("Please esign document properly.");
        }
      } else {
        this.toast.error('Please esign document properly!');
      }
    });

  }

  otpVerification() {
    this.refreshQuotationCount = this.refreshQuotationCount + 1;

    this.getSessionData();
    console.log("stepperDataModel++", this.stepperDataModel);
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step03Data?.checkboxChecked == true) {
        if (this.stepperDataModel?.step03Data?.otpValue != null && this.stepperDataModel?.step03Data?.otpValue != '') {
          this.showLoader = true;
          let a2AcceptanceRequest = {
            "otp": this.stepperDataModel?.step03Data?.otpValue
          };
          this.remittanceService.getA2FormCart(a2AcceptanceRequest).subscribe((respDownloadA2) => {
            this.showLoader = false;
            var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
            if (jsonResult.cartDetails.a2FormUrl) {
              let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
              window.open(a2FormUrl, "_blank");
            }
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.updateStepNo(4);
            this.horizontalWizardStepper.next();
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });

        } else {
          this.toast.error("Please enter OTP");
        }
      } else {
        this.toast.error("Please accept declaration");
      }
    } else {
      this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        });
    }
  }


  showIframeForEsign() {
    this.getSessionData();
    if (this.stepperDataModel.step03Data) {
      if (!this.stepperDataModel.step03Data.checkboxChecked) {
        this.toast.error('Please select checkbox !');
      } else {
        this.showESignIframe = true;
      }
    } else {
      this.toast.error('Please select checkbox !');
    }
  }

  public configData: any;
  public showNetBanking: boolean = true;
  public showNEFT: boolean = true;
  public partialPaymentAllowed: boolean = true;

  getMenuItemFromConfig() {
    // let data = sessionStorage.getItem('CONFIG_DATA');
    // this.configData = JSON.parse(data);
    // console.log(JSON.stringify(this.configData));

    this.configData={
      "remittanceFlowEnable": true,
      "personalFlowEnable": true,
      "cardFlowEnable": true,
      "insuranceEnable": true,
      "dashboardEnable": true,
      "myAccountMenuEnable": true,
      "myAccountSubMenuEnable": true,
      "relationshipEnable": true,
      "beneficiaryEnable": true,
      "bankAccountEnable": true,
      "ordersEnable": true,
      "pendingOrdersEnable": true,
      "rewardsEnable": true,
      "offersEnable": true,
      "branchNetworkEnable": true,
      "currencyConverterEnable": true,
      "resetPasswordEnable": true,
      "ratesEnable": true,
      "setRateAlertEnable": true,
      "supportEnable": true,
      "addressEnable": true,
      "faqEnable": true,
      "tutorialEnable": true,
      "paymentModeNBEnable": true,
      "paymentModeNEFTEnable": true,
      "partialPaymentEnable": true,
      "openGICAccountEnable": true,
      "blockedAccountEnable": true,
      "zolveCreditCardEnable": true,
      "isicCardEnable": true
  };

    if (!this.configData.paymentModeNBEnable) {
      this.showNetBanking = false;
    } else if (!this.configData.paymentModeNEFTEnable) {
      this.showNEFT = false;
    } else if (!this.configData.partialPaymentEnable) {
      this.partialPaymentAllowed = false;
    }

  }

  showLRSFormSection(booleanValue) {
    this.showLRSForm = booleanValue;
  }


  saveLRSDataLocally() {

    this.isLRSformSubmit = true;
    if(this.lrsForm.valid) {
      this.lrsDeclarationRequestModel.transactionDate = DateUtils.getNgbDateStructToDate(this.lrsForm.controls['selectedDateOfTransaction'].value);
      const newTransaction = {
        transactionDate: this.lrsDeclarationRequestModel.transactionDate,
        lrsDeclarationAmount: this.lrsForm.controls['lrsDeclarationAmount'].value,
        institudeName: this.lrsForm.controls['instituteName'].value
      };
      this.localLRSArray.push(newTransaction);
      this.lrsForm.reset();  
      this.showLRSForm = false;
      if(this.localLRSArray.length > 0){
        this.showLocalTransactionDetails = true;
      }
    }
  }


//  proceedToLRSDeclaration() {
//     this.cardService.lrsDeclaration(this.localLRSArray).subscribe(resp => {
//       this.showLoader = false;
//       this.consumerDetail = resp.body;
//       this.toast.success('LRS Declaration Details saved successfully!');
//       this.hitGetQuotationCall();
//       this.getAllOrderLrsDetail();
//       this.showLocalTransactionDetails = this.showLRSForm = false;
//     });
//   }

  getAllOrderLrsDetail() {
    this.cardService.getAllOrderLrsDetails().subscribe((resp) => {
      this.transactionList = resp.body;
      console.log(this.transactionList);
      if (this.transactionList.length > 0) {
        this.showTransactionDetails = true;
        this.selectedOption = 'Yes';
        this.showLRSFormSection(true);
      }
    });
  }

  removeLRSEntry(index: number) {
    this.localLRSArray.splice(index, 1);
    if(this.localLRSArray.length == 0){
      this.showLocalTransactionDetails = false;
    }
  }

  async checkLRSStep(){
    if (this.selectedOption) {
      if(this.localLRSArray.length > 0 && !this.isLRSSave) {
        this.showLoader = true;
         this.cardService.lrsDeclaration(this.localLRSArray).subscribe(resp => { 
          this.consumerDetail = resp.body;
           this.showLoader = false;
           this.getAllLRSData();
        
        }, err => {
               this.showLoader = false;

          console.log(err);
           Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
        
      }); 
        
      } else {
        this.horizontalWizardStepper.next();        
      }
    } else {
      this.toast.error('Please complete LRS Declaration!')
    }
  }
  async getAllLRSData() {
    this.cardService.getAllOrderLrsDetails().subscribe(resp => {
           this.getDocumentForSign();
            this.transactionList = resp.body;
            this.hitGetQuotationCall();
            this.showLRSForm = false;
            this.isLRSSave = true;
            this.isLRSformSubmit = false;
            this.horizontalWizardStepper.next();

     }, err => {
      console.log(err);
       Swal.fire({
              title:"Dear Customer", 
              html: err.error.message, 
              imageUrl: "assets/images/icons/info_icon.png",
              imageWidth: 80,
              imageHeight: 80,
              focusConfirm: false,
              confirmButtonColor: '#1D2A62'
            });
     }); 
  }

  closeLRSForm() {
    this.lrsForm.reset();
    this.isLRSformSubmit = false;
    this.showLRSForm = false;
  }

  get lrsFormControl(): any {
    return this.lrsForm['controls'];
  }
  addNewCoupon = (offer) => {
    return { title: offer, promoCodeId: offer }
  };

}