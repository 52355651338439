import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { LoaderRoutingModule } from "app/shared/utility/utility-routing.module";
import { RelationshipService } from "./services/relationship.service";
import { AddRelationshipComponent } from "./view/add-relationship/add-relationship.component";
import { ListRelationshipComponent } from "./view/list-relationship/list-relationship.component";

const routes = [
  {
    path: 'list-relationship',
    component: ListRelationshipComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]
  },
  {
    path: 'add-relationship',
    component: AddRelationshipComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]
  } 
];

@NgModule({
  declarations: [ListRelationshipComponent , AddRelationshipComponent],
  imports: [
    RouterModule.forChild(routes), 
    ContentHeaderModule, 
    NgSelectModule,
    CommonModule,
    FormsModule,
    NgbModule,
    LoaderRoutingModule

  ],
  exports: [ ListRelationshipComponent, AddRelationshipComponent],
  providers:[RelationshipService]
})
export class RelationshipRoutingModule {}
