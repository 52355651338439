<div *ngIf="showLoader"></div>
<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 mb-20">
                        <h6 class="content-header-title  mb-0 " style="color: black" *ngIf="pageTitle == 'GIC' || pageTitle == 'Blocked'">Apply for {{this.pageTitle}} Account</h6>
                        <h6 class="content-header-title  mb-0 " style="color: black" *ngIf="pageTitle == 'Zolve'">Apply for Zolve Credit Card</h6>
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'GIC'">
                        <label class="form-label"><b>Visiting Country</b></label>
                        <input type="text" id="visitingCountry" class="form-control fs-12" readonly="readonly" style="border-color:black" placeholder="Enter Country" value="CANADA">
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'Blocked'">
                        <label class="form-label"><b>Visiting Country</b></label>
                        <input type="text" name="country" id="country" class="form-control fs-12" style="border-color:black" value="Germany" readonly />
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'Zolve'">
                        <label class="form-label" for="country"><b>Country</b></label>
                        <ng-select placeholder="Select Country" style="border-color:black" name="countryName" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [searchable]="true" [(ngModel)]="accountRegisterModel.visitingCountry">
                        </ng-select>
                    </div>
                    <!-- <div class="form-group col-md-6" *ngIf="pageTitle == 'Zolve'">
                        <label class="form-label"><b>First Name*</b></label>
                        <input type="text" name="first-name" id="first-name" class="form-control fs-12" style="border-color:black" placeholder="First Name" />
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'Zolve'">
                        <label class="form-label"><b>Lsst Name*</b></label>
                        <input type="text" name="last-name" id="last-name" class="form-control fs-12" style="border-color:black" placeholder="Last Name"  />
                    </div> -->
                    <div class="form-group col-md-6">
                        <label class="form-label"><b>Name*</b></label>
                        <input type="text" name="name" id="name" class="form-control fs-12" style="border-color:black" placeholder="Name" [(ngModel)]="accountRegisterModel.fullName" (keypress)="letterOnly($event)" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                        <label class="form-label"><b>Date of Birth*</b></label>
                        <div class="input-group">
                            <input class="form-control fs-12" name="dob" style="border-color:black" ngbDatepicker #basicDP1="ngbDatepicker" placeholder="Date of Birth" [(ngModel)]="dateofBirth" required [maxDate]="dobMaxDate" [minDate]="{year: 1900, month: 1, day: 1}">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP1.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                        <label class="form-label"><b>Gender*</b></label>
                        <select id="gender" name="gender" class="form-control fs-12" style="border-color:black" [(ngModel)]="accountRegisterModel.gender">
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label"><b>Address*</b></label>
                        <input type="text" name="address" id="address" class="form-control fs-12" style="border-color:black" placeholder="Address" [(ngModel)]="accountRegisterModel.address" />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label"><b>Email id*</b></label>
                        <input type="text" name="emailid" id="emailid" class="form-control fs-12" style="border-color:black" placeholder="Email ID" [(ngModel)]="accountRegisterModel.email" />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label"><b>Mobile No*</b></label>
                        <input type="text" name="mobileno" id="mobileno" class="form-control fs-12" style="border-color:black" placeholder="Mobile No" [(ngModel)]="accountRegisterModel.mobile" maxlength="10" pattern="^[1-9][0-9]*$" required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            (keypress)="numberOnly($event)" />
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'GIC' || pageTitle == 'Blocked'">
                        <label class="form-label"><b>Nationality*</b></label>
                        <input type="text" name="nationality" id="nationality" class="form-control fs-12" style="border-color:black" placeholder="Nationality" value="INDIAN" readonly />
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'GIC' || pageTitle == 'Blocked'">
                        <label class="form-label"><b>Passport no*</b></label>
                        <input type="text" name="passportNo" id="passportNo" class="form-control fs-12" style="border-color:black" placeholder="Passport No" (keypress)="alphaNumberOnly($event)" [(ngModel)]="accountRegisterModel.passportNumber" />
                    </div>
                    <div class="form-group col-md-6" *ngIf="pageTitle == 'GIC' || pageTitle == 'Blocked'">
                        <label class="form-label"><b>PAN no*</b></label>
                        <input type="text" name="panNo" id="panNo" class="form-control fs-12" style="border-color:black" placeholder="PAN No" (keypress)="alphaNumberOnly($event)" [(ngModel)]="accountRegisterModel.panNumber" />
                    </div>

                    <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':showCheckedMarkForPassportFront, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPassportFront}" *ngIf="pageTitle == 'Zolve'">
                        <label for="basicInputFile"><b>Passport Photo Page*</b></label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadLocalDocument($event,'PASSPORT_PHOTO_PAGE')" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="col-md-2 col-3" *ngIf="showCheckedMarkForPassportFront">
                        <div class="input-group-append mt-23" style="height: 38px;">
                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            <span class="input-group-text" style="cursor: pointer;"><img
                                src="assets/images/icon/view-document.png"
                                style="width:20px;width:20px"
                                (click)="downloadUploadedDoc('PASSPORT_PHOTO_PAGE')"></span>

                        </div>
                    </div>

                    <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':showCheckedMarkForPassportBack, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPassportBack}" *ngIf="pageTitle == 'Zolve'">
                        <label for="basicInputFile"><b>Passport Address Page*</b></label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadLocalDocument($event,'PASSPORT_ADDRESS_PAGE')" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="col-md-2 col-3" *ngIf="showCheckedMarkForPassportBack">
                        <div class="input-group-append mt-23" style="height: 38px;">
                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            <span class="input-group-text" style="cursor: pointer;"><img
                                src="assets/images/icon/view-document.png"
                                style="width:20px;width:20px"
                                (click)="downloadUploadedDoc('PASSPORT_ADDRESS_PAGE')"></span>
                        </div>
                    </div>

                    <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':showCheckedMarkForVisa, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForVisa}" *ngIf="pageTitle == 'Zolve'">
                        <label for="basicInputFile"><b>Visa*</b></label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadLocalDocument($event,'VISA')" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="col-md-2 col-3" *ngIf="showCheckedMarkForVisa">
                        <div class="input-group-append mt-23" style="height: 38px;">
                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            <span class="input-group-text" style="cursor: pointer;"><img
                                src="assets/images/icon/view-document.png"
                                style="width:20px;width:20px"
                                (click)="downloadUploadedDoc('VISA')"></span>
                        </div>
                    </div>

                    <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9 mt-20':showCheckedMarkForPANCard, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12 mt-20':!showCheckedMarkForPANCard}" *ngIf="pageTitle == 'Zolve'">
                        <label for="basicInputFile"><b>Pan Card*</b></label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadLocalDocument($event,'PAN_CARD')" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="col-md-2 col-3" *ngIf="showCheckedMarkForPANCard">
                        <div class="input-group-append mt-23" style="height: 38px;">
                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            <span class="input-group-text" style="cursor: pointer;"><img
                                src="assets/images/icon/view-document.png"
                                style="width:20px;width:20px"
                                (click)="downloadUploadedDoc('PAN_CARD')"></span>
                        </div>
                    </div>

                    <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9 mt-20':showCheckedMarkForI20, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12 mt-20':!showCheckedMarkForI20}" *ngIf="pageTitle == 'Zolve'">
                        <label for="basicInputFile"><b>i-20 form*</b></label>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="customFile" style="border-color:black" (change)="fileUploadLocalDocument($event,'I_20')" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="col-md-2 col-3" *ngIf="showCheckedMarkForI20">
                        <div class="input-group-append mt-23" style="height: 38px;">
                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            <span class="input-group-text" style="cursor: pointer;"><img
                                src="assets/images/icon/view-document.png"
                                style="width:20px;width:20px"
                                (click)="downloadUploadedDoc('I_20')"></span>
                        </div>
                    </div>

                    <div class="col-md-12 col-9 mb-20 text-right mt-20 ">
                        <button type="submit" rippleEffect class="btn btn-primary  fs-12" (click)="submitForm()">Submit</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>