<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-12">
                        <h5 class="content-header-title float-left mb-0 fs-13">
                            Add Bank Account
                        </h5>
                    </div>
                </div>
                <section>
                    <form class="form-horizontal" #AddBankAccount="ngForm">
                        <div class="row mt-15">
                            <div class="form-group col-md-6">
                                <label class="form-label fs-11" for="country">Bank Name*</label>
                                <input required type="text" class="form-control fs-12" placeholder="Bank Name" name="bank_name" />
                                <!-- <span *ngIf="AddBankAccount.submitted && bankNameRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="bankNameRef.errors.required">This field is required!</small>
                                </span> -->
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label fs-11">IFSC Number</label>
                                <input type="text" class="form-control fs-12" name="ifscCode" placeholder="IFSC Number" />
                                <!-- <span *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="ifscCodeRef.errors.required">This field is required!</small>
                                </span> -->
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label fs-11">Branch Name</label>
                                <input type="text" class="form-control fs-12" name="ifscCode" placeholder="Branch Name" />
                                <!-- <span *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="ifscCodeRef.errors.required">This field is required!</small>
                                </span> -->
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label fs-11">Account Number</label>
                                <input required type="text" class="form-control fs-12" placeholder="Account Number" name="account_no" />
                                <!-- <span *ngIf="AddBankAccount.submitted && accountNoRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="accountNoRef.errors.required">This field is required!</small>
                                </span> -->
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label fs-11">Confirm Account Number</label>
                                <input required type="text" name="confirm_acccount_no" class="form-control fs-12" placeholder="Confirm Account Number" />
                                <!-- <span *ngIf="AddBankAccount.submitted && confirmAccountNoRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="confirmAccountNoRef.errors.required">This field is required!</small>
                                </span> -->
                            </div>
                        </div>

                        <div class="row mb-20 mt-20">
                            <div class="col-md-3 col-3">
                                <button type="submit" rippleEffect class="btn btn-outline-secondary fs-12">Back</button>
                            </div>
                            <div class="col-md-9 col-9 text-right">
                                <button type="submit" rippleEffect class="btn btn-primary fs-12">Add Account</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</div>