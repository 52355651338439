export class AddBankAccountRequest {
        bankName: string;
        default: true;
        number: string;
        confirmAccNo: string;
        ifscCode: string;
        type: string;
        branch: string;
        isDefault: true;
        consumerId: string;
        nameAsPerAccountNumber: string;
        mobileNo: string;
        relation: string;
}

export class VerifyBankAccountRequest {
        beneficiaryAccountNo: string;
        beneficiaryAccountIFSC: string;
        bankId: string;
        mobile: string;
        name: string;
}