import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class InsuranceService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  getFilteredConsumerTravelInsuranceOrderList(startDate, endDate) {
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    var orderURL = 'travelInsuranceMyOrders?consumerId=' +
      consumerId +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate;
    return this.makeGetCall(orderURL);
  }

  submitInsuranceForm(requestModal) {
    var resourceURL = "b2c/travelInsuranceForm";
    return this.makePostCall(resourceURL, requestModal);
  }

  getInsurancePlans(ageLimit, dayLimit, companyName, planName, sumInsured, isStudent,insuranceOrderId) {
    var url = "b2c/premiumPlan?ageLimit=" + ageLimit + "&dayLimit=" + dayLimit + "&companyName=" + companyName + "&planName=" + planName + "&sumInsured=" + sumInsured + "&isStudent=" + isStudent + "&insuranceOrderId=" + insuranceOrderId;
    return this.makeGetCall(url);
  }

  getTravelInsuranceCartDetails(insuranceOrderId) {
    var url =
      "b2c/getCartTravelInsuranceOrder?insuranceOrderId=" + insuranceOrderId;
    return this.makeGetCall(url);
  }

  insuranceCartPlaceOrder(bankAccountId, paymentMode, insuranceOrderId) {
    var resourceUrl =
      "b2c/cartPlaceInsuranceOrder?bankAccountId=" + bankAccountId + "&paymentMode=" + paymentMode + "&insuranceOrderId=" + insuranceOrderId;
    return this.makePostCall(resourceUrl, {});
  }

  insuranceCartPlaceOrderNEFT(paymentMode, insuranceOrderId, amount) {
    var resourceUrl = "b2c/insuranceCartPlaceOrder";
    return this.makePostCall(resourceUrl, {
      "paymentMode": paymentMode,
      "cartId": insuranceOrderId,
      "verificationStatus": "UNVERIFIED",
      "amount": amount
    });
  }

  buyTravelInsurancePremium(requestModel) {
    var serviceURL = "travelInsuranceCartOrder";
    return this.makePostCall(serviceURL, requestModel);
  }

  insuranceGetPaymentGatewayLink(cashfreeorderId) {
    var resourceUrl =
      "b2c/getInsurancePaymentGatewayLink?cashfreeorderId=" + cashfreeorderId;
    return this.makeGetCall(resourceUrl);
  }

  getTravelInsuranceCoverageDetails(planCode) {
    var serviceURL = "b2c/getPlanRiders?planCode=" + planCode;
    return this.makeGetCall(serviceURL);
  }

  getFiltersValueTravelInsuranceList(ageLimit, dayLimit) {
    var url = "b2c/getInsuranceFilters?ageLimit=" + ageLimit + "&dayLimit=" + dayLimit;
    return this.makeGetCall(url);
  }
}