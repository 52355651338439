import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { OfferRewardsService } from '../../services/offer-rewards.service';

@Component({
  selector: 'offer-coupons',
  templateUrl: './offer.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferComponent implements OnInit {
  public showLoader: boolean = false;
  public offersList : any;
  public noDataFound : any;

  constructor(
    public offerRewardsService: OfferRewardsService,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,
  ) { }

  ngOnInit() { 
    this.getOffersList();
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert("Offer code has been copied! ");
  }

  getOffersList() {
    this.showLoader = true;
    this.offerRewardsService.getOffers().subscribe(data => {
      this.showLoader = false;
      this.offersList = data.body;
      if(this.offersList.length == 0){
        this.noDataFound = true;
      }
    }, error => {
      this.toast.error(error.message);
    })
  }
}
