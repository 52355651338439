<div class="col-md-12 col-xl-12 col-sm-12 col-xs-12 col-lg-4">
    <table class=" table-product" style="border-radius: 5px;">
        <thead class="table-head">
            <tr>
                <th scope="col" class="table-row text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    Product
                </th>
                <th scope="col" class="table-row text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    Currency
                </th>
                <th scope="col" class="table-row text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    Fx Amount
                </th>
                <th scope="col" class="table-row text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    IBR
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let singleItem of orderDetails?.invoice?.invoiceItems">
                <td scope="col" class="table-col text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px; ">
                    {{singleItem?.product }}</td>
                <td scope="col" class="table-col text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    {{singleItem?.currencyCode}}
                </td>
                <td scope="col" class="table-col text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    {{singleItem?.currencyAmount}}
                </td>
                <td scope="col" class="table-col text-center fs-12" style="padding-top: 10px; padding-left: 7px; padding-right: 7px; padding-bottom: 10px;">
                    {{singleItem?.effectiveRate}}
                </td>
            </tr>
        </tbody>
    </table>
    <br>
    <div style="background: #f4f5f8;padding: 10px;border-radius: 5px;">
        <div class="invoice-sales-total-wrapper">
            <table class="table table-borderless  fs-12">
                <tbody>
                    <tr style="padding-top: 1px;">
                        <td class="thick-line text-black fw-600" colspan="3" style="padding-top: 1%;">
                            <strong>Amount :</strong>
                        </td>
                        <td class="thick-line text-right text-black fw-600 " style="padding-top: 1%;"> ₹ {{orderDetails?.invoice?.billableAmountString}}
                        </td>
                    </tr>
                    <tr style="padding-top: 1%;">
                        <td class="no-line " colspan="3" ><strong>Forex Margin :</strong></td>
                        <td class="no-line text-right">₹ {{forexMarginString}}</td>
                    </tr>
                    <tr style="padding-top: 1px;">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>Service Charges :
                            </strong>
                        </td>
                        <td *ngIf="!isSurrenderOrder" class="no-line text-right" style="padding-top: 1%;">₹ {{orderDetails?.invoice?.totalHandlingChargesString}}
                        <td *ngIf="isSurrenderOrder" class="no-line text-right" style="padding-top: 1%;">- ₹ {{orderDetails?.invoice?.totalHandlingChargesString}}

                        </td>
                    </tr>
                    <!-- <tr *ngIf="!isSurrenderOrder && !hideBeneficiaryDetails">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>Nostro Charges :
                            </strong>
                        </td>
                        <td class="no-line text-right" style="padding-top: 1%;">₹ {{orderDetails?.notstoChargesString}} </td>
                    </tr> -->
                    <tr>
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>GST :</strong>
                        </td>
                        <td *ngIf="!isSurrenderOrder" class="no-line text-right" style="padding-top: 1%;">₹ {{orderDetails?.invoice?.totalTaxesString}}
                        <td *ngIf="isSurrenderOrder" class="no-line text-right" style="padding-top: 1%;">- ₹ {{orderDetails?.invoice?.totalTaxesString}}

                        </td>
                    </tr>

                    <tr *ngIf="orderDetails?.redeemedPoints!=0.0">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>Redeemed Points :</strong>
                        </td>
                        <td  class="no-line text-right" style="padding-top: 1%;">₹
                            {{orderDetails?.redeemedPoints}}
                
                    </tr>
                    <tr *ngIf="orderDetails?.invoice?.totalDiscount!=0.0">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>Coupon Code :</strong>
                        </td>
                        <td class="no-line text-right" style="padding-top: 1%;">₹
                            {{orderDetails?.invoice?.totalDiscount}}
                    
                    </tr>

                    <tr *ngIf="!isSurrenderOrder">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>TCS  ({{
                                orderDetails?.tcs?.tcsPercentageString1
                                }}%):</strong>
                        </td>
                        <td class="no-line text-right" style="padding-top: 1%;">₹ {{this.orderDetails?.tcs?.tcsAmountString1}}
                        </td>
                    </tr>
                    <!-- <tr *ngIf="!isSurrenderOrder">
                        <td class="no-line " colspan="3" style="padding-top: 1%;">
                            <strong>TCS 2 ({{
                                orderDetails?.tcs?.tcsPercentageString2
                                }}%):</strong>
                        </td>
                        <td class="no-line text-right" style="padding-top: 1%;">₹ {{this.orderDetails?.tcs?.tcsAmountString2}}
                        </td>
                    </tr> -->
                    <tr style="border-top:1px solid #bdbcbc">
                        <td class="no-line " colspan="3" style="padding-top: 2%;">
                            <strong>Total Amount :</strong>
                        </td>
                        <td class="no-line text-right" style="padding-top: 2%;">
                            <strong>₹
                                {{orderDetails?.invoice?.totalBillAmountString}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="no-line " colspan="3" style="padding-top: 1%;" *ngIf="!isSurrenderOrder">
                            <strong>Amount Paid :</strong>
                        </td>
                        <td class="no-line " colspan="3" style="padding-top: 1%;" *ngIf="isSurrenderOrder" >
                            <strong>Payment Issued :</strong>
                        </td>
        
                        <td class="no-line text-right" style="padding-top: 1%;">
                            <strong>₹
                                {{orderDetails?.invoice?.totalPaymentReceivedString}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="no-line fw-800" colspan="3" style="color: #1d2a62; padding-top: 0%;">
                            Balance : </td>
                        <td class="no-line text-right fw-800" style="color: #1d2a62; padding-top: 0%;"> ₹ {{this.orderDetails?.invoice?.totalPaymentPendingString}}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
    <br>
</div>