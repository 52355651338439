import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RequestLoginModel } from '../../models/login.models';
import { ToastrService } from 'ngx-toastr';
import * as shajs from 'sha.js';
import { ForgotPwdService } from '../../services/forgot-pwd.service';
import { RequestModelForForgetPwd } from '../../models/forgot-pwd.model';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [ForgotPwdService],
})
export class ForgotPasswordComponent implements OnInit {
  public coreConfig: any;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  public requestLoginModel = new RequestLoginModel();
  public forgetPwdForm: FormGroup;
  public loginWithPassswordForm: FormGroup;

  public showLoader: boolean = false;
  public toast: ToastrService
  public showLoginWithPassword: boolean = false;
  public requestModelForForgetPwd = new RequestModelForForgetPwd();
  private _unsubscribeAll: Subject<any>;
  public readonlyFields: boolean = true;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _route: ActivatedRoute,
    private _router: Router,
    private forgetService: ForgotPwdService,
    public _formBuilder: FormBuilder,
    public toastr: ToastrService
  ) {
    localStorage.clear();

    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };

  }

  get f() {
    return this.forgetPwdForm.controls;
  }



  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }


  ngOnInit(): void {
    this.forgetPwdForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      otp: ['', Validators.required],
      newPassword: ['', Validators.required]
    });

    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }



  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getOTPForForgetPwd() {
    if (this.requestModelForForgetPwd.id && this.requestModelForForgetPwd.id != null && this.requestModelForForgetPwd.id != '') {
      if (this.requestModelForForgetPwd.id.toString().length == 10) {
        this.requestModelForForgetPwd.id = this.requestModelForForgetPwd.id;
        this.forgetService.getOTP(this.requestModelForForgetPwd).subscribe((resp => {
          this.readonlyFields = false;
          this.toastr.success("OTP sent to registered Mobile Number");
        }), error => {
          this.toastr.error(error.error.message);
        });
      } else {
        this.error = 'Mobile no should be 10 characters only';
      }
    } else {
      this.toastr.error('Please enter valid mobile no.');
    }

  }

  onSubmit() {
    this.showLoader = true;
    this.error = '';
    this.requestModelForForgetPwd.authType = "MOBILE";
    this.requestModelForForgetPwd.newPassword = shajs('sha256').update(this.requestModelForForgetPwd.newPassword).digest('hex') + '@1';

    console.log(JSON.stringify(this.requestModelForForgetPwd));
    if (this.requestModelForForgetPwd.otp.toString().length == 6) {
      this.forgetService.resetPasswordWithOtp(this.requestModelForForgetPwd).subscribe(resp => {
        this.showLoader = false;
        var respBody = JSON.parse(JSON.stringify(resp.body));
        this.toastr.success('Password reset successfuly');
        // localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
        // localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
        // localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
        this._router.navigate(['/login']);
      }, error => {
        this.showLoader = false;
        this.error = error.error.message;
      });
    } else {
      this.showLoader = false;
      this.error = "OTP must be 6 characters long";
    }
  }
}
