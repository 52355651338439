import { Component, OnInit, VERSION } from '@angular/core'
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequestLoginModel } from '../../models/login.models';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { SignUpService } from '../../services/signup.service';
import { RequestConsumerOtpSignUp } from '../../models/signup.models';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from 'app/shared/constant/constants-keys';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../login-with-otp/login-with-otp.component.scss'],
  providers: [LoginService, SignUpService],
})
export class SignUpComponent implements OnInit {
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;

  public passwordTextType: boolean = false;
  public requestLoginModel = new RequestLoginModel();
  public requestConsumerOtpSignUp = new RequestConsumerOtpSignUp();
  public showLoader = false;
  name = 'Angular ' + VERSION.major;

  constructor(
    private _coreConfigService: CoreConfigService,
    private signUpService: SignUpService,
    private _router: Router,
    public toast: ToastrService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("[a-zA-Z][a-zA-Z ]+");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit(data) {
    if (data === true) {
      this.showLoader = true;
      this.signUpService.signUpWithOtp(this.requestConsumerOtpSignUp).subscribe(respData => {
        this.showLoader = false;

        var respBody = JSON.parse(JSON.stringify(respData.body));

        localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, respData.headers.get('X-AUTH-TOKEN'));
        localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
        localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
        localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

        localStorage.setItem(ConstantsKey.AGENT_ID, "WSFx-agent");
        localStorage.setItem(ConstantsKey.AGENT_KEY, "kjkjafhkhds-agkjag-099");

        //this._router.navigate(['/home']);
        this._router.navigate(['/new-dashboard']);
        this.toast.success("Registered successfully!!");
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }
  }

  ngOnInit() {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  getOTPForSignUp() {
    this.signUpService.getMobileOTPSignUp(this.requestConsumerOtpSignUp.mobile, this.requestConsumerOtpSignUp.email).subscribe((resp => {
      this.toast.success("OTP sent to registered Mobile Number and Email Id");
    }), error => {
      this.toast.error(error.error.message);
    });
  }
}
