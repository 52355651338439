import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UpdateProfileModel } from "app/modules/update-profile/models/update-profile.model";
import { UpdateProfileService } from "app/modules/update-profile/services/update-profile.service";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { ToastrService } from "ngx-toastr";

 

@Component({
  selector: 'passport-data-form',
  templateUrl: './passport-data-form.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PassportDataFormComponent implements OnInit {
  
  public showCheckedMark : boolean = false;
  public showCheckedMarkForPassportBack : boolean = false;
  public passportIssueMaxDate : any;
  public passportExpiryMinDate : any;

  public filePassportFront: File;
  public filePassportBack: File;
  public passportBackUrl: any;
  public passportFrontUrl: any;
  public formData: FormData = new FormData();
  public passportIssueDate: NgbDateStruct;
  public passportExpiryDate: NgbDateStruct;
  public passportIssueMinDate : any; 
  public passportExpiryMaxDate: any;
  public showLoader: boolean = false;
  public updateProfileModel = new UpdateProfileModel;
  public consumerDetail : any;

  constructor(
    private router: Router,
    public toast: ToastrService,
    public updateProfileService: UpdateProfileService,
  ) { 
  }

  ngOnInit() {
    this.passportIssueMaxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.passportIssueMinDate = { year: new Date().getFullYear()-10, month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.passportExpiryMinDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.passportExpiryMaxDate = { year: new Date().getFullYear()+10, month: new Date().getMonth() + 1, day: new Date().getDate() }
   }

   

   fileUploadPaxDocument(event, documentType) {
    this.showLoader = true;
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "PAX");
        formData.append('type', documentType);
        formData.append('description', "");
        formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
        formData.append('documentPurpose', "ADDRESS_PROOF");
        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.updateProfileService.paxDocumentUploadUpdate(formData).subscribe((respDoc) => {
            this.showLoader = false;
            if (documentType == 'PASSPORT_ADDRESS_PAGE') {
              this.showCheckedMarkForPassportBack = true;
              this.filePassportBack = fileList[0];
            } else {
              this.showCheckedMark = true;
              this.filePassportFront = fileList[0];
            }
            var localDocList = JSON.parse(JSON.stringify(respDoc.body));
            localDocList.forEach((localDocListItems) => {
              if (localDocListItems.type == 'PASSPORT_ADDRESS_PAGE') {
                this.passportBackUrl = localDocListItems.location;
              } else if (localDocListItems.type == 'PASSPORT_FRONT_PAGE') {
                this.passportFrontUrl = localDocListItems.location;
              }
            });

            if(this.filePassportBack && this.filePassportFront){
              this.doOcrPassport();
            }
            this.toast.success('Document uploaded successfully!');
          }, (error) => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
        this.showLoader = false;
      }
    } else {
      console.log("Hello I m in error last,", this.showLoader);
      this.showLoader = false;
    }
  }

  doOcrPassport() {
    this.showLoader = true;
    this.formData = new FormData();
    this.formData.append('passportFront', this.filePassportFront, this.filePassportFront.name);
    this.formData.append('passportBack', this.filePassportBack, this.filePassportBack.name);

    this.updateProfileService.passportOCR(this.formData).subscribe(result => {
      let response = result.body;
      this.showLoader = false;

      if (response != null && response['response']['result']['passportNumber'] != null &&
        response['response']['result']['passportNumber'].length > 0) {
        this.updateProfileModel.passportNumber = response['response']['result']['passportNumber'];

        this.updateProfileModel.passportFileNumber = response['response']['result']['fileNumber'];
        this.updateProfileModel.passportIssuePlace = response['response']['result']['placeOfIssue'];
        this.updateProfileModel.birthCity = response['response']['result']['placeOfBirth'];

        this.updateProfileModel.passportExpiryDate = response['response']['result']['expiryDate'];
        this.updateProfileModel.passportIssueDate = response['response']['result']['issueDate'];

        this.passportExpiryDate = { 'year': parseInt(this.updateProfileModel.passportExpiryDate.split("/")[2]),'month': parseInt(this.updateProfileModel.passportExpiryDate.split("/")[1]), 'day': parseInt(this.updateProfileModel.passportExpiryDate.split("/")[0])};
         
        this.passportIssueDate = { 'year': parseInt(this.updateProfileModel.passportIssueDate.split("/")[2]),'month': parseInt(this.updateProfileModel.passportIssueDate.split("/")[1]), 'day': parseInt(this.updateProfileModel.passportIssueDate.split("/")[0])};
        
        console.log("Expiry Date >>>>" ,this.passportExpiryDate);
        console.log("Issue Date >>>>" ,this.passportIssueDate);
        this.updateProfileModel.passportExpiryDate= this.getProperFormatedDate(this.passportExpiryDate);
        this.updateProfileModel.passportIssueDate= this.getProperFormatedDate(this.passportIssueDate);

        this.updateProfileModel.address = response['response']['result']['address'];
        this.toast.success("Yay! Your Passport has been uploaded successfully!");
        console.log("passport model>>"+this.updateProfileModel);
        this.updatePaxDetails();
      } else {
        this.toast.error('Please upload valid passport documents!');
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
  getProperFormatedDate(ngbDateStruct) {
    var returnDate = ngbDateStruct.year;
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.month).slice(-2);
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.day).slice(-2);
    return returnDate;
  }
  updatePaxDetails(){
    var paxId=localStorage.getItem(ConstantsKey.PAXID);
    this.updateProfileService.updateProfileDetails(paxId, this.updateProfileModel).subscribe(async resp => {
      console.log(resp.body);
     
    }, error => {
      if (error.status == 200) {
        sessionStorage.setItem('PASSPORT_AVAILABLE', 'true');
      } else {
        //this.toast.error('Something went wrong ! Please try again !');
      }
    });
  }
 
  downloadUploadedImage(docType){
    if(docType == 'PASSPORT_FRONT_PAGE'){
      window.open(this.passportFrontUrl, "_blank");
    }else if(docType == 'PASSPORT_BACK_PAGE'){
      window.open(this.passportBackUrl, "_blank");

    }

  }
}