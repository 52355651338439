import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ToastrService } from 'ngx-toastr';
import { ISICRegisterModel } from '../../models/isic-card.model';
import { ISICCardService } from '../../services/isic-card.service';

@Component({
  selector: 'isic-card-application',
  templateUrl: './isic-card-application.component.html',
  // styleUrls: ['../../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IsicCardApplicationComponent implements OnInit {

  public isicRegisterModel = new ISICRegisterModel();
  public genderListItems = Constants.genderListItems;
  public showLoader: boolean = false;
  public countryListItems: any;
  public consumerDetails: any;
  public maxDate: any;
  public minDate: any;
  public consumerDetail: any;
  public dateofBirth: NgbDateStruct;

  constructor(
    public masterDataService: MasterDataService,
    public isicCardService: ISICCardService,
    public toast: ToastrService,
    public router: Router,
    public updateProfileService: UpdateProfileService,
  ) {
    this.getCountryList();
    this.getProfileDetails();
  }

  ngOnInit() {
    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.minDate = { year: 1950, month: 1, day: 1 };
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    }, error => {
    });
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.consumerDetail = resp.body;
      this.isicRegisterModel.mobile = this.consumerDetail.paxResponse.mobile;
      this.isicRegisterModel.fullName = this.consumerDetail.paxResponse.name;
      this.isicRegisterModel.email = this.consumerDetail.email;
      this.isicRegisterModel.purposeOfVisit = 'Student Travel';
      this.isicRegisterModel.nationality = 'INDIAN';
    });
  }

  applyForISIC(data) {
    if (this.setISICFormValidation()) {
      if (data == true) {
        this.showLoader = true;
        this.isicRegisterModel.formType = 'ISIC';
        this.isicRegisterModel.dob = DateUtils.getNgbDateStructToDate(this.dateofBirth);
        this.isicCardService.isicRegister(this.isicRegisterModel).subscribe((resp => {
          this.showLoader = false;
          this.toast.success("Successfully applied for ISIC card", "Success !!");
          this.router.navigate(['/isicCard']);
        }), error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      }
    }
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }


  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("[a-zA-Z][a-zA-Z ]+");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  validatePassportNumber(passportNo) {
    const regex = /^[A-PR-WYZ][1-9]\d\s?\d{4}[0-9]$/;
    if (!regex.test(passportNo)) {
      return false;
    } else {
      return true;
    }
  }

  restrictSepcialCharacters(event) {
    var charCode = event.keyCode;
    if ((charCode > 96 && charCode < 123) || (charCode > 64 && charCode < 91) || (charCode >= 48 && charCode <= 57) || charCode <= 31)
      return true;
    else return false;
  }


  validateEmailId(emailId) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(emailId)) {
      return false;
    } else {
      return true;
    }
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }

  setISICFormValidation() {
    if (!this.validateEmailId(this.isicRegisterModel.email)) {
      this.toast.error("Email id is not valid!");
      return false;
    }

    if (!this.validatePassportNumber(this.isicRegisterModel.passportNumber)) {
      this.toast.error("Please enter valid passport no!");
      return false;
    }

    if (!this.validatePANNo(this.isicRegisterModel.pan)) {
      this.toast.error("Please enter valid PAN no!");
      return false;
    }

    return true;
  }
}
