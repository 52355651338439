<ng-container *ngIf="!item.hidden">
    <!-- item.url -->
    <a class="dropdown-item d-flex align-items-center" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl" [routerLink]="[item.url]" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="dropdown-item d-flex align-items-center" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl" [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <img class="icon" [src]="item.image" width="17px" height="17px" *ngIf="item.image"/>&nbsp;
        <span class="icomoon" [ngClass]="item.icon" *ngIf="item.icon"></span>
        <span [translate]="item.translate">{{ item.title }}</span>

    </ng-template>
</ng-container>