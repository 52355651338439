import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { TransferBalanceModel, TransferBalanceToWalletModel } from '../../models/card.model';
import { ConstantsKey } from 'app/shared/constant/constants-keys';

@Component({
  selector: 'transfer-to-inr-wallet',
  templateUrl: './transfer-to-inr-wallet.component.html',
  styleUrls: [ '../../../remittance/stepper.scss', './transfer-to-inr-wallet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransferToINRWalletComponent implements OnInit {
  public passwordTextType: boolean;
  public currencyItems: any;
  public isShowInvoiceBox: boolean = false;

  public cardDetails: any;
  public isDataLoaded: boolean = false;
  public showLoader: boolean = false;
  public currencyItemsList = [];
  public currencyDetails: any;
  public selectedCurrencyCode: any;

  public isChecked: boolean = false;
  public transferBalanceModel = new TransferBalanceModel();
  public transferBalanceToWalletModel = new TransferBalanceToWalletModel();
  public amountToBeTransfer: any;
  public convertCurrencyRate: any;
  public rateRange: any;
  public cardWalletListItems: any;
  public transferableAmount: any;
  public consumerDetails: any;
  public otpForTransferBalance: any;

  public product: any;
  public currencyCode: any;
  public partnerId: any;
  public sellType: any;
  public toCurrencyCode = ['INR'];

  constructor(
    private cardService: CardService,
    private router: Router,
    public toast: ToastrService,
    public activateRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getCardDetails();
    this.activateRoute.queryParams.subscribe(params => {
      this.currencyDetails = params;
      this.currencyCode = this.currencyDetails.currencyCode;
      var consumerData = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
      this.consumerDetails = JSON.parse(consumerData);
    });
  }

  getCardDetails() {
    this.showLoader = true;

    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      this.showLoader = false;

      this.isDataLoaded = true;
      if (responseBody.length > 0) {
        this.cardDetails = responseBody[0];
        this.cardWalletListItems = this.cardDetails.cardWallet;

        // this.currencyCode = this.cardWalletListItems.currencyCode;
        // var CurrencyListArray = [];
        // CurrencyListArray = this.cardDetails.cardWallet.currencyCode;
        // console.log(CurrencyListArray);

        // for(let singleItem of this.cardDetails.cardWallet) {
        //   this.currencyItemsList.push(singleItem.currencyCode);
        // }
        // this.currencyItems = this.currencyItemsList;
      } else {
        this.router.navigate(['/new-dashboard'])
      .then(() => {
      window.location.reload();
       });
      }
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });;
  }

  showInvoiceBoxCard(value, transferableAmount) {
    this.isShowInvoiceBox = true;
    this.transferableAmount = transferableAmount;
    this.cardService.getCurrencyConversionRateList(this.currencyCode, this.selectedCurrencyCode, transferableAmount).subscribe(resp => {
      this.convertCurrencyRate = resp.body;
    })
  }
  otpRequestForBalanceTransfer() {
    if (this.isChecked) {
      this.transferBalanceModel.authType = 'MOBILE';
      this.transferBalanceModel.id = this.consumerDetails.mobile;
      this.transferBalanceModel.otpRequestReason = 'WALLET_TRANSFER_OTP';
      this.cardService.otpRequestForBalanceTransfer(this.transferBalanceModel).subscribe(resp => {
        this.toast.success("OTP send successfully");
      }, error => {
        this.toast.error(error.error.message);
      });
    }
  }

  checkCheckBoxvalue(isChecked) {
    if (isChecked) {
      this.isChecked = isChecked;
      this.otpRequestForBalanceTransfer();
    }
  }

  getSelectedCurrencyCode(selectedCurrencyCode) {
    console.log(selectedCurrencyCode);
    this.product = 'FOREXCARD';
    this.selectedCurrencyCode = selectedCurrencyCode;
    this.partnerId = 'p_wsfxConsumer';
    this.sellType = 'SELL';
    this.cardService.getCurrencyRateList(selectedCurrencyCode).subscribe(resp => {
      this.rateRange = resp.body;
    })
  }

  amountChanges(value) {
    // this.cardService.getCurrencyConversionRateList(value).subscribe(resp=>{
    // })
  }

  transferBalance(amount, otp) {
    if (this.isChecked) {
      this.transferBalanceToWalletModel.fromCurrencyCode = this.currencyCode;
      this.transferBalanceToWalletModel.toCurrencyCode = this.selectedCurrencyCode;
      this.transferBalanceToWalletModel.amount = amount;
      this.transferBalanceToWalletModel.agreement = 'true';
      this.transferBalanceToWalletModel.otp = otp;

      this.cardService.walletToWalletTransfer(this.transferBalanceToWalletModel).subscribe(resp => {
        this.toast.success("Balance Transfer successfully", "Success !!");
        this.router.navigate(['/my-card']);
      }, error => {
        this.toast.error(error.error.message);
      });
    }
  }

}
