<div class="content-wrapper row  container-xxl p-0 ml-0 mr-0">
    <div class="content-body col-md-12">
        <div class="card">
            <div class="card-body">
                <loader *ngIf="showLoader"></loader>
                <h4 class="fw-600 text-black mb-40 text-center mt-20 fs-16">Ratings</h4>
                <!-- <img src="assets/images/illustration/ratingImg.png" class="rating-img mb-30"> -->
                <div class="text-center">
                    <div class="fw-600 text-black mt-10 fs-13">Onboarding Experience</div>
                    <div class="rating mb-10">
                        <ngb-rating [(rate)]="requestOrderFeedbackModel.onboarding" [max]="5">
                            <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span></ng-template>
                        </ngb-rating>
                    </div>
                    <hr>

                    <div class="fw-600 text-black mt-10 fs-13">Transaction Experience</div>
                    <div class="rating mb-10">
                        <ngb-rating [(rate)]="requestOrderFeedbackModel.transaction" [max]="5">
                            <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span></ng-template>
                        </ngb-rating>
                    </div>
                    <hr>

                    <div class="fw-600 text-black mt-10 fs-13">Transparency</div>
                    <div class="rating mb-10">
                        <ngb-rating [(rate)]="requestOrderFeedbackModel.transparency" [max]="5">
                            <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span></ng-template>
                        </ngb-rating>
                    </div>
                    <hr>

                    <div class="fw-600 text-black mt-10 fs-13">Customer Support</div>
                    <div class="rating mb-10">
                        <ngb-rating [(rate)]="requestOrderFeedbackModel.support" [max]="5">
                            <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span></ng-template>
                        </ngb-rating>
                    </div>
                    <hr>

                    <div class="fw-600 text-black mt-10 fs-13">Overall Experience</div>
                    <div class="rating mb-10">
                        <ngb-rating [(rate)]="requestOrderFeedbackModel.overall" [max]="5">
                            <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span></ng-template>
                        </ngb-rating>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="col-md-12 text-center mt-20">
                            <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="submitOrderRating()">Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-modal #vcipCompleteAfterOrder>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body  fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="font-bold-black  mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p class="font-normal-black">Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p class="font-bold-red">Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="font-bold-black  mb-20">You can complete your video KYC from Monday to Saturday between 09:30 am IST till 07:00 pm IST</p>

                <p class="font-normal-blue mb-20 ">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>