import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class SsoService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    getDataForSso(requestId: string) {
        var resourceUrl = "wsfx/redirectionData?id="+requestId;
        return this.makeGetCall(resourceUrl);
    }

    getDataFromToken(token: string) {
        var resourceUrl = "getConsumerAuthFromToken?authToken="+token;
        return this.makeGetCall(resourceUrl);
    }
    
    getConsumerProfileData() {
        var resourceUrl = "consumers/profile";
        return this.makeGetCall(resourceUrl);
    }


}