import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "app/shared/constant/constants";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { RequestOrderFeedbackModel } from "../models/order.model";

@Injectable()
export class OrderService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    getListOrderShortSnippets() {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'orderShortSnippets?consumerId=' + consumerId + '&partnerId=' + Constants.partnerId + '&scope=FULL';
        return this.makeGetCall(resourceUrl);
    }

    getPendingOrderList() {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'getIncompleteOrders?consumerId='  + consumerId;
        return this.makeGetCall(resourceUrl);
    }

    proceedIncompleteOrder(iOrderId) {
        var resourceUrl = 'getIncompleteOrderToCart?iOrderId='  + iOrderId;
        return this.makeGetCall(resourceUrl);
    }

    getOrderDetailsSingle(orderId: string) {
        var resourceUrl = 'orders/' + orderId;
        return this.makeGetCall(resourceUrl);
    }
    downloadA2Form(orderId: string) {
        var resourceUrl = 'a2FormDownload/' + orderId;
        return this.makeGetCall(resourceUrl);
    }

    postOrderRating(request: RequestOrderFeedbackModel) {
        var resourceUrl = "orderFeedback";
        return this.makePostCall(resourceUrl, request);
    }

    updatePaymentDetails(request) {
        var resourceUrl = "consumerManualPayment";
        return this.makePostCall(resourceUrl, request);
    }

    getTransactionDetailsList(orderId) {
        var resourceUrl = 'transactions?orderId=' + orderId;
        return this.makeGetCall(resourceUrl);
    }

    getFilteredOrderList(startDate, endDate) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'orderShortSnippets?consumerId=' + consumerId + '&partnerId=' + Constants.partnerId + '&scope=FULL&startDate=' + startDate + '&endDate=' + endDate;
        return this.makeGetCall(resourceUrl);
    }

    getRetryPaymentGatewayLink(refOrderId: string) {
        var resourceUrl = "getPaymentGatewayLink?refOrderId=" + refOrderId;
        return this.makeGetCall(resourceUrl);
    }
    getRetryPaymentGatewayLinkOrders(refOrderId: string,bankId: string) {
        var resourceUrl = "getPaymentGatewayLink?refOrderId=" + refOrderId+"&bankId="+bankId;
        return this.makeGetCall(resourceUrl);
    }

    uploadTransactionProof(imageData) {
        return this.makeUploadCall("transactionProof", imageData);
    }
}