import { Component, OnInit, Input, } from '@angular/core';
import { Router } from '@angular/router';

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: boolean;
  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html'
})
export class ContentHeaderComponent implements OnInit {
  public dashboard: boolean = false;
  // input variable
  @Input() contentHeader: ContentHeader;

  constructor(public router: Router) {}

  ngOnInit() {

  }

}
