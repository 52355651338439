import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr'; 
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { RateAlertCreateRequestList, SetRateAlertRequestModel } from 'app/modules/set-rate-alert/model/set-rate-alert.model';
import { SetRateAlertService } from 'app/modules/set-rate-alert/services/set-rate-alert.service';

@Component({
  selector: 'enquiry-form',
  templateUrl: './enquiry-form.component.html',
  // styleUrls: ['../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnquiryComponent implements OnInit {
  public setRateAlertRequestModel: SetRateAlertRequestModel = new SetRateAlertRequestModel();
  public rateAlertCreateRequest: RateAlertCreateRequestList = new RateAlertCreateRequestList();
  // @ViewChild('SetRateAlertForm', { static: false }) SetRateAlertForm: NgForm;
  // @ViewChild('SetRateAlertFormForSell', { static: false }) SetRateAlertFormForSell: NgForm;
  public rateList: any;
  public showLoader: boolean = false;
  public countryListItems: any;
  public cityListItems = Constants.cityItemsForRateALert;
  public consumerDetail: any;
  public consumerFirstName: any;
  public consumerLastName: any;
  public consumerEmail: any;
  public consumerMobile: any;

  public forexCheckbox : any;
  public cashCheckbox:any;
  public sendMoneyCheckbox : any;
  public sellForexCheckbox : any;
  public sellCashCheckbox : any;
  public activeTab  = 'BUY';

  constructor(
    public setRateAlertService: SetRateAlertService,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,
    public updateProfileService: UpdateProfileService) {
      
  }

  ngOnInit() {
    this.getCountryList();
    this.getProfileList();
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
      
    }, error => {
    });
  }

  setRateAlert(data) {
    if(this.activeTab == 'BUY'){
      if(this.forexCheckbox || this.cashCheckbox || this.sendMoneyCheckbox ) {
        this.callFUnctionRateAlert(data);
      }else{
        this.toast.error('Please select checkbox');
      }
    }else if(this.activeTab == 'SELL'){
      if(this.sellForexCheckbox || this.sellCashCheckbox){
        this.callFUnctionRateAlert(data);
      }else{
        this.toast.error('Please select checkbox');
      }
    }
    

  }

  callFUnctionRateAlert(data){
    if (data === true) {
      this.showLoader = true;
      this.setRateAlertRequestModel.contactUsRequestType = 'RATE_ALERT';
      this.setRateAlertRequestModel.firstName = this.consumerFirstName;
      this.setRateAlertRequestModel.lastName = this.consumerLastName;
      this.setRateAlertRequestModel.email = this.consumerEmail;
      this.setRateAlertRequestModel.mobile = this.consumerMobile;

      console.log(JSON.stringify(this.setRateAlertRequestModel));
      this.setRateAlertService.setRateAlert(this.setRateAlertRequestModel).subscribe(resp => {
        this.showLoader = false;
        this.toast.success("Rate  alert added successfully");
        
        this.setRateAlertRequestModel.country = 'US';
        this.rateAlertCreateRequest.upperLimit = 0;
        this.rateAlertCreateRequest.lowerLimit = 0;
        // window.location.reload();
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }
  }

  changeTab(event) {
    if (event == 'SELL' || event == 'SELL') {
      this.setRateAlertRequestModel.country = null;
      this.rateAlertCreateRequest.upperLimit = null;
      this.rateAlertCreateRequest.lowerLimit = null;
    }
    if(event == 'SELL'){
      this.activeTab = 'SELL';
    }else{
      this.activeTab = 'BUY';
    }
  }

  getProfileList() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;

      this.consumerDetail = resp.body;

      this.consumerFirstName = this.consumerDetail.firstName;
      this.consumerLastName = this.consumerDetail.lastName;
      this.consumerEmail = this.consumerDetail.email;
      this.consumerMobile = this.consumerDetail.mobile;

      console.log(JSON.stringify(this.consumerDetail));

    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

}
