<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <loader *ngIf="showLoader"></loader>
                    <div class="col-md-12 mb-20">
                        <h6 class="content-header-title float-left mb-0" style="color: black"> Your saved bank accounts</h6>
                    </div>
                    <div class="col-md-12" *ngFor="let singleBank of bankAccountList;let i=index">
                        <div style="box-shadow: 0 0 0 0 !important" [ngClass]="(bankAccountList.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
                            <div class="row mt-20">
                                <div class="col-md-8 col-8 mb-10">
                                    <span class="card-title fs-13 fw-600 text-black">  {{singleBank.bankName}}</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-8 col-8">
                                    <span class="card-text fs-11 text-black">Account Number : </span>
                                    <span class="fs-11 text-black"> {{singleBank.number}}</span>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-8 col-8">
                                    <span class="card-text fs-11 text-black"> IFSC Number : </span>
                                    <span class="fs-11 text-black"> {{singleBank.ifscCode}}</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBank.branch != null && singleBank.branch != ''">
                                <div class="col-md-8 col-8">
                                    <span class="card-text fs-11 text-black">   Branch : </span>
                                    <span class="fs-11 text-black"> {{singleBank.branch}} </span>
                                </div>
                            </div>
                            <div class="row mb-20">
                                <div class="col-md-3  mt-10  col-6" *ngIf="singleBank.bankAccountVerificationStatus == 'UNVERIFIED' ">
                                    <button type="button" class="btn btn-outline-warning btn-prev fs-11" rippleEffect (click)="openModalBankVerification(singleBank,bankAccountVerificationModal)">
                                        <i class="icomoon icon-edit_mode_icon  "></i>
                                        Verify Now
                                    </button>
                                </div>
                                <div class="col-md-3 mt-10 col-6" *ngIf="singleBank.bankAccountVerificationStatus == 'VERIFIED' ">
                                    <button type="button" class="btn btn-outline-success btn-prev fs-11" rippleEffect>
                                        <!-- <i class="feather icon-check  "></i> -->
                                        Verified 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-20">
                        <button type="button" class="btn btn-primary btn-prev fs-11" routerLink="/add-bank-account" rippleEffect>
                                <span class="align-middle">Add Bank</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>




<ng-template #bankAccountVerificationModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">Bank Account Verification</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="fs-12 text-black fw-500 text-center mt-20">
                We have send a verification request to validate your bank account details.
            </div>
        </div>
        <button class="btn btn-primary btn-block col-md-2 offset-md-5  fs-12 mt-30" rippleEffect (click)="verifyBankAccount(bankDetails)">Okay</button>

    </div>
</ng-template>