<label class="text-danger mt-40 fs-8 fw-600">Rates will refresh after {{ progressWidth }} minutes </label>
<div class="progress">
    <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
</div>

<div class="table-responsive mt-15">
    <table class="table table-condensed table-borderless  fs-12">
        <tbody>
            <tr>
                <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                <td class="no-line text-right">₹ {{ stepperDataModel.orderDetails.invoice.billableAmountString }}
                </td>
            </tr>
            <tr>
                <td class="no-line " colspan="3"><strong>Service Charges</strong></td>
                <td class="no-line text-right">₹ {{ stepperDataModel.orderDetails.invoice.invoiceItems[0].nostroCharges}}</td>
            </tr>
            <tr>
                <td class="no-line " colspan="3"><strong>TCS ({{ stepperDataModel.orderDetails.tcs.tcsPercentageString }}%)</strong></td>
                <td class="no-line text-right">₹ {{ stepperDataModel.orderDetails.tcs.tcsAmountString }}</td>
            </tr>
            <tr class="border-bottom-grey">
                <td class="no-line " colspan="3"><strong>GST </strong></td>
                <td class="no-line text-right">₹ {{ stepperDataModel.orderDetails.invoice.totalTaxesString}}</td>
            </tr>

            <tr>
                <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                <td class="no-line text-right text-black fw-800 pt-10">₹ {{ stepperDataModel.orderDetails.invoice.totalBillAmountString}}
                </td>
            </tr>
        </tbody>
    </table>
</div>