import { Injectable } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { fileURLToPath } from "url";

@Injectable()
export class Utils {
  public static getSingleCountryByCountryCode(countryCode: string, countryListItems: any) {
    var returnSingleCountry: any;
    countryListItems.forEach(singleCountry => {
      if (singleCountry.countryCode == countryCode) {
        returnSingleCountry = singleCountry;
      }
    });
    return returnSingleCountry;
  }

  public static toTitleCase(str) {
    return str.replace(/\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  public static getLocalImage(imagePath) {
    var fileUrl: any;
    var reader = new FileReader();
    reader.readAsDataURL(imagePath);
    reader.onload = (event) => {
      fileUrl = event.target.result;
    }
    return fileUrl;
  }


  public static isIfscCodeValid(ifscCode) {
    var regExp = "^[A-Z]{4}[0][A-Z0-9]{6}$";
    let isvalid: boolean = false;

    if (ifscCode.length() > 0) {
      isvalid = ifscCode.matches(regExp);
    }
    return isvalid;
  }

  public static getFileExtension(fileUrl){
    var extension =  fileUrl.toString().split('.').pop();
    return extension;
  }
}