<div class="row mb-20">
    <div class="col-md-12">
        <h6 class="content-header-title float-left mb-0">
            Upload transaction documents
        </h6>
    </div>
    <div class="col-md-12 fs-11">

    </div>
</div>

<section class="row" *ngFor="let singleDocument of transactionDocListUploaded">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
        <div class="form-group">
            <label for="basicInputFile">{{singleDocument.title}}</label>
            <div class="custom-file">
                <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                <label class="custom-file-label" for="customFile">Choose file</label>
            </div>
        </div>
    </div>
    <div class="col-md-1 col-3" *ngIf="singleDocument.status == 'UPLOADED' ">
        <div class="input-group-append mt-23" *ngIf="true" style="height: 38px;">
            <span class="input-group-text link" (click)="uploadDownloaddocument(singleDocument)">
                <img src="assets/images/icon/view-document.png" class="w-h-20">
            </span>
            <span class="input-group-text link ml-7"><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
        </div>
    </div>
</section>

<section *ngFor="let singleDocument of transactionDocListAwaiting">
    <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
        <div class="col-md-6">
            <div class="form-group">
                <label for="basicInputFile">{{singleDocument.title}}</label>
                <div class="custom-file">
                    <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                    <label class="custom-file-label" for="customFile">Choose file</label>
                </div>
            </div>
        </div>
    </div>
</section>