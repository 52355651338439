import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { AddServiceRequestModel } from 'app/modules/support/models/support.model';
import { SupportService } from 'app/modules/support/services/support.service';
import { Constants } from 'app/shared/constant/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'raise-dispute-ticket',
  templateUrl: './raise-dispute-ticket.component.html',
  styleUrls: [
    './raise-dispute-ticket.component.scss',
      // '../../../../../@core/scss/angular/libs/form-wizard.component.scss' ,
      // '../../../../../assets/scss/select.component.scss'
  ],
})
export class RaiseDisputeTicketComponent implements OnInit {
  public raiseTicketItems = Constants.raiseTicketItems;
  public chatMessage : any;
  public messageDetails : any;
  public showLoader : boolean = false;
  public addServiceRequestModel = new AddServiceRequestModel();
  public raiseTicketDetails  : any;

  constructor(
    public supportService :  SupportService,
    public toast : ToastrService,
    public router :  Router,
    public route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.addServiceRequestModel.subject =  Constants.raiseTicketItems[0];
    this.route.queryParams.subscribe(params => {
      this.raiseTicketDetails = params;
    });

    this.chatMessage = 'Reference no. ' + this.raiseTicketDetails.txRef +  ' Amount ' + this.raiseTicketDetails.balance;
  }

  submitFeedback(data){
    if(data == true){
    this.showLoader = true;
    this.supportService.addFeedback(this.chatMessage, this.addServiceRequestModel.subject).subscribe((resp=>{
      this.showLoader = false;

      this.messageDetails = resp.body;
      this.chatMessage = '';
      this.toast.success('Dispute Ticket Raise submitted successfully!');
      this.router.navigate(['/listServiceRequest']);
    }),error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
    }
  }
}
