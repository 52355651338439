import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers: [UpdateProfileService],
  encapsulation: ViewEncapsulation.None
})
export class MyAccountComponent implements OnInit {
  public showLoader: boolean = false;
  public consumerDetail: any;
  public resGetVcipStatus: any;
  public isVcipComplete: boolean = false;
  public skipVcipStatus: boolean = false;


  public menuItems = [
    {
      'icon': 'fingerprint.png',
      'title': 'Reset Password',
      'url': 'security-setting',
    },
    {
      'icon': 'credit.png',
      'title': 'My Card',
      'url': 'my-card',
    },
    {
      'icon': 'bank.png',
      'title': 'My Bank Account',
      'url': 'list-bank-account',
    },
    {
      'icon': 'man.png',
      'title': 'Beneficiary',
      'url': 'list-beneficiary',
    },
    {
      'icon': 'carts.png',
      'title': 'My Orders',
      'url': 'order-list',
    },
    {
      'icon': 'address.png',
      'title': 'My Address',
      'url': 'list-address',
    },
    {
      'icon': 'ticket.png',
      'title': 'My Tickets',
      'url': 'listServiceRequest',
    },
    {
      'icon': 'twins.png',
      'title': 'My Relation',
      'url': 'list-relationship',
    },
    {
      'icon': 'carts.png',
      'title': 'My Policy',
      'url': 'insurance-list',
    },
  ];

  constructor(
    public router: Router,
    public updateProfileService: UpdateProfileService,
    public toast: ToastrService,
  ) { }

  ngOnInit() {
    this.getProfileList();
  }

  goToEditProfile() {
    this.router.navigate(['/update-profile']);
  }

  getProfileList() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      console.log("this.isVcipComplete >>", this.isVcipComplete);
      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      }
    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }

  proceedToNextPage(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  initiateVcip() {
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }
}
