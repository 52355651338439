<div class="content-wrapper row  container-xxl p-0">
    <div class="content-body col-md-12">
        <loader *ngIf="showLoader"> </loader>
        <div class="card invoice-preview-wrapper" *ngIf="orderDetails">

            <div class="card-body invoice-preview" *ngIf="((paymentMode == 'MANUAL' && (paymentStatus == 'COMPLETED' || paymentStatus == 'PENDING')) || (paymentMode == 'NB' && (paymentStatus == 'COMPLETED' || paymentStatus == 'PARTIAL')) || showSuccessContent)">
                <img src="assets/images/payment_successful.gif" class="success-gif">

                <div class="row">
                    <div class="col-md-10 offset-md-1 text-center mt-30">
                        <h3 class="text-black20 fw-600 fs-17">Thank You !</h3>
                        <div class="text-black fs-13">We have received your order details.</div>
                        <div class="text-black fs-13">We will inform you after confirmation.</div>
                    </div>
                </div>
                <hr class="invoice-spacing" />

                <div class="row">
                    <div class="col-md-3">
                    </div>

                    <div class="col-md-6 amout-breakup-bg">
                        <div class="p-15">
                            <div class="text-center text-black fw-600 mb-30">Order ID : {{ orderDetails?.orderId }}
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fw-600 fs-13 text-black">Amount :</div>
                                <div class="col-md-6 col-6 text-right fw-600 fs-13 text-black">₹ {{this.orderDetails?.invoice?.billableAmountString}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black"> Forex Margin :</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{forexMarginString}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black"> Service Charges : </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalHandlingChargesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">GST :</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalTaxesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">TCS : ({{this.orderDetails?.tcs?.tcsPercentageString1}}%):
                                </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.tcs?.tcsAmountString1}}
                                </div>
                            </div>
                            <!-- <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">TCS 2: ({{this.orderDetails?.tcs?.tcsPercentageString2}}%):
                                </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{ this.orderDetails?.tcs?.tcsAmountString2}}
                                </div>
                            </div> -->

                            <div class="row mb-10" *ngIf="orderDetails?.invoice?.totalDiscount != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Applied coupon code</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">- ₹ {{this.orderDetails?.invoice?.totalDiscount}}
                                </div>
                            </div>
                            <div class="row mb-10" *ngIf="orderDetails?.redeemedPoints != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Redeemed points</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">- ₹ {{this.orderDetails?.redeemedPoints}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 text-black">Total Amount :</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">₹ {{this.orderDetails?.invoice?.totalBillAmountString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 fw-600 text-black">Amount Paid :</div>
                                <div class="col-md-6 col-6 text-right fs-13 fw-600 text-black">
                                    ₹ {{this.orderDetails?.invoice?.totalPaymentReceived}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                    </div>
                    <div class="col-md-6 text-center mt-20">
                        <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="navigateToRating(VCIPModal)">Proceed</button>
                    </div>
                    <div class="col-md-3">
                    </div>
                </div>
            </div>

            <!-- <div class="card-body invoice-preview" *ngIf="paymentMode == 'NB' && paymentStatus == 'PENDING'">

                <img src="assets/images/failure.gif" style="height:60px;margin:auto;display:block">

                <div class="row">
                    <div class="col-md-12 text-center fw-600 mt-20">Apologies</div>
                    <div class="col-md-12 text-center mt-10">There seems to be an issue.</div>
                    <div class="col-md-6 offset-md-3 text-center">Please contact customer care at <a class="card-subtitle text-black mb-1" href="tel:8976707222" style="color : #0062ff">+91-8976707222 </a> or <a class="card-subtitle text-black  mb-1" href="mailto:customersupport@wsfx.in" style="color : #0062ff">customersupport@wsfx.in</a>                        and we will reach out to you immediately.!</div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="p-15">
                            <div class="text-center text-black fw-600 mb-30">Order ID : {{ orderDetails?.orderId }}
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fw-600 fs-13 text-black">Amount</div>
                                <div class="col-md-6 col-6 text-right fw-600 fs-13 text-black">₹ {{this.orderDetails?.invoice?.billableAmountString}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">Service Charges</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalHandlingChargesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">GST</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalTaxesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">TCS({{ this.orderDetails?.tcs?.tcsPercentageString }}%)
                                </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">
                                    ₹ {{ this.orderDetails?.tcs?.tcsAmountString }}</div>
                            </div>

                            <div class="row mb-10" *ngIf="orderDetails?.invoice?.totalDiscount != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Applied coupon code</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    - ₹ {{this.orderDetails?.invoice?.totalDiscount}}
                                </div>
                            </div>
                            <div class="row mb-10" *ngIf="orderDetails?.redeemedPoints != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Redeemed points</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    - ₹ {{this.orderDetails?.redeemedPoints}}
                                </div>
                            </div>

                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 text-black">Total Amount</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    ₹ {{this.orderDetails?.invoice?.totalBillAmountString}}
                                </div>
                            </div>

                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 fw-600 text-black">Amount Paid </div>
                                <div class="col-md-6 col-6 text-right fs-13 fw-600 text-black">₹ {{this.orderDetails?.invoice?.totalPaymentReceived}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-md-2">
                        <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="goToOrderDetails()">Order Details</button>
                    </div>
                    <div class="col-md-10 text-right">
                        <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="retryPayment()">Retry
                            Now</button>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card invoice-preview-wrapper" *ngIf="insuranceDetails">

            <div class="card-body invoice-preview">
                <img src="assets/images/payment_successful.gif" class="success-gif">

                <div class="row">
                    <div class="col-md-10 offset-md-1 text-center mt-30">
                        <h3 class="text-black20 fw-600 fs-17">Thank You !</h3>
                        <div class="text-black fs-13">We have received your order details.</div>
                        <div class="text-black fs-13">We will inform you after confirmation.</div>
                    </div>
                </div>
                <hr class="invoice-spacing" />

                <div class="row">
                    <div class="col-md-12">
                        <div class="p-15">
                            <div class="text-center text-black fw-600 mb-30">Insurance Order ID : {{ insuranceDetails.cart[0].insuranceOrderId }}
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fw-600 fs-13 text-black">Amount</div>
                                <div class="col-md-6 col-6 text-right fw-600 fs-13 text-black">₹ {{this.insuranceDetails?.totalPremiumAmount}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black"> Service Charges </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.insuranceDetails?.serviceCharges}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">CGST</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.insuranceDetails?.cgst}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">SGST
                                </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{ this.insuranceDetails?.sgst }}</div>
                            </div>

                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 text-black">Total Amount</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">₹ {{this.insuranceDetails?.totalPayableAmount}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-right mt-20">
                        <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="goToInsuranceDetails()">Go to order details</button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<ng-template let-modal #VCIPModal>
    <div class="modal-body fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="fw-600 text-black mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p>Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p>Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="fw-600 text-black mb-20">{{vkycTimingText}}</p>

                <p class="blue-text mb-20 fw-600">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <button class="btn vcip-btn fs-13" (click)="closePopup()"> Schedule your VKYC later</button> &nbsp; &nbsp;
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>