import { Component, OnInit} from '@angular/core'
import { FaqService } from '../services/faq.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit {
   remittanceList: any;
   generalList: any;
   smartCurrencyCardList: any;
   foreignCurrencyList: any;
   VideoKycList: any;
   public showLoader : boolean = true;


  constructor(public faqService : FaqService) {}

  ngOnInit(){
    this.getRemittenceListAll();
    this.getGeneralListAll();
    this.getSmartCurrencyCardListAll();
    this.getForeignCurrencyListAll();
    this.getVideoKycListAll();
  }

  getRemittenceListAll(){
    this.showLoader = true;
    this.faqService.getRemittanceJSON().subscribe(res=>{
      this.showLoader = false;
      this.remittanceList = res;
    })
  }

  getGeneralListAll(){
    this.showLoader = true;
    this.faqService.getGeneralJSON().subscribe(res=>{
      this.showLoader = false;
      this.generalList = res;
    })
  }

  getSmartCurrencyCardListAll(){
    this.showLoader = true;
    this.faqService.getSmartCurrencyCardJSON().subscribe(res=>{
      this.showLoader = false;
      this.smartCurrencyCardList = res;
    })
  }

  getForeignCurrencyListAll(){
    this.showLoader = true;
    this.faqService.getForeignCurrencyJSON().subscribe(res=>{
      this.showLoader = false;
      this.foreignCurrencyList = res;
    })
  }

  getVideoKycListAll(){
    this.showLoader = true;
    this.faqService.getVideoKycJSON().subscribe(res=>{
      this.showLoader = false;
      this.VideoKycList = res;
    })
  }
}