<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <div class="content-header mb-10">
            <h6 class="fs-14 mb-0 text-black ">Add Address</h6>
        </div>
        <form class="form-horizontal" (ngSubmit)="addAddress(AddAddress.form.valid)" #AddAddress="ngForm">
            <loader *ngIf="showLoader"></loader>
            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label class="form-label">Address</label>
                    <input type="text" class="form-control fs-12" placeholder="Address" [(ngModel)]="addAddressModel.address" name="address" #addressRef="ngModel" [class.error]="AddAddress.submitted && addressRef.invalid" required minlength="10" pattern="\s*(\S\s*){10,}"
                        style="border-color: black;" />
                    <span *ngIf="AddAddress.submitted && addressRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="addressRef.errors.required">This field is required!</small>
                    </span>
                    <span class="invalid-form" *ngIf="AddAddress.submitted && addressRef.invalid">
                        <small class="text-danger" >Address must be at least 10 characters long.</small> 
                    </span>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label" for="country">PIN Code*</label>
                    <input type="number" class="form-control fs-12" placeholder="PIN Code*" [(ngModel)]="addAddressModel.postalCode" name="pincode" maxlength="6" #pincodeRef="ngModel" [class.error]="AddAddress.submitted
                    && pincodeRef.invalid" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190"
                        style="border-color: black;" required />
                    <span *ngIf="AddAddress.submitted && pincodeRef.invalid " class="invalid-form">
                        <small class="form-text text-danger" *ngIf="pincodeRef.errors.required">This field is required!</small>
                    </span>
                    <span *ngIf="AddAddress.submitted" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="pincodeRef.errors?.maxlength">Pincode must be at least 6 characters long.</small>
                    </span>
                </div>
            </div>
            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label class="form-label" for="country">Select City*</label>
                    <ng-select class="fs-12" [clearable]="false" [items]="cityItemList" bindLabel="cityItemList" bindValue="cityItemList" [(ngModel)]="addAddressModel.city" placeholder="Select" name="city" #cityRef="ngModel" [class.error]="AddAddress.submitted && cityRef.invalid"
                        required [searchable]="true"> </ng-select>
                    <span *ngIf="AddAddress.submitted && cityRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="cityRef.errors.required">This field is required!</small>
                    </span>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label" for="country">Select Address Type *</label>
                    <ng-select class="fs-12" [clearable]="false" [items]="addressTypeList" bindLabel="addressTypeList" bindValue="addressTypeList.toUpperCase()" [(ngModel)]="addAddressModel.locationType" placeholder="Select" name="address_type" #address_typeRef="ngModel"
                        [class.error]="AddAddress.submitted && address_typeRef.invalid" [searchable]="false" required>
                    </ng-select>
                    <span *ngIf="AddAddress.submitted && address_typeRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="address_typeRef.errors.required">This field is required!</small>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-3">
                    <button type="button" rippleEffect class="btn btn-primary fs-12" routerLink="/list-address">Back</button>
                </div>
                <div class="col-md-9 col-9 mb-20 text-right">
                    <button type="submit" rippleEffect class="btn btn-primary fs-12" *ngIf="addressID == null ">Add</button>
                    <button type="submit" rippleEffect class="btn btn-primary fs-12" *ngIf="addressID != null && addressID != ''">Update</button>
                </div>
            </div>
        </form>
    </div>
</div>