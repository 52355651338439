// import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RequestDataModel, RequestDirectLogin } from 'app/init-widget/models/init-widget.model';
import { RequestLoginModel } from 'app/modules/onboarding/models/login.models';
import { LoginService } from 'app/modules/onboarding/services/login.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitWidgetService } from 'app/init-widget/services/init_widget.service';

@Component({
  selector: 'init-widget',
  templateUrl: './init-widget.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService]
})
export class InitWidgetComponent implements OnInit {

  public coreConfig: any;
  public agentCode: string;
  public themeName: string;

  // Private
  private _unsubscribeAll: Subject<any>;
  public requestLoginModel = new RequestLoginModel();
  public parseDataToObj: RequestDataModel;
  public requestDirectLogin: RequestDirectLogin = new RequestDirectLogin();

  /**
   * Constructor
   * @param {DOCUMENT} document
   * @param {CoreConfigService} _coreConfigService
  **/
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _coreConfigService: CoreConfigService,
    public router: Router,
    public route: ActivatedRoute,
    private initWidgetService: InitWidgetService,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit() {
    localStorage.clear();
    this.testDataBuild();
    this.route.queryParams.subscribe(async params => {
      var getBase64Data = params['data'];
      this.parseDataToObj = JSON.parse(atob(getBase64Data));

      if (!this.parseDataToObj || !this.parseDataToObj.agentCode) {
        this.router.navigate(['/unauthorizedAccess']);
      }

      this.requestDirectLogin.agentId = this.parseDataToObj.agentCode;
      this.requestDirectLogin.email = this.parseDataToObj.email;
      this.requestDirectLogin.name = this.parseDataToObj.fullname;
      this.requestDirectLogin.mobile = this.parseDataToObj.mobileNo;

      this.requestDirectLogin.agentId = this.parseDataToObj.agentCode.slice(0, -3);
      this.themeName = this.parseDataToObj.agentCode.substr(-3);

      await this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
        this.coreConfig = config;

        if (this.themeName == "G01") {
          this.coreConfig.layout.skin = "green";
        } else if (this.themeName == "B01") {
          this.coreConfig.layout.skin = "black";
        } else if (this.themeName == "O01") {
          this.coreConfig.layout.skin = "orange";
        } else {
          this.coreConfig.layout.skin = "black";
        }

        this.coreConfig.layout.skin = "black";

        this.document.body.classList.remove('default-layout', 'green-layout', 'black-layout', 'orange-layout', 'blue-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      });

      this.getDataAndVerifiy();
    });
  }

  // ngOnDestroy() {
  //   this._unsubscribeAll.next();
  //   this._unsubscribeAll.complete();
  // }

  getDataAndVerifiy() {

    this.initWidgetService.directLogin(this.requestDirectLogin).subscribe((respData) => {
      var respBody = JSON.parse(JSON.stringify(respData.body));

      localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, respData.headers.get('X-AUTH-TOKEN'));
      localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
      localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

      localStorage.setItem(ConstantsKey.AGENT_ID, this.parseDataToObj.agentCode);
      localStorage.setItem(ConstantsKey.AGENT_KEY, this.parseDataToObj.agentKey);

      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

      this.router.navigate(['/home'])
        .then(() => {
        window.location.reload();
       });

    }, error => {
      localStorage.setItem(ConstantsKey.AGENT_ID, this.requestDirectLogin.agentId);
      localStorage.setItem(ConstantsKey.AGENT_THEME, this.themeName);
      this.router.navigate(['/login']);
    });
  }

  testDataBuild() {
    var dataJson = {
      "mobileNo": "9420796077",
      "password": "986532",
      "agentCode": "PA1005G01",
      "agentKey": "adjfak4635",
      "email": "pgfepale@gmail.com",
      "fullname": "Prashant Fepale",
      "arg01": "test value",
      "arg02": "test 2 value",
      "vcipStatus": true
    };

    var dataJson1 = {
      "agentCode": "PA1005B01",
      "mobileNo": "",
      "fullname": "Prashant Fepale",
      "email": "pgfepale@gmail.com",
    };

    console.log("base 64 data encode >>", btoa(JSON.stringify(dataJson1)));
    console.log("base 64 data decode >>", atob(btoa(JSON.stringify(dataJson1))));
  }
}