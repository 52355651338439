import { Injectable } from "@angular/core";
import { SinglePurposeItem } from "../models/shared-models";

@Injectable()
export class PurposeListBeneficiary {
    public static purpose = [
        {
            "key": "UniversityFee",
            "icon": "assets/images/purposeIcon/StudyAbroad-University.png",
            "title": "University Fee",
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "key": "UniversityFeesOwnAccount",
            "icon": "assets/images/purposeIcon/StudyAbroad-University.png",
            "title": "University Fees Own Account",
            "purposeCode": "UNIVERSITY_FEES_OWN_ACCOUNT",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "key": "StudentLivingExpenses",
            "icon": "assets/images/purposeIcon/StudyAbroad-University.png",
            "title": "Student Living Expenses",
            "purposeCode": "STUDENT_LIVING_EXPENSES",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "key": "GIC",
            "icon": "assets/images/purposeIcon/Family-Maintenance.png",
            "title": "GIC",
            "purposeCode": "GIC",
            "remittanceType": "STUDENT",
            "isDashboardShow": "false"
        },
        {
            "key": "BlockAccount",
            "icon": "assets/images/purposeIcon/Family-Maintenance.png",
            "title": "Block Account",
            "purposeCode": "BLOCK_ACCOUNT",
            "remittanceType": "STUDENT",
            "isDashboardShow": "false"
        },
        {
            "key": "TourRemittance",
            "icon": "assets/images/purposeIcon/GiftBox.png",
            "title": "Tour Remittance",
            "purposeCode": "TOUR_REMITTANCE",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "MedicalExpenses",
            "icon": "assets/images/purposeIcon/Travel-Accommodation.png",
            "title": "Medical Expenses",
            "purposeCode": "MEDICAL_EXPENSES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "Emigration",
            "icon": "assets/images/purposeIcon/Medical-Expenses.png",
            "title": "Emigration",
            "purposeCode": "EMIGRATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        // {
        //     "key": "EmigrationFee",
        //     "icon": "assets/images/purposeIcon/Emigration.png",
        //     "title": "Emigration Fee",
        //     "purposeCode": "EMIGRATION_FEES",
        //     "remittanceType": "OTHERS",
        //     "isDashboardShow": "true"
        // },
        {
            "key": "VisaFees",
            "icon": "assets/images/purposeIcon/Debt-Equity.png",
            "title": "Visa Fees",
            "purposeCode": "VISA_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        // {
        //     "key": "PrivateVisit",
        //     "icon": "",
        //     "title": "Private Visit",
        //     "purposeCode": "PRIVATE_VISIT",
        //     "remittanceType": "OTHERS",
        //     "isDashboardShow": "false"
        // },
        {
            "key": "ExaminationVisit",
            "icon": "",
            "title": "Examination Visit",
            "purposeCode": "EXAMINATION_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "JobVisit",
            "icon": "",
            "title": "Job Visit",
            "purposeCode": "JOB_APPLICATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "SpecialisedTrainingFees",
            "icon": "",
            "title": "Specialised Training Fees",
            "purposeCode": "SPECIALISED_TRAINING_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "InternationalCompetitionFees",
            "icon": "",
            "title": "International Competition Fees",
            "purposeCode": "INTERNATIONAL_COMPETITION_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "AttendingConference",
            "icon": "",
            "title": "Attending Conference",
            "purposeCode": "ATTENDING_CONFERENCE",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
    ];

    public static getPurposeObjectByPurposeCode(purposeCode: string) {
        var returnPurpose: SinglePurposeItem;
        PurposeListBeneficiary.purpose.forEach(singlePurpose => {
            if (singlePurpose.purposeCode == purposeCode) {
                returnPurpose = singlePurpose;
            }
        });
        return returnPurpose;
    }

    public static getPurposeListBeneficiary() {
        let purposeMenu: Array<SinglePurposeItem> = [];
        PurposeListBeneficiary.purpose.forEach(singlePurpose => {
            purposeMenu.push(singlePurpose);
        });

        purposeMenu.sort((a,b) => a.title.localeCompare(b.title));
        return purposeMenu;
    }
}

