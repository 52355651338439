<div class="content-wrapper row  container-xxl p-0">
    <div class="content-body col-md-12">
        <div class="card invoice-preview-wrapper">

            <div class="card-body invoice-preview">
                <div class="row">
                    <div class="col-md-10 offset-md-1 text-center mt-30">
                        <h3 class="text-black20 fw-600 fs-17">Order submitted successfully</h3>
                        <div class="text-black fs-13 mt-10">Your currency notes sell request has been submitted successfully. Please visit the selected branch to surrender foreign Currency Notes.</div>
                        <div class="textBlue fs-13 mt-10 fw-600">Order ID #{{orderId}}</div>
                        <div class="textBlue fs-13 mt-10 fw-600">Branch Address : {{branchName}}</div>
                        <div class="text-black fs-13 mt-10">For any further assistance, please contact us at 8976707222 or mail us at customersupport@wsfx.in</div>


                        <img src="assets/images/illustration/sitandrelax.png" class="sit-back-real-image">
                        <div class="row mt-40">
                            <div class="col-md-12 text-center mt-20">
                                <button type="submit" (click)="goToOrderDetails()" rippleEffect class="btn btn-primary fs-12">Order Details</button>
                            </div>
                        </div>






                    </div>
                </div>
            </div>
        </div>