import { Injectable } from "@angular/core";
import { Constants } from "app/shared/constant/constants"
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class RatesService extends BaseProvider {

    getRatesList(productType){
        // $url = "wsfx/currentRates??clientId=spicefx&partnerId=p_wsfx";
        var resourceUrl = "wsfx/currentRates?partnerId=" + Constants.partnerId;
        return this.makeGetCall(resourceUrl);
    }
    getProductWiseRatesList(productType){
        // $url = "wsfx/currentRates??clientId=spicefx&partnerId=p_wsfx";
        var resourceUrl = "wsfx/currentRates?partnerId=" + Constants.partnerId+'&products='+productType;
        return this.makeGetCall(resourceUrl);
    }
}