<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14  mb-10">Eligibility Check</h5>
            <h6>Please answer the following questions to check your eligibility as per RBI regulations.</h6>
            <hr class="my-50 hr-color">
        </div>
        <div class="row ">
            <div class="col-md-12 mt-20">
                <div class="fw-600 fs-16">Are you an Indian Resident ?
                    <span class="showButton">
                        <img src="assets/images/icons/info.png"  style="width: 20px;">
                        <div class="openTooltip">
                            <div class="card" style="position: absolute;z-index:1000; font-size:11px;width: 550px;">
                                <div class="card-body">
                                    <div class="fw-600">"Person resident in India" means: </div>
                                    <ul class="fw-400">
                                        <li>a person residing in India for more than one hundred and eighty-two days during the course of the preceding FY but does not include :</li>
                                        <li>a person who has gone out of India or who stays outside India, in either case:</li>
                                        <li>for or on taking up employment outside India, or</li>
                                        <li>for carrying on outside India a business or vocation outside India, or</li>
                                        <li>for any other purpose, in such circumstances as would indicate his intention to stay outside India for an uncertain period;</li>
                                        <li>a person who has come to or stays in India, in either case, otherwise than:</li>
                                        <li>for or on taking up employment in India, or</li>
                                        <li>for carrying on in India a business or vocation in India, or</li>
                                        <li>for any other purpose, in such circumstances as would indicate his intention to stay in India for an uncertain period;</li>
                                    </ul>

                                    <div class="fw-600">Explanation</div>
                                    <div class="fw-400">- The residential status of a person leaving India will be determined as under:if a person leaves India for the purpose of employment, business or for any other purpose that indicates his intention to stay outside India for an uncertain period; then he becomes a person resident outside India from the day he leaves India for such purpose.</div>
                                    <div class="fw-400">- The residential status of a person returning to India will be determined as under:If a person comes to India for the purpose of employment, business or for any other purpose that indicates his intention to stay in India for an uncertain period; then he becomes a person resident in India from the day he comes to India for such purpose. Under FEMA, stay for a period of 182 days is also stated. However, it shall be noted that the residential status of a person in our opinion is primarily determined basis the intention of the person to stay in India.</div>
                                    <ul class="fw-400">
                                        <li>any person or body corporate registered or incorporated in India;</li>
                                        <li>an office, branch or agency in India owned or controlled by a person resident outside India;</li>
                                        <li>an office, branch or agency outside India owned or controlled by a person resident in India</li>
                                    </ul>
                                    <div class="fw-400"> "Person resident outside India" means a person who is not a resident of India</div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>

            </div>
            <div class="col-md-12 mt-20">
                <div class="row">
                    <div class="col-md-1 fs-12 fw-500">
                        <input type="radio" name="radios" id="radio" value='Yes' (click)="openModalForPANNo()" /> &nbsp; &nbsp;YES
                    </div>
                    <div class="col-md-1 fs-12 fw-500">
                        <input type="radio" name="radios" id="radio" value='No' (click)="openNotAnIndianResidentPopup()" /> &nbsp; &nbsp;NO
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template let-modal #PANDetailModal>
    <form [formGroup]="formGroup">
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Costumer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN" formControlName="panNo" maxlength="100" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required/>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN" formControlName="panName"  (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" id="checkboxForAcceptTermsAndCondition" class="custom-control-input" formControlName="cb" style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10" [disabled]="formGroup.invalid" rippleEffect (click)="getPanName()">
            Proceed
        </button>
        </div>
    </form>
</ng-template>


<ng-template let-modal #NotAnIndianResidentPopup>
    <div class="modal-header">
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center" tabindex="0" ngbAutofocus>
        <label class="form-label mb-10"><b>For more support, please give us a call at 8976707222*</b></label>
        <button class="col-md-3 mt-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss()">
            Close
        </button>
    </div>
</ng-template>