export class UpdateProfileModel {
    mobile: string;
    email: string;
    nationality: string;
    occupation: string;
    passportNumber: string;
    dob: any;
    address: string;
    pan: string;
    name: string;
    passportExpiryDate: any;
    gender: string;
    salutation: string;
    educationQualifications: string;
    income: string;
    maritalStatus: string;
    passportIssueDate: any;
    passportIssuePlace: string;
    birthCity: string;
    birthCountry: string;
    passportFileNumber: string;
    passportPersonName: string;
    profileComplete: boolean;
    nameAsPerPassport: string;
    pinCode: string;
    cityTown: string;
    relationship: string;
    consumerId: string;
    // photoUrl: string;
    // profileStatus: string;
}
export class DobFormat {
    year: number;
    month: number;
    day: number;
}