<div class="content-wrapper row  container-xxl p-0 ml-0 mr-0">
    <div class="content-body col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10 offset-md-1 text-center mt-30 mb-20" *ngIf="isFromReload == true">
                        <h5 class="text-black fw-600 mb-20">Sit Back and Relax</h5>
                        <div class="text-black fw-500 fs-12">Thank you for your reload request. Your card will be credited shortly.</div>
                    </div>

                    <div class="col-md-10 offset-md-1 text-center mt-30 mb-20" *ngIf="isFromRemittance == true">
                        <h5 class="text-black fw-600 mb-20">Sit Back and Relax</h5>
                        <div class="col-md-8 offset-md-2 text-black fw-500 fs-12">Thank you for placing your order with us. We would like to inform you that your remittance is currently in process. Kindly complete the payment and upload proof of payment in <a class="text-blue" (click)="goToOrderDetails()">My orders</a>                            to complete the order. </div>
                        <div class="text-black fw-500 fs-12">Your cooperation is greatly valued. Thank you!</div>
                    </div>


                    <div class="col-md-10 offset-md-1 text-center mt-30 mb-20" *ngIf="isFromBuyNewCard == true || isFromRetryPayment == true">
                        <h5 class="text-black fw-600 mb-20">Sit Back and Relax</h5>
                        <div class="text-black fw-500 fs-12" *ngIf="paymentMethod == 'NB'"> Your order is in process securely and safely with us. Please do check 'My Orders' for real-time updates on your order.</div>
                        <div class="text-black fw-500 fs-12" *ngIf="paymentMethod != 'NB'"> Thank you for placing your order with us. We would like to inform you that your card order is currently being processed. Kindly complete the payment and upload the proof of payment in the 'My orders' section to complete the order.
                            Please note that your order will be valid for 4 hours only. Thank you for your cooperation.</div>
                    </div>

                    <img src="assets/images/illustration/sitandrelax.png" class="sit-back-real-image">
                </div>
                <div class="row mt-40">
                    <div class="col-md-12 text-center mt-20">
                        <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="goToOrderDetails()">Order Details</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>