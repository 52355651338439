import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module'; 
import { SellCurrencyNotesComponent } from './sell-currency-notes/sell-currency-notes.component';
import { EligibilityCheckComponent } from './eligibility-check/eligibility-check.component';
import { CurrencyNotesOrderSuccessComponent } from './currency-notes-order-success/currency-notes-order-success.component';
import { RemittanceRoutingModule } from 'app/modules/remittance/remittance-routing.module';
import { CurrencyNotesLandingComponent } from './curreny-notes-landing/currency-notes-landing.component';
import { CurrencyNotesViewCartComponent } from './currency-notes-view-cart/currency-notes-view-cart.component';

const routes = [
    {
    path: 'currency-notes-landing',
    component: CurrencyNotesLandingComponent,
    data: { animation: 'CurrencyNotesLandingComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'currency-notes-landing',
    component: CurrencyNotesLandingComponent,
    data: { animation: 'CurrencyNotesLandingComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'sell-currency-notes',
    component: SellCurrencyNotesComponent,
    data: { animation: 'SellCurrencyNotesComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'eligibility-check',
    component: EligibilityCheckComponent,
    data: { animation: 'EligibilityCheckComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'currency-notes-order-success/:orderId',
    component: CurrencyNotesOrderSuccessComponent,
    data: { animation: 'CurrencyNotesOrderSuccessComponent' },
    canActivate: [AuthGuard]
  },

  {
    path: 'currency-notes-view-cart',
    component: CurrencyNotesViewCartComponent,
    data: { animation: 'CurrencyNotesViewCartComponent' },
    canActivate: [AuthGuard]
    
  }
];

@NgModule({
  declarations: [
    SellCurrencyNotesComponent,
    EligibilityCheckComponent,
    CurrencyNotesOrderSuccessComponent, 
    CurrencyNotesLandingComponent,
    CurrencyNotesViewCartComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    LoaderRoutingModule,
    NgSelectModule,
    NgbModule,RemittanceRoutingModule
  ],
  exports: [],
  providers: [
    SellCurrencyNotesComponent,
    EligibilityCheckComponent,
    CurrencyNotesOrderSuccessComponent, 
    CurrencyNotesLandingComponent,
    CurrencyNotesViewCartComponent
  ]
})
export class SellCurrencyNotesRoutingModule { }
