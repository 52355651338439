import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { RequestModelForForgetPwd } from "../models/forgot-pwd.model";

@Injectable()
export class ForgotPwdService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    getOTP(requestModel){
        var resourceUrl = 'consumers/forgotPassword';
        return this.makePostCall(resourceUrl, requestModel);
    }

    resetPasswordWithOtp(requestModel: RequestModelForForgetPwd) {
        var resourceUrl = "consumers/passwordThroughOtp";
        return this.makePostCall(resourceUrl, requestModel);
    }
    
}