<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card mb-0">
            <div class="card-body">
                <div class="col-md-12 mb-20">
                    <h5 class="content-header-title float-left mb-0">Incomplete Orders</h5>
                </div>
                <!-- <div class="row">
                    <div class="col-md-4 col-12">
                        <label for="">From</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="Select Start Date"
                                    (click)="startDatePicker.toggle()" name="Start Date Picker" [(ngModel)]="startDate"
                                    ngbDatepicker #startDatePicker="ngbDatepicker"
                                    (dateSelect)="onDateSelect($event, 'startDate')" readonly [maxDate]="maxDate">
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                        (click)="startDatePicker.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <label for="">To</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="Select End Date"
                                    (click)="endDatePicker.toggle()" name="End Date Picker" [(ngModel)]="endDate"
                                    ngbDatepicker #endDatePicker="ngbDatepicker"
                                    (dateSelect)="onDateSelect($event, 'endDate')" readonly [maxDate]="maxDate"
                                    [minDate]="minDateForEndDate">
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                        (click)="( enableEndDatePicker ) ? endDatePicker.toggle() : showErrorMessage()"
                                        type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-12 mt-23">
                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect>
                            <span class="align-middle " (click)="getFilteredOrderList()">Apply Filters</span>
                        </button>
                    </div>
                </div> -->
                <div class="row" *ngIf="noDataFound">
                    <div class="col-md-12 text-center mt-20" >
                        <div class="text-black fw-500 ">
                            <div class="fs-12"> No orders found </div>
                        </div>
                    </div>
                </div>
                <loader *ngIf="showLoader"></loader>
                <div class="row mt-20" *ngIf="!noDataFound">
                    <div class="col-md-12 card-body" *ngFor="let singleOrder of incompleteOrderList"
                        style="border-bottom:1px dashed">
                        <div style="cursor: pointer; box-shadow: 0 0 0 0 !important;">
                            <div class="row">
                                <div class="col-md-12 col-12 mb-10">
                                    <span class="card-title fs-14 fw-600 text-black">
                                        Order Id : #{{ singleOrder.ioId }}
                                        | {{ singleOrder.createdAt | date:'MMM dd, yyyy hh:mm:ss a' }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Benificiary Name : </span>
                                    <span class="fs-11 text-black">{{ singleOrder.beneficiaryName }}</span>
                                </div>
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Order Details</span>
                                    <span class="fs-11 text-black">&nbsp;&nbsp; {{ singleOrder.product + " | " + singleOrder.currencyCode + " | " + singleOrder.currencyAmount }}</span>
                                </div>
                            </div>
                            <div class="col-md-4 col-12 mt-23  paddingleft0">
                                <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect>
                                    <span class="align-middle" (click)= "navigateToProceedOrder(singleOrder.ioId)">Continue Order</span>
                                </button>
                            </div> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>