import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NavigationStart, Router } from '@angular/router';
import { CardService } from 'app/modules/card/services/card.service';
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster, SinglePurposeItem, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { QuotationCurrencyItem, RequestQuotationModel } from '../../models/quotation.models';
declare const gtag: Function;

@Component({
  selector: 'app-widget-dashboard',
  templateUrl: './widget-home.component.html',
  styleUrls: [
    '../dashboard.scss',
    '../../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../../../../../@core/scss/angular/libs/ratings.component.scss',
    '../../../../../@core/scss/angular/libs/select.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class DashboardComponent implements OnInit {
  public currencyItems: any;
  public selectedPurposeItem: any;
  public cartType: any;
  public purposeMenu: Array<SinglePurposeItem> = [];

  public purposeListRemittance: any = [];

  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public showLoader: boolean = false;

  ///STEPPER DATA
  public stepperDataModel: StepperDataModel = new StepperDataModel();

  ///QUOTATION
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public quotationResponse: any;
  
  public showCurrency: boolean = false;
  public consumerDetail: any;
  public vcipStatusDetails: any;
  public vcipMessage: any;
  public vcipAccepted: any;
  public showButtonOnVCIP: boolean = false;
  public showMoreContents: boolean = false;
  public buttonText: string = 'View More';
  public cardDetails: any;
  public showCardDetailsBox: boolean = false;
  public purposeEleven: boolean = false;
  public showlessmore: boolean = false;
 
  constructor(
    private remittanceService: RemittanceService,
    private masterDataService: MasterDataService,
    public router: Router,
    public toast: ToastrService,
    public updateProfileService: UpdateProfileService,
    private cardService: CardService,
  ) {
    gtag('set', 'page', '/home');
    gtag('send', 'pageview');
  }

  ngOnInit() {
    this.refreshOnBackButtonClick();
    this.purposeMenu = PurposeList.getPurposeDashboard();
    this.showLessMorePurpose(11);
    this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(this.purposeListRemittance[0]);
    // this.firstFourPurposes();
    // this.lastFourPurposes();
    // this.nextrowPurposes();
    this.getCurrencies();
    this.quotationCurrencyItem.currencyCode = "USD";
    this.getRateRange();
    this.getVCIPStatus();
    this.getCardDetails();
    this.getQuotationRequestBody();
  }

  refreshOnBackButtonClick(): void {
    this.router.events.subscribe((event: NavigationStart) => {
        if (event.url == '/home'){
        location.reload();
      }
    });
  }

  showLessMorePurpose(itemCount) {
    // if (itemCount == 8) {
    //   this.purposeListRemittance = PurposeList.getPurposeListAgentPortal();
    //   this.purposeListRemittance = this.purposeListRemittance.slice(0, 8);
    //   console.log("this.purposeListRemittance >>", this.purposeListRemittance);
    //   this.purposeEleven = false;
    // } else {
      this.purposeListRemittance = PurposeList.getPurposeListAgentPortal();
    //   this.purposeEleven = true;
    //   this.showlessmore = true;
    // }
  }

  showContents() {
    this.showMoreContents = true;
    this.buttonText = 'View Less';
  }

  hideContents() {
    this.showMoreContents = false;
    this.buttonText = 'View More';
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.vcipStatusDetails = resp.body;
      this.vcipMessage = this.vcipStatusDetails.message;
      this.showButtonOnVCIP = this.vcipStatusDetails.showBtnOnStatus;
      // this.showLoader = false;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.vcipAccepted = true;
      } else {
        this.vcipAccepted = false;
      }
    }), error => {
      this.showLoader = false;
    });
  }

  getCurrencies() {
    this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = ""
    this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
    });
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(result => {
    });
  }

  getActiveSectionTitle(purposeCode) {
    this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(purposeCode);
    console.log("purpose code++", purposeCode);
    console.log("selected item++", this.selectedPurposeItem)
  }

  getRateRange() {
    this.showLoader = true;
    this.masterDataService.getRateRange(
      Constants.productsEnum[2],
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
    }, error => {
      this.showLoader = false;
      console.log("get error ", console.error());
    });
  }

  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;

    return true;
  }

  enterCurrencyAmount() {
    if (this.quotationCurrencyItem.currencyAmount) {
      this.getQuotationRequestBody();
      this.remittanceService.getQuotation(JSON.parse(JSON.stringify(this.requestQuotationModel))).subscribe(resp => {
        this.quotationResponse = resp.body;
        console.log("this.quotationResponse >>", this.quotationResponse);
      }, error => {
        this.showLoader = false;
        this.toast.error(JSON.stringify(error.error.message));
      });
    }
  }

  async proceedToStepper() {
    if (this.selectedPurposeItem && this.quotationCurrencyItem.currencyAmount) {
      if (await this.getProfileDetails()) {
        this.showLoader = true;
        this.cartType = this.selectedPurposeItem.remittanceType;
        var vcipKycStatus = this.consumerDetail.vcipKyc;
        if (vcipKycStatus) {
          this.remittanceService.cartPrepare(this.cartType).subscribe(result => {
            this.getQuotationRequestBody();
            this.remittanceService.getQuotation(this.requestQuotationModel).subscribe(resp => {

              ///store active order in session storage
              this.stepperDataModel.purposeItem = this.selectedPurposeItem;
              this.stepperDataModel.quotationRequest = this.requestQuotationModel;
              this.stepperDataModel.quotationResponse = resp.body;

              sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
              this.showLoader = false;

              if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
                this.router.navigate(['/stepper3step'])
              } else {
                this.router.navigate(['/stepper'])
              }
            }, error => {
              this.showLoader = false;
              this.toast.error(JSON.stringify(error.error.message));
            });
          });
        } else {
          this.showLoader = false;
          this.toast.error('Kindly complete your profile and VCIP!');
        }
      }
    } else {
      var errorMessage = 'Please enter amount';
      if (!this.selectedPurposeItem) {
        errorMessage = "Please select purpose";
      }
      this.toast.error(errorMessage);
    }
  }

  getQuotationRequestBody() {
    let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];

    /// prepare quotation request
    this.quotationCurrencyItem.product = Constants.productsEnum[2];
    this.quotationCurrencyItem.bidRate = this.singleRateResItem.minimumRateInINR;
    this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
      ? this.selectedPurposeItem.purposeCode
      : "UNIVERSITY_FEES";
    
    listQuotationCurrencies.push(this.quotationCurrencyItem);

    this.requestQuotationModel.sellType = Constants.sellTypes[0];
    this.requestQuotationModel.partnerId = Constants.partnerId;
    this.requestQuotationModel.nostroChargeType = 'REMITTER';
    this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  }

  getCardDetails() {
    this.showLoader = true;
    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.showCardDetailsBox = false;
      } else {
        this.showCardDetailsBox = true;
      }
      this.showLoader = false;
    }), error => {
      this.showLoader = false;
      this.showCardDetailsBox = true;
      this.toast.error(error.error.message);
    });;
  }
}
