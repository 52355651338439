<style>
    a#ngb-nav-0 {
        border-radius: 5px 5px 0px 0px !important;
        border: 0px !important;
    }
</style>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <loader *ngIf="showLoader"></loader>
        <section id="faq">
            <div class="row nav-vertical d-flex">
                <div class="col-md-3 mb-2 mb-md-0">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="payment" data-toggle="pill" aria-expanded="true" role="tab">
                                <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12"> General </span>
                            </a>

                            <ng-template ngbNavContent>
                                <div role="tabpanel" class="tab-pane active collapse-icon" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="credit-card" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <h5 class="mb-0"> General </h5>
                                            <span> </span>
                                        </div>
                                    </div>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="paymentId1">
                                                <ngb-panel id="paymentId{{ i }}" [cardClass]="'collapse-margin'" *ngFor="let singleGeneralFaq of generalList; let i = index">
                                                    <ng-template ngbPanelTitle>
                                                        <span class="text-black fw-500 fs-12" class="text-black fw-500 fs-12">{{i+1}}. {{singleGeneralFaq.question}}</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        {{singleGeneralFaq.answer}}
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>
                                        <ng-template #noResults>
                                            <div class="p-5 text-center">
                                                <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> No Results found</h5>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="delivery" data-toggle="pill" aria-expanded="false" role="tab">
                                <i data-feather="shopping-bag" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12">Remittance</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon" id="faq-delivery" role="tabpanel" aria-labelledby="delivery" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="shopping-bag" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <h5 class="mb-0">Remittance</h5>
                                            <span> </span>
                                        </div>
                                    </div>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="deliveryId1">
                                                <ngb-panel id="deliveryId{{ i }}" [cardClass]="'collapse-margin'" *ngFor="let singleRemittanceFaq of remittanceList; let i = index">
                                                    <ng-template ngbPanelTitle>
                                                        <span class="text-black fw-500 fs-12">{{i+1}}. {{ singleRemittanceFaq.question }}</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        {{ singleRemittanceFaq.answer }}
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>
                                        <ng-template #noResults>
                                            <div class="p-5 text-center">
                                                <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> No Results found</h5>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="cancellation-return" data-toggle="pill" aria-expanded="false" role="tab">
                                <i data-feather="refresh-cw" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12">WSFx Smart Currency Card</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon" id="faq-cancellation-return" role="tabpanel" aria-labelledby="cancellation-return" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="refresh-cw" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <h5 class="mb-0">WSFx Smart Currency Card</h5>
                                            <span> </span>
                                        </div>
                                    </div>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="cancellationReturnId1">
                                                <ngb-panel id="cancellationReturnId{{ i }}" [cardClass]="'collapse-margin'" *ngFor="let singleSmartCurrencyCardFaq of smartCurrencyCardList; let i = index">
                                                    <ng-template ngbPanelTitle>
                                                        <span class="text-black fw-500 fs-12">{{i+1}}. {{ singleSmartCurrencyCardFaq.question }}</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        {{ singleSmartCurrencyCardFaq.answer }}
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>
                                        <ng-template #noResults>
                                            <div class="p-5 text-center">
                                                <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> No Results found</h5>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="my-order" data-toggle="pill" aria-expanded="false" role="tab">
                                <i data-feather="settings" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12">Foreign Currency</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon" id="faq-my-order" role="tabpanel" aria-labelledby="my-order" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="settings" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <h5 class="mb-0">Foreign Currency</h5>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="myOrdersId1">
                                                <ngb-panel id="myOrdersId{{ i }}" [cardClass]="'collapse-margin'" *ngFor="let singleForeignCurrencyListFaq of foreignCurrencyList; let i = index">
                                                    <ng-template ngbPanelTitle>
                                                        <span class="text-black fw-500 fs-12">{{i+1}}. {{ singleForeignCurrencyListFaq.question }}</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        {{ singleForeignCurrencyListFaq.answer }}
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>
                                        <ng-template #noResults>
                                            <div class="p-5 text-center">
                                                <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> No Results found</h5>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="product-services" data-toggle="pill" href="#faq-product-services" aria-expanded="false" role="tab">
                                <i data-feather="package" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12">Video KYC</span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon" id="faq-product-services" role="tabpanel" aria-labelledby="product-services" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="package" class="font-medium-4"></i>
                                        </div>
                                        <div>
                                            <h5 class="mb-0">Video KYC</h5>
                                            <span> </span>
                                        </div>
                                    </div>

                                    <div class="accordion collapse-margin mt-2">
                                        <ng-container>
                                            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="productServicesId1">
                                                <ngb-panel id="productServicesId{{ i }}" [cardClass]="'collapse-margin'" *ngFor="let singleVideoKycFaq of VideoKycList; let i = index">
                                                    <ng-template ngbPanelTitle>
                                                        <span class="text-black fw-500 fs-12">{{i+1}}. {{ singleVideoKycFaq.question }}</span>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        {{ singleVideoKycFaq.answer }}
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </ng-container>
                                        <ng-template #noResults>
                                            <div class="p-5 text-center">
                                                <h5 class="p-1"><i data-feather="info" size="19" class="mr-25"></i> No Results found</h5>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </li>



                        <!-- <img src="assets/images/faq/faq-illustrations.svg" class="img-fluid d-none d-md-block pt-5" alt="demand img" /> -->
                    </ul>
                </div>
                <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9  fs-11 text-black20">
                    <loader *ngIf="showLoader"></loader>
                </div>
            </div>
        </section>


    </div>
</div>

<!-- <div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill col-md-12 fs-12">
                        <li ngbNavItem>
                            <a ngbNavLink>General</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin" *ngFor="let singleGeneralFaq of generalList; let i = index">
                                    <ng-container>
                                        <ngb-accordion>
                                            <ngb-panel id="paymentId">
                                                <ng-template ngbPanelTitle>
                                                    <span class="text-black fw-500 fs-12">{{i+1}}. {{singleGeneralFaq.question}}</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>{{singleGeneralFaq.answer}}
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Remittance</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin" *ngFor="let singleRemittanceFaq of remittanceList; let i = index">
                                    <ng-container>
                                        <ngb-accordion>
                                            <ngb-panel id="paymentId">
                                                <ng-template ngbPanelTitle>
                                                    <span class="text-black fw-500 fs-12">{{i+1}}. {{singleRemittanceFaq.question}}</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>{{singleRemittanceFaq.answer}}
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>WSFx Smart Currency Card</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin" *ngFor="let singleSmartCurrencyCardFaq of smartCurrencyCardList; let i = index">
                                    <ng-container>
                                        <ngb-accordion>
                                            <ngb-panel id="paymentId">
                                                <ng-template ngbPanelTitle>
                                                    <span class="text-black fw-500 fs-12">{{i+1}}. {{singleSmartCurrencyCardFaq.question}}</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>{{singleSmartCurrencyCardFaq.answer}}
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Foreign Currency</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin" *ngFor="let singleForeignCurrencyListFaq of foreignCurrencyList; let i = index">
                                    <ng-container>
                                        <ngb-accordion>
                                            <ngb-panel id="paymentId">
                                                <ng-template ngbPanelTitle>
                                                    <span class="text-black fw-500 fs-12">{{i+1}}. {{singleForeignCurrencyListFaq.question}}</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>{{singleForeignCurrencyListFaq.answer}}
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Video KYC</a>
                            <ng-template ngbNavContent>
                                <div class="accordion collapse-margin" *ngFor="let singleVideoKycFaq of VideoKycList; let i = index">
                                    <ng-container>
                                        <ngb-accordion>
                                            <ngb-panel id="paymentId">
                                                <ng-template ngbPanelTitle>
                                                    <span class="text-black fw-500 fs-12">{{i+1}}. {{singleVideoKycFaq.question}}</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>{{singleVideoKycFaq.answer}}
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navFilled" class="col-md-12 fs-11 text-black20">
                        <loader *ngIf="showLoader"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->