import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RequestDirectLogin } from 'app/init-widget/models/init-widget.model';
import { RequestLoginModel } from 'app/modules/onboarding/models/login.models';
import { LoginService } from 'app/modules/onboarding/services/login.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Subject } from 'rxjs';
import { SsoService } from '../services/sso.service';
import { FormGroup } from '@angular/forms';
import { ISICCardService } from 'app/modules/isic-card/services/isic-card.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sso',
  templateUrl: './sso.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService, SsoService]
})
export class SsoComponent implements OnInit {

  public coreConfig: any;
  public agentCode: string;
  public themeName: string;
  public submitted: any;
  public showLoader: boolean = false;
  public loginForm: FormGroup;
  public error = '';
  public parseID: any;
  public product : any;
  public currencyCode : any;
  public currencyCodeAmount  :any;
  public type : any;
  public xAuthToken : any;

  private _unsubscribeAll: Subject<any>;
  public requestLoginModel = new RequestLoginModel();
  public requestDirectLogin: RequestDirectLogin = new RequestDirectLogin();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _coreConfigService: CoreConfigService,
    public router: Router,
    public route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService,
    public sosService: SsoService,
    public toast : ToastrService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit() {
    localStorage.clear();

    this.route.queryParams.subscribe(async params => {
      var getBase64Data = params['data'];
      this.parseID = atob(getBase64Data);
      this.sosService.getDataForSso(this.parseID).subscribe(resp => {
        var responseJSON = resp.body;
        console.log(JSON.stringify(responseJSON));

        this.product = responseJSON['product'];
        this.currencyCode = responseJSON['requiredCurrencyCode'];
        this.currencyCodeAmount = responseJSON['requiredCurrencyCodeAmount'];
        //responseJSON['requiredCurrencyCodeAmount']=2000;
        this.type = responseJSON['type'];
        localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, responseJSON['xAuthToken']);
        localStorage.setItem(ConstantsKey.SSO_RESP,JSON.stringify(responseJSON));
        localStorage.setItem(ConstantsKey.PRODUCT, this.product);
        this.sosService.getConsumerProfileData().subscribe(resp => {
          this.showLoader = true;
          var respBody = JSON.parse(JSON.stringify(resp.body));
         localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.id);
         localStorage.setItem(ConstantsKey.PAXID, respBody.paxResponse.id);
         localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody));
         if(respBody?.paxResponse?.pan){
          localStorage.setItem(ConstantsKey.CONSUMER_PAN, respBody?.paxResponse?.pan);
        }
          if(this.product == 'SEND-MONEY'){
            this.router.navigate(['/send-money-dashboard']);
          }else if(this.product == 'BUY-FOREX'){
            this.router.navigate(['/buy-forex-landing']);
          }else  if (this.product == 'SELL-FOREX'){
              this.router.navigate(['/buy-forex-landing']);
          }else  if (this.product == 'BUY-CASH'){
              this.router.navigate(['/currency-notes-landing']);
          }else  if (this.product == 'SELL-CASH'){
              this.router.navigate(['/sell-currency-notes']);
          }
          else {
              this.router.navigate(['/new-dashboard']);
          }
          
          console.log(respBody);
        }, error => {
          this.showLoader = false;
          this.error = error.error.message;
        });
      }, error => {
        this.toast.error(error.error);
      });
    });
  }
}

 
