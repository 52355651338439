<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <label for="">From</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="Select Start Date" (click)="startDatePicker.toggle()" name="Start Date Picker" [(ngModel)]="startDate" ngbDatepicker #startDatePicker="ngbDatepicker" (dateSelect)="onDateSelect($event, 'startDate')" readonly
                                    [maxDate]="maxDate">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="startDatePicker.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <label for="">To</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="Select End Date" (click)="endDatePicker.toggle()" name="End Date Picker" [(ngModel)]="endDate" ngbDatepicker #endDatePicker="ngbDatepicker" (dateSelect)="onDateSelect($event, 'endDate')" readonly [maxDate]="maxDate"
                                    [minDate]="minDateForEndDate">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="( enableEndDatePicker ) ? endDatePicker.toggle() : showErrorMessage()" type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-12 mt-23">
                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect>
                            <span class="align-middle " (click)="getFilteredOrderList()">Apply Filters</span>
                        </button>
                    </div>
                </div>

                <div class="col-md-10 vcip-alert-box" *ngIf="!isVcipComplete">
                    <div class="row">
                        <div class="col-md-7">
                            <div style="margin-top:8px">
                                Your VKYC is pending and needs to be completed to Process the Transaction.
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-primary fs-10  w-100" rippleEffect (click)="initiateVcip()">
                                  Complete VKYC
                                </button>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-20" *ngIf="noDataFound">
                        <div class="text-black fw-500 ">
                            <div class="fs-12"> No orders found </div>
                        </div>
                    </div>
                </div>
                <loader *ngIf="showLoader"></loader>
                <div class="row mt-20" *ngIf="!noDataFound">
                    <div class="col-md-12 card-body" *ngFor="let singleOrder of orderListItems" style="border-bottom:1px dashed ">
                        <div class="" (click)="goToOrderDetails(singleOrder.orderId, singleOrder.date, singleOrder.time)" style="cursor: pointer; box-shadow: 0 0 0 0 !important;">
                            <div class="row">
                                <div class="col-md-12 col-12 mb-10">
                                    <span class="card-title fs-14 fw-600 text-black">Order Id : #{{singleOrder.orderId}}
                                    </span>
                                    <span class="fs-11 fw-600" [ngClass]="(singleOrder.orderStatus == 'EXPIRED' || singleOrder.orderStatus ==  'CANCELLED' || singleOrder.orderStatus ==  'REJECTED') ?  'badge badge-danger' : (singleOrder.orderStatus ==  'IN_PROCESS') ? 'badge badge-warning' : 'badge badge-success'">
                                        {{singleOrder.orderStatus }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Order Type : </span>
                                    <span class="fs-11 text-black" *ngIf="singleOrder.sellType == 'SELL'">BUY</span>
                                    <span class="fs-11 text-black" *ngIf="singleOrder.sellType == 'BUY'">SELL</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Product : </span>
                                    <span class="fs-11 text-black"> {{ singleOrder.productGroup}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <span class="card-text fs-11 text-black fw-600">Created On : </span>
                                    <span class="fs-11 text-black">{{ singleOrder.date}}, {{singleOrder.time}}</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleOrder.productGroup == 'REMITTANCE' ">
                                <div class="col-md-4 col-12 mt-23">
                                    <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect>
                                        <span class="align-middle"
                                            (click)="navigateToProceedOrder(singleOrder.orderId)">Re-Order</span>
                                    </button>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-12 col-12">
                                <!-- <span class="card-text fs-11 text-black fw-600">Order Status : </span> -->
                                <!-- <span class="fs-11 fw-600" [ngClass]="(singleOrder.orderStatus == 'EXPIRED' || singleOrder.orderStatus ==  'CANCELLED' || singleOrder.orderStatus ==  'REJECTED') ? 'badge badge-danger' : 'badge badge-warning'"> {{singleOrder.orderStatus }}</span> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template let-modal #vcipCompleteAfterOrder>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body  fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="font-bold-black  mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p class="font-normal-black">Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p class="font-bold-red">Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="font-bold-black  mb-20">{{vkycTimingText}}</p>

                <p class="font-normal-blue mb-20 ">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <!-- <button class="btn vcip-btn fs-13" (click)="proceedWithourVCIP()"> Schedule your VKYC later</button> &nbsp; &nbsp; -->
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>