import { Component, OnInit, ViewEncapsulation, VERSION } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RequestLoginModel } from '../../models/login.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
import * as shajs from 'sha.js';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'app-login-with-otp',
  templateUrl: './login-with-otp.component.html',
  styleUrls: ['./login-with-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService]
})
export class LoginWithOTPComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public coreConfig: any;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  public requestLoginModel = new RequestLoginModel();
  public loginForm: FormGroup;
  public loginWithPassswordForm: FormGroup;

  public showLoader: boolean = false;
  public toast: ToastrService
  public showLoginWithPassword: boolean = false;
  public cryptoUtils: CryptoUtils = new CryptoUtils();

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService,
    public _formBuilder: FormBuilder,
    public toastr: ToastrService
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  get f() {
    return this.loginForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      otp: ['', Validators.required]
    });

    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  onSubmit() {
    this.submitted = true;
    this.showLoader = true;
    if (this.loginForm.invalid) {
      this.showLoader = false;
      return;
    } else {
      this.error = '';
      this.requestLoginModel.type = "MOBILE";
      if (this.requestLoginModel.otp.toString().length == 6) {
        this.loginService.loginWithOtp(this.requestLoginModel).subscribe(resp => {
          this.showLoader = true;
          var respBody = JSON.parse(JSON.stringify(resp.body));
          localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
          localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
          localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
          localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

          // localStorage.setItem(ConstantsKey.AGENT_CODE, "WSFx-agent");
          // localStorage.setItem(ConstantsKey.AGENT_KEY, "kjkjafhkhds-agkjag-099");

          this._router.navigate(['/new-dashboard']);
        }, error => {
          this.showLoader = false;
          this.error = error.error.message;
        });
      } else {
        this.showLoader = false;
        this.error = "OTP must be 6 characters long";
      }
    }
  }

  loginWithPassword() {
    this.submitted = true;
    this.showLoader = true;

    this.error = '';
    this.requestLoginModel.type = "MOBILE";
    this.requestLoginModel.passwordType = "PASSWORD";
    this.requestLoginModel.token = shajs('sha256').update(this.requestLoginModel.token).digest('hex') + '@1';
    this.requestLoginModel.latitude = null;
    this.requestLoginModel.longitude = null;

    this.loginService.loginWithPassword(this.requestLoginModel).subscribe(resp => {
      this.showLoader = false;
      var respBody = JSON.parse(JSON.stringify(resp.body));
      localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
      localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
      localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

      // localStorage.setItem(ConstantsKey.AGENT_ID, "WSFx-agent");
      // localStorage.setItem(ConstantsKey.AGENT_KEY, "kjkjafhkhds-agkjag-099");

      this._router.navigate(['/new-dashboard']);
    }, error => {
      this.showLoader = false;
      this.error = error.error.message;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getOTPForLogin() {
    this.loginService.getMobileOTPLogin(this.requestLoginModel.id).subscribe((resp => {
      this.toastr.success("OTP sent to registered Mobile Number and Email Id");
    }), error => {
      this.toastr.error(error.error.message);
    });
  }
}
