import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../../services/order.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: [
    './order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public startDateForFilter: any;
  public endDateForFilter: any;
  public orderListItems: any;
  public date: any;
  public maxDate: any;
  public modalReference: any;
  public selectedFromDate: any;
  public showLoader: boolean = true;
  public minDateForEndDate: any;
  public maxDateForEndDate: any;
  public resGetVcipStatus: any;
  public isVcipComplete: boolean = false;
  public enableEndDatePicker: boolean = false;
  public noDataFound: boolean = false;
  public consumerDetail: any;
  @ViewChild('vcipCompleteAfterOrder') vcipCompleteAfterOrder: TemplateRef<any>;
  vkycTimingText: any;

  constructor(
    private orderService: OrderService,
    public updateProfileService: UpdateProfileService,
    public router: Router,
    public toast: ToastrService,
    public modalService :NgbModal
  ) {
    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    var startDate = new Date();
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth() + 1;
    let startDay = 1;
    this.startDate = { 'year': startYear, 'month': startMonth, 'day': startDay };

    this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  }

  ngOnInit(): void {
    this.vkycTimingText= Constants.vkycTimingText;
    this.getProfileDetails();
    this.getOrderList();
  }

  goToOrderDetails(orderID) {
    this.router.navigate(['/order-details', orderID]);
  }

  getOrderList() {
    this.showLoader = true;
    this.orderService.getListOrderShortSnippets().subscribe((resp => {
      this.orderListItems = resp.body;
      this.showLoader = false;
      this.noDataFound = false;
      if (this.orderListItems.length == 0) {
        this.noDataFound = true;
      }
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  onDateSelect(event, sourceDate) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    if (sourceDate == 'startDate') {
      this.startDateForFilter = year + "-" + month + "-" + day;
      this.selectedFromDate = this.startDateForFilter;
      this.enableEndDatePicker = true;
      let minDateForEndDate = this.selectedFromDate;
      this.minDateForEndDate = { year: parseInt(minDateForEndDate.split("-")[0]), month: parseInt(minDateForEndDate.split("-")[1]), day: parseInt(minDateForEndDate.split("-")[2]) };

    } else if (sourceDate == 'endDate') {
      this.endDateForFilter = year + "-" + month + "-" + day;
    }
  }

  getFilteredOrderList() {
    this.showLoader = true;
    this.startDateForFilter = this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day;
    this.endDateForFilter = this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day;
    this.orderService.getFilteredOrderList(this.startDateForFilter, this.endDateForFilter).subscribe((resp => {
      this.orderListItems = resp.body;
      this.showLoader = false;
      this.noDataFound = false;
      if (this.orderListItems.length == 0) {
        this.noDataFound = true;
      }
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  showErrorMessage() {
    this.toast.error('Please select from date first');
  }

  navigateToProceedOrder(orderId) {
    var redirectionUrl = "/proceed-and-pending-order/" + orderId + "/RE-ORDER";
    this.router.navigate([redirectionUrl])
  }


  initiateVcip() {
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }


  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if (!this.isVcipComplete) {
        this.getVCIPStatus();
      }
    });
  }

  async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete = true;
      }else { 
        //this.modalReference = this.modalService.open(this.vcipCompleteAfterOrder, { size: 'xl', centered: true });
      }
    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
  }



}


