<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <loader *ngIf="showLoader"></loader>
        <section>
            <div class="row">
                <div class="col-md-9 mb-2 mb-md-0">
                    <div class="d-flex align-items-center">
                        <div class="avatar avatar-tag bg-light-primary mr-1">
                            <i data-feather="credit-card" class="font-medium-4"></i>
                        </div>
                        <div>
                            <h5 class="mb-0"> {{pageTitle}} </h5>
                            <span> </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 mb-2 mb-md-0 text-right">
                    <button type="button" class="btn btn-primary fs-11" (click)="openFilterModal(filterModal)">
                        <i class="fa fa-sliders"></i> Filter
                      </button> &nbsp;
                    <button type="button" class="btn btn-primary fs-11" (click)="getDataForSort()">
                        <i class="fa fa-light fa-sort"></i> Sort By
                          </button>
                </div>
            </div>
            <div *ngIf="noInsuranceFound" class="col-md-12 text-center">
                <div class="text-center">
                    No travel insurance premium found !
                </div>
                <button type="button" class="btn btn-primary fs-11 mt-20" rippleEffect (click)="clearFilters()">
                  Clear filters
                      </button>
            </div>
            <div class="row mt-20">
                <div class="col-md-12">
                    <div class="card-body grey-box mb-20" *ngFor="let singlePlan of planDetails; let i=index">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="fw-600 fs-12">{{singlePlan?.companyName}}</div>
                                <div class="fs-10 mt-5">{{singlePlan?.planName}} </div>
                                <div class="fs-10 mt-5">Plan Short Name : {{singlePlan?.planShortName}} </div>
                                <div class="fs-10 mt-5">Age Limit : {{singlePlan?.ageLimit}} </div>
                                <div class="fs-10 mt-5">Day Limit : {{singlePlan?.dayLimit}} </div>

                                <div class="mt-20">
                                    <div class="custom-control custom-control-primary custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="colorCheck-{{i}}" (click)="clickToCheckTermAndCondition(singlePlan,i)" [checked]="singlePlan?.isChecked" />
                                        <label class="custom-control-label fs-11" for="colorCheck-{{i}}">I agree to the  
                                            <a [href]="tncUrl" target="_blank" class="blue-link">Terms and Conditions</a> 
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="col-md-3 mt-8 text-right">
                                        <label>Sum Insured</label>
                                    </div>
                                    <div class="col-md-5">
                                        <input type="text" placeholder="Sum Assured" class="form-control fs-11" value={{singlePlan?.sumInsured}}> </div>
                                    <div class="col-md-4 blue-link fs-18 pl-0 fw-600 mt-5"> ₹ {{singlePlan?.premium}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 text-right">
                                <a [href]="getcoveragePdfUrl(singlePlan?.planName)" target="_blank"><button type="button" class="btn btn-outline-secondary fs-11" >
                                        View Details 
                                </button>
                                </a> &nbsp;
                                <button type="button" class="btn btn-primary fs-11" rippleEffect (click)="goToCart(singlePlan)">
                                          Buy Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<ng-template #planDetailsModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title text-center" id="myModalLabel1">Coverage Details</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-black fw-500 mt-10 mb-10 fs-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Coverage</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cruise Cover</td>
                            <td>$ 1000</td>
                        </tr>
                        <tr>
                            <td>Loss of Gadgets</td>
                            <td>$ 500</td>
                        </tr>
                        <tr>
                            <td>Political Risk and Catastrophe Evacuation</td>
                            <td>$ 2500</td>
                        </tr>
                        <tr>
                            <td>Flight Delay(Airlines)</td>
                            <td>$ 500</td>
                        </tr>
                        <tr>
                            <td>Financial Emergency Assistance</td>
                            <td>$ 500</td>
                        </tr>
                        <tr>
                            <td>Bail Bond</td>
                            <td>$ 500</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" style="cursor: pointer;">
            <a target="_blank" href={{coveragePDFURL}}> View PDF For More Details</a></div>
    </div>
</ng-template>

<ng-template #filterModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title text-center" id="myModalLabel1">FILTER BY</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <ng-select class="fs-10 mt-20" [clearable]="false" [items]="companyNameItems" bindLabel="companyName" bindValue="companyName" placeholder="Insurance Company" name="companyName" [searchable]="false" [(ngModel)]="companyName" required>
            </ng-select>
            <ng-select class="fs-10 mt-20" [clearable]="false" [items]="sumInsuredItems" bindLabel="sumInsureds" bindValue="sumInsureds" placeholder="Sum Insured" name="suminsured" [searchable]="false" [(ngModel)]="sumInsured" required>
            </ng-select>
            <ng-select class="fs-10 mt-20" [clearable]="false" [items]="planNameItems" bindLabel="planNames" bindValue="planNames" placeholder="Plan Type" name="planType" [searchable]="false" [(ngModel)]="planType" required>
            </ng-select>
            <button type="button" class="btn btn-primary fs-11 mt-20" rippleEffect (click)="getPlanDetails(false)">
                     Apply
                  </button>
        </div>
    </div>

</ng-template>