import { Injectable, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class DateUtils {

  public static getNgbDateStructToDate(ngbDateStruct) {
    var returnDate = ngbDateStruct.year;
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.month).slice(-2);
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.day).slice(-2);
    return returnDate;
  }
  public static getNgbDateStructToDatev2(ngbDateStruct) {
    // var returnDate = ngbDateStruct.year;
    // returnDate = returnDate + "-" + ('0' + ngbDateStruct.month).slice(-2);
    // returnDate = returnDate + "-" + ('0' + ngbDateStruct.day).slice(-2);
    var returnDate=('0' + ngbDateStruct.day).slice(-2);
    returnDate = returnDate + "/" + ('0' + ngbDateStruct.month).slice(-2);
    returnDate = returnDate + "/" + ngbDateStruct.year;



    return returnDate;
  }

  public static getNgbDateStructToCardPinDate(ngbDateStruct) {
    var returnDate = ('0' + ngbDateStruct.day).slice(-2) + ('0' + ngbDateStruct.month).slice(-2) + ngbDateStruct.year;
    return returnDate;
  }

  ///expected input : YYYY-MM-DD return NgbDateStruct
  public static ngbDateStructDateConverter(strDate) {
    var getYear = strDate.substring(0, 4);
    var getMonth = strDate.substring(5, 7);
    var getDay = strDate.substring(8);

    let initializedDate: NgbDateStruct = {
      day: parseInt(getDay),
      month: parseInt(getMonth),
      year: parseInt(getYear),
    }
    return initializedDate;
  }


   
}