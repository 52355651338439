import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'link-card',
  templateUrl: './link-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LinkCardComponent implements OnInit {
 
  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private _router: Router,
  ) {
     
  }

  ngOnInit() { }
  
  goToExistingCardLink(){
    this._router.navigate(['/link-existing-card']);
  }

  goToHome(){
    this._router.navigate(['/new-dashboard']);
  }
}
