<div class="content-wrapper">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <img src="assets/images/smartfx-portal-assets/vector-link-existing-card.png " style="width:100% ">
                    </div>
                    <div class="col-8">
                        <div class="col-md-10">
                            <br>
                            <div class="row">
                                <div class="content-header-title float-left mb-0 fw-600 fs-30  bold-text">
                                    Hey!
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <div class="fs-18">
                                    We have identified
                                </div>
                                <div class="fs-18">
                                    you as a <span class="bold-text fs-25">WSFx </span>
                                </div>
                                <div class="fs-18">
                                    card-holder.
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-10">
                                <div class="fs-14 float-left mb-0" style="text-decoration: underline;">
                                    Please Link your existing card here...
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-10">
                                <button type="button" rippleEffect class="btn btn-primary btn-gradient btn-submit fs-13" (click)="goToExistingCardLink()" style="padding:10px !important">
                                    Link Existing Card
                                </button>
                            </div>
                        </div><br><br><br><br><br>
                        <div style="text-align: end;">
                            <a class="fs-12" (click)="goToHome()" style="padding:7px !important;text-decoration: underline;">
                                Skip
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>