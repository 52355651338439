import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { NewDashboardComponent } from './views/new-dashboard.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

const routes = [
  {
    path: 'new-dashboard',
    component: NewDashboardComponent,
    data: { animation: 'NewDashboardComponent' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    NewDashboardComponent
  ],
  imports: [RouterModule.forChild(routes),
  RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    LoaderRoutingModule
  ],
  exports: [],
  providers: []
})
export class NewDashboardRoutingModule { }
