import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { RequestLoginModel } from "../models/login.models";

@Injectable()
export class LoginService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    loginWithOtp(requestLoginModel: RequestLoginModel) {
        var resourceUrl = "consumer/otpLogin";
        return this.makePostCall(resourceUrl, requestLoginModel);
    }

    loginWithPassword(requestLoginModel: RequestLoginModel) {
        var resourceUrl = "consumers/login";
        return this.makePostCall(resourceUrl, requestLoginModel);
    }

    getMobileOTPLogin(mobileNo) {
        var resourceUrl = 'otpForMobileLogin?mobileNo=' + mobileNo;
        return this.makeGetCall(resourceUrl);
    }

    getMobileEmailOTPLogin(mobileNo,email) {
        var resourceUrl = 'otpForMobileLogin?mobileNo=' + mobileNo;
        return this.makeGetCall(resourceUrl);
    }

}
