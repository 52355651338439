import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { RequestDirectLogin } from "../models/init-widget.model";

@Injectable()
export class InitWidgetService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    directLogin(requestDirectLogin: RequestDirectLogin) {
        var resourceUrl = "widget/directLogin";
        return this.makePostCall(resourceUrl, requestDirectLogin);
    }
}
