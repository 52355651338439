import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class BranchNetworkService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }
}