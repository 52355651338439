<div class="content-wrapper container-xxl p-0">
    <div class="content-body card card-body">
        <div class="row">
            <div class="col-md-12 fw-600 fs-13 mb-20">Enquiry Form</div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <form class="form-horizontal" (ngSubmit)="setRateAlert(SetRateAlertFormForSell.form.valid)" #SetRateAlertFormForSell="ngForm">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1" name="sellForexCheckbox" [(ngModel)]="sellForexCheckbox" />
                                        <label class="custom-control-label fs-13" for="customCheck1">Forex</label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2" name="sellCashCheckbox" [(ngModel)]="sellCashCheckbox" />
                                        <label class="custom-control-label fs-13" for="customCheck2">Cash</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-15">
                                <div class="form-group col-md-3">
                                    <label class="form-label" for="country">Select Country*</label>
                                    <ng-select class="fs-12" [clearable]="false" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" placeholder="Select Country" name="countryList" #countryRef="ngModel" [(ngModel)]="setRateAlertRequestModel.country" [class.error]="SetRateAlertFormForSell.submitted && countryRef.invalid"
                                        [searchable]="false" required>
                                    </ng-select>
                                    <span *ngIf="SetRateAlertFormForSell.submitted && countryRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="countryRef.errors.required">Please select country !</small>
                                                </span>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Highest Rate*</label>
                                    <input type="text" class="form-control fs-12" placeholder="Highest Rate*" name="highest_rate" #highestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.upperLimit" [class.error]="SetRateAlertFormForSell.submitted && highestRateRef.invalid" required/>
                                    <span *ngIf="SetRateAlertFormForSell.submitted && highestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="highestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label" for="country">Lowest Rate*</label>
                                    <input type="number" class="form-control fs-12" placeholder="Lowest Rate*" name="lowest_rate" #lowestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.lowerLimit" [class.error]="SetRateAlertFormForSell.submitted && lowestRateRef.invalid" required
                                    />
                                    <span *ngIf="SetRateAlertFormForSell.submitted && lowestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="lowestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                </div>
                                <div class="col-md-3 col-3 mb-20  mt-20">
                                    <button type="submit" rippleEffect class="btn btn-primary fs-12">Set Rate Alert</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>