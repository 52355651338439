import { PasswordChangeModel } from "app/modules/security-setting/models/security-setting.model";

export class RequestLoginModel {
    type: string;
    id: string;
    token: string;
    pin: string;
    portal: string;
    firebaseToken: string;
    latitude: string;
    longitude: string;
    mobileNo: string;
    otp: string;
    passwordType: string;
}

// login with password

// type:'MOBILE'
// id:username/mobileno
// token : Password with sha@1
// passwordtype:'PASSWORD'