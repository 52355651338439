<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal" (ngSubmit)="addRelation(AddRelationForm.form.valid)" #AddRelationForm="ngForm">
                    <loader *ngIf="showLoader"></loader>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>Relationship*</b></label>
                            <ng-select placeholder="Relationship" class="fs-12" style="border-color:black" [items]="relationDropdownItems" bindValue="relationDropdownItems" name="rselationship" [(ngModel)]="addRelationshipModel.relationship" #relationshipRef="ngModel" [class.error]="AddRelationForm.submitted && relationshipRef.invalid"
                                required [clearable]="false" [searchable]="false" [readonly]="freezeInputField">
                            </ng-select>
                            <span *ngIf="AddRelationForm.submitted && relationshipRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">Please select relationship!</small>
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>Name as per PAN*</b></label>
                            <input type="text" name="name_as_per_pan" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN*" [(ngModel)]="addRelationshipModel.name" #panNameRef="ngModel" [class.error]="AddRelationForm.submitted && panNameRef.invalid"
                                (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" required [readonly]="freezeInputField">
                            <span *ngIf="AddRelationForm.submitted && panNameRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="panNameRef.errors.required">Please enter name !</small>
                            </span>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>PAN Number*</b></label>
                            <!-- <input type="text" name="pan_number" class="form-control fs-12" style="border-color:black" placeholder="PAN Number*" [(ngModel)]="addRelationshipModel.pan" #panNoRef="ngModel" [class.error]="AddRelationForm.submitted && panNoRef.invalid" (keypress)="alphaNumberOnly($event)"
                                (paste)="onPaste($event)" required [readonly]="freezeInputField" (input)="getPanNameOnEnter()"> -->
                            <input type="text" name="pan_number" class="form-control fs-12 text-uppercase" style="border-color:black" placeholder="PAN Number*" (input)="getPanNameOnEnter()" [(ngModel)]="addRelationshipModel.pan" #panNoRef="ngModel" [class.error]="AddRelationForm.submitted && panNoRef.invalid"
                                (keydown.space)="$event.preventDefault()" (keypress)="alphaNumberOnly($event)" required [readonly]="freezeInputField" (paste)="onPaste($event)" required>
                            <span *ngIf="AddRelationForm.submitted && panNoRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="panNoRef.errors.required">Please enter PAN!</small>
                            </span>
                        </div>

                        <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-9':showCheckedMarkForPANCard, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPANCard}">
                            <div class="form-group">
                                <label for="basicInputFile"><b>Select PAN</b></label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" style="border-color:black" id="customFile" (change)="fileUploadPaxDocument($event,'PANCARD')" />
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-3" *ngIf="showCheckedMarkForPANCard">
                            <div class="input-group-append mt-23" style="height: 38px;">
                                <span class="input-group-text" style="cursor: pointer;"><img
                                    src="assets/images/icon/view-document.png"
                                    style="width:20px;width:20px"
                                    (click)="downloadUploadedDoc()"></span>

                                <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"  class="w-h-20" ></span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>Address Proof</b></label>
                            <ng-select class="fs-12" [clearable]="false" [searchable]="false" [items]="addressProofItems" bindValue="addressProofItems" placeholder="Address Proof" name="addressproof" [(ngModel)]="selectedAddressProofType" (change)="getSelectedAddressProofType(selectedAddressProofType)">
                            </ng-select>
                        </div>

                        <div *ngIf="showFileUploadCartDocument" [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMark, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMark}">
                            <div class="form-group">
                                <label for="basicInputFile"><b> {{ showPassportFields ? 'Passport Front Page' : selectedAddressProofType }} * </b></label>
                                <div class="custom-file">
                                    <input type="file" id="customFile" class="custom-file-input fs-12" style="border-color:black" (change)="fileUploadPaxDocument($event,selectedAddressProofType)" />
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-5" *ngIf="showCheckedMark">
                            <div class="input-group-append mt-23" style="height: 38px;">
                                <span class="input-group-text">
                                    <img src="assets/images/icon/view-document.png" (click)="viewLocalDocument()" class="w-h-20">
                                </span>
                                <span class="input-group-text"><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                            </div>
                        </div>

                        <div *ngIf="showFileUploadCartDocument && showPassportFields " [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMarkForPassportBack, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPassportBack}">
                            <div class="form-group">
                                <label for="basicInputFile"><b> Passport Address Back* </b></label>
                                <div class="custom-file">
                                    <input type="file" id="customFile" class="custom-file-input fs-12" style="border-color:black" (change)="fileUploadPaxDocument($event,'PASSPORT_ADDRESS_PAGE')" />
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-5" *ngIf="showPassportFields && showCheckedMarkForPassportBack">
                            <div class="input-group-append mt-23" style="height: 38px;">
                                <span class="input-group-text">
                                    <img src="assets/images/icon/view-document.png" (click)="viewPassportBackPage()" class="w-h-20">
                                </span>
                                <!-- <span class="input-group-text link" (click)="downloadUploadedDoc(singleDocument)"><img
                                    src="assets/images/icon/view-document.png" class="w-h-20">
                            </span> -->
                                <span class="input-group-text"><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label"><b>Name as per Passport*</b></label>
                            <input type="text" name="name_as_per_passport" class="form-control fs-12" style="border-color:black" placeholder="Name as per Passport*" [(ngModel)]="addRelationshipModel.name" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)">
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label"><b>Passport Number*</b></label>
                            <input type="text" name="passport_no" class="form-control fs-12" style="border-color:black" placeholder="Passport Number*" [(ngModel)]="addRelationshipModel.passportNumber" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)">
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label"><b>Passport Issue Date*</b></label>
                            <div class="input-group">
                                <input class="form-control fs-12" name="passportIssueDate" style="border-color:black" [(ngModel)]="addRelationshipModel.passportIssueDate" ngbDatepicker #basicDP1="ngbDatepicker" required [minDate]="passportIssueMinDate">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP1.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label"><b>Passport Expiry Date*</b></label>
                            <div class="input-group">
                                <input class="form-control fs-12" name="passportExpiryDate" style="border-color:black" [(ngModel)]="addRelationshipModel.passportExpiryDate" ngbDatepicker #basicDP2="ngbDatepicker" required [minDate]="passportExpiryMinDate">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP2.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label">Passport File Number*</label>
                            <input type="text" id="passport_file_no" class="form-control fs-12" placeholder="Passport File Number*" [(ngModel)]="updateProfileModel.passportFileNumber">
                        </div> -->

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group" *ngIf="showPassportFields">
                            <label class="form-label"><b>Passport Issue Place*</b></label>
                            <input type="text" name="passport_issue_place" class="form-control fs-12" style="border-color:black" placeholder="Passport Issue Place*" [(ngModel)]="addRelationshipModel.passportIssuePlace" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)">
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>Mobile No*</b></label>
                            <input type="text" name="mobile_no" class="form-control fs-12" style="border-color:black" placeholder="Mobile No*" [(ngModel)]="addRelationshipModel.mobile" #mobileRef="ngModel" [class.error]="AddRelationForm.submitted && mobileRef.invalid" maxlength="10"
                                [readonly]="freezeInputField" (keypress)="keyPressNumbers($event)">
                            <span *ngIf="AddRelationForm.submitted && mobileRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="mobileRef.errors.required">Please enter phone number!</small>
                            </span>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>Email ID*</b></label>
                            <input type="text" name="email_id" class="form-control fs-12" style="border-color:black" placeholder="Email ID*" [(ngModel)]="addRelationshipModel.email" #emailRef="ngModel" [class.error]="AddRelationForm.submitted && emailRef.invalid" required>
                            <span *ngIf="AddRelationForm.submitted && emailRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="emailRef.errors.required">Please enter e-mail ID!</small>
                            </span>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <label class="form-label"><b>DOB*</b></label>
                            <div class="input-group ">
                                <input class="form-control fs-12" placeholder="yyyy-mm-dd" style="border-color:black" name="Basic Date Picker" [(ngModel)]="addRelationshipModel.dob" ngbDatepicker #basicDP="ngbDatepicker" #dobRef="ngModel" [class.error]="AddRelationForm.submitted && dobRef.invalid"
                                    required (dateSelect)="onDateSelect($event)" [minDate]="{year: 1950, month: 1, day: 1}" [maxDate]="DOBMaxDate" [readonly]="freezeInputField">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="(freezeInputField) ? null : basicDP.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>
                            <!-- <label class="form-label ">DOB*</label>
                            <div class="input-group ">
                                <input class="form-control fs-12 " placeholder="yyyy-mm-dd " name="Basic Date Picker " [(ngModel)]="addRelationshipModel.dob " #dobRef="ngbDatepicker " [class.error]="AddRelationForm.submitted && dobRef.invalid " required>
                                <div class="input-group-append ">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon " type="button " (click)="dobRef.toggle() " rippleEffect></button>
                                </div>
                            </div> -->
                            <span *ngIf="AddRelationForm.submitted && dobRef.invalid " class="invalid-form ">
                                <small class="form-text text-danger " *ngIf="dobRef.errors.required ">Please select DOB!</small>
                            </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group ">
                            <label class="form-label "><b>Address as per proof provided*</b></label>
                            <input type="text " name="address_as_per_proof " class="form-control fs-12 " style="border-color:black" placeholder="Address as per proof provided* " [(ngModel)]="addRelationshipModel.address " #addressRef="ngModel" [class.error]="AddRelationForm.submitted
                                && addressRef.invalid " maxlength="100" required>
                            <span *ngIf="AddRelationForm.submitted && addressRef.invalid " class="invalid-form ">
                            <small class="form-text text-danger " *ngIf="addressRef.errors.required ">Please enter details of your address as per proof!</small>
                        </span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group ">
                            <label class="form-label "><b>Select occupation*</b></label>
                            <ng-select class="fs-12" style="border-color:black" [clearable]="false" [items]="occupationItems " bindValue="occupationItems " placeholder="Occupation " name="occupation " [(ngModel)]="addRelationshipModel.occupation " #occupationRef="ngModel" [class.error]="AddRelationForm.submitted
                              && occupationRef.invalid " required [searchable]="false" [readonly]="freezeInputField">
                            </ng-select>
                            <span *ngIf="AddRelationForm.submitted && occupationRef.invalid " class="invalid-form ">
                                <small class="form-text text-danger " *ngIf="occupationRef.errors.required ">Please select occupation!</small>
                        </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="invalid-state fs-11 text-black mt-10 note-bg">*Please upload documents in <span class="fw-600 "> JPEG, PNG, or PDF</span> format, and the file size should be under <span class="fw-600 ">1MB </span></div>
                        </div>
                    </div>
                    <div class="row mt-20">
                        <div class="col-md-6 col-6">
                            <button type="button" class="btn btn-primary  fs-12" rippleEffect routerLink="/list-relationship">Back</button>
                        </div>
                        <div class="col-md-6 col-6 text-right ">
                            <button class="btn btn-primary  fs-12" rippleEffect *ngIf="!updateRelationshipDetails">Add Relation</button>
                            <button class="btn btn-primary  fs-12" rippleEffect *ngIf="updateRelationshipDetails">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>