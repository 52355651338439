import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class OfferRewardsService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  getOffers() {
    var resourceUrl = "promoCodeFetchActive/CONSUMER";
    return this.makeGetCall(resourceUrl);
  }
  
  getConsumerCredits(){
    var resourceUrl = "consumerCredits";
    return this.makeGetCall(resourceUrl);
  }

  getconsumerCreditClaimHistory(){
    var resourceUrl = "consumerCreditClaimHistory";
    return this.makeGetCall(resourceUrl);
  }

  
  getConsumerRewards(unlockedId){
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    return this.makeGetCall("rewards?consumerId=" + consumerId + "&unlocked=" + unlockedId);
  }
  
  unlockReward(rewardId){
    var  request = {};
    return this.makePatchCall("unlockReward/" + rewardId , request)
  }

  getConsumerReferral(){
    return this.makeGetCall("consumerReferral");
  }

  getClaimedrewards(){
    return this.makeGetCall("claimedRewards");
  }

  claimReward(id){
    var request ={};
    return this.makePostCall("claimReward?rewardId=" + id, request)
  }
}