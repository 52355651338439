import { Component, OnInit} from '@angular/core'
import Stepper from 'bs-stepper';

@Component({
  selector: 'tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls:['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  private modernVerticalWizardStepper: Stepper;
  constructor() {}

  ngOnInit() {
  }

 
  
  
}
