import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { Router } from '@angular/router';
import { BeneficiaryService } from '../../services/beneficiary.service';
import { ToastrService } from 'ngx-toastr';
import { PagesList } from 'app/shared/constant/page-list';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PurposeListBeneficiary } from 'app/shared/constant/purpose-list-beneficiary';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { Utils } from 'app/shared/utility/utils';

@Component({
  selector: 'list-beneficiary',
  templateUrl: './list-beneficiary.component.html',
  styleUrls: ['../../view/beneficiary.scss',],
  encapsulation: ViewEncapsulation.None
})
export class ListBeneficiaryComponent implements OnInit {
  public showDetailsBeneficiary = false;
  public beneficiaryListItems = [];
  public purposeList: Array<String> = [];
  public purposeListAll: any = [];
  public noDataFound: boolean = false;
  public purposeDetails: any;
  public remittanceType: any;
  public selectedPurposeObject: any;
  public singleBeneficiaryData: any;
  public showLoader: boolean = false;
  public buttonText: string = 'View More';
  public purposeListRemittance: any = [];
  public selectedPurposeItem: any;
  public countryListItems ;
  public countryName  : any;

  constructor(
    private _coreSidebarService: CoreSidebarService,
    public masterDataService : MasterDataService,
    public router: Router,
    public beneficiaryService: BeneficiaryService,
    public toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getPurposeList();
    this.getCountryList();
  }

  getPurposeList() {
    var purposeCodeForBeneficairyAdd = sessionStorage.getItem(ConstantsKey.BENEFICIARY_PURPOSE_CODE);
    if (purposeCodeForBeneficairyAdd) {
      this.getBeneficiaryList(purposeCodeForBeneficairyAdd);
      let tempPurposeListRemittance = PurposeListBeneficiary.getPurposeObjectByPurposeCode(purposeCodeForBeneficairyAdd);
      this.purposeListRemittance.push(tempPurposeListRemittance);
      console.log(this.purposeListRemittance);
      sessionStorage.removeItem(ConstantsKey.BENEFICIARY_PURPOSE_CODE);
    } else {
      this.purposeListRemittance = PurposeListBeneficiary.getPurposeListBeneficiary();
      // var listItems = this.purposeListRemittance;
      // this.purposeListRemittance = listItems.sort((a, b) => {
      //   if (a.key < b.key) {
      //     return -1;
      //   }
      //   if (a.key > b.key) {
      //     return 1;
      //   }
      //   return 0;
      // });
      // console.log(this.purposeListRemittance[0]);
      this.getBeneficiaryList(this.purposeListRemittance[0].purposeCode);
    }
  }

  goToAddBeneficiary() {
    var sharedQueryParams = this.selectedPurposeObject;
    sharedQueryParams['sourcePage'] = PagesList.LIST_BENEFICIARY;
    this.router.navigate(['/add-beneficiary'], { queryParams: sharedQueryParams });
    // this.router.navigate(['/add-beneficiary'], {skipLocationChange: true , queryParams : {remittanceType : this.selectedPurposeObject.remittanceType, purposeCode :this.selectedPurposeObject.purposeCode}});
  }

  getBeneficiaryList(purposeCode) {
    this.showLoader = true;
    this.selectedPurposeObject = PurposeListBeneficiary.getPurposeObjectByPurposeCode(purposeCode);
    let localBeneficiaryList;
    this.beneficiaryService.getBeneficiaryListAll(this.selectedPurposeObject.remittanceType).subscribe(resp => {
      localBeneficiaryList = resp.body;
      this.showLoader = false;
      this.beneficiaryListItems = [];
      localBeneficiaryList.forEach(singleBeneficiary => {
        this.noDataFound = false;
        if (singleBeneficiary.purpose == purposeCode) {
          this.beneficiaryListItems.push(singleBeneficiary);
        }
      });
      if (this.beneficiaryListItems.length == 0) {
        this.noDataFound = true;
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  submit(form) {
    if (form.valid) {
      this.toggleSidebar('new-user-sidebar');
    }
  }

  showBeneficiaryDetails(singleBeneficiary) {
    this.singleBeneficiaryData = singleBeneficiary;
    this.buttonText = 'View Less';
    this.showDetailsBeneficiary = true;
    this.getCountryName(singleBeneficiary.countryCode);
    // this.router.navigate(['/details-beneficiary'], {queryParams : this.singleBeneficiaryDetails});
  }

  hideDetailsBeneficiary() {
    this.showDetailsBeneficiary = false;
    this.buttonText = 'View More';
  }

  deleteSingleBeneficiary(beneficiary_id) {
    this.showLoader = true;
    this.beneficiaryService.deleteBeneficiary(beneficiary_id).subscribe((resp => {
      this.showLoader = false;
      this.toast.success("Beneficiary deleted successfully!");
      this.getBeneficiaryList(this.purposeListRemittance[0].purposeCode);
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  navigateToProceedOrder(singleBeneficiary) {
    console.log("redirectionUrl >>", singleBeneficiary);
    sessionStorage.setItem(ConstantsKey.BENEFICIARY_ORDER_DATA, JSON.stringify(singleBeneficiary));

    var redirectionUrl = "/proceed-and-pending-order/" + singleBeneficiary.id + "/BENEFICIARY-ORDER";
    this.router.navigate([redirectionUrl])
  }

  async getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    }, error => {
    });
  }

  getCountryName(countryCode){
      var countryObject =  Utils.getSingleCountryByCountryCode(countryCode, this.countryListItems);
      this.countryName = countryObject?.countryName;
  }
}
