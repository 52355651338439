<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                <stepper-header></stepper-header>
                <div class="row pl-2 pr-2" style="padding-top: 8px;">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                            minutes
                        </label>
                        <div class="progress">
                            <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                        </div>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <loader *ngIf="showLoader"></loader>
                        <div id="beneficiary-details" class="content">
                            <form #accountDetailsForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <section>
                                            <div class="row">
                                                <div class="col-md-12  mb-10">
                                                    <h6 class="content-header-title
                                            float-left mb-0" style="color: black">
                                                        {{ contentHeader }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-md-12" style="padding-bottom:8px">
                                                    <div class="fs-13" style="color: black">
                                                        Fill in university details like university name, country, address, university bank details etc.
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section class="pb-15" style="border-bottom: 1px solid #eee;">
                                            <div class="row" *ngIf="selectedPurpose.purposeCode === 'UNIVERSITY_FEES' || selectedPurpose.purposeCode === 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="country" style="color: black">Country</label>
                                                    <ng-select name="countryListItems" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="selectedCountry" (change)="getUniversityList(selectedCountry)" [clearable]="false" [searchable]="true" class="fs-14" placeholder="Select Country">
                                                    </ng-select>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="country" style="color: black">University</label>
                                                    <ng-select name="universityListItems" [items]="universityListItems" bindLabel="name" [(ngModel)]="selectedUniversity" addTagText="Add New University" [addTag]="addNewUniversity" (change)="navigateToAddBeneficiary(selectedUniversity.name, selectedCountry)"
                                                        placeholder="Select University" class="fs-14" [clearable]="false" [searchable]="true">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </section>

                                        <list-beneficiary-stepper (childCallbackSender)="parentCallbackReceiverForListBeneficiary($event)">
                                        </list-beneficiary-stepper>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>

                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text"><a
                                                                        (click)="openModalForEditCart()">Edit
                                                                    </a></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between" style="margin-top: 12px;">
                                    <button (click)="gotoDashboard()" type="submit" class="btn btn-outline-dark btn-prev fs-14" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle ml-sm-25 ml-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="step01CreateCartItems()" type="submit" class="btn btn-primary btn-next fs-14" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div id="student-details" class="content">
                            <form #studentDetailsForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <div class="row mb-20">
                                            <div class="col-md-12  mb-10">
                                                <h6 class="content-header-title
                                        float-left mb-0" style="color: black">
                                                    {{ contentHeader }}
                                                </h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="fs-13" style="color: black">
                                                    Student should be same as on university letter. Please add student if other than self.
                                                </div>
                                            </div>
                                        </div>

                                        <list-relationship-stepper (childCallbackRelationship)="parentCallbackForRelationship($event)">
                                        </list-relationship-stepper>

                                        <section class="grey-box-padding-30 mb-1">
                                            <loader *ngIf="showLoader"></loader>
                                            <div class="invalid-state fs-12 text-danger mt-10 mb-20" *ngIf="selectedSourceofFund == 'Loan'" style="color: black">
                                                Note :Remittance will be sent from the LRS quota & TCS will be applied to the user (Self)
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="sourceofFund" style="color: black">Source of Fund</label>
                                                    <ng-select [items]="sourceofFundItems" [(ngModel)]="selectedSourceofFund" (change)="getSelectedSourceFund(selectedSourceofFund)" name="sourceofFund" id="sourceofFund" #sourceofFundRef="ngModel" [class.error]="studentDetailsForm.submitted && sourceofFundRef.invalid"
                                                        placeholder="Select Source of Fund" required [clearable]="false" class="fs-14" [searchable]="false">
                                                    </ng-select>
                                                    <span *ngIf="studentDetailsForm.submitted && sourceofFundRef.invalid" class="invalid-form">
                                                        <small class="form-text text-danger fs-12"
                                                            *ngIf="sourceofFundRef.errors.required"
                                                            style="color: black">
                                                            This field is required!
                                                        </small>
                                                    </span>
                                                </div>
                                            </div>
                                            <section *ngIf="showFinancialOptions">
                                                <div class="row">
                                                    <div class="form-group col-md-6 mt-20" *ngIf="showFinancialOptions">
                                                        <label class="form-label" style="color: black">Financial
                                                            Institution</label>
                                                        <ng-select class="fs-14" [items]="financialInstitutionItem" [(ngModel)]="selectedInstitution" id="financialInstitution" name="financialInstitution" #financialInstitutionRef="ngModel" [class.error]="studentDetailsForm.submitted && financialInstitutionRef.invalid"
                                                            [searchable]="true" required [clearable]="false" placeholder="Select Financial Institution">
                                                        </ng-select>
                                                        <span *ngIf="studentDetailsForm.submitted && financialInstitutionRef.invalid" class="invalid-form">
                                                            <small class="form-text text-danger fs-12"
                                                                *ngIf="financialInstitutionRef.errors.required"
                                                                style="color: black">This field is
                                                                required!</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6" *ngIf="selectedInstitution== 'Other'">
                                                        <input type="text" class="form-control fs-14" placeholder="Name of the institute*" [(ngModel)]="instituteName" id="instituteName" name="instituteName" #instituteNameRef="ngModel" [class.error]="studentDetailsForm.submitted && instituteNameRef.invalid"
                                                            required />
                                                        <span *ngIf="studentDetailsForm.submitted && instituteNameRef.invalid" class="invalid-form">
                                                            <small class="form-text text-danger fs-12"
                                                                *ngIf="instituteNameRef.errors.required"
                                                                style="color: black">This field is
                                                                required!</small>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class=" mt-20" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-9 ">
                                                        <div class="form-group">
                                                            <label for="basicInputFile" style="color: black">Financial
                                                                Institution Document</label>
                                                            <div class="custom-file">
                                                                <input type="file" (change)="fileUploadCartDocument($event,'LOAN_DOCUMENT')" class="form-control fs-14" id="customFile" placeholder="Financial Institution*" />
                                                                <label class="custom-file-label" for="customFile" style="color: black">Choose
                                                                    file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 col-3 pl-0 finBox  mt-23" *ngIf="showTick">
                                                        <div class="input-group-append" style="height: 38px;">
                                                            <span class="input-group-text" style="cursor: pointer;"><img
                                                                    src="assets/images/icon/download.png"
                                                                    style="width:20px;width:20px"
                                                                    (click)="downloadUploadedLoanDoc()"></span>
                                                            <span class="input-group-text "><img
                                                                    src="assets/images/icon/checkmark.gif"
                                                                    class="w-h-20"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>

                                        <div class="custom-control custom-checkbox mb-20 mt-20" *ngIf="selectedSourceofFund === 'Loan'">
                                            <input type="checkbox" name="checkboxconfirmation" class="custom-control-input" id="checkboxconfirmation" [(ngModel)]="isChecked" (change)="checkLoanConfirmationCheckboxvalue()" />
                                            <label class="custom-control-label text-black fs-13" for="checkboxconfirmation" style="margin-top: 2px;" style="color: black">
                                                I confirm that my loan is from a certified financial institution which
                                                is
                                                registered under section 80E
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">

                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text"><a
                                                                        (click)="openModalForEditCart()">Edit
                                                                    </a></span>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between mt-30" style="margin-top: 12px;">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-14" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle ml-sm-25 ml-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="step02StudentDetails(studentDetailsForm)" class="btn btn-primary btn-next fs-14" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <div class="row mb-20">
                                            <div class="col-md-12  mb-10">
                                                <h6 class="content-header-title
                                            float-left mb-0" style="color: black">
                                                    {{ contentHeader }}
                                                </h6>
                                            </div>
                                            <div class="col-md-12" style="color: black">
                                                <div class="fs-13">Upload your transaction documents.
                                                </div>
                                            </div>
                                        </div>
                                        <loader *ngIf="showLoader"></loader>

                                        <section *ngFor="let singleDocument of transactionDocListUploaded">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 col-7">
                                                    <div class="form-group">
                                                        <label for="basicInputFile" style="color: black">{{singleDocument.title}}</label>
                                                        <div class="custom-file">
                                                            <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                            <label class="custom-file-label" for="customFile" style="color: black">Choose
                                                                file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-5" *ngIf="singleDocument.status == 'UPLOADED' ">
                                                    <div class="input-group-append mt-23" style="height: 38px;">
                                                        <span class="input-group-text link" (click)="downloadUploadedDoc(singleDocument)"><img
                                                                src="assets/images/icon/download.png" class="w-h-20">
                                                        </span>
                                                        <span class="input-group-text link ml-7">
                                                            <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section *ngFor="let singleDocument of transactionDocListAwaiting">
                                            <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
                                                <div class="col-md-8">
                                                    <div class="form-group">
                                                        <label for="basicInputFile" style="color: black">{{
                                                            singleDocument.title }}</label>
                                                        <div class="custom-file">
                                                            <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                            <label class="custom-file-label" for="customFile" style="color: black">
                                                                Choose file
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section *ngIf="isAddress=='false'">
                                            <div class="row mb-20">
                                                <div class="col-md-8 fs-12 mb-20">
                                                    <label style="color: black" class=" col-md-12 pl-0"> Add Address*</label>
                                                    <textarea type="textarea" [(ngModel)]="updateProfileModel.address" name="address" rows="3" class="w-100">  </textarea>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">

                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text"><a
                                                                        (click)="openModalForEditCart()">Edit
                                                                    </a></span>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between mt-30">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-14" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle ml-sm-25 ml-0"></i>
                                        <span class="align-middle
                                           ">Back</span>
                                    </button>
                                    <button (click)="(navigateToFourthStep) ? step03GetDocumentProceed(kycForm) : showToast()" class="btn btn-primary btn-next fs-14" rippleEffect>
                                        <span class="align-middle
                                           ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div id="lrsDeclaration" class="content">
                            <form #lrsDeclaration="ngForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="col-md-12  pl-0 content-header-title fs-16  mb-10">
                                            Traveller details
                                        </div>
                                        <div class="col-md-12  pl-0 text-black fs-14">Have you conducted any foreign exchange transactions under LRS in the current financial year through any other Regulated Entity (AD/FFMC/ADII)?*</div>
                                        <div class="col-md-12  pl-0 mt-20">
                                            <div class="row">
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='Yes' (click)="showLRSFormSection(true)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;Yes
                                                </div>
                                                <div class="col-md-2 col-6 fs-14 fw-500">
                                                    <input type="radio" name="radios" id="radio" value='No' (click)="showLRSFormSection(false)" [(ngModel)]="selectedOption" /> &nbsp; &nbsp;No
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0" *ngIf="showTransactionDetails || showLocalTransactionDetails">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <div class="row" *ngIf="showTransactionDetails">
                                                        <div class="col-md-12">
                                                    <section class="grey-box-padding-10 mt-10" *ngFor="let singleTransaction of transactionList">
                                                        <div class="row d-flex justify-content-between mb-20">
                                                            <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                            <!-- <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger"></i></div> -->
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Date of Transaction</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.dateOfTranscation | date :'dd MMM yyyy' }}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Amount in INR</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.lrsAmount}}</div>
                                                        </div>
                                                    </section>
                                                </div>
                                                </div>
                                                <div class="row" *ngIf="showLocalTransactionDetails">
                                                    <div class="col-md-12">
                                                    <section class="grey-box-padding-10 mt-10" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                        <div class="row d-flex justify-content-between mb-20">
                                                            <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                            <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Date of Transaction</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Amount in INR</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                        </div>
                                                    </section>             
                                                </div>
                                                </div>                                       
                                                </div>
                                                <div class="col-md-3 mt-30 pl-0 ">
                                                    <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="showLRSFormSection(true)" rippleEffect>
                                                        <span class="align-middle" >Add more details</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 pl-0 mb-20" *ngIf="showLRSForm">
                                            <section class="grey-box-padding-30 mt-30">
                                                <form [formGroup]="lrsForm" (ngSubmit)="saveLRSDataLocally(lrsForm)" novalidate>
                                                    <div class="row">
                                                        <div class="col-md-12 col-12">
                                                            <input type="text" class="form-control fs-14" placeholder="Name of the Bank/FFMC/ADII*" formControlName="instituteName" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.instituteName.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.instituteName.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-20">
                                                            <div class="input-group ">
                                                                <input class="form-control fs-12" placeholder="Date of transaction*" formControlName="selectedDateOfTransaction" (click)="basicDP.toggle()" ngbDatepicker #basicDP="ngbDatepicker" (dateSelect)="onDateSelect($event)"
                                                                    [minDate]="{year: 1950, month: 1, day: 1}" [maxDate]="maxLRSDate" [minDate]="minLRSDate">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.selectedDateOfTransaction.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.selectedDateOfTransaction.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 col-12 mt-30">
                                                            <input type="number" class="form-control fs-14" placeholder="Amount in INR*" formControlName="lrsDeclarationAmount" />
                                                            <div *ngIf="isLRSformSubmit && lrsFormControl.lrsDeclarationAmount.invalid" class="invalid-form">
                                                                <small class="form-text text-danger fs-12" *ngIf="lrsFormControl.lrsDeclarationAmount.errors.required" style="color: red">
                                                                    This field is required!
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="button" class="btn btn-outline-dark btn-prev fs-14" rippleEffect (click)="closeLRSForm()">
                                                                <span class="align-middle">Cancel</span>
                                                            </button>
                                                        </div>
                                                        <div class="col-md-3 col-6 mt-30">
                                                            <button type="submit" class="btn btn-primary btn-next fs-14" rippleEffect>
                                                                <span class="align-middle">Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>
                                        </div>

                                        <!-- <div class="col-md-12 pl-0" *ngIf="showLocalTransactionDetails">
                                            <div class="row" *ngFor="let singleTransaction of localLRSArray ;let i = index">
                                                <div class="col-md-12">
                                                    <section class="grey-box-padding-10 mt-10">
                                                        <div class="row d-flex justify-content-between mb-20">
                                                            <div class="col-md-8 fs-14 fw-600">Previous Transaction Details</div>
                                                            <div class="col-md-4 text-right"><i class="fa fa-trash fs-18 text-danger" (click)="removeLRSEntry(i)"></i></div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Name of the Bank/FFMC/ADII</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.instituteName}}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Date of Transaction</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.transactionDate }}</div>
                                                        </div>
                                                        <div class="row d-flex justify-content-between mb-10">
                                                            <div class="col-md-8">Amount in INR</div>
                                                            <div class="col-md-4 text-right">{{singleTransaction.lrsDeclarationAmount}}</div>
                                                        </div>
                                                    </section>
                                                </div> -->
                                                <!-- <div class="col-md-3 mt-30 pl-0 ">
                                                    <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="showLRSFormSection(true)" rippleEffect>
                                                        <span class="align-middle" >Add more details</span>
                                                    </button>
                                                </div> -->
                                            <!-- </div>
                                        </div> -->
                                        <!-- <div class="col-md-2 offset-md-10 mt-10  pl-0 mb-20" *ngIf="showLocalTransactionDetails">
                                            <button class="btn btn-primary btn-next fs-11 p-10 w-100" (click)="proceedToLRSDeclaration()" rippleEffect>
                                                <span class="align-middle" >Submit</span>
                                            </button>
                                        </div> -->
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12" >
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>Rate</th>
                                                            <th>INR Amount</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>

                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                            <th class="mob-table">Action</th>
                                                            <td>
                                                                <span class="blue-text-edit"><a (click)="openModalForEditCart(singleTransaction)">Edit </a></span> | <span class="red-text-delete"><a
                                                                                                                                    (click)="removeCartItem(singleTransaction)">Delete </a></span>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button (click)="checkLRSStep()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <loader *ngIf="showLoader"></loader>
                                <esign *ngIf="!showESignIframe"></esign>
                                <div class="col-md-12 text-right pr-0">
                                    <button class="btn btn-primary btn-next mb-20" rippleEffect *ngIf="signatureMode == 'AADHAR_ESIGN' && !showESignIframe" (click)="showIframeForEsign()">
                                        <span class="align-middle ">Proceed </span>
                                    </button>
                                </div>
                                <iframe *ngIf="showESignIframe" width="100%" height="500" [src]="url" id="adharesign" style="border:1px solid #bbb"></iframe>

                                <div class="d-flex justify-content-between mt-30" *ngIf="signatureMode != 'AADHAR_ESIGN' || showESignIframe">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-14" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle ml-sm-25 ml-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="step03EsignOrOtpValidate()" class="btn btn-primary btn-next fs-14" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div id="payment-details" class="content">
                            <add-bank-account-for-remittance *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                            </add-bank-account-for-remittance>
                            <form #paymentDetails="ngForm" *ngIf="!showAddBankForm">
                                <div class="row mt-30">
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">

                                        <div class="row mb-20">
                                            <div class="col-md-12  mb-10">
                                                <h6 class="content-header-title float-left mb-0" style="color: black">
                                                    {{ contentHeader }}
                                                </h6>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="fs-13" style="color: black">
                                                    Complete your payment successfully to proceed with your remittance request.
                                                </div>
                                            </div>
                                        </div>

                                        <loader *ngIf="showLoader"></loader>
                                        <section class="offer-section mt-20">
                                            <div class="row">
                                                <div class="col-md-9 col-9">
                                                    <div class="form-group m-form__group">
                                                        <label class="form-group m-form__group mb-0">Apply Coupon</label>
                                                        <!-- <select class="form-control" name="offer" placeholder="Apply Coupon" required [(ngModel)]="requestQuotationModel.promoCode">
                                                            <option *ngFor="let singleOffer of listOffers"
                                                                [value]="singleOffer.promoCodeId">
                                                                {{ singleOffer.title }}</option>
                                                        </select> -->
                                                        <ng-select #refPromoCodeList name="offer" [items]="listOffers" bindLabel="title" bindValue="promoCodeId" [addTag]="addNewCoupon" addTagText="Add New Coupon" [(ngModel)]="requestQuotationModel.promoCode" placeholder="" class="fs-12"  placeholder="Select Coupon or add Coupon" required>
                                                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                                                <b>{{item.title}}</b><br>
                                                                <span>{{item.shortDescription}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="!promoCodeApplied">
                                                    <button class="btn btn-primary" (click)="applyPromoCode()">Apply</button>
                                                </div>

                                                <div class="col-md-2 col-3 pl-0 mt-23" *ngIf="promoCodeApplied">
                                                    <button class="btn btn-danger" (click)="removePromoCode()">Remove</button>
                                                </div>

                                            </div>
                                        </section>

                                        <section class="col-md-12 redeempoints-section mt-20  redeem-points p-15">
                                            <div class="row">
                                                <div class="col-md-9 col-9">
                                                    <div class="form-group">
                                                        <input type="number" name="pointsToRedeem" [(ngModel)]="requestQuotationModel.pointsToRedeem" class="form-control fs-12" placeholder="Enter Redeemable Points *" min="0" max={{redeemableCredits?.availableCredit}} oninput="this.value = 
                                                !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-3 pl-0" *ngIf="!creditPointApplied">
                                                    <button class="btn btn-primary" (click)="applyCreditPointToQuote()">Apply</button>
                                                </div>
                                                <div class="col-md-3 col-3 pl-0" *ngIf="creditPointApplied">
                                                    <button class="btn btn-danger" (click)="removeCreditPoint()">Remove</button>
                                                </div>
                                                <!-- <div class="col-md-6">
                                                    <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                                </div>
                                                <div class="col-md-6 text-right">
                                                    <div class="text-black fw-500 fs-10">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                                </div> -->
                                                <div class="col-md-12 d-flex justify-content-between align-items-center mt-2">
                                                    <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
                                                    <div class="text-black fw-500 fs-10 text-right">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
                                                </div>
                                            </div>
                                        </section>
                                        <hr class="dotted">

                                        <!-- <div class="row">
                                            <div class="col-md-8 col-12" style="margin-top: 3px;">
                                                <div class="fs-16 text-black fw-600" style="color: black">
                                                    Choose Your Bank Account
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <button type="button" class="btn btn-primary btn-prev fs-14" rippleEffect (click)="showAddBankForm = true" style="color: black">
                                                    Add Bank Account
                                                </button>
                                            </div>
                                        </div> -->
                                        <div class="col-md-12 mb-30 mt-30 pl-0 d-flex align-items-center">
                                            <div class="col-md-6 col-sm-6 pl-0 d-flex justify-content-start align-items-center">
                                                <div class="fs-12 text-black pl-0 fw-600">
                                                    Choose Your Bank Account
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 d-flex justify-content-end   mt-md-0">
                                                <button type="button" class="btn btn-primary btn-prev fs-12" rippleeffect="" (click)="openModalForAddBankAccount(addBankAccountModal)">Add Bank Account</button>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
                                                <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                                                    <div class="row mb-10">
                                                        <div class="col-md-11 col-10">
                                                            <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                                            <span class="card-title fs-14 text-black fw-600 mb-10" style="color: black">
                                                                {{ singleBank.bankName }}
                                                            </span>
                                                            <div class="fs-13 ml-15" style="color: black"> Account No : {{singleBank.number}}
                                                            </div>
                                                            <div class="fs-13 ml-15" style="color: black"> IFSC Code : {{singleBank.ifscCode}}
                                                            </div>
                                                            <div class="fs-13 ml-15" style="color: black"> Branch : {{singleBank.branch}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-20">
                                            <div class="col-md-12">
                                                <div class="fs-12 text-black fw-600">
                                                    Payment Type
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row mt-10 mb-20">
                                            <div class="col-md-6 fs-11">
                                                <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="setPaymentModeAsFullPayment()" /> &nbsp; &nbsp; Full Payment
                                            </div>
                                            <div class="col-md-4  fw-600">
                                                ₹ {{this.quotationResponse.totalBillableAmount}}
                                            </div>
                                        </div> -->
                                        <div class="row mt-3 mb-3 align-items-center">
                                            <div class="col-8 col-md-6 fs-6">
                                                <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="setPaymentModeAsFullPayment()" /> &nbsp; &nbsp; Full Payment
                                            </div>
                                            <div class="col-4 col-md-4 fw-bold">
                                                ₹ {{this.quotationResponse.totalBillableAmount}}
                                            </div>
                                        </div>
                                        <!-- <div class="row mt-20">
                                            <div class="col-md-6 fs-11" *ngIf="partialPaymentAllowed">
                                                <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="setPaymentMethodAsPartial()" /> &nbsp; &nbsp; Book and Pay
                                            </div>
                                            <div class="col-md-4   fw-600" *ngIf="partialPaymentAllowed">
                                                ₹ {{this.quotationResponse.partialBillableAmount}}
                                            </div>
                                        </div> -->
                                        <div class="row mt-3  align-items-center">
                                            <div class="col-8 col-md-6 fs-6">
                                                <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="setPaymentMethodAsPartial()" /> &nbsp; &nbsp; Book and Pay
                                            </div>
                                            <div class="col-4 col-md-4 fw-bold" *ngIf="partialPaymentAllowed">
                                                ₹ {{this.quotationResponse.partialBillableAmount}}
                                            </div>
                                        </div>
                                        <div class="row mt-10 mb-20">
                                            <div class="col-md-12 fs-9 text-blue fw-600" style="margin-left: 35px!important ;" *ngIf="partialPaymentAllowed">
                                            Book your forex order now and pay remaining later. Enjoy today’s rate by paying only 1% amount

                                            </div>
                                        </div>

                                        <div class="row mt-30">
                                            <div class="col-md-12">
                                                <div class="fs-12 text-black fw-600" style="color: black">
                                                    Payment Method
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row mt-10 mb-30">
                                            <div class="col-md-6 col-12 fs-11 " style="color: black" *ngIf="showNetBanking || !showNEFT">
                                                <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" style="color: black" /> &nbsp; &nbsp; Net Banking
                                            </div>
                                            <div class="col-md-6 col-12 fs-11 " *ngIf="ALLOW_NEFT_PAYMENT && showNEFT" style="color: black">
                                                <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" style="color: black" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                            </div>
                                        </div> -->
                                        <div class="row mt-10 mb-30">
                                            <div class="col-12 d-flex justify-content-between fs-14" style="color: black">
                                                <div *ngIf="showNetBanking || !showNEFT" class="flex-fill">
                                                    <input type="radio" name="paymentRadio" id="radioNB" value='NB' [(ngModel)]="paymentMode" style="color: black" /> &nbsp; &nbsp; Net Banking
                                                </div>
                                                <div *ngIf="ALLOW_NEFT_PAYMENT && showNEFT" class="flex-fill">
                                                    <input type="radio" name="paymentRadio" id="radioManual" value='MANUAL' [(ngModel)]="paymentMode" style="color: black" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                                </div>
                                            </div>
                                        </div>


                                        <div class="  mt-20" *ngIf="paymentMode == 'MANUAL'" style="color: black">
                                            <div class="fs-13 fw-600  text-black" style="color: black">{{accountName}}
                                            </div>
                                            <div class="fs-12 text-black" style="color: black">{{bankName}}</div>
                                            <div class="fs-12 text-black" style="color: black">{{accountNo}}</div>
                                            <div class="fs-12 text-black" style="color: black">{{bankIFSC}}</div>
                                            <div class="fs-12 text-black" style="color: black">{{bankBranch}}</div>
                                        </div>

                                        <div class="mb-30 fs-11 text-danger mt-30  text-500" *ngIf="paymentMode == 'MANUAL'" style="color: black">
                                            Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section.
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-md-12  mb-5">
                                                <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                                                    Your Cart Details
                                                </h6>
                                            </div>

                                        </div>
                                        <div class="table-responsive mt-15">
                                            <div>
                                                <table class="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>Product</th>
                                                            <th>Currency</th>
                                                            <th>FX Amount</th>
                                                            <th>IBR</th>
                                                            <th>INR Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="fs-10 text-black">
                                                        <tr *ngFor="let singleTransaction of quotationResponse?.quotationCurrencyResponses; let i = index">
                                                            <th class="mob-table">Product</th>
                                                            <td>
                                                                {{singleTransaction?.product}}
                                                            </td>
                                                            <th class="mob-table">Currency</th>
                                                            <td>
                                                                {{singleTransaction?.currencyCode}}
                                                            </td>
                                                            <th class="mob-table">FX Amount</th>
                                                            <td>
                                                                {{singleTransaction?.currencyAmount}}
                                                            </td>
                                                            <th class="mob-table">IBR</th>
                                                            <td>
                                                                {{singleTransaction.rateString | number : '1.2-2'}}
                                                            </td>
                                                            <th class="mob-table">INR Amount</th>
                                                            <td>
                                                                {{singleTransaction.inrEquivalentAmountString}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <stepper-footer (childCallbackSender)="parentCallbackForFooter($event)" [refreshQuotationCountFooter]="refreshQuotationCount"></stepper-footer>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between mt-30">
                                    <button class="btn btn-primary btn-gradient btn-submit fs-14" rippleEffect (click)="horizontalWizardStepperPrevious()">
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button class="btn btn-primary btn-gradient btn-submit fs-14" rippleEffect (click)="openModalForPaymentInfo(paymentInstructionModal)">
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #transactionDocumentViewModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1" style="color: black">{{ documentTitle }}</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <img src={{documentUrl}} style="height:400px;display:block;margin:auto;width:100%;object-fit: contain;">
        </div>
    </div>
</ng-template>

<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1" style="color: black"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                {{paymentInstruction}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-14 text-center blue-text" (click)="step04Payment()" style="cursor: pointer;">
            <a>OKAY</a>
        </div>
    </div>
</ng-template>

<ng-template #crimeaRegionDailog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Is this Beneficiary / transaction link to Embargoes sanctions country i.e. CRIMEA - REGION OF UKRAINE
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-6 fw-500 fs-14 text-center blue-text" (click)="openNoTransactionAllowedDailog()" style="cursor: pointer;" style="color: black">
                Yes
            </div>
            <div class="col-md-6 fw-500 fs-14 text-center blue-text" (click)="openDueDeligenceDailog()" style="cursor: pointer;" style="color: black">
                No
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dueDiligenceDialog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Please note: Transaction will undergo compliance for extra due diligence
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-14 text-center blue-text" style="cursor: pointer;" (click)="agreeDueDiligence()" style="color: black">
            Agree
        </div>
    </div>
</ng-template>

<ng-template let-modal #noTransactionAllowedDialog>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                No transaction allowed to this country
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-14 text-center blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
            <a> OKAY </a>
        </div>
    </div>
</ng-template>

<ng-template let-modal #EditCardModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Select Currency*</label>
                <ng-select [disabled]="isGICorBlockAccount" class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationRequest.quotationCurrencies[0].currencyCode" [searchable]="false">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6 col-6 mb-1">
                <label class="form-label" for="country" style="color: black">Enter Amount*</label>
                <input required type="number" name="amount" class="form-control fs-12" style="border-color:black" placeholder="Amount*" (paste)="onPaste($event)" [(ngModel)]="reqCurrencyAmount" required />
            </div>
        </div>
    </div>
    <div class="col-md-12  text-center ">
        <button class="mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="getRateRange();updateCurrencyAmount()">
            Update
        </button>
    </div>
</ng-template>

<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center">Please be informed that our online portal facilitates allow transaction up to $25,000 (or equivalent).</span><br>
        <span class="text-center">If your transaction exceeds this limit, we kindly request you to utilize our branches. </span>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect (click)="modal.dismiss('Cross click')">
        Okay </button>
    </div>
</ng-template>
<ng-template let-modal #addBankAccountModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form class="form-horizontal" (ngSubmit)="addBankAccount(AddBankAccount.form.valid)" #AddBankAccount="ngForm">
            <div class="col-md-12">
                <loader *ngIf="showLoader"></loader>
                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label">Name</label>
                        <input required type="text" class="form-control fs-12" placeholder="Name" [(ngModel)]="addBankAccountRequest.nameAsPerAccountNumber" name="name" #nameRef="ngModel" [class.error]="AddBankAccount.submitted && nameRef.invalid" (keypress)="alphaNumberOnly($event)"
                            (paste)="onPaste($event)" maxlength="100" required />
                        <span *ngIf="AddBankAccount.submitted && nameRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="nameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="country">Relationship*</label>
                        <ng-select class="fs-12" [items]="bankRelationshipList" bindValue="bankRelationshipList" [(ngModel)]="addBankAccountRequest.relation" required placeholder="Relationship" name="relationship" #relationshipRef="ngModel" [class.error]="AddBankAccount.submitted && relationshipRef.invalid"
                            [clearable]="false" [searchable]="true">
                        </ng-select>
                        <span *ngIf="AddBankAccount.submitted && relationshipRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>

                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="country">Bank Name*</label>
                        <ng-select [items]="bankDropdownItemList" bindLabel="displayName" bindValue="displayName" [(ngModel)]="addBankAccountRequest.bankName" placeholder="Select" name="bank_name" #bankNameRef="ngModel" [class.error]="AddBankAccount.submitted && bankNameRef.invalid"
                            [searchable]="false" required>
                        </ng-select>
                        <span *ngIf="AddBankAccount.submitted && bankNameRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="bankNameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Branch Name</label>
                        <input type="text" class="form-control fs-12" name="ifscCode" placeholder="Branch Name" maxlength="100" [(ngModel)]="addBankAccountRequest.branch" #branchNameRef="ngModel" [class.error]="AddBankAccount.submitted && branchNameRef.invalid" required />
                        <span *ngIf="AddBankAccount.submitted && branchNameRef.invalid" class="invalid-form">
                            <small class="text-danger" *ngIf="branchNameRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>
                <div class="row mt-15">
                    <div class="form-group col-md-6">
                        <label class="form-label">Account Number</label>
                        <div class="input-group form-password-toggle">
                            <input [type]="showAccountNo ? 'text' : 'password'" class="form-control fs-12" [(ngModel)]="addBankAccountRequest.number" placeholder="Account Number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid"
                                (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required autoComplete="true" />
                            <div class="input-group-append" (click)="showAccountNo = !showAccountNo">
                                <span class="input-group-text cursor-pointer" style="height:38px !important"><i class="feather"
                                [ngClass]="{'icon-eye-solid': showAccountNo, 'icon-eye-slash-solid': !showAccountNo }"></i></span>
                            </div>

                        </div>
                        <!-- <input required type="password" class="form-control fs-12" placeholder="Account Number" [(ngModel)]="addBankAccountRequest.number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid" required /> -->
                        <span *ngIf="AddBankAccount.submitted && accountNoRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="accountNoRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Confirm Account Number</label>
                        <input required type="text" name="confirm_acccount_no" class="form-control fs-12" placeholder="Confirm Account Number" [(ngModel)]="addBankAccountRequest.confirmAccNo" #confirmAccountNoRef="ngModel" [class.error]="AddBankAccount.submitted && confirmAccountNoRef.invalid"
                            (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required autoComplete="true" />
                        <span *ngIf="AddBankAccount.submitted && confirmAccountNoRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="confirmAccountNoRef.errors.required">This field is required!</small>
                        </span>
                    </div>
                </div>

                <div class="row mt-15">

                    <div class="form-group col-md-6">
                        <label class="form-label">IFSC Number</label>
                        <input type="text" class="form-control fs-12" name="ifscCode" placeholder="IFSC Number" [(ngModel)]="addBankAccountRequest.ifscCode" #ifscCodeRef="ngModel" [class.error]="AddBankAccount.submitted && ifscCodeRef.invalid" required pattern="([A-Za-z0]{4})(0\d{6})$"
                            (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="11" />
                        <span *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid" class="invalid-form">
                            <small class="text-danger" *ngIf="ifscCodeRef.errors.required">This field is required!</small>
                        </span>
                        <div class="text-danger fs-11" *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid">IFSC Code must be in valid format!</div>
                    </div>
                </div>

                <div class="row">
                    <button class="col-md-2 offset-md-5 btn btn-primary fw-500 fs-12 text-center blue-text" type="submit" style="cursor: pointer;">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>