export class RequestQuotationModel {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    location: string;
    sellType: string;
    partnerId: string;
    orderId: string;
    subCategory: string;
    promoCode: string;
    pointsToRedeem: number;
    pan: string;
    tcsPaymentSource: string;
    tcsPaymentSourceDetails: string;
    purpose: string;
    taxResidentOfIndia: boolean;
    otherSourcePaymentDetails: string;
    otherTaxResident: string;
    paymentSourceDetails: string;
    calculateTcs: boolean;
    nostroChargeType: string;
    quotationSnapshotId:string;
    quotationCurrencies: Array<QuotationCurrencyItem>;
}

export class QuotationCurrencyItem {
    currencyCode: string;
    bidRate: string;
    currencyAmount: any;
    product: string;
    purpose: string;
}
