import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { DashboardComponent } from './view/widget-home/widget-home.component';
import { SmartFxHomeComponent } from './view/smartfx-home/smartfx-home.component';
import { NewDashboardComponent } from '../new-dashboard/views/new-dashboard.component';
import { SSOSendMoneyDashboardComponent } from './view/sso-send-money-dashboard/sso-send-money-dashboard.component';

const routes = [
  {
    path: 'dashboard',
    component: NewDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: NewDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'send-money-dashboard',
    component: SSOSendMoneyDashboardComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [DashboardComponent, SmartFxHomeComponent,SSOSendMoneyDashboardComponent],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    LoaderRoutingModule
  ],
  exports: [DashboardComponent, SmartFxHomeComponent,],
  providers: [MasterDataService],
})
export class DashboardRoutingModule { }
