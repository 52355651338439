import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  public configData: any;

  ngOnInit(): void {
    let menuItem = this.menu || this._coreMenuService.getCurrentMenu();

    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      menuItem = this._coreMenuService.getCurrentMenu();

      let menuItemsLocal = [];
      // let data = sessionStorage.getItem('CONFIG_DATA');
      // this.configData = JSON.parse(data);
      this.configData={
        "remittanceFlowEnable": true,
        "personalFlowEnable": true,
        "cardFlowEnable": true,
        "insuranceEnable": true,
        "dashboardEnable": true,
        "myAccountMenuEnable": true,
        "myAccountSubMenuEnable": true,
        "relationshipEnable": true,
        "beneficiaryEnable": true,
        "bankAccountEnable": true,
        "ordersEnable": true,
        "pendingOrdersEnable": true,
        "rewardsEnable": true,
        "offersEnable": true,
        "branchNetworkEnable": true,
        "currencyConverterEnable": true,
        "resetPasswordEnable": true,
        "ratesEnable": true,
        "setRateAlertEnable": true,
        "supportEnable": true,
        "addressEnable": true,
        "faqEnable": true,
        "tutorialEnable": true,
        "paymentModeNBEnable": true,
        "paymentModeNEFTEnable": true,
        "partialPaymentEnable": true,
        "openGICAccountEnable": true,
        "blockedAccountEnable": true,
        "zolveCreditCardEnable": true,
        "isicCardEnable": true
    };

      for (let m = 0; m < menuItem.length; m++) {
        let mainMenu = menuItem[m];

        if ((mainMenu.id == 'dashboard' && this.configData?.dashboardEnable) ||
          (mainMenu.id == 'insurance' && this.configData?.insuranceEnable)) {
          menuItemsLocal.push(mainMenu);
        } else if (mainMenu.id == 'myprofile') {
          let subMenuList = mainMenu['children'];
          for (let i = 0; i < subMenuList.length; i++) {
            let singleChildrenMenu = subMenuList[i];

            if ((singleChildrenMenu.id == 'myaccount' && !this.configData.myAccountSubMenuEnable) ||
              (singleChildrenMenu.id == 'relationship' && !this.configData.relationshipEnable) ||
              (singleChildrenMenu.id == 'beneficiary' && !this.configData.beneficiaryEnable) ||
              (singleChildrenMenu.id == 'bankaccount' && !this.configData.bankAccountEnable)) {
              subMenuList.splice(i, 1);
            }
          }
          mainMenu['children'] = subMenuList;
          menuItemsLocal.push(mainMenu);
        } else if (mainMenu.id == 'myorders') {
          let subMenuList = mainMenu['children'];
          for (let i = 0; i < subMenuList.length; i++) {
            let singleChildrenMenu = subMenuList[i];
            if ((singleChildrenMenu.id == 'orders' && !this.configData.ordersEnable) ||
              (singleChildrenMenu.id == 'pendingorders' && !this.configData.pendingOrdersEnable)) {
              subMenuList.splice(i, 1);
            }
          }
          mainMenu['children'] = subMenuList;
          menuItemsLocal.push(mainMenu);
        } else if (mainMenu.id == 'More') {
          let subMenuList = mainMenu['children'];
          for (let i = 0; i < subMenuList.length; i++) {
            let singleChildrenMenu = subMenuList[i];

            if ((singleChildrenMenu.id == 'offers' && !this.configData.offersEnable) ||
              (singleChildrenMenu.id == 'rewards' && !this.configData.rewardsEnable) ||
              (singleChildrenMenu.id == 'rates' && !this.configData.ratesEnable) ||
              (singleChildrenMenu.id == 'branch-locator' && !this.configData.branchNetworkEnable) ||
              (singleChildrenMenu.id == 'currency-converter' && !this.configData.currencyConverterEnable) ||
              (singleChildrenMenu.id == 'securitysettings' && !this.configData.resetPasswordEnable) ||
              (singleChildrenMenu.id == 'rate-alert' && !this.configData.setRateAlertEnable) ||
              (singleChildrenMenu.id == 'support' && !this.configData.supportEnable) ||
              (singleChildrenMenu.id == 'address' && !this.configData.addressEnable) ||
              (singleChildrenMenu.id == 'FAQ' && !this.configData.faqEnable) ||
              (singleChildrenMenu.id == 'tutorial' && !this.configData.tutorialEnable)) {
              subMenuList.splice(i, 1);
            }
          }
          mainMenu['children'] = subMenuList;
          menuItemsLocal.push(mainMenu);
        }
      }



      this.menu = menuItemsLocal;
      console.log(this.menu);

      this._changeDetectorRef.markForCheck();
    });
  }
}
