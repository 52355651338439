<div class="content-wrapper">
    <div class="content-body">
        <div class="row">
            <div class="col-4"></div>
            <div class="col-8" style="font-size: large; color: #1D2A62;"><span style="font-weight: 500;">Order Forex
                    Online in </span><span style="font-weight: bolder;">4 Easy Steps</span></div>
        </div>
        <div class="row mt-40">
            <div class="col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body" style="height: 100%;width: 100%;">
                        <img src="/assets/images/icons/studentRemittance.png"
                            style="height: 60px;margin-top: -50px; margin-left: 38%; margin-right: 50%;">
                        <div class="text-center" style="color: #1D2A62; font-size: medium; font-weight: 600;">Student
                            Remittance</div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/university.png" style="height: 40px;margin: auto;">
                                <div class="vl hide-sm"></div>
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    University</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Enter your university details like name, location, address and university bank.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/student.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Student</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Enter the name and PAN details of the remitter.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/KYC.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">KYC
                                </div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Sign your A2 form and upload mandatory KYC documents.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/payment.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Payment</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Make payment so the remittance can be processed successfully.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body" style="height: 100%;width: 100%;">
                        <img class="text-center" src="/assets/images/icons/Personal.png"
                            style="height: 60px;margin-top: -50px; margin-left: 38%; margin-right: 50%;">
                        <div class="text-center" style="color: #1D2A62; font-size: medium; font-weight: 600;">Personal
                            Remittance</div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/beneficiary.png" style="height: 40px;margin: auto;">
                                <div class="vl1 hide-sm"></div>
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Beneficiary</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Enter your beneficiary details like beneficiary name, address and bank details.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/KYC.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">KYC
                                </div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Sign your A2 form and upload mandatory KYC documents.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/payment.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Payment</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Make payment so the remittance can be processed successfully.
                                </div>
                            </div>
                        </div><br><br><br>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-xl-4 col-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body" style="height: 100%;width: 100%;">
                        <img src="/assets/images/icons/forexCard.png"
                            style="height: 60px;margin-top: -50px; margin-left: 38%; margin-right: 50%;">
                        <div class="text-center" style="color: #1D2A62; font-size: medium; font-weight: 600;">Forex Card
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/tripInfo.png" style="height: 40px;margin: auto;">
                                <div class="vl hide-sm"></div>
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">Trip
                                    Info</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Enter the trip info like departure date, arrival date, purpose of travel, etc.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/traveller.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Traveller</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Enter the traveller details.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/KYC.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">KYC
                                </div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Sign your A2 form and upload mandatory KYC documents.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mt-20">
                                <img src="/assets/images/icons/payment.png" style="height: 40px;margin: auto;">
                            </div>
                            <div class="col-md-10 mt-15">
                                <div style="color: #1D2A62; font-size: medium; font-weight: 600; text-align: left;">
                                    Payment</div>
                                <div style="color: #000; font-size: x-small; font-weight: 500; text-align: left;">
                                    Make payment so the remittance can be processed successfully.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>