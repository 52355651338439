<!-- Footer -->
<p class="clearfix mb-0">

    <span class="text-center d-none d-md-block fs-12" style="color: black">
   <div class="footerText">
            <div style="line-height: 15px;">  
                CIN L99999MH1986PLC039660 | ISO 27001 Certified<br>
                © {{footerYear | date: 'yyyy'}} WSFx Global Pay Limited. All Rights Reserved |
               <span> <a style="color:#000;cursor: pointer;"  (click)="openDialog(disclaimerModal)">Disclaimer</a> |
                <a href="https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/Terms_and_Conditions_webPortal.pdf" target="_blank" style="color:#000;">
                    Terms & Conditions
                </a></span>
    </div>
    </div>
    </span>
</p>
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>



<ng-template let-modal #disclaimerModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">Disclaimer</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fs-13" tabindex="0" ngbAutofocus>
        <p>WSFX does not guarantee the accuracy, adequacy or completeness of any information and is not responsible for any errors or omissions or for the results obtained from the use of such information. WSFX especially states that it has no financial
            liability whatsoever to any user on account of the use of information provided on its website. WSFX does not warrant the accuracy, adequacy or completeness of this information and material and expressly disclaims liability for errors or omissions
            in this information and material. WSFX is not responsible for any errors, omissions or representations on any of our pages or on any links on any of our pages. Please verify the veracity of all information on your own before undertaking any
            trade or alliance. These results should therefore be used only as a guide. You can contact or visit our branch in order to obtain the actual rate available at time of exchange.</p>

        <p>There are risks associated with utilizing internet. Subscribers are advised to understand that the services can fail due to failure of hardware, software, and Internet connection. WSFX hereby expressly disclaims any implied warranties imputed
            by the laws of any jurisdiction. We consider ourselves and intend to be subject to the jurisdiction only of the courts of Mumbai in India. If you don't agree with any of our disclaimers above please do not rely on information shared on any
            of our pages. This site is specifically for users in the territory of India.</p>
        <p>Although the access to users outside India is not denied, WSFX shall have no legal liabilities whatsoever in any laws of any jurisdiction other than India. We reserve the right to make changes to our site and these disclaimers, terms, and conditions
            at any time. Money exchange rates & its trade is inherently risky and you agree to assume complete and full responsibility for the outcomes of all trading decisions that you make, including but not limited to loss of capital. The information
            is not meant to be an endorsement or offering of any purchase. They are meant to be a guide only, which must be tempered by experience and independent decision making process of the viewer.</p>

        <p>Thus this information is NOT a recommendation or solicitation to buy or sell any of foreign currency. By using this website including any software and content contained therein, you agree that use of the Service is entirely at your own risk and
            WSFX is not responsible thereof.</p>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-right blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
            <button class="btn btn-primary"> Close</button>
        </div>
    </div>
</ng-template>