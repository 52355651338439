import { Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit {
 
  constructor(private _location: Location) {}
  

  ngOnInit() {
  }

  backClicked() {
    this._location.back();
  }

}
