import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuranceService } from 'app/modules/insurance/services/insurance.service';
import { OrderService } from 'app/modules/order/services/order.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: [
    './payment-success.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentSuccessComponent implements OnInit {

  public orderId: string;
  public orderDetails: any;
  public showLoader: boolean = true;
  public paymentStatus: any;
  public paymentMode: any;
  public forexMarginString: String = '0.00';
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public insuranceDetails: any;
  public insuranceId: any;
  public showSuccessContent: any;
  public modalReference: any;
  public consumerDetail: any;
  public isProfileComplete: any;
  public isVcipComplete: any;
  public showVCIPPopup: boolean = false;
  public scheduleVCIPLater : boolean = false ;
  @ViewChild('VCIPModal') VCIPModal: TemplateRef<any>;
  vkycTimingText: any;

  constructor(  
    private route: ActivatedRoute,
    public orderService: OrderService,
    private router: Router,
    public modalService: NgbModal,
    public updateProfileService: UpdateProfileService,
    public insuranceService: InsuranceService,
    public toast: ToastrService) { }

  ngOnInit(): void {
    this.vkycTimingText= Constants.vkycTimingText;
    this.route.params.subscribe((params: Params) => {
      this.orderId = params['orderId'];
    });
    this.getSingleOrderDetails();
   // this.getProfileDetails();

  }

  getSingleOrderDetails() {
    this.showLoader = true;
    this.orderService.getOrderDetailsSingle(this.orderId).subscribe(respOrderData => {
      if (respOrderData['status'] == 200) {
        this.showLoader = false;
        this.orderDetails = respOrderData.body;
        this.paymentStatus = this.orderDetails?.orderStatusGroup?.consumerPayment;
        this.paymentMode = this.orderDetails?.paymentMode;
        let paymentMode = sessionStorage.getItem(ConstantsKey.PAYMENT_METHOD);
        if (paymentMode == 'NB' && this.paymentStatus == 'PENDING') {
          this.router.navigate(['/payment-failed', this.orderId]);
        } else if ((paymentMode == 'MANUAL' && (this.paymentStatus == 'COMPLETED' || this.paymentStatus == 'PENDING')) || (paymentMode == 'NB' && (this.paymentStatus == 'COMPLETED' || this.paymentStatus == 'PARTIAL'))) {
          this.showSuccessContent = true;
        }
        if (this.orderDetails?.invoice?.marginCharges != null) {
          this.forexMarginString = this.orderDetails?.invoice?.marginCharges.toFixed(2) + "";
        }
      }
    }, error => {
      this.showLoader = false;
      this.insuranceService.getTravelInsuranceCartDetails(this.orderId).subscribe(respInsuranceData => {
        this.showLoader = false;
        this.insuranceDetails = respInsuranceData.body;
        this.insuranceId = this.insuranceDetails.cart[0].insuranceOrderId;
      }, error => {
        this.insuranceDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        this.insuranceId = this.insuranceDetails.cart[0].insuranceOrderId;
        console.log('INSURANCE DETAILS >>> ', JSON.stringify(this.insuranceDetails));
      });
    });
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      let vcipCompletionFlag = localStorage.getItem(ConstantsKey.IS_VCIP_COMPLETE);
      console.log(vcipCompletionFlag);
      console.log(this.isVcipComplete);
      if (!this.isVcipComplete && vcipCompletionFlag == 'false') {
        this.showVCIPPopup = true;
        setTimeout(() => {
          this.modalReference = this.modalService.open(this.VCIPModal, { size: 'xl', centered: true });
          }, 3000);
      } 
    });
  }


  initiateVcip() {
    this.modalReference.close();
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  closePopup(){
    this.scheduleVCIPLater = true;
  }

  navigateToRating(VCIPModal) {
    this.router.navigate(['/ratings', this.orderId]);
    // if (this.showVCIPPopup && !this.scheduleVCIPLater) {
    //   this.modalReference = this.modalService.open(VCIPModal, { size: 'xl', centered: true });
    // } else {
    //   this.router.navigate(['/ratings', this.orderId]);
    // }
  }

  retryPayment() {
    this.router.navigate(['/retry-payment', this.orderId]);
  }

  goToOrderDetails() {
    this.router.navigate(['/order-details', this.orderId]);
  }

  goToInsuranceDetails() {
    // this.router.navigate(['/insurance-list']);
    this.router.navigate(['/insurance-details', this.insuranceId]);
  }
}