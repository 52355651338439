import { Component, OnInit} from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'list-service-request',
  templateUrl: './list-service-request.component.html',
})
export class ListServiceRequestComponent implements OnInit {
  public raiseTicketsList  :any;
  public showLoader : boolean = false;
  public noDataFound  :boolean = false;

  constructor(
    public supportService : SupportService,
    public toast : ToastrService,
    public router : Router
  ) {
    this.getRaiseTickets();
  }
  
  ngOnInit() {
  }

  getRaiseTickets(){
    this.showLoader = true;

    this.supportService.getConsumerTickets().subscribe((resp => {
      this.raiseTicketsList = resp.body;
      this.showLoader = false;

      if(this.raiseTicketsList.length == 0){
        this.noDataFound = true;
      }
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  goToTicketDetails(singleTicketDetails){
    this.router.navigate(['/ticketDetails'], {queryParams : singleTicketDetails});
  }
}
