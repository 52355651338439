<div class="content-wrapper row  container-xxl p-0">
    <div class="content-body col-md-12">
        <loader *ngIf="showLoader"> </loader>
        <div class="card invoice-preview-wrapper" *ngIf="orderDetails">
            <div class="card-body invoice-preview" *ngIf="paymentMode == 'NB' && paymentStatus == 'PENDING'">

                <img src="assets/images/failure.gif" style="height:60px;margin:auto;display:block">

                <div class="row">
                    <div class="col-md-12 text-center fw-600 mt-20">Apologies</div>
                    <div class="col-md-12 text-center mt-10">There seems to be an issue.</div>
                    <div class="col-md-6 offset-md-3 text-center">Please contact customer care at <a class="card-subtitle text-black mb-1" href="tel:8976707222" style="color : #0062ff">+91-8976707222 </a> or <a class="card-subtitle text-black  mb-1" href="mailto:customersupport@wsfx.in" style="color : #0062ff">customersupport@wsfx.in</a>                        and we will reach out to you immediately.!</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="p-15">
                            <div class="text-center text-black fw-600 mb-30">Order ID : {{ orderDetails?.orderId }}
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fw-600 fs-13 text-black">Amount</div>
                                <div class="col-md-6 col-6 text-right fw-600 fs-13 text-black">₹ {{this.orderDetails?.invoice?.billableAmountString}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black"> Forex Margin</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{forexMarginString}}
                                </div>
                            </div>
                            <div class="row  mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">Service Charges</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalHandlingChargesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">GST</div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">₹ {{this.orderDetails?.invoice?.totalTaxesString}}
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-12 text-black">TCS({{ this.orderDetails?.tcs?.tcsPercentageString }}%)
                                </div>
                                <div class="col-md-6 col-6 text-right fs-12 text-black">
                                    ₹ {{ this.orderDetails?.tcs?.tcsAmountString }}</div>
                            </div>

                            <div class="row mb-10" *ngIf="orderDetails?.invoice?.totalDiscount != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Applied coupon code</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    - ₹ {{this.orderDetails?.invoice?.totalDiscount}}
                                </div>
                            </div>
                            <div class="row mb-10" *ngIf="orderDetails?.redeemedPoints != '0'">
                                <div class="col-md-6 col-6 fs-13 text-black">Redeemed points</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    - ₹ {{this.orderDetails?.redeemedPoints}}
                                </div>
                            </div>

                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 text-black">Total Amount</div>
                                <div class="col-md-6 col-6 text-right fs-13  text-black">
                                    ₹ {{this.orderDetails?.invoice?.totalBillAmountString}}
                                </div>
                            </div>

                            <div class="row mb-10">
                                <div class="col-md-6 col-6 fs-13 fw-600 text-black">Amount Paid </div>
                                <div class="col-md-6 col-6 text-right fs-13 fw-600 text-black">₹ {{this.orderDetails?.invoice?.totalPaymentReceived}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-md-2">
                        <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="goToOrderDetails()">Order Details</button>
                    </div>
                    <div class="col-md-10 text-right">
                        <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="retryPayment()">Retry
                            Now</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>