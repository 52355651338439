import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IncompleteDisplayOrderModel } from '../../models/order.model';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'pending-order-list',
  templateUrl: './pending-order-list.component.html',
})
export class PendingOrderListComponent implements OnInit {
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public startDateForFilter: any;
  public endDateForFilter: any;
  public orderListItems: any;
  public date: any;
  public maxDate: any;
  public selectedFromDate: any;
  public showLoader: boolean = true;
  public minDateForEndDate: any;
  public maxDateForEndDate: any;
  public enableEndDatePicker: boolean = false;
  public noDataFound: boolean = false;
  public myjson: any = JSON;
  public ioId: string;
  public consumerId: string;
  public product: string;
  public cartInformation: any;
  public incompleteOrderList: Array<IncompleteDisplayOrderModel> = [];

  constructor(
    private orderService: OrderService,
    public router: Router,
    public toast: ToastrService
  ) {
    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    var startDate = new Date();
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth() + 1;
    let startDay = 1;
    this.startDate = { 'year': startYear, 'month': startMonth, 'day': startDay };
    this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  }

  ngOnInit(): void {
    this.getOrderList();
    // this.getFilteredOrderList();
  }

  getOrderList() {
    this.showLoader = true;
    this.orderService.getPendingOrderList().subscribe((resp => {
      this.orderListItems = resp.body;
      this.showLoader = false;
      this.noDataFound = false;
      if (this.orderListItems.length == 0) {
        this.noDataFound = true;
      }
      this.getPendingOrderList();
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  getPendingOrderList() {
    this.incompleteOrderList = [];
    for (let i = 1; i < this.orderListItems.length; i++) {
      let singleIncompleteOrderObj = new IncompleteDisplayOrderModel();
      if (this.orderListItems[i].beneficiaryName == "undefined" || this.orderListItems[i].beneficiaryName == undefined || this.orderListItems[i].beneficiaryName == "") {
        console.log("this.orderListItems[i].beneficiaryName");
        continue;
      }
      singleIncompleteOrderObj.ioId = this.orderListItems[i].ioId;
      singleIncompleteOrderObj.consumerId = this.orderListItems[i].consumerId;
      singleIncompleteOrderObj.createdAt = this.orderListItems[i].createdAt.toString();
      singleIncompleteOrderObj.beneficiaryName = this.orderListItems[i].beneficiaryName;

      var cartItemsJson = JSON.parse(this.orderListItems[i].cartItemsJson);
      singleIncompleteOrderObj.currencyCode = cartItemsJson[0].currencyCode;
      singleIncompleteOrderObj.currencyAmount = cartItemsJson[0].currencyAmount;
      singleIncompleteOrderObj.product = cartItemsJson[0].product;
      this.incompleteOrderList.push(singleIncompleteOrderObj);
    }
  }

  onDateSelect(event, sourceDate) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    if (sourceDate == 'startDate') {
      this.startDateForFilter = year + "-" + month + "-" + day;
      this.selectedFromDate = this.startDateForFilter;
      this.enableEndDatePicker = true;
      let minDateForEndDate = this.selectedFromDate;
      this.minDateForEndDate = { year: parseInt(minDateForEndDate.split("-")[0]), month: parseInt(minDateForEndDate.split("-")[1]), day: parseInt(minDateForEndDate.split("-")[2]) };

    } else if (sourceDate == 'endDate') {
      this.endDateForFilter = year + "-" + month + "-" + day;
    }
  }

  getFilteredOrderList() {
    this.showLoader = true;
    // this.startDateForFilter = this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day;
    // this.endDateForFilter = this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day;

    this.startDateForFilter = "2023-01-01";
    this.endDateForFilter = "2023-05-25";
    

    this.orderService.getFilteredOrderList(this.startDateForFilter, this.endDateForFilter).subscribe((resp => {
      this.orderListItems = resp.body;
      this.showLoader = false;
      this.noDataFound = false;
      if (this.orderListItems.length == 0) {
        this.noDataFound = true;
      }
      this.getPendingOrderList();
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  showErrorMessage() {
    this.toast.error('Please select from date first');
  }

  navigateToProceedOrder(orderId) {
    var redirectionUrl = "/proceed-and-pending-order/" + orderId + "/INCOMPLETE-ORDER";
    this.router.navigate([redirectionUrl])
  }
}