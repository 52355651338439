<div class="bs-stepper-header">
    <div class="step" data-target="#beneficiary-details">
        <button class="step-trigger d-none  d-lg-flex">
            <span class="bs-stepper-box">1</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">University</span>
                <span class="bs-stepper-subtitle" style="color: black"> University Details </span>
            </span>
        </button>
        <!-- mobile view  -->
        <button class="step-trigger d-flex  d-lg-none">
            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                <span class="">1</span>
            </span>
            <span class="bs-stepper-label">
                <img src="assets/images/cartStepper/University.png"  style="width:30%">
                         <p class="fs-11 fw-600 mb-0">University  </p>
            </span>
        </button>
        <!-- mobile view  -->
    </div>

    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#student-details">
        <button class="step-trigger d-none  d-lg-flex">
            <span class="bs-stepper-box">2</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">Student</span>
                <span class="bs-stepper-subtitle" style="color: black">Student Details</span>
            </span>
        </button>

        <button class="step-trigger d-flex  d-lg-none">
            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                <span class="">2</span>
            </span>
            <span class="bs-stepper-label">
                <img src="assets/images/cartStepper/Student.png"  style="width:30%">
                         <p class="fs-11 fw-600 mb-0">Student  </p>
            </span>
        </button>
        <!-- mobile view  -->
    </div>



    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#kyc" [ngClass]="kycSelfDeclElement.classList.contains('active') ? 'esignactive' : 'noEesignactive'">
        <button class="step-trigger d-none  d-lg-flex">
            <span class="bs-stepper-box">3</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">KYC</span>
                <span class="bs-stepper-subtitle" style="color: black">Complete your
                    KYC</span>
            </span>
        </button>
        <button class="step-trigger d-flex  d-lg-none">
            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                <span class="">3</span>
            </span>
            <span class="bs-stepper-label">
                <img src="assets/images/cartStepper/KYC.png"  style="width:30%">
                         <p class="fs-11 fw-600 mb-0">KYC  </p>
            </span>
        </button>
        <!-- mobile view  -->
    </div>

    <div class="step" data-target="#lrsDeclaration">
        <button class="step-trigger">
        </button>
    </div>

    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#kyc-self-declaration" style="display:none" #kycSelfDeclElement>
        <button class="step-trigger"></button>
    </div>

    <div class="step" data-target="#payment-details">
        <button class="step-trigger d-none  d-lg-flex">
            <span class="bs-stepper-box">4</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">Payment</span>
                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
            </span>
        </button>
        <button class="step-trigger d-flex  d-lg-none">
            <span class="position-absolute top-0 start-0 translate-middle  bs-stepper-box border border-light rounded-circle"   >
                <span class="">4</span>
            </span>
            <span class="bs-stepper-label">
                <img src="assets/images/cartStepper/payment.png"  style="width:30%">
                         <p class="fs-11 fw-600 mb-0">Payment  </p>
            </span>
        </button>
        <!-- mobile view  -->
    </div>
</div>