<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">

                <!-- Stepper Head Section -->
                <div class="bs-stepper-header">
                    <div class="step" data-target="#beneficiary-details">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Beneficiary</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Beneficiary Details </span>
                            </span>
                        </button>
                    </div>

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">KYC</span>
                                <span class="bs-stepper-subtitle" style="color: black">Complete your
                                    KYC</span>
                            </span>
                        </button>
                    </div>

                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc-self-declaration" style="display:none">
                        <button class="step-trigger"></button>
                    </div>

                    <div class="step" data-target="#payment-details">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Payment</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
                            </span>
                        </button>
                    </div>
                </div>
                <!-- End Stepper Head Section -->

                <div class="bs-stepper-content">
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <loader *ngIf="showLoader"></loader>

                        <!-- First Step -->
                        <div id="beneficiary-details" class="content">
                            <form #accountDetailsForm="ngForm">
                                <section>
                                    <div class="row">
                                        <div class="col-md-12  mb-10">
                                            <h6 class="content-header-title
                                            float-left mb-0">
                                                {{ contentHeader }}
                                            </h6>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="fs-11">
                                                Fill in university details like university name, country, address, university bank details etc.
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-20">
                                            <div style="background: #b6b1b1;padding: 10px;border-radius: 5px;">
                                                <div class="row">
                                                    <div class="col-md-7 col-7">
                                                        <span><img class="flag-icon"
                                                            src="assets/images/currencyFlag/{{stepperDataModel.orderDetails.orderItems[0].currencyCode}}.png"></span>
                                                        <span class="fw-600 text-black fs-10">
                                                            {{
                                                                stepperDataModel.orderDetails.orderItems[0].currencyCode
                                                            }}
                                                            {{
                                                                stepperDataModel.orderDetails.orderItems[0].currencyAmount
                                                            }}
                                                            =
                                                        </span>
                                                        <span><img src="assets/images/logo/india.png"
                                                                class="flag-icon"></span>
                                                        <span class="fw-600 text-black fs-10">
                                                            ₹ {{
                                                                stepperDataModel.orderDetails.orderItems[0].billableAmount
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="col-md-5 col-5 text-right">
                                                        <div class="fs-10 text-black fw-500 middle-text"> 1 {{stepperDataModel.orderDetails.orderItems[0].currencyCode}} = {{stepperDataModel.orderDetails.orderItems[0].bidRate}} INR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="pb-15" style="border-bottom: 1px solid #eee;">
                                    <div class="row" *ngIf="selectedPurpose.purposeCode === 'UNIVERSITY_FEES' || selectedPurpose.purposeCode === 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                        <div class="form-group col-md-6">
                                            <label class="form-label" for="country">Country</label>
                                            <ng-select placeholder="Select Country" name="countryListItems" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="selectedCountry" (change)="getUniversityList(selectedCountry)" class="fs-12" [clearable]="false" [searchable]="false">
                                            </ng-select>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label class="form-label" for="country">University</label>
                                            <ng-select placeholder="Select University" name="universityListItems" [items]="universityListItems" bindLabel="name" [(ngModel)]="selectedUniversity" addTagText="Add New University" [addTag]="addNewUniversity" (change)="navigateToAddBeneficiary(selectedUniversity.name, selectedCountry)"
                                                class="fs-12" [clearable]="false" [searchable]="false">
                                            </ng-select>
                                        </div>
                                    </div>
                                </section>

                                <list-beneficiary-stepper-email (childCallbackSender)="parentCallbackReceiverForListBeneficiary($event)">
                                </list-beneficiary-stepper-email>
                                <stepper-footer-email></stepper-footer-email>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" class="btn btn-outline-dark btn-prev fs-12" rippleEffect routerLink="/dashboard">
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Previous</span>
                                    </button> -->
                                    <button (click)="gotoDashboard()" type="submit" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Previous</span>
                                    </button>
                                    <button (click)="step01CreateCartItems()" type="submit" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End First Step-->

                        <!-- Third Step -->
                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <div class="row mb-20">
                                    <div class="col-md-12  mb-10">
                                        <h6 class="content-header-title
                                            float-left mb-0">
                                            {{ contentHeader }}
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="fs-11">Accept A2 Form cum declaration and upload your transaction documents.
                                        </div>
                                    </div>
                                </div>
                                <loader *ngIf="showLoader"></loader>

                                <section class="row" *ngFor="let singleDocument of transactionDocListUploaded">
                                    <div [ngClass]="{'col-lg-5 col-md-5 col-sm-5 col-xs-12 col-7':singleDocument.status == 'UPLOADED', 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12': singleDocument.status != 'UPLOADED'}">
                                        <div class="form-group">
                                            <label for="basicInputFile">{{ singleDocument.title }}</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                <label class="custom-file-label" for="customFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 col-5" *ngIf="singleDocument.status == 'UPLOADED' ">
                                        <div class="input-group-append mt-23" style="height: 38px;">
                                            <span class="input-group-text" (click)="documentsDownload(singleDocument)"><img
                                                    src="assets/images/icon/view-document.png" class="w-h-20"></span>
                                            <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                                    class="w-h-20"></span>
                                        </div>
                                    </div>
                                </section>

                                <section *ngFor="let singleDocument of transactionDocListAwaiting">
                                    <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="basicInputFile">{{ singleDocument.title }}</label>
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                    <label class="custom-file-label" for="customFile">
                                                        Choose file
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <stepper-footer-email></stepper-footer-email>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                            ">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                            ">Previous</span>
                                    </button>
                                    <button (click)="(navigateToFourthStep) ? step03GetDocumentProceed(kycForm) : showToast()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle
                                            ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Third Step -->

                        <!-- Fourth Step -->
                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <loader *ngIf="showLoader"></loader>
                                <esign-email></esign-email>
                                <stepper-footer-email></stepper-footer-email>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Previous</span>
                                    </button>
                                    <button (click)="step03EsignOrOtpValidate(kycselfdeclaration)" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Fourth Step -->

                        <!-- Fifth Step -->
                        <div id="payment-details" class="content">
                            <add-bank-account-for-remittance-email *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                            </add-bank-account-for-remittance-email>
                            <form #paymentDetails="ngForm" *ngIf="!showAddBankForm">
                                <div class="row mb-20">
                                    <div class="col-md-12  mb-10">
                                        <h6 class="content-header-title
                                            float-left mb-0">
                                            {{ contentHeader }}
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="fs-11">Complete your payment successfully to proceed with your remittance request.</div>
                                    </div>
                                </div>

                                <loader *ngIf="showLoader"></loader>

                                <table class="table table-bordered">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" class="text-center" style="padding-top: 10px">
                                                {{ stepperDataModel.orderDetails.orderItems[0].product }}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                1 {{ stepperDataModel.orderDetails.orderItems[0].currencyCode}} = INR {{ stepperDataModel.orderDetails.orderItems[0].bidRate }}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                {{ stepperDataModel.orderDetails.orderItems[0].currencyCode}} {{ stepperDataModel.orderDetails.orderItems[0].currencyAmount}}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                ₹ {{ stepperDataModel.orderDetails.orderItems[0].billableAmount}}
                                            </td>
                                        </tr>
                                        <tr></tr>
                                    </tbody>
                                </table>

                                <hr class="solid; hr-color: b-radius-5">

                                <div class="row mb-30">
                                    <div class="col-md-12 col-12">
                                        <div class="fs-14 text-black fw-600">
                                            Choose Your Bank Account
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i = index">
                                        <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                                            <div class="row mb-10">

                                                <div class="col-md-11 col-10">
                                                    <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                                    <span class="card-title fs-12 text-black fw-600 mb-10">
                                                        {{singleBank.bankName}}
                                                    </span>
                                                    <div class="fs-11 ml-15"> Account No : {{singleBank.number}}</div>
                                                    <div class="fs-11 ml-15"> IFSC Code : {{singleBank.ifscCode}}</div>
                                                    <div class="fs-11 ml-15"> Branch : {{singleBank.branch}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12 mt-20">
                                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true">
                                            Add Bank Account
                                        </button>
                                    </div>
                                </div>

                                <hr class="solid; hr-color: b-radius-5">

                                <div class="row mt-30">
                                    <div class="col-md-12">
                                        <div class="fs-12 text-black fw-600 mb-20">
                                            Payment Method
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-30">
                                    <div class="col-md-2 col-12 fs-12 mt-10">
                                        <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; Net Banking
                                    </div>

                                    <div class="col-md-3 col-12 fs-12 mt-10" *ngIf="ALLOW_NEFT_PAYMENT">
                                        <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                    </div>

                                    <div class="col-md-12 mt-30" *ngIf="paymentMode == 'MANUAL'">
                                        <div class="fs-12 fw-500  text-black">{{accountName}}
                                        </div>
                                        <div class="fs-11  text-black">{{bankName}}</div>
                                        <div class="fs-11  text-black">{{accountNo}}</div>
                                        <div class="fs-11  text-black">{{bankIFSC}}</div>
                                        <div class="fs-11  text-black">{{bankBranch}}</div>
                                    </div>

                                    <div class="col-md-12 fs-10 text-danger mt-30 mb-30 text-500" *ngIf="paymentMode == 'MANUAL'">
                                        Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section on the WSFx Global
                                        Pay App.
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn  btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle ">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                            ">Previous</span>
                                    </button>
                                    <button class="btn btn-primary btn-gradient btn-submit fs-12" rippleEffect (click)="openModalForPaymentInfo(paymentInstructionModal)">
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!--End Fifth Step -->

                    </form>
                </div>
            </div>
        </section>
    </div>
</div>


<ng-template #transactionDocumentViewModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">{{ documentTitle }}</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <img src={{documentUrl}} style="height:400px;display:block;margin:auto;width:100%;object-fit: contain;">
        </div>
    </div>
</ng-template>


<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                {{paymentInstruction}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" (click)="step04Payment()" style="cursor: pointer;">
            <a> OKAY  </a></div>
    </div>
</ng-template>