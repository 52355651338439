import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router';
import { RequestQuotationModel, QuotationCurrencyItem } from 'app/modules/dashboard/models/quotation.models';
import { StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { SinglePurposeItem, SingleRateResItem } from 'app/shared/models/shared-models';
import { RemittanceService } from 'app/modules/email-link/services/email.service';

@Component({
  selector: 'stepper-footer-email',
  templateUrl: './stepper-footer-email.component.html',
  styleUrls: ['../../../stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperFooterEmailComponent implements OnInit {

  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public quotationResponse: any;
  public selectedPurpose: SinglePurposeItem;
  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public orderId: any;
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public selectedPurposeItem: any;

  constructor(private router: Router, private remittanceSrevice: RemittanceService) {
    this.timer();
    this.getSessionData();
  }

  ngOnInit(): void {
    // this.getSessionData();
  }

  ngOnDestroy() {  }

  getData:any;
  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.requestQuotationModel = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
      console.log("Session Data++", this.stepperDataModel);
      this.orderId = this.stepperDataModel.orderDetails.orderId;
    } else {
      this.router.navigate(['/home']);
    }
  }

  callQuotation() {
    this.remittanceSrevice.updateQuotation(this.orderId).subscribe(resp => {
      this.stepperDataModel.orderDetails = resp.body;
      console.log("quotation response+", this.stepperDataModel.quotationResponse);
      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
      this.getSessionData();
    }, error => {
      // this.showLoader = false;
      // this.toast.error(JSON.stringify(error.error.message));
    });
  }


  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
        this.callQuotation();
      }
    }, 1000);
  }

}
