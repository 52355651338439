import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module'; 
import { CurrencyConverterComponent } from './views/currency-converter.component';

const routes = [
  {
    path: 'currency-converter',
    component: CurrencyConverterComponent,
    data: { animation: 'CurrencyConverterComponent' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    CurrencyConverterComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    LoaderRoutingModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [],
  providers: [
    CurrencyConverterComponent
  ]
})
export class CurrencyConverterRoutingModule { }
