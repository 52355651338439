import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { LoginWithOTPComponent } from './view/login-with-otp/login-with-otp.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { SignUpComponent } from './view/sign-up/sign-up.component';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { LogoutComponent } from './view/logout/logout.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { SmartFxLandingComponent } from '../smartfx-landing/views/smartfx-landing.component';
import { DirectLoginComponent } from '../direct-login/view/direct-login.component';

const routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SmartFxLandingComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'direct-login',
    pathMatch: 'full',
    component: DirectLoginComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'signup',
    component: SignUpComponent,
    data: { animation: 'auth' },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { animation: 'auth' },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { animation: 'auth' },
  }
];

@NgModule({
  declarations: [
    LoginWithOTPComponent,
    SignUpComponent,
    LogoutComponent,
    ForgotPasswordComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgbAlertModule,
    FormsModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    LoaderRoutingModule,
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-center-center',
    }),
  ],
  exports: []
})
export class OnboardingRoutingModule { }
