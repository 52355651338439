<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 text-center mb-20">
                        <h6 class="content-header-title">Refer and Earn</h6>
                    </div>
                    <div class="col-md-12 text-center">
                        <div class="fs-16 fw-600">Invite your friends and earn rewards</div>

                        <div class="text-center mt-20">Share your referral link and invite your friends.Earn upto
                            <span style="color:#1D2A62 !important" class="fw-600">&#8377;1000</span>
                        </div>

                        <div class="text-center mt-20 fw-600"> Here's How it works ?</div>
                    </div>
                </div>

                <div class="row mt-50">
                    <div class="col-md-4 text-center">
                        <img src="../../../../assets/images/Invite your Friends.png">
                        <div class="text-center mt-15">
                            <span class="fw-600">Invite your <br>friends & earn rewards </span>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <img src="../../../../assets/images/Register.png">
                        <div class="text-center mt-10">
                            <span class="fw-600">Your friend will <br>register through app</span>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <img src="../../../../assets/images/You and Your friend gets reward.png">
                        <div class="text-center mt-10">
                            <span class="fw-600">You & Your friend will   <br>receive the Award</span>
                        </div>
                    </div>
                </div>

                <div class="row mt-50 text-center">
                    <div class="col-md-2 ">
                        <div class="mt-8">Your Referral Code</div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="{{referralCode}}" value="{{referralCode}}">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" (click)="copyMessage(referralCode)">Copy</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-4 mt-5">
                        <div class="col-md-12  float-right">
                            <span> Share Now &nbsp;&nbsp;&nbsp;</span>
                            <a href="https://www.facebook.com" class="mr6" target="_blank">
                                <img src="assets/images/facebook.png" class="social-icon"></a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://www.instagram.com" class="mr6" target="_blank">
                                <img src="assets/images/instagram.png" class="social-icon"></a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://twitter.com/" class="mr6" target="_blank">
                                <img src="assets/images/twitter.png" class="social-icon"></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>