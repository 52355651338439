import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let xAuthToken = localStorage.getItem(ConstantsKey.X_AUTH_TOKEN);
    if (xAuthToken == null) {
      this._router.navigate(['/login']);
      return false;
    } else {
      return true;
    }

    ///check agent key first
    let agentId = localStorage.getItem(ConstantsKey.AGENT_ID);
    agentId = "SFT3456SSD";

    if (agentId == null) {
      this._router.navigate(['/unauthorizedAccess']);
      return false;
    } else {
      if (state.url.includes("login")) {
        return true;
      } else {
        let xAuthToken = localStorage.getItem(ConstantsKey.X_AUTH_TOKEN);
        if (xAuthToken == null) {
          this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        } else {
          return true;
        }
      }
    }
  }
}
