import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { AddAddressModel } from '../../models/address.model';
import { AddressService } from '../../services/address.service';

@Component({
  selector: 'add-address',
  templateUrl: './add-address.component.html',
  // styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddAddressComponent implements OnInit {

  public addAddressModel = new AddAddressModel();
  public showLoader : boolean = false;
  public addressTypeList = Constants.locationTypeList;
  public cityItemList = Constants.addressCityList;
  public consumerId : any;
  public editAddressDetails;
  public addressID = '';

  constructor(
    public toast: ToastrService,
    public router  : Router,
    public addressService : AddressService,
    public route: ActivatedRoute,
    ) { 
     this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.editAddressDetails = params;
      if(this.editAddressDetails != null ||  this.editAddressDetails != ""){
          this.addAddressModel.address = this.editAddressDetails.address;
          this.addAddressModel.city = this.editAddressDetails.city;
          this.addAddressModel.locationType =this.editAddressDetails.locationType;
          this.addAddressModel.postalCode = this.editAddressDetails.postalCode;
          this.addressID = this.editAddressDetails.id;
      } 
    });
  }

  addAddress(data){
    if(data == true){
      this.showLoader = true;
      this.addAddressModel.consumerId = this.consumerId;
      this.addAddressModel.default = true;
      this.addAddressModel.isDefault = true;
     
      if(this.addressID == undefined) {
        let localAddAddressRequests: Array<AddAddressModel> = [];
        localAddAddressRequests.push(this.addAddressModel);
        this.addressService.createConsumerAddress(localAddAddressRequests).subscribe((resp => {
          this.showLoader = false;
          this.toast.success("Address added successfully !");
          this.router.navigate(['/list-address']);
        }),error=>{
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else  {
        this.addressService.updateConsumerAddress(this.addAddressModel, this.addressID).subscribe((resp => {
          this.showLoader = false;
          this.toast.success("Address Updated successfully !");
          this.router.navigate(['/list-address']);
        }),error=>{
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      }  
      
    }    
  }
   
}
