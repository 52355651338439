import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class FaqService extends BaseProvider{

    assetUrl = 'assets/data';
    faqExtension = '.json'
    
    getRemittanceJSON(){
        var jsonUrl = this.assetUrl + "/remittanceFaq" + this.faqExtension;
        return this.http.get(jsonUrl);
    }

    getGeneralJSON(){
        var jsonUrl = this.assetUrl + "/generalFaq" + this.faqExtension;
        return this.http.get(jsonUrl);
    }

    getSmartCurrencyCardJSON(){
        var jsonUrl = this.assetUrl + "/smartCurrencyCardFaq" + this.faqExtension;
        return this.http.get(jsonUrl);
    }

    getForeignCurrencyJSON(){
        var jsonUrl = this.assetUrl + "/foreignCurrencyFaq" + this.faqExtension;
        return this.http.get(jsonUrl);
    }

    getVideoKycJSON(){
        var jsonUrl = this.assetUrl + "/videoKycFaq" + this.faqExtension;
        return this.http.get(jsonUrl);
    }
}