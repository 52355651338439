<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="fs-12 text-black fw-600">My Service Request</div>
                    </div>
                    <div class="col-md-6 text-right">
                        <button type="button" rippleEffect class="btn btn-primary mb-20 fs-10" routerLink="/addServiceRequest">New Service Request</button>
                    </div>
                </div>
                <loader *ngIf="showLoader"></loader>
                <div class="row" *ngIf="noDataFound">
                    <div class="col-md-12 text-center fw-500 text-black fs-12">No tickets found</div>
                </div>
                <div class="row" *ngIf="!noDataFound">
                    <div class="col-md-12" *ngFor="let singleRaiseTicket of raiseTicketsList">
                        <div class="card" style="box-shadow: 0 0 0 0 !important;border-bottom:1px dashed " (click)="goToTicketDetails(singleRaiseTicket)">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 col-10 mb-10">
                                        <span class="card-title fs-14 fw-600 text-black">Ticket Id : #{{singleRaiseTicket.id}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 col-12">
                                        <span class="card-text fs-11 text-black fw-500">Subject : </span>

                                        <span class="fs-11 text-black"> {{singleRaiseTicket.subject}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 col-12">
                                        <span class="card-text fs-11 text-black fw-500">Created At: </span>

                                        <span class="fs-11 text-black">{{singleRaiseTicket?.createdAt | date:'dd/mm/yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 col-12">
                                        <span class="card-text fs-11 text-black fw-500">Updated At: </span>

                                        <span class="fs-11 text-black">{{singleRaiseTicket?.updatedAt | date:'dd/mm/yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 col-12">
                                        <span class="card-text fs-11 text-black fw-500">Status: </span>

                                        <span class="fs-11 fw-600" [ngClass]="(singleRaiseTicket.status == 'Close' || singleRaiseTicket.status ==  'Open') ? 'text-danger' : 'text-warning'">{{singleRaiseTicket.status.toUpperCase()}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>