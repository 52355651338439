import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { BeneficiaryCreateRequestModel } from "../models/beneficiary.model";

@Injectable()
export class BeneficiaryService extends BaseProvider {

    getBeneficiaryListAll(remittanceType) {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'consumers/' + consumerId + '/beneficiary?remittanceType=' + remittanceType;
        return this.makeGetCall(resourceUrl);
    }

    addBeneficiary(request: any) {
        var serviceURL = "consumers/beneficiary";
        return this.makePostCall(serviceURL, request);
    }

    getSingleBeneficiary(beneficiaryId: string) {
        var serviceURL = "consumers/beneficiary/"+beneficiaryId;
        return this.makeGetCall(serviceURL);
    }

    deleteBeneficiary(beneficiaryId) {
        var resourceUrl = 'consumers/beneficiary/' + beneficiaryId;
        return this.makeDeleteCall(resourceUrl);
    }

    getPurposeListAll() {
        var resourceUrl = 'products?partnerId=p_wsfx';
        return this.makeGetCall(resourceUrl);
    }
}