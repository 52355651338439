<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="app-ecommerce-details">
            <div class="card">
                <div class="row my-2 text-center">
                    <div class="col-12 col-md-12 mb-2 mb-md-0">
                        <h4 class="fs-18 fw-600 mb-20" style="color: black"> Free ISIC Card </h4>
                        <div class="d-flex col-md-4 offset-md-4">
                            <img src="assets/images/icons/CardFront.png" class="img-fluid product-img" style="margin:auto;display: block;" />
                        </div>
                    </div>
                    <div class="col-12 col-md-10 offset-md-1">

                        <p class="card-text fs-11 mt-20" style="color: black">
                            WSFx Gloal Pay provides you with an annual membership of a complimentary virtual <span class="fw-600">ISIC card</span> in association with <span class="fw-600">International Student Identity Card (ISIC)</span> when you transact with
                            WSFx Global Pay.With this highly beneficial card, you can save money and make the most of your study abroad experience.
                        </p>

                        <p class="card-text fs-11" style="color: black">
                            Get 1,50,000+ discounts on food, travel, accommodation, entertainment and more in 125+ countries. Transact with us today to receive your complimentary Virtual ISIC card on behalf of WSFx Global Pay.
                        </p>
                        <p class="card-text fs-11" style="color: black">
                            For Terms and Conditions and other details visit- <a style="color: #3CC9EA;" href="https://www.isic.org/" target="_blank">https://www.isic.org</a>
                        </p>


                        <!-- <div class="link fs-11 blue-text" (click)="buttonText == 'View More' ? showContents() : hideContents()">{{buttonText}}</div> -->

                        <div class="pt-1">
                            <button type="button" rippleEffect class="btn btn-primary mb-20 fs-12" routerLink="/isicCardApplication">Apply Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>