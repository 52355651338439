<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills" style="margin-bottom: 0px !important; ">
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#sendMoney" [ngClass]="{ 'active':activeTab==='SEND_MONEY'}" (click)="getActiveTab('SEND_MONEY')" data-toggle="tab">Send Money</a>
                        <ng-template ngbNavContent>
                            <section id="sendMoney">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-6 col-12 mb-1 mt-30">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationCurrencyItem.currencyCode" (change)="getRateRange()" [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Amount" [(ngModel)]="quotationCurrencyItem.currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187"
                                                        (keypress)="keyPressNumbers($event)" (keyup)="enterCurrencyAmount()" />
                                                </div>
                                                <small class="form-text text-black20 fs-10 text-right fw-600">
                                                    1 {{ quotationCurrencyItem.currencyCode }} = {{
                                                    singleRateResItem.minimumRateInINR }} INR
                                                </small>
                                            </div>
                                            <div class="col-md-6 col-12  dotted-border">
                                                <div class="input-group  mt-30">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" value="{{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}" readonly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-10">
                                        <div class="fs-10 fw-600">You Pay</div>
                                        <div class="bold-amount mt-5">₹ {{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}
                                        </div>
                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-2 mt-30 text-right">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-10" (click)="proceedToStepper()">
                                            Book Now
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </ng-template>
                    </li>
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link" href="#buyForex" [ngClass]="{ 'active':activeTab==='BUY_FOREX'}" data-toggle="tab" (click)="getActiveTab('BUY_FOREX')" data-toggle="tab">Buy Forex</a>
                        <ng-template ngbNavContent>
                            <section id="buyForex">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-6 col-12 mb-1 mt-30">
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" [(ngModel)]="quotationCurrencyItem.currencyCode" [searchable]="false">
                                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Amount" [(ngModel)]="quotationCurrencyItem.currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187"
                                                        (keypress)="keyPressNumbers($event)" (keyup)="enterCurrencyAmount()" />
                                                </div>
                                                <small class="form-text text-black20 fs-10 text-right fw-600">
                                                    1 {{ quotationCurrencyItem.currencyCode }} = {{
                                                    singleRateResItem.minimumRateInINR }} INR
                                                </small>
                                            </div>
                                            <div class="col-md-6 col-12  dotted-border">
                                                <div class="input-group  mt-30">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-inr" id="basic-addon3">
                                                            <img src="assets/images/logo/india.png" class="inr-flag">
                                                            &nbsp; INR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="basic-url3" aria-describedby="basic-addon3" placeholder="Amount" value="{{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}" readonly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-10">
                                        <div class="fs-10 fw-600">You Pay</div>
                                        <div class="bold-amount mt-5">₹ {{quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString}}
                                        </div>
                                        <div class="text-black40 fs-9 mt-5">Including Forex Markup
                                        </div>
                                        <div class="fw-600 fs-9">*Plus Government taxes as applicable</div>
                                    </div>
                                    <div class="col-md-2 mt-30 text-right">
                                        <button type="submit" rippleEffect class="btn btn-primary fs-10" (click)="proceedToStepper()">
                                            Book Now
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="card p-20"></div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="fw-600 fs-18">Global Pay</div>
                    </div>
                    <div class="col-md-7 col-xl-7 col-12 mt-30">
                        <div class=" content-wrapper container-xl p-0 ">
                            <section id="wishlist" class="grid-view wishlist-items " style="grid-template-columns : 1fr 1fr 1fr 1fr !important;">
                                <div *ngFor="let singleMenu of menuItem ;let i=index">
                                    <div style=" padding-top: 10px; " (click)="getSubMenu(singleMenu)">
                                        <div class="dashboard-icon wh-72" [ngClass]="singleMenu.key === activeState
                                        ? 'selectedMenu' : '' " style="cursor: pointer;">
                                            <div class="mt-15">
                                                <img src="{{ singleMenu.icon }}" class="home-icon">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-15 mb-20">
                                        <div class="fw-600 text-black fs-12 text-center">
                                            {{ singleMenu.title }}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-md-5 pl-0">
                        <div *ngIf="!isVcipComplete" class="card mt-20" style="text-align: center;">
                            <div class="mt-10 mb-10 p-10">
                                <span style="color:#880808;margin-bottom: 10px;" class="fs-10"> We need your urgent attention. Please complete your VCIP first before proceed.</span><br>
                                <button type="submit" rippleEffect class="btn btn-primary fs-10 mt-10" (click)="initiateVcip()">
                                    Complete VCIP
                                </button>
                            </div>
                        </div>
                        <div class="br-10  p-20 mt-30 light-bg-grey" *ngIf="showCardDetailsBox && isVcipComplete">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="fs-16">Get</div>
                                    <div class="fs-16">Your Smart</div>
                                    <div class="fs-18 fw-600">Forex Card Now!</div>
                                    <!-- <div class="fs-9 mt-5 text-black fw-600">View More >></div> -->
                                </div>
                                <div class="col-md-5">
                                    <img src="assets/images/smartfx-home/Forex-Card.png" class="w-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-30">
                    <div class="content-wrapper container-xl">
                        <section id=" wishlist " class="grid-view wishlist-items " style="grid-template-columns :1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;">
                            <div *ngFor=" let singleMenu of studentRemittance ;let i=index " (click)="getActiveSectionTitle(singleMenu)">
                                <!-- (click)="navigateToNextPage(singleMenu.pageUrl)" -->
                                <div style=" padding-top: 10px;">
                                    <div class="dashboard-icon wh-72" [ngClass]="selectedPurposeItem?.title == singleMenu?.title
                                    ? 'ecommerce-card-selected' : '' ">
                                        <div class="mt-15" style="cursor: pointer;">
                                            <img src="{{ singleMenu.icon }}" class="home-icon">
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-15">
                                    <div class="fw-600 text-black fs-12 text-center">{{ singleMenu.title }}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>