import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { CustomerValidationRequestModel, LinkCardMobileRequestModel, LinkCardRequest02Model } from '../../models/card.model';
import { CardService } from '../../services/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';

@Component({
  selector: 'link-existing-card',
  templateUrl: './link-existing-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LinkExistingCardComponent implements OnInit {

  public customerValidationRequestModel = new CustomerValidationRequestModel();
  public cardLinkOption: any;
  public linkCardMobileRequest = new LinkCardMobileRequestModel();
  public linkCardStep = 1;
  public dobPanPass = 1;
  public showLinkMobile = true;
  public showLinkPan = true;
  public linkCardRequest02Model = new LinkCardRequest02Model();
  public showLoader: boolean = false;
  public consumerDetail: any;
  public mobileNo: any;
  public cardLinkType: any;
  public showPAN: boolean = true;
  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    public updateProfileService: UpdateProfileService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getProfileDetails();
  }

  getValidateCustomer(data) {
      if (data == true) {
        this.showLoader = true;
        this.cardService.validateCustomer(this.customerValidationRequestModel).subscribe(resp => {
          this.showLoader = false;
          // this.router.navigate(['/validate-card-details']);
          // this.toast.success("Valid Customer");
          this.dobPanPass = 2;
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      }
  }

  getOTP() {
    this.showLoader = true;
    this.cardLinkOption = "OTP";

    this.cardService.getCardLinkOTP("false", {}).subscribe(response => {
      this.showLoader = false;
      this.showPAN = false;
      this.toast.success('OTP has been sent successfully on ' + this.linkCardMobileRequest.mobileNumber);
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  getResendOTP() {
    this.cardLinkOption = "OTP";
    this.cardService.getCardLinkOTP("true", {}).subscribe(response => {
      this.toast.success('OTP resent successfully on ' + this.linkCardMobileRequest.mobileNumber);
    }, error => {
      this.toast.error('OTP resent failed.');
    });
  }

  validateCustomerMobile() {
    this.showPAN = false;
    if (this.linkCardMobileRequest.otp != null) {
      this.showLoader = true;
      this.cardService.validateCustomer(this.linkCardMobileRequest).subscribe(result => {
        this.showLoader = false;
        this.linkCardStep = 2;
        this.showLinkPan = false;
        this.showLinkMobile = false;
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    } else {
      this.toast.error("Please enter OTP");
    }
  }

  linkCardStep02() {
    if (this.validateStep2Data()) {
      this.linkCardRequest02Model.dob = this.formateDateToRequired(this.linkCardRequest02Model.dob);
      this.cardService.cardLinkStep02(this.linkCardRequest02Model).subscribe(result => {
        localStorage.setItem("isCardLinked", 'true');
        this.router.navigate(['/my-card']);
      }, error => {
        if (error.error.reason=='INVALID_ENTRY_ERROR_TO_UI') {
          this.toast.error("Invalid details! Please provide a valid Date of birth and Card Expiry ");
        } else {
          this.toast.error(error.error.message);
        }
        this.linkCardRequest02Model.dob = null;
        this.linkCardRequest02Model.cardExpiry=null;
      });
    }
  }

  validateStep2Data() {
    if (!(this.linkCardRequest02Model.dob != null)) {
      this.toast.error('Please select date of birth');
      return false;
    } else if (!(this.linkCardRequest02Model.cardExpiry != null)) {
      this.toast.error('Please enter card expiry');
      return false;
    } else {
      return true;
    }
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.consumerDetail = resp.body;
      this.linkCardMobileRequest.mobileNumber = this.consumerDetail.paxResponse.mobile;
    });
  }

  formateDateToRequired(dateObj) {
    var requiredDate = dateObj.year + "-" + this.pad(dateObj.month) + "-" + this.pad(dateObj.day);
    return requiredDate;
  }

  pad(n) { return n < 10 ? '0' + n : n }
}
