<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body mt-30">
                <loader *ngIf="showLoader"></loader>
                <form class="form-horizontal" (ngSubmit)="submitFeedback(FeedbackForm.form.valid)" #FeedbackForm="ngForm">

                    <!-- <div class="card-body"> -->
                    <!-- <div class="row mt-120">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="disabledInput">Subject</label>
                            <input type="text" class="form-control" id="consumerFeedback" readonly="readonly"
                                value="Consumer Feedback">
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div> -->
                    <div class="row">
                        <div class="col-md-4 offset-md-4">
                            <label for="basicTextarea">Feedback</label>
                            <fieldset class="form-group">
                                <textarea class="form-control fs-12" name="feedback" id="basicTextarea" rows="5" placeholder="Your feedback here!" [(ngModel)]="chatMessage" #feedbackRef="ngModel" [class.error]="FeedbackForm.submitted && feedbackRef.invalid" minlength="10" pattern="\s*(\S\s*){10,}"
                                    required></textarea>
                            </fieldset>

                            <span *ngIf="FeedbackForm.submitted && feedbackRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="feedbackRef.errors.required">This field is required!</small>
                            </span>
                            <span class="invalid-form" *ngIf="FeedbackForm.submitted && feedbackRef.invalid">
                                <small class="text-danger" >Feedback must be at least 10 characters long.</small> 
                            </span>
                        </div>

                        <div class="col-sm-4 offset-md-4 text-right ">
                            <button type="submit" rippleEffect class="btn btn-primary btn-gradient fs-12 ">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>