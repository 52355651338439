<style>
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #000 !important;
        font-weight: 500;
        border-color: #000 !important;
        border-radius: 0ch !important;
    }
</style>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <loader *ngIf="showLoader"></loader>
        <section class="invoice-preview-wrapper">
            <div class="row invoice-preview">
                <div class="col-xl-12 col-md-12 col-12 printRef">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pb-0">
                            <img src="/assets/images/logo/logo.png" style="height:35px;margin: auto;display: block;"
                                class="mb-20  d-block d-sm-none">
                            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                                <div class="mt-md-0 mt-2 mb-20">
                                    <h4 class="invoice-title">
                                        Order ID
                                        <span class="invoice-number"># {{orderDetails?.orderId}}</span>
                                    </h4>
                                    <div class="invoice-date-wrapper mb-10">
                                        <p class="invoice-date fs-12 text-black">{{orderDetails?.orderCreateDate |
                                            date:'MMM dd yyyy | hh:mm a'}}</p>
                                    </div>
                                    <div class="invoice-date-wrapper fs-12">
                                        <p class="invoice-date"
                                            [ngClass]="(orderDetails?.orderStatusGroup?.status == 'EXPIRED' || orderDetails?.orderStatusGroup?.status ==  'CANCELLED' || orderDetails?.orderStatusGroup?.status ==  'REJECTED') ? 'badge badge-danger' : (orderDetails?.orderStatusGroup?.status ==  'IN_PROCESS') ? 'badge badge-warning' :  'badge badge-success' ">
                                            {{orderDetails?.orderStatusGroup?.status}}</p>
                                    </div>
                                </div>

                                <div class="fs-12 text-black section-right ">
                                    <img src="/assets/images/logo/logo.png" style="height:35px;"
                                        class="mb-20 show-section">
                                    <div>
                                        <p class="card-text mb-25 fw-500"> Product : {{
                                            this.orderDetails?.orderItems[0]?.product}}
                                        </p>
                                        <p class="card-text mb-25 fw-500">Currency :
                                            {{this.orderDetails?.orderItems[0]?.currencyCode}}
                                        </p>
                                        <p class="card-text mb-25 fw-500"> FX Amount :
                                            {{this.orderDetails?.orderItems[0]?.currencyAmount}}
                                        </p>
                                        <p class="card-text mb-25 fw-500"> IBR :
                                            {{this.orderDetails?.orderItems[0]?.bidRate}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="invoice-spacing" />

                        <div class="card-body card-padding">
                            <div class="row invoice-sales-total-wrapper">
                                <div class="col-md-12  order-md-2 order-1">
                                    <div class="invoice-total-wrapper p-15">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Amount : </p>
                                            <p class="invoice-total-amount fs-12 text-black fw-500">₹
                                                {{this.orderDetails?.invoice?.billableAmountString}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Charges (Includes
                                                Nostro Charges) :</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹
                                                {{this.orderDetails?.invoice?.totalHandlingChargesString}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Taxes :</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹
                                                {{this.orderDetails?.invoice?.totalTaxesString}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">TCS : ({{
                                                this.orderDetails?.tcs?.tcsPercentageString }}):</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹
                                                {{this.orderDetails?.tcs?.tcsAmountString}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title fs-12 text-black fw-600">Total Amount :</p>
                                            <p class="invoice-total-amount fs-12 text-black fw-500">₹
                                                {{this.orderDetails?.invoice?.totalBillAmountString}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Amount Paid : </p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹
                                                {{this.orderDetails?.invoice?.totalPaymentReceived}}
                                            </p>
                                        </div>
                                        <hr class="my-50">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-16" style="color: #00CCEE;">Balance :
                                            </p>
                                            <p class="invoice-total-amount text-black fw-700 fs-16" style="color: #00CCEE;">₹
                                                {{this.orderDetails?.invoice?.totalPaymentPending}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="invoice-spacing" />

                        <div class="col-md-12 text-center">
                            <div class="fw-600 text-black  fs-13 mt-30 mb-30"
                                style="cursor: pointer;" *ngIf="!readOnlyRating">
                                Do you want to accept this Quotation and Proceed with it?</div>
                            <div class="row">
                            <div class="col-md-8">
                                <button type="submit" class="btn btn-primary btn-next fs-12" (click)="openModalForQuotes(quotesModal)" rippleEffect>
                                    <span class="align-middle ">Yes</span>
                                </button>
                            </div>
                            <div class="col-md-1">
                                <button type="submit" class="btn btn-primary btn-next fs-12" (click)="cancelQuotation()" rippleEffect>
                                    <span class="align-middle ">No</span>
                                </button>
                            </div>
                          </div> 
                          <br>   
                        </div> 

                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #quotesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                Do you want to proceed to fill the details and complete your quotation?
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="w-100">
            <button type="button" class="btn btn-secondery mr-auto" (click)="gotoEmailFlow()" style="cursor: pointer; color: blue;">Yes</button>
            <button type="button" class="btn btn-secondery float-right" (click)="msgConvey()" style="cursor: pointer; color: blue;" >No</button>
        </div>
    </div>
</ng-template>