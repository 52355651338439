import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { OfacFatfModel } from 'app/modules/email-link/models/cart.model';
import { StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { RemittanceService } from 'app/modules/email-link/services/email.service';
import { RelationshipService } from 'app/modules/relationship/services/relationship.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PagesList } from 'app/shared/constant/page-list';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'enhance-due-diligence-email',
  templateUrl: './enhance-due-diligence-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DueDiligenceEmailComponent implements OnInit {

  public addOfacRequestModel: OfacFatfModel = new OfacFatfModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();

  constructor(
    private router: Router,
    public remittanceService: RemittanceService,
    public toast: ToastrService
  ) { }

  ngOnInit() { }

  addOfacFatfData() {
    /// check ofac fatf enable
    console.log(JSON.stringify(this.addOfacRequestModel));
    
    // if (true) {
    //   if (this.addOfacRequestModel.occupationLengthResidenceForeign != null && this.addOfacRequestModel.sourceOfFund != null
    //     && this.addOfacRequestModel.lengthResidenceForeign != null && this.addOfacRequestModel.natureBusinessInIndia != null
    //     && this.addOfacRequestModel.transactionHistory != null) {
    //     // add reference id
    //     // this.addOfacRequestModel.refOrderId = this.getRefCartId;
    //     this.remittanceService.postDueDelligenceData(this.addOfacRequestModel).subscribe((result) => {
    //     });
    //   } else {
    //     this.toast.error("Please fill all the details")
    //   }
    // }
  }

  updateDataToSession() {
    this.getSessionData();
    this.stepperDataModel.ofacData.isOfacRequired = true;
    this.stepperDataModel.ofacData.addOfacRequestModel = this.addOfacRequestModel;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
    }
  }
}