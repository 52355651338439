import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'order-success',
  templateUrl: './order-success.component.html',
  // styleUrls: ['../../../../../@core/scss/base/pages/app-invoice.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
