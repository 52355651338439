import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddServiceRequestModel } from '../../models/support.model';
import { SupportService } from '../../services/support.service';
import { Constants } from 'app/shared/constant/constants';


@Component({
  selector: 'add-service-request',
  templateUrl: './add-service-request.component.html',
  // styleUrls: ['../../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddServiceRequestComponent implements OnInit {
  public addServiceRequestModel = new AddServiceRequestModel();
  public serviceRequestSubjectListItems = Constants.serviceRequestSubjectListItems;


  constructor(
    public supportService: SupportService,
    public toast: ToastrService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  addServiceRequest() {
    if (this.addServiceRequestModel.comment != null && this.addServiceRequestModel.comment != '') {
      if (this.addServiceRequestModel.subject != null && this.addServiceRequestModel.subject != '') {
        this.supportService.addServiceRequest(this.addServiceRequestModel.subject, this.addServiceRequestModel.comment).subscribe((resp => {
          this.toast.success("Request added successfully !");
          this.router.navigate(['/listServiceRequest']);
        }), error => {
          this.toast.error(error.error.message);
        });
      } else {
        this.toast.error('Please select subject');
      }
    } else {
      this.toast.error('Please enter comment!');
    }

  }

  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
}
