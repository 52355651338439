<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <!-- <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14  mb-10">Buy Currency Notes</h5>
            <hr class="my-50 hr-color">
        </div> -->
        <div class="row" *ngIf="!isCartEmpty">
            <div class="col-md-3"></div>

            <div class="col-md-6">
                <label class="text-danger fs-10 fw-500">Rates will refresh after {{ progressWidth }}
                        minutes
                    </label>
                <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
                </div>
            </div>
        </div>
        <loader *ngIf="showLoader"></loader>
        <div class="row mt-30">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 card-order">
                <div class="row">
                    <div class="col-md-12  mb-1">
                        <!-- <section class="row" style="padding:0px 5px 0px 0px;">
                            <div class="col-md-12">
                                <div class="card  bg-light-grey" style="box-shadow : 0 0 0 0!important"> -->
                                    <div class="card-header text-black fw-500 fs-14">
                                        Add other Currency Notes (Cash)
                                    </div>
                                    <div class="row" style="padding:0rem 1.5rem 0.2rem 1.5rem">
                                        <div class="col-md-4 col-12  mb-1 pl-0">
                                            <ng-select class="fs-12" [items]="currencyCountryList" placeholder="Select Currency" [(ngModel)]="currencySelectedCash" bindLabel="currencyCode" bindValue="currencyCode" [clearable]="false" [searchable]="false">
                                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                    <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-4 col-12 mb-1 pl-0">
                                            <input type="number" class="form-control fs-11" [(ngModel)]="currencyAmountCash" placeholder="Amount *" aria-label="Amount" style="border-color: black" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187 && event.keyCode !== 190"
                                            />
                                        </div>
                                        <div class="col-md-4 col-12 mb-1 pl-0 text-right">
                                            <button type="submit" rippleEffect class="btn btn-primary btn-block fs-10" (click)="addCashCurrencyToCard()">Add Cash</button>
                                        </div>
                                    </div>
                                <!-- </div>
                            </div>
                        </section> -->
                    </div>
                    <loader *ngIf="showLoader"></loader>
                </div>
                <div class="row">

                <div class="col-md-12  mb-1">
                    <section class="row" style="padding:0px 5px 0px 0px;">
                        <div class="col-md-12">
                            <div class="card " style="box-shadow : 0 0 0 0!important">
                                <div class="card-header text-black fw-500 fs-14">
                                    <!-- <label style="font-size:0.980rem!important;">Add <a style="font-weight: 800;">Forex Card</a> along with Currency Notes (Optional)</label> -->
                                    <label style="font-size:0.980rem!important;">Add Forex Card along with Currency Notes (Optional)</label>
                                </div>
                                <div class="card-body row">
                                    <div class="col-md-4 col-12 mb-1 pl-0">
                                        <ng-select class="fs-12" [items]="currencyCountryList" placeholder="Select Currency"
                                            [(ngModel)]="currencySelected" bindLabel="currencyCode" bindValue="currencyCode"
                                            [clearable]="false" [searchable]="false">
                                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png"
                                                    style="height:15px;width: 15px;"> {{item.currencyCode}}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-4 col-12 mb-1 pl-0">
                                        <input type="number" class="form-control fs-11" [(ngModel)]="currencyAmount"
                                            placeholder="Amount *" aria-label="Amount" style="border-color: black"
                                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187 && event.keyCode !== 190" />
                                    </div>
                                    <div class="col-md-4 col-12 pl-0 text-right">
                                        <button type="submit" rippleEffect class="btn btn-primary btn-block fs-10"
                                            (click)="addCardToCart()">Add To Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                </div>

            </div>



            <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12" *ngIf="!isCartEmpty">
                <div class="row">
                    <div class="col-md-12  mb-5">
                        <h6 class="content-header-title float-left mb-0" style="color: #1866f4 !important">
                            Your Cart Details
                        </h6>
                    </div>

                </div>
                <div>
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Currency</th>
                                <th>FX Amount</th>
                                <th>IBR</th>
                                <th>INR Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody class="fs-10 text-black">
                            <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                                <td>
                                    {{singleTransaction?.product}}
                                </td>
                                <td>
                                    {{singleTransaction?.currencyCode}}
                                </td>
                                <td>
                                    {{singleTransaction?.currencyAmount}}
                                </td>
                                <td>
                                    {{singleTransaction.rateString | number : '1.2-2'}}
                                </td>
                                <td>
                                    {{singleTransaction.inrEquivalentAmountString}}
                                </td>
                                <td>
                                    <span class="red-text-delete"><a (click)="removeCartItemClicked(singleTransaction)">Delete </a></span>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <section class="invoice-preview-wrapper">
                    <div class="table-responsive mt-15 amout-breakup-bg">
                        <table class="table table-condensed table-borderless  fs-12">
                            <tbody>
                                <tr style="border-top:1px solid #f4f4f4">
                                    <td colpan="2"></td>
                                </tr>
                                <tr>
                                    <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                    <td class="thick-line text-right text-black fw-600 "> ₹ {{quotationResponse?.billableAmountString}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.marginChargesString}} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.finalChargesString }} </td>
                                </tr>
                                <tr *ngIf="quotationResponse?.tcs?.tcsAmountString!=0.0">
                                    <td class="no-line " colspan="3"><strong>TCS ({{ quotationResponse?.tcs?.tcsPercentageString }}%)</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.tcs?.tcsAmountString }} </td>
                                </tr>
                                <tr>
                                    <td class="no-line " colspan="3"><strong>GST</strong></td>
                                    <td class="no-line text-right">₹ {{ quotationResponse?.gstString }} </td>
                                </tr>

                                <tr style="border-top:1px solid #bdbcbc">
                                    <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                    <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </section>

            </div>
        </div>
        <hr class="dotted">

        <div class="d-flex justify-content-between mt-20">
            <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect (click)="backClick()">
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle">Back</span>
                </button>
            <button type="submit" class="btn btn-primary btn-next fs-12" (click)="checkVcip()" rippleEffect>
                    <span class="align-middle">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
        </div>
    </div>
</div>
<ng-template let-modal #AmountLimitModal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <span class="text-center">Dear Customer,</span><br>
        <span class="text-center"> {{cashLimitErrorMsg}}</span><br>
    </div>
    <div class="row">
        <button class="offset-md-5 mt-10 mb-10 btn btn-primary fs-10" rippleEffect
            (click)="modal.dismiss('Cross click')">
            Okay </button>
    </div>
</ng-template>

<ng-template #deleteConfirmationDailog let-modal>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
              <span class="text-center"> Do you want to Remove </span> <br> {{deleteItemMesg}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 modal-footer border-right">
            <div class="row col-12">
                <div class="fw-500 fs-12 blue-text" (click)="removeCartItem()" style="cursor: pointer; padding-left: 50%;">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-6 modal-footer border-left">
            <div class="row col-12">
                <div class="fw-500 fs-12 red-text-delete" (click)="modal.dismiss('Cross click')"
                    style="cursor: pointer; padding-left: 40%;">
                    No
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipModalNewUser>
    <!-- <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-14" style="color: black">
                Dear Customer, Your <span class="fw-600 text-black">Video Based Identification Process (VCIP)</span> is pending.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row ">
            <button class="col-md-4 offset-md-1  btn  fs-10 vcip-btn" rippleEffect (click)="VCIPNow()">
                I will complete my VCIP Now
            </button>
            <!-- <div class="col-md-1"></div> -->
            <button class="col-md-4  offset-md-1 btn btn-primary fs-10" rippleEffect (click)="proceedWithourVCIP()">
                I will complete my VCIP Later
            </button>
        </div>
    </div>
</ng-template>

<!-- vcip complete or not modal  -->
<ng-template let-modal #vcipCompleteOrNotModal>
    <div class="modal-header" >
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="proceedWithourVCIP()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body  fs-13 p-40" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-md-3">
                <img src="assets/images/VCIP.png" style="width:100%">
            </div>
            <div class="col-md-9 fs-13">
                <div class="font-bold-black  mb-20">In order to complete the transaction, Video-Know Your Customer (VKYC) step has to be completed by you.</div>
                <p class="font-normal-black">Kindly complete the video KYC at the earliest. Video KYC is mandatory and is to be completed within 24 hours of receiving the VKYC link. For transactions booked on Saturday, please ensure that VKYC is done within the next working day</p>

                <p class="font-bold-red">Also note that non-completion of VKYC shall render your order to be cancelled, and funds will be returned to the originating bank account.</p>

                <p class="font-bold-black  mb-20">{{vkycTimingText}}</p>

                <p class="font-normal-blue mb-20 ">If you have any further queries, please contact our Customer Care at 8976707222 or email us at customersupport@wsfx.in
                </p>

                <div class="fw-500 fs-13 blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
                    <!-- <button class="btn vcip-btn fs-13" (click)="proceedWithourVCIP()"> Schedule your VKYC later</button> &nbsp; &nbsp; -->
                    <button class="btn btn-primary fs-13" (click)="initiateVcip()"> Complete your VKYC now</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template let-modal #PANDetailModal>
        <div class="modal-header" style="display:block; ">
            <h5 class="modal-title fw-600" id="myModalLabel1" style="text-align:center; color: #1d2a62;">Customer Details</h5>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Permanent Account Number (PAN)*</b></label>
            <input required type="text" name="panNo" (keypress)="alphaNumberOnly($event)" (keypress)="alphaNumberOnly($event)" oninput="this.value = this.value.toUpperCase()" class="form-control fs-12" style="border-color:black" placeholder="Enter your PAN"  maxlength="10" (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.pan" required minlength="10" />
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label class="form-label mb-5"><b>Name as per Permanent Account Number*</b></label>
            <input required type="text" name="panName" class="form-control fs-12" style="border-color:black" placeholder="Name as per PAN"   (paste)="onPaste($event)" [(ngModel)]="updateProfileModel.name" required/>
        </div>
        <div class="modal-body">
        <div class="custom-control custom-checkbox col-md-12 fs-12">
            <input type="checkbox" [(ngModel)]="isPANCBChecked" id="checkboxForAcceptTermsAndCondition" class="custom-control-input"  style="border-top:  1.2px dashed rgb(138 144 150 / 75%) !important">
            <label class="custom-control-label text-black fs-11" for="checkboxForAcceptTermsAndCondition">  I declare that the PAN provided belongs to me.</label>
        </div>
        </div>
        <div class="modal-body">
            <label class="form-label"><b>Date Of Birth*</b></label>
            <div class="input-group">
                <input  class="form-control fs-12"   (click)="basicDP.toggle()" name="dobb" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="updateProfileModel.dob" ngbDatepicker #basicDP="ngbDatepicker"  [minDate]="{year: 1900, month: 1, day: 1}"
                   >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            
            <button class="col-md-12  mt-10 btn btn-primary fs-10"  rippleEffect (click)="validatePanAPI()">
            Proceed
        </button>
        </div>
</ng-template>
<ng-template #errorModal let-modal>
    <div class="modal-header">
        <div class="modal-title fs-10 text-black text-center" id="myModalLabel1">

        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center red-text-delete">Invalid Details !
        </div>
        <div class="text-center">
            {{panVerificationErrMsg}}
        </div>
    </div>
    
    <div class="mcol-md-12  text-center">
        <div class="mb-10 fw-500 fs-10 btn btn-primary" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            Close
        </div>
    </div>
</ng-template>