import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { OfferRewardsService } from '../../services/offer-rewards.service';
 
@Component({
  selector: 'referAndEarn',
  templateUrl: './referAndEarn.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
            './referAndEarn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReferAndEarnComponent implements OnInit {
  referralCode: string;
  referralList = new Array<any[]>();
  constructor(
    public router: Router,
    public offerRewardsService  : OfferRewardsService) { }

  ngOnInit() {
    this.getConsumerReferralDetails();
  }

  getConsumerReferralDetails() {
    this.offerRewardsService.getConsumerReferral().subscribe(
      result => {
        var res = result.body;
        this.referralCode = res['code'];

      },
      error => {
      });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert("Referral Code Copied ");

  }
}
