<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <div class="content-header">
            <h5 class="mb-10" style="color: black;font-weight: 600;">Activate INR Wallet</h5>
        </div>
        <div style="color: red;font-size: 12px;padding-bottom: 12px;">
            Note: Kindly fill the details as per Aadhaar Card(*) 
        </div>
        <form class="form-horizontal" (ngSubmit)="callActivateINRWallet(ActivateINRWallet.form.valid)" #ActivateINRWallet="ngForm">
            <loader *ngIf="showLoader"></loader>
            <div class="row ">
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label class="form-label"><b>Gender</b></label>
                    <select id="gender" name="gender" class="form-control fs-12" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.gender" #genderRef="ngModel" required>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                    </select>
                    <span *ngIf="ActivateINRWallet.submitted && genderRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="genderRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>Name*</b></label>
                    <input type="text" class="form-control fs-12" name="ifscCode" placeholder="Name" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.nameOnId" #nameRef="ngModel"
                        [class.error]="ActivateINRWallet.submitted && nameRef.invalid" (keypress)="letterOnly($event)"
                        required />
                    <span *ngIf="ActivateINRWallet.submitted && nameRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="nameRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
            </div>

            <div class="row ">
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label class="form-label"><b>Date Of Birth*</b></label>
                        <div class="input-group">
                            <input class="form-control fs-12" (click)="basicDP.toggle()" name="dob" style="border-color:black"
                                placeholder="Select Date of Birth" #dobRef="ngModel" [maxDate]="dobMaxDate" [(ngModel)]="activateINRWalletRequest.dob" ngbDatepicker
                                #basicDP="ngbDatepicker" required [minDate]="{year: 1900, month: 1, day: 1}"
                                >
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                    (click)="basicDP.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    <!-- <input required type="password" class="form-control fs-12" placeholder="Account Number" [(ngModel)]="addBankAccountRequest.number" name="account_no" #accountNoRef="ngModel" [class.error]="ActivateINRWallet.submitted && accountNoRef.invalid" required /> -->
                    <span *ngIf="ActivateINRWallet.submitted && dobRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="dobRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            
                        <label class="form-label"><b>Select Profession*</b></label>
                        <select id="select_occupation" name="occupation" class="form-control fs-12" style="border-color:black"
                            [(ngModel)]="activateINRWalletRequest.profession" #professionRef="ngModel" required>
                            <option *ngFor="let singleItem of occupationListItems.keys()" [ngValue]="singleItem">
                                {{occupationListItems.get(singleItem)}}
                            </option>
                        </select>
                        <span *ngIf="ActivateINRWallet.submitted && professionRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="professionRef.errors.required">This field is
                                required!</small>
                        </span>
                </div>
            </div>
          <div class="row " *ngIf="activateINRWalletRequest.profession=='SELF_EMPLOYED'">
                    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label class="form-label"><b>Select Self Employed Profession*</b></label>
                        <select id="select_seflprofession" name="selfoccupation" class="form-control fs-12" style="border-color:black"
                            [(ngModel)]="activateINRWalletRequest.self_employed_profession" #selfprofessionRef="ngModel" required="activateINRWalletRequest.profession=='SELF_EMPLOYED'">
                            <option *ngFor="let singleItem of selfEmployedListItems.keys()" [ngValue]="singleItem">
                                {{selfEmployedListItems.get(singleItem)}}
                            </option>
                        </select>
                        <span *ngIf="ActivateINRWallet.submitted && selfprofessionRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="selfprofessionRef.errors.required">This field is
                                required!</small>
                        </span>
                    </div>
          </div>
        <div class="row " *ngIf="activateINRWalletRequest.profession=='BUSINESS'">
            <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <label class="form-label"><b>Select Nature of Business*</b></label>
                <select id="select_nature_of_business" name="natureOfBusiness" class="form-control fs-12" style="border-color:black"
                    [(ngModel)]="activateINRWalletRequest.nature_of_business" #natueOfBusinessRef="ngModel"
                    required="activateINRWalletRequest.profession=='BUSINESS'">
                    <option *ngFor="let singleItem of natureOfBusinessItems.keys()" [ngValue]="singleItem">
                        {{natureOfBusinessItems.get(singleItem)}}
                    </option>
                </select>
                <span *ngIf="ActivateINRWallet.submitted && natueOfBusinessRef.invalid" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="natueOfBusinessRef.errors.required">This field is
                        required!</small>
                </span>
            </div>
        </div>

            <div class="row ">

                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label class="form-label"><b>Select Education*</b></label>
                    <select id="select_education" name="education" class="form-control fs-12" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.education_qualification" #educationRef="ngModel" required>
                        <option *ngFor="let singleItem of educationListItems.keys()" [ngValue]="singleItem">
                            {{educationListItems.get(singleItem)}}
                        </option>
                    </select>
                    <span *ngIf="ActivateINRWallet.submitted && educationRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="educationRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>Address*</b></label>
                    <input type="text" class="form-control fs-12" name="address" placeholder="Address" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.address" #addressRef="ngModel"
                         required />
                    <span *ngIf="ActivateINRWallet.submitted && addressRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="addressRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
            </div>
            <div class="row ">
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>Pin Code*</b></label>
                    <input type="number " id="pincode " class="form-control fs-12 " style="border-color:black" placeholder="Pincode* "
                        [(ngModel)]="activateINRWalletRequest.pinCode" #pinCodeRef="ngModel" maxlength="6"
                        oninput="javascript: if (this.value.length>his.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode!== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190"
                        >
                    <span *ngIf="ActivateINRWallet.submitted && pinCodeRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="pinCodeRef.errors.required">This field is
                            required!</small>
                    </span>
            
                </div>
            
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label class="form-label"><b>Source of Fund*</b></label>
                    <select id="source_of_fund" name="fund" class="form-control fs-12" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.source_of_fund" #fundRef="ngModel" required>
                        <option *ngFor="let singleItem of sourceOfFundsItems.keys() " [ngValue]="singleItem">
                            {{sourceOfFundsItems.get(singleItem)}}
                        </option>
                    </select>
                    <span *ngIf="ActivateINRWallet.submitted && fundRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="fundRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
            </div>

            <div class="row ">
            
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label class="form-label"><b>Annual Income*</b></label>
                    <select id="annual_income" name="income" class="form-control fs-12" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.annual_income" #incomeRef="ngModel" required>
                        <option *ngFor="let singleItem of annualIncomeItems.keys()" [ngValue]="singleItem">
                            {{annualIncomeItems.get(singleItem)}}
                        </option>
                    </select>
                    <span *ngIf="ActivateINRWallet.submitted && incomeRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="incomeRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>Aadhaar Number*</b></label>
                    <input type="text" class="form-control fs-12" name="aadhar" placeholder="Aadhar" style="border-color:black"
                        [(ngModel)]="activateINRWalletRequest.idNumber" #aadharRef="ngModel" required />
                    <span *ngIf="ActivateINRWallet.submitted && aadharRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="aadharRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>Mobile Number*</b></label>
                    <input  id="mobile " class="form-control fs-12 "  name="contact" style="border-color:black" placeholder="Mobile Number* "
                        [(ngModel)]="activateINRWalletRequest.contactNo" #mobileNoRef="ngModel" maxlength="10"
                        required disabled >
                        <a class="send-otp text-right ng-star-inserted float-right" (click)="getOTP(activateINRWalletRequest.contactNo)">Send
                            OTP</a>
                    <span *ngIf="ActivateINRWallet.submitted && mobileNoRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="mobileNoRef.errors.required">This field is
                            required!</small>
                    </span>
                
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label for="country"><b>OTP*</b></label>
                    <input type="number " id="otp" class="form-control fs-12 " style="border-color:black"
                        placeholder="OTP " [(ngModel)]="activateINRWalletRequest.otp" #otpRef="ngModel"
                        maxlength="6"
                        oninput="javascript: if (this.value.length>his.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode!== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190">
                  

                    <span *ngIf="ActivateINRWallet.submitted && otpRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="otpRef.errors.required">This field is
                            required!</small>
                    </span>
                
                </div>

            </div>

            <div class="row">
                <div class="col-md-3 col-3">
                    <button type="button" rippleEffect class="btn btn-primary btn-prev mr-1 fs-12"
                        (click)="backClicked()">Back</button>
                </div>
                <div class="col-md-9 col-9  mb-20 text-right">
                    <button type="submit" rippleEffect class="btn btn-primary mr-1 fs-12">Proceed</button>
                    <!-- <button type="reset" rippleEffect class="btn btn-outline-secondary fs-12">Reset</button> -->
                </div>
            </div>
        </form>
    </div>
</div>