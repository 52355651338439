import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { RequestLoginModel } from "app/modules/onboarding/models/login.models";
import { LoginService } from "app/modules/onboarding/services/login.service";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Component({
  selector: 'direct-login',
  templateUrl: './direct-login.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class DirectLoginComponent implements OnInit {
  // public base64EncodedString = "eyJpZCI6Ijk0MjA3OTYwNzciLCJvdHAiOiI5ODY1MzIiLCJ0eXBlIjoiTU9CSUxFIiwicmVxdWlyZWRDdXJyZW5jeUNvZGUiOiJVU0QiLCJyZXF1aXJlZEN1cnJlbmN5Q29kZUFtb3VudCI6IjEwMDAifQ==";
  public base64EncodedString: any;
  private _unsubscribeAll: Subject<any>;
  public showLoader: boolean = true;
  public requestLoginModel = new RequestLoginModel();
  public base64Decode: any;

  constructor(
    private _coreConfigService: CoreConfigService,
    private loginService: LoginService,
    public router: Router,
    public toast: ToastrService,
    public route: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit() {
    this.showLoader = true;
    this.route.params.subscribe((params: Params) => {
      this.base64EncodedString = params['data'];

      this.base64Decode = JSON.parse(atob(this.base64EncodedString));
      this.loginWithOTP();
    });
  }

  loginWithOTP() {
    this.showLoader = true;
    this.requestLoginModel.type = "MOBILE";
    this.requestLoginModel.id = this.base64Decode.id;
    this.requestLoginModel.otp = this.base64Decode.otp;

    this.loginService.loginWithOtp(this.requestLoginModel).subscribe(resp => {
      this.showLoader = true;
      var respBody = JSON.parse(JSON.stringify(resp.body));
      localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
      localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
      localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
      localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

      this.router.navigate(['/home']);
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
}
