export class AddAddressModel {
    consumerId: string;
    address: string;
    postalCode: number;
    city: string;
    locationType: string;
    default: true;
    type: string;
    addressLabel: string;
    isDefault: true;
    state: string;
    country: string;
}