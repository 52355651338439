
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { LoaderComponent } from './loader/loader.component';
 
const routes = [
  
  
];

@NgModule({
    declarations: [ 
        LoaderComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
    ],
    exports: [ 
        LoaderComponent
    ]
})
export class LoaderRoutingModule {}
