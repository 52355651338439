import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ISICRegisterModel } from 'app/modules/isic-card/models/isic-card.model';
import { ISICCardService } from 'app/modules/isic-card/services/isic-card.service';
import { DocumentDetails } from 'app/modules/relationship/view/model/document-detail.model';
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'open-account-form',
  templateUrl: './open-account-form.component.html',
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class OpenAccountFormComponent implements OnInit {
  public pageTitle: any;
  public showLoader: boolean = false;
  public countryListItems: any;
  public accountRegisterModel = new ISICRegisterModel();
  public dateofBirth: NgbDateStruct;
  public passportFrontUrl: any;
  public passportBackUrl: any;
  public visaUrl: any;
  public panCardUrl: any;
  public i20Url: any;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();
  public localArrayForList = new Array();
  public documentDetails = new DocumentDetails();
  public showCheckedMarkForPassportFront: boolean = false;
  public showCheckedMarkForPassportBack: any;
  public showCheckedMarkForPANCard: any;
  public showCheckedMarkForVisa: any;
  public showCheckedMarkForI20: any;
  public accountRequestId: any;
  public dobMaxDate: any;

  constructor(
    public masterDataService: MasterDataService,
    public route: ActivatedRoute,
    public toast: ToastrService,
    public isicCardService: ISICCardService,
    public router: Router
  ) {
    this.route.params.subscribe((params: Params) => {
      this.pageTitle = params['title'];
      if (this.pageTitle == 'GIC') {
        this.accountRegisterModel.visitingCountry = 'CA'; //Canada
      } else if (this.pageTitle == 'Blocked') {
        this.accountRegisterModel.visitingCountry = 'DE'; // Germany
      }
    });
  }

  ngOnInit() {
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.getCountryList();
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    }, error => {
    });
  }

  submitForm() {
    if (this.setFormValidation()) {
      if (this.dateofBirth) {
        this.accountRegisterModel.dob = DateUtils.getNgbDateStructToDate(this.dateofBirth);
        this.accountRegisterModel.nationality = 'INDIAN';

        if (this.pageTitle == 'Zolve') {
          this.accountRegisterModel.formType = 'ZOLVE';
        } else if (this.pageTitle == 'GIC') {
          this.accountRegisterModel.formType = 'GIC';
        } else {
          this.accountRegisterModel.formType = 'BLOCK_ACCOUNT';
        }

        this.isicCardService.isicRegister(this.accountRegisterModel).subscribe((async resp => {
          this.showLoader = false;
          if (this.pageTitle == 'Zolve') {
            console.log(resp.body);
            var response = resp.body;
            this.accountRequestId = response["id"];
            // await this.uploadDocuments();
          }
          this.toast.success("Application submitted successfully !");
          if (this.pageTitle == 'Zolve') {
            this.router.navigate(['/zolve-credit-card']);
          } else if (this.pageTitle == 'GIC') {
            this.router.navigate(['/open-gic-account']);
          } else {
            this.router.navigate(['/open-blocked-account']);
          }

        }), error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else {
        this.toast.error('Please select date of birth!');
      }
    }
  }

  fileUploadLocalDocument(event, documentType) {
    let docType;
    docType = documentType;
    let fileList: FileList = event.target.files;

    if (documentType === 'PASSPORT_PHOTO_PAGE') {
      docType = 'PASSPORT_PHOTO_PAGE';
    } else if (documentType === 'PASSPORT_ADDRESS_PAGE') {
      docType = 'PASSPORT_ADDRESS_PAGE';
    } else if (documentType === 'PAN_CARD') {
      docType = 'PANCARD';
    } else if (documentType === 'VISA') {
      docType = 'VISA';
    } else {
      docType = 'I_20';
    }


    if (fileList.length > 0) {
      if (documentType == 'PASSPORT_PHOTO_PAGE') {
        this.showCheckedMarkForPassportFront = true;
        this.documentDetails = new DocumentDetails();
        this.passportFrontUrl = fileList[0];
        this.documentDetails.file = this.passportFrontUrl;
        this.documentDetails.documentType = docType;
        this.localArrayForList.push(this.documentDetails);
      } else if (documentType == 'PASSPORT_ADDRESS_PAGE') {
        this.showCheckedMarkForPassportBack = true;
        this.passportBackUrl = fileList[0];
        this.documentDetails.file = this.passportBackUrl;
        this.documentDetails.documentType = docType;
        this.localArrayForList.push(this.documentDetails);
      } else if (documentType == 'PAN_CARD') {
        this.panCardUrl = fileList[0];
        this.showCheckedMarkForPANCard = true;
        this.documentDetails.file = this.panCardUrl;
        this.documentDetails.documentType = docType;
        this.localArrayForList.push(this.documentDetails);
      } else if (documentType == 'VISA') {
        this.visaUrl = fileList[0];
        this.showCheckedMarkForVisa = true;
        this.documentDetails.file = this.visaUrl;
        this.documentDetails.documentType = docType;
        this.localArrayForList.push(this.documentDetails);
      } else {
        this.i20Url = fileList[0];
        this.showCheckedMarkForI20 = true;
        this.documentDetails.file = this.i20Url;
        this.documentDetails.documentType = docType;
        this.localArrayForList.push(this.documentDetails);
      }
    }
  }

  downloadUploadedDoc(docType) {
    let file: any;
    if (docType == 'PASSPORT_ADDRESS_PAGE') {
      file = this.passportBackUrl;
    } else if (docType == 'PASSPORT_PHOTO_PAGE') {
      file = this.passportFrontUrl;
    } else if (docType == 'PAN_CARD') {
      file = this.panCardUrl;
    } else if (docType == 'VISA') {
      file = this.visaUrl;
    } else {
      file = this.i20Url;
    }
    this.viewLocalFileUtils.viewLocalDocument(file);
  }


  uploadDocuments() {
    for (let i = 0; i < this.localArrayForList.length; i++) {
      this.showLoader = true;
      let file: File = this.localArrayForList[i].file;
      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('isicRequestId', this.accountRequestId);
        formData.append('documentType', this.localArrayForList[i].documentType);

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.isicCardService.isicDocumentUpload(formData).subscribe((respDoc) => {
            this.showLoader = false;

            var localDocList = JSON.parse(JSON.stringify(respDoc.body));
            localDocList.forEach((localDocListItems) => {
              if (localDocListItems.type == 'PASSPORT_PHOTO_PAGE') {
                this.passportFrontUrl = localDocListItems.location;
              } else if (localDocListItems.type == 'PASSPORT_ADDRESS_PAGE') {
                this.passportBackUrl = localDocListItems.location;
              } else if (localDocListItems.type == 'VISA') {
                this.visaUrl = localDocListItems.location;
              } else if (localDocListItems.type == 'PAN_CARD') {
                this.panCardUrl = localDocListItems.location;
              } else {
                this.i20Url = localDocListItems.location;
              }
            });
            this.toast.success('Document uploaded successfully!');
          }, (error) => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
        this.showLoader = false;
      }
    }
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }


  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  validatePassportNumber(passportNo) {
    const regex = /^[A-PR-WY-Z][1-9]\d\s?\d{4}[0-9]$/;
    if (!regex.test(passportNo)) {
      return false;
    } else {
      return true;
    }
  }

  restrictSepcialCharacters(event) {
    var charCode = event.keyCode;
    if ((charCode > 96 && charCode < 123) || (charCode > 64 && charCode < 91) || (charCode >= 48 && charCode <= 57) || charCode <= 31)
      return true;
    else return false;
  }


  validateEmailId(emailId) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(emailId)) {
      return false;
    } else {
      return true;
    }
  }

  validatePANNo(panNo) {
    const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    if (!regex.test(panNo)) {
      return false;
    } else {
      return true;
    }
  }

  setFormValidation() {
    if (!this.accountRegisterModel.fullName) {
      this.toast.error("Please enter fullname!");
      return false;
    }

    if (!this.validateEmailId(this.accountRegisterModel.email)) {
      this.toast.error("Email id is not valid!");
      return false;
    }

    if (!this.accountRegisterModel.gender) {
      this.toast.error("Please select gender!");
      return false;
    }

    if (!this.accountRegisterModel.address) {
      this.toast.error("Please enter address!");
      return false;
    }

    if (!this.accountRegisterModel.mobile) {
      this.toast.error("Please enter mobile no!");
      return false;
    }

    if (this.pageTitle == 'Zolve'){
      if(!this.accountRegisterModel.visitingCountry){
        this.toast.error("Please select country!");
        return false;
      }

      if(!this.showCheckedMarkForPassportFront){
        this.toast.error("Please upload passport photo page!");
        return false;
      }

      if(!this.showCheckedMarkForPassportBack){
        this.toast.error("Please upload passport address page!");
        return false;
      }

      if(!this.showCheckedMarkForVisa){
        this.toast.error("Please upload visa document!");
        return false;
      }

      if(!this.showCheckedMarkForPANCard){
        this.toast.error("Please upload PAN card!");
        return false;
      }

      if(!this.showCheckedMarkForI20){
        this.toast.error("Please upload I20 Document!");
        return false;
      }
    }

    if (this.pageTitle == 'GIC' || this.pageTitle == 'Blocked') {
      if (!this.validatePassportNumber(this.accountRegisterModel.passportNumber)) {
        this.toast.error("Please enter valid passport no!");
        return false;
      }

      if (!this.validatePANNo(this.accountRegisterModel.panNumber)) {
        this.toast.error("Please enter valid PAN no!");
        return false;
      }

      // if (!this.accountRegisterModel.nationality) {
      //   this.toast.error("Please enter nationality!");
      //   return false;
      // }
    }

    return true;
  }


}
