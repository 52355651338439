import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class AddressService extends BaseProvider {

    getAddressList(){
        var resourceUrl = "consumers/address";
        return this.makeGetCall(resourceUrl);
    }

    createConsumerAddress(requestModel){
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = "consumers/" + consumerId +   "/address";
        return this.makePostCall(resourceUrl, requestModel);
    }

    updateConsumerAddress(requestModel, id){
        var resourceUrl = "consumers/address/" + id;
        return this.makePatchCall(resourceUrl, requestModel);
    }

    
    deleteConsumerAddress(addressID) {
        var resourceUrl = 'consumers/address/' + addressID;
        return this.makeDeleteCall(resourceUrl);
    }
}