import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { ToastrService } from 'ngx-toastr';
import { UpdatePaymentDetailsRequestModel } from '../../models/order.model';
import { OrderService } from '../../services/order.service';
import  moment from 'moment';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { RequestEmailLinkDataModel } from 'app/modules/email-link/models/email-link.model';
import { RemittanceService } from 'app/modules/email-link/services/email.service';
import { OrderStatusModificationRequest } from 'app/modules/email-link/models/cart.model';

@Component({
  selector: 'quote-order-details',
  templateUrl: './quote-order-details.component.html',
  styleUrls: [
    './quote-order-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuotesOrderDetailsComponent implements OnInit {
  public orderRating: any;
  public orderID: any;
  public date: any;
  public time: any;
  public orderDetails: any;
  public showLoader: boolean = true;
  public paymentItemList = Constants.paymentItemList;
  public requestEmailLinkDataModel = new RequestEmailLinkDataModel();
  public modalReference: any;
  public orderStatusModificationRequest = new OrderStatusModificationRequest();
  public documentUrl: any;
  public documentTitle: any;
  public startDate: NgbDateStruct;
  public readOnlyRating: boolean = true;
  public manualPaymentType: any;
  public selectedPurposeItem: any;
  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;
  public showModal : boolean = false;

  public paymentDate: any;
  public transactionPaymentDate : any;

  public updatePaymentDetailsRequestModel = new UpdatePaymentDetailsRequestModel();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public modalRef: any;
  public readOnlyAmount: boolean = false;
  public transactionDetails: any;
  public showCheckedMark: boolean = false;
  public status : any;
  public showUploadPaymentProof: boolean = false;
  public showUploadPaymentProofRightSection: boolean = false;
  public showTransactionPaymentForm: boolean = false;
  public transactionPaymentStatus: boolean = false;
  public consumerId :any;
  public transactionDetailsMaxDate : any;
  public transactionID :any;
  public showCheckMarkForPaymentProof : boolean = false;
  public hideBeneficiaryDetails  : boolean = false;
  public  moment: any = moment;
  public goToEmailFlow: boolean = true;

  constructor(
    public orderService: OrderService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public _router: Router,
    public toast: ToastrService,
    public remittanceService: RemittanceService,
  ) {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.orderID = this.route.snapshot.paramMap.get('orderID');
    this.getSingleOrderDetails();
  }

  ngOnInit(): void {
   }


  getSingleOrderDetails() {
    this.showLoader = true;
    this.orderService.getOrderDetailsSingle(this.orderID).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
      if(this.orderDetails?.orderType?.includes('CARD')){
        this.hideBeneficiaryDetails = true;
      }

      if (this.orderDetails.orderFeedback.averageRating) {
        this.orderRating = this.orderDetails.orderFeedback.averageRating;
      } else {
        if (this.orderDetails.orderFeedback.onboarding > 0) {
          this.orderRating = (this.orderDetails.orderFeedback.onboarding +
            this.orderDetails.orderFeedback.transaction +
            this.orderDetails.orderFeedback.transparency +
            this.orderDetails.orderFeedback.support +
            this.orderDetails.orderFeedback.overall) / 5;
        } else{
          this.readOnlyRating = false;
        }
      }

      this.updatePaymentDetailsRequestModel.amount = this.orderDetails?.invoice?.totalBillAmountString;
      if (this.updatePaymentDetailsRequestModel.amount != null || this.updatePaymentDetailsRequestModel.amount != "") {
        this.readOnlyAmount = true;
      }
      // if (this.orderRating == -1 || this.orderRating == 0) {
      //   this.readOnlyRating = false;
      // }
      if (this.orderDetails.orderStatusGroup.consumerPayment == 'COMPLETED') {
        this.showUploadPaymentProof = false;
        this.showTransactionPaymentForm = false;
      } else if (this.orderDetails.paymentMode != 'TPV' && this.orderDetails.orderStatusGroup.consumerPayment != 'COMPLETED') {
        this.showUploadPaymentProof = true;
        // this.showTransactionPaymentForm = true;
      }
    },
      error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
  }

  msgConvey() {
    this.modalRef.close();
    this.toast.success("As your wish...!!!")
  }

  gotoEmailFlow(respBody) {
    if(this.goToEmailFlow) {
    this.modalRef.close();
    this.orderService.getOrderDetailsSingle(this.orderID).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
      console.log("I am here")
      console.log("orderdetails++", this.orderDetails);
    this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(this.orderDetails.orderItems[0].purpose);
        console.log("this.selectedPurposeItem >>", this.selectedPurposeItem);
        //   /// prepare quotation request
        
        let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
        this.quotationCurrencyItem.currencyCode = this.orderDetails.orderItems[0].currencyCode;
        this.quotationCurrencyItem.currencyAmount = this.orderDetails.orderItems[0].currencyAmount;
        this.quotationCurrencyItem.bidRate = this.orderDetails.orderItems[0].bidRate;
        this.quotationCurrencyItem.product = Constants.productsEnum[2];
        this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
          ? this.selectedPurposeItem.purposeCode
          : "UNIVERSITY_FEES";

        listQuotationCurrencies.push(this.quotationCurrencyItem);

        this.requestQuotationModel.sellType = Constants.sellTypes[0];
        this.requestQuotationModel.partnerId = Constants.partnerId;
        this.requestQuotationModel.nostroChargeType = 'REMITTER';
        this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;

        /// store active order in session storage
        this.stepperDataModel.purposeItem = this.selectedPurposeItem;
        this.stepperDataModel.quotationRequest = this.requestQuotationModel;
        this.stepperDataModel.orderDetails = resp.body;
        console.log("stepper data +++", this.stepperDataModel.orderDetails);

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
        this.showLoader = false;

        console.log("respBody.consumer.vcipKycStatus >>", respBody);

        if (!this.orderDetails.consumer.vcipKycStatus) {
          this._router.navigate(['/update-profile']);
        } else if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
          this._router.navigate(['/emailStepper3step']);
        } else {
          this._router.navigate(['/emailStepper'])
        }
      }, error => {
        this.showLoader = false;
        console.log(error);
      });  
    }  
  }

  openModalForQuotes(quotesModal) {
    this.showModal = true;
    this.showLoader = true;
    this.modalRef = this.modalService.open(quotesModal, { centered: true });
    this.status = 'ON_HOLD';
    this.orderStatusModificationRequest.status = this.status;
    this.remittanceService.changeOrderStatus(this.orderID, this.orderStatusModificationRequest).subscribe(resp => {
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
      this.goToEmailFlow = false;
    });
  }

  cancelQuotation() {
    this.showModal = true;
    this.showLoader = true;
    this.status = 'CANCELLED';
    this.orderStatusModificationRequest.status = this.status;
    this.remittanceService.changeOrderStatus(this.orderID, this.orderStatusModificationRequest).subscribe(resp => {
      this.showLoader = false;
      this.toast.success("As your wish...!!!")
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
}
