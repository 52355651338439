import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import Stepper from 'bs-stepper';

@Component({
  selector: 'card-stepper-footer-email',
  templateUrl: './card-stepper-footer-email.component.html',
  styleUrls: ['../../../../remittance/stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailCardStepperFooterComponent implements OnInit {

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;

  public progressWidth: any;
  public progressWidthInPercentage: number = 0;

  constructor() { }

  ngOnInit() {
    this.timer();
    this.getSessionData();
  }

  ngOnDestroy() { 
    // clearInterval();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
    }
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }
}
