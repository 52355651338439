<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section *ngIf="!showAddBankForm">
            <div class="row">
                <div class="col-md-9 mb-2 mb-md-0">
                    <div class="d-flex align-items-center">
                        <div class="avatar avatar-tag bg-light-primary mr-1">
                            <i data-feather="credit-card" class="font-medium-4"></i>
                        </div>
                        <div>
                            <h5 class="mb-0"> Cart Payment </h5>
                            <span> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-20">
                <div class="col-md-7">
                    <div class="card-body grey-box mb-20">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="fw-600 fs-12">{{plans?.cart[0].companyName}}</div>
                                <div class="fs-10 mt-5">{{plans?.cart[0].planName}} </div>
                                <div class="mt-20">
                                    <div class="custom-control custom-control-primary custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="colorCheck1" checked disabled />
                                        <!-- <label class="custom-control-label fs-11" for="colorCheck1">I agree to the  <a [href]="tncUrl" target="_blank"  class="blue-link">Terms and Conditions</a> </label> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Sum Assured" class="form-control fs-12" value={{plans?.cart[0].sumInsuranceAmount}}>
                            </div>
                            <div class="col-md-3 text-right">
                                <div class="blue-link fs-18 pl-0 fw-600 mt-5 float-right"> ₹ {{plans?.cart[0].premiumAmount}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-5">

                    <section class="invoice-preview-wrapper">
                        <div class="card-body grey-box  invoice-preview">
                            <div class="row invoice-sales-total-wrapper">
                                <div class="col-md-12  order-md-2 order-1">
                                    <div class="invoice-total-wrapper">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-14">Amount </p>
                                            <p class="invoice-total-amount text-black fw-600 fs-14">₹ {{plans?.totalPremiumAmount}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-500 fs-11">Service Charges </p>
                                            <p class="invoice-total-amount fs-11  text-black fw-500">₹ {{plans?.serviceCharges}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-500 fs-11">CGST </p>
                                            <p class="invoice-total-amount fs-11  text-black fw-500">₹ {{plans?.cgst}}</p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-500 fs-11">GST </p>
                                            <p class="invoice-total-amount fs-11  text-black fw-500">₹ {{plans?.sgst}} </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title fs-14 text-black fw-600">Total</p>
                                            <p class="invoice-total-amount fs-14 text-black fw-600">₹ {{plans?.totalPayableAmount}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="row mt-30 mb-10">
                            <div class="col-md-12">
                                <div class="fs-13 text-black bold-text">
                                    Payment Method
                                </div>
                            </div>
                        </div>
                        <div class="row mb-30">
                            <div class="col-md-6">
                                <div class="payment-card">
                                    <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" /> Net Banking/UPI
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="payment-card">
                                    <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" /> NEFT/RTGS/IMPS
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="mt-20">
                        <div class="row">
                            <div class="col-md-8 col-6">
                                <div class="fs-13 text-black bold-text">
                                    Choose Your Bank Account
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="button" class="btn btn-outline-secondary blue-link fw-600 fs-11 w-100" rippleEffect (click)="showAddBankForm = true">
                                    <i class="fa fa-plus"></i> Add Bank Account
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12" *ngFor="let singleBank of bankAccountList">
                                <div class="row mb-10">
                                    <div class="col-md-1 col-2">
                                        <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                    </div>
                                    <div class="col-md-11 col-10">
                                        <div class="fs-13 text-black fw-600">
                                            {{singleBank.nameAsPerAccountNumber}}
                                        </div>
                                        <div class="fs-11 "> IFSC Code : {{singleBank?.ifscCode}}</div>
                                        <div class="fs-11"> Bank Name : {{singleBank?.bankName}}</div>
                                        <div class="fs-11 "> Branch Name : {{singleBank?.branch}}</div>
                                        <div class="fs-11"> Account No : {{singleBank?.number}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 mt-20">
                                <div class="fs-8 text-danger">{{paymentMessage1}}</div>
                                <div class="fs-8 text-danger">{{paymentMessage2}}</div>
                                <div class="fs-8 text-danger">{{paymentMessage3}}</div>
                            </div>
                            <div class="col-md-4 mt-20">
                                <button type="button" class="btn btn-primary w-100 fs-11" rippleEffect (click)="proceedForPayment()">
                                    Pay Now
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </section>

        <div class="row" *ngIf="showAddBankForm">
            <div class="col-md-12">
                <add-bank-account-for-remittance (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                </add-bank-account-for-remittance>
            </div>
        </div>
    </div>
</div>