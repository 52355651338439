import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { OpenBlockedAccountComponent } from './views/open-blocked-account.component';

const routes = [
  {
    path: 'open-blocked-account',
    component: OpenBlockedAccountComponent,
    data: { animation: 'OpenBlockedAccountComponent' },
    canActivate: [AuthGuard]
  } 
];

@NgModule({
  declarations: [
    OpenBlockedAccountComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    LoaderRoutingModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [
    OpenBlockedAccountComponent
  ],
  providers: [
  
  ]
})
export class OpenBlockedAccountRoutingModule { }
