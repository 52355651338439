export class ReqGetCurrencyMaster {
    cartType: string;
    countryCode: string;
    issuer: string;
    partnerId: string;
    product: string;
}

export class SinglePurposeItem {
    key?: string;
    icon?: string;
    title: string;
    purposeCode: string;
    remittanceType: string;
}

export class SingleRateResItem {
    sellType: string;
    currencyCode: string;
    cardRateInINR: string;
    minimumRateInINR: string;
    currencyName: string;
}