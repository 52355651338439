<div class="row mt-20 mb-20">
    <div class="col-md-12">
        <img src="assets/images/illustration/a2form.png" class="mb-30 image-self-declaration" style="height:120px !important;">
    </div>
</div>

<div class="row">
    <div class="col-md-12 fs-13 text-black fw-600 mb-20 text-center">Please proceed for Self Declaration</div>
</div>

<div class=" mt-20 mb-30">
    <div class="row">
        <div class="col-md-12">
            <div class="custom-control custom-checkbox mb-20">
                <input type="checkbox" class="custom-control-input" id="declaration" [(ngModel)]="isChecked" (change)="checkCheckBoxvalue()" />
                <label class="custom-control-label text-black fs-12" for="declaration">
                    I hereby acknowledge that I have read, fully understood and confirm via OTP in acceptance of the
                    <a class="link" target="_blank" href="https://b2capptnc.s3.ap-south-1.amazonaws.com/ForexCardT%26C.pdf"> <span class="blue-text"> terms and conditions, </span> </a> 
                    <a class="link"  href="https://s3.ap-south-1.amazonaws.com/faqs.wsfl.in/card_reload_form.pdf"
                                                target="_blank"  
                                                *ngIf="cartType == 'CARD_RELOAD'">
                                                <span class="blue-text"> Reload Form </span> </a>

                    <a class="link"  href="https://s3.ap-south-1.amazonaws.com/faqs.wsfl.in/card_application_form.pdf"
                    target="_blank"  
                    *ngIf="cartType == 'CARD_PURCHASE'">
                    <span class="blue-text">Application Form </span></a>
                    cum declaration, 
                    
                    <a class="link" (click)="getA2FormLink()" >
                        <span class="blue-text">Form A2 </span></a>
                    
                    cum declaration and my KYC
                    documents.
                </label>
            </div>
        </div>

        <div class="col-md-4">
            <label for="basicInputFile">OTP</label>

            <input type="text" class="form-control fs-11" [(ngModel)]="otpValue" (change)="updateSessionData()" placeholder="OTP for Acceptance*" />
            <small class="form-text text-right fs-11 blue-text" style="cursor: pointer;" (click)="sendA2FormAcknowledge()">Resend OTP</small>
        </div>

        <div [ngClass]="{'col-lg-3 col-md-3 col-sm-3 col-xs-12 col-9 ':showImageUploadedIcon, 'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12':!showImageUploadedIcon}">
            <div class="form-group">
                <label for="basicInputFile">Upload signature</label>
                <div class="custom-file">
                    <input type="file" (change)="uploadSignature($event)" class="form-control fs-12" accept=".jpeg,.jpg,.png" id="customFile-papersign" placeholder="Upload signature *" />
                    <label class="custom-file-label" for="customFile-papersign">
                        Choose file
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-3  mt-23" *ngIf="showImageUploadedIcon">
            <div class="input-group-append" style="height: 38px;">
                <!-- <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                        style="width:20px;width:20px"
                        ></span> -->
                <span class="input-group-text "><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
            </div>
        </div>


        <div class="col-md-12 text-black fs-12 mt-10">
            *Please note that the A2 Form will be sent to you registered email ID. You will be required to sign the A2 Form and upload it or have it emailed to us at customersupport@wsfx.in for the transaction to be processed successfully. The physical copy of the
            A2 Form will be subsequently collected by our service executive.
        </div>
    </div>
</div>

<card-stepper-footer></card-stepper-footer>