<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <form class="form-horizontal" #DueDiligenceForm="ngForm">
            <div class="row mt-15">
                <div class="form-group col-md-8 mt-10">
                    <label class="form-label">
                        Occupation and Length of residence of the beneficiary in the foreign country and the profile *
                    </label>
                    <input type="text" class="form-control fs-12" name="occupationLengthResidenceForeign" [(ngModel)]="addOfacRequestModel.occupationLengthResidenceForeign" placeholder="Enter Occupation and Length of residence of the beneficiary in the foreign country and the profile *"
                    />
                </div>
                <div class="form-group col-md-8 mt-10">
                    <label class="form-label" for="country">Sources of funds used for doing the transaction *</label>
                    <input type="text" class="form-control fs-12" name="sourceOfFund" [(ngModel)]="addOfacRequestModel.sourceOfFund" placeholder="Sources of funds used for doing the transaction *" />
                </div>

                <div class="form-group col-md-8 mt-10">
                    <label class="form-label">Length of residence in foreign country with specific occupation * </label>
                    <input type="text" class="form-control fs-12" name="lengthResidenceForeign" [(ngModel)]="addOfacRequestModel.lengthResidenceForeign" placeholder="Length of residence in foreign country with specific occupation *" />
                </div>

                <div class="form-group col-md-8 mt-10">
                    <label class="form-label">Occupation and Nature of business in India of customer * </label>
                    <input type="text" class="form-control fs-12" name="natureBusinessInIndia" [(ngModel)]="addOfacRequestModel.natureBusinessInIndia" placeholder="Occupation and Nature of business in India of customer *" />
                </div>

                <div class="form-group col-md-8 mt-10">
                    <label class="form-label">Company profile (If occupation is Business)</label>
                    <input type="text" class="form-control fs-12" name="companyProfile" [(ngModel)]="addOfacRequestModel.companyProfile" placeholder="Company profile (If occupation is Business)" />
                </div>

                <div class="form-group col-md-8 mt-10">
                    <label class="form-label">Transaction history with this country, provide detail * </label>
                    <input type="text" class="form-control fs-12" name="transactionHistory" [(ngModel)]="addOfacRequestModel.transactionHistory" placeholder="Transaction history with this country ,provide detail*  " />
                </div>

                <div class="form-group col-md-8 mt-10">
                    <div class="mb-20">
                        <div class="fs-12 text-black fw-600">Are you Frequent Flyer of this country if Yes please provide</div>
                    </div>
                    <input type="radio" name="radios" id="radio" value="true" [(ngModel)]="addOfacRequestModel.isFrequentFlyerCountry">
                    <span class="fs-12"> &nbsp;&nbsp; Yes &nbsp;&nbsp;</span>
                    <input type="radio" name="radios" id="radio" value="false" [(ngModel)]="addOfacRequestModel.isFrequentFlyerCountry" />
                    <span class="fs-12"> &nbsp;&nbsp;&nbsp;&nbsp; No</span>
                </div>
                <div class="form-group col-md-8 mt-10">
                    <button type="submit" rippleEffect class="btn btn-primary btn-gradient fs-12 " (click)="addOfacFatfData()">Submit</button>
                </div>

                <!-- <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxFlex="100" class="isFrequentFlyerCountryText">
                    <div>Are you Frequent Flyer of this country if Yes please provide</div>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxFlex="100" class="isFrequentFlyerCountryText">
                    <mat-radio-group [(ngModel)]="addOfacRequestModel.isFrequentFlyerCountry" formControlName="isFrequentFlyerCountry">
                        <mat-radio-button value="true" style="font-size: 13px;margin-right: 20px;">
                            Yes</mat-radio-button>
                        <mat-radio-button value="false" style="font-size: 13px;" checked>No
                        </mat-radio-button>
                    </mat-radio-group>
                </div> -->

            </div>
        </form>
    </div>
</div>