import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
  })
  export class LoaderComponent implements OnInit {

    ngOnInit(): void {
       
    }

    constructor(){
        
    }
}