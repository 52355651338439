import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClaimedRewardModel } from '../../model/offer-rewards.model';
 
import { OfferRewardsService } from '../../services/offer-rewards.service';

@Component({
  selector: 'redeem-voucher',
  templateUrl: './redeem-voucher.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
    './redeem-voucher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RedeemVoucherComponent implements OnInit {

  constructor(
    public offerRewardsService: OfferRewardsService,
    public router: Router) { }

    id: any;
    fullName: any;
    email: any;
    profileURL: string;
    consumerDetailsList: any;
    claimDetailsList: any;
    unlock = false;
    consumerId: string;
    unlockedDetailsList: any;
    availablerewards = new Array<any>();
    claimedrewards = new Array<ClaimedRewardModel>();
  
    
    ngOnInit() {
      this.getConsumerDetails();
      this.getClaimedDetails();
  
    }
  
    back(){
      this.router.navigate(['/referral']);
    }

    getConsumerDetails() {
      this.offerRewardsService.getConsumerCredits().subscribe(result => {
        this.consumerDetailsList = result.body;
        this.availablerewards = this.consumerDetailsList['availableRewards'];
      },
        error => {
        });
    }

    onTabClick(event) {
      this.getConsumerDetails();
      this.getClaimedDetails();
      this.unlockedList();
    }

    getClaimedDetails() {
      this.offerRewardsService.getClaimedrewards().subscribe(result => {
        this.claimDetailsList = result.body;
      },
        error => {
        });
    }

    unlockedList() {
      this.unlock = false;
      this.consumerId = localStorage.getItem('consumerID');
      this.offerRewardsService.getConsumerRewards(this.unlock).subscribe(result => {
        this.unlockedDetailsList = result.body;
      },
        error => {
        });
    }

    claimReward(available) {
      this.id = available.id;
      this.offerRewardsService.claimReward(this.id).subscribe(
        response => {
          Swal.fire('Success', 'Your claim request raised successfully', 'success');
        },
        error => {
  
        }
      )
    }

    
    copyMessage(val: string) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      alert("Code Copied Successfully!!");
  
    }
  
 
}
