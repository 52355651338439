import { SinglePurposeItem } from "app/shared/models/shared-models";
import { OfacFatfCardModel, QuotationRequestModel } from "./card.model";

export class StepperDataCardModel {
    purposeItem: SinglePurposeItem;
    quotationRequest: QuotationRequestModel;
    quotationResponse: any;
    cartType: string;
    cartItemsResponse: any;
    step01Data: TripInfoRequestModel;
    step02Data: Step02DataTraveller;
    step03Data: Step03DataA2Form;
    step04Data: Step04DataPayment;
    ofacCardData: OfacCardData = new OfacCardData();
    kycDocumentUploadStatus: boolean = false;
    isPartial : boolean;
}

export class OfacCardData {
    isOfacRequired: boolean;
    addOfacRequestModel: OfacFatfCardModel;
}
export class Step01Data {
    beneficiary: any;
    beneficairyStatus: any;
}

export class Step02DataTraveller {
    selfResPaxItemSingle: any;
    sourceofFunds: any;
    tcsPaymentSourceDetails: any;
    paymentSourceDetails: any;
    tcsPaymentSource: any;
    payerTravelerRequestModel: PayerTravelerRequestModel;
}

export class Step03DataA2Form {
    checkboxChecked: any;
    otpValue: any;
}

export class Step04DataPayment {
    paymentMode: any;
    bankAccountId: any;
}

export class TripInfoRequestModel {
    departure: string;
    returnDate: string;
    travellingCountryCode: string;
    purpose: string;
    subPurpose: string;
    hideProceedButton: boolean;
}

export class PayerTravelerRequestModel {
    payerName: string;
    payerPan: string;
    remitterType: string;
    paxId: string;
    travellerPaxId: string;
    payerPaxId: string;
    deliveryRequest: AddressRequestRequestModel;
}

export class AddressRequestRequestModel {
    address: string;
    scheduledDate: string;
    scheduledTime: string;
    deliveryStatus: string;
    deliveryAddressType: string;
}