import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddBankAccountComponent } from './view/add-bank-account/add-bank-account.component';
import { ListBankAccoountComponent } from './view/list-bank-account/list-bank-account.component';
import { BankAccountService } from './services/bank-account.service';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
 

const routes = [
  {
    path: 'add-bank-account',
    component: AddBankAccountComponent,
    data: { animation: 'AddBankAccountComponent' },
    canActivate : [AuthGuard]

  },
  {
    path: 'list-bank-account',
    component: ListBankAccoountComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]

  }  
];

@NgModule({
    declarations: [ AddBankAccountComponent, ListBankAccoountComponent
    ],
    imports: [RouterModule.forChild(routes), 
      ContentHeaderModule,
      TranslateModule,
      CoreCommonModule,
      CardSnippetModule,
      FormsModule,
      CoreDirectivesModule,
      NgSelectModule,
      LoaderRoutingModule
    ],
    exports: [ 
      AddBankAccountComponent,
      ListBankAccoountComponent
    ],
    providers: [
      BankAccountService
    ]
})
export class BankRoutingModule {}
