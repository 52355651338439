import { Component, OnInit, ViewEncapsulation, VERSION  } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { DateUtils } from 'app/shared/utility/date-utils';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AddRelationshipModel } from '../../models/relationship.model';
import { RelationshipService } from '../../services/relationship.service';
import { DocumentDetails } from '../model/document-detail.model';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';

@Component({
  selector: 'add-relationship',
  templateUrl: './add-relationship.component.html',
  styleUrls: ['../../../remittance/stepper.scss',
  // '../../../../../assets/scss/select.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddRelationshipComponent implements OnInit {
  public documentDetails = new DocumentDetails();
  public addRelationshipModel = new AddRelationshipModel();
  public contentHeader: object;
  private response: any;
  public relationDropdownItems = Constants.payerList;
  public addressProofItems = Constants.addressProofItems;
  public occupationItems = Constants.occupationListItems;
  public startDate: NgbDateStruct;
  public dob;
  public consumerId: any;
  public selectedAddressProofType: any;
  public showLoader: boolean = false;
  public showCheckedMark: boolean = false;
  public showPassportFields: boolean = false;
  public showCheckedMarkForPassportBack: boolean = false;
  public showFileUploadCartDocument: boolean = false;
  public showCheckedMarkForPANCard: boolean = false;
  public file1: File;
  public file2: File;
  public panFile: File;
  public relationShipID: any;
  public localArrayForList = new Array();
  public passportIssueMinDate: any;
  public passportExpiryMinDate: any;
  public DOBMaxDate: any;
  public PANFileUrl: any;
  public queryParams: any;
  public updateRelationshipDetails: boolean = false;
  public passportBackUrl: any;
  public selectedAddressProofUrl: any;
  public paxID: any;
  public paxesList: any;
  public singlePaxItem: any;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();
  public freezeInputField : boolean = false;
  
  constructor(
    public relationshipService: RelationshipService,
    private activateRoute: ActivatedRoute,
    public toast: ToastrService,
    public router: Router,
    public updateProfileService: UpdateProfileService) {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
  }

  ngOnInit() {
    this.DOBMaxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.passportExpiryMinDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.passportIssueMinDate = { year: new Date().getFullYear() - 10, month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.activateRoute.queryParams.subscribe(params => {
      // this.getPaxList();
      this.queryParams = params;
      this.paxID = this.queryParams.id;
      this.addRelationshipModel.name = this.queryParams.name;
      this.addRelationshipModel.email = this.queryParams.email;
      this.addRelationshipModel.mobile = this.queryParams.mobile;
      this.addRelationshipModel.nationality = this.queryParams.nationality;
      this.addRelationshipModel.occupation = this.queryParams.occupation;
      this.addRelationshipModel.address = this.queryParams.address;
      this.addRelationshipModel.pan = this.queryParams.pan;
      this.freezeInputField = this.queryParams.freezeField;
      this.addRelationshipModel.relationship = this.queryParams.relationship ? this.queryParams.relationship.charAt(0).toUpperCase() + this.queryParams.relationship.substr(1).toLowerCase() : '';
      if (this.queryParams.dob) {
        this.addRelationshipModel.dob = DateUtils.ngbDateStructDateConverter(moment(+this.queryParams.dob).format("YYYY-MM-DD"));
        this.addRelationshipModel.dob = this.setDOBToInput(this.addRelationshipModel.dob);
      }
    });
  } 
  
  getPaxList() {
    this.relationshipService.getPaxesList().subscribe((resp) => {
      this.showLoader = false;
      this.paxesList = resp.body;
      this.paxesList.paxResponses.forEach(element => {
        if (this.paxID == element.id){
          this.singlePaxItem = element;
        }

        let documents = this.singlePaxItem.paxDocuments;
        this.selectedAddressProofUrl = false; 

        documents.forEach(singleDoc => {
          if (singleDoc.type.includes("PASSPORT")) {
            this.showPassportFields = true;
            this.selectedAddressProofType = "Passport";
          }
          if (singleDoc.type.includes("BACK")) {
            this.showFileUploadCartDocument = true;
            this.showCheckedMarkForPassportBack = true;
            this.passportBackUrl = singleDoc.location;
            console.log("passportBackUrl >>> " ,this.passportBackUrl);
          }
          if(singleDoc.type == 'PASSPORT' || singleDoc.type.includes('ELECTRICITY')) {
            this.showFileUploadCartDocument = true;
            this.showCheckedMark = true;
            this.selectedAddressProofUrl = singleDoc.location;
            console.log("selectedAddressProofUrl >>> " ,this.selectedAddressProofUrl);
          }
          if(singleDoc.type.includes('PANCARD')) {
            this.showCheckedMarkForPANCard = true;
            this.PANFileUrl =  singleDoc.location;
            console.log("PANFileUrl >>> " ,this.PANFileUrl);
          }
        });
      });
    });
  }

  

  getSelectedAddressProofType(selectedItem) {
    this.showFileUploadCartDocument = true;
    if (selectedItem.includes("Passport")) {
      this.showPassportFields = true;
    } else {
      this.showPassportFields = false;
    }
  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.dob = year + "-" + month + "-" + day;
  }

  validatePassportFields() {
    if (!this.validateEmailId(this.addRelationshipModel.email)) {
      this.toast.error("Email id is not valid!");
      return false;
    }
    if (this.showPassportFields) {
      if (!(this.addRelationshipModel.passportNumber)) {
        this.toast.error("Please enter passport number!");
        return false;
      } else if (!(this.addRelationshipModel.passportIssueDate)) {
        this.toast.error("Please select passportIssueDate!");
        return false;
      } else if (!(this.addRelationshipModel.passportExpiryDate)) {
        this.toast.error("Please select passportExpiryDate!");
        return false;
      } else if (!this.addRelationshipModel.passportIssuePlace) {
        this.toast.error("Please enter passportIssuePlace");
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  addRelation(data) {
    if (this.validatePassportFields()) {
      if (data === true) {
        if (this.paxID == undefined) {
          this.showLoader = true;
          this.addRelationshipModel.nationality = 'INDIAN';
          this.addRelationshipModel.consumerId = this.consumerId;
          this.addRelationshipModel.dob = this.dob;
          if (this.showPassportFields) {
            this.addRelationshipModel.passportExpiryDate = DateUtils.getNgbDateStructToDate(this.addRelationshipModel.passportExpiryDate);
            this.addRelationshipModel.passportIssueDate = DateUtils.getNgbDateStructToDate(this.addRelationshipModel.passportIssueDate);
          }
          let localPaxCreateRequests: Array<AddRelationshipModel> = [];
          localPaxCreateRequests.push(this.addRelationshipModel);
          let paxCreateRequests = { "paxCreateRequests": localPaxCreateRequests };
          console.log(JSON.stringify(paxCreateRequests));

          this.relationshipService.addRelationship(paxCreateRequests).subscribe(async resp => {
            this.response = resp.body;
            this.relationShipID = this.response.id;
            this.showLoader = false;
            this.router.navigate(['/list-relationship']);
            this.toast.success("Relationship added successfully", "Success !!");
            console.log(JSON.stringify(this.localArrayForList));
    
            for (let i = 0; i < this.localArrayForList.length; i++) {
              if (this.localArrayForList[i].file.type.indexOf("pdf") >= 0 || this.localArrayForList[i].file.type.indexOf("image") >= 0) {
                const formData = new FormData();
                formData.append('file', this.localArrayForList[i].file, this.localArrayForList[i].file.name);
                formData.append('ownerType', "CONSUMER");
                formData.append('type', this.localArrayForList[i].documentType);
                formData.append('description', "");
                formData.append('ownerId', this.relationShipID);
                if (this.localArrayForList[i].documentType == 'PANCARD') {
                  formData.append('documentPurpose', "KYC");
                } else {
                  formData.append('documentPurpose', "ADDRESS_PROOF");
                }
                const response = await this.updateProfileService.paxDocumentUploadUpdate(formData).toPromise();
              }
            }
            this.router.navigate(['/list-relationship']);
            console.log("New Relation++", this.localArrayForList);
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
            this.addRelationshipModel.dob = this.setDOBToInput(this.addRelationshipModel.dob);
          });

        } else {
          this.showLoader = true;
          this.addRelationshipModel.dob = DateUtils.getNgbDateStructToDate(this.addRelationshipModel.dob);
          this.relationshipService.editPax(this.paxID, this.addRelationshipModel).subscribe(resp => {
            this.showLoader = false;
            this.router.navigate(['/list-relationship']);
            this.toast.success("Relationship updated successfully", "Success!!");
          }, error => {
            this.addRelationshipModel.dob = this.setDOBToInput(this.addRelationshipModel.dob);
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        }
      }
      else {
        this.addRelationshipModel.dob = this.setDOBToInput(this.addRelationshipModel.dob);
        this.toast.error("Please Fill in all the fields.");
      }
    }
  }

  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
     return false;
    }

    onPaste(e) {
    e.preventDefault();
    return false;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  setDOBToInput(dateOfBirth): NgbDateStruct {
    let date = { year: parseInt(dateOfBirth.split("-")[0]), month: parseInt(dateOfBirth.split("-")[1]), day: parseInt(dateOfBirth.split("-")[2]) }
    return date;
  }

  fileUploadPaxDocument(event, documentType) {
    this.showLoader = true;
    let docType;
    let fileList: FileList = event.target.files;
    if (documentType === 'Passport') {
      docType = 'PASSPORT_FRONT_PAGE';
    } else if (documentType === 'PASSPORT_ADDRESS_PAGE') {
      docType = 'PASSPORT_ADDRESS_PAGE';
    } else if (documentType === 'PANCARD') {
      docType = 'PANCARD';
    } else {
      docType = this.getAddressProofCode(this.selectedAddressProofType);
    }
    if (fileList.length > 0) {
      if (documentType == 'PASSPORT_ADDRESS_PAGE') {
        this.file2 = fileList[0];
      } else if (documentType == 'PANCARD') {
        this.panFile = fileList[0];
      } else {
        this.file1 = fileList[0];
      }
    }
   
    if (this.file2) {
      this.documentDetails = new DocumentDetails();
      this.documentDetails.documentType = docType;
      this.documentDetails.file = this.file2;
      this.showCheckedMarkForPassportBack = true;
      this.localArrayForList.push(this.documentDetails);
    } else if(this.file1) {
      this.documentDetails = new DocumentDetails();
      this.documentDetails.documentType = docType;
      this.documentDetails.file = this.file1;
      this.showCheckedMark = true;
      this.localArrayForList.push(this.documentDetails);
    }

    if (this.panFile) {
      this.documentDetails = new DocumentDetails();
      this.documentDetails.documentType = docType;
      this.documentDetails.file = this.panFile;
      this.showCheckedMarkForPANCard = true;
      this.localArrayForList.push(this.documentDetails);
    } 

    this.showLoader = false;
    console.log("File Array  >>>>", this.localArrayForList);
  }

  getAddressProofCode(typeString) {
    return typeString.replaceAll(' ', '_').toUpperCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  }

  downloadUploadedDoc(){ 
    this.viewLocalFileUtils.viewLocalDocument(this.panFile);
  }

  viewLocalDocument(){
    this.viewLocalFileUtils.viewLocalDocument(this.file1);
  }

  viewPassportBackPage(){
    this.viewLocalFileUtils.viewLocalDocument(this.file2);

  }

  validateEmailId(emailId) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(emailId)) {
      return false;
    } else {
      return true;
    }
  }
 
  
  getPanNameOnEnter() {
    this.showLoader = true;
    var searchValue = this.addRelationshipModel.pan;
    if (searchValue.length == 10) {
      this.updateProfileService.getPanName(searchValue).subscribe((respPanData) => {
        this.showLoader = false;
        let resObj = JSON.parse(JSON.stringify(respPanData.body));
        if (resObj.fullName != 'null' && resObj.fullName != '') {
          this.addRelationshipModel.name = resObj.flatPan.details;
        } else {
          this.addRelationshipModel.name = '';
          this.toast.error(resObj.flatPan.status);
        }
      }, error => {
        this.showLoader = false;
      });
    } else {
      this.showLoader = false;
    }
  }
}
