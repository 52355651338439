<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row text-center">
                    <div class="col-md-12 ">
                        <h4 class="content-header-title  mb-0 ml-10" style="color: black">Zolve Credit Card</h4>
                    </div>
                    <div class="col-md-4 offset-md-4 mt-30">
                        <img src="assets/images/smartfx-home/zolve-card.png" style="width:80%">
                    </div>
                    <div class="col-md-12 br-10 cursor" (click)="openAccountForm('Zolve')">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <div class="mt-20 fs-11">With <span class="fw-600">Zolve</span>, you can apply for a <span class="fw-600">US account and US Credit Card</span> even before you set foot in America!</div>
                            </div>
                            <div class="col-md-2 offset-md-5 mt-15">
                                <button class="btn btn-primary  btn-block fs-12 text-center" tabindex="4" rippleEffect (click)="openAccountForm('Zolve')">Apply Now</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>