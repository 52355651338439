<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h4 class="content-header-title float-left mb-0">
                            Order Summary
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <table class="table-bordered" style="width: 100%;">
                            <tbody>
                                <tr class="text-center fs-11 text-white fw-500 bg-grey">
                                    <td width="20%" class="p-10">Product</td>
                                    <td width="20%">Currency</td>
                                    <td width="20%">FX Amount </td>
                                    <td width="20%">IBR</td>
                                    <td width="20%">INR Amount</td>
                                </tr>
                                <tr class="text-center fs-12 text-black fw-600">
                                    <td class="p-10">Remittance</td>
                                    <td>USD</td>
                                    <td>500.0</td>
                                    <td>75.18 </td>
                                    <td>2867 </td>
                                </tr>
                            </tbody>
                        </table>

                        <section class="invoice-preview-wrapper mb-20">
                            <div class="row invoice-preview">
                                <div class="card-body card-padding">
                                    <div class="row invoice-sales-total-wrapper">
                                        <div class="col-md-12  order-md-2 order-1">
                                            <div class="invoice-total-wrapper bg-light-grey p-15">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 text-black fw-700">Amount</p>
                                                    <p class="invoice-total-amount text-black fw-700 fs-12">₹ 43,238.00</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black">Charges </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">₹ 0.00</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black">Taxes </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">₹ 0.00</p>
                                                </div>

                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black ">TCS </p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">₹ 4,597.88</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black ">Total Amount</p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">₹ 78.00</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title fs-12 fw-700 text-black ">Amount Paid</p>
                                                    <p class="invoice-total-amount fs-12 fw-700 text-black">₹ 78.00</p>
                                                </div>
                                                <hr class="my-50 hr-color">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title text-black fw-700 fs-12">Balance</p>
                                                    <p class="invoice-total-amount text-black fw-700 fs-12">₹ 43,316.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 text-right">
                                    <img src="assets/images/card/payment_success_stamp.png" style="height:70px">
                                </div>
                            </div>
                        </section>
                    </div>

                    <div class="col-md-4 offset-md-1 text-center">
                        <img src="assets/images/card/order-success.png" style="height:100px">
                        <div class="text-black fw-700 fs-13 mt-10">Order ID : 223456</div>
                        <div class="text-warning fs-12 mt-10">Thank You !</div>
                        <div class="text-warning fs-12">Your order has been received.</div>
                        <div class="mt-10 fs-12 text-black20">
                            Yay! Your payment was successfully received! You will receive an e-receipt on your registered e-mail address shortly.
                        </div>
                        <div class="mt-10 fs-12 text-black20">
                            Thank you for your payment! You are requested to kindly e-mail the proof of your payment towards your pat customersupport@wsfx.in to complete your transaction.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>