<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class="content-header-title float-left mb-0 ml-10" style="color: black">Rewards and Referrals</h6>
                    </div>
                    <div class="col-md-6 text-right">
                        <button class="btn btn-primary circular-btn" (click)="goToReferAndEarnPage()">
                            Refer and Earn
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" style="background: #fff;margin-bottom:25px; ">
                            <li ngbNavItem class="ml-10" style="padding: 5px;">
                                <a ngbNavLink class="fs-11">MY CREDITS</a>
                                <ng-template ngbNavContent>
                                    <section class="border-card">
                                        <div class="row p-20">
                                            <div class="col-md-2 col-6">
                                                <img src="assets/images/avatars/profile.png" style="height: 40px;">
                                            </div>
                                            <div class="col-md-4">
                                                <div class="fw-600">{{consumerCreditData?.consumerName}}</div>
                                                <div>{{consumerCreditData?.email}}</div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>Available Credits</div>
                                                <div>{{consumerCreditData?.credits}}</div>
                                            </div>
                                            <div class="col-md-2 col-6">
                                                <button class="btn btn-primary redeem-button" (click)="goToRedeemPage()"> Redeem </button>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="mt-20">
                                        <table class="table table-bordered text-center">
                                            <thead>
                                                <th>Transaction Value</th>
                                                <th>Earn Credits</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Upto $2000</td>
                                                    <td>100</td>
                                                </tr>
                                                <tr>
                                                    <td>$2001-$3000</td>
                                                    <td>150</td>
                                                </tr>
                                                <tr>
                                                    <td>$3001-$5000</td>
                                                    <td>200</td>
                                                </tr>
                                                <tr>
                                                    <td>$5001-$7500</td>
                                                    <td>250</td>
                                                </tr>
                                                <tr>
                                                    <td>$7501-$10000</td>
                                                    <td>400</td>
                                                </tr>
                                                <tr>
                                                    <td>$10001-$15000</td>
                                                    <td>500</td>
                                                </tr>
                                                <tr>
                                                    <td>$15001-$20000</td>
                                                    <td>750</td>
                                                </tr>
                                                <tr>
                                                    <td>Greater than $20000</td>
                                                    <td>1000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>

                                    <div class="row mt-20">
                                        <div class="col-md-2">
                                            <input type="radio" name="radios" id="radio" class="mr-10" (click)="displayEarned()" checked/>
                                            <label for="">EARNED</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" name="radios" id="radio" class="mr-10" (click)="displaySpent()" />
                                            <label for="">SPENT</label>
                                        </div>
                                    </div>

                                    <div class="card mt-20">
                                        <div class="card-header fw-600">
                                            History
                                        </div>
                                        <div class="card-body">
                                            <section *ngIf="displayEarn == true">
                                                <div class="col-md-12 pl-0" *ngIf="consumerCreditHistory?.length == 0">
                                                    No data found!
                                                </div>
                                                <div class="row" *ngFor="let singleCreditHistory of consumerCreditHistory; let i =index">
                                                    <div class="col-md-4">
                                                        <div class="fw-600">{{singleCreditHistory.description}}</div>
                                                        <div>{{singleCreditHistory.createdAt | date: "dd/MM/yyyy"}}</div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="fw-600">{{singleCreditHistory.credit}} Points</div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="text-success fw-600">{{singleCreditHistory.creditStatus}}</div>
                                                    </div>
                                                    <div class='col-md-12 mt-10 mb-10'>
                                                        <div [ngClass]="(consumerCreditHistory.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'"></div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section *ngIf="!displayEarn">
                                                <div class="col-md-12 pl-0" *ngIf="noclaimedRewardFound">
                                                    No data found!
                                                </div>
                                                <div class="row" *ngFor="let claimed of claimedRewardResponseForAdmin; let i =index">
                                                    <div class="col-md-4">
                                                        <div class="fw-600">{{claimed.title}}</div>
                                                        <div> {{claimed.claimDate | date: "dd-MM-yyyy h:mm a" }}</div>
                                                        <div> {{claimed.rejectionReason}}</div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div> Claimed points : <span class="fw-600"> {{claimed.pointsRequired }}</span></div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div> {{claimed.status.replaceAll("_"," ")}}</div>
                                                    </div>
                                                    <div class='col-md-12  mt-10 mb-10'>
                                                        <div [ngClass]="(claimedRewardResponseForAdmin.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'"></div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem style="padding: 5px;">
                                <a ngbNavLink class="fs-11">REDEEMED CREDITS</a>
                                <ng-template ngbNavContent>

                                    <div class="mt-20">

                                        <div class="card-body">
                                            <section *ngIf="unlock == false">
                                                <div class="col-md-12 text-center pl-0" *ngIf="unlockedDetailsList?.length == 0">
                                                    No data found!
                                                </div>
                                                <div class="row" *ngFor="let singleUnlockItem of unlockedDetailsList; let i =index">
                                                    <div class="col-md-4">
                                                        <div class="fw-600">{{singleUnlockItem.title}}</div>
                                                        <div class="fw-600">{{singleUnlockItem.shortDescription}}</div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <div> Validity : {{ singleUnlockItem.startDate | date: "dd-MM-yyyy h:mm a" }} To {{ singleUnlockItem.endDate | date: "dd-MM-yyyy h:mm a" }}</div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <button class="mat-flat-button btn-new-bw-theme " (click)="unlockReward(singleUnlockItem)">
                                                                Unlock
                                                            </button>
                                                    </div>
                                                    <div class='col-md-12 mt-20'>
                                                        <div [ngClass]="(unlockedDetailsList.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'"></div>
                                                    </div>
                                                </div>
                                                <div style="text-align: center" class="pt2 mt-20">
                                                    <button class="btn btn-primary redeem-button" *ngIf="unlock == false" (click)="availableList()">
                                                        Unlocked Vouchers
                                                    </button>
                                                </div>
                                            </section>
                                            <section *ngIf="unlock == true">
                                                <div class="col-md-12 text-center pl-0" *ngIf="availableDetailsList?.length == 0">
                                                    No data found!
                                                </div>
                                                <div class="row" *ngFor="let singleAvailableItem of availableDetailsList; let i =index">
                                                    <div class="col-md-4">
                                                        <div class="fw-600">{{singleAvailableItem.title}}</div>
                                                        <div class="fw-600">{{singleAvailableItem.shortDescription}}</div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <div> Validity : {{ singleAvailableItem.startDate | date: "dd-MM-yyyy h:mm a" }} To {{ singleAvailableItem.endDate | date: "dd-MM-yyyy h:mm a" }}</div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <button class="mat-flat-button btn-new-bw-theme " (click)="rewardDetails(singleAvailableItem)">
                                                                Details
                                                            </button>
                                                    </div>
                                                    <div class='col-md-12 mt-20'>
                                                        <div [ngClass]="(unlockedDetailsList.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'"></div>
                                                    </div>
                                                </div>
                                                <div style="text-align: center" class="pt2 mt-20">
                                                    <button class="btn btn-primary redeem-button" *ngIf="unlock == true" (click)="unlockedList()">
                                                        Available Vouchers
                                                    </button>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="navFilled" class="col-md-12 fs-12 text-black20">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>