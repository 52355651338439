import { Injectable } from "@angular/core";
 
@Injectable()
export class ViewLocalFileUtils { 

    public viewLocalDocument(selectedFile) {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(selectedFile)
        a.href = objectUrl
        a.download = selectedFile.name;
        a.click();
        URL.revokeObjectURL(objectUrl);
    }
}