<div class="row">
    <div class="col-md-12 mb-10 mt-10">
        <h6 class="content-header-title float-left">
            Remittance for student
        </h6>
    </div>
    <div class="col-md-12" *ngFor="let singleItem of relationshipMemberListItems; let i=index">
        <div [ngClass]="(relationshipMemberListItems.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
            <div class="row">
                <div class="col-md-12 col-12 mt-10">
                    <span style="margin-right:5px">
                        <input type="radio" name="radios" id="radio"
                            (change)="updateSelectedStudentDetailsToSession(singleItem)" />
                    </span>
                    <span class="card-title fs-12 text-black">
                        {{ singleItem.name }}
                    </span>
                </div>
            </div>
            <div class="row mb-10">
                <div class="col-md-12 col-12" style="margin-left : 22px">
                    <span class="fs-11 "> Relationship : {{ singleItem.relationship }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-10 mb-20">
        <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="showAddRelationForm = true">
            Add Relationship
        </button>
    </div>
</div>

<section *ngIf="showAddRelationForm">
    <form class="form-horizontal" (ngSubmit)="addRelation(AddRelationForm.form.valid)" #AddRelationForm="ngForm">

        <div class="row">
            <div class="form-group col-md-6">
                <label class="form-label">Relationship*</label>
                <ng-select class="fs-12" [items]="getRelationShipList" bindValue="getRelationShipList" [(ngModel)]="addRelationshipModel.relationship" required placeholder="Relationship" name="relationship" #relationshipRef="ngModel" [class.error]="AddRelationForm.submitted && relationshipRef.invalid"
                    [clearable]="false" [searchable]="false">
                </ng-select>

                <span *ngIf="AddRelationForm.submitted && relationshipRef.invalid" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">
                        This field is required!
                    </small>
                </span>
            </div>
            <div class="form-group col-md-6">
                <label class="form-label">PAN*</label>
                <input type="text" name="pan" class="form-control fs-12" placeholder="PAN*" [(ngModel)]="addRelationshipModel.pan" name="pan_no" #panNoRef="ngModel" [class.error]="AddRelationForm.submitted && panNoRef.invalid" style="border-color: black;" required />
                <span *ngIf="AddRelationForm.submitted && panNoRef.invalid" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="panNoRef.errors.required">
                        This field is required!
                    </small>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-6">
                <label class="form-label">Name as per PAN*</label>
                <input type="text" id="name" class="form-control fs-12" placeholder="Name as per PAN*" [(ngModel)]="addRelationshipModel.name" name="name_as_per_pan" #nameAsPerPanRef="ngModel" [class.error]="AddRelationForm.submitted && nameAsPerPanRef.invalid" required
                    style="border-color: black;" />
                <span *ngIf="AddRelationForm.submitted && nameAsPerPanRef.invalid" class="invalid-form">
                    <small class="form-text text-danger" *ngIf="nameAsPerPanRef.errors.required">This field is
                        required!</small>
                </span>
            </div>
            <div class="form-group col-md-6">
                <label for="basicInputFile">Choose File</label>
                <div class="custom-file">
                    <input type="file" class="custom-file-input fs-12" id="customFile" />
                    <label class="custom-file-label" for="customFile">Relationship Proof(PAN Card)*</label>
                    <div class="invalid-state fs-11 text-muted mt-10">*Please upload documents in either JPEG/PNG/PDF under 1MB</div>
                </div>
            </div>

            <div class="col-md-12 mb-20 text-right">
                <button type="submit" rippleEffect class="btn btn-primary fs-12">Save</button> &nbsp;&nbsp;
                <button type="submit" rippleEffect class="btn btn-primary fs-12" (click)="hideAddRelation()">Cancel</button>
            </div>
        </div>
    </form>
</section>