<loader *ngIf="showLoader"></loader>

<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Login v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="assets\images\logo\logo.png" alt="brand-logo" height="100" />
                </a>
                <ngb-alert [type]="'danger'" *ngIf="error != '' " class="mt-1" [dismissible]="false">
                    <div class="alert-body">
                        <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                        <span class="fs-12">{{error}}</span>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!showLoginWithPassword">
                    <div class="form-group">
                        <label class="form-label" for="login-email">Mobile</label>
                        <input oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" style="border-color:black" formControlName="id" class="form-control fs-12" [(ngModel)]="requestLoginModel.id"
                            placeholder="Enter Mobile" [ngClass]="{ 'is-invalid': submitted && f.id.errors }" (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" />
                        <div *ngIf="submitted && f.id.errors" class="invalid-feedback">
                            <div *ngIf="f.id.errors.required">Mobile no is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between ">
                            <label for="login-password">OTP</label>
                            <a (click)="getOTPForLogin()">
                                <small style="color:#000">Send OTP</small>
                            </a>
                        </div>

                        <div class="input-group input-group-merge
                            form-password-toggle">
                            <input type="number" style="border-color:black" maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Enter OTP"
                                formControlName="otp" class="form-control fs-12 form-control-merge" [(ngModel)]="requestLoginModel.otp" aria-describedby="login-password" [ngClass]="{ 'is-invalid error': submitted && f.otp.errors }" (keypress)="keyPressNumbers($event)"
                                (paste)="onPaste($event)" (keydown.enter)="onSubmit()" />
                            <!-- <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i
                                        class="feather font-small-4"
                                        [ngClass]="{
                                        'icon-eye-off': passwordTextType,
                                        'icon-eye': !passwordTextType
                                        }" (click)="togglePasswordTextType()"></i></span>
                            </div> -->
                        </div>
                        <div *ngIf="submitted && f.otp.errors" class="col-md-12 invalid-feedback" [ngClass]="{ 'd-block': submitted && f.otp.errors }">
                            <div *ngIf="f.otp.errors.required">OTP is required</div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" tabindex="4" rippleEffect>Login</button>
                </form>

                <form class="auth-login-form mt-2" *ngIf="showLoginWithPassword" (ngSubmit)="loginWithPassword()">
                    <div class="form-group">
                        <label class="form-label" for="login-email">Mobile</label>
                        <input type="number" name="mobile" class="form-control fs-12" (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" [(ngModel)]="requestLoginModel.id"
                            placeholder="Enter Mobile" maxlength="10" style="border-color:black" />
                        <!-- <div *ngIf="submitted && loginWithPassswordForm.id.errors" class="invalid-feedback">
                            <div *ngIf="loginWithPassswordForm.id.errors.required">Mobile no is required</div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between ">
                            <label for="login-password ">Password</label>
                        </div>

                        <div class="input-group input-group-merge
                            form-password-toggle">
                            <input type="password" name="token" class="form-control fs-12" placeholder="Enter Password" style="border-color:black" [(ngModel)]="requestLoginModel.token" (keydown.enter)="onSubmit()" />
                        </div>
                        <!-- <div *ngIf="submitted && loginWithPassswordForm.token.errors" class="invalid-feedback">
                            <div *ngIf="loginWithPassswordForm.token.errors.required">Password is required</div>
                        </div> -->
                    </div>
                    <div class="text-right mb-10">
                        <div class="fs-11 link" routerLink="/forgot-password" style="color:#000">Forgot Password ? </div>
                    </div>
                    <button class="btn btn-primary btn-block" tabindex="4" rippleEffect>Login</button>
                </form>


                <div class="mt-20 link text-center blue-text fw-500 fs-12" style="text-decoration : underline" *ngIf="!showLoginWithPassword">
                    <div (click)="showLoginWithPassword = true"> Login with password </div>
                </div>
                <div class="mt-20 link text-center blue-text fw-500 fs-12" style="text-decoration : underline" *ngIf="showLoginWithPassword">
                    <div (click)="showLoginWithPassword = false"> Login with OTP </div>
                </div>

                <p class="text-center mt-2 fs-12">
                    <span style="color:#000">New on our platform?</span>
                    <a routerLink="/signup">
                        <span>&nbsp;Create an account</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>