export class ISICRegisterModel{
        fullName:string;
        gender:string;
        address: string;
        email: string;
        mobile: string;
        nationality: string;
        purposeOfVisit: string;
        visitingCountry: string;
        passportNumber:string;
        pan: string;
        // dob: 1989-11-21T18:30:00.000Z;
        dob:  string;
        wsfxInvoiceNumber: string;
        schoolUniversityName:  string;
        panNumber: string;
        product: string;
        formType: string;
}
 