<div class="row mt-20 mb-20">
    <div class="col-md-12">
        <img src="assets/images/illustration/a2form.png" class="mb-30 image-self-declaration">
    </div>
</div>
<div class="row">
    <div class="col-md-12 fs-13 text-black fw-600 mb-20 text-center">Please proceed for Self Declaration</div>
</div>
<loader *ngIf="showLoader"></loader>
<div class="row">
    <div class="col-md-12">
        <span class="custom-control custom-checkbox mb-20">
            <input type="checkbox" class="custom-control-input" id="declaration" [(ngModel)]="isChecked"
                (change)="checkCheckBoxvalue(isChecked)" style="border-color: black;" />
            <label for="declaration" class="custom-control-label"></label>
            <label>
                I hereby acknowledge that I have read, fully understood and affix my signature in acceptance of the
                <span class="blue-text"><a (click)="openTermsAndConditions()">Terms & Conditions,</a></span>
                <span class="blue-text link"> <a (click)="getA2FormLink()">Form A2 cum Declaration </a> </span> and my KYC documents.
            </label>
        </span>
        
        <div class="row">

            <div class="col-md-3 col-sm-6 mt-23">
                <label for="basicInputFile">OTP</label>
                <input type="text" style="border-color:black" class="form-control fs-12" name="otp" #otp
                    placeholder="OTP for Acceptance*" [(ngModel)]="otpValue" maxlength="6"
                    (ngModelChange)="getEnteredOTP($event)" />
                <small class="form-text text-right  blue-text link fs-11" (click)="sendOTP()">
                    Resend OTP
                </small>
            </div>

            <!-- <div class="col-md-1 col-sm-6 mt-23" style="text-align: center;">
                <br> AND 
            </div>

            <div class="col-md-3 col-sm-6 col-xs-12 col-7 mt-23">
                <div class="form-group">
                    <label for="basicInputFile">Upload signature</label>
                    <div class="custom-file">
                        <input type="file" style="border-color:black"  (change)="uploadSignature($event)" class="form-control fs-12" accept=".jpeg,.jpg,.png" id="customFile-papersign" placeholder="Upload signature *" />
                        <label class="custom-file-label" for="customFile-papersign">
                            Choose file
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-3 mt-23 mt-23" *ngIf="showImageUploadedIcon">
                <br>
                <div class="input-group-append" style="height: 38px;">
                    <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                            style="width:20px;width:20px"
                            (click)="openModalForFinancialDocumentView(transactionDocumentViewModal, fileUrl)"></span>
                    <span class="input-group-text "><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                </div>
            </div> -->

            <!-- <div class="col-md-3 col-sm-6 mt-23">
                <label for="basicInputFile">
                    Please sign in the box
                </label>
                <div class="signaturepad" style="width:300px;">
                    <div class="signaturePadOptionsStyle">
                        <signature-pad [options]="signaturePadOptions" drag-content="false" (onBeginEvent)="drawBegin()"
                            (onEndEvent)="drawComplete()" (window:resize)="canvasResize()" #signaturePad>
                        </signature-pad>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <button class="btn btn-primary btn-gradient btn-submit fs-12" rippleEffect (click)="clear()">
                            <span class="align-middle">Clear</span>
                        </button>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <button class="btn btn-primary btn-gradient btn-submit fs-12" rippleEffect
                            (click)="saveSignature()">
                            <span class="align-middle">Save</span>
                        </button>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-md-2 col-3 mt-23" *ngIf="showImageUploadedIcon">
                <div class="input-group-append" style="height: 38px; margin-top: 30px;">
                    <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                            style="width:20px;width:20px"
                            (click)="openModalForFinancialDocumentView(transactionDocumentViewModal, fileUrl)"></span>
                    <span class="input-group-text "><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                </div>
            </div> -->

        </div>
        <br>
        <!-- SEND OTP
        <div class="form-group col-md-4 pl-0 mt-40 mb-30">
            <input type="text" class="form-control fs-12" name="otp" #otp placeholder="OTP for Acceptance*" [(ngModel)]="otpValue" maxlength="6" (ngModelChange)="getEnteredOTP($event)" />
            <small class="form-text text-right  blue-text link fs-11" (click)="sendOTP()">
                    Resend OTP
            </small>
        </div> -->
        <!-- <div class="text-black  fs-12 mt-20 mb-30"> Note : Aadhaar eSign sign's your documents needed for a successful completion of your KYC, to process a remittance request with WSFx.</div> -->
    </div>
</div>