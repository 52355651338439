<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card invoice-preview-card">
            <div class="card-padding">
                <div class="row">
                    <div class="col-md-12">
                        <loader *ngIf="showLoader"></loader>
                        <ul ngbNav #nav="ngbNav" class="nav nav-pills pay-nav" style="width: 100%;">

                            <li class="nav-item" ngbNavItem >
                                <a ngbNavLink class="nav-link d-flex py-75" id="payment" role="tab" >
                                    <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12">Payment</span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment == 'COMPLETED' ">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED' ">
                                </a>
                                <hr class="dotted d-none">
                                <ng-template ngbNavContent>
                                    <div style="padding-left: 3%;">
                                        <div class="row">
                                            <div class="col-md-7 col-xl-7 col-sm-12 col-xs-12 col-lg-7" style="padding-right: 0%;" >
                                       

                                                <div class="row">
                                                           
                                                        <div class="col-md-6">
                                                            
                                                                    <h3 class=" fs-14"><span style="color: #00CCEE; font-weight: 600;"><br>
                                                                            Order ID : {{orderDetails?.orderId}}</span>
                                                                    </h3>
                                                              
                                                           
                                                        </div>

                                                       

                                                    <div class="col-md-8 fs-13" *ngIf="orderDetails?.orderStatusGroup?.status != 'EXPIRED' ">
                                                        <div class="card" style="background-color: #a8dde626; padding: 2%; border:  1.3px dashed #00CCEE;">
                                                            <div class="row">
                                                                <div class="col-md-8">
                                                                    <span class="text-black"><strong> Payment Status :
                                                                        </strong></span>
                                                                    <span [ngClass]="orderDetails?.orderStatusGroup?.consumerPayment == 'COMPLETED' ? 'text-success fw-600' : 'text-danger fw-600'">
                                                                        <strong>{{orderDetails?.orderStatusGroup?.consumerPayment}}</strong>
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-4" *ngIf="!isSurrenderOrder">
                                                                    <div *ngIf="orderDetails?.orderStatusGroup?.consumerPayment !='COMPLETED'">
                                                                        <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="retryPayment()">Pay Now</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="!isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'" >
                                                    <div class="col-md-12 col-12 col-sm-12">
                                                        <table class="table table-bordered table-responsive" *ngIf="transactionDetails?.length  > 0">
                                                            <thead>
                                                                <tr class="table-header">
                                                                    <th>Payment Date</th>
                                                                    <th>Reference Number</th>
                                                                    <th>Payment Type</th>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                    <th>Upload payment proof</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="fs-12 text-black">
                                                                <tr *ngFor="let singleTransaction of transactionDetails">
                                                                    
                                                                    <td *ngIf="!singleTransaction?.transactionDetails.paymentDate.includes('-')">
                                                                        {{moment(+singleTransaction?.transactionDetails.paymentDate).format(' DD-MMM-YYYY, h:mm:ss A')}}
                                                                    </td>
                                                                    <th class="mob-table">Payment Date</th>
                                                                    
                                                                    <td *ngIf="singleTransaction?.transactionDetails.paymentDate.includes('-')">
                                                                        {{singleTransaction?.transactionDetails.paymentDate }}
                                                                    </td>
                                                                    <th class="mob-table">Reference Number</th>
                                                                    
                                                                    <td>{{singleTransaction?.transactionDetails?.referenceNumber}}
                                                                    </td>
                                                                    <th class="mob-table">Payment Type</th>
                                                                    <!-- <td *ngIf="singleTransaction?.transactionDetails?.bankReferenceNumber">{{singleTransaction?.transactionDetails?.bankReferenceNumber}}
                                                                    </td> -->
                                                                   
                                                                    <td>{{singleTransaction?.paymentChannel}} </td>
                                                                     <th class="mob-table">Amount</th>
                                                                    
                                                                    <td>{{singleTransaction?.amount}}
                                                                    </td>
                                                                    <th class="mob-table">Status</th>
                                                                    
                                                                    <td><i class="fa fa-check text-success" style="width:20px"></i></td>
                                                                    <th class="mob-table">Upload payment proof</th>
                                                                    <td>
                                                                        <div class="image-upload">
                                                                            <label for="file-input">
                                                                                <img src="assets/images/icons/upload-image.png"
                                                                                    style="height:20px"
                                                                                    (click)="getTransactionID(singleTransaction?.id)" />
                                                                            </label>
                                                                            <span *ngIf="transaction?.proof">
                                                                                &nbsp;
                                                                                <span><img
                                                                                        src="assets/images/icon/checkmark.gif"
                                                                                        class="w-h-20"></span> &nbsp;
                                                                            <span><img (click)="showProof()"
                                                                                    src="assets/images/icon/view-document.png"
                                                                                    class="w-h-20"></span>
                                                                            </span>
                                                                            <input id="file-input" type="file" ngModel="transactionProof" #file name="imageName" (change)="fileUploadTransactionProof($event)" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <div class="col-md-12 fs-12 link blue-text text-right" *ngIf="transactionDetails?.length  > 0 && orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED'" (click)="showTransactionForm()">
                                                            Add Transaction Details</div>

                                                        <form class="form-horizontal" (ngSubmit)="updatePaymentDetails(PaymentDetailsForm.form.valid)" #PaymentDetailsForm="ngForm">
                                                            <section *ngIf="showTransactionPaymentForm">
                                                                <div class="row  mb-20">
                                                                    <div class="col-md-12">
                                                                        <div class="text-black fs-13 fw-500">Transaction Details
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="form-group col-md-6">
                                                                        <label class="form-label">Transaction Reference Number
                                                                        </label>
                                                                        <input type="text" class="form-control fs-12" placeholder="Transaction Reference Number" [(ngModel)]="updatePaymentDetailsRequestModel.referenceNumber" name="referenceNumber" #referenceNumberRef="ngModel" [class.error]="PaymentDetailsForm.submitted && referenceNumberRef.invalid"
                                                                            style="border-color:#000" required />
                                                                        <span *ngIf="PaymentDetailsForm.submitted && referenceNumberRef.invalid" class="invalid-form">
                                                                            <small class="form-text text-danger"
                                                                                *ngIf="referenceNumberRef.errors.required">This
                                                                                field is
                                                                                required!</small>
                                                                        </span>
                                                                    </div>

                                                                    <div class="form-group col-md-6">
                                                                        <label>Payment Type*</label>
                                                                        <div class="form-group m-form__group">
                                                                            <select class="form-control m-input" id="manualPaymentType" name="manualPaymentType" autocomplete="off" required [(ngModel)]="updatePaymentDetailsRequestModel.manualPaymentType">
                                                                               <option value="" selected data-default>Select Payment Type</option>
                                                                              <option *ngFor="let singleItem of paymentItemList" [value]="singleItem">
                                                                              {{ singleItem }}</option>
                                                                           </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group  col-md-6">
                                                                        <label class="form-label" for="transaction_date">Transaction
                                                                            Date</label>
                                                                        <div class="input-group">
                                                                            <input class="form-control fs-12" placeholder="Select Transaction Date" (click)="basicDP.toggle()" [(ngModel)]="updatePaymentDetailsRequestModel.paymentDate" ngbDatepicker #basicDP="ngbDatepicker" name="paymentDate" #paymentDateRef="ngModel" [class.error]="PaymentDetailsForm.submitted && paymentDateRef.invalid"
                                                                                required (dateSelect)="getDateFormat($event)" [maxDate]="transactionDetailsMaxDate">
                                                                            <div class="input-group-append" style="border-color:#000">
                                                                                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                                            </div>
                                                                        </div>
                                                                        <span *ngIf="PaymentDetailsForm.submitted && paymentDateRef.invalid" class="invalid-form">
                                                                            <small class="form-text text-danger"
                                                                                *ngIf="paymentDateRef.errors.required">This field is
                                                                                required!</small>
                                                                        </span>
                                                                    </div>

                                                                    <div class="form-group col-md-6">
                                                                        <label class="form-label">Transaction Amount </label>
                                                                        <input type="text" class="form-control fs-12" placeholder="Transaction Amount" [(ngModel)]="updatePaymentDetailsRequestModel.amount" name="amount" #amountRef="ngModel" [class.error]="PaymentDetailsForm.submitted && amountRef.invalid" required 
                                                                        />
                                                                        <span *ngIf="PaymentDetailsForm.submitted && amountRef.invalid" class="invalid-form">
                                                                            <small class="form-text text-danger"
                                                                                *ngIf="amountRef.errors.required">This field is
                                                                                required!</small>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <button class="btn btn-primary fs-12" rippleEffect>Submit</button>
                                                                    </div>
                                                                    <div class="col-md-12 mt-20 ">
                                                                        <div class="fs-14 fw-600 text-black">For NEFT/RTGS/IMPS payment
                                                                        </div>
                                                                        <div class="fs-11 fw-500  text-black">Transfer to :</div>
                                                                        <div class="fs-11 fw-500  text-black">{{accountName}}
                                                                        </div>
                                                                        <div class="fs-11  text-black">{{bankName}}</div>
                                                                        <div class="fs-11  text-black">{{accountNo}}</div>
                                                                        <div class="fs-11  text-black">{{bankIFSC}}</div>
                                                                        <div class="fs-11  text-black">{{bankBranch}}</div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </form>
                                                        <br>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>

                                        </div>
                                    </div>
                                </ng-template>
                            </li>





                            <li class="nav-item" [ngbNavItem]="2" *ngIf="!hideBeneficiaryDetails && orderDetails?.orderStatusGroup?.status != 'EXPIRED'">
                                <a ngbNavLink class="nav-link d-flex py-75" id="payer-details" role="tab">
                                    <!-- <span class="step-number" [ngClass]=" active == 2 ? 'ecommerce-card-selected' : '' ">
                                        <i data-feather="user" class="font-medium-3"></i>
                                    </span>&nbsp; -->
                                    <i data-feather="users" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Beneficiary Details</span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="showBeneDetails">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="!showBeneDetails">

                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div style="padding-left: 3%; ">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                        Order ID is # {{orderDetails?.orderId}}</span>
                                                <div class="col-md-12 mt-20 pl-0">
                                                    <div class="fs-14 fw-600 dark-blue-title"> Beneficiary Details </div>
                                                </div>
                                                <div class="col-md-12 mb-30 pl-0">
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Country Name : </span>
                                                        <span> {{orderDetails?.travellingCountryName}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Beneficiary Name : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.name}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Beneficiary Address : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.address}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Bank Name : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.bankName}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Bank Address : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.bankAddress}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Account Number/IBAN : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.bankAccountNumber}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Account Number/IBAN : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.bankAccountNumber}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Other Details(Student ID, Passport, Ref
                                                                No.) : </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.studentId != '' ">
                                                                {{orderDetails?.beneficiaryDetails?.studentId}} </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.studentId == '' || orderDetails?.beneficiaryDetails?.studentId == null ">
                                                                - </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Swift Code : </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.swiftCode != '' ">
                                                                {{orderDetails?.beneficiaryDetails?.swiftCode}} </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.swiftCode == '' || orderDetails?.beneficiaryDetails?.swiftCode == null ">
                                                                - </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> SORT/ABA/BSB/TRANS NO : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.sortCode}} </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Intermediary Bank Name : </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.intermediaryBankName != '' ">
                                                                {{orderDetails?.beneficiaryDetails?.intermediaryBankName}} </span>
                                                        <span *ngIf="orderDetails?.beneficiaryDetails?.intermediaryBankName == '' || orderDetails?.beneficiaryDetails?.intermediaryBankName == null ">
                                                                - </span>
                                                    </div>
                                                    <div class="fs-11 mt-10">
                                                        <span class="text-black fw-500"> Intermediary Bank Swift Code : </span>
                                                        <span> {{orderDetails?.beneficiaryDetails?.intermediaryBankSwiftCode}}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" ngbNavItem *ngIf="!isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'" >
                                <a ngbNavLink class="nav-link d-flex py-75" id="payer-details" role="tab">
                                    <!-- <span class="step-number" [ngClass]=" active == 3 ? 'ecommerce-card-selected' : '' ">
                                        <i data-feather="user" class="font-medium-3"></i>
                                    </span>&nbsp; -->
                                    <i data-feather="user" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Payer Details</span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails?.payer?.payerName">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="!orderDetails?.payer?.payerName">

                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div style="padding-left: 3%; ">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                        Order ID is # {{orderDetails?.orderId}}</span>
                                                <div class="col-md-12 mb-20 pl-0">
                                                    <div class="fs-14 fw-600 dark-blue-title pl-0 mt-10"> Payer Details </div>
                                                    <div class="col-md-12 mb-20 pl-0">
                                                        <div class="fs-11 mt-10">
                                                            <span class="text-black fw-500"> Payer's Name : </span>
                                                            <span> {{orderDetails?.payer?.payerName}} </span>
                                                        </div>
                                                        <div class="fs-11 mt-10">
                                                            <span class="text-black fw-500"> Payer's PAN : </span>
                                                            <span> {{orderDetails?.payer?.payerPan}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <!-- <li class="nav-item" ngbNavItem *ngIf="showUploadPaymentProof && !isSurrenderOrder">
                                <a ngbNavLink class="nav-link d-flex py-75" id="payment-proof" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                                    <span class="font-weight-bold fs-12"> Retry Payment </span>
                                </a>
                                <hr class="dotted">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon card card-body" id="faq-payment-proof" role="tabpanel" aria-labelledby="payment-proof" aria-expanded="false" style="padding-left: 3%;  ">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-tag bg-light-primary mr-1">
                                                <i data-feather="credit-card" class="font-medium-4"></i>
                                            </div>
                                            <div>
                                                <h5 class="mb-0"> Retry Payment</h5>
                                                <span></span>
                                            </div>
                                        </div>

                                        <div class="row  mt-20">
                                            <div class="col-md-7 text-center">
                                                <div class="text-center" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED'">
                                                    <button type="button" rippleEffect class="btn btn-primary fs-12" (click)="retryPayment()">Retry Now</button>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li> -->

                            <li class="nav-item" ngbNavItem *ngIf="!isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'">
                                <a ngbNavLink class="nav-link d-flex py-75" id="documents" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="file-text" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12">Documents</span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails?.documents">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="!orderDetails?.documents">
                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-documents" role="tabpanel" aria-labelledby="documents" aria-expanded="false" style="padding-left: 3% ">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                        Order ID is # {{orderDetails?.orderId}}</span>

                                                <div class="col-md-12 mt-20 mb-20 pl-0">
                                                    <div class="fs-14 fw-600 dark-blue-title"> Transaction Documents </div>
                                                </div>

                                                <div class="col-md-12 pl-0">
                                                    <section *ngFor="let singleDocument of orderDetails.documents">
                                                        <div class="row" *ngIf="singleDocument.kycDocumentPurpose == 'TRANSACTION' ">
                                                            <div class="col-md-4 col-8 fs-13 mb-20">
                                                                {{singleDocument.documentType.replaceAll('_', ' ') | titlecase}}
                                                            </div>
                                                            <div class="col-md-1 col-2" *ngIf="singleDocument.status == 'UPLOADED'">
                                                                <a href={{singleDocument.location}}><img src="assets/images/icon/view-document.png" class="w-h-20"></a>
                                                            </div>
                                                            <div class="col-md-1 col-2" *ngIf="singleDocument.status == 'UPLOADED'">
                                                                <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>


                            <li class="nav-item" ngbNavItem *ngIf="orderDetails?.extSwift || orderDetails?.extSwift != null && orderDetails?.extSwift != '' && orderDetails?.orderStatusGroup?.status != 'EXPIRED' ">
                                <a ngbNavLink class="nav-link d-flex py-75" id="signed-A2-Form" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="check-square" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Receipt/Swift </span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails.extSwift">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="!orderDetails.extSwift">
                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-signed-A2-Form" role="tabpanel" aria-labelledby="signed-A2-Form" aria-expanded="false" style="padding-left: 3%;  ">
                                        <div class="row ">
                                            <div class="col-md-7  ">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                    Order ID is # {{orderDetails?.orderId}}</span>

                                                <div class="row  mt-20 mb-20">
                                                    <div class="col-md-6 pl-0 mt-5">
                                                        <div class="fs-14 fw-600 dark-blue-title"> Download Receipt/Swift </div>
                                                    </div>
                                                    <div>

                                                    <a href={{orderDetails.extSwift}}><button type="button" rippleEffect class="btn btn-primary fs-12">Download</button></a>
                                                    
                                                        </div>

                                                </div>
                                                <div class="row  mt-20 mb-20" style="border-top:1px solid #bdbcbc; padding-top: 10px;" *ngIf="orderDetails?.extInvoice || orderDetails?.extInvoice != null && orderDetails?.extInvoice != '' ">
                                                    <div class="col-md-6 pl-0 mt-5">
                                                        <div class="fs-14 fw-600 dark-blue-title"> Download Invoice </div>
                                                    </div>
                                                    <div>
                                                
                                                        <a href={{orderDetails.extInvoice}}><button type="button" rippleEffect
                                                                class="btn btn-primary fs-12">Download</button></a>
                                                
                                                    </div>
                                                
                                                </div>
                                                    
                                              
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" ngbNavItem *ngIf="isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'">
                                <a ngbNavLink class="nav-link d-flex py-75" id="product-services" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="users" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Encashment Status</span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails.orderStatusGroup?.consumerPayment">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="!orderDetails.orderStatusGroup?.consumerPayment">
                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-product-services" role="tabpanel" aria-labelledby="product-services" aria-expanded="false" style="padding-left: 3%;  ">
                                        <div class="row mt-20">
                                            <div class="col-md-7 ">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                    Order ID is # {{orderDetails?.orderId}}</span>
                                                <div class="col-md-12 mt-20 mb-20 pl-0">
                                                    <div class="fs-14 fw-600 dark-blue-title"> Encashment Status </div>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> Encashment Status : </span>
                                                    <span> {{orderDetails?.orderStatusGroup?.consumerPayment}} </span>
                                                </div>

                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" ngbNavItem *ngIf="isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'">
                                <a ngbNavLink class="nav-link d-flex py-75" id="product-services" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="users" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Bank Details</span>
                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-product-services" role="tabpanel" aria-labelledby="product-services" aria-expanded="false" style="padding-left: 3%">

                                        <div class="row  ">
                                            <div class="col-md-7 ">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                    Order ID is # {{orderDetails?.orderId}}</span>
                                                <div class="col-md-12 mt-20 mb-20 pl-0">
                                                    <div class="fs-14 fw-600 dark-blue-title"> Bank Details </div>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> Name : </span>
                                                    <span> {{orderDetails?.bankAccountResponse?.nameAsPerAccountNumber}} </span>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> Bank Name : </span>
                                                    <span> {{orderDetails?.bankAccountResponse?.bankName}} </span>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> Bank Account Number : </span>
                                                    <span> {{orderDetails?.bankAccountResponse?.number}} </span>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> IFSC Code : </span>
                                                    <span> {{orderDetails?.bankAccountResponse?.ifscCode}} </span>
                                                </div>
                                                <div class="fs-11 mt-10">
                                                    <span class="text-black fw-500"> Branch : </span>
                                                    <span> {{orderDetails?.bankAccountResponse?.branch}} </span>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li class="nav-item" ngbNavItem *ngIf="!isSurrenderOrder && orderDetails?.orderStatusGroup?.status != 'EXPIRED'">
                                <a ngbNavLink class="nav-link d-flex py-75" id="signed-A2-Form" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="check-square" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Signed A2 Form </span>
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails?.payer?.payerName">

                                </a>
                                <hr class="dotted d-none">

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-signed-A2-Form" role="tabpanel" aria-labelledby="signed-A2-Form" aria-expanded="false" style="padding-left: 3%; ">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16"> Your
                                                        Order ID is # {{orderDetails?.orderId}}</span>

                                                        <div class="col-md-12 mt-20 mb-20 pl-0">
                                                            <div class="fs-14 fw-600 dark-blue-title"> A2 Form </div>
                                                        </div>
        
                                                        <div class="col-md-12 pl-0">
                                                            <section >
                                                                <div class="row">
                                                                    <div class="col-md-4 col-8 fs-13 mb-20">
                                                                        Signed A2 Form
                                                                    </div>
                                                                    <div class="col-md-1 col-2" >
                                                                        <a href={{a2FormUrl}}><img src="assets/images/icon/view-document.png" class="w-h-20"></a>
                                                                    </div>
                                                                    <div class="col-md-1 col-2">
                                                                        <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>

                                                
                                            </div>

                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <li class="nav-item" ngbNavItem>
                                <a ngbNavLink class="nav-link d-flex py-75" id="summary" data-toggle="pill" aria-expanded="false" role="tab">
                                    <i data-feather="file" class="font-medium-3 mr-1"></i>
                                    <span class="fs-12"> Summary </span> &nbsp;
                                    <img src="assets/images/icons/correct.png" width="16" height="16" *ngIf="orderDetails?.orderStatusGroup?.status == 'COMPLETED' ">
                                    <img src="assets/images/icons/warning.png" width="16" height="16" *ngIf="orderDetails?.orderStatusGroup?.status != 'COMPLETED' ">


                                </a>
                                <!-- <hr class="dotted "> -->

                                <ng-template ngbNavContent>
                                    <div class="tab-pane collapse-icon" id="faq-signed-A2-Form" role="tabpanel" aria-labelledby="signed-A2-Form" aria-expanded="false" style="padding-left: 3%;  ">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <span style="color: #00CCEE; font-weight: bolder;" class="fs-16 mb-20"> Your
                                                Order ID is # {{orderDetails?.orderId}}</span>


                                                <div class="col-md-10 mt-20 vcip-alert-box" *ngIf="!isVcipComplete && orderDetails.sellType!='BUY'">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div>
                                                                Your VKYC is pending and needs to be completed to Process the Transaction.
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <button class="btn btn-primary fs-10  w-100" rippleEffect (click)="initiateVcip()">
                                                                Complete VKYC
                                                                </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-12 pl-0 mt-20">
                                                    <div class="dark-blue-title fs-16 fw-600">Order Status</div>
                                                </div>
                                                
                                               <div class="col-md-12 mt-30 mb-30 pl-0">
                                                    <ul class="timeline">
                                                        <li class="li complete">
                                                            <div class="status">
                                                                <div class="icon text-center">
                                                                    <img src="assets/images/icons/orderRecieved1.png" style="width: 20px; height: 20px;">
                                                                </div>
                                                                <h4 class="mt-15"> Order Received <br><span class="date">
                                                                    {{orderDetails?.orderCreateDate | date:'hh:mm a, dd MMM yyyy'}}</span></h4>
                                                            </div>
                                                        </li>
                                                        <li [ngClass]="orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED' ? 'li' : 'li complete'">
                                                            <div class="status">
                                                                <div class="icon text-center">
                                                                    <img src="assets/images/icons/paymentStatus.png" style="width: 20px; height: 20px;" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED'">
                                                                    <img src="assets/images/icons/paymentStatus1.png" style="width: 20px; height: 20px;" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment == 'COMPLETED'">
                                                                </div>
                                                                <h4 class="mt-15 text-center"> Payment Status <br>
                                                                    <span class="text-danger" style="font-size: smaller;" *ngIf="orderDetails?.orderStatusGroup?.consumerPayment != 'COMPLETED' ">{{orderDetails?.orderStatusGroup?.consumerPayment}}</span>
                                                                    <span *ngIf="orderDetails?.orderStatusGroup?.consumerPayment == 'COMPLETED' " style="color: #00CCEE; font-size: smaller;">{{orderDetails?.orderStatusGroup?.consumerPayment}}</span>
                                                                </h4>
                                                            </div>
                                                        </li>
                                                        <li [ngClass]="orderDetails?.remittanceStatus != 'COMPLETED' ? 'li' : 'li complete'">
                                                            <div class="status">
                                                                <div class="icon text-center">
                                                                    <img src="assets/images/icons/remittanceStatus.png" style="width: 20px; height: 20px;" *ngIf="orderDetails?.remittanceStatus != 'COMPLETED'">
                                                                    <img src="assets/images/icons/remittanceStatus1.png" style="width: 20px; height: 20px;" *ngIf="orderDetails?.remittanceStatus == 'COMPLETED'">
                                                                </div>
                                                                <h4 class="mt-15 text-center" *ngIf="orderDetails?.orderItems[0]?.product == 'REMITTANCE' ">
                                                                    Remittance Status <br><span class="text-danger" style="font-size: smaller;" *ngIf="orderDetails?.orderStatusGroup?.status != 'COMPLETED' ">{{orderDetails?.orderStatusGroup?.status}}</span>
                                                                    <span *ngIf="orderDetails?.remittanceStatus == 'COMPLETED' " style="color: #00CCEE; font-size: smaller;">{{orderDetails?.orderStatusGroup?.status}}</span>
                                                                </h4>
                                                                <h4 class="mt-15" *ngIf="orderDetails?.orderItems[0]?.product != 'REMITTANCE' ">
                                                                    Transaction Status <br><span class="text-danger" style="font-size: smaller;" *ngIf="orderDetails?.orderStatusGroup?.status != 'COMPLETED' ">{{orderDetails?.orderStatusGroup?.status}}</span>
                                                                    <span *ngIf="orderDetails?.remittanceStatus == 'COMPLETED' " style="color: #00CCEE; font-size: smaller;">{{orderDetails?.orderStatusGroup?.status}}</span>
                                                                </h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div class="col-md-1 col-xl-1 col-lg-1"></div>
                                            <div class="col-md-4">
                                                <order-amount-summary> </order-amount-summary>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <!-- <img src="assets/images/icons/correct.png" width="25" height="25" style="padding: 0%;" *ngIf="orderDetails?.departureDate && orderDetails?.orderItems[0]?.product != 'REMITTANCE'">
                            <img src="assets/images/icons/warning.png" width="25" height="25" *ngIf="!orderDetails?.departureDate && orderDetails?.orderItems[0]?.product != 'REMITTANCE'"> -->





                            <!-- <img src="assets/images/icons/correct.png" width="25" height="25" *ngIf="transactionUploaded">
                            <img src="assets/images/icons/warning.png" width="25" height="25" *ngIf="!transactionUploaded"> -->


                        </ul>
                    </div>
                    <div [ngbNavOutlet]="nav" style="width:100%"></div>
                </div>
            </div>
        </div>



    </div>
</div>