import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import moment from 'moment';
import { TravelInsuranceOrderRequest } from '../../model/insurance.model';

@Component({
  selector: 'insurance-plan',
  templateUrl: './insurance-plans.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
    './insurance-plans.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsurancePlansComponent implements OnInit {
  public planDetails: any;
  public modalReference: any;
  public insuranceDetails: any;
  public insuranceRequest: any;
  public plans: any;
  public ageLimit: any;
  public dayLimit: any;
  public showLoader: boolean = false;
  public pageTitle: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public travelInsuranceOrderRequest = new TravelInsuranceOrderRequest();
  public isAgree: boolean = false;
  public coverageDetails: any;
  // public coveragePDFURL = "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/PremiumCoverages/AnnualSmartPlan.pdf";
  public tncUrl = "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/Terms%26Conditions/TermsAndConditions.pdf";

  public filterOptions: any;
  public companyNameItems: Array<string> = [];
  public planNameItems = [];
  public sumInsuredItems = [];
  public planType;
  public sumInsured;
  public companyName;
  public modalReferenceForFilter: any;
  public noInsuranceFound: boolean = false;
  public sortAsc = true;

  constructor(
    public insuranceService: InsuranceService,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.insuranceDetails = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_RESPONSE));
    this.insuranceRequest = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_REQUEST));
    this.pageTitle = sessionStorage.getItem(ConstantsKey.INSURANCE_TYPE);
    this.dayLimit = this.insuranceRequest.NoOfDaysTravel;

    if (this.insuranceRequest?.dob) {
      const convertAge = new Date(this.insuranceRequest.dob);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.ageLimit = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }

    this.getPlanDetails(true);
  }

  goToCart(selectedPlanDetails) {
    if (selectedPlanDetails?.isChecked) {
      this.showLoader = true;
      this.travelInsuranceOrderRequest.ordercartdetailsId = this.insuranceDetails.cartOrderId;
      this.travelInsuranceOrderRequest.agelimit = selectedPlanDetails.ageLimit;
      this.travelInsuranceOrderRequest.noOfDaysTravel = selectedPlanDetails.nOfDaysTravel;
      this.travelInsuranceOrderRequest.premium = selectedPlanDetails.premium;
      this.travelInsuranceOrderRequest.consumerId = this.insuranceRequest.consumerId;
      this.travelInsuranceOrderRequest.paxId = this.insuranceRequest.paxId;
      this.travelInsuranceOrderRequest.orderformdetailsId = this.insuranceDetails.orderformdetailsId;
      this.travelInsuranceOrderRequest.planName = selectedPlanDetails.planName;
      this.travelInsuranceOrderRequest.productId = selectedPlanDetails.planCode;
      this.travelInsuranceOrderRequest.sumInsuranceAmount = selectedPlanDetails.sumInsured;
      this.travelInsuranceOrderRequest.agreement = true;

      this.insuranceService.buyTravelInsurancePremium(this.travelInsuranceOrderRequest).subscribe(resp => {
        var responseJson = resp.body;
        console.log("RESPONSE JSON >>>>", responseJson);
        sessionStorage.setItem(ConstantsKey.INSURANCE_CART_DETAILS, JSON.stringify(responseJson));
        this.router.navigate(['/insurance-cart', this.travelInsuranceOrderRequest.ordercartdetailsId]);
      });
    } else {
      this.toast.error('Kindly agree to terms and conditions!');
    }
  }

  getPlanDetails(fromNgOnInit) {
    var companyName = (this.companyName) ? this.companyName : '';
    var planType = (this.planType) ? this.planType : '';
    var sumInsured = (this.sumInsured) ? this.sumInsured : '';
    var isStudent = (this.pageTitle == 'Leisure') ? false : true;
    let insuranceOrderId = this.insuranceDetails.cartOrderId;
    if (this.modalReferenceForFilter) {
      this.modalReferenceForFilter.close();
    }
    this.showLoader = true;
    this.insuranceService.getInsurancePlans(this.ageLimit, this.dayLimit, companyName, planType, sumInsured, isStudent, insuranceOrderId).subscribe(resp => {
      this.showLoader = false;
      this.planDetails = resp;
    }, error => {
      this.showLoader = false;
      this.planDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      if (this.planDetails.length == 0) {
        this.noInsuranceFound = true;
      }
      console.log(JSON.stringify(this.planDetails));

      if (fromNgOnInit) {
        this.buildFilteredValuesTravelInsurancePremiumList();
      }
    });
  }

  getCoverageDetails(selectedPlanDetails, planDetailsModal) {
    this.modalReference = this.modalService.open(planDetailsModal, { centered: true });
    this.insuranceService.getTravelInsuranceCoverageDetails(selectedPlanDetails.planCode).subscribe(resp => {
      this.coverageDetails = resp.body;
    }, error => {
      this.coverageDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log(this.coverageDetails);
    });
  }

  buildFilteredValuesTravelInsurancePremiumList() {
    let companyNameItemsLocal = [];
    let planNameItemsLocal = [];
    let sumInsuredItemsLocal = [];

    this.planDetails.forEach(function (singlePlanDetails) {
      companyNameItemsLocal.push(singlePlanDetails.companyName);
      planNameItemsLocal.push(singlePlanDetails.planName);
      sumInsuredItemsLocal.push(singlePlanDetails.sumInsured);
    });

    this.companyNameItems = companyNameItemsLocal.filter((n, i) => companyNameItemsLocal.indexOf(n) === i);
    this.planNameItems = planNameItemsLocal.filter((n, i) => planNameItemsLocal.indexOf(n) === i);
    this.sumInsuredItems = sumInsuredItemsLocal.filter((n, i) => sumInsuredItemsLocal.indexOf(n) === i);
  }

  openFilterModal(filterModal) {
    if (this.noInsuranceFound) {
      this.noInsuranceFound = false;
    }
    this.modalReferenceForFilter = this.modalService.open(filterModal, { centered: true });
  }

  clearFilters() {
    this.companyName = null;
    this.planType = null;
    this.sumInsured = null;
    this.noInsuranceFound = false;
    this.getPlanDetails(true);
  }

  clickToCheckTermAndCondition(singlePlan, i) {
    if (singlePlan?.isChecked) {
      singlePlan.isChecked = false;
    } else {
      singlePlan.isChecked = true;
    }

    this.planDetails[i] = singlePlan;
  }

  getDataForSort() {
    this.sortAsc = !this.sortAsc;
    if (this.sortAsc) {
      this.planDetails = this.planDetails.sort((a, b) => a['premium'] - b['premium']);
    } else {
      this.planDetails = this.planDetails.sort((b, a) => a['premium'] - b['premium']);
    }
  }

  getcoveragePdfUrl(planName) {
    if (planName.includes("IC Smart Annual")) {
      return "assets/insuranceFiles/AnnualSmart.pdf";
    } else if (planName.includes("IC Smart")) {
      return "assets/insuranceFiles/FITSmart.pdf";
    } else if (planName.includes("IC Style Annual")) {
      return "assets/insuranceFiles/AnnualStyle.pdf";
    } else if (planName.includes("IC Style")) {
      return "assets/insuranceFiles/FITStyle.pdf";
    } else if (planName.includes("IC Student Smart") || planName.includes("IC Stu Smart")) {
      return "assets/insuranceFiles/StudentSmart.pdf";
    } else if (planName.includes("IC Student Style") || planName.includes("IC Stu Style")) {
      return "assets/insuranceFiles/StudentStyle.pdf";
    }
    return "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/PremiumCoverages/AnnualSmartPlan.pdf";
  }
}
