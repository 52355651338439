import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster, SinglePurposeItem, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { CartItemCreateRequestList, QuotationCurrenciesRequestModel, QuotationRequestModel, RemittanceStep01Model } from '../../../models/card.model';
import { CardService } from '../../../services/card.service';

@Component({
  selector: 'buy-new-card',
  templateUrl: './buy-new-card.component.html',
  styleUrls: [
    // '../../../../../../assets/scss/select.component.scss',
    // '../../../../../../@core/scss/base/pages/app-ecommerce-details.scss',
    '../../../../remittance/stepper.scss',
    './buy-new-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class BuyNewCardComponent implements OnInit {

  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public currencyItems: any;
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public selectedPurposeItem: any;
  public cartType: any;
  public purposeMenu: Array<SinglePurposeItem> = PurposeList.purpose;
  public showLoader: boolean = false;

  public addCurrencyTocard: FormGroup;
  public showSelectedCard: boolean = true;
  public promoCodeList = [];
  public consumerPanSelf: string;
  public currencyCountryList: any;
  public currencySelected: any;
  public currencySelectedObj: any;
  public currencyAmount;
  public showPricing: boolean = false;
  public quotationCurrencyResponsesList = [];
  public remittanceStep01: RemittanceStep01Model = new RemittanceStep01Model();
  public tcsPaymentSource = 'OWN';
  public loading = false;
  public quotationRequestModel: QuotationRequestModel;
  public quotationResponseModel: any;
  public cartCurrenciesList = new Array<QuotationCurrenciesRequestModel>();
  public quotationSnapshotId: any;
  public quotationSnapshotDetails: any;
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public travellerList = [];

  public showMoreContents: boolean = false;
  public buttonText = 'View More';
  public consumerDetail: any;
  public vcipStatusDetails: any;
  public vcipMessage: any;
  public vcipAccepted: any;
  public showButtonOnVCIP: boolean = false;

  constructor(
    private masterDataService: MasterDataService,
    private cardService: CardService,
    public router: Router,
    public toast: ToastrService,
    public updateProfileService: UpdateProfileService,
  ) {
    this.quotationCurrencyItem.currencyCode = "USD";
  }

  ngOnInit() {
    this.getRateRange();
    this.getVCIPStatus();
    this.getProfileDetails();
  }

  getRateRange() {
    this.masterDataService.getCurrentRateListCard().subscribe(resp => {
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
      this.currencyItems = this.singleRateResItem[0].rates;
    });
  }

  showHidePricingRange(showPricing: boolean) {
    this.showPricing = showPricing;
  }

  getCurrencyFields() {
    this.currencyAmount = '';
    this.showPricing = false;
  }

  callQuotation() {
    this.showLoader = true;
    this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);
    if (this.currencySelected) {
      if (this.currencyAmount && this.currencyAmount >= 0) {
        let orderRequestList = [];
        let singleRequestCurrency: CartItemCreateRequestList = new CartItemCreateRequestList();
        singleRequestCurrency.currencyAmount = this.currencyAmount;
        singleRequestCurrency.currencyCode = this.currencySelectedObj.currencyCode;
        singleRequestCurrency.product = "FOREXCARD";
        singleRequestCurrency.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR)

        singleRequestCurrency.purpose = (this.remittanceStep01.purpose) ? this.remittanceStep01.purpose : 'UNIVERSITY_FEES';
        orderRequestList.push(singleRequestCurrency);

        this.quotationRequest.sellType = Constants.sellTypes[0];
        this.quotationRequest.partnerId = Constants.partnerId;
        this.quotationRequest.nostroChargeType = "REMITTER"
        this.quotationRequest.quotationCurrencies = orderRequestList;

        this.quotationRequest.pan = this.consumerPanSelf;
        this.quotationRequest.tcsPaymentSource = this.tcsPaymentSource;

        if (this.quotationResponse) {
          this.quotationRequest.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }

        this.cardService.getQuotation(this.quotationRequest).subscribe((respQuote) => {
          this.showLoader = false;

          this.quotationResponse = respQuote.body;
          this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];
          this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
        }, error => {
          this.showLoader = false;
          this.showPricing = false;
          this.toast.error(error.error.message);
        });

        this.showPricing = true;
      }
      else {
        this.showLoader = false;
        this.toast.error("Please fill in the currency amount.");
      }
    }
    else {
      this.showLoader = false;
      this.toast.error("Please fill in the currency");
    }
  }

  getSelectedCurrencyObj(currencyCode: string) {
    var returnCurrencyObj: any;
    this.currencyItems.forEach(singleCurreny => {
      if (singleCurreny.currencyCode == currencyCode) {
        returnCurrencyObj = singleCurreny;
      }
    });
    return returnCurrencyObj;
  }

  async proceedToBuySelectedCard() {
    var vcipKycStatus = this.consumerDetail?.vcipKyc;
    if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
      vcipKycStatus = true;
    } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
      vcipKycStatus = true;
    }
    if (vcipKycStatus) {
      this.currencySelectedObj = this.getSelectedCurrencyObj(this.currencySelected);

      this.quotationRequestModel = new QuotationRequestModel();
      this.quotationRequestModel.sellType = Constants.sellTypes[0];
      this.quotationRequestModel.partnerId = Constants.partnerId;

      let quotationCurrencies = new QuotationCurrenciesRequestModel();
      quotationCurrencies.bidRate = +JSON.stringify(this.currencySelectedObj.minSalePriceInINR);
      quotationCurrencies.currencyAmount = this.currencyAmount;
      quotationCurrencies.currencyCode = this.currencySelectedObj.currencyCode;
      quotationCurrencies.product = Constants.productsEnum[0];
      quotationCurrencies.purpose = 'PRIVATE_VISIT';

      this.cartCurrenciesList.push(quotationCurrencies);
      localStorage.setItem('cartCurrenciesList', JSON.stringify(this.cartCurrenciesList));
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
      sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
      sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
      this.router.navigate(['/view-cart']);
    } else {
      this.toast.error('Kindly complete your profile and VCIP!');
    }
  }

  showContents() {
    this.showMoreContents = true;
    this.buttonText = 'View Less';
  }

  hideContents() {
    this.showMoreContents = false;
    this.buttonText = 'View More';
  }

  async getProfileDetails() {
    this.showLoader = true;
    var resp = await this.updateProfileService.getProfileListAll().toPromise();

    this.showLoader = false;
    this.consumerDetail = resp.body;
    console.log("this.consumerDetail >>", this.consumerDetail);

    return true;
  }

  gotoDashboard() {
    this.router.navigate(['home'])
      .then(() => {
        window.location.reload();
      });
  }

  getVCIPStatus() {
    this.showLoader = true;
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
      this.vcipStatusDetails = resp.body;
      this.vcipMessage = this.vcipStatusDetails.message;
      this.showButtonOnVCIP = this.vcipStatusDetails.showBtnOnStatus;
      if (this.vcipStatusDetails.vcipStatus == 'ACCEPTED') {
        this.vcipAccepted = true;
      } else {
        this.vcipAccepted = false;
      }
    }), error => {
      this.showLoader = false;
    });
  }
}
