import { Injectable } from "@angular/core";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { AddRelationshipModel } from "../models/relationship.model";

@Injectable()
export class RelationshipService extends BaseProvider {

    getRelationshipList() {
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'consumers/' + consumerId + "/pax";
        return this.makeGetCall(resourceUrl);
    }

    addRelationship(addRelationRequestModel) {
        var resourceUrl = 'consumers/pax';
        return this.makePostCall(resourceUrl, addRelationRequestModel);
    }

    deleteRelationship(paxId: string) {
        var resourceUrl = 'consumers/pax/' + paxId;
        return this.makeDeleteCall(resourceUrl);
    }

    getPaxesList(){
        var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
        var resourceUrl = 'consumers/' + consumerId + "/paxes";
        return this.makeGetCall(resourceUrl);
    }

    editPax(paxId: string, requestModel){
        var resourceUrl = 'consumers/pax/' + paxId;
        return this.makePatchCall(resourceUrl,requestModel);
    }
 
}