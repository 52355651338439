<div class="content-wrapper container-xxl p-0">
    <div class="content-body card card-body">

        <div class="row">
            <div class=" col-md-3">
                <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" style="margin-bottom:25px; ">
                    <li ngbNavItem style="padding: 5px;">
                        <a ngbNavLink class="fs-11" (click)="changeTab('BUY')">Buy Forex</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <form class="form-horizontal" (ngSubmit)="setRateAlert(SetRateAlertForm.form.valid)" #SetRateAlertForm="ngForm">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" name="forexCheckbox" [(ngModel)]="forexCheckbox" />
                                                    <label class="custom-control-label fs-13" for="customCheck1">Forex</label>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck2" name="cashCheckbox" [(ngModel)]="cashCheckbox" />
                                                    <label class="custom-control-label fs-13" for="customCheck2">Cash</label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck3" name="sendMoneyCheckbox" [(ngModel)]="sendMoneyCheckbox" />
                                                    <label class="custom-control-label fs-13" for="customCheck3">Send Money Abroad</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-15">
                                            <div class="form-group col-md-3">
                                                <label class="form-label" for="country">Select Currency*</label>
                                                <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency" name="countryList" #countryRef="ngModel" [(ngModel)]="rateAlertCreateRequest.currencyCode" [class.error]="SetRateAlertForm.submitted && countryRef.invalid"
                                                    required [searchable]="false">
                                                    <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                    </ng-template>
                                                </ng-select>
                                                <span *ngIf="SetRateAlertForm.submitted && countryRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="countryRef.errors.required">Please select currency !</small>
                                                </span>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label">Highest Rate*</label>
                                                <input type="number" class="form-control fs-12" placeholder="Highest Rate*" style="border-color: black;" name="highest_rate" #highestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.upperLimit" [class.error]="SetRateAlertForm.submitted && highestRateRef.invalid"
                                                    required/>
                                                <span *ngIf="SetRateAlertForm.submitted && highestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="highestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label" for="country">Lowest Rate*</label>
                                                <input type="number" class="form-control fs-12" placeholder="Lowest Rate*" style="border-color: black;" name="lowest_rate" #lowestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.lowerLimit" [class.error]="SetRateAlertForm.submitted && lowestRateRef.invalid"
                                                    required />
                                                <span *ngIf="SetRateAlertForm.submitted && lowestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="lowestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                            </div>
                                            <div class="col-md-2 mb-20 mt-20 text-right">
                                                <button type="submit" rippleEffect class="btn btn-primary fs-12">Set Rate Alert</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem style="padding: 5px;">
                        <a ngbNavLink class="fs-11" (click)="changeTab('SELL')">Sell Forex</a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <form class="form-horizontal" (ngSubmit)="setRateAlert(SetRateAlertFormForSell.form.valid)" #SetRateAlertFormForSell="ngForm">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" name="sellForexCheckbox" [(ngModel)]="sellForexCheckbox" />
                                                    <label class="custom-control-label fs-13" for="customCheck1">Forex</label>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck2" name="sellCashCheckbox" [(ngModel)]="sellCashCheckbox" />
                                                    <label class="custom-control-label fs-13" for="customCheck2">Cash</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-15">
                                            <div class="form-group col-md-3">
                                                <label class="form-label" for="country">Select Currency*</label>
                                                <!-- <ng-select class="fs-12" [clearable]="false" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" placeholder="Select Country" name="countryList" #countryRef="ngModel" [(ngModel)]="setRateAlertRequestModel.country" [class.error]="SetRateAlertFormForSell.submitted && countryRef.invalid"
                                                    [searchable]="false" required>
                                                </ng-select> -->
                                                <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency" name="countryList" #countryRef="ngModel" [(ngModel)]="rateAlertCreateRequest.currencyCode" [class.error]="SetRateAlertFormForSell.submitted && countryRef.invalid"
                                                    required [searchable]="true">
                                                    <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                        <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                                    </ng-template>
                                                </ng-select>
                                                <span *ngIf="SetRateAlertFormForSell.submitted && countryRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="countryRef.errors.required">Please select currency !</small>
                                                </span>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label">Highest Rate*</label>
                                                <input type="text" class="form-control fs-12" placeholder="Highest Rate*" name="highest_rate" #highestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.upperLimit" [class.error]="SetRateAlertFormForSell.submitted && highestRateRef.invalid" required/>
                                                <span *ngIf="SetRateAlertFormForSell.submitted && highestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="highestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label" for="country">Lowest Rate*</label>
                                                <input type="number" class="form-control fs-12" placeholder="Lowest Rate*" name="lowest_rate" #lowestRateRef="ngModel" [(ngModel)]="rateAlertCreateRequest.lowerLimit" [class.error]="SetRateAlertFormForSell.submitted && lowestRateRef.invalid" required
                                                />
                                                <span *ngIf="SetRateAlertFormForSell.submitted && lowestRateRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="lowestRateRef.errors.required">Please enter rate !</small>
                                                </span>
                                            </div>
                                            <div class="col-md-3 col-3 mb-20  mt-20">
                                                <button type="submit" rippleEffect class="btn btn-primary fs-12">Set Rate Alert</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div [ngbNavOutlet]="navFilled" class="col-md-12 fs-12 text-black20">
                <!-- <loader *ngIf="showLoader"></loader> -->
            </div>
        </div>
    </div>
</div>