import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InitiatePaymentforCartRequest, QuotationRequestModel } from 'app/modules/card/models/card.model';
import { StepperDataCardModel, TripInfoRequestModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { MasterDataService } from 'app/shared/services/master-data.service';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'card-stepper-email',
  templateUrl: './card-stepper-email.component.html',
  styleUrls: [
    '../../../../remittance/stepper.scss',
    // '../../../../../../@core/scss/base/pages/app-invoice.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class EmailCardStepperComponent implements OnInit {
  public return_date: NgbDateStruct;
  public departure_date: NgbDateStruct;
  private horizontalWizardStepper: Stepper;
  public selectedCountry: any;
  public countryListItems: any;
  public financialInstitutionItem: any;

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;

  public cartItemsResponse: any;
  public tripInfoRequestModel: TripInfoRequestModel = new TripInfoRequestModel();
  public cartType: string;
  public getRefCartId: string;

  public quotationCurrencyResponsesList = [];
  public quotationSnapshotId: any;
  public isCalculateTCS: boolean = true;
  public isTCSApplicable: boolean = true;
  public showLoader: boolean = false;

  public modalReference: any;
  public paymentInstruction: any;
  public paymentMode: any;

  public paymentMethodsList = [];
  public banksList = [];
  public payScript: string;
  public initiatePaymentforCartRequest = new InitiatePaymentforCartRequest();
  public selectedPayMode = "CARD";

  public loanDocumentUploadedUrl: string;
  public hideProceedButton: boolean = false;
  public showDiligenceForm: boolean = false;
  public bankAccountId:any;
  public isPartial : boolean = false;
  
  public departure: any;
  public cardDeliveryDate: any;

  constructor(
    private router: Router,
    public masterDataService: MasterDataService,
    private modalService: NgbModal,
    public cardService: CardService,
    public toast: ToastrService,
    private remittanceService: RemittanceService
  ) { }

  ngOnInit() {
    this.getCountryList();
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this.getSessionData();
    this.getPaymentMode();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
      if (this.stepperDataCardModel?.ofacCardData?.isOfacRequired) {
        this.showDiligenceForm = true;
      }
    }
  }

  parentCallbackFormChild($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.hideProceedButton = this.stepperDataCardModel.step01Data.hideProceedButton;
    }

    if ($event == 'REFRESH_DATA_UPDATE_QUOTATION') {
      this.callQuotation();
    }
  }

  async mapTripDetailsStep01() {
    ///get session data
    this.getSessionData();
    this.tripInfoRequestModel = this.stepperDataCardModel.step01Data;
    this.departure = this.tripInfoRequestModel.departure;
    if (this.validateStep01()) {
      this.tripInfoRequestModel.purpose = "PRIVATE_VISIT";
      this.showLoader = true;

      this.cardService.mapTripDetails(this.tripInfoRequestModel).subscribe(async (respTripData) => {
        this.showLoader = false;

        var step1Result = JSON.parse(JSON.stringify(respTripData.body));
        this.cartType = step1Result.cartDetails.cartType;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        await this.updateStepNo(2);
        this.horizontalWizardStepper.next();
      }, (error) => {
        this.showLoader = false;
        this.toast.error(error.error.message);

        console.log("step1Result ", error);
      });
    }
  }

  validateStep01() {
    if (this.tripInfoRequestModel) {
      if (!Object.keys(this.tripInfoRequestModel).length) {
        this.toast.error('Please enter all required data.');
        return false;
      }

      if (!this.tripInfoRequestModel.departure) {
        this.toast.error('Please enter departure date.');
        return false;
      }

      if (!this.tripInfoRequestModel.returnDate) {
        this.toast.error('Please enter return date.');
        return false;
      }

      if (!this.tripInfoRequestModel.subPurpose) {
        this.toast.error('Please select purpose.');
        return false;
      }

      if (!this.tripInfoRequestModel.travellingCountryCode) {
        this.toast.error('Please select travelling country.');
        return false;
      }
    } else {
      this.toast.error('Please enter all required data.');
    }

    return true;
  }

  mapPayerDetailsStep02() {
    this.getSessionData();
    var payerTravelerRequestModel = this.stepperDataCardModel.step02Data.payerTravelerRequestModel;
    this.cardDeliveryDate = this.stepperDataCardModel.step02Data.payerTravelerRequestModel.deliveryRequest.scheduledDate;
    if (this.validateStep02()) {
      this.showLoader = true;
      this.cardService.mapPayerTraveller(payerTravelerRequestModel).subscribe(async (respPayer) => {
        this.showLoader = false;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        await this.updateStepNo(3);
        this.horizontalWizardStepper.next();
        // this.horizontalWizardStepper.next(); /// SKIP DOCUMENT UPLOAD
      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }
  }

  validateStep02() {
    if (!this.stepperDataCardModel.step02Data || !Object.keys(this.stepperDataCardModel.step02Data).length) {
      this.toast.error('Please select source of fund.');
      return false;
    }

    if(this.departure < this.cardDeliveryDate) {
      this.toast.error('Card Delivery date should not exceed then date of departure');
      return false;
    }

    if (!this.stepperDataCardModel.step02Data?.paymentSourceDetails) {
      this.toast.error('Please select source of fund.');
      return false;
    }
    if (this.cartType == 'CARD_PURCHASE') {
      var deliveryRequestMap = this.stepperDataCardModel.step02Data.payerTravelerRequestModel.deliveryRequest;
      // if (Object.keys(deliveryRequestMap).length == 0) {
      //   this.toast.error('Please select mandatory fields');
      //   return false;
      // } 

      if (!deliveryRequestMap.deliveryAddressType) {
        this.toast.error('Please select delivery address type and address also');
        return false;
      }

      if (!deliveryRequestMap.address) {
        this.toast.error('Please select address');
        return false;
      }

      if (!deliveryRequestMap.scheduledDate) {
        this.toast.error('Please select delivery date');
        return false;
      }

      if (!deliveryRequestMap.scheduledTime) {
        this.toast.error('Please select time slot');
        return false;
      }
    }

    return true;
  }

  checkDocumentUplodStep03() {
    this.stepperDataCardModel.kycDocumentUploadStatus
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      if (this.stepperDataCardModel?.kycDocumentUploadStatus) {
        this.horizontalWizardStepper.next();
      } else {
        this.toast.error('Please upload all mandatory documents.');
      }
    }
  }

  validateStep03() {
    if (!this.stepperDataCardModel.step03Data || !Object.keys(this.stepperDataCardModel.step03Data).length) {
      this.toast.error('Please accept the terms and conditions to proceed.');
      return false;
    }

    if (!this.stepperDataCardModel?.step03Data?.checkboxChecked) {
      this.toast.error('Please accept the terms and conditions to proceed.');
      return false;
    }

    if (!this.stepperDataCardModel?.step03Data?.otpValue) {
      this.toast.error('Please enter the OTP received.');
      return false;
    }

    return true;
  }

  downloadA2FormStep04() {
    this.getSessionData();
    // this.showLoader = true;
    this.horizontalWizardStepper.next();
    if (this.validateStep03()) {
      this.showLoader = true;
        let a2AcceptanceRequest = {
          "otp": this.stepperDataCardModel?.step03Data?.otpValue
        };
        this.remittanceService.getA2FormCart(a2AcceptanceRequest).subscribe((respDownloadA2) => {
          this.showLoader = false;
          var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
          if (jsonResult.cartDetails.a2FormUrl) {
            let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
            window.open(a2FormUrl, "_blank");
          }
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.updateStepNo(4);
          this.horizontalWizardStepper.next();
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
    }
  }


  step04Payment() {
    this.getSessionData();
    this.paymentMode = this.stepperDataCardModel.step04Data.paymentMode;
    this.bankAccountId = this.stepperDataCardModel.step04Data.bankAccountId;

    this.modalReference.close();
    var paymentMode = this.paymentMode;
    this.remittanceService.cartPlaceOrder(this.bankAccountId, paymentMode).subscribe(respPayment => {
      var respPaymentBody = JSON.parse(JSON.stringify(respPayment.body));
      // this.showLoader = false;
      console.log("respPaymentBody cart place order >", respPaymentBody);
      if (paymentMode == PaymentMode.NET_BANKING) {
        this.getPaymentGatewayLink(respPaymentBody.orderId);
      } else {
        this.router.navigate(['/payment-success', respPaymentBody.orderId]);
      }
    }, error => {
      // this.showLoader = false;
      this.toast.error(error.error.message);
    })
  }

  getPaymentGatewayLink(orderId: string) {
    this.remittanceService.getPaymentGatewayLink(orderId, this.isPartial,this.bankAccountId).subscribe(respPaymentLink => {
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      window.location.href = responsePaymentLinkBody.payment_link;
    }, error => {
      console.log("Error in generating payment link", error);
    });
  }

  proceedForPayment() {
    this.quotationSnapshotId = this.quotationResponse.quotationSnapshotId;
    console.log("this.quotationResponse >>", this.quotationResponse);

    if (this.selectedPayMode == "NB") {
      this.initiatePaymentforCartRequest.amount = this.stepperDataCardModel.quotationResponse.totalBillableAmountString;
      this.initiatePaymentforCartRequest.paymentMedium = "B2C_WEB";
      this.initiatePaymentforCartRequest.payertype = this.cartItemsResponse.cartPayer.paidBy;
      this.initiatePaymentforCartRequest.agreement = "true";
      this.cardService.initiatePaymentforCart(this.initiatePaymentforCartRequest, this.quotationSnapshotId).subscribe((respPay) => {
        var jsonResult = JSON.parse(JSON.stringify(respPay.body));
        localStorage.setItem("OrderId", jsonResult.orderId);
        console.log(jsonResult.redirectUrl);
        this.initiatePG(jsonResult.redirectUrl);
      });
    } else if (this.selectedPayMode == "CARD") {
      this.initiatePaymentforCartRequest.amount = this.stepperDataCardModel.quotationResponse.totalBillableAmountString;
      this.initiatePaymentforCartRequest.paymentMedium = "B2C_WEB";
      this.initiatePaymentforCartRequest.paymentModeCategoryId = this.paymentMethodsList[0]["paymentModeCategories"][0]["id"];
      this.initiatePaymentforCartRequest.payertype = this.cartItemsResponse.cartPayer.paidBy;
      this.initiatePaymentforCartRequest.agreement = "true";
      // this.loading = true;
      this.cardService.initiatePaymentforCart(this.initiatePaymentforCartRequest, this.quotationSnapshotId).subscribe((respPay) => {
        // this.loading = false;
        let jsonResult = JSON.parse(JSON.stringify(respPay.body));
        localStorage.setItem("OrderId", jsonResult.orderId);
        console.log(jsonResult.redirectUrl);
        this.initiatePG(jsonResult.redirectUrl);
      });
    } else {
      this.toast.error("Please select the mode of payment.");
    }
  }

  initiatePG(pgURL) {
    const url = pgURL;
    this.cardService.makePGCall(url).subscribe((respPgCall) => {
    }, (error) => {
      //ERROR
    });

    const payScript = "var form = document.getElementById('ts-app-payment-form-redirect');";
    this.payScript = payScript;
    const iframe = document.getElementById("pgContainer");
    const script = document.createElement("script");
    script.type = "text/javascript";
    localStorage.setItem("pgRedirect", "true");
    script.text = payScript;
    iframe.appendChild(script);
    console.log(payScript);
  }

  /// call this when mode is N
  getPaymentMode() {
    this.cardService.getPaymentMethods().subscribe((respPayMethods) => {
      let jsonResult = JSON.parse(JSON.stringify(respPayMethods.body));
      var netBankingPayMode = jsonResult.filter((payment) => payment.paymentMode === "NB");
      this.banksList = netBankingPayMode[0].paymentModeCategories;
      this.paymentMethodsList = jsonResult;
    });

    // if (payMode == "NB") {
    //   this.showListNatbaking = true;
    // } else {
    //   this.showListNatbaking = false;
    // }
  }

  async updateStepNo(stepNo: number) {
    const response = await this.cardService.getCartToUpdateStepNumber(stepNo).toPromise();
    this.cartItemsResponse = response.body;
    this.getRefCartId = this.cartItemsResponse['cartDetails']['refOrderId'];
    this.getSessionData();
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.stepperDataCardModel.cartItemsResponse = this.cartItemsResponse;
    }
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    return response;
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  callQuotation() {
    this.getSessionData();

    var quotationRequest = this.stepperDataCardModel.quotationRequest;
    quotationRequest.paymentSourceDetails = this.stepperDataCardModel?.step02Data?.paymentSourceDetails;
    quotationRequest.calculateTcs = this.isCalculateTCS;

    this.cardService.getQuotation(quotationRequest).subscribe((respQuote) => {
      var quotationResponse = JSON.parse(JSON.stringify(respQuote.body));
      this.quotationCurrencyResponsesList = quotationResponse["quotationCurrencyResponses"];
      this.stepperDataCardModel.quotationResponse = quotationResponse;

      if (this.isCalculateTCS && quotationResponse["tcs"]["tcsAmountString"] != "0.00") {
        this.isTCSApplicable = true;
      } else {
        this.isTCSApplicable = false;
      }

      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

    }, (error) => {
      console.log("Error callQuotation >", error);
    });
  }

  horizontalWizardStepperNext(data) {
    if (data.form.valid === true) {
      this.horizontalWizardStepper.next();
    }
  }

  horizontalWizardStepperPrevious() {
    this.horizontalWizardStepper.previous();
  }

  openModalForPaymentInfo(paymentInstructionModal) {
    this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
    if (this.paymentMode == PaymentMode?.NET_BANKING) {
      this.paymentInstruction = "Please note, you are requested to upload a proof of your payment in the Order Details section of My Orders, if you do choose to proceed with this mode of payment.\n";
    } else if(this.paymentMode == PaymentMode?.NEFT_RTGS || this.paymentMode == PaymentMode?.TPV) {
      this.paymentInstruction = "Please note, if a payment is not received within 4 hours, your order will stand cancelled. Please do contact customer care at +91 89767 07222 for any queries.\n";
    } else if(this.paymentMode != PaymentMode?.NET_BANKING || this.paymentMode != PaymentMode?.NEFT_RTGS ||
      this.paymentMode != PaymentMode?.TPV) {
      this.paymentInstruction = "Please select payment mode."
    }
  }

  onSubmit() {
    this.modalReference.close();
    this.router.navigate(['/card-payment-success']);
  }


}
