import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'insurance-retry-payment',
  templateUrl: './insurance-retry-payment.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
  '../insurance-payment/insurance-payment.component.scss',
  '../../../../../@core/scss/base/pages/app-invoice.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceRetryPaymentComponent implements OnInit {

  public bankAccountList: any;
  public showLoader: boolean = false;
  public showAddBankForm: boolean = false;
 
  public insuranceId: any;
  public orderPaymentStatus;
  public policyStatus;
  public insuranceDetails: any;
  public paymentStatus : any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public orderStatus : any;
  public paymentMode: any;
  public plans: any;
  public paymentMessage1 = "Please make the payment to the above mentioned account.";
  public paymentMessage2 = "Kindly note, funds transfer to be done from your registered bank account only.";
  public paymentMessage3 = "Once fund transfer made kindly update fund transfer details in payment section of order details page.";
  public bankAccountId: any;
  
  constructor(
    private router: Router,
    public remittanceService: RemittanceService,
    public toast: ToastrService,
    private route: ActivatedRoute, 
    public insuranceService: InsuranceService,
  ) {
    this.insuranceId = this.route.snapshot.paramMap.get('ordercartdetailsId');
    console.log("this.insuranceId", this.insuranceId);
    // this.insuranceDetails = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_CART_DETAILS));
    // this.orderPaymentStatus = sessionStorage.getItem('INSURANCE_PAYMENT_STATUS')
    // this.policyStatus = sessionStorage.getItem('INSURANCE_ORDER_STATUS')
    // console.log("this.insuranceDetails", this.insuranceDetails);
    // console.log("this.orderPaymentStatus", this.orderPaymentStatus);
    // console.log("this.policyStatus", this.policyStatus);
    // if(this.insuranceDetails){
    //   this.insuranceId = this.insuranceDetails.ordercartdetailsId;
    // }
  }

  ngOnInit() {
    this.getInsuranceDetails();
    this.getBankAccountList();
    this.getCartDetails();
  }

  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getInsuranceDetails() {
    this.showLoader = true;
    this.insuranceService.getTravelInsuranceCartDetails(this.insuranceId).subscribe(respInsuranceData => {
      var responeJson = respInsuranceData.body;
      this.showLoader = false;
      console.log("this.insuranceDetail >>>>>> ",responeJson);

    }, error => {
      this.plans = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log("this.this.plans  >>>>>> ",this.plans );

      this.showLoader = false;
      // let orderDetailObj = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_CART_DETAILS));
      // this.orderPaymentStatus = orderDetailObj?.paymentStatus;
      // this.policyStatus = orderDetailObj?.orderStatus;
    });
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    }, error => {
    });
  }
 
  getCartDetails() {
    console.log(this.insuranceId);
    
    this.insuranceService.getTravelInsuranceCartDetails(this.insuranceId).subscribe(resp => {
      this.showLoader = false;
      var responeJson = resp.body;
      // this.plans = JSON.parse(this.cryptoUtils.resDecrypt(responeJson));
      console.log("responseJson>>> ", responeJson);
    }, error => {
      this.plans = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log("responseJson>>> ", this.plans );

    });
  }

  proceedForPayment() {
    var responsePayment;
    this.showLoader = true;
    if (this.paymentMode == 'NB') {
      this.insuranceService.insuranceGetPaymentGatewayLink(this.insuranceId).subscribe(resp => {
        responsePayment = resp.body;
      }, error => {
        this.showLoader = false;
        responsePayment = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        window.location.href = responsePayment.payment_link;
      });
    } else {
      this.insuranceService.insuranceCartPlaceOrderNEFT(this.paymentMode, this.insuranceId, this.plans?.totalPayableAmount).subscribe(resp => {
        this.showLoader = false;
        responsePayment = resp.body;
        this.router.navigate(['/payment-success', this.insuranceId]);
      }, error => {
        this.showLoader = false;
        this.router.navigate(['/payment-success', this.insuranceId]);
      });
    }
  }
 
}