// 
import { Injectable } from "@angular/core";
import { SinglePurposeItem } from "../models/shared-models";

@Injectable()
export class NewDashboardPurposeList {

    public static dashboardMenuList = ['STUDENT', 'OTHERS', 'FOREXCARD','CURRENCYNOTES', 'TRAVELINSURANCE', 'OPENGICACCOUNT', 'BLOCKEDACCOUNT', 'ZOLVEDCREDITCARD', 'ISICCARD'];

    public static studentMenuList = ['UniversityFees', 'UniversityFeesOwnAccount', 'StudentLivingExpenses', 'GIC', 'BlockAccount'];

    public static personalMenuList = ['TourRemittance', 'EmigrationFee', 'MedicalExpenses', 'VisaFee', 'ExaminationFees', 'JobVisit', 'SpecialisedTrainingFees', 'InternationalCompetitionFees', 'AttendingConference'];

    public static homePagePurposeList = [
        {
            'key': 'STUDENT',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/stud-remittance.png',
            'title': 'Student Remittance',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'OTHERS',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/personal-remittance.png',
            'title': 'Personal Remittance',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'FOREXCARD',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/forex-card.png',
            'title': 'Forex Card',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": 'my-card'
        },
        {
            'key': 'CURRENCYNOTES',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/CurrencyNote.png',
            'title': 'Currency Notes',
            'isActive': false,
            "purposeCode": "PRIVATE_VISIT",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": 'sell-currency-notes'
        },
        {
            'key': 'TRAVELINSURANCE',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/travel-insurance.png',
            'title': 'Travel Insurance',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'OPENGICACCOUNT',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/open-gic-acc.png',
            'title': 'Open GIC Account',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'BLOCKEDACCOUNT',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/open-blocked-acc.png',
            'title': 'Open Blocked Account',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'ZOLVEDCREDITCARD',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/zolve-credit-card.png',
            'title': 'Zolve Credit Card',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            'key': 'ISICCARD',
            'icon': 'assets/images/smartfx-portal-assets/dashboard-global-pay/isic-card.png',
            'title': 'ISIC Card',
            'isActive': false,
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "UniversityFees",
            "title": "University Fees",
            "icon": "assets/images/smartfx-portal-assets/student-remittance/university-fees.png",
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "UniversityFeesOwnAccount",
            "icon": "assets/images/smartfx-portal-assets/student-remittance/university-fees-own-account.png",
            "title": "University Fees Own Account",
            "purposeCode": "UNIVERSITY_FEES_OWN_ACCOUNT",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "StudentLivingExpenses",
            "icon": "assets/images/smartfx-portal-assets/student-remittance/student-living-expenses.png",
            "title": "Student Living Expenses",
            "purposeCode": "STUDENT_LIVING_EXPENSES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "GIC",
            "title": "GIC Remittance",
            "icon": "assets/images/smartfx-portal-assets/student-remittance/open-gic-acc.png",
            "purposeCode": "GIC",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "BlockAccount",
            "title": "Blocked Account Remittance",
            "icon": "assets/images/smartfx-portal-assets/student-remittance/open-blocked-acc.png",
            "purposeCode": "BLOCK_ACCOUNT",
            "remittanceType": "STUDENT",
            "isPurpose": "false",
            "pageUrl": ''
        },
        {
            "key": "TourRemittance",
            "title": "Tour Remittance",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/tour-remittance.png",
            "purposeCode": "TOUR_REMITTANCE",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "EmigrationFee",
            "title": "Emigration",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/emigration-fees.png",
            "purposeCode": "EMIGRATION",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "MedicalExpenses",
            "title": "Medical Expenses",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/medical-expenses.png",
            "purposeCode": "MEDICAL_EXPENSES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "VisaFee",
            "title": "Visa Fee",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/visa-fees.png",
            "purposeCode": "VISA_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "ExaminationFees",
            "title": "Examination Fees",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/examination-fees.png",
            "purposeCode": "EXAMINATION_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "JobVisit",
            "title": "Job Visit",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/job-visit.png",
            "purposeCode": "JOB_APPLICATION",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "SpecialisedTrainingFees",
            "title": "Specialised Training Fees",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/specialised-training-fees.png",
            "purposeCode": "SPECIALISED_TRAINING_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "InternationalCompetitionFees",
            "title": "International Competition Fees",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/international-competition-fees.png",
            "purposeCode": "INTERNATIONAL_COMPETITION_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "AttendingConference",
            "title": "Attending Conference",
            "icon": "assets/images/smartfx-portal-assets/personal-remittance/attending-conference.png",
            "purposeCode": "ATTENDING_CONFERENCE",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl": ''
        },
        {
            "key": "BuyNewCard",
            "title": "Buy New Card",
            "icon": "assets/images/purposeIcon/buy-card.png",
            "purposeCode": "",
            "remittanceType": "FOREXCARD",
            "isPurpose": "false",
            "pageUrl": "/buy-new-card"
        },
        {
            "key": "LinkExistingCard",
            "title": "Link Existing Card",
            "icon": "assets/images/purposeIcon/payment-method.png",
            "purposeCode": "",
            "remittanceType": "FOREXCARD",
            "isPurpose": "false",
            "pageUrl": "/link-existing-card"
        },
    ];

    public static getPurposeObjectByRemittanceType(remittanceType: string) {
        let purposeSubMenu: Array<SinglePurposeItem> = [];
        NewDashboardPurposeList.homePagePurposeList.forEach(singlePurpose => {
            if (singlePurpose.remittanceType == remittanceType.toUpperCase()) {
                purposeSubMenu.push(singlePurpose);
            }
        });
        return purposeSubMenu;
    }

    public static getPurposeObjectByPurposeCodeStudentRemittance(purposeCode: string) {
        var returnPurpose: SinglePurposeItem;
        NewDashboardPurposeList.homePagePurposeList.forEach(singlePurpose => {
            if (singlePurpose.purposeCode == purposeCode) {
                returnPurpose = singlePurpose;
            }
        });
        return returnPurpose;
    }

    public static getDashboardMenu(){
        let mainMenuItems: Array<SinglePurposeItem> = [];
        NewDashboardPurposeList.homePagePurposeList.forEach(singleElement => {
             for (let i = 0; i < NewDashboardPurposeList.dashboardMenuList.length; i++) {
                if (singleElement.key == NewDashboardPurposeList.dashboardMenuList[i]) {
                    mainMenuItems.push(singleElement);
                }
             }
        });
        return mainMenuItems;
    }

    public static getStudentSubMenu(){
        let mainMenuItems: Array<SinglePurposeItem> = [];
        NewDashboardPurposeList.homePagePurposeList.forEach(singleElement => {
             for (let i = 0; i < NewDashboardPurposeList.studentMenuList.length; i++) {
                if (singleElement.key == NewDashboardPurposeList.studentMenuList[i]) {
                    mainMenuItems.push(singleElement);
                }
             }
        });
        return mainMenuItems;
    }

    public static getPersonalSubMenu(){
        let mainMenuItems: Array<SinglePurposeItem> = [];
        NewDashboardPurposeList.homePagePurposeList.forEach(singleElement => {
             for (let i = 0; i < NewDashboardPurposeList.personalMenuList.length; i++) {
                if (singleElement.key == NewDashboardPurposeList.personalMenuList[i]) {
                    mainMenuItems.push(singleElement);
                }
             }
        });
        return mainMenuItems;
    }
}