<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class="content-header-title float-left mb-0 ml-10" style="color: black">My Account</h6>
                    </div>
                    <div class="col-md-6 text-right">
                        <button  class="btn btn-primary" (click)="goToEditProfile()">
                            Edit Profile
                        </button>
                        <button *ngIf="!isVcipComplete" type="submit" style="margin-left: 15px;" rippleEffect class="btn btn-primary fs-10"
                            (click)="initiateVcip()">
                            Please Complete VCIP
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <a class="mr-25" *ngIf="consumerDetail?.photo != null && consumerDetail?.photo != '' ">
                            <img src="{{consumerDetail?.photo}}" class="b-radius50" height="70" width="70"
                                style="border: 3px solid #234;" />
                        </a>
                        <a class="mr-25" *ngIf="consumerDetail?.photo == null || consumerDetail?.photo == '' ">
                            <img src="assets/images/avatars/user.png" class="b-radius50 mr-50" height="70" width="70" />
                        </a>
                        <div class="text-black fs-12 fw-600 mt-10" style="border-color:black">
                            {{this.consumerDetail?.paxResponse?.name}}</div>
                        <div class="text-black20 fs-11" style="color: black">
                            {{this.consumerDetail?.paxResponse?.mobile}}</div>
                    </div>
                </div>

                <div class="row mt-30">
                    <div class="col-md-2" *ngFor="let singleItem of menuItems">
                        <div class="card cursor" (click)="proceedToNextPage(singleItem.url)">
                            <div class="card-body text-center">
                                <img src="assets/images/profile/{{singleItem.icon}}" style="width:40px;height:40px">
                                <div class="fs-10 fw-600 mt-10"> {{ singleItem.title }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>