export class BeneficiaryCreateRequestModel {
    country: string;
    remittanceType: string;
    name: string;
    address: string;
    bankName: string;
    bankAddress: string;
    consumerId: string;
    bankAccountNumber: string;
    confirmBankAccountNumber : string;
    swiftCode: string;
    sortCode: string;
    countryCode: string;
    additionalDetails: string;
    chargeCode: string;
    remitterBy: string;
    remittanceForRelationship: string;
    transferType: string;
    studentId: string;
    remittanceFor: string;
    payerName: string;
    payerPan: string;
    paidBy: string;
    beniDocUrl: string;
    flyWireReferenceNumber: string;
    viaAgentName: string;
    intermediaryBankName: string;
    intermediaryBankSwiftCode: string;
    city: string;
    purpose: string;
    currencyCode: string;
    remittanceForOthers: string;
}