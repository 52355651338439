import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class ISICCardService extends BaseProvider {
  
  constructor(public http: HttpClient) {
    super(http);
  }
 
  isicRegister(requestModel){
    var resourceUrl = "isicRegister";
    return this.makePostCall(resourceUrl, requestModel);
  }

  isicDocumentUpload(imageData){
    var resourceUrl = "isicDocumentUpload";
    return this.makeUploadCall(resourceUrl, imageData);
  }
}