import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import Stepper from 'bs-stepper';

@Component({
  selector: 'stepper-header-email',
  templateUrl: './stepper-header-email.component.html',
  styleUrls: ['../../../stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperHeaderEmailComponent implements OnInit {

  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public quotationResponse: any;
  public selectedPurpose: SinglePurposeItem;

  constructor(private router: Router) {
    this.getSessionData();
  }

  ngOnInit(): void { }

  ngOnDestroy() { }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
    } else {
      this.router.navigate(['/home'])
      .then(() => {
      window.location.reload();
       });
    }
  }
}
