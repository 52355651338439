import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { InsuranceService } from '../../services/insurance.service';
import { ActivatedRoute, Router } from "@angular/router";
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
@Component({
  selector: 'insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['../../../../../@core/scss/base/pages/app-invoice.scss',
    '../../../../../@core/scss/angular/libs/select.component.scss',
    './insurance-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceDetailsComponent implements OnInit {
  public orderRating: 5;
  public paymentItemList = Constants.paymentItemList;
  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;
  public insuranceDetails: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public insuranceId: any;
  public orderPaymentStatus;
  public policyStatus;
  public showLoader : boolean = false;
  public paymentStatus : any;
  public orderStatus : any;

  constructor(
    public insuranceService: InsuranceService,
    public router : Router,
    private activatedRoute: ActivatedRoute,
  ) { 
    this.insuranceId = this.activatedRoute.snapshot.paramMap.get('insuranceId');
    this.insuranceDetails = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_CART_DETAILS));
    this.orderPaymentStatus = sessionStorage.getItem('INSURANCE_PAYMENT_STATUS')
    this.policyStatus = sessionStorage.getItem('INSURANCE_ORDER_STATUS')
    console.log("this.insuranceDetails", this.insuranceDetails);
    console.log("this.orderPaymentStatus", this.orderPaymentStatus);
    console.log("this.policyStatus", this.policyStatus);
    if(this.insuranceDetails){
      this.insuranceId = this.insuranceDetails.ordercartdetailsId;
    }
  }

  ngOnInit() {
    // this.insuranceId = this.activatedRoute.snapshot.paramMap.get('insuranceId');
    this.getInsuranceDetails();
  }

  getInsuranceDetails() {
    this.showLoader = true;
    this.insuranceService.getTravelInsuranceCartDetails(this.insuranceId).subscribe(respInsuranceData => {
      this.insuranceDetails = respInsuranceData.body;
      this.showLoader = false;
    }, error => {
      this.insuranceDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log(this.insuranceDetails);
      this.showLoader = false;
      // let orderDetailObj = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_CART_DETAILS));
      // this.orderPaymentStatus = orderDetailObj?.paymentStatus;
      // this.policyStatus = orderDetailObj?.orderStatus;
    });
  }

  retryInsurancePayment(insuranceOrderId){
    this.router.navigate(['/insurance-retry-payment', insuranceOrderId])
  }
}