import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { BaseProvider } from 'app/shared/helper/http/BaseProvider';

@Injectable()
export class RemittanceService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  /// STEP 1: get quotation
  cartPrepare(cartType: string) {
    var resourceUrl = "cartPrepare?cartType=" + cartType;
    return this.makePostCall(resourceUrl, {});
  }

  getA2FormCart(a2AcceptanceRequest) {
    var resourceUrl = "cartA2"
    return this.makePutCall(resourceUrl, a2AcceptanceRequest);
  }
  
  getQuotation(requestQuotationModel: RequestQuotationModel) {
    var resourceUrl = "quotations";
    return this.makePostCall(resourceUrl, requestQuotationModel);
  }

  getOtpForEmail(orderConsumerA2AcceptanceRequest) {
    var resourceUrl = "/order/generateA2Signed";
    return this.makePostCall(resourceUrl, orderConsumerA2AcceptanceRequest);
  }

  cartItemPost(request) {
    var resourceUrl = "cartItem";
    return this.makePutCall(resourceUrl, request);
  }

  updateQuotation(orderId: any) {
    var resourceUrl = "/order/updateWithCurrentRate?orderId=" + orderId;
    return this.makePostCall(resourceUrl, orderId);
  }

  mapBeneficiary(request) {
    var resourceUrl = 'order/beneficiary';
    return this.makePatchCall(resourceUrl, request);
  }

  /// Update cart on every steps
  getCartToUpdateStepNumber(step: number) {
    var resourceUrl = 'cart';
    if (step == 4) {
      resourceUrl = 'cart?stepToComplete=' + step.toString();
    }
    return this.makeGetCall(resourceUrl);
  }

  getLoanProviderList() {
    var resourceUrl = 'tcsLoanProvider';
    return this.makeGetCall(resourceUrl);
  }

  getBankAccountList() {
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    var resourceUrl = 'bankAccount?consumerId=' + consumerId;
    return this.makeGetCall(resourceUrl);
  }

  uploadOrderDocument(imageData) {
    return this.makeUploadCall("doclocker/documents", imageData);
  }

  placeOrder(requestBody) {
    var resourceUrl = "order/acceptEmailOrder";
    return this.makePostCall(resourceUrl, requestBody);
  }

  /// map payer
  orderPayerMap(request) {
    var resourceUrl = 'order/payer';
    return this.makePatchCall(resourceUrl, request);
  }

  getDocumentSigned(orderId: string, cartType: string) {
    var serviceURL = "getDocumentSigned/" + orderId + "?cartType=" + cartType;
    return this.makeGetCall(serviceURL);
  }

  getA2FormSignedDoc(orderDetails) {
    var resourceUrl = "aadharEsignDocumentDetails";
    return this.makePostCall(resourceUrl, orderDetails);
  }

  getA2Form(otp, request) {
    var resourceUrl = "cartA2?otp=" + otp;
    return this.makePutCall(resourceUrl, request);
  }

  /// Get document upload and pending
  getOrderDocuments(orderId) {
    var resourceUrl = 'doclocker/totalVersusUploadedDocuments?orderId='+orderId;
    return this.makeGetCall(resourceUrl);
  }

  getPaymentGatewayLink(refOrderId: string) {
    var resourceUrl = "getPaymentGatewayLink?refOrderId=" + refOrderId;
    return this.makeGetCall(resourceUrl);
  }

  getOrderA2FormAcknowledge(orderId) {
    var resourceUrl = 'order/a2OtpInit?otp='+orderId;
    return this.makeGetCall(resourceUrl);
  }

  getA2FormOrder(requestBody) {
    var resourceUrl = "order/generateA2Signed";
    return this.makePostCall(resourceUrl, requestBody);
  }

  uploadA2FormSignature(orderId, formdata) {
    var resourceUrl = "order/a2FormSignature?orderId=" + orderId;
    return this.makeUploadCall(resourceUrl, formdata);
  }

  /// Call GetCartCountriesSanctionsStatus after country code selected on first step 
  getCartCountriesSanctionsStatus(countryCode) {
    var resourceUrl = "getCartCountriesSanctionsStatus?countryCode=" + countryCode;
    return this.makeGetCall(resourceUrl);
  }

  changeOrderStatus(orderId,orderStatusModificationRequest) {
     var resourceUrl = "changeOrderStatus/" + orderId;
     return this.makePostCall(resourceUrl, orderStatusModificationRequest);
  }

  getDueDelligenceStatus(countryCode) {
    var resourceUrl = 'getCartCountriesSanctionsStatus?countryCode=' + countryCode;
    return this.makeGetCall(resourceUrl);
  }

  postDueDelligenceData(request) {
    var resourceUrl = 'fatfEdd';
    return this.makePostCall(resourceUrl, request);
  }
}