<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 text-center mb-20">
                        <h6 class="content-header-title  mb-0 ml-10 fs-18">Open GIC Account</h6>
                        <div class="mb-0 ml-10 fs-12 mt-10">Guaranteed Investment Certificate (GIC)</div>
                    </div>

                    <div class="col-md-12 mb-20 text-center">
                        <img src="assets/images/smartfx-home/GIC.png" style="height: 100px;width:100px;">
                    </div>

                    <div class="col-md-6 offset-md-3 text-center">
                        <div class="fs-11"><span class="fw-600"> GIC </span> means <span class="fw-600"> Guaranteed Investment Certificate</span>. It is an investment account offered by a Canadian Bank that offers a guaranteed interest rate over a fixed period of time.</div>
                        <div class="fs-11 mt-15">Students have to deposit a fixed amount (between CAD 20,635 to CAD 50,000) which will be proof of funds and will be released every month of 12 months for all their expenses.</div>
                    </div>

                    <div class="col-md-2 offset-md-5 mt-15">
                        <button class="btn btn-primary  btn-block fs-12 text-center" tabindex="4" rippleEffect (click)="openAccountForm('GIC')">Apply Now</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>