<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-2">
                <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link d-flex py-75" id="payment" data-toggle="pill" aria-expanded="true" role="tab" (click)="showLeisureForm()">
                            <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                            <span class="font-weight-bold fs-12"> Leisure </span>
                        </a>
                    </li>
                    <li class="nav-item" ngbNavItem>
                        <a ngbNavLink class="nav-link d-flex py-75" id="delivery" data-toggle="pill" aria-expanded="false" role="tab" (click)="showStudentForm()">
                            <i data-feather="shopping-bag" class="font-medium-3 mr-1"></i>
                            <span class="font-weight-bold fs-12">Student</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-10">
                <div class="d-flex align-items-center">
                    <div class="avatar avatar-tag bg-light-primary mr-1">
                        <i data-feather="credit-card" class="font-medium-4"></i>
                    </div>
                    <div>
                        <h5 class="mb-0"> {{insuranceFor}} </h5>
                    </div>
                </div>
                <section class="form mt-20">
                    <form class="form-horizontal" (ngSubmit)="proceedInsuranceForm(AddInsuranceForm.form.valid)" #AddInsuranceForm="ngForm">

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Relationship*</b></label>
                                <ng-select name="relationship" placeholder="Select Relationship" [items]="relationDropdownItemsAll" [(ngModel)]="insuranceAddRequestModel.relationship" class="fs-12" [clearable]="false" [searchable]="false" #relationshipRef="ngModel" [class.error]="AddInsuranceForm.submitted && relationshipRef.invalid"
                                    [searchable]="false" required (change)="getPaxId()"> </ng-select>
                                <span *ngIf="AddInsuranceForm.submitted && relationshipRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">Please select relationship!</small>
                            </span>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                                <label class="form-label"><b>Departure Date</b></label>
                                <div class="input-group ">
                                    <input class="form-control fs-12 border-black" name="departureDate" placeholder="yyyy-mm-dd" name="departureDate" ngbDatepicker #basicDP="ngbDatepicker" [minDate]="departureMinDate" [(ngModel)]="departureDate" #departureDateRef="ngModel" [class.error]="AddInsuranceForm.submitted && departureDateRef.invalid"
                                        (dateSelect)="onDateSelect($event, 'departureDate')" (click)="basicDP.toggle()" required>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                                <span *ngIf="AddInsuranceForm.submitted && departureDateRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="departureDateRef.errors.required">Please select departure date!</small>
                                </span>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-group">
                                <label class="form-label"><b>Arrival Date</b></label>
                                <div class="input-group">
                                    <input class="form-control fs-12 border-black" name="arrivalDate" placeholder="yyyy-mm-dd" ngbDatepicker #basicDP2="ngbDatepicker" required [minDate]="minDateForArrivalDate" [(ngModel)]="arrivalDate" #arrivalDateRef="ngModel" [class.error]="AddInsuranceForm.submitted && arrivalDateRef.invalid"
                                        (dateSelect)="onDateSelect($event, 'arrivalDate')" (click)="( enableArrivalDatePicker ) ? basicDP2.toggle() : showErrorMessage()">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="(enableArrivalDatePicker) ? basicDP2.toggle() : showErrorMessage()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                                <span *ngIf="AddInsuranceForm.submitted && arrivalDateRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="arrivalDateRef.errors.required">Please select arrival date!</small>
                                </span>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Name of Passenger*</b></label>
                                <input type="text" name="passengerName" class="form-control fs-12 border-black" placeholder="Name of Passenger" [(ngModel)]="insuranceAddRequestModel.name" #nameRef="ngModel" [class.error]="AddInsuranceForm.submitted && nameRef.invalid" required (keypress)="letterOnly($event)">
                                <span *ngIf="AddInsuranceForm.submitted && nameRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="nameRef.errors.required">Please enter passenger name!</small>
                                </span>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>No. of days Travel*</b></label>
                                <input type="number" name="days" class="form-control fs-12 border-black" placeholder="No. of days Travel" [(ngModel)]="insuranceAddRequestModel.NoOfDaysTravel" #noOfDaysTravelRef="ngModel" [class.error]="AddInsuranceForm.submitted && noOfDaysTravelRef.invalid"
                                    [disabled]="freezeNoofDaysField" required>
                                <span *ngIf="AddInsuranceForm.submitted && noOfDaysTravelRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="noOfDaysTravelRef.errors.required">Please select no of days travel!</small>
                                </span>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Address 1*</b></label>
                                <input type="text" name="address" class="form-control fs-12 border-black" placeholder="Address 1" [(ngModel)]="insuranceAddRequestModel.address1" #addressRef="ngModel" [class.error]="AddInsuranceForm.submitted && addressRef.invalid" required>
                                <span *ngIf="AddInsuranceForm.submitted && addressRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="addressRef.errors.required">Please enter address 1!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Address 2</b></label>
                                <input type="text" name="address2" class="form-control fs-12 border-black" placeholder="Address 2" [(ngModel)]="insuranceAddRequestModel.address2" #address2Ref="ngModel" [class.error]="AddInsuranceForm.submitted && address2Ref.invalid">
                                <span *ngIf="AddInsuranceForm.submitted && address2Ref.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="address2Ref.errors.required">Please enter address 2!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Mobile*</b></label>
                                <input type="text" name="mobileNo" class="form-control fs-12 border-black" placeholder="Mobile" [(ngModel)]="insuranceAddRequestModel.mobile" #mobileRef="ngModel" [class.error]="AddInsuranceForm.submitted && mobileRef.invalid" maxlength="10" pattern="^[1-9][0-9]*$"
                                    required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                                <span *ngIf="AddInsuranceForm.submitted && mobileRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="mobileRef.errors.required">Please enter mobile no!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>City*</b></label>
                                <input type="text" name="city" class="form-control fs-12 border-black" placeholder="City" [(ngModel)]="insuranceAddRequestModel.city" #cityRef="ngModel" [class.error]="AddInsuranceForm.submitted && cityRef.invalid" (keypress)="letterOnly($event)" required>
                                <span *ngIf="AddInsuranceForm.submitted && cityRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="cityRef.errors.required">Please enter city!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Phone no.</b></label>
                                <input type="text" name="phoneno" class="form-control fs-12 border-black" placeholder="Phone no." [(ngModel)]="insuranceAddRequestModel.phoneNo" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" maxlength="10">
                                <!-- <span *ngIf="AddInsuranceForm.submitted && phoneRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="phoneRef.errors.required">Please enter phone no !</small>
                                </span> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>District*</b></label>
                                <input type="text" name="district" class="form-control fs-12 border-black" placeholder="District" [(ngModel)]="insuranceAddRequestModel.district" #districtRef="ngModel" [class.error]="AddInsuranceForm.submitted && districtRef.invalid" required (keypress)="letterOnly($event)">
                                <span *ngIf="AddInsuranceForm.submitted && districtRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="districtRef.errors.required">Please enter district!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Email Id*</b></label>
                                <input type="email" name="emailid" class="form-control fs-12 border-black" placeholder="Email ID" [(ngModel)]="insuranceAddRequestModel.email" #emailRef="ngModel" [class.error]="AddInsuranceForm.submitted && emailRef.invalid" required>
                                <span *ngIf="AddInsuranceForm.submitted && emailRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="emailRef.errors.required">Please enter email id!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>State*</b></label>
                                <input type="text" name="state" class="form-control fs-12 border-black" placeholder="State" [(ngModel)]="insuranceAddRequestModel.state" #stateRef="ngModel" [class.error]="AddInsuranceForm.submitted && stateRef.invalid" (keypress)="letterOnly($event)"
                                    required>
                                <span *ngIf="AddInsuranceForm.submitted && stateRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="stateRef.errors.required">Please enter state!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Travel Tag Number</b></label>
                                <input type="text" name="tagNo" class="form-control fs-12 border-black" placeholder="Travel Tag Number" [(ngModel)]="insuranceAddRequestModel.travelTagNumber">

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Pincode*</b></label>
                                <input type="text" name="pincode" class="form-control fs-12 border-black" placeholder="Pincode" [(ngModel)]="insuranceAddRequestModel.pincode" #pincodeRef="ngModel" [class.error]="AddInsuranceForm.submitted && pincodeRef.invalid" maxlength="6" (keypress)="numberOnly($event)"
                                    required>
                                <span *ngIf="AddInsuranceForm.submitted && pincodeRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="pincodeRef.errors.required">Please enter pincode!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Past Illness</b></label>
                                <input type="text" name="passIllness" class="form-control fs-12 border-black" placeholder="Past Illness" [(ngModel)]="insuranceAddRequestModel.pastIllness" (keypress)="letterOnly($event)">
                                <!-- <span *ngIf="AddInsuranceForm.submitted && pastIllnessRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="pastIllnessRef.errors.required">Please enter past illness if any or enter NA</small>
                                    </span> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Destination Country*</b></label>
                                <ng-select [clearable]="false" class="fs-12" name="countryListItems" [clearable]="false" [items]="countryListItems" bindLabel="countryName" [(ngModel)]="insuranceAddRequestModel.country" bindValue="countryName" placeholder="Select Destination Country*"
                                    [searchable]="false" #destinationCountryRef="ngModel" [class.error]="AddInsuranceForm.submitted && destinationCountryRef.invalid" required>
                                </ng-select>
                                <span *ngIf="AddInsuranceForm.submitted && destinationCountryRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="destinationCountryRef.errors.required">Please select destination country!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Nominee Name*</b></label>
                                <input type="text" name="nomineeName" class="form-control fs-12 border-black" placeholder="Nominee Name" [(ngModel)]="insuranceAddRequestModel.nomineeName" #nomineeNameRef="ngModel" [class.error]="AddInsuranceForm.submitted && nomineeNameRef.invalid"
                                    (keypress)="letterOnly($event)" required>
                                <span *ngIf="AddInsuranceForm.submitted && nomineeNameRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="nomineeNameRef.errors.required">Please enter nominee name!</small>
                                    </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Date of Birth*</b></label>
                                <div class="input-group">
                                    <input class="form-control fs-12   border-black" placeholder="yyyy-mm-dd" name="dateofBirth" ngbDatepicker #basicDP3="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" [(ngModel)]="dob" #dobRef="ngModel" [class.error]="AddInsuranceForm.submitted && dobRef.invalid"
                                        (dateSelect)="onDateSelect($event, 'dob')" required>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP3.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                                <span *ngIf="AddInsuranceForm.submitted && dobRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="dobRef.errors.required">Please select date of birth!</small>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b> Nominee Relationship*</b></label>
                                <!-- <select placeholder="Nominee Relationship" name="nomineeRelationShip" style="height: 36.54px;" class="form-control card-shadow fs-12 border-black" [(ngModel)]="insuranceAddRequestModel.nomineeRelationShip">
                                <option *ngFor="let singleItem of relationDropdownItems" value='{{ singleItem }}' >{{ singleItem }}</option>
                                </select> -->

                                <ng-select name="nominee-relationship" placeholder="Select Nominee Relationship" [items]="relationDropdownItems" [(ngModel)]="insuranceAddRequestModel.nomineeRelationShip" placeholder="Select Nominee Relationship" class="fs-12" [clearable]="false" #nomineeRelationshipRef="ngModel"
                                    [searchable]="false" [class.error]="AddInsuranceForm.submitted && nomineeRelationshipRef.invalid" required> </ng-select>
                                <span *ngIf="AddInsuranceForm.submitted && nomineeRelationshipRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="nomineeRelationshipRef.errors.required">Please select nominee relationship!</small>
                                    </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Passport Number*</b></label>
                                <input type="text" name="passportNo" class="form-control fs-12 border-black" placeholder="Passport Number"  [(ngModel)]="insuranceAddRequestModel.passportNumber" #passportNoRef="ngModel" (keypress)="alphaNumberOnly($event)" [class.error]="AddInsuranceForm.submitted && passportNoRef.invalid"
                                    required>
                                <span *ngIf="AddInsuranceForm.submitted && passportNoRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="passportNoRef.errors.required">Please enter passport number!</small>
                                    </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="insuranceFor == 'Student' ">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>University Name*</b></label>
                                <input type="text" name="universityName" class="form-control fs-12 border-black" placeholder="University Name" [(ngModel)]="insuranceAddRequestModel.universityName" #universityNameRef="ngModel" [class.error]="AddInsuranceForm.submitted && universityNameRef.invalid"
                                    (keypress)="letterOnly($event)" required>
                                <span *ngIf="AddInsuranceForm.submitted && universityNameRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="universityNameRef.errors.required">Please enter university name!</small>
                                    </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>University Address*</b></label>
                                <input type="text" name="universityAddress" class="form-control fs-12 border-black" placeholder="University Address" [(ngModel)]="insuranceAddRequestModel.universityAddress" #universityAddressRef="ngModel" [class.error]="AddInsuranceForm.submitted && universityAddressRef.invalid"
                                    required>
                                <span *ngIf="AddInsuranceForm.submitted && universityAddressRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="universityAddressRef.errors.required">Please enter university address!</small>
                                    </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Sponsor Name*</b></label>
                                <input type="text" name="sponsorName" class="form-control fs-12 border-black" placeholder="Sponsor Name" [(ngModel)]="insuranceAddRequestModel.sponsorName" #sponsorNameRef="ngModel" [class.error]="AddInsuranceForm.submitted && sponsorNameRef.invalid"
                                    (keypress)="letterOnly($event)" required>
                                <span *ngIf="AddInsuranceForm.submitted && sponsorNameRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="sponsorNameRef.errors.required">Please enter sponsor name!</small>
                                    </span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                                <label class="form-label"><b>Sponsor Relationship*</b></label>
                                <ng-select name="sponsor-relationship" placeholder="Select Sponsor Relationship" [items]="relationDropdownItems" [(ngModel)]="insuranceAddRequestModel.sponsorRelationShip" placeholder="Select Sponsor Relationship" class="fs-12" [clearable]="false" #sponsorRelationshipRef="ngModel"
                                    [searchable]="false" [class.error]="AddInsuranceForm.submitted && sponsorRelationshipRef.invalid" required> </ng-select>
                                <span *ngIf="AddInsuranceForm.submitted && sponsorRelationshipRef.invalid" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="sponsorRelationshipRef.errors.required">Please select sponsor relationship!</small>
                                    </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-primary fs-12 float-right" rippleEffect>Show Plans</button>
                            </div>
                        </div>
                    </form>

                </section>
            </div>
        </div>

    </div>
</div>