import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'insurance-cart-item',
  templateUrl: './insurance-cart-item.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
'./insurance-cart-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceCartItemComponent implements OnInit {
  public plans : any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();

  public showLoader : boolean = false;
  public insuranceDetails : any;
  public insuranceRequest : any;
  public ordercartdetailsId : any;
  public tncUrl = "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/Terms%26Conditions/TermsAndConditions.pdf";

  constructor(
    public insuranceService: InsuranceService,
    private route: ActivatedRoute,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,) { }

  ngOnInit() { 
    this.insuranceDetails = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_RESPONSE));
    this.insuranceRequest = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_REQUEST));
    this.ordercartdetailsId = this.route.snapshot.paramMap.get('ordercartdetailsId');
    this.getCartDetails();
  }
  
  proceedForPayment(){
    this.router.navigate(['/insurance-payment',this.ordercartdetailsId ]);
  }

  getCartDetails(){
      this.insuranceService.getTravelInsuranceCartDetails(this.ordercartdetailsId).subscribe(resp => {
        this.showLoader = false;
        var responeJson = resp.body;
        console.log("responeJson >>>" , responeJson);
      //   this.plans = JSON.parse(this.cryptoUtils.resDecrypt(responeJson));
      // console.log("responseJson>>> ", this.planDetails);
    },error =>{
      this.plans = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log("responseJson>>> ", this.plans);
    });
  }

  removeItemFromCart(){
    this.plans = null;
    this.router.navigate(['/insurance-plans']);
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
