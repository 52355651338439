import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EligibilityCheckComponent implements OnInit {
  public modalReference:any;
  @ViewChild('PANDetailModal') PANDetailModal: TemplateRef<any>;
  @ViewChild('NotAnIndianResidentPopup') NotAnIndianResidentPopup: TemplateRef<any>;
  
  constructor(
    public masterDataService: MasterDataService,
    public toast: ToastrService,
    public router : Router,
    public modalService : NgbModal
  ) {

  }

  ngOnInit() {
 
  }
 
  openModalForPANNo() {
    this.modalReference = this.modalService.open(this.PANDetailModal, { centered: true, size: 'sm' });
  }

  openNotAnIndianResidentPopup(){
    this.modalReference = this.modalService.open(this.NotAnIndianResidentPopup, { centered: true, size: 'sm' });
  }

  goToOrderSuccess(){
    this.modalReference.close();
    this.router.navigate(['/currency-notes-order-success']);
  }
}
