import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FaqComponent } from './view/faq.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FaqService } from './services/faq.service';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { AuthGuard } from 'app/auth/helpers/auth.guards';

const routes = [
  {
    path: 'faq',
    component: FaqComponent,
    data: { animation: 'FaqComponent' },
    canActivate : [AuthGuard]

  } ,
];

@NgModule({
    declarations: [ FaqComponent
    ],
    imports: [RouterModule.forChild(routes), 
      NgbModule,
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      ContentHeaderModule,
      LoaderRoutingModule
    ],
    exports: [ 
        FaqComponent
    ],
    providers: [
        FaqService
    ]
})
export class FaqRoutingModule {}
