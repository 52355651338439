<label class="text-danger mt-40 fs-8 fw-600">Rates will refresh after {{ progressWidth }} minutes </label>
<div class="progress">
    <div class="progress-bar bg-danger" role="progressbar" [ngStyle]="{'width.%': progressWidthInPercentage}" aria-valuemin="0"></div>
</div>
<!-- 
<div class="row mt-10 mb-20">
    <div class="col-md-12">
        <div class="card-body bg-light-grey b-radius-5">
            <div class="d-flex  justify-content-between">
                <div class="fs-17 text-black fw-600">₹ {{stepperDataCardModel?.quotationResponse?.totalBillableAmountString}}
                    <div class="fs-11 text-muted ">Includes Taxes and Charges
                        <button rippleEffect class="tooltip-icon" ngbTooltip="Handling Charges GST">
                            <span [data-feather]="'info'" class="'ml-1'"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="table-responsive mt-15">
    <table class="table table-condensed table-borderless  fs-12">
        <tbody>
            <tr>
                <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                <td class="thick-line text-right text-black fw-600 ">₹ {{stepperDataCardModel.quotationResponse.billableAmountString}}
                </td>
            </tr>
            <tr>
                <td class="no-line pl-0" colspan="3"><strong>Forex Margin </strong></td>
                <td class="no-line pr-0 text-right"> ₹ {{ stepperDataCardModel.quotationResponse.marginChargesString }} </td>
            </tr>
            <tr>
                <td class="no-line " colspan="3"><strong>Service Charges</strong></td>
                <td class="no-line text-right">₹ {{stepperDataCardModel.quotationResponse.finalChargesString}}</td>
            </tr>
            <tr>
                <td class="no-line " colspan="3"><strong>TCS({{stepperDataCardModel.quotationResponse.tcs.tcsPercentageString}}%)</strong></td>
                <td class="no-line text-right">₹ {{stepperDataCardModel.quotationResponse.tcs.tcsAmountString}}</td>
            </tr>
            <tr class="border-bottom-grey">
                <td class="no-line " colspan="3"><strong>GST </strong></td>
                <td class="no-line text-right">₹ {{stepperDataCardModel.quotationResponse.gstString}}</td>
            </tr>

            <tr>
                <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                <td class="no-line text-right text-black fw-800 pt-10">₹ {{stepperDataCardModel.quotationResponse.totalBillableAmountString}}
                </td>
            </tr>
        </tbody>
    </table>
</div>