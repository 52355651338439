import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router';
// import { SignaturePad } from 'angular2-signaturepad';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { Step03Data, StepperDataModel } from '../../models/stepperdata.model';
import { RemittanceService } from '../../services/remittance.service';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';
import { EsignMode } from 'app/shared/constant/esign-mode';

@Component({
  selector: 'esign',
  templateUrl: './esign.component.html',
  styleUrls: ['esign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ESignComponent implements OnInit {

  public contentHeader: object;
  public horizontalWizardStepper: Stepper;
  public isChecked: boolean = false;
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public otpValue: any;
  public showLoader: boolean = false;
  public showImageUploadedIcon: boolean = false;
  public signatureUrlS3: string;
  public signatureUrl: any;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();
  public consumerSignature : any;
  public consumerSignatureURL : any;
  public signatureMode = EsignMode.activeEsignMode;

  @Output() childCallbackSender = new EventEmitter<string>();

  constructor(
    public router: Router,
    public toast: ToastrService,
    public remittanceService: RemittanceService,
  ) {
    this.getSessionData();
    this.consumerSignatureURL = localStorage.getItem(ConstantsKey.CONSUMER_SIGNATURE);
    console.log(this.consumerSignatureURL);
    
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
    }
  }

  ngOnInit() {
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    // this.canvasResize();
  }

  // AfterViewInit() {
  //   this.canvasResize();
  // }
  
  checkCheckBoxvalue(isChecked) {
    if (isChecked && (this.signatureMode == EsignMode.OTP || this.signatureMode == EsignMode.OTP_SIGNATURE)) {
      this.sendOTP();
    }

    this.getSessionData();

    let step03Data = new Step03Data();
    if (this.stepperDataModel.step03Data) {
      step03Data = this.stepperDataModel.step03Data;
    }
    step03Data.checkboxChecked = isChecked;
    this.stepperDataModel.step03Data = step03Data;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
  }

  getEnteredOTP(otp) {
    if (otp.length == 6) {
      this.getSessionData();
      let step03Data = new Step03Data();
      if (this.stepperDataModel.step03Data) {
        step03Data = this.stepperDataModel.step03Data;
      }
      step03Data.otpValue = otp;
      this.stepperDataModel.step03Data = step03Data;
      sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    }
  }

  // updateSessionData() {
  //   this.getSessionData();
  //   let step03Data = new Step03Data();
  //   step03Data.checkboxChecked = this.isChecked;
  //   step03Data.otpValue = this.otpValue;
  //   this.stepperDataModel.step03Data = step03Data;

  //   sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataModel));
  //   this.childCallbackSender.emit('REFRESH_DATA');

  // for now 
  //   step03Data.otpValue = "235689";
  // }

  getA2FormLink() {
    let a2AcceptanceRequest = {
      "otp": "235689"
    };
    this.remittanceService.getA2FormCart(a2AcceptanceRequest).subscribe((respDownloadA2) => {
      this.showLoader = false;
      var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
      if (jsonResult.cartDetails.a2FormUrl) {
        let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
        window.open(a2FormUrl, "_blank");
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });   
  }

  // openTermsAndConditions() {
  //   window.open('https://b2capptnc.s3.ap-south-1.amazonaws.com/ForexCardT%26C.pdf');
  // }

  openTermsAndConditions() {
    window.open('https://s3.ap-south-1.amazonaws.com/files.wsfx.in/smartFxWebPortalDocuments/TC_for_Remittance_30.04.2020_revised.pdf');
  }

  sendOTP() {
    this.showLoader = true;
    this.remittanceService.getA2FormAcknowledge().subscribe((resp) => {
      this.showLoader = false;
      this.toast.success("OTP has been send successfully!!");
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    };
  }

  uploadSignature(event) {
    this.getSessionData();
    this.showLoader = true;
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();

    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.consumerSignature = fileList[0];
      console.log("fileupload++", file);
      if ((file.type == 'image/png') || (file.type == 'image/jpg') || (file.type == 'image/jpeg') ) {
        if (file.size < 1000000) {
          formData.append('file', file, file.name);
          var refOrderNo = this.stepperDataModel.cartItemsResponse.cartDetails.refOrderId;
          this.remittanceService.uploadA2FormSignature(refOrderNo, formData).subscribe((respA2Form) => {
            this.showLoader = false;
            this.showImageUploadedIcon = true;
            this.consumerSignatureURL = false;

            var jsonResult = JSON.parse(JSON.stringify(respA2Form.body));
            this.signatureUrlS3 = jsonResult?.url;

            this.getA2FormOrder();

            ///update sign data flag
            let step03Data = new Step03Data();
            if (this.stepperDataModel.step03Data) {
              step03Data = this.stepperDataModel.step03Data;
            }
            step03Data.signatureUpload = true;
            this.stepperDataModel.step03Data = step03Data;
            sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));

            this.toast.success("Signature saved successfully!");
          }), error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          };
        } else {
          this.showLoader = false;
          this.toast.error("Document size should be less than 1MB!!");
        }
      } else {
        this.showLoader = false;
        this.toast.error("Upload image document!");
      }
    }
  }

  getA2FormOrder() {
    var refOrderNo = this.stepperDataModel.cartItemsResponse.cartDetails.refOrderId;
    let reqBodyForGenerateA2Form = {
      "orderId": refOrderNo,
      // "signatureUrl": this.signatureUrlS3,
    };

    this.remittanceService.getA2FormCart(reqBodyForGenerateA2Form).subscribe((respDownloadA2) => {
      this.showLoader = false;
      var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
      if (jsonResult.cartDetails.a2FormUrl) {
        let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
        window.open(a2FormUrl, "_blank");
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  viewUploadedSignature() {
    window.open(this.consumerSignatureURL, "_blank");
    // window.location.assign(this.signatureUrlS3);
  }

  openUplaodedSignature(){
    this.viewLocalFileUtils.viewLocalDocument(this.consumerSignature);
  }


  navigateToDashboard() {
    this.router.navigate(['/login']);
  }

  // saveSignature() {
  //   this.showLoader = true;
  //   this.signatureUrlS3 = this.fileSignaturePad.name;
  //   let formData: FormData = new FormData();
  //   this.getSessionData();
  //   console.log("signaturepad", this.fileSignaturePad);

  //   formData.append("file", this.fileSignaturePad, this.fileSignaturePad.name);
  //   var refOrderNo = this.stepperDataModel.cartItemsResponse.cartDetails.refOrderId;
  //   this.remittanceService.uploadA2FormSignature(refOrderNo, formData).subscribe((respA2Form) => {
  //     this.showLoader = false;
  //     this.showImageUploadedIcon = true;

  //     var jsonResult = JSON.parse(JSON.stringify(respA2Form.body));
  //     this.signatureUrlS3 = jsonResult?.url;
  //     console.log("signature", jsonResult?.url);
  //     this.getA2FormOrder();

  //     ///update sign data flag
  //     let step03Data = new Step03Data();
  //     if (this.stepperDataModel.step03Data) {
  //       step03Data = this.stepperDataModel.step03Data;
  //     }
  //     step03Data.signatureUpload = true;
  //     this.stepperDataModel.step03Data = step03Data;
  //     sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));

  //     this.toast.success("Signatured saved successfully!");
  //   }), error => {
  //     this.showLoader = false;
  //     this.toast.error(error.error.message);
  //   };
  // }

  // public signaturePadOptions: Object = {
  //   minWidth: 1,
  //   canvasWidth: 300,
  //   canvasHeight: 70,
    
  // };

  // canvasResize() {
  //   let canvas = document.querySelector('canvas');
  //   this.signaturePad.set('minWidth', 1);
  //   this.signaturePad.set('canvasWidth', canvas.offsetWidth);
  //   this.signaturePad.set('canvasHeight', canvas.offsetHeight);
  // }


  // public _signature: any = null;
  // public propagateChange: Function = null;

  // get signature(): any {
  //   return this._signature;
  // }

  // set signature(value: any) {
  //   this._signature = value;
  //   this.propagateChange(this.signature);
  // }

  // public writeValue(value: any): void {
  //   if (!value) {
  //     return;
  //   }
  //   this._signature = value;
  //   this.signaturePad.fromDataURL(this.signature);
  // }

  // public registerOnChange(fn: any): void {
  //   this.propagateChange = fn;
  // }

  // public registerOnTouched(): void {
  //   // no-op
  // }

  // public ngAfterViewInit(): void {
  //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API  }
  // }

  // public drawBegin(): void {
  //   // this.canvasResize();
  //   console.log("Begin Drawing");
  // }

  // public drawComplete() {
  //   if (this.signaturePad.isEmpty()) {
  //     alert("Please provide a signature first.");
  //   } else {
  //     let signatureBas64Data = this.signaturePad.toDataURL();
  //     this.fileSignaturePad = this.dataURIToBlob(signatureBas64Data);
  //   }
  // }

  // public clear(): void {
  //   this.signaturePad.clear();
  //   this.signatureUrlS3 = null;
  //   this.signature = "";
  //   // this.canvasResize();
  // }

  // dataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',')
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i)

  //   let returnFile: any = new Blob([ia], { type: mimeString });
  //   returnFile.lastModifiedDate = new Date();
  //   returnFile.name = "signature.png";
  //   return <File>returnFile;
  // }
}