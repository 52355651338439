import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster, SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { QuotationCurrencyItem, RequestQuotationModel } from '../../models/quotation.models';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ToastrService } from 'ngx-toastr';
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { Router } from '@angular/router';
import { CardService } from 'app/modules/card/services/card.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
declare const gtag: Function;

@Component({
  selector: 'app-smartfx-home',
  templateUrl: './smartfx-home.component.html',
  styleUrls: [
    '../dashboard.scss',
    '../../../../../@core/scss/base/pages/app-ecommerce.scss',
    '../../../../../@core/scss/angular/libs/ratings.component.scss',
    '../../../../../@core/scss/angular/libs/select.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})

export class SmartFxHomeComponent implements OnInit {
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public showLoader: boolean = false;
  public currencyCode: any;
  public currencyItems: any;
  public studentRemittance: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public quotationResponse: any;
  public purposeListRemittance: any = [];
  public selectedPurposeItem: any;

  @ViewChild('selectedTab') selectedTab;
  public cartType: any;
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public showCardDetailsBox: boolean = false;
  public consumerDetail: any;
  public activeTab = 'SEND_MONEY';
  public deletedItem: any;

  public signature: any;
  public isProfileComplete: boolean = false;
  public isVcipComplete: boolean = false;
  public activeState = '';
  public configData: any;

  public menuItem = [
    {
      'key': 'STUDENT',
      'icon': 'assets/images/smartfx-home/UniFeesOwnAccount.svg',
      'title': 'Student',
      'isActive': false
    },
    {
      'key': 'OTHERS',
      'icon': 'assets/images/smartfx-home/ewallet.svg',
      'title': 'Personal',
      'isActive': false
    },
    {
      'key': 'FOREXCARD',
      'icon': 'assets/images/smartfx-home/credit-card.svg',
      'title': 'Forex Card',
      'isActive': false
    },
    {
      'key': 'TRAVELINSURANCE',
      'icon': 'assets/images/smartfx-home/travel-insurance (2).svg',
      'title': 'Travel Insurance',
      'isActive': false
    },
    {
      'key': 'OPENGICACCOUNT',
      'icon': 'assets/images/smartfx-home/GIC.png',
      'title': 'Open GIC Account',
      'isActive': false
    },
    {
      'key': 'BLOCKEDACCOUNT',
      'icon': 'assets/images/smartfx-home/germany.png',
      'title': 'Open Blocked Account',
      'isActive': false
    },
    {
      'key': 'ZOLVEDCREDITCARD',
      'icon': 'assets/images/smartfx-home/zolve-card.png',
      'title': 'Zolve Credit Card',
      'isActive': false
    },
    {
      'key': 'ISICCARD',
      'icon': 'assets/images/smartfx-home/id-card.png',
      'title': 'ISIC Card',
      'isActive': false
    },
  ];

  constructor(
    private masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    public toast: ToastrService,
    public router: Router,
    private cardService: CardService,
    public updateProfileService: UpdateProfileService,
  ) {
    this.activeTab = 'SEND_MONEY';
    this.quotationCurrencyItem.currencyCode = "USD";
    this.selectedPurposeItem = PurposeList.getPurposeObjectByRemittanceType('STUDENT');
  }

  ngOnInit() {
    this.getMenuItemFromConfig();
    this.getCurrencies();
    this.getRateRange();
    this.getQuotationRequestBody();
    this.getCardDetails();
    this.getProfileDetails();

    if (localStorage.getItem(ConstantsKey.SSO_RESP)) {
      let data = JSON.parse(localStorage.getItem(ConstantsKey.SSO_RESP));
      this.quotationCurrencyItem.currencyAmount = data['requiredCurrencyCodeAmount'];
      this.quotationCurrencyItem.currencyCode = data['requiredCurrencyCode'];
      // this.getRateRange();
    }
  }

  getMenuItemFromConfig() {
     let menuItemsLocal = [];
    // let data = sessionStorage.getItem('CONFIG_DATA');
    // this.configData = JSON.parse(data);
    this.configData={
      "remittanceFlowEnable": true,
      "personalFlowEnable": true,
      "cardFlowEnable": true,
      "insuranceEnable": true,
      "dashboardEnable": true,
      "myAccountMenuEnable": true,
      "myAccountSubMenuEnable": true,
      "relationshipEnable": true,
      "beneficiaryEnable": true,
      "bankAccountEnable": true,
      "ordersEnable": true,
      "pendingOrdersEnable": true,
      "rewardsEnable": true,
      "offersEnable": true,
      "branchNetworkEnable": true,
      "currencyConverterEnable": true,
      "resetPasswordEnable": true,
      "ratesEnable": true,
      "setRateAlertEnable": true,
      "supportEnable": true,
      "addressEnable": true,
      "faqEnable": true,
      "tutorialEnable": true,
      "paymentModeNBEnable": true,
      "paymentModeNEFTEnable": true,
      "partialPaymentEnable": true,
      "openGICAccountEnable": true,
      "blockedAccountEnable": true,
      "zolveCreditCardEnable": true,
      "isicCardEnable": true
  };
    this.menuItem.forEach(singleMenu => {
        if(singleMenu.key == 'TRAVELINSURANCE' && this.configData.insuranceEnable){
          menuItemsLocal.push(singleMenu);
        } 
        if(singleMenu.key == 'FOREXCARD' && this.configData.cardFlowEnable){
          menuItemsLocal.push(singleMenu);
        }
        if(singleMenu.key == 'STUDENT' && this.configData.remittanceFlowEnable){
          menuItemsLocal.push(singleMenu);
        }
        if(singleMenu.key == 'OTHERS' && this.configData.personalFlowEnable){
          menuItemsLocal.push(singleMenu);
        }
        if(singleMenu.key == 'OPENGICACCOUNT' && this.configData.openGICAccountEnable){
          menuItemsLocal.push(singleMenu);
        } 
        if(singleMenu.key == 'BLOCKEDACCOUNT' && this.configData.blockedAccountEnable){
          menuItemsLocal.push(singleMenu);
        }
        if(singleMenu.key == 'ZOLVEDCREDITCARD' && this.configData.zolveCreditCardEnable){
          menuItemsLocal.push(singleMenu);
        }
        if(singleMenu.key == 'ISICCARD' && this.configData.isicCardEnable){
          menuItemsLocal.push(singleMenu);
        }
        
    });
    this.menuItem = menuItemsLocal;
  }


  checkCardLink() {
    var requestModel = {
      silentValidation: "true"
    }
    this.cardService.linkCard(requestModel).subscribe((result => {
      localStorage.setItem(ConstantsKey.LINK_CARD_NAVIGATED, "true");
      if (result.body == null) {
        this.router.navigate(['/new-dashboard']);
      } else {
        var data=result.body
        if(data['msg']!=null && data['msg']=='true' ){
          this.router.navigate(['/link-card']);
        }else{
          this.router.navigate(['/new-dashboard']);

        }
        
      }
    }), error => {
      this.router.navigate(['/new-dashboard']);
    });
  }

  getActiveTab(active) {
    this.activeTab = active;
    if (this.activeTab == 'SEND_MONEY') {
      if (this.menuItem.length == 3) {
        this.menuItem.splice(0, 0, this.deletedItem[0]);
      }
    } else if (this.activeTab == 'BUY_FOREX') {
      if (this.menuItem.length == 4) {
        this.deletedItem = this.menuItem.splice(this.menuItem.findIndex(item => item.key === 'STUDENT'), 1);
        console.log(this.deletedItem);
      }
    }
  }

  getActiveSectionTitle(singleMenu) {
    if (singleMenu.pageUrl != '') {
      this.navigateToNextPage(singleMenu.pageUrl);
    } else if (singleMenu.purposeCode == '' && singleMenu.pageUrl == '') {
      this.toast.error('Coming soon!');
    }

    this.selectedPurposeItem = singleMenu;
  }

  getCurrencies() {
    this.showLoader = true;
    this.reqGetCurrencyMaster.cartType = this.selectedPurposeItem.remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
    });
  }

  getSubMenu(menuItemSingle) {
    var remittanceType = menuItemSingle.key;
    this.activeState = menuItemSingle.key;
    if (menuItemSingle.key == 'TRAVELINSURANCE') {
      this.router.navigate(['/insurance']);
    }
    if (menuItemSingle.key == 'FOREXCARD') {
      if (this.isVcipComplete) {
        if (!this.showCardDetailsBox) {
          this.router.navigate(['/my-card']);
        }
      } else {
        this.toast.error("VCIP is not complete!");
      }
    }
    if (menuItemSingle.key == 'BLOCKEDACCOUNT') {
      this.router.navigate(['/open-blocked-account']);
    }
    if (menuItemSingle.key == 'OPENGICACCOUNT') {
        this.router.navigate(['/open-gic-account']);
    }
    if (menuItemSingle.key == 'ZOLVEDCREDITCARD') {
      this.router.navigate(['/zolve-credit-card']);
    }
    if (menuItemSingle.key == 'ISICCARD') {
      this.router.navigate(['/isicCard']);
    }
    window.scrollTo(0, 5000);
    this.studentRemittance = PurposeList.getPurposeObjectByRemittanceType(remittanceType);
    console.log(remittanceType);
  }

  getRateRange() {
    this.showLoader = true;
    this.masterDataService.getRateRange(
      Constants.productsEnum[2],
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
    }, error => {
      this.showLoader = false;
    });
  }

  enterCurrencyAmount() {
    if (this.quotationCurrencyItem.currencyAmount) {
      this.getQuotationRequestBody();
      this.remittanceService.getQuotationEncrypted(JSON.parse(JSON.stringify(this.requestQuotationModel))).subscribe(resp => {
        this.quotationResponse = resp.body;
      }, error => {
        this.showLoader = false;
        if (error.status==200 ) {
           this.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));

        } else {
             this.toast.error(JSON.stringify(error.error.message));
        }
      });
    }
  }

  getQuotationRequestBody() {
    let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
    /// prepare quotation request
    this.quotationCurrencyItem.product = Constants.productsEnum[2];
    this.quotationCurrencyItem.bidRate = this.singleRateResItem.minimumRateInINR;
    this.quotationCurrencyItem.purpose = this.selectedPurposeItem?.purposeCode;
    // this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
    //   ? this.selectedPurposeItem.purposeCode
    //   : "UNIVERSITY_FEES";

    listQuotationCurrencies.push(this.quotationCurrencyItem);

    this.requestQuotationModel.sellType = Constants.sellTypes[0];
    this.requestQuotationModel.partnerId = Constants.partnerId;
    this.requestQuotationModel.nostroChargeType = 'REMITTER';
    this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  proceedToStepper() {
    if (this.isVcipComplete) {
      if (this.selectedPurposeItem.length > 0) {
        this.toast.error('Please select purpose !');
      } else {
        if (this.selectedPurposeItem && this.quotationCurrencyItem.currencyAmount) {
          this.showLoader = true;
          // this.cartType = (this.activeTab == 'SEND_MONEY') ? 'STUDENT' : 'OTHERS';
          this.cartType = this.selectedPurposeItem.remittanceType;
          this.remittanceService.cartPrepare(this.cartType).subscribe(result => {
            this.getQuotationRequestBody();
            this.remittanceService.getQuotationEncrypted(this.requestQuotationModel).subscribe(resp => {

              ///store active order in session storage
              // this.stepperDataModel.purposeItem = this.selectedPurposeItem;
              // this.stepperDataModel.quotationRequest = this.requestQuotationModel;
              // this.stepperDataModel.quotationResponse = resp.body;

              // sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
              // this.showLoader = false;

              // if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
              //   this.router.navigate(['/stepper3step'])
              // } else {
              //   this.router.navigate(['/stepper'])
              // }
            }, error => {
                      this.showLoader = false;
                        if (error.status==200 ) {

                              this.stepperDataModel.purposeItem = this.selectedPurposeItem;
                                this.stepperDataModel.quotationRequest = this.requestQuotationModel;
                                this.stepperDataModel.quotationResponse = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));

                                sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
                                this.showLoader = false;

                                if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
                                  this.router.navigate(['/stepper3step'])
                                } else {
                                  this.router.navigate(['/stepper'])
                                }


                        } else {
                            this.toast.error(JSON.stringify(error.error.message));
                        }
            });
          });
        } else {
          var errorMessage = 'Please enter amount';
          if (!this.selectedPurposeItem) {
            errorMessage = "Please select purpose";
          }
          this.toast.error(errorMessage);
        }
      }
    } else {
      this.toast.error("VCIP is not complete!");
    }
  }

  navigateToNextPage(pageUrl) {
    if (pageUrl) {
      this.router.navigate([pageUrl]);
    } else {
      this.proceedToStepper();
    }
  }

  getCardDetails() {
    this.showLoader = true;
    this.cardService.getCardDetails().subscribe((respCardData => {
      var responseBody = JSON.parse(JSON.stringify(respCardData.body));
      if (responseBody.length > 0) {
        this.showCardDetailsBox = false;
      } else {
        if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
          this.checkCardLink();
        }
        this.showCardDetailsBox = true;
      }
      this.showLoader = false;
    }), error => {
      this.showLoader = false;
      this.showCardDetailsBox = true;
      if (localStorage.getItem(ConstantsKey.LINK_CARD_NAVIGATED) != "true") {
        this.checkCardLink();
      }
      this.toast.error(error.error.message);
    });;
  }

  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isProfileComplete = this.consumerDetail.paxResponse?.profileComplete;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      if(this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed){
        this.isVcipComplete=true;
      }else if(this.consumerDetail?.paxResponse?.consumer?.offlineonboarding=='1'){
        this.isVcipComplete=true;
      }
      this.signature = this.consumerDetail.signature;

      if (this.signature == undefined) {
        this.isProfileComplete = false;
      }

      localStorage.setItem(ConstantsKey.IS_VCIP_COMPLETE, this.isVcipComplete.toString());
      localStorage.setItem(ConstantsKey.CONSUMER_SIGNATURE, this.signature);
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  initiateVcip() {
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }
}
