import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { MasterDataService } from "app/shared/services/master-data.service";
import { ToastrService } from "ngx-toastr";
import { CardService } from "app/modules/card/services/card.service"; 

@Component({
  selector: 'add-bank-account-card-email',
  templateUrl: './add-bank-account-card-email.component.html',
  // styleUrls: ['../../../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailAddBankAccountForCardComponent implements OnInit {

  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private _router: Router,
    private masterDataService: MasterDataService,
  ) { }

  ngOnInit() { }
}
