import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddressService } from '../../services/address.service';


@Component({
  selector: 'list-address',
  templateUrl: './list-address.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ListAddressComponent implements OnInit {
  public addressList : any;
  public noDataFound : boolean = false;
  public showLoader : boolean = false;

  constructor( 
    public router : Router,
    public addressService : AddressService,
    public toast : ToastrService) {
  }
 
  ngOnInit(): void {
    this.getAddressesList();
  }

  getAddressesList(){
    this.showLoader = true;
    this.addressService.getAddressList().subscribe((res=>{
    this.showLoader = false;

      this.addressList = res.body;
      if(this.addressList.length == 0){
        this.noDataFound = true;
      }
    }),error=>{
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  editAddress(addressDetails){
    this.router.navigate(['/add-address'],{  queryParams: addressDetails });
  }

  deleteAddress(addressID){
    this.showLoader = true;
    this.addressService.deleteConsumerAddress(addressID).subscribe((resp =>{
      this.toast.success("Consumer address deleted sucessfully !");
      this.showLoader = false;
      this.getAddressesList();
    }), error=>{
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }
 
}
