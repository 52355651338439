import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsuranceService } from 'app/modules/insurance/services/insurance.service';
import { OrderService } from 'app/modules/order/services/order.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'currency-notes-order-success',
  templateUrl: './currency-notes-order-success.component.html',
  styleUrls: [
    './currency-notes-order-success.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyNotesOrderSuccessComponent implements OnInit {
  public orderId: string;
  public branchName: string;

  constructor(  
    public orderService: OrderService,
    public modalService: NgbModal,
    public updateProfileService: UpdateProfileService,
    public insuranceService: InsuranceService,
    private router: Router,
    private route: ActivatedRoute,
    public toast: ToastrService) { }

  ngOnInit(): void {
   
    this.orderId = this.route.snapshot.paramMap.get('orderId');

    this.branchName=sessionStorage.getItem("cashSellBranchId");

  }

  goToOrderDetails() {
    this.router.navigate(['/order-details', this.orderId]);
  }
  
}