import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { EmailLinkComponent } from './view/email-link.component';
import { BeneficiaryService } from '../beneficiary/services/beneficiary.service';
import { RelationshipService } from '../relationship/services/relationship.service';
import { CardService } from '../card/services/card.service';
import { UpdateProfileService } from '../update-profile/services/update-profile.service';
import { StepperEmailComponent } from './view/stepper/stepper-email/stepper-email.component';
import { ListBeneficiaryStepperEmailComponent } from './view/stepper/1-list-beneficiary-stepper/list-beneficiary-stepper-email.component';
import { ListRelationshipStepperEmailComponent } from './view/stepper/2-list-relationship-stepper-email/list-relationship-stepper-email.component';
import { ESignEmailComponent } from './view/stepper/3-esign/esign-email.component';
import { PaymentEmailComponent } from './view/stepper/4-payment/payment-email.component';
import { StepperHeaderEmailComponent } from './view/stepper/stepper-header-email/stepper-header-email.component';
import { StepperFooterEmailComponent } from './view/stepper/stepper-footer-email/stepper-footer-email.component';
import { Stepper3StepEmailComponent } from './view/stepper/stepper-3-email/stepper3step-email.component';
import { AddBankAccountForRemittanceEmailComponent } from './view/stepper/add-bank-account-for-remittance-email/add-bank-account-for-remittance-email.component';
import { DueDiligenceEmailComponent } from './view/stepper/enhance-due-diligence-email/enhance-due-diligence-email.component';
import { EmailA2FormComponent } from './view/stepper-card/4-a2-form-email/a2-form-email.component';
import { EmailTripInformationComponent } from './view/stepper-card/1-trip-information-email/trip-information-email.component';
import { EmailTravellerDetailsComponent } from './view/stepper-card/2-traveller-details-email/traveller-details-email.component';
import { EmailKYCComponent } from './view/stepper-card/3-document-email/document-email.component';
import { EmailPaymentComponent } from './view/stepper-card/5-payment-email/payment-email.component';
import { EmailAddAddressCardComponent } from './view/stepper-card/add-address-card-email/add-address-card-email.component';
import { EmailAddBankAccountForCardComponent } from './view/stepper-card/add-bank-account-card-email/add-bank-account-card-email.component';
import { EmailCardStepperComponent } from './view/stepper-card/card-stepper-email/card-stepper-email.component';
import { EmailCardStepperFooterComponent } from './view/stepper-card/card-stepper-footer-email/card-stepper-footer-email.component';
import { BrowserModule } from '@angular/platform-browser';
import { CardRoutingModule } from '../card/card-routing.module';
import { RemittanceService } from './services/email.service';
import { NgSelectModule } from '@ng-select/ng-select';
// import { SignaturePadModule } from 'angular2-signaturepad';

const routes = [
  {
    path: 'email-login',
    component: EmailLinkComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'emailStepper',
    component: StepperEmailComponent,
    data: { animation: 'auth' }
  },{
    path: 'emailStepper3step',
    component: Stepper3StepEmailComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'emailCardStepper',
    component: EmailCardStepperComponent,
    data: { animation: 'auth' }
  },
];

@NgModule({
  declarations: [
    EmailLinkComponent,
    ListBeneficiaryStepperEmailComponent,
    ListRelationshipStepperEmailComponent,
    StepperEmailComponent,
    Stepper3StepEmailComponent,
    ESignEmailComponent,
    PaymentEmailComponent,
    StepperHeaderEmailComponent,
    StepperFooterEmailComponent,
    Stepper3StepEmailComponent,
    AddBankAccountForRemittanceEmailComponent,
    DueDiligenceEmailComponent,
    EmailA2FormComponent,
    EmailTripInformationComponent,
    EmailTravellerDetailsComponent,
    EmailKYCComponent,
    EmailPaymentComponent,
    EmailAddAddressCardComponent,
    EmailAddBankAccountForCardComponent,
    EmailCardStepperComponent,
    EmailCardStepperFooterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgSelectModule,
    NgbAlertModule,
    NgbModule,
    // SignaturePadModule,
    ReactiveFormsModule,
    LoaderRoutingModule,
    CardRoutingModule,
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-center-center',
    }),
  ],
  exports: [
    EmailLinkComponent,
    ListBeneficiaryStepperEmailComponent,
    ListRelationshipStepperEmailComponent,
    StepperEmailComponent,
    Stepper3StepEmailComponent,
    ESignEmailComponent,
    PaymentEmailComponent,
    StepperHeaderEmailComponent,
    StepperFooterEmailComponent,
    Stepper3StepEmailComponent,
    AddBankAccountForRemittanceEmailComponent,
    DueDiligenceEmailComponent,
    EmailA2FormComponent,
    EmailTripInformationComponent,
    EmailTravellerDetailsComponent,
    EmailKYCComponent,
    EmailPaymentComponent,
    EmailAddAddressCardComponent,
    EmailAddBankAccountForCardComponent,
    EmailCardStepperComponent,
    EmailCardStepperFooterComponent,
  ],
  providers: [RemittanceService, BeneficiaryService, RelationshipService, CardService, UpdateProfileService]
})
export class EmailLinkRoutingModule { }
