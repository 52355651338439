import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { AddAddressComponent } from './view/add-address/add-address.component';
import { ListAddressComponent } from './view/list-address/list-address.component';
import { AddressService } from './services/address.service';
import { AuthGuard } from 'app/auth/helpers/auth.guards';


const routes = [
  {
    path: 'add-address',
    component: AddAddressComponent,
    data: { animation: 'AddBankAccountComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'list-address',
    component: ListAddressComponent,
    data: { animation: 'home' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    ListAddressComponent,
    AddAddressComponent
  ],
  imports: [RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CardSnippetModule,
    FormsModule,
    CoreDirectivesModule,
    NgSelectModule,
    LoaderRoutingModule,
  ],
  exports: [
    ListAddressComponent,
    AddAddressComponent
  ],
  providers: [
    AddressService
  ]
})
export class AddressRoutingModule { }
