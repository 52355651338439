<div class="bs-stepper-header">
    <div class="step" data-target="#beneficiary-details">
        <button class="step-trigger">
            <span class="bs-stepper-box">1</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">University</span>
                <span class="bs-stepper-subtitle" style="color: black; font-size: 13px; font-weight: 500;"> University Details </span>
            </span>
        </button>
    </div>

    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#student-details">
        <button class="step-trigger">
            <span class="bs-stepper-box">2</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">Student</span>
                <span class="bs-stepper-subtitle" style="color: black">Student Details</span>
            </span>
        </button>
    </div>

    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#kyc">
        <button class="step-trigger">
            <span class="bs-stepper-box">3</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">KYC</span>
                <span class="bs-stepper-subtitle" style="color: black">Complete your
                    KYC</span>
            </span>
        </button>
    </div>

    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
    <div class="step" data-target="#kyc-self-declaration" style="display:none">
        <button class="step-trigger"></button>
    </div>

    <div class="step" data-target="#payment-details">
        <button class="step-trigger">
            <span class="bs-stepper-box">4</span>
            <span class="bs-stepper-label">
                <span class="bs-stepper-title" style="color: black">Payment</span>
                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
            </span>
        </button>
    </div>
</div>