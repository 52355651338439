import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { RequestLoginModel } from "app/modules/onboarding/models/login.models";
import { LoginService } from "app/modules/onboarding/services/login.service";
import { ConstantsKey } from "app/shared/constant/constants-keys";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { DirectSignupService } from "../services/direct-signup.service";

@Component({
  selector: 'direct-signup',
  templateUrl: './direct-signup.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [DirectSignupService],
  host: { class: 'ecommerce-application' }
})
export class DirectSignupComponent implements OnInit {
  // public base64EncodedString = "eyJpZCI6Ijk0MjA3OTYwNzciLCJvdHAiOiI5ODY1MzIiLCJ0eXBlIjoiTU9CSUxFIiwicmVxdWlyZWRDdXJyZW5jeUNvZGUiOiJVU0QiLCJyZXF1aXJlZEN1cnJlbmN5Q29kZUFtb3VudCI6IjEwMDAifQ==";
  public base64EncodedString: any;
  private _unsubscribeAll: Subject<any>;
  public showLoader: boolean = true;
  public requestLoginModel = new RequestLoginModel();
  public base64Decode: any;
  public authToken: any;
  currentUser: any;

  constructor(
    private _coreConfigService: CoreConfigService,
    private directSignupService: DirectSignupService,
    public router: Router,
    public toast: ToastrService,
    public route: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit() {
    this.showLoader = true;
    this.route.params.subscribe((params: Params) => {
      this.base64EncodedString = params['data'];

      this.base64Decode = JSON.parse(atob(this.base64EncodedString));
      this.authToken = this.base64Decode.xAuthToken;
      localStorage.setItem(
        "X-AUTH-TOKEN",
        this.base64Decode.xAuthToken
      );
      this.getDataFromAuthToken();
    });
  }

  getDataFromAuthToken() {
    this.directSignupService.getDataFromToken(this.authToken).subscribe(result => {
      // LOCAL STORAGE ADD
      this.currentUser = result.body;
      localStorage.setItem("validCardCurrencyList", JSON.stringify(this.currentUser['validCardCurrencyDetails']));

      var cardList = [];
      cardList = this.currentUser['consumerCardResponseList'];
      localStorage.setItem("consumerID", this.currentUser['consumer']['id']);
      if (cardList.length > 0) {
        localStorage.setItem("isCardLinked", 'true');
      } else {
        localStorage.setItem("isCardLinked", 'false');
      }
      localStorage.setItem("passwordSet", this.currentUser['passwordSet']);
      localStorage.setItem('cartCount', '0');
      localStorage.setItem("kycStatus", this.currentUser['kysStatus']);
      localStorage.setItem("mobile", this.currentUser['consumer']['mobile']);
      localStorage.setItem("rateExpiryInSeconds", this.currentUser['rateExpiryInSeconds']);
      localStorage.setItem("rateExpiryInSecondsInitial", this.currentUser['rateExpiryInSeconds']);
      var shortConfigList = this.currentUser["shortConfigs"];
      localStorage.setItem("appConfig", JSON.stringify(shortConfigList));
      for (var i = 0; i < shortConfigList.length; i++) {
        if (shortConfigList[i]['key'] == 'VCIP') {
          if (shortConfigList[i]['value'] == 'true') {
            localStorage.setItem('showVCIP', 'true');
          } else {
            localStorage.setItem('showVCIP', 'false');
          }
        }
      }
      this.router.navigate(['/home']);

      if (this.currentUser['consumer']['dob']) {
        localStorage.setItem("dob", this.currentUser['consumer']['dob']);
      }

    }, error => {
      if (error.message != null) {
        let errorMessage = error.message;
        this.toast.error('Error', errorMessage);
      } else {
        this.toast.error('Error', 'Something went wrong');
      }
      this.router.navigate(['/login']);
    });
  }

}
