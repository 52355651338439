<style>
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #000 !important;
        font-weight: 500;
        border-color: #000 !important;
        border-radius: 0ch !important;
    }
</style>
<loader *ngIf="showLoader"></loader>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="invoice-preview-wrapper">
            <div class="row invoice-preview">
                <div class="col-xl-12 col-md-12 col-12 printRef">
                    <div class="card invoice-preview-card">
                        <div class="card-body invoice-padding pb-0">
                            <img src="/assets/images/logo/logo.png" style="height:35px;margin: auto;display: block;" class="mb-20  d-block d-sm-none">
                            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                                <div class="mt-md-0 mt-2 mb-20">
                                    <h4 class="fs-16 fw-500">
                                        Order ID
                                        <span class="invoice-number fw-600 text-black">#  {{ insuranceDetails?.cart[0]?.insuranceOrderId }}  </span>
                                    </h4>
                                    <div class="invoice-date fs-11 text-black"> {{insuranceDetails?.cart[0]?.createdAt | date:'MMM dd yyyy | hh:mm a'}}</div>
                                </div>

                                <div class="fs-12 text-black section-right ">
                                    <img src="/assets/images/logo/logo.png" style="height:45px;" class="mb-20 show-section">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4" *ngFor="let singlePolicy of insuranceDetails?.cart">
                                    <table class="table table-bordered fs-11">
                                        <thead>
                                            <tr>
                                                <th class="fw-600">Policy Id </th>
                                                <th>{{singlePolicy?.id}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="fw-600">Insured </td>
                                                <td>{{singlePolicy?.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-600">Policy Type </td>
                                                <td>{{singlePolicy?.planName}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-600">Sum Assured</td>
                                                <td>{{singlePolicy?.sumInsuranceAmount}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-600">Premium </td>
                                                <td>{{singlePolicy?.premiumAmount}}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-600">Status</td>
                                                <td>{{policyStatus}}</td>
                                                <!-- <td>{{insuranceDetails?.orderStatus}}</td> -->
                                            </tr>
                                        </tbody>
                                        <!-- <tfoot>
                                            <tr>
                                                <th class="fs-10 text-center bg-blue" colspan="2">Download Policy</th>
                                            </tr>
                                        </tfoot> -->
                                    </table>
                                </div>
                            </div>
                        </div>

                        <hr class="invoice-spacing" />

                        <div class="card-body card-padding">
                            <div class="row invoice-sales-total-wrapper">
                                <div class="col-md-12  order-md-2 order-1">
                                    <div class="invoice-total-wrapper p-15">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Amount : </p>
                                            <p class="invoice-total-amount fs-12 text-black fw-500">₹ {{insuranceDetails?.totalPremiumAmount}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">Service Charges :</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹ {{insuranceDetails?.serviceCharges}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">CGST :</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹ {{insuranceDetails?.cgst}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-12">SGST :</p>
                                            <p class="invoice-total-amount fs-12  text-black fw-500">₹ {{insuranceDetails?.sgst}}
                                            </p>
                                        </div>

                                        <hr class="my-50">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title text-black fw-600 fs-16">TOTAL PAYABLE :
                                            </p>
                                            <p class="invoice-total-amount text-black fw-700 fs-16">₹ {{insuranceDetails?.totalPayableAmount}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 text-center">
                                        <div class="fw-600 text-black  fs-13 mt-30   blue-text" style="cursor: pointer;">IBR this order now</div>
                                        <div class="rating mb-30">
                                            <ngb-rating [(rate)]="orderRating" [max]="5">
                                                <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o" [class.fa-star]="fill===100"></span>
                                                </ng-template>
                                            </ngb-rating>
                                        </div>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="faq">
            <div class="row  d-flex">
                <div class="col-md-3 mb-2 mb-md-0">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="payment" data-toggle="pill" aria-expanded="false" role="tab">
                                <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                                <!-- <span class="font-weight-bold fs-12"> Payment : <span class="fs-11 fw-600" [ngClass]="insuranceDetails?.paymentStatus == 'PENDING' ? 'text-danger' : 'text-success' ">{{insuranceDetails?.paymentStatus}}</span></span> -->
                                <span class="font-weight-bold fs-12"> Payment : <span class="fs-11 fw-600" [ngClass]="(orderPaymentStatus == 'PENDING') ? 'text-danger' : (orderPaymentStatus == 'COMPLETED') ? 'text-success' : 'text-warning' ">{{orderPaymentStatus}}</span></span>

                            </a>

                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon card card-body" id="faq-payment" role="tabpanel" aria-labelledby="payment" aria-expanded="false" *ngIf="orderPaymentStatus == 'PENDING'">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="credit-card" class="font-medium-4"></i>
                                        </div>
                                        <div class=" mb-20">
                                            <h5 class="mb-0">Payment</h5>
                                        </div>
                                    </div>

                                    <div class="row  mt-20 ">
                                        <div class="col-md-12 col-12 col-sm-12  mb-20">

                                            <div *ngIf="orderPaymentStatus == 'PENDING'">
                                                <button class="btn btn-primary icomoon" (click)="retryInsurancePayment(insuranceDetails?.cart[0]?.insuranceOrderId)" type="button" rippleEffect>Retry Payment</button>
                                            </div>

                                            <form class="form-horizontal" *ngIf="false">
                                                <section>
                                                    <div class="row  mb-20">
                                                        <div class="col-md-12">
                                                            <div class="text-black fs-14 fw-500">Transaction Details
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-10">
                                                        <div class="form-group col-md-6">
                                                            <label class="form-label">Transaction Reference Number
                                                            </label>
                                                            <input type="text" class="form-control fs-12" placeholder="Transaction Reference Number" name="referenceNumber" style="border-color:#000" required />
                                                            <!-- <span
                                                                *ngIf="PaymentDetailsForm.submitted && referenceNumberRef.invalid"
                                                                class="invalid-form">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="referenceNumberRef.errors.required">This
                                                                    field is
                                                                    required!</small>
                                                            </span> -->
                                                        </div>

                                                        <div class="form-group col-md-6">
                                                            <label>Payment Type*</label>
                                                            <div class="form-group m-form__group">
                                                                <select class="form-control m-input" id="manualPaymentType" name="manualPaymentType" autocomplete="off" required>
                                                                   <option value="" selected data-default>Select Payment Type</option>
                                                                  <option *ngFor="let singleItem of paymentItemList" [value]="singleItem">
                                                                  {{ singleItem }}</option>
                                                               </select>
                                                            </div>
                                                        </div>

                                                        <div class="form-group  col-md-6">
                                                            <label class="form-label" for="transaction_date">Transaction
                                                                Date</label>
                                                            <div class="input-group">
                                                                <input class="form-control fs-12" placeholder="Select Transaction Date" (click)="basicDP.toggle()" ngbDatepicker #basicDP="ngbDatepicker" name="paymentDate">
                                                                <div class="input-group-append" style="border-color:#000">
                                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                                </div>
                                                            </div>
                                                            <!-- <span
                                                                *ngIf="PaymentDetailsForm.submitted && paymentDateRef.invalid"
                                                                class="invalid-form">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="paymentDateRef.errors.required">This field is
                                                                    required!</small>
                                                            </span> -->
                                                        </div>



                                                        <div class="form-group col-md-6">
                                                            <label class="form-label">Transaction Amount </label>
                                                            <input type="text" class="form-control fs-12" placeholder="Transaction Amount" name="amount" required value="{{insuranceDetails?.totalPayableAmount}}" readonly/>
                                                            <!-- <span
                                                                *ngIf="PaymentDetailsForm.submitted && amountRef.invalid"
                                                                class="invalid-form">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="amountRef.errors.required">This field is
                                                                    required!</small>
                                                            </span> -->
                                                        </div>

                                                        <div class="col-md-12">
                                                            <div class="fs-14 fw-600 text-black">For NEFT/RTGS/IMPS payment
                                                            </div>
                                                            <div class="fs-11 fw-500  text-black">Transfer to :</div>
                                                            <div class="fs-11 fw-500  text-black">{{accountName}}
                                                            </div>
                                                            <div class="fs-11  text-black">{{bankName}}</div>
                                                            <div class="fs-11  text-black">{{accountNo}}</div>
                                                            <div class="fs-11  text-black">{{bankIFSC}}</div>
                                                            <div class="fs-11  text-black">{{bankBranch}}</div>
                                                        </div>

                                                        <div class="col-md-12 mt-20 text-right">
                                                            <button class="btn btn-primary fs-12" rippleEffect>Update</button>
                                                        </div>
                                                    </div>
                                                </section>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                        <li class="nav-item" ngbNavItem>

                            <a ngbNavLink class="nav-link d-flex py-75" id="payment" data-toggle="pill" aria-expanded="false" role="tab">
                                <i data-feather="credit-card" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold fs-12"> Issuance : <span class="fs-11 fw-600" [ngClass]="insuranceDetails?.orderStatus == 'PENDING' ? 'text-danger' : 'text-success' ">{{insuranceDetails?.orderStatus}}</span></span>
                            </a>

                            <ng-template ngbNavContent>
                                <div class="tab-pane collapse-icon card card-body" id="faq-payment" role="tabpanel" aria-labelledby="payment" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar avatar-tag bg-light-primary mr-1">
                                            <i data-feather="credit-card" class="font-medium-4"></i>
                                        </div>
                                        <div class=" mb-20">
                                            <h5 class="mb-0">Issuance</h5>

                                        </div>
                                    </div>


                                </div>
                            </ng-template>
                        </li>


                    </ul>
                </div>
                <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
            </div>
        </section>
    </div>
</div>