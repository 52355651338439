import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { StepperComponent } from './view/stepper/stepper.component';
import { BeneficiaryRoutingModule } from '../beneficiary/beneficiary-routing.module';
import { RelationshipRoutingModule } from '../relationship/relationship-routing.module';
import { StepperFooterComponent } from './view/stepper-footer/stepper-footer.component';
import { ESignComponent } from './view/3-esign/esign.component';
import { ListBeneficiaryStepperComponent } from './view/1-list-beneficiary-stepper/list-beneficiary-stepper.component';
import { RemittanceService } from './services/remittance.service';
import { BeneficiaryService } from '../beneficiary/services/beneficiary.service';
import { RelationshipService } from '../relationship/services/relationship.service';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { ListRelationshipStepperComponent } from './view/2-list-relationship-stepper/list-relationship-stepper.component';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { StepperHeaderComponent } from './view/stepper-header/stepper-header.component';
import { Stepper3StepComponent } from './view/stepper-3/stepper3step.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { DueDiligenceComponent } from './view/enhance-due-diligence/enhance-due-diligence.component';
import { AddBankAccountForRemittanceComponent } from './view/add-bank-account-for-remittance/add-bank-account-for-remittance.component';
// import { SignaturePadModule } from 'angular2-signaturepad';

const routes = [
  {
    path: 'stepper',
    component: StepperComponent,
    data: { animation: 'home' },
    canActivate: [AuthGuard]
  },
  {
    path: 'stepper3step',
    component: Stepper3StepComponent,
    data: { animation: 'home' },
    canActivate: [AuthGuard]
  },
  {
    path: 'esign',
    component: ESignComponent,
    data: { animation: 'ESignComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'list-beneficiary-stepper',
    component: ListBeneficiaryStepperComponent,
    data: { animation: 'ListBeneficiaryStepperComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'due-diligence',
    component: DueDiligenceComponent,
    data: { animation: 'DueDiligenceComponent' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    StepperComponent,
    Stepper3StepComponent,
    StepperFooterComponent,
    ESignComponent,
    ListBeneficiaryStepperComponent,
    ListRelationshipStepperComponent,
    StepperHeaderComponent,
    DueDiligenceComponent,
    AddBankAccountForRemittanceComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CardSnippetModule,
    FormsModule,
    CoreDirectivesModule,
    NgSelectModule,
    BeneficiaryRoutingModule,
    RelationshipRoutingModule,
    LoaderRoutingModule,
    NgbRatingModule,
    NgbModule,
    // SignaturePadModule
  ],
  exports: [
    StepperComponent,
    Stepper3StepComponent,
    StepperFooterComponent,
    ESignComponent,
    ListBeneficiaryStepperComponent,
    ListRelationshipStepperComponent,
    StepperHeaderComponent,
    DueDiligenceComponent,
    AddBankAccountForRemittanceComponent
  ],
  providers: [RemittanceService, BeneficiaryService, RelationshipService]
})

export class RemittanceRoutingModule { }
