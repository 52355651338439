import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { Step03DataA2Form, StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'a2-form-email',
  templateUrl: './a2-form-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EmailA2FormComponent implements OnInit {

  ///SESSION DATA
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public step03DataA2Form: Step03DataA2Form = new Step03DataA2Form();

  public otpValue: any;
  public isChecked: boolean = false;
  public cartType :any;
  public showImageUploadedIcon: boolean = false;
  
  @Output() childCallbackSender = new EventEmitter<string>();

  constructor(
    public router: Router,
    public toast: ToastrService,
    private cardService: CardService,
    public remittanceService: RemittanceService,
  ) { }

  ngOnInit() {
    this.getSessionData();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
      this.cartType = this.stepperDataCardModel.cartType;
      console.log("CART TYPE" , this.cartType);
    }
  }

  updateSessionData() {
    this.getSessionData();
    this.step03DataA2Form.checkboxChecked = this.isChecked;
    this.step03DataA2Form.otpValue = this.otpValue;
    this.stepperDataCardModel.step03Data = this.step03DataA2Form;

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
  }

  checkCheckBoxvalue() {
    if (this.isChecked) {
      this.sendA2FormAcknowledge();
    }
    this.updateSessionData();
  }

  sendA2FormAcknowledge() {
    this.cardService.getA2FormAcknowledge().subscribe(respData => {
      this.toast.success("OTP for acceptance has been send successfully.");
    })
  }

  getA2FormLink() {
    var a2otp = "235689";
    this.cardService.getA2Form(a2otp, {}).subscribe((respA2Form) => {
      var jsonResult = JSON.parse(JSON.stringify(respA2Form.body));
      var a2FormPdfUrl = jsonResult.cartDetails.a2FormUrl;
      window.open(a2FormPdfUrl, "_blank");
    });
  }

  uploadSignature(event) {
    this.getSessionData();

    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      formData.append('file', file, file.name);
      console.log(JSON.stringify(this.stepperDataCardModel.cartItemsResponse));
      
      var refOrderNo = this.stepperDataCardModel.cartItemsResponse.cartDetails.refOrderId;
      this.remittanceService.uploadA2FormSignature(refOrderNo, formData).subscribe((respA2Form) => {
        this.showImageUploadedIcon = true;
        this.toast.success("Signatured saved successfully");
      });
    }
  }
}
