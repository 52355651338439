import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import Stepper from 'bs-stepper';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { Step02Data, StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { Router } from '@angular/router';
import { AddRelationshipModel } from 'app/modules/relationship/models/relationship.model';
import { RelationshipService } from 'app/modules/relationship/services/relationship.service';

@Component({
  selector: 'list-relationship-stepper-email',
  templateUrl: './list-relationship-stepper-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ListRelationshipStepperEmailComponent implements OnInit {

  private horizontalWizardStepper: Stepper;
  public showAddRelationSection = false;
  public relationshipMemberListItems: any;
  public getRelationShipList = Constants.payerList;
  public consumerId: any;
  public stepperDataModel: StepperDataModel = new StepperDataModel();

  public addRelationshipModel = new AddRelationshipModel();
  public showAddRelationForm: boolean = false;

  @Output() childCallbackRelationship = new EventEmitter<string>();

  constructor(private _coreSidebarService: CoreSidebarService,
    public relationshipService: RelationshipService,
    private toast: ToastrService,
    public router: Router) {
    this.getSessionData();
  }

  ngOnInit(): void {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.getRelationshipMemberList();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
    } else {
      this.router.navigate(['/home']);
    }
  }

  addRelation(data) {
    if (data === true) {
      this.addRelationshipModel.nationality = 'INDIAN';
      this.addRelationshipModel.consumerId = this.consumerId;
      let localPaxCreateRequests: Array<AddRelationshipModel> = [];
      localPaxCreateRequests.push(this.addRelationshipModel);
      let paxCreateRequests = { "paxCreateRequests": localPaxCreateRequests };

      this.relationshipService.addRelationship(paxCreateRequests).subscribe(resp => {
        this.getRelationshipMemberList();
        this.toast.success("Relationship added successfully", "Success !!");
        this.showAddRelationForm = false;
        this.addRelationshipModel.pan = '';
        this.addRelationshipModel.name = '';
        this.addRelationshipModel.relationship = '';
      }, error => {
        this.toast.error(error.error.message);
      });
    }
  }

  getRelationshipMemberList() {
    this.relationshipService.getRelationshipList().subscribe(resp => {
      this.relationshipMemberListItems = resp.body;
    }, error => {
      this.toast.error(error.error.message);
    });
  }

  toggleSidebar(name): void {
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  hideAddRelation() {
    this.showAddRelationForm = false;
    this.addRelationshipModel.relationship = '';
    this.addRelationshipModel.pan = '';
    this.addRelationshipModel.name = '';
  } 

  updateSelectedStudentDetailsToSession(singleRelationship) {
    this.getSessionData();
    let step02Data = new Step02Data();
    if (this.stepperDataModel.step02Data) {
      step02Data = this.stepperDataModel.step02Data;
    }
    step02Data.selfResPaxItemSingle = singleRelationship;
    this.stepperDataModel.step02Data = step02Data;
    // this.stepperDataModel.step02Data.selfResPaxItemSingle = singleRelationship;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    this.childCallbackRelationship.emit('RELATIONSHIP_DATA_ADDED');
  }
}
