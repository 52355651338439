import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestOrderFeedbackModel } from 'app/modules/order/models/order.model';
import { OrderService } from 'app/modules/order/services/order.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'rating',
  templateUrl: './ratings.component.html',
  // styleUrls: ['../../../../../@core/scss/angular/libs/ratings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RatingsComponent implements OnInit {
  public orderId: string;
  public requestOrderFeedbackModel: RequestOrderFeedbackModel = new RequestOrderFeedbackModel();
  public showLoader: boolean = false;
  @ViewChild('vcipCompleteAfterOrder') vcipCompleteAfterOrder: TemplateRef<any>;
  public modalReference: any;
  public consumerDetail: any;
  public isProfileComplete: any;
  public isVcipComplete: any;
  public showVCIPPopup: boolean = false;
  public scheduleVCIPLater : boolean = false ;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private orderService: OrderService,
    public toast: ToastrService,
    public modalService: NgbModal,
    public updateProfileService: UpdateProfileService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.orderId = params['orderId'];
    });
    this.getProfileDetails();

  }

  submitOrderRating() {
    this.showLoader = true;
    this.requestOrderFeedbackModel.orderId = this.orderId;
    if (this.requestOrderFeedbackModel.onboarding && this.requestOrderFeedbackModel.transaction && this.requestOrderFeedbackModel.transparency && this.requestOrderFeedbackModel.support && this.requestOrderFeedbackModel.overall) {
      this.orderService.postOrderRating(this.requestOrderFeedbackModel).subscribe((respOrderRating => {
        this.showLoader = false;
        this.goToNextPage();

      }), error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }else{
      this.showLoader = false;
      this.goToNextPage();

    }
  }
  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;

      this.isVcipComplete = this.consumerDetail?.vcipKyc;
      let vcipCompletionFlag = localStorage.getItem(ConstantsKey.IS_VCIP_COMPLETE);
      console.log(vcipCompletionFlag);
      console.log(this.isVcipComplete);
      // if (!this.isVcipComplete && vcipCompletionFlag == 'false') {
      //   this.showVCIPPopup = true;
      //   setTimeout(() => {
      //     this.modalReference = this.modalService.open(this.vcipCompleteAfterOrder, { size: 'xl', centered: true });
      //     }, 1000);
      // } 
    });
  }
  closePopup(){
    this.scheduleVCIPLater = true;
  }
  goToNextPage(){
    let vcipCompletionFlag = localStorage.getItem(ConstantsKey.IS_VCIP_COMPLETE);
    if(vcipCompletionFlag=='false'){
      this.router.navigate(['/order-details', this.orderId]);
    }else{
      this.router.navigate(['/sit-back-relax', this.orderId]);
    }
  }
  initiateVcip() {
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }
}
