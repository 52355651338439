import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'insurance-list',
  templateUrl: './insurance-list.component.html',
})
export class InsuranceListComponent implements OnInit {
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public startDateForFilter: any;
  public endDateForFilter: any;
  public orderListItems: any;
  public date: any;
  public maxDate: any;
  public selectedFromDate: any;
  public showLoader: boolean = false;
  public minDateForEndDate: any;
  public maxDateForEndDate: any;
  public enableEndDatePicker: boolean = false;
  public noDataFound: boolean = false;
  public insuranceListItems: any;
  public insuranceList: any;
  public cryptoUtils: CryptoUtils = new CryptoUtils();

  constructor(
    public router: Router,
    public toast: ToastrService,
    public insuranceService: InsuranceService
  ) {
    this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    var startDate = new Date();
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth() + 1;
    let startDay = 1;
    this.startDate = { 'year': startYear, 'month': startMonth, 'day': startDay };

    this.endDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  }

  ngOnInit(): void {
    this.getInsuranceList();
  }

  getInsuranceList() {
    this.showLoader = true;
    this.startDateForFilter = this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day;
    this.endDateForFilter = this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day;
    this.insuranceService.getFilteredConsumerTravelInsuranceOrderList(this.startDateForFilter, this.endDateForFilter).subscribe((resp => {
      this.insuranceList = resp.body;
      this.insuranceListItems = this.insuranceList.insuranceOrders;
      console.log(this.insuranceListItems);

      this.showLoader = false;
      this.noDataFound = false;
      if (this.insuranceListItems.length == 0) {
        this.noDataFound = true;
      }
    }), error => {
      this.showLoader = false;
      this.insuranceListItems = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
    });
  }

  onDateSelect(event, sourceDate) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    if (sourceDate == 'startDate') {
      this.startDateForFilter = year + "-" + month + "-" + day;
      this.selectedFromDate = this.startDateForFilter;
      this.enableEndDatePicker = true;
      let minDateForEndDate = this.selectedFromDate;
      this.minDateForEndDate = { year: parseInt(minDateForEndDate.split("-")[0]), month: parseInt(minDateForEndDate.split("-")[1]), day: parseInt(minDateForEndDate.split("-")[2]) };

    } else if (sourceDate == 'endDate') {
      this.endDateForFilter = year + "-" + month + "-" + day;
    }
  }

  showErrorMessage() {
    this.toast.error('Please select from date first');
  }

  getInsuranceDetails(insuranceDetails) {
    console.log(insuranceDetails)
    let key = insuranceDetails.id;
    let value = insuranceDetails;
    value = JSON.stringify(value);
    sessionStorage.setItem(key, value);
    sessionStorage.setItem('INSURANCE_PAYMENT_STATUS', insuranceDetails.paymentStatus);
    sessionStorage.setItem('INSURANCE_ORDER_STATUS', insuranceDetails.orderStatus);
    this.router.navigate(['/insurance-details', insuranceDetails.id]);
  }
}


