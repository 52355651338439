import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module'; 
import { EnquiryComponent } from './views/enquiry-form.component';
import { SetRateAlertService } from '../set-rate-alert/services/set-rate-alert.service';

const routes = [
  {
    path: 'enquiry-form',
    component: EnquiryComponent,
    data: { animation: 'EnquiryComponent' },
    canActivate : [AuthGuard]
  } ,
  
];

@NgModule({
    declarations: [ 
      EnquiryComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      LoaderRoutingModule,
      NgSelectModule,
      NgbModule,
    ],
    exports: [ 
      EnquiryComponent
    ],
    providers: [
      SetRateAlertService
    ]

})
export class EnquiryFormRoutingModule {}
