import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'app/modules/onboarding/services/login.service';
import { RequestLoginModel } from 'app/modules/onboarding/models/login.models';
import { DOCUMENT } from '@angular/common';
import { RequestEmailLinkDataModel } from '../models/email-link.model';
import { OrderService } from 'app/modules/order/services/order.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { Constants } from 'app/shared/constant/constants';
import { SingleRateResItem } from 'app/shared/models/shared-models';
import { StepperDataModel } from '../models/stepperdata.model';

@Component({
  selector: 'app-email-link',
  templateUrl: './email-link.component.html',
  styleUrls: ['./email-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService]
})
export class EmailLinkComponent implements OnInit {
  public coreConfig: any;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  public requestLoginModel = new RequestLoginModel();
  public loginForm: FormGroup;

  public mobile: string;
  public email: string;
  public requestEmailLinkDataModel = new RequestEmailLinkDataModel();

  public showLoader: boolean = true;
  public toast: ToastrService
  public showLoginWithPassword: boolean = false;
  public orderDetails: any;
  public selectedPurposeItem: any;
  public otpCalled = false;

  ///STEPPER DATA
  public stepperDataModel: StepperDataModel = new StepperDataModel();

  ///QUOTATION
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public quotationResponse: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param {DOCUMENT} document
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _coreConfigService: CoreConfigService,
    public route: ActivatedRoute,
    private _router: Router,
    private loginService: LoginService,
    public _formBuilder: FormBuilder,
    public toastr: ToastrService,
    public orderService: OrderService,
    private remittanceService: RemittanceService,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: { hidden: true },
        menu: { hidden: true },
        footer: { hidden: true },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  ngOnInit() {
    localStorage.clear();
    this.route.queryParams.subscribe(async params => {

      var getBase64Data = params['data'];
      this.requestEmailLinkDataModel = JSON.parse(atob(getBase64Data));

      this.mobile = this.requestEmailLinkDataModel.mobile;
      this.email = this.requestEmailLinkDataModel.email;

      await this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
        this.coreConfig = config;
        this.coreConfig.layout.skin = "black";

        this.document.body.classList.remove('default-layout', 'green-layout', 'black-layout', 'orange-layout', 'blue-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');

        if (!this.otpCalled) {
          this.getDataAndVerifiy();
        }

      });
    });
  }

  getDataAndVerifiy() {
    this.requestLoginModel.id = this.mobile;
    this.loginService.getMobileEmailOTPLogin(this.requestLoginModel.id, this.email).subscribe((resp => {
      this.toastr.success("OTP sent to registered Mobile Number and Email Id");
      this.showLoader = false;
      this.otpCalled = true;
    }), error => {
      this.toastr.error(error.error.message);
    });
  }

  onProceedToLogin() {
    this.submitted = true;
    this.showLoader = true;
    this.error = '';
    this.requestLoginModel.type = "MOBILE";

    if (this.requestLoginModel.otp.toString().length == 6) {
      this.loginService.loginWithOtp(this.requestLoginModel).subscribe(resp => {
        this.showLoader = true;
        var respBody = JSON.parse(JSON.stringify(resp.body));

        localStorage.setItem(ConstantsKey.X_AUTH_TOKEN, resp.headers.get('X-AUTH-TOKEN'));
        localStorage.setItem(ConstantsKey.CONSUMER_ID, respBody.consumer.id);
        localStorage.setItem(ConstantsKey.CONSUMER_DATA, JSON.stringify(respBody.consumer));
        localStorage.setItem(ConstantsKey.OTP_LOGIN_RESPONSE, JSON.stringify(respBody));

        //// STORE LOCAL STORAGE FOR EMAIL LINK
        let emailLinkData = { "quotationId": this.requestEmailLinkDataModel.quotationId };
        localStorage.setItem(ConstantsKey.IS_FROM_EMAIL_LINK, "true");
        localStorage.setItem(ConstantsKey.EMAIL_LINK_DATA, JSON.stringify(emailLinkData));

        ///get things done and move to stepper
        this.getSingleOrderDetails(respBody);
      }, error => {
        this.showLoader = false;
        this.error = error.error.message;
      });
    } else {
      this.showLoader = false;
      this.error = "OTP must be 6 characters long";
    }
  }

  getSingleOrderDetails(respBody) {
    this.showLoader = true;

    this.orderService.getOrderDetailsSingle(this.requestEmailLinkDataModel.quotationId).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
      console.log("orderdetails++", this.orderDetails);

      if (this.validateOrderData()) {
        console.log("orderstatus++", this.orderDetails.orderStatusGroup.status);
        
        if(this.orderDetails.orderStatusGroup.status != 'PENDING_CONSUMER_ACCEPTANCE' &&
        this.orderDetails.orderStatusGroup.status != 'QUOTATION' ){
          this._router.navigate(['/order-details/', this.orderDetails.orderId]);
        } else if(this.orderDetails.orderStatusGroup.status == 'QUOTATION') {
          this._router.navigate(['/quote-order-details/', this.orderDetails.orderId]);
        } else if(this.orderDetails.orderStatusGroup.status == 'PENDING_CONSUMER_ACCEPTANCE'){
        this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(this.orderDetails.orderItems[0].purpose);
        console.log("this.selectedPurposeItem >>", this.selectedPurposeItem);
        //   /// prepare quotation request
        let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
        this.quotationCurrencyItem.currencyCode = this.orderDetails.orderItems[0].currencyCode;
        this.quotationCurrencyItem.currencyAmount = this.orderDetails.orderItems[0].currencyAmount;
        this.quotationCurrencyItem.bidRate = this.orderDetails.orderItems[0].bidRate;
        this.quotationCurrencyItem.product = Constants.productsEnum[2];
        this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
          ? this.selectedPurposeItem.purposeCode
          : "UNIVERSITY_FEES";

        listQuotationCurrencies.push(this.quotationCurrencyItem);

        this.requestQuotationModel.sellType = Constants.sellTypes[0];
        this.requestQuotationModel.partnerId = Constants.partnerId;
        this.requestQuotationModel.nostroChargeType = 'REMITTER';
        this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;

        /// store active order in session storage
        this.stepperDataModel.purposeItem = this.selectedPurposeItem;
        this.stepperDataModel.quotationRequest = this.requestQuotationModel;
        this.stepperDataModel.orderDetails = resp.body;
        console.log("stepper data +++", this.stepperDataModel.orderDetails);

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
        this.showLoader = false;

        console.log("respBody.consumer.vcipKycStatus >>", respBody);

        if (!respBody.consumer.vcipKycStatus) {
          this._router.navigate(['/update-profile']);
        } else if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
          this._router.navigate(['/emailStepper3step']);
        } else {
          this._router.navigate(['/emailStepper'])
        }
      }
      } else {
        this.toastr.error("There is issue in processing this order, kindly contact your partner!");
        this._router.navigate(['/home']);
      }

    }, error => {
      this.showLoader = false;
      console.log(error);
    });
  }

  validateOrderData() {
    this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(this.orderDetails.orderItems[0].purpose);
    console.log("this.selectedPurposeItem >>", this.selectedPurposeItem);

    if (this.orderDetails.orderItems[0].product != "REMITTANCE") {
      return false;
    }

    // if (this.selectedPurposeItem == undefined) {
    //   return false;
    // }

    return true;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

///{
//   "mobile":"9420796077",
//   "email":"pgfepale@gmail.com",
//   "quotationId":"220918837"
// }
/// ewogIm1vYmlsZSI6Ijk0MjA3OTYwNzciLAoiZW1haWwiOiJwZ2ZlcGFsZUBnbWFpbC5jb20iLAogInF1b3RhdGlvbklkIjoiMjIwOTE4ODM3IgogfQ==