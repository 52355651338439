import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivateINRWalletRequest } from "../../models/activate-inr-wallet.model";
import { DobFormat } from "app/modules/update-profile/models/update-profile.model";
import { Constants } from "app/shared/constant/constants";
import { CardService } from "../../services/card.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import Swal from "sweetalert2";
import { debug } from "console";
import { CryptoUtils } from "app/shared/utility/crypto.utils";
import { DateUtils } from "app/shared/utility/date-utils";

interface NgbDateStruct {
  day: any,
  month: any,
  year: any
}
@Component({
  selector: 'update-profile',
  templateUrl: './activate-inr-wallet.component.html',
  styleUrls: ['./activate-inr-wallet.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ActivateINRWallet implements OnInit{
  public activateINRWalletRequest = new ActivateINRWalletRequest();
  public dobMaxDate: any;
  public ngbDatepicker: NgbDateStruct;
  public occupationListItems = Constants.occupationMap;
  public selfEmployedListItems = Constants.selfEmployedMap;
  public natureOfBusinessItems = Constants.natureOfBusinessMap;
  public educationListItems = Constants.educationMap;
  public sourceOfFundsItems = Constants.sourceOfFundsMap;
  public annualIncomeItems = Constants.incomeMap;
  public showLoader: boolean = false;
  public consumerDetail: any;
  public inrFormFields: any;

  public cryptoUtils: CryptoUtils = new CryptoUtils();

  constructor(
    private cardService: CardService,
    private router: Router,
    public toast: ToastrService,
    private location: Location,
    public activateRoute: ActivatedRoute,

  ) {
    this.dobMaxDate = { year: new Date().getFullYear() - 13, month: new Date().getMonth() + 1, day: new Date().getDate() }

  }

  public dobFormat = new DobFormat();
  ngOnInit(): void {
       this.activateRoute.queryParams.subscribe(params => {
         this.consumerDetail = params;
        this.activateINRWalletRequest.nameOnId = this.consumerDetail?.name;
         this.activateINRWalletRequest.gender = this.consumerDetail?.gender;
        this.activateINRWalletRequest.contactNo = this.consumerDetail?.mobile;
        this.activateINRWalletRequest.address = this.consumerDetail?.address;

         
       });
    this.getFormFields();
     
    }
  
  getOTP(mobile) {
    if (mobile != null) {
      this.showLoader = true;
      var mobile = mobile;
      this.cardService.getOTPINRWallet().subscribe(
        (result) => {
          this.showLoader = false;
        },
        (error) => {
          this.showLoader = false;
          if (error.status==200) {
            // Swal.fire(" Success", "OTP has been sent successfully", "success");
            var response = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            if (response['msg'] == "'User already registered.") {
               Swal.fire("Error", response['msg'], "error");
              this.backClicked();
            } else {
                this.toast.error(response['msg']);
            }


          }              

        }
      );
    } 
  }

  getFormFields() {
    this.showLoader = true;
      this.cardService.getINRWalletFormFields().subscribe(
        (result) => {
          this.showLoader = false;
        },
        (error) => {
          this.showLoader = false;
          if (error.status==200) {
              this.inrFormFields=   JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            console.log("inrFormFields>>>"+ JSON.stringify(this.inrFormFields));
          } else {
                 Swal.fire("Error", error.error.message, "error");

          }             

        }
      );
  }

  callActivateINRWallet(data) {
    if (data === true) { 
      this.showLoader = true;
      this.activateINRWalletRequest.idType = this.inrFormFields?.idType;
      this.activateINRWalletRequest.purpose_of_card_issuance = this.inrFormFields?.purpose_of_card_issuance;
      this.activateINRWalletRequest.kycRefNo = this.inrFormFields?.kycRefNo;
      this.activateINRWalletRequest.entityId = this.inrFormFields?.entityId;
      this.activateINRWalletRequest.self_employed_profession = this.inrFormFields?.self_employed_profession;
      this.activateINRWalletRequest.nature_of_business = this.inrFormFields?.nature_of_business;
      this.activateINRWalletRequest.profession = this.activateINRWalletRequest.profession.toUpperCase();
      this.activateINRWalletRequest.dob = DateUtils.getNgbDateStructToDate(this.activateINRWalletRequest.dob);
      console.log("activateINRWalletRequest>>"+ JSON.stringify(this.activateINRWalletRequest));
        this.cardService.activateINRWallet(this.activateINRWalletRequest).subscribe(
        (result) => {
          this.showLoader = false;
          Swal.fire(" Success", "INR Wallet is activated successfully", "success");
        },
        (error) => {
          this.showLoader = false;
          if (error.status==200) {
             Swal.fire(" Success", "INR Wallet is activated successfully", "success");
            //this.backClicked();
             this.router.navigate(["/my-card"]);

          } else {
              Swal.fire("Error", error.error.message, "error");

          }              

        }
      );
    }
  }


  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }


  backClicked() {
    this.location.back();
  }

}