import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { TutorialComponent } from './view/tutorial.component';
import {MatStepperModule} from '@angular/material/stepper';

const routes = [
  {
    path: 'tutorial',
    component: TutorialComponent,
    data: { animation: 'TutorialComponent' },
    canActivate : [AuthGuard]
  } ,
  
];

@NgModule({
    declarations: [ TutorialComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      NgbModule,
      FormsModule,
      ReactiveFormsModule,
      MatStepperModule,
    ],
    exports: [ 
        TutorialComponent
    ]
})
export class TutorialRoutingModule {}
