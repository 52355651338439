import { Injectable } from "@angular/core";
import { SinglePurposeItem } from "../models/shared-models";

@Injectable()
export class PurposeList {
    public static purpose = [
        {
            "key": "StudyAbroadUniversity",
            "icon": "assets/images/purposeIcon/StudyAbroad-University.png",
            "title": "University Fee",
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "key": "FamilyMaintenance",
            "icon": "assets/images/purposeIcon/Family-Maintenance.png",
            "title": "Family\nMaintenance",
            "purposeCode": "FAMILY_MAINTENANCE",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "GiftDonation",
            "icon": "assets/images/purposeIcon/GiftBox.png",
            "title": "Gift",
            "purposeCode": "GIFT",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "TravelAccommodation",
            "icon": "assets/images/purposeIcon/Travel-Accommodation.png",
            "title": "Travel & Accommodation",
            "purposeCode": "TRAVEL_AND_ACCOMMODATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "MedicalExpenses",
            "icon": "assets/images/purposeIcon/Medical-Expenses.png",
            "title": "Medical\nExpenses",
            "purposeCode": "MEDICAL_TREATMENT_ABROAD",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "key": "Emigration",
            "icon": "assets/images/purposeIcon/Emigration.png",
            "title": "Emigration",
            "purposeCode": "EMIGRATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "key": "DebtEquity",
            "icon": "assets/images/purposeIcon/Debt-Equity.png",
            "title": "Debt & Equity",
            "purposeCode": "INVESTMENT_IN_DEBT",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "key": "PrivateVisit",
            "icon": "",
            "title": "Private Visit",
            "purposeCode": "PRIVATE_VISIT",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        }
    ];

    public static getPurposeObjectByPurposeCode(purposeCode: string) {
        var returnPurpose: SinglePurposeItem;
        PurposeList.purpose.forEach(singlePurpose => {
            if (singlePurpose.purposeCode == purposeCode) {
                returnPurpose = singlePurpose;
            }
        });
        return returnPurpose;
    }

    public static getPurposeDashboard() {
        let purposeMenu: Array<SinglePurposeItem> = [];
        PurposeList.purpose.forEach(singlePurpose => {
            if (singlePurpose.isDashboardShow == "true") {
                purposeMenu.push(singlePurpose);
            }
        });
        return purposeMenu;
    }

    // ---------------------------------------------------------
    public static purposeRemittance = [
        {
            "title": "University Fees",
            "icon": "assets/images/purposeIcon/universityFees.png",
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "title": "GIC Payment",
            "icon": "assets/images/purposeIcon/GICPayment.png",
            "purposeCode": "OVERSEAS_EDUCATION_GIC",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "title": "Visa Fee",
            "icon": "assets/images/purposeIcon/visaFees.png",
            "purposeCode": "VISA_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Tour Remittance",
            "icon": "assets/images/purposeIcon/travelInsurence.png",
            "purposeCode": "TOUR_REMITTANCE_OTHERS",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Medical Treatment",
            "icon": "assets/images/purposeIcon/medicalTreatment.png",
            "purposeCode": "MEDICAL_TREATMENT_ABROAD",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Global Conference",
            "icon": "assets/images/purposeIcon/globalConference.png",
            "purposeCode": "FEES_FOR_PARTICIPATION_IN_GLOBAL_CONFERENCES_SPECIALIZED_TRAINING",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Immigration Fee",
            "icon": "assets/images/purposeIcon/ImmigrationFees.png",
            "purposeCode": "EMIGRATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
        {
            "title": "Immigration Fees",
            "icon": "assets/images/purposeIcon/ImmigrationFees.png",
            "purposeCode": "EMIGRATION_FEES",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Portuguese Visa Fee",
            "icon": "assets/images/purposeIcon/portugueseVisa.png",
            "purposeCode": "PROCESSING_FEES_FOR_REGISTRATION_OF_DOCUMENTS_AS_REQUIRED_BY_THE_PORTUGUESE_OR_OTHER_GOVERNMENTS",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Oversea Examination Fees",
            "icon": "assets/images/purposeIcon/overseaexaminationfee.png",
            "purposeCode": "EXAMINATION_FEES",
            "remittanceType": "STUDENT",
            "isDashboardShow": "true"
        },
        {
            "title": "Overseas Job Application",
            "icon": "assets/images/purposeIcon/overSeaJobApplication.png",
            "purposeCode": "EMPLOYMENT_AND_PROCESSING_ASSESSMENTS_FEES_FOR_OVERSEAS_JOB_APPLICATIONS",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "International Subscription Fees",
            "icon": "assets/images/purposeIcon/internationalSubscription.png",
            "purposeCode": "REGISTRATION_SUBSCRIPTION_MEMBERSHIP_FEES_TO_INTERNATIONAL_ORGANIZATION",
            "remittanceType": "OTHERS",
            "isDashboardShow": "true"
        },
        {
            "title": "Private Visit",
            "icon": "assets/images/purposeIcon/internationalSubscription.png",
            "purposeCode": "PRIVATE_VISIT",
            "remittanceType": "OTHERS",
            "isDashboardShow": "false"
        },
    ];

    public static getPurposeObjectByPurposeCodeRemittance(purposeCode: string) {
        var returnPurpose: SinglePurposeItem;
        PurposeList.purposeRemittance.forEach(singlePurpose => {
            if (singlePurpose.purposeCode == purposeCode) {
                returnPurpose = singlePurpose;
            }
        });
        return returnPurpose;
    }

    public static getPurposeListAgentPortal() {
        let purposeMenu: Array<SinglePurposeItem> = [];
        PurposeList.purposeRemittance.forEach(singlePurpose => {
            if (singlePurpose.isDashboardShow == "true") {
                purposeMenu.push(singlePurpose);
            }
        });
        return purposeMenu;
    }

    // ---------------------------------------------------------
    public static homePagePurposeList = [
        {
            "key":"UniversityFees",
            "title": "University Fees",
            "icon": "assets/images/purposeIcon/university.png",
            "purposeCode": "UNIVERSITY_FEES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "UniversityFeesOwnAccount",
            "icon": "assets/images/smartfx-home/UniFeesOwnAccount.svg",
            "title": "University Fees Own Account",
            "purposeCode": "UNIVERSITY_FEES_OWN_ACCOUNT",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "StudentLivingExpenses",
            "icon": "assets/images/purposeIcon/costs.png",
            "title": "Student Living Expenses",
            "purposeCode": "STUDENT_LIVING_EXPENSES",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "GIC",
            "title": "GIC",
            "icon": "assets/images/purposeIcon/GIC.png",
            "purposeCode": "GIC",
            "remittanceType": "STUDENT",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "BlockAccount",
            "title": "Block Account",
            "icon": "assets/images/purposeIcon/bank.png",
            "purposeCode": "BLOCK_ACCOUNT",
            "remittanceType": "STUDENT",
            "isPurpose": "false",
            "pageUrl":''
        },
        // {
        //     "key":"ISICCard",
        //     "title": "ISIC Card",
        //     "icon": "assets/images/purposeIcon/id-card.png",
        //     "purposeCode": "",
        //     "remittanceType": "STUDENT",
        //     "isPurpose": "false",
        //     "pageUrl" : "/isicCard"
        // },
        // {
        //     "key":"OverseasBankAccount",
        //     "title": "Overseas Bank Account",
        //     "icon": "assets/images/purposeIcon/bank-acc.png",
        //     "purposeCode": "",
        //     "remittanceType": "STUDENT",
        //     "isPurpose": "true",
        //     "pageUrl":''
        // },
        {
            "key":"TourRemittance",
            "title": "Tour Remittance",
            "icon": "assets/images/purposeIcon/travel-and-tourism.png",
            "purposeCode": "TOUR_REMITTANCE",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key":"EmigrationFee",
            "title": "Emigration",
            "icon": "assets/images/purposeIcon/migration.png",
            "purposeCode": "EMIGRATION",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "MedicalExpenses",
            "title": "Medical Expenses",
            "icon": "assets/images/purposeIcon/expenses.png",
            "purposeCode": "MEDICAL_EXPENSES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "VisaFee",
            "title": "Visa Fee",
            "icon": "assets/images/purposeIcon/boarding-pass.png",
            "purposeCode": "VISA_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "ExaminationFees",
            "title": "Examination Fees",
            "icon": "assets/images/purposeIcon/exam.png",
            "purposeCode": "EXAMINATION_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "JobVisit",
            "title": "Job Visit",
            "icon": "assets/images/purposeIcon/job.png",
            "purposeCode": "JOB_APPLICATION",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "SpecialisedTrainingFees",
            "title": "Specialised Training Fees",
            "icon": "assets/images/purposeIcon/presentation.png",
            "purposeCode": "SPECIALISED_TRAINING_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "InternationalCompetitionFees",
            "title": "International Competition Fees",
            "icon": "assets/images/purposeIcon/medal.png",
            "purposeCode": "INTERNATIONAL_COMPETITION_FEES",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "AttendingConference",
            "title": "Attending Conference",
            "icon": "assets/images/purposeIcon/conference.png",
            "purposeCode": "ATTENDING_CONFERENCE",
            "remittanceType": "OTHERS",
            "isPurpose": "true",
            "pageUrl":''
        },
        {
            "key": "BuyNewCard",
            "title": "Buy New Card",
            "icon": "assets/images/purposeIcon/buy-card.png",
            "purposeCode": "",
            "remittanceType": "FOREXCARD",
            "isPurpose": "false",
            "pageUrl":"/buy-new-card"
        },
        {
            "key": "LinkExistingCard",
            "title": "Link Existing Card",
            "icon": "assets/images/purposeIcon/payment-method.png",
            "purposeCode": "",
            "remittanceType": "FOREXCARD",
            "isPurpose": "false",
            "pageUrl" : "/link-existing-card"
        },
    ];

    public static getPurposeObjectByRemittanceType(remittanceType: string) {
        let purposeSubMenu: Array<SinglePurposeItem> = [];
        PurposeList.homePagePurposeList.forEach(singlePurpose => {
            if (singlePurpose.remittanceType == remittanceType.toUpperCase()) {
                purposeSubMenu.push(singlePurpose);
            }
        });
        return purposeSubMenu;
       
    }

    public static getPurposeObjectByPurposeCodeStudentRemittance(purposeCode: string) {
        var returnPurpose: SinglePurposeItem;
        PurposeList.homePagePurposeList.forEach(singlePurpose => {
            if (singlePurpose.purposeCode == purposeCode) {
                returnPurpose = singlePurpose;
            }
        });
        return returnPurpose;
    }
}

