import { Component, OnInit, ViewEncapsulation, VERSION } from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { AddBankAccountRequest } from '../../models/bank-account.model';
import { BankAccountService } from '../../services/bank-account.service';
import { Constants } from 'app/shared/constant/constants';

@Component({
  selector: 'add-bank-account',
  templateUrl: './add-bank-account.component.html',
  // styleUrls: ['../../../../../assets/scss/select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddBankAccountComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public addBankAccountRequest = new AddBankAccountRequest();
  public bankDropdownItemList: any;
  public consumerId: any;
  public consumerMobile: any;
  public showLoader: boolean = false;
  public showAccountNo: boolean = false;
  public validIFSC: boolean = false;
  public bankRelationshipList = Constants.payerList;

  constructor(
    public bankAccountService: BankAccountService,
    public toast: ToastrService,
    private location: Location,
    public router: Router) { }

  ngOnInit() {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    let consumerData = JSON.parse(localStorage.getItem(ConstantsKey.CONSUMER_DATA));
    this.consumerMobile = consumerData.mobile
    this.getBankList();
  }

  getBankList() {
    var singleObject;
    var localBankDropdownItemList;
    this.bankAccountService.getPaymentModes().subscribe(res => {

      localBankDropdownItemList = res.body;
      for (let i = 0; i < localBankDropdownItemList.length; i++) {
        singleObject = localBankDropdownItemList[i];
        if (singleObject.categoryName == "Net Banking") {
          this.bankDropdownItemList = singleObject.paymentModeCategories;
        }
      }

    });
  }

  addBankAccount(data) {
    if (data === true) {
      this.showLoader = true;
      if (this.addBankAccountRequest.number === this.addBankAccountRequest.confirmAccNo) {
        this.addBankAccountRequest.consumerId = this.consumerId;
        this.addBankAccountRequest.default = true;
        this.addBankAccountRequest.type = 'SAVINGS';
        this.addBankAccountRequest.isDefault = true;
        this.addBankAccountRequest.mobileNo = this.consumerMobile;
        console.log(JSON.stringify(this.addBankAccountRequest));

        this.bankAccountService.addMyBankAccount(this.addBankAccountRequest).subscribe(resp => {
          this.showLoader = false;
          this.toast.success("Bank account added successfully", "Success !!");
          this.router.navigate(['/list-bank-account']);
        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });
      } else {
        this.showLoader = false;
        this.toast.error("Account no and confirm account no. must be same");
      }
    }
  }

  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }


  backClicked() {
    this.location.back();
  }
}