<style>
    .ng-select.ng-select-single .text-white .ng-value-container .ng-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff !important;
        font-weight: 500;
        border-color: #000 !important;
        border-radius: 0ch !important;
    }
</style>
<div class="content-wrapper container-xxl p-0">
    <div class="row">
        <div class="content-body col-md-12">
            <loader *ngIf="showLoader"></loader>
            <div class="card">
                <div class="card-body">
                    <section id="home-page">
                        <div class="row">
                            <div class="col-md-12 fs-14 text-black20 fw-800">Select Amount</div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2 col-sm-3 col-6 mb-1 mt-20">
                                        <!-- <ng-select class="fs-12 dropdown-black" [items]="currencyItems" [(ngModel)]="quotationCurrencyItem.currencyCode" bindLabel="currencyCode" bindValue="currencyCode"
                                          placeholder="Select Currency" (change)="getRateRange()" [clearable]="false" >
                                            <ng-template class="ng-select-container" ng-option-tmp ng-label-tmp let-item="item" style="color: white">
                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                            </ng-template>
                                        </ng-select> -->
                                        <ng-select class="fs-12 dropdown-black" [clearable]="false" [items]="currencyItems" [(ngModel)]="quotationCurrencyItem.currencyCode" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency" (change)="getRateRange()" [searchable]="false">
                                            <ng-template class="text-white ng-select-container" ng-option-tmp ng-label-tmp let-item="item">
                                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:15px;width: 15px;"> {{item.currencyCode}}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-6 pl-0 mt-20">
                                        <input type="number" class="form-control fs-12" min="1" placeholder="Amount" [(ngModel)]="quotationCurrencyItem.currencyAmount" aria-label=" Amount " onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187"
                                            (keypress)="keyPressNumbers($event)" style="border-color: black;" />
                                    </div>
                                    <div class="col-md-2 col-sm-12 text-center">
                                        <button type=" submit " rippleEffect class="btn btn-primary fs-11 mt-20 btn-width-100" (click)="enterCurrencyAmount()" style="height:38px;padding:12px">Add to
                                            cart</button>
                                    </div>
                                    <div class="col-md-6" *ngIf="quotationResponse != null">
                                        <div class="mt-20" style="background: #b6b1b1;padding: 10px;border-radius: 5px">
                                            <div class="row">
                                                <div class="col-md-7 col-7">
                                                    <span>
                                                        <img class="flag-icon"
                                                            src="assets/images/currencyFlag/{{quotationResponse.quotationCurrencyResponses[0].currencyCode}}.png">
                                                    </span>
                                                    <span class="fw-600 text-black fs-10">
                                                        {{
                                                        quotationResponse?.quotationCurrencyResponses[0]?.currencyCode
                                                        }}
                                                        {{
                                                        quotationResponse?.quotationCurrencyResponses[0]?.currencyAmount
                                                        }}
                                                        =
                                                    </span>
                                                    <span>
                                                        <img src="assets/images/logo/india.png" class="flag-icon">
                                                    </span>
                                                    <span class="fw-600 text-black fs-10">
                                                        ₹
                                                        {{
                                                        quotationResponse?.quotationCurrencyResponses[0]?.inrEquivalentAmountString
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="col-md-5 col-5 text-right">
                                                    <div class="fs-9 text-black fw-500 middle-text"> 1 {{ quotationCurrencyItem.currencyCode }} = {{ singleRateResItem.minimumRateInINR }} INR
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" row mt-40">
                            <div class="col-md-12 mb-20 fs-14 text-black20 fw-800 " style="color: black">
                                Send Money Abroad in Jiffy
                            </div>

                            <!-- <div class="col-md-5" >
                                <select placeholder="Select Purpose" style="height: 36.54px;" class="form-control card-shadow fs-12" (change)="getActiveSectionTitle($event.target.value)">
                                    <option *ngFor="let singlePurpose of purposeListRemittance" value='{{ singlePurpose.purposeCode }}'>
                                        {{ singlePurpose.title }}
                                    </option>
                                </select>
                            </div> -->



                            <div class=" col-md-12 col-xl-12 col-12">
                                <div class=" content-wrapper container-xl p-0 ">
                                    <section id=" wishlist " class="grid-view wishlist-items ">
                                        <div *ngFor=" let singlePurpose of purposeListRemittance ;let i=index " (click)=" getActiveSectionTitle(singlePurpose.purposeCode) ">
                                            <div style=" padding-top: 10px; ">
                                                <div class="dashboard-icon" style=" width: 72px; height: 72px; " [ngClass]=" selectedPurposeItem?.title==singlePurpose.title
                                                ? 'ecommerce-card-selected' : '' ">
                                                    <div style=" padding-top:13px; ">
                                                        <img src="{{ singlePurpose.icon }}" style=" width: 42px;margin: auto;display: block; ">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card-body">
                                                <a>
                                                    <div class="fw-600 text-black fs-12 text-center">{{ singlePurpose.title }}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-md-2 mb-20 fs-13 text-black20" *ngIf="!purposeEleven">
                                <a class="fw-900" style="color:#1a3cc3 ;text-decoration:underline">
                                    <div class="fs-13  pl-25" (click)="showLessMorePurpose(11)">More</div>
                                </a>
                            </div>
                            <div class="col-md-2 mb-20 fs-13 text-black20" *ngIf="purposeEleven">
                                <a class="fw-900" style="color:#1a3cc3;text-decoration:underline">
                                    <div class="fs-13  pl-25" (click)="showLessMorePurpose(8)">Less</div>
                                </a>
                            </div> -->
                            <div class="col-md-2 offset-md-10 text-right pr-30">
                                <button type="submit" rippleEffect class="btn btn-primary fs-13 btn-gradient" (click)="proceedToStepper()">Go</button>
                            </div>
                        </div>


                    </section>
                </div>
            </div>
            <!-- <div class="card mt-30">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 col-12 mb-10">
                            <img src="assets/images/dashboard/banner.png" style="width: 100%;">
                        </div>
                        <div class=" col-md-3 col-sm-3 mb-10 col-12 mt-10">
                            <div class=" fs-15 fw-500 " style=" color: #5e5873 ">Get your Smart
                                <div class=" fs-22 fw-900 " style=" color: #5e5873 "> Forex Card</div>
                                <div class=" fs-15 fw-500 " style=" color: #5e5873 "> here !</div>
                            </div>
                        </div>
                        <div class=" col-md-6 col-sm-5 col-12">

                            <div class="card-text-bottom fs-10 ">With the WSFx Smart Currency Card (WSCC), you can globe-trot with confidence. This app enables multi-currency prepaid card comes with 14 key international currencies and an INR wallet. </div>

                            <div class="row" style="padding-left: 15px;">
                                <button type="col-md-6 submit" rippleEffect class=" btn btn-primary fs-11 mt-20" style="padding:11px !important" routerLink="/buy-new-card">Buy New Card</button> &nbsp;&nbsp;&nbsp;
                                <button type="col-md-6 submit" rippleEffect class=" btn btn-primary  fs-11 mt-20" style="padding:11px !important" routerLink="/link-existing-card">Link Existing Card</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div> -->
            <loader *ngIf="showLoader"></loader>

            <!-- <div class="row">
                <div class="col-md-6 col-12 mt-10" *ngIf="vcipStatusDetails?.vcipStatus != 'ACCEPTED'">
                    <div class="card card-body" style="padding: 40px 40px 20px 40px !important;margin-bottom:0px !important">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img src="assets/images/dashboard/KYC.png" style="width: 100%;">
                            </div>
                            <div class="col-md-8 col-8">
                                <div class="fs-14 fw-600 text-black">Complete your KYC </div>
                                <div class="fs-11 mt-20">Complete your One-time Video-KYC if you are currrently located in India. </div>
                                <button type="submit" rippleEffect class="btn btn-primary fs-11 btn-gradient mt-40" style="float : right" routerLink="/update-profile">Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-12 mt-10">
                    <div class="card card-body" style="padding:  40px 20px 20px 20px !important;margin-bottom:0px !important">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img src="assets/images/dashboard/banner.png" style="width: 100%;">
                            </div>
                            <div class="col-md-8 col-8">
                                <div class="fs-12 fw-500 text-black">Get your Smart</div>
                                <div class="fs-14 fw-600 text-black">Forex Card Here!</div>
                                <div class="fs-11 mt-10">
                                    <div class="card-text-bottom fs-10 " style="color: black" *ngIf="!showMoreContents">With the WSFx Smart Currency Card (WSCC), you can globe-trot with confidence. </div>
                                    <div class="card-text-bottom fs-10 " style="color: black" *ngIf="showMoreContents">With the WSFx Smart Currency Card (WSCC), you can globe-trot with confidence. This app enables multi-currency prepaid card comes with 14 key international currencies and an INR wallet. </div>
                                </div>

                                <div class="link fs-11 blue-text" (click)="buttonText == 'View More' ? showContents() : hideContents()">{{buttonText}}
                                </div>

                                <div class="row" style="padding-left: 15px;" *ngIf="showCardDetailsBox">
                                    <button type="col-md-6 submit" rippleEffect class=" btn btn-primary fs-11 mt-20" style="padding:11px !important" routerLink="/buy-new-card">Buy New Card</button> &nbsp;&nbsp;&nbsp;
                                    <button type="col-md-6 submit" rippleEffect class=" btn btn-primary  fs-11 mt-20" style="padding:11px !important" routerLink="/link-existing-card">Link Existing
                                          Card</button>
                                    <div class="row" style="padding-left: 15px;" *ngIf="!showCardDetailsBox">
                                        <button type="col-md-6 submit" rippleEffect class=" btn btn-primary fs-11 mt-20" style="padding:11px !important" routerLink="/my-card">My Card</button> &nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-12 mt-10" *ngIf="!showCardDetailsBox">
                        <div class="card card-body" style="padding: 40px 40px 20px 40px !important;margin-bottom:0px !important">
                            <div class="row">
                                <div class="col-md-4 col-4">
                                    <img src="assets/images/dashboard/TransactionPending.png" style="width: 100%;">
                                </div>
                                <div class="col-md-8 col-8">
                                    <div class="fs-14 fw-600 text-black">Pending Transactions </div>
                                    <div class="fs-11 mt-20 text-black fw-500"> Academic Power House Limited </div>
                                    <div class="fs-11"> Purpose : University Fees </div>
                                    <div class="fs-11"> <span class="text-black fw-500">Account : </span> Academic </div>
                                    <button type="submit" rippleEffect class="btn btn-primary fs-11 btn-gradient mt-20" style="float : right" routerLink="/order-list">Complete Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->



        </div>
    </div>
</div>