import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../../services/order.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { PaymentMode } from 'app/shared/constant/payment-mode';

@Component({
  selector: 'retry-payment',
  templateUrl: './retry-payment.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RetryPaymentComponent implements OnInit {

  public bankAccountList: any;
  public showLoader: boolean = false;
  public bankAccountId: any;

  public paymentMode: any;
  public orderID: any;
  public orderDetails: any;
  public paymentMethod: any;
  public isPartial : boolean ;
  public selectedPaymentMode: any;
  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;

  constructor(
    private router: Router,
    public remittanceService: RemittanceService,
    public toast: ToastrService,
    public orderService: OrderService,
    private route: ActivatedRoute,
  ) {
    this.orderID = this.route.snapshot.paramMap.get('orderID');
  }

  ngOnInit() {
    this.getOrderDetails();
    this.getBankAccountList();
    this.paymentMethod = false;
    this.isPartial = false;
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    }, error => {
    });
  }

  getOrderDetails() {
    this.showLoader = true;
    this.orderService.getOrderDetailsSingle(this.orderID).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  getSelectedPaymentType(isPartial) {
    this.paymentMethod = isPartial;
  }

  getSelectedPaymentMode(paymentModeValue) {
    this.selectedPaymentMode = paymentModeValue;
    sessionStorage.setItem(ConstantsKey.PAYMENT_METHOD, paymentModeValue);
  }

  proceedToPayment() {
    if (this.paymentMode) {
      if (this.bankAccountId) {
        if (this.paymentMethod != null && this.paymentMode != '') {
          if (this.paymentMode == PaymentMode.NET_BANKING) {
        this.getPaymentGatewayLink(this.orderID);
      } else {
        this.router.navigate(['/payment-success', this.orderID]);
      }
        } else {
          this.toast.error('Please select payment method! ');
        }
      } else {
        this.toast.error('Please select bank account ! ');
      }
    } else {
      this.toast.error('Please select payment mode !');
    }
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getPaymentGatewayLink(orderId: string) {
      this.showLoader = true;

    this.orderService.getRetryPaymentGatewayLinkOrders(orderId, this.bankAccountId).subscribe(respPaymentLink => {
      this.showLoader = false;
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      window.location.href = responsePaymentLinkBody.payment_link;
      sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'true');
      sessionStorage.setItem(ConstantsKey.PAYMENT_METHOD, 'NB');
    }, error => {
      this.showLoader = false;
      console.log("Error in generating payment link");
    });
  }
}