import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CardValidationRequestModel } from '../../models/card.model';
import { CardService } from '../../services/card.service';

@Component({
  selector: 'validate-card-details',
  templateUrl: './validate-card-details.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ValidateCardDetailsComponent implements OnInit {
  
  public cardValidationRequestModel = new CardValidationRequestModel();
  public dob: any;
  public showLoader : boolean = false;

  constructor(
    public cardService: CardService,
    public toast: ToastrService,
    private _router: Router,
  ) { }

  ngOnInit() {
  }

  getValidateCardDetails(validData) {
    if(validData){
      this.showLoader = true;
      this.cardValidationRequestModel.dob = this.dob;
      this.cardService.validateExpiryDob(this.cardValidationRequestModel).subscribe(resp=>{
        this.showLoader  = false;

        this._router.navigate(['/my-card']);
        this.toast.success("Card Linked Successfully.");
    }, error=>{
      this.showLoader  =false;
      this.toast.error(error.error.message);
    });
  }
  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.dob = year + "-" + month + "-" + day;
    console.log(this.dob);
  }
}
