import { Component, OnInit} from '@angular/core'
import { Constants } from 'app/shared/constant/constants';
import { constants } from 'buffer';
import { RatesService } from '../services/rates.service';

@Component({
  selector: 'rates',
  templateUrl: './rates.component.html',
})
export class RatesComponent implements OnInit {
  
  public rateList : any;
  public showLoader : boolean = false;
  constructor(public rateService:RatesService) {}

  ngOnInit() {
    this.getRateListAll(Constants.productsEnum[2]);
  }

  getRateListAll(productType){
    this.showLoader = true;
    this.rateService.getRatesList(productType).subscribe(res=>{
      this.showLoader = false;
      this.rateList = [];
      this.rateList = res.body[0].rates;
      console.log(this.rateList);
    },error=>{
      this.showLoader = false;
    })
  }
}
