import { Component, Input, OnInit,SimpleChanges, ViewEncapsulation } from '@angular/core'
import { QuotationCurrencyResponsesModel, QuotationRequestModel } from 'app/modules/card/models/card.model';
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';

@Component({
  selector: 'card-stepper-footer',
  templateUrl: './card-stepper-footer.component.html',
  styleUrls: ['../../../../remittance/stepper.scss','./card-stepper-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardStepperFooterComponent implements OnInit {

  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public quotationCurrencyResponsesList = new Array<QuotationCurrencyResponsesModel>();

  public progressWidth: any;
  public progressWidthInPercentage: number = 0;
  public isCartEmpty: boolean = false;

  @Input() refreshQuotationCountFooter: number = 0;

  constructor() { }

  ngOnInit() {
    //this.timer();
    this.getSessionData();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      // console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }
     
    this.getSessionData();
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
      this.quotationCurrencyResponsesList = this.quotationResponse['quotationCurrencyResponses'];

    
    if(  this.quotationCurrencyResponsesList!=null &&   this.quotationCurrencyResponsesList.length>0 ){
      this.isCartEmpty=false;
    }else{
      this.isCartEmpty=true;

    }
  }

  timer() {
    let seconds: number = Constants.timerValue;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = seconds < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.progressWidth = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      this.progressWidthInPercentage = (seconds / Constants.timerValue) * 100;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        this.timer();
      }
    }, 1000);
  }
}
