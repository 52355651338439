import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class DirectSignupService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  getDataFromToken(authToken){
    var resourceUrl = 'getConsumerAuthFromToken?authToken=' + authToken;
    return this.makeGetCall(resourceUrl);
}

}