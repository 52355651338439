import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";
import { CoreDirectivesModule } from "@core/directives/directives";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { LoaderRoutingModule } from "app/shared/utility/utility-routing.module";
import { BeneficiaryService } from "./services/beneficiary.service";
import { AddBeneficiaryComponent } from "./view/add-beneficiary/add-beneficiary.component";
import { DetailsBeneficiaryComponent } from "./view/details-beneficiary/details-beneficiary.component";
import { ListBeneficiaryComponent } from "./view/list-beneficiary/list-beneficiary.component";

const routes = [
  {
    path: 'add-beneficiary',
    component: AddBeneficiaryComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]
  },
  {
    path: 'details-beneficiary',
    component: DetailsBeneficiaryComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]
  },
  {
    path: 'list-beneficiary',
    component: ListBeneficiaryComponent,
    data: { animation: 'home' },
    canActivate : [AuthGuard]
  }
];

@NgModule({
  declarations: [ListBeneficiaryComponent, AddBeneficiaryComponent, DetailsBeneficiaryComponent],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    FormsModule,
    NgSelectModule,
    CoreDirectivesModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    LoaderRoutingModule
  ],
  exports: [ListBeneficiaryComponent, AddBeneficiaryComponent, DetailsBeneficiaryComponent,],
  providers: [BeneficiaryService]

})
export class BeneficiaryRoutingModule { }
