<loader *ngIf="showLoader"></loader>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="{{ coreConfig.app.appLogoImage }}" height="58" />
                </a>
                <ngb-alert [type]="'danger'" *ngIf="error != '' " class="mt-1" [dismissible]="false">
                    <div class="alert-body">
                        <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                        <span class="fs-12">{{error}}</span>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="forgetPwdForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="login-email">Mobile</label>
                        <input type="number" formControlName="id" class="form-control fs-12" [(ngModel)]="requestModelForForgetPwd.id" placeholder="Enter Mobile" maxlength="10" />
                        <!-- <div *ngIf="submitted && f.id.errors" class="invalid-feedback">
                            <div *ngIf="f.id.errors.required">Mobile no is required</div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between ">
                            <label for="login-password">OTP</label>
                            <a (click)="getOTPForForgetPwd()">
                                <small>Send OTP</small>
                            </a>
                        </div>
                        <div class="input-group input-group-merge
                            form-password-toggle">
                            <input type="number" maxlength="6" placeholder="Enter OTP" formControlName="otp" class="form-control fs-12 form-control-merge" [(ngModel)]="requestModelForForgetPwd.otp" [readonly]="readonlyFields" />
                        </div>

                        <div class="form-group mt-2">
                            <div class="d-flex justify-content-between ">
                                <label for="login-password">Password</label>
                            </div>
                            <div class="input-group input-group-merge
                                form-password-toggle">
                                <input type="password" formControlName="newPassword" class="form-control fs-12" placeholder="Enter Password" [(ngModel)]="requestModelForForgetPwd.newPassword" [readonly]="readonlyFields" />
                            </div>
                        </div>

                        <!-- <div *ngIf="submitted && f.otp.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.otp.errors }">
                            <div *ngIf="f.otp.errors.required">OTP is required</div>
                        </div> -->
                    </div>
                    <button class="btn btn-primary btn-block" rippleEffect>Submit</button>
                </form>

                <p class="text-center mt-2 fs-12">
                    <a routerLink="/login">
                        <span>&nbsp;Back to Login</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>