export class TestRequestModel {
  testString: string;
}

export class InsuranceAddRequestModel{
  arrivalDate: string;
  departureDate: string;
  noOfDaysTravel: number;
  relationship: string;
  name: string;
  address1: string;
  address2: string;
  dob: any;
  passportNumber: string;
  DepartureDate: string;
  NoOfDaysTravel: number;
  ArrivalDate: string;
  nomineeName: string;
  nomineeRelationShip: string;
  mobile: string;
  consumerId: string;
  paxId: string;
  email: string;
  universityName: string;
  universityAddress: string;
  sponsorName: string;
  city: string;
  state: string;
  district: string;
  pincode: string;
  pastIllness: string;
  travelTagNumber: string;
  phoneNo: string;
  country: string;
  sponsorRelationShip: string;
  orderformdetailsId: string;
  ordercartdetailsId: string;
}

export class TravelInsuranceOrderRequest {
  ordercartdetailsId: string;
  agelimit: number;
  noOfDaysTravel: number;
  premium: number;
  consumerId: string;
  paxId: string;
  orderformdetailsId: string;
  planName: string;
  productId: string;
  sumInsuranceAmount: string;
  agreement: true
}