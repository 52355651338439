<div class="content-wrapper container-xxl p-0">
    <div class="mt-20">
        <div class="row mb-20">
            <div class="col-md-9 col-6">
                <div class="fs-13 text-black fw-600">
                    Added {{listTitle}}
                </div>
            </div>
            <div class="col-md-3 col-6 text-right" *ngIf="listTitle === 'Beneficiaries'">
                <button type="button" class="btn btn-primary btn-prev fs-11" rippleEffect (click)="goToAddBeneficiary()">
                    <span class="align-middle">Add Beneficiary</span>
                </button>
            </div>
            <div class="col-md-3 col-6 text-right" *ngIf="listTitle != 'Beneficiaries'">
                <button type="button" class="btn btn-primary btn-prev fs-11" rippleEffect (click)="goToAddBeneficiary()">
                    <span class="align-middle">Add University</span>
                </button>
            </div>
        </div>

        <div class="row" *ngIf="noDataFound">
            <div class="col-md-4 text-center">
                <div class="card card-body text-black fw-500">
                    <div class="fs-10"> No {{listTitle}} added </div>
                </div>
            </div>
        </div>

        <div class="row" *ngFor="let singleBeneficiary of beneficiaryListItems; let i =index">
            <div class="col-md-9">
                <section>
                    <div class="row">
                        <div class="col-md-12 col-10 mb-10">
                            <input type="radio" name="radios" id="radio" (change)="updateSelectedBeneficiaryToSession(singleBeneficiary)" />
                            <span class="card-title fs-12 ml5" (click)="showBeneficiaryDetails(singleBeneficiary)" style="cursor: pointer;">{{singleBeneficiary.viaAgentName | titlecase}}</span>
                            <span class="card-title fs-12 ml5" *ngIf="singleBeneficiary.purpose != 'UNIVERSITY_FEES' && singleBeneficiary.purpose != 'UNIVERSITY_FEES_OWN_ACCOUNT'  " (click)="showBeneficiaryDetails(singleBeneficiary)" style="cursor: pointer;">{{singleBeneficiary.name}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11  col-11 ml-15">
                            <span class="card-text fs-11 fw-600">Purpose : </span>
                            <span class="fs-11"> {{ singleBeneficiary.purpose.replaceAll('_', ' ') | titlecase }}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11  col-11 ml-15">
                            <span class="card-text fs-11 fw-600">Account Holder Name : </span>
                            <span class="fs-11">{{ singleBeneficiary.name }}</span>
                        </div>
                    </div>

                    <div class="row" *ngIf="singleBeneficiary.beneficiaryPaymentType != null && singleBeneficiary.beneficiaryPaymentType != '' ">
                        <div class="col-md-11  col-11 ml-15 ">
                            <span class="card-text fs-11  fw-600">Payment Type : </span>
                            <span class="fs-11 "> {{singleBeneficiary.beneficiaryPaymentType}}</span>
                        </div>
                    </div>

                    <div class="row " *ngIf="selectedPurpose.purposeCode=='UNIVERSITY_FEES' || selectedPurpose.purposeCode=='UNIVERSITY_FEES_OWN_ACCOUNT' ">
                        <div class="col-md-11  col-11 ml-15 ">
                            <span class="card-text fs-11  fw-600">Bank Name :</span>
                            <span class="fs-11 "> {{singleBeneficiary.bankName}}</span>
                        </div>
                    </div>

                    <div class="row " *ngIf="selectedPurpose.purposeCode=='UNIVERSITY_FEES' || selectedPurpose.purposeCode=='UNIVERSITY_FEES_OWN_ACCOUNT' ">
                        <div class="col-md-11  col-11 ml-15 ">
                            <span class="card-text fs-11  fw-600">Account Number :</span>
                            <span class="fs-11 "> {{singleBeneficiary.bankAccountNumber}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-11  col-11 ml-15 ">
                            <span class="card-text fs-11  fw-600">Status :</span>
                            <span class="fs-10 fw-600 " [ngClass]="(singleBeneficiary.beneficiaryStatusType=='AUTO_APPROVED' ||  singleBeneficiary.beneficiaryStatusType == 'APPROVED') ? 'text-success' : singleBeneficiary.beneficiaryStatusType=='PENDING' ? 'text-danger' : 'text-warning' ">
                                {{singleBeneficiary.beneficiaryStatusType}}</span>
                        </div>
                    </div>
                </section>

                <section *ngIf="showDetailsBeneficiary && (singleBeneficiaryData.id == singleBeneficiary.id)">
                    <div class="row">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600">Country Name :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.address}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.city != null && singleBeneficiaryData?.city != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600">City :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.city}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.bankAddress != null && singleBeneficiaryData?.bankAddress != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Account Holder Address :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.bankAddress}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.bankName != null && singleBeneficiaryData?.bankName != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Bank Name :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.bankName}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.bankAccountNumber != null && singleBeneficiaryData?.bankAccountNumber != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Account number/IBAN :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.bankAccountNumber}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.studentId != null && singleBeneficiaryData?.studentId != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Student ID :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.studentId}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.sortCode != null && singleBeneficiaryData?.sortCode != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600">SORT/ABA/BSB/TRANS No :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.sortCode}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.intermediaryBankSwiftCode != null && singleBeneficiaryData?.intermediaryBankSwiftCode != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Intermediary Bank Swift Code :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.intermediaryBankSwiftCode}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.remittanceForRelationship != null && singleBeneficiaryData?.remittanceForRelationship != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Relationship with Beneficiary :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData?.remittanceForRelationship}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.viaAgentName != null && singleBeneficiaryData?.viaAgentName != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> University Name :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.viaAgentName}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.name != null && singleBeneficiaryData?.name != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600">Account Holder Name :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.name}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.currencyCode != null && singleBeneficiaryData?.currencyCode != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Currency :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.currencyCode}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.address != null && singleBeneficiaryData?.address != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Bank Address :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.address}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.swiftCode != null && singleBeneficiaryData?.swiftCode != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Swift Code :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.swiftCode}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.flyWireReferenceNumber != null && singleBeneficiaryData?.flyWireReferenceNumber != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> GIC/FLYWIRE/WU REFERENCE NUMBER :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.flyWireReferenceNumber}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.intermediaryBankName != null && singleBeneficiaryData?.intermediaryBankName != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Intermediary Bank Name :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.intermediaryBankName}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.additionalDetails != null && singleBeneficiaryData?.additionalDetails != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Special Instructions :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData.additionalDetails}} </span>
                        </div>
                    </div>
                    <div class="row" *ngIf="singleBeneficiaryData?.mobileNo != null && singleBeneficiaryData?.mobileNo != '' ">
                        <div class="col-md-11 col-11 ml-15">
                            <span class="card-text fs-11  fw-600"> Beneficiary Mobile No :</span>
                            <span class="fs-11 "> {{singleBeneficiaryData?.mobileNo}} </span>
                        </div>
                    </div>
                </section>

                <section class="row mt-10">
                    <div class="col-md-3 col-11 ml-15">
                        <button type="submit" rippleEffect class="btn btn-bordered" (click)="buttonText == 'View More' ? showBeneficiaryDetails(singleBeneficiary) : hideDetailsBeneficiary() ">{{buttonText}}</button>
                    </div>
                </section>
            </div>


            <div class="col-md-12 mt-20 mb-20">
                <div [ngClass]="(beneficiaryListItems.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
                </div>
            </div>
        </div>
    </div>
</div>
























<!-- <section *ngIf="showDetailsBeneficiary ">
            <div class="mb-20" (click)="hideDetailsBeneficiary()" style="cursor: pointer;"><i data-feather="chevron-left" class="fs-17"></i> Back</div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group  row">
                        <div class="col-md-6 fw-500 text-black">Country Name</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.address}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.city != null && singleBeneficiary?.city != '' ">
                        <div class="col-md-6 fw-500 text-black">City</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.city}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.bankAddress != null && singleBeneficiary?.bankAddress != '' ">
                        <div class="col-md-6 fw-500 text-black">Account Holder Address</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.bankAddress}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.bankName != null && singleBeneficiary?.bankName != '' ">
                        <div class="col-md-6 fw-500 text-black">Bank Name</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.bankName}}

                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.bankAccountNumber != null && singleBeneficiary?.bankAccountNumber != '' ">
                        <div class="col-md-6 fw-500 text-black">Account number/IBAN</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.bankAccountNumber}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.studentId != null && singleBeneficiary?.studentId != '' ">
                        <div class="col-md-6 fw-500 text-black">Student ID</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.studentId}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.sortCode != null && singleBeneficiary?.sortCode != '' ">
                        <div class="col-md-6 fw-500 text-black">SORT/ABA/BSB/TRANS No</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.sortCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.intermediaryBankSwiftCode != null && singleBeneficiary?.intermediaryBankSwiftCode != '' ">
                        <div class="col-md-6 fw-500 text-black">Intermediary Bank Swift Code</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.intermediaryBankSwiftCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.remittanceForRelationship != null && singleBeneficiary?.remittanceForRelationship != '' ">
                        <div class="col-md-6 fw-500 text-black">Relationship with Beneficiary</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary?.remittanceForRelationship}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.viaAgentName != null && singleBeneficiary?.viaAgentName != '' ">
                        <div class="col-md-6 fw-500 text-black">University Name</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.viaAgentName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.name != null && singleBeneficiary?.name != '' ">
                        <div class="col-md-6 fw-500 text-black">Account Holder Name</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.name}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.currencyCode != null && singleBeneficiary?.currencyCode != '' ">
                        <div class="col-md-6 fw-500 text-black">Currency</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.currencyCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.address != null && singleBeneficiary?.address != '' ">
                        <div class="col-md-6 fw-500 text-black">Bank Address</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.address}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.swiftCode != null && singleBeneficiary?.swiftCode != '' ">
                        <div class="col-md-6 fw-500 text-black">Swift Code</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.swiftCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.flyWireReferenceNumber != null && singleBeneficiary?.flyWireReferenceNumber != '' ">
                        <div class="col-md-6 fw-500 text-black">GIC/FLYWIRE/WU REFERENCE NUMBER</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.flyWireReferenceNumber}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.intermediaryBankName != null && singleBeneficiary?.intermediaryBankName != '' ">
                        <div class="col-md-6 fw-500 text-black">Intermediary Bank Name</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.intermediaryBankName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.additionalDetails != null && singleBeneficiary?.additionalDetails != '' ">
                        <div class="col-md-6 fw-500 text-black">Special Instructions</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary.additionalDetails}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiary?.mobileNo != null && singleBeneficiary?.mobileNo != '' ">
                        <div class="col-md-6 fw-500 text-black">Beneficiary Mobile No</div>
                        <div class="col-md-6 text-black40">
                            {{singleBeneficiary?.mobileNo}}
                        </div>
                    </div>
                </div>
            </div>
        </section> -->