<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class="content-header-title float-left mb-0 ml-10" style="color: black">Redeemable Vouchers</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" style="background: #fff;margin-bottom:25px; ">
                            <li ngbNavItem class="ml-10" style="padding: 5px;">
                                <a ngbNavLink class="fs-11">Available</a>
                                <ng-template ngbNavContent>
                                    <section class="">
                                        <div class="row">
                                            <div class="col-md-3" *ngFor="let available of availablerewards">
                                                <div class="card">
                                                    <img class="card-img-top" src="{{ available.imageUrl }}" alt="Card image cap">
                                                    <div class="card-body text-center">
                                                        <div class="fs-14 fw-600 blue-title">{{ available.title }}</div>
                                                        <p class="card-text mt-10">Requires {{ available.pointsRequired }} Credit Points
                                                        </p>
                                                        <a href="#" class="btn btn-primary rounded-btn-blue" (click)="claimReward(available)">Claim</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                </ng-template>
                            </li>
                            <li ngbNavItem style="padding: 5px;">
                                <a ngbNavLink class="fs-11">Claimed </a>
                                <ng-template ngbNavContent>
                                    <div class="mt-20">
                                        <div class="card-body">
                                            <section class="">
                                                <div class="row">
                                                    <div class="col-md-3" *ngFor="let singleClaimedItem of claimDetailsList">
                                                        <div class="card">
                                                            <img class="card-img-top" src="{{ singleClaimedItem.imageUrl }}" alt="Card image cap">
                                                            <div class="card-body text-center">
                                                                <div class="fs-14 fw-600 blue-title">{{ singleClaimedItem.title }}</div>
                                                                <p class="card-text mt-10 fs-10">Claimed Date : {{ singleClaimedItem.createdAt | date: "dd-MM-yyyy h:mm a" }}
                                                                </p>
                                                                <a class="btn btn-primary rounded-btn-blue">Claimed</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="navFilled" class="col-md-12 fs-12 text-black20">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>