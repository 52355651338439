

import { Injectable } from "@angular/core";

@Injectable()
export class EsignMode {
    public static AADHAR_ESIGN = "AADHAR_ESIGN";
    public static OTP = "OTP";
    public static OTP_SIGNATURE = "OTP_SIGNATURE";
    
    public static activeEsignMode = EsignMode.AADHAR_ESIGN;
}