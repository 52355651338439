import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { RatesService } from 'app/modules/rates/services/rates.service';
import { Constants } from 'app/shared/constant/constants';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { OfferRewardsService } from '../../services/offer-rewards.service';

@Component({
  selector: 'rewards-credit-points',
  templateUrl: './rewards.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
    './rewards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RewardsComponent implements OnInit {
  public showLoader: boolean = false;
  public consumerCreditData: any;
  public consumerDetailsList: any;
  public consumerCreditHistory: any;
  public claimedRewardResponseForAdmin: any;
  public noclaimedRewardFound: any;
  public displayEarn: boolean = true;
  public unlock: boolean = true;
  public unlockedDetailsList: any;
  public rewardId: any;
  public availableDetailsList: any;

  constructor(
    public offerRewardsService: OfferRewardsService,
    public router: Router) { }

  ngOnInit() {
    this.getConsumerCredit();
    this.getConsumerCreditHistory();
    this.unlockedList();
  }

  getConsumerCredit() {
    this.showLoader = true;
    this.offerRewardsService.getConsumerCredits().subscribe(res => {
      this.showLoader = false;
      this.consumerCreditData = res.body;
    }, error => {
      this.showLoader = false;
    })
  }

  getConsumerCreditHistory() {
    this.showLoader = true;
    this.offerRewardsService.getconsumerCreditClaimHistory().subscribe(res => {
      this.showLoader = false;
      this.consumerDetailsList = res.body;
      this.consumerCreditHistory = this.consumerDetailsList?.consumerCredits;
      this.claimedRewardResponseForAdmin = this.consumerDetailsList?.claimedRewardResponseForAdmin;
      console.log(this.claimedRewardResponseForAdmin?.length);

      if (this.claimedRewardResponseForAdmin?.length == 0) {
        this.noclaimedRewardFound = true;
      }
    }, error => {
      this.showLoader = false;
    })
  }

  displaySpent() {
    this.displayEarn = false;
  }

  displayEarned() {
    this.displayEarn = true;
  }

  unlockedList() {
    this.unlock = false;
    this.offerRewardsService.getConsumerRewards(this.unlock)
      .subscribe(
        (result) => {
          this.unlockedDetailsList = result.body;
        },
        (error) => { }
      );
  }

  unlockReward(singleUnlockItem) {
    this.rewardId = singleUnlockItem.id;
    this.offerRewardsService.unlockReward(this.rewardId).subscribe(
      (result) => {
        Swal.fire("Success", "Reward Unlock successfully", "success");
        this.unlockedList();
      },
      (error) => { }
    );
  }

  availableList() {
    this.unlock = true;
    this.offerRewardsService
      .getConsumerRewards(this.unlock)
      .subscribe(
        (result) => {
          this.availableDetailsList = result.body;
        },
        (error) => { }
      );
  }

  goToReferAndEarnPage() {
    this.router.navigate(["/refer-and-earn"]);
  }

  goToRedeemPage(){
    this.router.navigate(["/redeem-voucher"]);
  }
}
