import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiaryService } from 'app/modules/beneficiary/services/beneficiary.service';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';
import { PagesList } from 'app/shared/constant/page-list';
import { Step01Data, StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';

@Component({
  selector: 'list-beneficiary-stepper-email',
  templateUrl: './list-beneficiary-stepper-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ListBeneficiaryStepperEmailComponent implements OnInit {

  public buttonText: string = 'View More';
  public showDetailsBeneficiary: boolean = false;
  public beneficiaryListItems = [];
  public selectedPurpose: SinglePurposeItem;
  public listTitle: any;
  public noDataFound: boolean = false;

  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public quotationResponse: any;

  public selectedBeneficiaryRadio: any;

  public selectedUniversityy: any;
  public singleBeneficiary: any;
  public singleBeneficiaryData: any;
  public isCollapsed5 = true;

  @Output() childCallbackSender = new EventEmitter<string>();

  constructor(
    public router: Router,
    public beneficiaryService: BeneficiaryService,
    public toast: ToastrService
  ) {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
      if (this.selectedPurpose.purposeCode === 'UNIVERSITY_FEES' || this.selectedPurpose.purposeCode == 'UNIVERSITY_FEES_OWN_ACCOUNT') {
        this.listTitle = 'Universities';
      } else {
        this.listTitle = 'Beneficiaries';
      }
    } else {
      this.router.navigate(['/home'])
      .then(() => {
      window.location.reload();
       });
    }
  }

  ngOnInit(): void {
    this.getBeneficiaryList(this.selectedPurpose.remittanceType);
  }

  goToAddBeneficiary() {
    var sharedQueryParams = this.selectedPurpose;
    sharedQueryParams['sourcePage'] = PagesList.REMITTANCE_STEPPER;
    this.router.navigate(['/add-beneficiary'], { queryParams: sharedQueryParams });
  }

  getBeneficiaryList(remittanceType) {
    let localBeneficiaryList;
    this.beneficiaryService.getBeneficiaryListAll(remittanceType).subscribe(resp => {
      localBeneficiaryList = resp.body;
      localBeneficiaryList.forEach(singleBeneficiary => {
        if (singleBeneficiary.purpose === this.selectedPurpose.purposeCode) {
          this.beneficiaryListItems.push(singleBeneficiary);
        }
      });

      if (this.beneficiaryListItems.length == 0) {
        this.noDataFound = true;
      }
    });
  }

  updateSelectedBeneficiaryToSession(singleBeneficiary) {
    // if (singleBeneficiary.beneficiaryStatusType == 'AUTO_APPROVED' || singleBeneficiary.beneficiaryStatusType == 'APPROVED') {
    let step01Data = new Step01Data();
    step01Data.beneficiary = singleBeneficiary;

    this.stepperDataModel.step01Data = step01Data;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));

    this.childCallbackSender.emit('REFRESH_DATA');
    // } else {
    //   this.toast.error("Please select approved beneficiary");
    // }
  }

  showBeneficiaryDetails(beneficiaryDetails) {
    this.singleBeneficiaryData = beneficiaryDetails;
    this.buttonText = 'View Less';
    this.showDetailsBeneficiary = true;
  }

  hideDetailsBeneficiary() {
    this.showDetailsBeneficiary = false;
    this.buttonText = 'View More';
  }
}
