import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class BankAccountService extends BaseProvider {

    getMyBankAccountist(){
        var resourceUrl = "bankAccount";
        return this.makeGetCall(resourceUrl);
    }

    getPaymentModes(){
        var resourceUrl = 'collectionMethods';
        return this.makeGetCall(resourceUrl);
    }

    addMyBankAccount(request) {
        var resourceUrl = "bankAccount";
        return this.makePostCall(resourceUrl, request);
    }

    verifyBankAccount(request) {
        var resourceUrl = "accountTransferValidation";
        return this.makePostCall(resourceUrl,request);
    }
}