import { Component, OnInit, ViewEncapsulation, VERSION } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PagesList } from 'app/shared/constant/page-list';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { BeneficiaryCreateRequestModel } from '../../models/beneficiary.model';
import { BeneficiaryService } from '../../services/beneficiary.service';
import { Location } from '@angular/common';
import { Utils } from 'app/shared/utility/utils';
import { ViewLocalFileUtils } from 'app/shared/utility/view-local-file-utils';
@Component({
  selector: 'add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['../../../remittance/stepper.scss',
  // '../../../../../assets/scss/select.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddBeneficiaryComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  public beneficiaryCreateRequestModel = new BeneficiaryCreateRequestModel();
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public relationshipWithBeneficiary = Constants.relationshipWithBeneficiary;
  public contentHeader: object;
  public currencyItems: any;
  public selectedRemittanceType: any;
  public countryListItems: any;
  public universityListItems: any;
  public consumerId: any;
  public purposeCode: any;
  public queryParams: any;
  public isReadOnly: boolean = false;
  public showLoader: boolean = false;
  public showForm: boolean = false;
  public showCheckedMark: boolean = false;
  public currencySelected: boolean = false;

  public additionalDocument: FileList;
  public additionalDocumentFile: File;
 
  public proofDocument: FileList;
  public proofDocumentFile: File;

  public idProofSelected: boolean = false;
  public additionalDOcSelected: boolean = false;
  public viewLocalFileUtils: ViewLocalFileUtils = new ViewLocalFileUtils();
  public freezeCountryField  : boolean = false;
  
  constructor(
    private activateRoute: ActivatedRoute,
    private masterDataService: MasterDataService,
    public route: ActivatedRoute,
    public beneficiaryService: BeneficiaryService,
    public toast: ToastrService,
    public router: Router,
    private location: Location
  ) {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.getCountryList();
    this.getCurrencies();
  }

  showFormFields() {
    this.showForm = true;
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      this.selectedRemittanceType = params.remittanceType;
      this.purposeCode = params.purposeCode; 
      if (params.sourcePage == PagesList.REMITTANCE_STEPPER) {
        this.beneficiaryCreateRequestModel.viaAgentName = params['beneficiaryName'];
        this.beneficiaryCreateRequestModel.countryCode = params['countryCode'];
        this.beneficiaryCreateRequestModel.currencyCode = params['currencyCode'];
        console.log(this.beneficiaryCreateRequestModel);
        this.currencySelected = true;
        if (this.beneficiaryCreateRequestModel.viaAgentName != null && this.beneficiaryCreateRequestModel.countryCode != null) {
          this.isReadOnly = true;
        }
      }
    });

    if(this.queryParams.key == 'BlockAccount'){
      this.beneficiaryCreateRequestModel.countryCode = 'DE';
      this.freezeCountryField = true;
    }

    if(this.queryParams.key == 'GIC'){
      this.beneficiaryCreateRequestModel.countryCode = 'CA';
      this.beneficiaryCreateRequestModel.currencyCode = 'CAD';
      this.freezeCountryField = true;
    }

    console.log(this.beneficiaryCreateRequestModel.currencyCode);
    
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
      console.log(JSON.stringify(this.countryListItems));
      
    }, error => {
    });
  }


  getUniversityList(selectedCountry) {
    this.masterDataService.getUniversityList(this.selectedRemittanceType, selectedCountry).subscribe(resp => {
      this.universityListItems = resp.body;
    }, error => {
    });
  }

  getCurrencies() {
    this.reqGetCurrencyMaster.cartType = ""
    this.reqGetCurrencyMaster.cartType = PurposeList.purpose[0].remittanceType;
    this.reqGetCurrencyMaster.countryCode = "US";
    this.reqGetCurrencyMaster.issuer = "";
    this.reqGetCurrencyMaster.partnerId = Constants.partnerId;
    this.reqGetCurrencyMaster.product = Constants.productsEnum[2];

    this.masterDataService.getCurrenciesList(this.reqGetCurrencyMaster).subscribe(result => {
      this.currencyItems = result.body;
    });
  }

  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  createBeneficiary(validData) {
    if (validData == true) {
      if(this.beneficiaryCreateRequestModel.bankAccountNumber == this.beneficiaryCreateRequestModel.confirmBankAccountNumber) {
      this.showLoader = true;
      var countryObject = Utils.getSingleCountryByCountryCode(this.beneficiaryCreateRequestModel.countryCode, this.countryListItems);

      this.beneficiaryCreateRequestModel.remittanceType = this.selectedRemittanceType;
      this.beneficiaryCreateRequestModel.consumerId = this.consumerId;
      this.beneficiaryCreateRequestModel.purpose = this.purposeCode;
      this.beneficiaryCreateRequestModel.country = countryObject?.countryName;

      let localBeneficiaryCreateRequest: Array<BeneficiaryCreateRequestModel> = [];
      localBeneficiaryCreateRequest.push(this.beneficiaryCreateRequestModel);
      let beneficiaryCreateRequest = { "beneficiaryCreateRequests": localBeneficiaryCreateRequest };

      this.beneficiaryService.addBeneficiary(beneficiaryCreateRequest).subscribe(resp => {
        this.showLoader = false;

        if (this.queryParams.sourcePage == PagesList.LIST_BENEFICIARY) {
          this.router.navigate(['/list-beneficiary']);
          sessionStorage.setItem(ConstantsKey.BENEFICIARY_PURPOSE_CODE, this.purposeCode);
          this.toast.success("Beneficiary added successfully", "Success !!");
        }

        if (this.queryParams.sourcePage == PagesList.REMITTANCE_STEPPER) {
          this.location.back();
          this.toast.success("Beneficiary added successfully", "Success !!");
        }

      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    } else {
      this.showLoader = false;
      this.toast.error("Please enter correct bank account number!!");
    }
    } else {
      this.showLoader = false;
      this.toast.error("Please fill all the mandatory fields!!");
    }
  }

  backClicked() {
    this.location.back();
  }

  fileUploadProofDocument(event) {
    this.proofDocument = event.target.files;
    if (this.proofDocument.length > 0) {
      this.proofDocumentFile = this.proofDocument[0];
      if (this.proofDocumentFile.type.indexOf("pdf") >= 0 || this.proofDocumentFile.type.indexOf("image") >= 0) {
          this.idProofSelected = true;
      }
    }
  }

  fileUploadAdditionalDocument(event) {
    this.additionalDocument = event.target.files;
    if (this.additionalDocument.length > 0) {
      this.additionalDocumentFile = this.additionalDocument[0];
      if (this.additionalDocumentFile.type.indexOf("pdf") >= 0 || this.additionalDocumentFile.type.indexOf("image") >= 0) {
          this.additionalDOcSelected = true;
      }
    }
  }
  
  downloadUploadedDoc(fileDetails){
    this.viewLocalFileUtils.viewLocalDocument(fileDetails);
  }

}
