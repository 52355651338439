import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModalModule, NgbNav, NgbNavItem, } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsComponent } from './view/order-details/order-details.component';
import { OrderListComponent } from './view/order-list/order-list.component';
import { OrderService } from './services/order.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { PaymentSuccessComponent } from './view/payment-success/payment-success.component';
import { RatingsComponent } from './view/ratings/ratings.component';
import { SitBackAndRelaxComponent } from './view/sit-back-and-relax/sit-back-and-relax.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { RetryPaymentComponent } from './view/retry-payment/retry-payment.component';
import { PendingOrderListComponent } from './view/pending-orders/pending-order-list.component';
import { PreparedProceedOrderComponent } from './view/prepared-and-proceed/prepared-and-proceed.component';
import { QuotesOrderDetailsComponent } from './view/quotes-order-details/quote-order-details.component';
import { PaymentFailedComponent } from './view/payment-failed/payment-failed.component';
import { OrderAmountSummaryComponent } from './view/order-amount-summary/order-amount-summary.component';

const routes = [
  {
    path: 'payment-success/:orderId',
    component: PaymentSuccessComponent,
    data: { animation: 'PaymentSuccessComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-failed/:orderId',
    component: PaymentFailedComponent,
    data: { animation: 'PaymentFailedComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'pending-order',
    component: PendingOrderListComponent,
    data: { animation: 'PendingOrderListComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'proceed-and-pending-order/:orderId/:source',
    component: PreparedProceedOrderComponent,
    data: { animation: 'PreparedProceedOrderComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'ratings/:orderId',
    component: RatingsComponent,
    data: { animation: 'RatingsComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'sit-back-relax/:orderID',
    component: SitBackAndRelaxComponent,
    data: { animation: 'SitBackAndRelaxComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'quote-order-details/:orderID',
    component: QuotesOrderDetailsComponent,
    data: { animation: 'OrderListComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'order-list',
    component: OrderListComponent,
    data: { animation: 'OrderListComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'order-details/:orderID',
    component: OrderDetailsComponent,
    data: { animation: 'OrderDetailsComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'retry-payment/:orderID',
    component: RetryPaymentComponent,
    data: { animation: 'RetryPaymentComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'order-amount-summary',
    component: OrderAmountSummaryComponent,
    data: { animation: 'OrderAmountSummaryComponent' },
    canActivate: [AuthGuard]
  },
  
];

@NgModule({
  declarations: [
    PaymentSuccessComponent,
    RatingsComponent,
    SitBackAndRelaxComponent,
    OrderDetailsComponent,
    OrderListComponent,
    RetryPaymentComponent,
    PendingOrderListComponent,
    PreparedProceedOrderComponent,
    QuotesOrderDetailsComponent,
    PaymentFailedComponent,
    OrderAmountSummaryComponent
  ],
  imports: [RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CardSnippetModule,
    FormsModule,
    CoreDirectivesModule,
    NgbRatingModule,
    NgbModule,
    NgbModalModule,
    NgSelectModule,
    LoaderRoutingModule, 
  ],
  exports: [
    PaymentSuccessComponent,
    RatingsComponent,
    SitBackAndRelaxComponent,
    OrderDetailsComponent,
    OrderListComponent,
    RetryPaymentComponent,
    PendingOrderListComponent,
    PreparedProceedOrderComponent,
    QuotesOrderDetailsComponent,
    PaymentFailedComponent, 
    
  ],
  providers: [OrderService]
})
export class OrdersRoutingModule { }
