import { Injectable } from "@angular/core";

@Injectable()
export class Constants {
  public static isStepperThreeStep = false;
  public static ALLOW_NEFT_PAYMENT = true;

  public static CONFIG_s3_BASE_URL = "https://s3.ap-south-1.amazonaws.com/smartfx.config";

  public static timerValue = 300;
  public static wsflReferenceBranchId = "b-wsfl15"; // default branch passing Mumbai
  public static userId = 'u-wsfxConsumerSuperAdmin';
  public static partnerId = "p_wsfxConsumer";
  public static emptyCartMsg="Your cart is empty! Please Add currency to proceed"
  public static productsEnum = ['FOREXCARD', 'CASH', 'REMITTANCE'];
  public static sellTypes = ['SELL', 'BUY', 'REMITTANCE'];
  public static vkycTimingText = "You can complete your video KYC from Monday to Saturday between 09:30 am IST till 07:00 pm IST";
  public static sourceOfFundsItemList = ['Loan', 'Own'];
  public static productItemList = ["FOREX CARD", "CURRENCY NOTES"];
  public static productItemListCashFlow = ["CURRENCY NOTES","FOREX CARD"];

  public static currenciesListGlobal:any ;
  public static paymentItemList = ['NEFT', 'RTGS', 'IMPS', 'UPI'];

  public static payerList = [
    'Self',
    'Father',
    'Mother',
    'Spouse',
    'Son',
    "Son's Wife",
    'Daughter',
    "Daughter's Husband",
    'Brother',
    'Sister'
  ];
  public static relationshipWithBeneficiary = [
    'Self',
    'Father',
    'Mother',
    'Spouse',
    'Son',
    "Son's Wife",
    'Daughter',
    "Daughter's Husband",
    'Brother',
    'Sister',
    'Lessor'
  ];
  public static addressProofItems = [
    'Electricity Bill',
    'Passport',
    'Utility',
    'Mobile Bill'
  ];

  public static occupationListItems = [
    "Homemaker",
    "Service",
    "Student",
    "Business",
    "Retired",
    "Self Employed",
    "Others"
  ];
  public static occupationMap = new Map<string, string>([
  
     ["SALARIED", "SALARIED"],
    [ "SELF_EMPLOYED", "SELF EMPLOYED"],
    [ "RETIRED", "RETIRED"],
    [ "HOME_MAKER", "HOME MAKER"],
    ["STUDENT", "STUDENT"],
    ["NOT_WORKING", "NOT WORKING"],
    ["AGRICULTURE", "AGRICULTURE"],
    [ "BUSINESS", "BUSINESS"]
  ]);
  public static selfEmployedMap = new Map<string, string>([

          ["ARCHITECT", "ARCHITECT"],
          [  "COMPANY_SECRETARY", "COMPANY SECRETARY"],
          [ "DOCTOR", "DOCTOR"],
          [ "CHARTERED_ACCOUNTANT", "CHARTERED ACCOUNTANT"],
          ["COST_ACCOUNTANT", "COST ACCOUNTANT"],
          [ "LAWYER", "LAWYER"],
  ]);
  public static natureOfBusinessMap = new Map<string, string>([    

          ["MANUFACTURING","MANUFACTURING"],
          [ "AGRICULTURE", "AGRICULTURE"],
          [ "SERVICE_PROVIDER", "SERVICE PROVIDER"],
          [ "TRADING", "TRADING"],
          ["STUDENT", "STUDENT"],
          [ "REAL_ESTATE", "REAL ESTATE"],
    ]);

  public static educationListItems = [
    '10th',
    '12th',
    'Graduate',
    'Post Graduate',
    'Other'
  ];
   public static educationMap = new Map<string, string>([
     ["UPTO_10", "UPTO 10"],
    [ "UNDER_GRADUATE", "UNDER GRADUATE"],
    [ "GRADUATE", "GRADUATE"],
    ["POST_GRADUATE", "POST GRADUATE"],
    ["PROFESSIONAL", "PROFESSIONAL"],
    ["NOT_APPLICABLE", "NOT APPLICABLE"]
   ]);
  public static sourceOfFundsMap = new Map<string, string>(
    [
     ["SALARY", "SALARY"],
    [ "WAGES", "WAGES"],
    [ "PENSION", "PENSION"],
    [ "INCOME_FROM_RENT", "INCOME FROM RENT"],
    ["INCOME_FROM_AGRICULTURE", "INCOME FROM AGRICULTURE"],
      ["ROYALTY", "ROYALTY"],
      ["INCOME_FROM_BUSINESS", "INCOME FROM BUSINESS"],
      ["INCOME_FROM_INVESTMENT", "INCOME FROM INVESTMENT"],
      [ "FAMILY_INCOME","FAMILY INCOME"],
    ["FEES", "FEES"],
    ["COMMISSION", "COMMISSION"],
    ["GIFT", "GIFT"],
    ["PRIZE_MONEY", "PRIZE MONEY"]
    ]);


  public static incomeListItems = [
    '0-3 lakh',
    '3-6 lakh',
    '6-9 lakh',
    '10 lakh & Above'
  ]
  public static incomeMap = new Map<string, string>([
    ["SLAB1", "0-1Lakh"],
    [ "SLAB2", "1Lakhs to 5Lakhs"],
    [ "SLAB3", "5Lakhs to 10Lakhs"],
    ["SLAB4", "10Lakhs to 25Lakhs"],
    ["SLAB5","25Lakhs to 50Lakhs"],
    ["SLAB6", "50Lakhs to 1Crore"]
    ]);

  public static locationTypeList = [
    'HOME',
    'OFFICE',
    'OTHER'
  ];

  public static addressCityList = [
    'Mumbai',
    'Ahmedabad',
    'Baroda',
    'Margao',
    'Pune',
    'Kochi',
    'Thiruvanantharpuram',
    'Amritsar',
    'Coimbatore',
    'Jalandhar',
    'Ludhiana',
    'Bangalore',
    'Hoshiarpur',
    'Patiala',
    'Chandigarh',
    'Delhi',
    'Hyderabad',
    'Chennai',
    'Kolkata AJC Bose'
  ];

  public static genderListItems = [
    'MALE',
    'FEMALE',
    'OTHER'
  ];

  public static serviceRequestSubjectListItems = [
    'Order Related Issue',
    'Payment Failure / Transaction Declined',
    'OTP Issue',
    'V-KYC Issue',
    'Card Replacement Request',
    'Card Block Request',
    'Others'
  ];

  public static raiseTicketItems = [
    'Pre-Authorization not released',
    'Transaction Declined',
    'Card not used but amount debited',
    'Transaction showing negative balance',
    'Other'
  ];



  public static cityItemsForRateALert = [
    'Adilabad',
    'Agar Malwa',
    'Agatti',
    'Agra',
    'Ahmedabad',
    'Ahmednagar',
    'Aizawl',
    'Ajmer',
    'Akola',
    'Alappuzha',
    'Aligarh',
    'Alipurduar',
    'Alirajpur',
    'Allahabad',
    'Almora',
    'Alwar',
    'Ambala',
    'Ambarnath',
    'Ambedkar Nagar',
    ' Amethi (Chatrapati Sahuji Mahraj Nagar)',
    'Amini',
    'Amravati',
    'Amreli',
    'Amritsar',
    'Amroha (J.P.Nagar)',
    'Anand',
    'Anantapur',
    'Anantnag',
    'Andheri',
    'Androth',
    'Angul',
    'Anjaw',
    'Anuppur',
    'Araria',
    'Aravalli',
    'Ariyalur',
    'Arwal',
    'Ashoknagar',
    'Auraiya',
    'Aurangabad',
    'Aurangabad',
    'Azamgarh',
    'Bagalkot',
    'Bageshwar',
    'Baghpat',
    'Bahraich',
    'Baksa',
    'Balaghat',
    'Balangir',
    'Balasore',
    'Ballari (Bellary)',
    ' Ballia',
    'Balod',
    'Baloda Bazar',
    'Balrampur',
    'Balrampur',
    'Banaskantha  (Palanpur)',
    'Banda',
    'Bandipore',
    'Bandra',
    'Bangalore',
    'Banka',
    'Bankura',
    'Banswara',
    'Barabanki',
    'Baramulla',
    'Baran',
    'Bareilly',
    'Bargarh',
    'Barmer',
    'Barnala',
    'Baroda',
    'Barpeta',
    'Barwani',
    'Bastar',
    'Basti',
    'Bathinda',
    'Beed',
    'Begusarai',
    'Belagavi (Belgaum)',
    'Bemetara',
    ' Bengaluru (Bangalore) Rural',
    'Betul',
    'Bhadohi',
    'Bhadradri',
    'Kothagudem',
    'Bhadrak',
    'Bhagalpur',
    'Bhandara',
    'Bharatpur',
    'Bharuch',
    'Bhavnagar',
    'Bhayandar',
    'Bhilwara',
    'Bhind',
    'Bhiwani',
    'Bhojpur',
    'Bhopal',
    'Bhubaneswar',
    'Bidar',
    'Bijapur',
    'Bijnor',
    'Bikaner',
    'Bilaspur',
    'Bilaspur',
    'Birbhum',
    'Bishnupur',
    'Biswanath',
    'Bithra',
    'Bokaro',
    'Bongaigaon',
    'Botad',
    'Boudh',
    'Budaun',
    'Budgam',
    'Bulandshahr',
    'Buldhana',
    'Bundi',
    'Burdwan  (Bardhaman)',
    'Burhanpur',
    ' Buxar',
    ' Byculla',
    ' Cachar',
    ' Chamarajanagar',
    ' Chamba',
    ' Chamoli',
    ' Champawat',
    ' Champhai',
    ' Chandauli',
    ' Chandel',
    ' Chandigarh',
    ' Chandrapur',
    ' Changlang',
    ' Charaideo',
    'CharkhiDadri',
    ' Chatra',
    ' Chennai',
    ' Chethlath',
    ' Chhatarpur',
    ' Chhindwara',
    'Chhota Udepur',
    ' Chikballapur',
    ' Chikkamagaluru  (Chikmagalur)',
    ' Chirang',
    ' Chitradurga',
    ' Chitrakoot',
    ' Chittoor',
    ' Chittorgarh',
    ' Churachandpur',
    ' Churchgate',
    ' Churu',
    ' Cochin',
    ' Coimbatore',
    'Cooch Behar',
    'Cuddalore',
    'Cuttack',
    'Dadra & Nagar Haveli',
    'Dahod',
    'Dakshin Dinajpur (South Dinajpur)',
    'Dakshina Kannada',
    'Daman',
    'Damoh',
    'Dangs  (Ahwa)',
    'Dantewada (South Bastar)',
    'Darbhanga',
    'Darjeeling',
    'Darrang',
    'Datia',
    'Dausa',
    'Davangere',
    'Dehradun',
    'Delhi',
    'Deogarh',
    'Deoghar',
    'Deoria',
    'Devbhoomi Dwarka',
    'Dewas',
    'Dhalai',
    'Dhamtari',
    'Dhanbad',
    'Dhar',
    'Dharmapuri',
    'Dharwad',
    'Dhemaji',
    'Dhenkanal',
    'Dholpur',
    'Dhubri',
    'Dhule',
    'Dibang Valley',
    'Dibrugarh',
    'Dima Hasao',
    'Dimapur',
    'Dindigul',
    'Dindori',
    'Diu',
    'Doda',
    'Dumka',
    'Dungarpur',
    'Durg',
    'East Champaran (Motihari)',
    'East Garo Hills',
    'East Jaintia',
    'Hills',
    'East Khasi Hills',
    'East Sikkim',
    'East Singhbhum',
    'Ernakulam',
    'Erode',
    'Etah',
    'Etawah',
    'Faizabad',
    'Faridabad',
    'Faridkot',
    'Farrukhabad',
    'Fatehabad',
    'Fatehgarh Sahib',
    'Fatehpur',
    'Fazilka',
    'Ferozepur',
    'Firozabad',
    'Gadag',
    'Gadchiroli',
    'Gajapati',
    'Ganderbal',
    'Gandhinagar',
    'Ganjam',
    'Garhwa',
    'Gariyaband',
    'Gautam Buddha Nagar',
    'Gaya',
    'Ghaziabad',
    'Ghazipur',
    'Gir Somnath',
    'Giridih',
    'Goa',
    'Goalpara',
    'Godavari',
    'Godda',
    'Golaghat',
    'Gomati',
    'Gonda',
    'Gondia',
    'Gopalganj',
    'Gorakhpur',
    'Goregaon',
    'Gumla',
    'Guna',
    'Guntur',
    'Gurdaspur',
    'Gurgaon',
    'Gwalior',
    'Hailakandi',
    'Hamirpur',
    'Hamirpur',
    'Hanumangarh',
    'Hapur(Panchsheel Nagar)',
    'Harda',
    'Hardoi',
    'Haridwar',
    'Hassan',
    'Hathras',
    'Haveri',
    'Hazaribag',
    'Hingoli',
    'Hisar',
    'Hojai',
    'Hooghly',
    'Hoshangabad',
    'Hoshiarpur',
    'Howrah',
    'Hyderabad',
    'Idukki',
    'Imphal  East',
    'Imphal  West',
    'Indore',
    'Jabalpur',
    'Jagatsinghapur',
    'Jagtial',
    'Jaipur',
    'Jaisalmer',
    'Jajpur',
    'Jalandhar',
    'Jalaun',
    'Jalgaon',
    'Jallandhar',
    'Jalna',
    'Jalore',
    'Jalpaiguri',
    'Jammu',
    'Jamnagar',
    'Jamtara',
    'Jamui',
    'Jangaon',
    'Janjgir-Champa',
    'Jashpur',
    'Jaunpur',
    'Jayashankar  Bhoopalpally',
    'Jehanabad',
    'Jhabua',
    'Jhajjar',
    'Jhalawar',
    'Jhansi',
    'Jharsuguda',
    'Jhunjhunu',
    'Jind',
    'Jiribam',
    'Jodhpur',
    'Jogulamba Gadwal',
    'Jorhat',
    'Junagadh',
    'Kabirdham (Kawardha)',
    'Kachchh',
    'Kadmath',
    'Kaimur (Bhabua)',
    'Kaithal',
    'Kakching',
    'Kalaburagi (Gulbarga)',
    'Kalahandi',
    'Kalimpong',
    'Kalpeni',
    'Kamareddy',
    'Kameng',
    'Kamjong',
    'Kamrup',
    'Kamrup Metropolitan',
    'Kanchipuram',
    'Kandhamal',
    'Kangpokpi',
    'Kangra',
    'Kanjurmarg',
    'Kanker (North Bastar)',
    'Kannauj',
    'Kannur',
    'Kanpur Dehat',
    'Kanpur Nagar',
    'Kanshiram Nagar (Kasganj)',
    'Kanyakumari',
    'Kapurthala',
    'Karaikal',
    'Karauli',
    'Karbi Anglong',
    'Kargil',
    'Karimganj',
    'Karimnagar',
    'Karnal',
    'Karur',
    'Kasaragod',
    'Kathua',
    'Katihar',
    'Katni',
    'Kaushambi',
    'Kavaratti',
    'Kendrapara',
    'Kendujhar (Keonjhar)',
    'Khagaria',
    'Khammam',
    'Khandwa',
    'Khargone',
    'Kheda (Nadiad)',
    'Khordha',
    'Khowai',
    'Khunti',
    'Kilthan',
    'Kinnaur',
    'Kiphire',
    'Kishanganj',
    'Kishtwar',
    'Kodagu',
    'Koderma',
    'Kohima',
    'Kokrajhar',
    'Kolar',
    'Kolasib',
    'Kolhapur',
    'Kolkata',
    'Kollam',
    'Komaram Bheem Asifabad',
    'Kondagaon',
    'Koppal',
    'Koraput',
    'Korba',
    'Korea (Koriya)',
    'Kota',
    'Kottayam',
    'Kozhikode',
    'Kra Daadi',
    'Krishna',
    'Krishnagiri',
    'Kulgam',
    'Kullu',
    'Kupwara',
    'Kurnool',
    'Kurukshetra',
    'Kurung Kumey',
    'Kushinagar (Padrauna)',
    'Lahaul  & Spiti',
    'Lakhimpur',
    'Lakhimpur - Kheri',

    'Lakhisarai',
    'Lalitpur',
    'Latehar',
    'Latur',
    'Lawngtlai',
    'Leh',
    'Lohardaga',
    'Lohit',
    'Longding',
    'Longleng',
    'Lower Dibang Valley',
    'Lower Siang',
    'Lower Subansiri',
    'Lucknow',
    'Ludhiana',
    'Lunglei',
    'Madhepura',
    'Madhubani',
    'Madurai',
    'Mahabubabad',
    'Mahabubnagar',
    'Maharajganj',
    'Mahasamund',
    'Mahe',
    'Mahendragarh',
    'Mahisagar',
    'Mahoba',
    'Mainpuri',
    'Majuli',
    'Malappuram',
    'Malda',
    'Malkangiri',
    'Mamit',
    'Mancherial',
    'Mandi',
    'Mandla',
    'Mandsaur',
    'Mandya',
    'Mansa',
    'Margao',
    'Mathura',
    'Mau',
    'Mayurbhanj',
    'Medak',
    'Medchal',
    'Medinipur',
    'Meerut',
    'Mehsana',
    'Mewat',
    'Minicoy',
    'Mira Road',
    'Mirzapur',
    'Moga',
    'Mokokchung',
    'Mon',
    'Moradabad',
    'Morbi',
    'Morena',
    'Morigaon',
    'Muktsar',
    'Mumbai',
    'Mumbra',
    'Mungeli',
    'Munger (Monghyr)',
    'Murshidabad',
    'Muzaffarnagar',
    'Muzaffarpur',
    'Mysuru (Mysore)',
    'Nabarangpur',
    'Nadia',
    'Nagaon',
    'Nagapattinam',
    'Nagarkurnool',
    'Nagaur',
    'Nagpur',
    'Nahur',
    'Nainital',
    'Nalanda',
    'Nalbari',
    'Nalgonda',
    'Namakkal',
    'Namsai',
    'Nanded',
    'Nandurbar',
    'Narayanpur',
    'Narmada (Rajpipla)',
    'Narsinghpur',
    'Nashik',
    'Navsari',
    'Nawada',
    'Nawanshahr',
    'Nawanshahr (Shahid Bhagat Singh Nagar)',
    'Nayagarh',
    'Neemuch',
    'Nellore',
    'Nicobar',
    'Nilgiris',
    'Nirmal',
    'Nizamabad',
    'Noida',
    'Noney',
    'North 24 Parganas ',
    'North And Middle Andaman',
    'North Garo Hills',
    'North Sikkim',
    'North Tripura',
    'Nuapada',
    'Osmanabad',
    'Pakur',
    'Palakkad',
    'Palamu',
    'Palghar',
    'Pali',
    'Palwal',
    'Panchkula',
    'Panchmahal (Godhra)',
    'Panipat',
    'Panjim',
    'Panna',
    'Papum Pare',
    'Parbhani',
    'Patan',
    'Pathanamthitta',
    'Pathankot',
    'Patiala',
    'Patna',
    'Pauri Garhwal',
    'Peddapalli',
    'Perambalur',
    'Peren',
    'Phek',
    'Pherzawl',
    'Pilibhit',
    'Pithoragarh',
    'Pondicherry',
    'Poonch',
    'Porbandar',
    'Prakasam',
    'Pratapgarh',
    'Pratapgarh',
    'Pudukkottai',
    'Pulwama',
    'Pune',
    'Punjab',
    'Puri',
    'Purnia (Purnea)',
    'Purulia',
    'RaeBareli',
    'Raichur',
    'Raigad',
    'Raigarh',
    'Raipur',
    'Raisen',
    'Rajanna Sircilla',
    'Rajgarh',
    'Rajkot',
    'Rajnandgaon',
    'Rajouri',
    'Rajsamand',
    'Ramanagara',
    'Ramanathapuram',
    'Ramban',
    'Ramgarh',
    'Rampur',
    'Ranchi',
    'Rangareddy',
    'Ratlam',
    'Ratnagiri',
    'Rayagada',
    'Reasi',
    'Rewa',
    'Rewari',
    'Ri Bhoi',
    'Rohtak',
    'Rohtas',
    'Rudraprayag',
    'Rupnagar',
    'Sabarkantha (Himmatnagar)',
    'Sagar',
    'Saharanpur',
    'Saharsa',
    'Sahibganj',
    'Sahibzada ',
    'Sahibzada Ajit Singh Nagar (Mohali)',
    'Saiha',
    'Salem',
    'Samastipur',
    'Samba',
    'Sambalpur',
    'Sambhal (Bhim Nagar)',
    'Sangareddy',
    'Sangli',
    'Sangrur',
    ' Sant Kabir Nagar',
    'Santacruz',
    'Saran',
    'Satara',
    'Satna',
    'Sawai Madhopur',
    'Sehore',
    'Senapati',
    'Seoni',
    'Sepahijala',
    'Seraikela-Kharsawan',
    'Serchhip',
    'Shahdara',
    'Shahdol',
    'Shahjahanpur',
    'Shajapur',
    ' Shamali',
    '(Prabuddh Nagar)',
    'Sheikhpura',
    'Sheohar',
    'Sheopur',
    'Shimla',
    'Shivamogga (Shimoga)',
    'Shivpuri',
    'Shopian',
    'Shravasti',
    'Siang',
    'Siddharth Nagar',
    'Siddipet',
    'Sidhi',
    'Sikar',
    'Simdega',
    'Sindhudurg',
    'Singrauli',
    'Sirmaur (Sirmour)',
    'Sirohi',
    'Sirsa',
    'Sitamarhi',
    'Sitapur',
    'Sivaganga',
    'Sivasagar',
    'Siwan',
    'Solan',
    'Solapur',
    'Sonbhadra',
    'Sonepur',
    'Sonipat',
    'Sonitpur',
    'South 24 Parganas',
    'South Andaman',
    'South Garo Hills',
    'South  Salmara-Mankachar',
    'South Sikkim',
    'South Tripura',
    'South West  Garo Hills ',
    'South West Khasi Hills',
    'Sri Ganganagar',
    'Srikakulam',
    'Srinagar',
    'Sukma',
    'Sultanpur',
    'Sundargarh',
    'Supaul',
    'Surajpur ',
    'Surat',
    'Surendranagar',
    'Surguja',
    'Suryapet',
    'Tamenglong',
    'Tapi (Vyara)',
    'Tardeo',
    'Tarn Taran',
    'Tawang',
    'Tehri Garhwal',
    'Tengnoupal',
    'Thane',
    'Thanjavur',
    'Theni',
    'Thiruvanantharpuram',
    'Thoothukudi  (Tuticorin)',
    'Thoubal',
    'Thrissur',
    'Tikamgarh',
    'Tinsukia',
    'Tirap',
    'Tiruchirappalli',
    'Tirunelveli',
    'Tiruppur',
    'Tiruvallur',
    'Tiruvannamalai',
    'Tiruvarur',
    'Tonk',
    'Tuensang',
    'Tumakuru (Tumkur)',
    'Udaipur',
    'Udalguri',
    'Udham Singh Nagar',
    'Udhampur',
    'Udupi',
    'Ujjain',
    'Ukhrul',
    'Umaria',
    'Una',
    'Unakoti',
    'Unnao',
    'Upper Siang',
    'Upper Subansiri',
    'Uttar Dinajpur (North Dinajpur)',
    'Uttar Pradesh',
    'Uttara Kannada (Karwar)',
    'Uttarakhand',
    'Uttarkashi',
    'Vadodara',
    'Vaishali',
    'Valsad',
    'Varanasi',
    'Vasai',
    'Vellore',
    'Vidisha',
    'Vijayapura (Bijapur)',
    'Vikarabad',
    'Viluppuram',
    'Virudhunagar',
    'Visakhapatnam',
    'Vizianagaram',
    'Wanaparthy',
    'Warangal (Rural)',
    'Warangal (Urban)',
    'Wardha',
    'Washim',
    'Wayanad',
    'West Champaran',
    'West Garo Hills',
    'West Jaintia Hills',
    'West Karbi Anglong',
    'West Khasi Hills',
    'West Siang',
    'West Sikkim',
    'West Singhbhum',
    'West Tripura',
    'Wokha',
    'Yadadri Bhuvanagiri',
    'Yadgir',
    'Yamunanagar',
    'Yanam',
    'Yavatmal',
    'YSR Kadapa',
    'Zunheboto'
  ];

  public static leisureRelationship = [
    'Self',
    'Mother',
    'Father',
    'Spouse',
    'Son',
    "Son's Wife",
    'Sister',
    'Daughter',
    "Daughter's Husband",
    'Brother',
  ];

  public static leisureNomnieeRelationship = [
    'Mother',
    'Father',
    'Spouse',
    'Son',
    "Son's Wife",
    'Daughter',
    "Daughter's Husband",
    'Brother',
    'Sister'
  ];
}