import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InsuranceService } from 'app/modules/insurance/services/insurance.service';
import { OrderService } from 'app/modules/order/services/order.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: [
    './payment-failed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentFailedComponent implements OnInit {

  public orderId: string;
  public orderDetails: any;
  public showLoader: boolean = true;
  public paymentStatus: any;
  public paymentMode: any;
  public forexMarginString: String = '0.00';
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public insuranceDetails: any;
  public insuranceId: any;

  constructor(
    private route: ActivatedRoute,
    public orderService: OrderService,
    private router: Router,
    public insuranceService: InsuranceService,
    public toast: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.orderId = params['orderId'];
    });

    this.getSingleOrderDetails();
  }

  getSingleOrderDetails() {
    this.showLoader = true;
    this.orderService.getOrderDetailsSingle(this.orderId).subscribe(respOrderData => {
      if (respOrderData['status'] == 200) {
        this.showLoader = false;
        this.orderDetails = respOrderData.body;
        this.paymentStatus = this.orderDetails?.orderStatusGroup?.consumerPayment;
        this.paymentMode = this.orderDetails?.paymentMode;
        if (this.orderDetails?.invoice?.marginCharges!=null) {
         this.forexMarginString = this.orderDetails?.invoice?.marginCharges.toFixed(2) + "";
        }
      }
    }, error => {
      this.showLoader = false;
      this.insuranceService.getTravelInsuranceCartDetails(this.orderId).subscribe(respInsuranceData => {
        this.showLoader = false;
        this.insuranceDetails = respInsuranceData.body;
        this.insuranceId = this.insuranceDetails.cart[0].insuranceOrderId;
      }, error => {
        this.insuranceDetails = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
        this.insuranceId = this.insuranceDetails.cart[0].insuranceOrderId;
        console.log('INSURANCE DETAILS >>> ', JSON.stringify(this.insuranceDetails));
      });
    });
  }

  navigateToRating() {
    this.router.navigate(['/ratings', this.orderId]);
  }

  retryPayment() {
    this.router.navigate(['/retry-payment', this.orderId]);
  }

  goToOrderDetails() {
    this.router.navigate(['/order-details', this.orderId]);
  }

  goToInsuranceDetails() {
    // this.router.navigate(['/insurance-list']);
    this.router.navigate(['/insurance-details', this.insuranceId]);
  }
}