<add-bank-account-for-remittance *ngIf="showAddBankForm"
    (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
</add-bank-account-for-remittance>
<section *ngIf="!showAddBankForm">
    <div class="row mb-20">
        <div class="col-md-12  mb-10">
            <h6 class="content-header-title float-left mb-0">
                Payment
            </h6>
        </div>
        <div class="col-md-12">
            <div class="fs-11">Complete your payment successfully to proceed with your Card request.</div>
        </div>
    </div>

    <loader *ngIf="showLoader"></loader>

    <!-- <div class="row mb-20 mt-30">
        <div class="col-md-3 mb-10">
            <div class=" text-black fs-11 bg-light-grey p-10 border-grey">Item </div>
            <div class="text-black fs-12 fw-600 p-10 border-grey"> {{
                this.quotationResponse.quotationCurrencyResponses[0].product }} </div>
        </div>
        <div class="col-md-3 mb-10">
            <div class="text-black fs-11 bg-light-grey p-10 border-grey">IBR </div>
            <div class="text-black fs-12 fw-600 p-10 border-grey"> 1
                {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} = INR
                {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
            </div>
        </div>
        <div class="col-md-3 mb-10">
            <div class="text-black fs-11 bg-light-grey p-10 border-grey">Quantity </div>
            <div class="text-black fs-12 fw-600 p-10 border-grey">
                {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}}
                {{this.quotationResponse.quotationCurrencyResponses[0].currencyAmountString}}
            </div>
        </div>
        <div class="col-md-3 mb-10">
            <div class="text-black fs-11 bg-light-grey p-10 border-grey">Price </div>
            <div class="text-black fs-12 fw-600 p-10 border-grey"> ₹
                {{this.quotationResponse.quotationCurrencyResponses[0].inrEquivalentAmountString}}
            </div>
        </div>
    </div> -->

    <table class="table table-bordered ">
        <thead class="blue-background">
            <tr>
                <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td scope="row" class="text-center" style="padding-top: 10px">{{this.quotationResponse.quotationCurrencyResponses[0].product }}</td>
                <td class="text-center" style="padding-top: 10px">1 {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} = INR {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
                </td>
                <td class="text-center" style="padding-top: 10px">{{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} {{this.quotationResponse.quotationCurrencyResponses[0].currencyAmountString}}
                </td>
                <td class="text-center" style="padding-top: 10px">₹ {{this.quotationResponse.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                </td>
            </tr>
            <tr></tr>
        </tbody>
    </table>

    <div class="row mb-30">
        <div class="col-md-12 col-12">
            <div class="fs-12 text-black fw-600">
                Choose Your Bank Account
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
            <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                <div class="row mb-10">
                    <!-- <div class="col-md-1 col-1">
                </div> -->
                    <div class="col-md-12 col-11">
                        <input type="radio" name="radios" id="radio"
                            (change)="getSelectedBankAccountID(singleBank.id)" />

                        <span class="card-title fs-12 text-black fw-600 mb-10" style="margin-left:10px">
                            {{ singleBank.bankName }}
                        </span>
                        <div class="fs-11  ml-25"> Account No : {{singleBank.number}}</div>
                        <div class="fs-11  ml-25"> IFSC Code : {{singleBank.ifscCode}}</div>
                        <div class="fs-11  ml-25"> Branch : {{singleBank.branch}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-12 mt-20">
            <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true">
                Add Bank Account
            </button>
        </div>
    </div>

    <div class="row  mt-30">
        <div class="col-md-12">
            <div class="fs-12 text-black fw-600 mb-20">
                Payment Method
            </div>
        </div>
    </div>

    <div class="row mb-30">
        <div class="col-md-2 fs-12 mb-20">
            <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode"
                (click)="getSelectedPaymentMode('NB')" /> &nbsp; &nbsp; Net Banking
            <!-- <br><span>Pay via payment gateway</span> -->
        </div>
        <div class="col-md-3 fs-12 mb-20" *ngIf="ALLOW_NEFT_PAYMENT">
            <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode"
                (click)="getSelectedPaymentMode('MANUAL')" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
        </div>

        <div class="col-md-12 mt-30" *ngIf="paymentMode == 'MANUAL'">
            <div class="fs-12 fw-500  text-black">{{ accountName }}</div>
            <div class="fs-11  text-black">{{ bankName }}</div>
            <div class="fs-11  text-black">{{ accountNo }}</div>
            <div class="fs-11  text-black">{{ bankIFSC }}</div>
            <div class="fs-11  text-black">{{ bankBranch }}</div>
        </div>

        <div class="col-md-12 fs-10 text-danger mt-30 mb-30 text-500" *ngIf="paymentMode == 'MANUAL'">
            Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be
            transferred within 4 hours of order completion and the reference details of the transfer is to be updated in
            the My Order section on the WSFx Gloabl Pay App.
        </div>
    </div>
</section>