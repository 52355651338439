import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { RatesService } from './services/rates.service';
import { RatesComponent } from './view/rates.component';

const routes = [
  {
    path: 'rates',
    component: RatesComponent,
    data: { animation: 'RatesComponent' },
    canActivate : [AuthGuard]
  } ,
  
];

@NgModule({
    declarations: [ RatesComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      LoaderRoutingModule,
      NgbModule,
    ],
    exports: [ 
        RatesComponent
    ],
    providers: [
      RatesService
    ]

})
export class RatesRoutingModule {}
