export class AddRelationshipModel{
    consumerId: string;
    name: string;
    mobile: string;
    dob: any;
    email: string;
    nationality: string;
    residentStatus: string;
    occupation: string;
    address: string;
    aadharNumber: string;
    passportNumber: string;
    passportIssueDate:string;
    passportExpiryDate:string;
    passportIssuePlace: string;
    otherId: string;
    pan: string;
    relationship: string;
}