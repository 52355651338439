<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body mt-20">
                <div class="row">
                    <div class="col-md-12 mb-20">
                        <h6 class="content-header-title float-left mb-0 ml-10" style="color: black">Branch Network</h6>
                    </div>
                </div>
                <ul ngbNav #navCenter="ngbNav" class="nav nav-pills justify-content-center">
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/delhi.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Delhi</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14">Delhi | Delhi</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text  fs-11 mt-5">Unit No. 212, 2nd floor Phelp Building, 9A Connaught Place, New Delhi, <br>Delhi-110001.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984626 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Connaught+Place,+New+Delhi,+Delhi+110001/@28.6289206,77.2065322,15z/data=!3m1!4b1!4m8!1m2!2m1!1s308,+Connaught+Place,+F-14+,+New+Delhi,+-110001!3m4!1s0x390cfd37b741d057:0xcdee88e47393c3f1!8m2!3d28.6304203!4d77.2177216?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/goa.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Goa</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Goa | Margoa</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5">
                                                Shop No.7 , Ground Floor, Dr Rafael Plaza, Father Miranda Road, Opposite Collector’s Office,
                                                <br> Behind Post Office, Margo,
                                                <br>Goa – 403001</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984656 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/WALL+STREET+FINANCE+LTD/@15.2803671,73.9713098,15z/data=!3m1!4b1!4m5!3m4!1s0x3bbfb3e9c143a8a7:0x712c3018a12610d5!8m2!3d15.2803466!4d73.9800646?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/gujarat.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Gujarat</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Gujarat | Ahmedabad</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5">
                                                ONYX Building, FF-103, Near Rajhans Society, Gulbai Tekra, Navrangpura,
                                                <br> Ahmedabad - 380013, Gujarat</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984613 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Limited/@17.9632319,71.9005942,6z/data=!4m8!1m2!2m1!1swall+street+finance+ltd+near+Gujarat!3m4!1s0x0:0xcb42d1b0a7159535!8m2!3d23.0367702!4d72.5605774?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/karnataka.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Karnataka</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Karnataka | Bangalore</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Unit no. S-107, First Floor, South Block Manipal Centre, Dickenson Road,
                                                <br> M.G.Road Bengaluru-42. Karnataka</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984608</span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Manipal+Center/@12.9749138,77.6125349,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae168376d1218b:0xedfd363b68aeaf20!8m2!3d12.9749086!4d77.6147236?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/kerala.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Kerala</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Kerala | Cochin</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Room No.34/647, 1st Floor KK Building, Toll Junction,<br> Edappally P.O. Ernakulam - 682 024.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984616 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/wsfx+Wall+Street+Finance+Ltd/@9.1332101,75.1883105,7.21z/data=!4m8!1m2!2m1!1swall+street+finance+ltd+near+Kerala!3m4!1s0x0:0xac0a63dc297b6b57!8m2!3d10.034443!4d76.3137817?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/maharashtra.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Maharashtra</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Maharashtra | Andheri</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Unit no 305,Centre Point, Near J B Nagar Metro Station, Andheri Kurla Road,<br> Andheri East, Mumbai : 400059.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 7304471678</span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Ltd+(WSFx)/@19.1162539,72.8542171,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c9d6381e17df:0x53a63deafec11b2a!8m2!3d19.1162488!4d72.8564058?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Maharashtra | Pune</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Office No. 116, 5th floor, Shreenath Plaza, CTS No 1184, Plot No 559, Old S No 206 & 189, Bhamburda, Shivaji Nagar, Pune - 411005</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> +91 7304537715</span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Ltd/@18.5239249,73.8678054,15z/data=!4m8!1m2!2m1!1swall+street+finance+limited,pune!3m4!1s0x3bc2c1e1c97a26d9:0x22e46b705efc804e!8m2!3d18.5347891!4d73.8743555?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/golden-temple.png" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Punjab</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Punjab | Amritsar</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Shop No. 83, 1st Floor, Nehru Complex,<br> Lawrence Road, Amritsar- 143001, Punjab.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12">7508123788 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Limited/@31.6340473,74.8372445,13z/data=!3m1!4b1!4m5!3m4!1s0x391964aa569e7355:0xa0482c0695b0d94c!8m2!3d31.633979!4d74.872264?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Punjab | Chandigarh</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> SCO 107-108, Sector 17-B, Bank Square <br>Chandigarh -160 017.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12">9316004535 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Fin+Ltd/@30.6796118,76.8073197,11.25z/data=!4m8!1m2!2m1!1swall+street+finance+limited+near+Chandigarh!3m4!1s0x0:0xcbb25a38ed97bbbd!8m2!3d30.7466675!4d76.794219?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Punjab | Jallandhar</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5">106, Sahota Complex, Opp. Hotel Residency, Near Bus Stand, Jalandhar Punjab - 144 001.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12">9646880203</span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Limited/@31.3032406,75.5869298,15z/data=!3m1!4b1!4m5!3m4!1s0x391a5bb7c7c12aab:0x78f3db7cc7ed1f04!8m2!3d31.3032226!4d75.5956846?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Punjab | Ludhiana</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> SCO NO. 29, First Floor, Feroz Gandhi Market, <br> Ludhiana -141 001,<br> Punjab.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12">8976984638</span></div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+Street+Finance+Limited/@30.9015613,75.8301668,17z/data=!3m1!4b1!4m5!3m4!1s0x391a83a410b99833:0x11ef351425873ec8!8m2!3d30.9015567!4d75.8323555?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Punjab | Hoshiarpur</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Inder Estate, Near Session Chowk, Sutheri Road, <br> Hoshiarpur - 146001 ,<br> Punjab</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12">9316045557</span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Wall+street+finance+limited+(spice+Money)/@31.5277618,75.9108281,17z/data=!3m1!4b1!4m5!3m4!1s0x391ae2d846445b73:0x2fb8683af73a1308!8m2!3d31.5277572!4d75.9130168?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/tamilnadu.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Tamilnadu</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Tamil Nadu | Chennai</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> New no.53/TF/19D (Old Door No.26/TF/19) Office Flat "F", Second Floor Jamal Fazal Chambers Greams Road
                                                <br>Chennai 600 006.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984615 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Jamal+Fazal+Chamber,+Kammalar+St,+Thousand+Lights+East,+Thousand+Lights,+Chennai,+Tamil+Nadu+600006/@13.0571602,80.2536836,17z/data=!3m1!4b1!4m5!3m4!1s0x3a52663f9588dfa1:0x7a349ed83cd89a6b!8m2!3d13.057155!4d80.2558723?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Tamil Nadu | Coimbatore</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Esteem complex, 1st floor, 702,<br>Avinashi Road, <br> Race Course, Coimbatore South, <br>Coimbatore-641018.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8976984619 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" style="color:#3CC9EA" href="https://www.google.co.in/maps/place/Mecricar+Rd,+R+S+Puram+West,+Coimbatore,+Tamil+Nadu+641002/@11.0053064,76.9512811,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba85904d75d6801:0x9aac456794f2479b!8m2!3d11.0053011!4d76.9534698?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/telangana.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">Telangana</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> Telangana | Hyderabad</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Shop No.3, Ground Floor, Elite Arcade Complex,, Panjagutta , Hyderabad 500 082.</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8886325146 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/WALL+STREET+FINANCE+LTD+-+SPICE+MONEY/@17.22931,78.6107798,9.38z/data=!4m8!1m2!2m1!1swall+street+finance+limited+near+Hyderabad,+Telangana!3m4!1s0x0:0x5bb3dda8d29596b9!8m2!3d17.4261578!4d78.4511375?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="mt-15">
                        <a ngbNavLink class="mr-15">
                            <div class="row">
                                <img src="assets/images/branch/kolkata.webp" class="wh-70 auto-margin">
                                <div class="col-md-12 text-center fs-10 mt-5">West Bengal</div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mt-30">
                                <div class="col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title fs-14"> West Bengal | Kolkata</h5>
                                            <h6 class="card-subtitle fw-600 text-black">WSFx Global Pay</h6>
                                            <p class="card-text fs-11 mt-5"> Premises No. 2/7, Room No. 502, 5th Floor, Vasundhara, Sarat Bose Road, PO-Elgin Road<br> PS-Ballygunge, Kolkata -700020</p>
                                            <div class="card-link"><span class="material-icons fs-14 align-middle"> phone</span> <span class="fs-12"> 8697748172 </span> </div>
                                            <div class="mt-20">
                                                <a style="color:#3CC9EA" target="_blank" class="fs-11" href="https://www.google.co.in/maps/place/Lords+Building,+7%2F1,+Lord+Sinha+Road,+Elgin,+Kolkata,+West+Bengal+700071/@22.5417445,88.3467509,17z/data=!4m13!1m7!3m6!1s0x3a0277166c071cb1:0x2225b8fdeba3fcef!2sLords+Building,+7%2F1,+Lord+Sinha+Road,+Elgin,+Kolkata,+West+Bengal+700071!3b1!8m2!3d22.5417396!4d88.3489396!3m4!1s0x3a0277166c071cb1:0x2225b8fdeba3fcef!8m2!3d22.5417396!4d88.3489396?hl=en-GB&authuser=0">Click to view map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navCenter" class="mt-1"></div>
            </div>
        </div>
    </div>
</div>