import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import { StepperDataModel } from '../../models/stepperdata.model';
import { StepperComponent } from '../stepper/stepper.component';
@Component({
  selector: 'stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['../../stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperHeaderComponent implements OnInit {

  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public quotationResponse: any;
  public selectedPurpose: SinglePurposeItem;
  public steptwo: any;
  public stepthree: any;
  public stepfour: any;
  public currentStepNumber: any;

  constructor(
    private router: Router,
    private stepperComponent: StepperComponent,
  ) {
    this.getSessionData();
  }

  ngOnInit(): void { }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
    } else {
      this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        });
    }
    this.steptwo = this.stepperComponent.StepTwo;
    this.stepthree = this.stepperComponent.StepThree;
    this.stepfour = this.stepperComponent.StepFour;
  }
}
