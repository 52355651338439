import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SecuritySettingComponent } from './view/security-setting.component';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateProfileService } from '../update-profile/services/update-profile.service';
import { AuthGuard } from 'app/auth/helpers/auth.guards';

const routes = [
  {
    path: 'security-setting',
    component: SecuritySettingComponent,
    data: { animation: 'SecuritySettingComponent' }    ,
    canActivate : [AuthGuard]}
];

@NgModule({
  declarations: [SecuritySettingComponent],
  imports: [RouterModule.forChild(routes),
    TranslateModule,
    CommonModule,
    BrowserModule,
    FormsModule,ReactiveFormsModule     
  ],
  exports: [SecuritySettingComponent],
  providers: [UpdateProfileService],
})
export class SecuritySettingModule { }
