<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-12  mb-10">
                        <h6 class="content-header-title
                            float-left mb-0">
                            Payment
                        </h6>
                    </div>
                    <div class="col-md-12">
                        <div class="fs-11">Complete your payment successfully to proceed with your reload request.
                        </div>
                    </div>
                </div>

                <loader *ngIf="showLoader"></loader>

                <div class="row mb-20 mt-30">
                    <div class="col-md-3 mb-10">
                        <div class=" text-black fs-11 bg-light-grey p-10 border-grey">Item </div>
                        <div class="text-black fs-12 fw-600 p-10 border-grey"> {{ this.orderDetails?.orderItems[0]?.product}} </div>
                    </div>
                    <div class="col-md-3 mb-10">
                        <div class="text-black fs-11 bg-light-grey p-10 border-grey">IBR </div>
                        <div class="text-black fs-12 fw-600 p-10 border-grey"> 1 {{this.orderDetails?.orderItems[0]?.currencyCode}} = INR {{this.orderDetails?.orderItems[0]?.bidRate}}
                        </div>
                    </div>
                    <div class="col-md-3 mb-10">
                        <div class="text-black fs-11 bg-light-grey p-10 border-grey">Quantity </div>
                        <div class="text-black fs-12 fw-600 p-10 border-grey">
                            {{this.orderDetails?.orderItems[0]?.currencyAmount}}
                        </div>
                    </div>
                    <div class="col-md-3 mb-10">
                        <div class="text-black fs-11 bg-light-grey p-10 border-grey">Price </div>
                        <div class="text-black fs-12 fw-600 p-10 border-grey"> ₹ {{this.orderDetails?.orderItems[0]?.billableAmount}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-12">
                        <div class="fs-14 text-black fw-600">
                            Choose Your Bank Account
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect
                            routerLink="/add-bank-account">
                            Add Bank Account
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
                        <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                            <div class="row mb-10">
                                <div class="col-md-11 col-10">
                                    <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                    <span class="card-title fs-12 text-black fw-600 mb-10">
                                        {{singleBank.bankName}}
                                    </span>
                                    <div class="fs-11 ml-15"> Account No : {{singleBank.number}}</div>
                                    <div class="fs-11 ml-15 "> IFSC Code : {{singleBank.ifscCode}}</div>
                                    <div class="fs-11 ml-15 "> Branch : {{singleBank.branch}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-30">
                    <div class="col-md-12">
                        <div class="fs-12 text-black fw-600 mb-20">
                            Payment Method
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 fs-11">
                        <input type="radio" name="paymentType" id="radio" value='FULL' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(false)" /> Full Payment
                    </div>
                    <div class="col-md-2 text-right fw-600">
                        ₹ {{this.orderDetails?.invoice?.totalPaymentPendingString}}
                    </div>
                </div>
                <!-- <div class="row mt-20">
                    <div class="col-md-3 fs-11">
                        <input type="radio" name="paymentType" id="radio" value='PAY' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(true)" /> Book and Pay
                    </div>
                    <div class="col-md-2 text-right fw-600">
                        ₹ {{this.orderDetails?.invoice?.partialBillableAmount}}
                    </div>
                </div> -->
                <!-- <div class="row mt-10">
                    <div class="col-md-12 fs-9 text-danger fw-600">
                        ₹ {{this.orderDetails?.partialAmountNote}}
                    </div>
                </div> -->


                <div class="row mt-30">
                    <div class="col-md-12">
                        <div class="fs-12 text-black fw-600 mb-20">
                            Payment Mode
                        </div>
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-md-2 col-12 fs-12">
                        <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" (click)="getSelectedPaymentMode('NB')" /> &nbsp; &nbsp; Net Banking
                    </div>
                    <div class="col-md-3 col-12 fs-12  ">
                        <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" (click)="getSelectedPaymentMode('MANUAL')" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                    </div>

                    <div class="col-md-12 mt-30" *ngIf="selectedPaymentMode == 'MANUAL'">
                        <div class="fs-12 fw-500  text-black">{{accountName}}
                        </div>
                        <div class="fs-11  text-black">{{bankName}}</div>
                        <div class="fs-11  text-black">{{accountNo}}</div>
                        <div class="fs-11  text-black">{{bankIFSC}}</div>
                        <div class="fs-11  text-black">{{bankBranch}}</div>
                    </div>

                    <div class="col-md-12 fs-10 text-danger mt-30 mb-30 text-500" *ngIf="selectedPaymentMode == 'MANUAL'">
                        Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section on the WSFx Global
                        Pay App.
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div></div>
                    <button class="btn btn-primary" rippleEffect (click)="proceedToPayment()">Proceed</button>
                </div>
            </div>
        </div>
    </div>
</div>