<div class="content-wrapper container-xxl p-0">
    <div class="content-body">


        <loader *ngIf="showLoader"></loader>
        <div class="card card-body">
            <div class="row">
                <div class="col-md-5">
                    <div class="fs-14 text-black fw-600">
                        Beneficiary Accounts
                    </div>
                </div>
                <div class="col-md-5 col-8">
                    <!-- <select placeholder="Select Purpose" style="height: 36.54px;" class="form-control card-shadow fs-12" (change)="getBeneficiaryList($event.target.value)">
                        <option *ngFor="let singlePurpose of purposeList" value='{{ singlePurpose }}'>
                            {{ singlePurpose.split('_').join(' ') | titlecase }}
                        </option>
                    </select> -->
                    <select placeholder="Select Purpose" style="height: 36.54px;" class="form-control card-shadow fs-12" (change)="getBeneficiaryList($event.target.value)">
                        <option *ngFor="let singlePurpose of purposeListRemittance"
                            value='{{ singlePurpose.purposeCode }}'>
                            {{ singlePurpose.title }}
                        </option>
                    </select>
                </div>

                <div class="col-md-2 col-4 text-right">
                    <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect style="width:100%" (click)="goToAddBeneficiary()">
                        <span class="align-middle">Add</span>
                    </button>
                </div>
            </div>

            <div class="row mt-40" *ngIf="noDataFound">
                <div class="col-md-12 text-center">
                    <div class="text-black fw-500 ">
                        <div class="fs-11"> No beneficiary added </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!noDataFound">
                <div class="col-md-12 card-body" *ngFor="let singleBeneficiary of beneficiaryListItems; let i=index">
                    <div class="" [ngClass]="(beneficiaryListItems.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
                        <div class="row">
                            <div class="col-md-6 col-12 mb-10">
                                <span class="card-title fs-14 text-black fw-500">
                                    {{singleBeneficiary.viaAgentName | titlecase}}
                                </span>
                            </div>

                        </div>

                        <div class="row" *ngIf="singleBeneficiary.purpose != 'UNIVERSITY_FEES' && singleBeneficiary.purpose != 'UNIVERSITY_FEES_OWN_ACCOUNT' ">
                            <div class="col-md-6 col-12  mb-10">
                                <span class="card-title fs-14 text-black fw-500">
                                    {{
                                    singleBeneficiary.name
                                    }}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Purpose :
                                </span>
                                <span class="fs-11 text-black">
                                    {{ singleBeneficiary.purpose.replace('_', ' ') | titlecase }} </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Recepient Name :
                                </span>
                                <span class="fs-11 text-black">{{singleBeneficiary.name}}</span>
                            </div>
                        </div>

                        <div class="row" *ngIf="singleBeneficiary.beneficiaryPaymentType != null && singleBeneficiary.beneficiaryPaymentType != '' ">
                            <div class="col-md-6 col-12 ">
                                <span class="card-text fs-11 text-black fw-500 ">Payment Type :</span>
                                <span class="fs-11 ">{{singleBeneficiary.beneficiaryPaymentType}}</span>
                            </div>
                        </div>

                        <div class="row" *ngIf="singleBeneficiary.purpose != 'TRAVEL_AND_ACCOMMODATION' || (singleBeneficiary.bankName != null && singleBeneficiary.bankName != '' ) ">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Bank Name :
                                </span>
                                <span class="fs-11 text-black">{{singleBeneficiary.bankName}}</span>
                            </div>
                        </div>

                        <div class="row" *ngIf="singleBeneficiary.purpose != 'TRAVEL_AND_ACCOMMODATION'">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Account Number :
                                </span>
                                <span class="fs-11 text-black">{{singleBeneficiary.bankAccountNumber}}</span>
                            </div>
                        </div>

                        <div class="row" *ngIf="singleBeneficiary.purpose != 'TRAVEL_AND_ACCOMMODATION'">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Student ID/ Reference No :
                                </span>
                                <span class="fs-11 text-black">{{singleBeneficiary.studentId}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-12">
                                <span class="card-text fs-11 text-black fw-500">
                                    Beneficiary Approved :
                                </span>
                                <span class="fs-11 fw-600 " [ngClass]="singleBeneficiary.beneficiaryStatusType=='AUTO_APPROVED' ? 'text-success' : singleBeneficiary.beneficiaryStatusType=='PENDING' ? 'text-danger' : 'text-warning' ">{{singleBeneficiary.beneficiaryStatusType}}</span>
                            </div>
                        </div>

                        <section *ngIf="showDetailsBeneficiary && (singleBeneficiaryData.id == singleBeneficiary.id)">
                            <div class="row">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500">Country Name :</span>
                                    <span class="fs-11 text-black"> {{countryName}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.city != null && singleBeneficiaryData?.city != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500">City :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.city}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.bankAddress != null && singleBeneficiaryData?.bankAddress != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Account Holder Address :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.bankAddress}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.bankName != null && singleBeneficiaryData?.bankName != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Bank Name :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.bankName}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.bankAccountNumber != null && singleBeneficiaryData?.bankAccountNumber != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Account number/IBAN :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.bankAccountNumber}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.studentId != null && singleBeneficiaryData?.studentId != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Student ID :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.studentId}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.sortCode != null && singleBeneficiaryData?.sortCode != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500">SORT/ABA/BSB/TRANS No :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.sortCode}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.intermediaryBankSwiftCode != null && singleBeneficiaryData?.intermediaryBankSwiftCode != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Intermediary Bank Swift Code
                                        :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.intermediaryBankSwiftCode}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.remittanceForRelationship != null && singleBeneficiaryData?.remittanceForRelationship != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Relationship with Beneficiary
                                        :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData?.remittanceForRelationship}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.viaAgentName != null && singleBeneficiaryData?.viaAgentName != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> University Name :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.viaAgentName}} </span>
                                </div>
                            </div>
                            <!-- <div class="row"
                                *ngIf="singleBeneficiaryData?.name != null && singleBeneficiaryData?.name != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500">Account Holder Name :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.name}} </span>
                                </div>
                            </div> -->
                            <div class="row" *ngIf="singleBeneficiaryData?.currencyCode != null && singleBeneficiaryData?.currencyCode != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Currency :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.currencyCode}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.address != null && singleBeneficiaryData?.address != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Bank Address :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.address}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.swiftCode != null && singleBeneficiaryData?.swiftCode != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Swift Code :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.swiftCode}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.flyWireReferenceNumber != null && singleBeneficiaryData?.flyWireReferenceNumber != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500">GIC/FLYWIRE/WU REFERENCE NUMBER
                                        :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.flyWireReferenceNumber}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.intermediaryBankName != null && singleBeneficiaryData?.intermediaryBankName != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Intermediary Bank Name :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.intermediaryBankName}}
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.additionalDetails != null && singleBeneficiaryData?.additionalDetails != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Special Instructions :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData.additionalDetails}} </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleBeneficiaryData?.mobileNo != null && singleBeneficiaryData?.mobileNo != '' ">
                                <div class="col-md-11 col-11">
                                    <span class="card-text fs-11 text-black fw-500"> Beneficiary Mobile No :</span>
                                    <span class="fs-11 text-black"> {{singleBeneficiaryData?.mobileNo}} </span>
                                </div>
                            </div>
                        </section>

                        <div class="row mt-20 mb-20">
                            <div class="col-md-3 col-xl-2 col-lg-2 col-6">
                                <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect>
                                    <span class="align-middle"
                                        (click)="navigateToProceedOrder(singleBeneficiary)">Order</span>
                                </button>
                            </div>
                            <div class="col-md-3 col-xl-2 col-lg-2 col-3">
                                <button type="button" class="btn btn-outline-dark btn-prev fs-11" rippleEffect (click)="buttonText == 'View More' ? showBeneficiaryDetails(singleBeneficiary) : hideDetailsBeneficiary() " style="width:100%">
                                    <i class="icomoon icon-eye-solid"></i>
                                    {{buttonText}}
                                </button>
                            </div>

                            <div class="col-md-3 col-3 pl-0 pr-0">
                                <button type="button" class="btn btn-outline-danger btn-prev fs-11" rippleEffect (click)="deleteSingleBeneficiary(singleBeneficiary.id)">
                                    <i class="icomoon icon-bin_delete_dust_erace_garbage_icon "></i>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class=" card-footer text-black text-center link">
                        <div class="row">
                            <div class="col-md-6 col-6 border-right1 fs-12" (click)="showBeneficiaryDetails(singleBeneficiary)">View Details</div>
                            <div class="col-md-6 col-6 fs-12" (click)="deleteSingleBeneficiary(singleBeneficiary.id)">Delete</div>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</div>