import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'app/shared/utility/utils';
import { MasterDataService } from 'app/shared/services/master-data.service';

@Component({
  selector: 'details-beneficiary',
  templateUrl: './details-beneficiary.component.html',
  styleUrls: ['../../view/beneficiary.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsBeneficiaryComponent implements OnInit {
  public singleBeneficiaryDetails : any;
  public countryListItems ;
  public countryName  : any;

  constructor(
    public activateRoute : ActivatedRoute,
    public masterDataService : MasterDataService
  ) {
  }
 
  async ngOnInit(): Promise<void> {
    await this.getCountryList();
  }
  
   async getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
      this.getSingleBeneficairyDetails();
    }, error => {
    });
  }

  getSingleBeneficairyDetails(){
    this.activateRoute.queryParams.subscribe( params => {
      this.singleBeneficiaryDetails = params;
      var countryObject =  Utils.getSingleCountryByCountryCode(this.singleBeneficiaryDetails?.countryCode, this.countryListItems);
      this.countryName = countryObject?.countryName;
    });
  }
}
