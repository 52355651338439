import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';

@Component({
  selector: 'insurance-payment',
  templateUrl: './insurance-payment.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss',
    './insurance-payment.component.scss',
    '../../../../../@core/scss/base/pages/app-invoice.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsurancePaymentComponent implements OnInit {
  public plans: any;
  public showAddBankForm: boolean = false;
  public bankAccountList: any;
  public paymentMessage1 = "Please make the payment to the above mentioned account.";
  public paymentMessage2 = "Kindly note, funds transfer to be done from your registered bank account only.";
  public paymentMessage3 = "Once fund transfer made kindly update fund transfer details in payment section of order details page.";
  public bankAccountId: any;
  public paymentMode: any;
  public insuranceOrderId: any;
  public insuranceDetails: any;
  public showLoader: boolean = false;
  public cryptoUtils: CryptoUtils = new CryptoUtils();
  public responseJsonPaymentLink: any;
  public termsAndConditionUrl = "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/Terms%26Conditions/TermsAndConditions.pdf";
  public tncUrl = "https://b2capptnc.s3.ap-south-1.amazonaws.com/TravelInsurance/Terms%26Conditions/TermsAndConditions.pdf";

  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;

  constructor(
    public insuranceService: InsuranceService,
    private route: ActivatedRoute,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
  ) {
    // this.insuranceDetails = JSON.parse(sessionStorage.getItem(ConstantsKey.INSURANCE_RESPONSE));
    // this.insuranceOrderId = this.insuranceDetails.cartOrderId;
    this.insuranceOrderId = this.route.snapshot.paramMap.get('ordercartdetailsId');
  }

  ngOnInit() {
    this.getBankAccountList();
    this.getCartDetails();
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    }, error => {
    });
  }

  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getCartDetails() {
    this.insuranceService.getTravelInsuranceCartDetails(this.insuranceOrderId).subscribe(resp => {
      this.showLoader = false;
      var responeJson = resp.body;
      // this.plans = JSON.parse(this.cryptoUtils.resDecrypt(responeJson));
      console.log("this.insuranceDetail >>>>>> ", responeJson);
      // console.log("responseJson>>> ", this.planDetails);
    }, error => {
      this.plans = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
      console.log("this.insuranceDetail >>>>>> ", this.plans);

    });
  }

  proceedForPayment() {
    var responsePayment;
    this.showLoader = true;
    if (this.paymentMode) {
      if (this.bankAccountId) {
        if ((this.paymentMode != null && this.paymentMode != '') && this.paymentMode == 'NB') {
          this.insuranceService.insuranceGetPaymentGatewayLink(this.insuranceOrderId).subscribe(resp => {
            responsePayment = resp.body;
          }, error => {
            this.showLoader = false;
            responsePayment = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            window.location.href = responsePayment.payment_link;
          });
        } else {
          this.insuranceService.insuranceCartPlaceOrderNEFT(this.paymentMode, this.insuranceOrderId, this.plans?.totalPayableAmount).subscribe(resp => {
            this.showLoader = false;
            responsePayment = resp.body;
            this.router.navigate(['/payment-success', this.insuranceOrderId]);
          }, error => {
            this.showLoader = false;
            this.router.navigate(['/payment-success', this.insuranceOrderId]);
          });
        }
      } else {
        this.toast.error('Please select bank account');
      }
    } else {
      this.toast.error('Please select payment mode !');
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

}
