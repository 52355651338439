import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'document-upload',
  templateUrl: './document.component.html',
  encapsulation: ViewEncapsulation.None
})
export class KYCComponent implements OnInit {

  public otp: string;
  public tncCheckBox: any;
  public documentListResponse: any;
  public transactionDocListAwaiting: any;
  public transactionDocListUploaded: any;
  public skipDocumentUpload: boolean = false;
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public documenturl: any;

  constructor(
    private cardService: CardService,
    public toast: ToastrService) {
  }

  ngOnInit() {
    this.getDocumentsList();
  }

  getDocumentsList() {
    this.cardService.cartTotalVersusUploadedDocuments().subscribe((respDocList) => {
      this.documentListResponse = respDocList.body;
      this.transactionDocListAwaiting = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.AWAITING;
      this.transactionDocListUploaded = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.UPLOADED;
      console.log("transactionDocument++", this.transactionDocListAwaiting)
      var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
      if (getActiveOrderData) {
        this.stepperDataCardModel = JSON.parse(getActiveOrderData);
        if (this.documentListResponse.skipDocumentUpload) {
          this.stepperDataCardModel.kycDocumentUploadStatus = true;
          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
        }
      }

    }), error => {
      this.toast.error(error.error.message);
    };
  }

  uploadDownloaddocument(documentDetails) {
   this.documenturl = documentDetails.location;
   window.location.assign(this.documenturl);
  }

  fileUploadCartDocument(event, docType) {
    // this.showLoader = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];

      if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "CONSUMER");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
        formData.append('documentPurpose', "TRANSACTION");

        // if (file.size > 1000000) {
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.cardService.uploadDocument(formData).subscribe((respDoc) => {
            console.log("In fild upload success", respDoc);
            if (docType != "LOAN_DOCUMENT") {
              this.getDocumentsList();
            }
          }, error => {
            this.toast.error(error.error.message);
          });
        
      }
    }
  }
}
