<section>

    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body card-padding">
                    <h4 class="card-title mb-10">{{ this.quotationResponse.quotationCurrencyResponses[0].product | titlecase}}
                    </h4>
                </div>
                <div class="card-body" style="padding:0px 15px 15px 15px !important">
                    <div class="row">
                        <div class="col-md-6 col-6">
                            <div class="fs-12 text-black fw-500">
                                {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} {{this.quotationResponse.quotationCurrencyResponses[0].currencyAmountString}}
                            </div>
                        </div>
                        <div class="col-md-6 col-6">
                            <div class="text-black fs-17 fw-600"> ₹ {{this.quotationResponse.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="fs-11 fw-500"> 1 {{this.quotationResponse.quotationCurrencyResponses[0].currencyCode}} = INR {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-md-8 col-6">
            <div class="fs-15 text-black fw-600">
                Choose Your Bank Account
            </div>
        </div>
        <div class="col-md-4">
            <div class="card" style="height: 90px;cursor: pointer;" routerLink="/add-bank-account">
                <div class="card-body text-center">
                    <img class="add-bank-account" src="assets/images/purposeIcon/StudyAbroad-University.png">
                    <div class="fw-600 text-black">Add Bank Account</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4" *ngFor="let singleBank of bankAccountList">
            <div class="row mb-10">
                <div class="col-md-1 col-2">
                    <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                </div>
                <div class="col-md-11 col-10">
                    <div class="card-title fs-14 text-black fw-600 mb-10">
                        {{singleBank.bankName}}
                    </div>
                    <div class="fs-13 "> Account No : {{singleBank.number}}</div>
                    <div class="fs-13 "> IFSC Code : {{singleBank.ifscCode}}</div>
                    <div class="fs-13 "> Branch : {{singleBank.branch}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-30">
        <div class="col-md-12">
            <div class="fs-15 text-black fw-600 mb-20">
                Payment Method
            </div>
        </div>
    </div>
    <div class="row mb-30">
        <div class="col-md-3">
            <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" /> Net Banking
        </div>
        <div class="col-md-3">
            <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" /> NEFT/RTGS/IMPS
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev" rippleEffect>
            <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
            <span class="align-middle
                                            ">Previous</span>
        </button>
        <button class="btn btn-success btn-submit" rippleEffect (click)="step04Payment()">Proceed To
            Pay</button>
    </div>

</section>