import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router'
import { QuotationCurrencyItem, RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { OrderService } from 'app/modules/order/services/order.service'
import { StepperDataModel } from 'app/modules/remittance/models/stepperdata.model';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { SingleRateResItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { SsOrderDataModel } from '../../models/order.model';
import { BeneficiaryService } from 'app/modules/beneficiary/services/beneficiary.service';

@Component({
  selector: 'prepared-and-proceed',
  templateUrl: './prepared-and-proceed.component.html',
})
export class PreparedProceedOrderComponent implements OnInit {

  public orderId: any;
  public source: any;
  ///STEPPER DATA
  public stepperDataModel: StepperDataModel = new StepperDataModel();

  ///QUOTATION
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public singleRateResItem: SingleRateResItem = new SingleRateResItem();
  public quotationResponse: any;

  public selectedPurposeItem: any;
  public showLoader: boolean = true;
  public cartType: any;

  public ssOrderDataModel: SsOrderDataModel = new SsOrderDataModel();

  constructor(
    private route: ActivatedRoute,
    public orderService: OrderService,
    public beneficiaryService: BeneficiaryService,
    private router: Router,
    private remittanceService: RemittanceService,
    public toast: ToastrService,
    private masterDataService: MasterDataService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.orderId = params['orderId'];
      this.source = params['source'];
      this.checkAndContinueToStepperFlow();
    });
  }

  checkAndContinueToStepperFlow() {
    if (this.source == "INCOMPLETE-ORDER") {
      this.orderService.proceedIncompleteOrder(this.orderId).subscribe(resp => {
        var cartItemsSingleFirst = resp.body['cartItems'][0];
        this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(cartItemsSingleFirst.purpose);
        this.quotationCurrencyItem.currencyAmount = cartItemsSingleFirst.currencyAmount;
        this.quotationCurrencyItem.currencyCode = cartItemsSingleFirst.currencyCode;

        /// [start] store data for default
        this.incompleteOrderDataToSession(resp.body);
        sessionStorage.setItem(ConstantsKey.SS_ORDER_DATA, JSON.stringify(this.ssOrderDataModel));
        /// [end] store data for default

        this.getRateRange();
        this.proceedToStepper();
      }, error => {
        this.showLoader = false;
        this.toast.error(JSON.stringify(error.error.message));
      });
    }

    if (this.source == "BENEFICIARY-ORDER") {
      let beneficiaryDetails:any = sessionStorage.getItem(ConstantsKey.BENEFICIARY_ORDER_DATA);;
      beneficiaryDetails = JSON.parse(beneficiaryDetails);

      if(beneficiaryDetails == null) {
        var redirectionUrl = "/list-beneficiary";
        this.router.navigate([redirectionUrl])
      }

      console.log("beneficiaryDetails >>",beneficiaryDetails);

      this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(beneficiaryDetails?.purpose);
      this.quotationCurrencyItem.currencyAmount = "100";
      this.quotationCurrencyItem.currencyCode = beneficiaryDetails.currencyCode;

      this.getRateRange();
      this.proceedToStepper();

      /// [start] store data for default
      this.ssOrderDataModel.beneficiaryId = this.orderId;
      sessionStorage.setItem(ConstantsKey.BENE_ID,  this.orderId);
      sessionStorage.setItem(ConstantsKey.SS_ORDER_DATA, JSON.stringify(this.ssOrderDataModel));
      /// [end] store data for default
    }

    if (this.source == "RE-ORDER") {
      this.orderService.getOrderDetailsSingle(this.orderId).subscribe(resp => {
        if(resp.body['orderItems'][0]) {
          var orderItemsSingleFirst = resp.body['orderItems'][0];
          var beneficiaryId = resp.body['beneficiaryDetails']['id'];
          console.log(beneficiaryId);

          this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeStudentRemittance(orderItemsSingleFirst.purpose);
          this.quotationCurrencyItem.currencyAmount = orderItemsSingleFirst.currencyAmount;
          this.quotationCurrencyItem.currencyCode = orderItemsSingleFirst.currencyCode;

          /// [start] store data for default
          this.reOrderDataToSession(resp.body);
          sessionStorage.setItem(ConstantsKey.SS_ORDER_DATA, JSON.stringify(this.ssOrderDataModel));
          sessionStorage.setItem(ConstantsKey.BENE_ID, beneficiaryId);
          /// [end] store data for default

          this.getRateRange();
          this.proceedToStepper();
        } else {
          this.showLoader = false;
          this.toast.error('Invalid order id !');
          this.router.navigate(['/order-list']);
        }
      }, error => {
        this.showLoader = false;
        this.toast.error(JSON.stringify(error.error.message));
      });
    }
  }

  async proceedToStepper() {
    if (this.selectedPurposeItem && this.quotationCurrencyItem.currencyAmount) {
      this.showLoader = true;
      this.cartType = this.selectedPurposeItem.remittanceType;

      this.remittanceService.cartPrepare(this.cartType).subscribe(result => {
        this.getQuotationRequestBody();

        this.remittanceService.getQuotation(this.requestQuotationModel).subscribe(resp => {
          ///store active order in session storage
          this.stepperDataModel.purposeItem = this.selectedPurposeItem;
          this.stepperDataModel.quotationRequest = this.requestQuotationModel;
          this.stepperDataModel.quotationResponse = resp.body;

          sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
          this.showLoader = false;
          console.log("SS_ACTIVE_ORDER", JSON.stringify(this.stepperDataModel));

          if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
            this.router.navigate(['/stepper3step'])
          } else {
            this.router.navigate(['/stepper'])
          }
        }, error => {
          this.showLoader = false;
          this.toast.error(JSON.stringify(error.error.message));
        });
      });

    } else {
      var errorMessage = 'Please enter amount';
      if (!this.selectedPurposeItem) {
        errorMessage = "Please select purpose";
      }
    }
  }

  getQuotationRequestBody() {
    let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];

    /// prepare quotation request
    this.quotationCurrencyItem.product = Constants.productsEnum[2];
    this.quotationCurrencyItem.bidRate = this.singleRateResItem.minimumRateInINR;
    this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
      ? this.selectedPurposeItem.purposeCode
      : "UNIVERSITY_FEES";
    listQuotationCurrencies.push(this.quotationCurrencyItem);

    this.requestQuotationModel.sellType = Constants.sellTypes[0];
    this.requestQuotationModel.partnerId = Constants.partnerId;
    this.requestQuotationModel.nostroChargeType = 'REMITTER';
    this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;
  }

  getRateRange() {
    this.showLoader = true;
    this.masterDataService.getRateRange(
      Constants.productsEnum[2],
      this.quotationCurrencyItem.currencyCode,
      Constants.sellTypes[0]
    ).subscribe(resp => {
      this.showLoader = false;
      this.singleRateResItem = JSON.parse(JSON.stringify(resp.body));
    });
  }

  reOrderDataToSession(resData) {
    this.ssOrderDataModel.beneficiaryId = resData['beneficiaryDetails']?.id;
  }

  ///prepare order data available from incomplete order 
  incompleteOrderDataToSession(resData) {
    if (resData['cartBeneficiary']) {
      this.ssOrderDataModel.beneficiaryId = resData['cartBeneficiary']?.beneficiaryId;
    }
  }
}
