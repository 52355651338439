export class ActivateINRWalletRequest {
        contactNo: string;
        gender: true;
        nameOnId: string;
        address: string;
        profession: string;
        dob: string;
        pinCode: string;
        otp: true;
        education_qualification: string;
        annual_income: string;
        source_of_fund: string;
        idNumber: string;
        idType: string;
        purpose_of_card_issuance: string;
        entityId: string;
        kycRefNo: string;
        self_employed_profession: string;
        nature_of_business: string;

}