<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <loader *ngIf="showLoader"></loader>
        <div class="card">
            <div class="card-body mt-30">
                <div class="row">
                    <div class="col-md-6 mb-20">
                        <h6 class="content-header-title float-left mb-0 ml-10" style="color: black">Exciting Offers</h6>
                    </div>
                    <div class="col-md-12 text-center" *ngIf="noDataFound">
                        No offers available
                    </div>
                </div>
                <section>
                    <div class="row">
                        <div class="col-md-3" *ngFor="let offers of offersList">
                            <div class="card">
                                <img class="card-img-top" src="{{offers.imageUrl}}" alt="Card image cap">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-7 fs-11 fw-600 blue-title">{{ offers.title }}</div>
                                        <div class="col-md-5 fs-10 text-right text-success fw-600">{{offers.promoCodeId}}</div>
                                    </div>
                                    <div class="card-text mt-10 fs-9">{{offers.shortDescription}} </div>
                                    <div class="card-text mt-10 fs-9">Validity : {{offers.startDate | date:'dd/MM/yyyy'}} To {{offers.endDate| date:'dd/MM/yyyy'}}</div>
                                    <div class="card-text mt-10 fs-9 fw-600">Status : <span [ngClass]="{
                                        'text-warning': offers.status == 'PENDING',
                                        'text-success': offers.status == 'ACTIVE',
                                        'text-danger': offers.status == 'INACTIVE'
                                      }"> {{offers.status | titlecase}} </span> </div>
                                    <div class="text-center mt-20">
                                        <a class="btn btn-primary rounded-btn-blue" (click)="copyMessage(offers.promoCodeId)">Copy Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>