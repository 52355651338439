import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { UpdateProfileService } from './services/update-profile.service';
import { UpdateProfileComponent } from './view/update-profile.component';

const routes = [
  {
    path: 'update-profile',
    component: UpdateProfileComponent,
    data: { animation: 'UpdateProfileComponent' },
    canActivate : [AuthGuard]
  } ,
  
];

@NgModule({
    declarations: [ 
      UpdateProfileComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      NgbModule,
      LoaderRoutingModule,
      ToastrModule,
      NgSelectModule
    ],

    exports: [
      UpdateProfileComponent
    ],
    
    providers:[
      UpdateProfileService
    ]
})
export class ProfileUpdateRoutingModule {}
