import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { OpenGICAccountComponent } from './views/open-gic-account/open-gic-account.component';
import { OpenAccountFormComponent } from './views/open-account-form/open-account-form.component';

const routes = [
  {
    path: 'open-gic-account',
    component: OpenGICAccountComponent,
    data: { animation: 'OpenGICAccountComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'open-account-form/:title',
    component: OpenAccountFormComponent,
    data: { animation: 'OpenAccountFormComponent' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    OpenGICAccountComponent, 
    OpenAccountFormComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    LoaderRoutingModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [
    OpenGICAccountComponent, 
    OpenAccountFormComponent
  ],
  providers: [

  ]
})
export class OpenGICAccountRoutingModule { }
