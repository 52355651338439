<add-bank-account-for-remittance *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
</add-bank-account-for-remittance>
<section *ngIf="!showAddBankForm">
    <div class="row mb-20">
        <div class="col-md-12  mb-10">
            <h6 class="content-header-title float-left mb-0">
                Payment
            </h6>
        </div>
        <div class="col-md-12">
            <div class="fs-11">Complete your payment successfully to proceed with your Card request.</div>
        </div>
    </div>

    <loader *ngIf="showLoader"></loader>
    <table class="table table-bordered ">
        <thead class="blue-background">
            <tr>
                <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of quotationResponse.quotationCurrencyResponses; let i = index">
                <td scope="row" class="text-center" style="padding-top: 10px">{{item.product }}</td>
                <td class="text-center" style="padding-top: 10px">1 {{item.currencyCode}} = INR {{this.quotationResponse.quotationCurrencyResponses[0].rateString}}
                </td>
                <td class="text-center" style="padding-top: 10px">{{item.currencyCode}} {{item.currencyAmountString}}
                </td>
                <td class="text-center" style="padding-top: 10px">₹ {{item.inrEquivalentAmountString}}
                </td>
            </tr>
            <tr></tr>
        </tbody>
    </table>

    <section class="offer-section mt-20">
        <div class="row">
            <div class="col-md-4 col-9">
                <div class="form-group m-form__group">
                    <label>Apply Coupon</label>
                    <select class="form-control" name="offer" placeholder="Apply Coupon" required [(ngModel)]="quotationRequest.promoCode">
                       <option *ngFor="let singleOffer of listOffers" [value]="singleOffer.promoCodeId">
                       {{ singleOffer.title }}</option>
                    </select>

                </div>
            </div>
            <div class="col-md-2 col-3 pl-0 mt25">
                <button class="btn btn-primary" (click)="applyPromoCode()">Apply</button>
            </div>
        </div>
    </section>

    <section class="col-md-5 redeempoints-section mt-20  redeem-points p-15">
        <div class="row">
            <div class="col-md-9 col-9">
                <div class="form-group">
                    <input type="text" class="form-control fs-12" placeholder="Enter Redeemable Points*" [(ngModel)]="quotationRequest.pointsToRedeem" oninput="this.value = 
                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" />
                </div>
            </div>
            <div class="col-md-3 col-3">
                <button class="btn btn-primary" (click)="applyCreditPointToQuote()">Apply</button>
            </div>
            <div class="col-md-6">
                <div class="text-black fw-500 fs-10">You have {{ redeemableCredits?.availableCredit }} points</div>
            </div>
            <div class="col-md-6 text-right">
                <div class="text-black fw-500 fs-10">1 point = ₹ {{ redeemableCredits?.costPerCredit }}</div>
            </div>
        </div>
    </section>


    <div class="row mt-30">
        <div class="col-md-9 col-12">
            <div class="fs-12 text-black fw-600">
                Choose Your Bank Account
            </div>
        </div>
    </div>
    <div class="col-md-3 col-12">
        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true">
            Add Bank Account
        </button>
    </div>

    <div class="row">
        <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
            <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                <div class="row mb-10">
                    <!-- <div class="col-md-1 col-1">
                </div> -->
                    <div class="col-md-12 col-11">
                        <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />

                        <span class="card-title fs-12 text-black fw-600 mb-10" style="margin-left:10px">
                            {{ singleBank.bankName }}
                        </span>
                        <div class="fs-11  ml-25"> Account No : {{singleBank.number}}</div>
                        <div class="fs-11  ml-25"> IFSC Code : {{singleBank.ifscCode}}</div>
                        <div class="fs-11  ml-25"> Branch : {{singleBank.branch}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row  mt-30">
        <div class="col-md-12">
            <div class="fs-12 text-black fw-600 mb-20">
                Payment Method
            </div>
        </div>
    </div>

    <div class="row mb-20">
        <div class="col-md-2 fs-12 mb-20">
            <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" (click)="getSelectedPaymentMode('NB')" /> &nbsp; &nbsp; Net Banking
            <!-- <br><span>Pay via payment gateway</span> -->
        </div>
        <div class="col-md-3 fs-12 mb-20" *ngIf="ALLOW_NEFT_PAYMENT">
            <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" (click)="getSelectedPaymentMode('MANUAL')" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
        </div>

    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="fs-12 text-black fw-600">
                Payment Type
            </div>
        </div>
    </div>
    <div class="row mt-20 mb-20">
        <div class="col-md-3 fs-11">
            <input type="radio" name="paymentType" id="radio" value='false' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(false)" /> &nbsp; &nbsp; Full Payment
        </div>
        <div class="col-md-2   fw-600">
            ₹ {{this.quotationResponse.totalBillableAmount}}
        </div>
    </div>
    <div class="row mt-20" *ngIf="paymentMode == 'NB'">
        <div class="col-md-3 fs-11">
            <input type="radio" name="paymentType" id="radio" value='true' [(ngModel)]="isPartial" (click)="getSelectedPaymentType(true)" /> &nbsp; &nbsp; Book and Pay
        </div>
        <div class="col-md-2   fw-600">
            ₹ {{this.quotationResponse.partialBillableAmount}}
        </div>
    </div>
    <div class="row mt-10 mb-20" *ngIf="paymentMode == 'NB'">
        <div class="col-md-12 fs-9 text-danger fw-600">
           Book your forex order and pay later. Enjoy today's rate with a 1% deposit
        </div>
    </div>


    <div class="mt-20" *ngIf="paymentMode == 'MANUAL'">
        <div class="fs-12 fw-600  text-black">{{ accountName }}</div>
        <div class="fs-11  text-black">{{ bankName }}</div>
        <div class="fs-11  text-black">{{ accountNo }}</div>
        <div class="fs-11  text-black">{{ bankIFSC }}</div>
        <div class="fs-11  text-black">{{ bankBranch }}</div>
    </div>

    <div class="mb-30 fs-10 text-danger mt-30  text-500" *ngIf="paymentMode == 'MANUAL'">
        Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section.
    </div>

    <!-- <card-stepper-footer></card-stepper-footer> -->

</section>