import { Component, OnInit, ViewEncapsulation } from '@angular/core' 
import { Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'open-blocked-account',
  templateUrl: './open-blocked-account.component.html',
  styleUrls: ['./open-blocked-account.component.scss'],
  providers:[],
  encapsulation: ViewEncapsulation.None
})
export class OpenBlockedAccountComponent implements OnInit {
  public showLoader: boolean = false;
  public reqGetCurrencyMaster: ReqGetCurrencyMaster = new ReqGetCurrencyMaster();
  public currencyItems : any;
  public surrenderingCurrencyCode : any;
  public requiredCurrencyCode : any;
  public amount : any;
  public currencyConverterResponse : any;
  public convertedValue : any;

  constructor(
     public masterDataService : MasterDataService,
     public router : Router
  ) {}

  ngOnInit() { 
  }

  openAccountForm(title){
    this.router.navigate(['/open-account-form',title]);
  }

}
