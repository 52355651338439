import { Injectable } from "@angular/core";

@Injectable()
export class WsfxBankDetails  {
public static ACCOUNT_NAME =
    "WSFx Global Pay Limited. (Bank Details)";
public static BANK_NAME = "Bank Name - HDFC Bank";
public static ACCOUNT_NUMBER = "Account No: 13720330000031";
public static BANK_IFSC = "IFSC: HDFC0001372";
public static BANK_BRANCH =
    "Branch: JOGESHWARI WEST - OSHIWARA,Mumbai";
}