<style>
    .input-group-text {
        height: 37px !important;
        padding-left: 10px !important;
        font-size: 0.95rem;
        border: 1.2px solid #bbb;
        padding: 8px !important;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-weight: 400;
        line-height: 1.45;
        color: #6e6b7b;
        text-align: center;
        white-space: nowrap;
        background-color: white;
        border-radius: 0.357rem;
    }

    .table {

        thead,
        tfoot {
            th {
                vertical-align: top;
                text-transform: none;
                font-size: 0.850rem;
                letter-spacing: 0.2px;
            }
        }

        th,
        td {
            padding: 0.6rem 1rem;
            vertical-align: middle;
        }

        &.table-sm {

            th,
            td {
                padding: 0.72rem 0.5rem;

                &:first-child {
                    padding-left: 0.75rem;
                }
            }
        }
    }

    .table-responsive {
        display: inline-table;
        width: 100%;
        overflow-x: auto;
    }
</style>
<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h5 class="content-header-title float-left mb-10 fs-13">
                            Cashout To Bank
                        </h5>
                    </div>
                </div>
                <loader *ngIf="showLoader"></loader>

                <div class="row">
                    <div
                        [ngClass]="{'col-lg-6 col-md-6 col-sm-12 col-xs-12 col-6':isQuotationAvailable, 'col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12':!isQuotationAvailable}">
                        <div class="row" *ngFor="let currency of cardTransferList; let i= index">

                            <div
                                [ngClass]="{'col-lg-5 col-md-5 col-sm-12 col-xs-12 col-5':isQuotationAvailable, 'col-lg-3 col-md-3 col-sm-12 col-xs-12 col-3':!isQuotationAvailable}">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="colorCheck{{i}}"
                                                        (change)="getTransferCurrency(currency.isAdded,i,currency.currencyCode)" />
                                                    <label class="custom-control-label" for="colorCheck{{i}}"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-select class="fs-12" [(ngModel)]="currency.currencyCode"
                                            bindLabel="currencyCode" bindValue="currencyCode" [clearable]="false"
                                            readonly notFoundText="" placeholder="Select Currency "
                                            style="width: 79.5%;" [searchable]="false">
                                            <ng-template ng-label-tmp let-item="item">
                                                <img src="assets/images/currencyFlag/{{currency.currencyCode}}.png"
                                                    style="height:15px;width: 15px;"> {{currency.currencyCode}}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <small class="form-text text-black40 fs-10 mb-5">
                                        Balance = {{ currency.currencyBalance }}
                                    </small>
                                </div>
                            </div>

                            <!-- <div class="col-md-1 col-1">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck-{{i}}" (change)="getTransferCurrency(currency.isAdded,i,currency.currencyCode)" />
                                    <label class="custom-control-label" for="customCheck-{{i}}"></label>
                                </div>
                            </div>
                            <div class="col-md-2 col-5">
                                <ng-select class="fs-12" [(ngModel)]="currency.currencyCode" bindLabel="currencyCode" bindValue="currencyCode" [clearable]="false" readonly notFoundText="" placeholder="Select Currency">
                                    <ng-template ng-label-tmp let-item="item">
                                        <img src="assets/images/currencyFlag/{{currency.currencyCode}}.png" style="height:15px;width: 15px;"> {{currency.currencyCode}}
                                    </ng-template>
                                </ng-select>
                                <small class="form-text text-black40 fs-10 mb-5">
                                    Balance = {{ currency.currencyBalance }}
                                </small>
                            </div> -->

                            <div class="col-md-4 col-11">
                                <input type="number" pattern="^[1-9]+\.?[0-9]*$" required
                                    oninput="if(!this.value.match('^[1-9]+\.?[0-9]*$'))this.value='';"
                                    class="form-control fs-11" (change)="getTransferAmount($event)"
                                    [readonly]="!currency.isAdded" placeholder="Transfer Amount" />
                                <small class="form-text text-black40 fs-10 mb-5">
                                    IBR = {{ currency.rate }}
                                </small>
                            </div>

                            <div
                                [ngClass]="{'col-lg-3 col-md-3 col-sm-12 col-xs-12 col-3':isQuotationAvailable, 'col-lg-2 col-md-2 col-sm-12 col-xs-12 col-2':!isQuotationAvailable}">
                                <button class="btn btn-primary btn-block fs-11"
                                    (click)="amountChanges(currency.currencyCode,i)">
                                    Add to Cart
                                </button>
                            </div>
                        </div>
                        <section class="invoice-preview-wrapper " *ngIf="isQuotationAvailable">
                            <div class="row invoice-preview">
                                <div class="form-group col-md-7">
                                    <label class="form-label fs-10" for="country">Select Bank Account*</label>
                                    <ng-select class="fs-11" [(ngModel)]="selectedBankId" [items]="bankAccountItems"
                                        bindLabel="showTitle" bindValue="bankId" [clearable]="false " readonly
                                        (change)="setSelectedBank($event)" placeholder="Select Bank Account"
                                        [searchable]="false">
                                    </ng-select>
                                </div>

                                <div class="col-md-7" *ngIf="showBankDetails">
                                    <div class="bg-light-grey fs-10 p-10 text-black">
                                        <div class="fw-500">Transfer to :</div>
                                        <div>Bank : {{ selectedBankDetails.bankName }}</div>
                                        <div>Account No : {{ selectedBankDetails.number }}</div>
                                        <div>IFSC : {{ selectedBankDetails.ifscCode }}</div>
                                        <div>Branch : {{ selectedBankDetails.branch }}</div>
                                    </div>
                                </div>

                                <div class="col-md-7">
                                    <div class="fs-10 sky-blue-text  link" routerLink="/add-bank-account-for-card">
                                        Add Other Bank Account
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                    <section class="col-md-6" *ngIf="isQuotationAvailable">
                        <table class="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Currency</th>
                                    <th>FX Amount</th>
                                    <th>IBR</th>
                                    <th>INR Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody class="fs-10 text-black">
                                <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                                    <td>
                                        {{singleTransaction?.product}}
                                    </td>
                                    <td>
                                        {{singleTransaction?.currencyCode}}
                                    </td>
                                    <td>
                                        {{singleTransaction?.currencyAmount}}
                                    </td>
                                    <td>
                                        {{singleTransaction.rateString | number :
                                        '1.2-2'}}
                                    </td>
                                    <td>
                                        {{singleTransaction.inrEquivalentAmountString}}
                                    </td>
                                    <td>
                                        <span class="red-text-delete"><a (click)="removeCartItem(singleTransaction)">Delete
                                            </a></span>

                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="table-responsive mt-15 amout-breakup-bg">
                            <table class="table table-condensed table-borderless  fs-12">
                                <tbody>
                                    <tr style="border-top:1px solid #f4f4f4">
                                        <td colpan="2"></td>
                                    </tr>
                                    <tr>
                                        <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong>
                                        </td>
                                        <td class="thick-line text-right text-black fw-600 "> ₹ {{
                                            quotationDisplay?.billableAmount | number : '1.2-2' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.marginChargesString }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.finalChargesString }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>GST</strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.gst | number : '1.2-2' }}
                                        </td>
                                    </tr>

                                    <tr style="border-top:1px solid #bdbcbc">
                                        <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                        <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{
                                            quotationDisplay.totalBillableAmount | number : '1.2-2' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
                <div class="row d-flex justify-content-between mt-20">
                    <div class="col-11">
                       <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect routerLink="/my-card">
                            <!-- <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i> -->
                            <span class="align-middle">Back</span>
                        </button>
                    </div>
                    <div class="col-1" *ngIf="isQuotationAvailable">
                        <button type="submit" class="btn btn-primary btn-next fs-12" (click)="executeTransfer()" rippleEffect>
                            <span class="align-middle">Next</span>
                            <!-- <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i> -->
                        </button>
                    </div>   
                </div>
            </div>
        </div>
    </div>
</div>