import { Component, OnInit} from '@angular/core'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'customer-feedback',
  templateUrl: './customer-feedback.component.html',
})
export class CustomerFeedbackComponent implements OnInit {
  public chatMessage : any;
  public messageDetails : any;
  public showLoader : boolean = false;

  constructor(public supportService :  SupportService,
    public toast : ToastrService,
    public router :  Router) {}

  ngOnInit() {
  }

  submitFeedback(data){
    if(data == true){
    this.showLoader = true;
    this.supportService.addFeedback(this.chatMessage, 'Customer Feedback').subscribe((resp=>{
      this.showLoader = false;

      this.messageDetails = resp.body;
      this.chatMessage = '';
      this.toast.success('Feedback submitted successfully!');
      this.router.navigate(['/support']);
    }),error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
    }
  }
}
