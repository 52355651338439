import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
// import { SignaturePad } from 'angular2-signaturepad';
import { Subject } from 'rxjs';

@Component({
  selector: 'unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class UnauthorizedAccessComponent implements OnInit {

  public agentCode: string;
  public colorTheme = "black";

  // public colorTheme = "black";

  // Private
  private _unsubscribeAll: Subject<any>;

  

  /**
   * Constructor
   * @param {CoreConfigService} _coreConfigService
  **/
  constructor(
    private _coreConfigService: CoreConfigService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // this.agentCode = this.route.snapshot.paramMap.get('agentCode');
    // this.colorTheme;
    // this._unsubscribeAll = new Subject();
    // this._coreConfigService.config = {
    //   layout: {
    //     navbar: { hidden: true },
    //     menu: { hidden: true },
    //     footer: { hidden: true },
    //     customizer: false,
    //     enableLocalStorage: false
    //   }
    // };

    // this.signaturePad.set('minWidth', 0.5); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  // @ViewChild(SignaturePad) signaturePad: SignaturePad;
  // public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  //   'minWidth': 0.5,
  //   'canvasWidth': 200,
  //   'canvasHeight': 60
  // };

  // navigateToDashboard() {
  //   this.router.navigate(['/login']);
  // }

  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad.set('minWidth', 0.5); // set szimek/signature_pad options at runtime
  //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  // }

  // drawComplete() {
  //   // will be notified of szimek/signature_pad's onEnd event
  //   console.log(this.signaturePad.toDataURL());
  // }

  // drawStart() {
  //   // will be notified of szimek/signature_pad's onBegin event
  //   console.log('begin drawing');
  // }
}
