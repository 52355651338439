export class RateDropdownModel{
    currencyCode:string;
    salePriceInINR:Number;
    buyPriceInINR:Number;
}

export class LoginModel {
    type: string;
    id: string;
    otp: string;
    token: string;
    passwordType:string;
    constructor(type?: string, id?: string, otp?: string,token?:string) {
        this.type = type;
        this.id = id;
        this.otp = otp;
        this.token=token;
        this.passwordType=this.passwordType;
    }
}

export class SignUpRequestModel {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    password: string;
    referral: string;
    otp:string;
    panName:string;

    constructor(firstName?: string, lastName?: string, email?: string, mobile?: string, referral?: string, password?: string,otp?:string , panName?:string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.mobile = mobile;
        this.referral = referral;
        this.password = password;
        this.otp = otp;
        this.panName= panName;
    }
}

export class PasswordResetPatchRequest {
    id: string;
    authType: string;
    otp: string;
    newPassword: string;
    
    constructor(id?: string, authType?: string, otp?: string, newPassword?: string) {
        this.id = id;
        this.authType = authType;
        this.otp = otp;
        this.newPassword = newPassword;
    }
}


export class PasswordResetRequest {
    id: string;
    authType: string;
    otp:string;
    newPassword:string;
    constructor(id?: string, authType?: string,otp?:string,newPassword?: string) {
        this.id = id;
        this.authType = authType;
        this.otp= otp;
        this.newPassword=newPassword;
    }
}
