import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { OnboardingRoutingModule } from 'app/modules/onboarding/onboarding-routing.module';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { RemittanceRoutingModule } from './modules/remittance/remittance-routing.module';
import { BeneficiaryRoutingModule } from './modules/beneficiary/beneficiary-routing.module';
import { BankRoutingModule } from './modules/bank-account/bank-routing.module';
import { DashboardService } from './modules/dashboard/services/dashboard.service';
import { ErrorComponent } from './modules/error/error.component';
import { SupportRoutingModule } from './modules/support/support-routing.module';
import { ProfileUpdateRoutingModule } from './modules/update-profile/update-profile-routing.module';
import { RatesRoutingModule } from './modules/rates/rates-routing.module';
import { IsicCardRoutingModule } from './modules/isic-card/isic-card-routing.modules';
import { FaqRoutingModule } from './modules/faq/faq-routing.modules';
import { TutorialRoutingModule } from './modules/tutorial/tutorial-routing.module';
import { OrdersRoutingModule } from './modules/order/order-routing.module';
import { LoaderRoutingModule } from './shared/utility/utility-routing.module';
import { CardRoutingModule } from './modules/card/card-routing.module';
import { SecuritySettingModule } from './modules/security-setting/security-setting-routing.module';
import { AddressRoutingModule } from './modules/address/address-routing.module';
import { TestComponent } from './modules/test-component/test.component';
import { UnauthorizedAccessComponent } from './init-widget/view/unauthorized-access/unauthorized-access.component';
import { InitWidgetComponent } from './init-widget/view/init-widget/init-widget.component';
import { SetRateAlertRoutingModule } from './modules/set-rate-alert/set-rate-alert-routing.module';
import { InitWidgetService } from './init-widget/services/init_widget.service';
import { EmailLinkRoutingModule } from './modules/email-link/email-link-routing.module';
import { Stepper3StepComponent } from './modules/remittance/view/stepper-3/stepper3step.component';
import { StepperComponent } from './modules/remittance/view/stepper/stepper.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { InsuranceRoutingModule } from './modules/insurance/insurance-routing.module';
import { OfferRewardsRoutingModule } from './modules/offer-rewards/offer-rewards.module';
import { BranchNetworkRoutingModule } from './modules/branch-network/branch-network.module';
import { SmartFxRoutingModule } from './modules/smartfx-landing/smartfx-landing.module';
import { CurrencyConverterRoutingModule } from './modules/currency-converter/currency-converter.module';
import { MyAccountRoutingModule } from './modules/my-account/my-account.module';
import { DirectLoginRoutingModule } from './modules/direct-login/direct-login-routing.module';
import { DirectSignupRoutingModule } from './modules/direct-signup/direct-signup-routing.module';
import { SsoComponent } from './sso/view/sso.component';
import { OpenGICAccountRoutingModule } from './modules/open-gic-account/open-gic-account-routing.module';
import { OpenBlockedAccountRoutingModule } from './modules/open-blocked-account/open-blocked-account-routing.module';
import { ZolveCreditCardRoutingModule } from './modules/zolve-credit-card/zolve-credit-card-routing.module';
import { CardStepperComponent } from './modules/card/view/buy-card/card-stepper/card-stepper.component';
import { NewDashboardRoutingModule } from './modules/new-dashboard/new-dashboard-routing.module';
import { BuyForexLandingRoutingModule } from './modules/buy-forex-landing/buy-forex-landing-routing.module';
import { EnquiryFormRoutingModule } from './modules/enquiry-form/enquiry-form-routing.module';
import { SellCurrencyNotesRoutingModule } from './modules/currency-notes-flow/views/currency-notes-flow.module';
import { CoreTranslationService } from '@core/services/translation.service';
import { SidebarCollapsibleComponent } from './sidebar-collapsible/sidebar-collapsible.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('app/modules/onboarding/onboarding-routing.module').then(m => m.OnboardingRoutingModule)
  },
  {
    path: 'initWidget',
    pathMatch: 'full',
    component: InitWidgetComponent,
  },
  {
    path: 'sso',
    pathMatch: 'full',
    component: SsoComponent,
  },
  {
    path: 'unauthorizedAccess',
    pathMatch: 'full',
    component: UnauthorizedAccessComponent,
  },
  {
    path: '**',
    redirectTo: '/error'
  },
  {
    path: 'test-component',
    pathMatch: 'full',
    component: TestComponent,
  },
];

@NgModule({
  declarations: [AppComponent, ErrorComponent, InitWidgetComponent, TestComponent, UnauthorizedAccessComponent, SidebarCollapsibleComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      toastClass: 'toast ngx-toastr',
      timeOut: 3000,
      positionClass: 'toast-center-center',
      extendedTimeOut: 1000,
    }),
    //NgBootstrap
    NgbModule,
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    OnboardingRoutingModule,
    DashboardRoutingModule,
    RemittanceRoutingModule,
    BeneficiaryRoutingModule,
    BankRoutingModule,
    SupportRoutingModule,
    ProfileUpdateRoutingModule,
    RatesRoutingModule,
    IsicCardRoutingModule,
    FaqRoutingModule,
    TutorialRoutingModule,
    OrdersRoutingModule,
    LoaderRoutingModule,
    CardRoutingModule,
    SecuritySettingModule,
    AddressRoutingModule,
    SetRateAlertRoutingModule,
    EmailLinkRoutingModule,
    InsuranceRoutingModule,
    OfferRewardsRoutingModule,
    BranchNetworkRoutingModule,
    SignaturePadModule,
    SmartFxRoutingModule,
    CurrencyConverterRoutingModule,
    MyAccountRoutingModule,
    DirectLoginRoutingModule,
    DirectSignupRoutingModule,
    OpenGICAccountRoutingModule,
    OpenBlockedAccountRoutingModule,
    ZolveCreditCardRoutingModule,
    NewDashboardRoutingModule,
    BuyForexLandingRoutingModule,
    EnquiryFormRoutingModule,
    SellCurrencyNotesRoutingModule
  ],
  exports: [ErrorComponent],
  bootstrap: [AppComponent],
  providers: [DashboardService, InitWidgetService, Stepper3StepComponent, StepperComponent, CardStepperComponent]
})
export class AppModule { }
