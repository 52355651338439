import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { SetRateAlertService } from './services/set-rate-alert.service';
import { SetRateAlertComponent } from './view/set-rate-alert.component';

const routes = [
  {
    path: 'set-rate-alert',
    component: SetRateAlertComponent,
    data: { animation: 'SetRateAlertComponent' },
    canActivate : [AuthGuard]
  } ,
  
];

@NgModule({
    declarations: [ 
      SetRateAlertComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      LoaderRoutingModule,
      NgSelectModule,
      NgbModule,
    ],
    exports: [ 
      SetRateAlertComponent
    ],
    providers: [
      SetRateAlertService
    ]

})
export class SetRateAlertRoutingModule {}
