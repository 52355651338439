import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { AddressRequestRequestModel, PayerTravelerRequestModel, Step02DataTraveller, StepperDataCardModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { DateUtils } from 'app/shared/utility/date-utils';
import { Utils } from 'app/shared/utility/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'traveller-details',
  templateUrl: './traveller-details.component.html',
  styleUrls: ['../../../../remittance/stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TravellerDetailsComponent implements OnInit {

  public institutionItem: any;
  public delivery_date: NgbDateStruct;

  public paymentSourceDetails: string;
  public otherSourcePaymentDetails: string;
  public sourceOfFundsList = [];
  public sourceOfFundsListApi = [];
  public instituteList = [];
  public homeAddressList = [];
  public showTick: boolean = false;

  public scheduledTimeList = [
    "10:00am - 12:00pm",
    "12:00pm - 02:00pm",
    "02:00pm - 04:00pm",
    "04:00pm - 06:00pm",
  ];

  @Output() childCallbackSender = new EventEmitter<string>();

  @Input() refreshQuotationCountFooter: number = 0;

  ///SESSION DATA
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;
  public cartType: string;
  public maxdate: NgbDateStruct;
  public step02DataTraveller: Step02DataTraveller = new Step02DataTraveller();
  public payerTravelerRequestModel: PayerTravelerRequestModel = new PayerTravelerRequestModel();

  public addressType: string;
  public addressRequestRequestModel = new AddressRequestRequestModel();
  public selectedBranchAddress: any;
  public selectedHomeAddress: any;
  public branchAddressList = [];

  public tcsPaymentSourceBankDetails: string;
  public travellerList = [];
  public travellerDefaultSelected: string;
  public navigatedFromProduct: string;
  public traveller: any;
  public id: any;
  public paxDocument = [];
  public isPassportUploaded = false;
  public showPassportUI = true;

  public scheduledDate: any;
  public scheduledTime: any;

  public consumerDetail: any;
  public passportUploaded: boolean = false;
  public noHomeAddressFound: boolean = false;
  public showAddAddressForm: boolean = false;
  public showHomeAddresses: boolean = false;

  public loanDocument: any;
  public deliveryMaxDate: any;
  public deliveryMinDate: any;

  constructor(
    public masterDataService: MasterDataService,
    public cardService: CardService,
    public datepipe: DatePipe,
    public toast: ToastrService,
    public updateProfileService: UpdateProfileService
  ) {
    this.deliveryMinDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.navigatedFromProduct = sessionStorage.getItem("fromProduct");
    

  }

  ngOnInit() {
    if (this.cartType == 'CARD_PURCHASE') {
      this.showHomeAddresses = true;
    } 
    //  this.maxdate = JSON.parse(sessionStorage.getItem("departureDate"));
    //  console.log("maxdate>>>"+  this.maxdate);
    // this.deliveryMaxDate = { year: this.maxdate.year, month: this.maxdate.month, day: this.maxdate.day };

    this.getSessionData();
    this.getPaxList();
    this.getLoanProviderList();
    this.getSourceOfFundsList();
    this.getProfileList();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const chng = changes[propName];
      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }
    this.getSessionData();
    this.sourceOfFundsList = [];

    if (this.stepperDataCardModel.step01Data?.subPurpose != "STUDENT_TRAVEL") {
      this.sourceOfFundsListApi.forEach((sourceOfFundsListItems, index) => {
        if (sourceOfFundsListItems == Utils.toTitleCase("LOAN")) {
        } else {
          this.sourceOfFundsList.push(sourceOfFundsListItems)
        }
      });
    } else {
      this.sourceOfFundsList = this.sourceOfFundsListApi;
    }

    console.log("this.sourceOfFundsList on refresh", this.sourceOfFundsList);
    console.log("this.stepperDataCardModel.step01Data?.subPurpose", this.stepperDataCardModel.step01Data?.subPurpose);
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
      if (this.stepperDataCardModel.step01Data?.departure != null) {
              console.log("stepperDataCardModel >>>"+this.stepperDataCardModel.step01Data?.departure);

          this.deliveryMaxDate = DateUtils.ngbDateStructDateConverter(this.stepperDataCardModel.step01Data.departure);

      }
      if (this.stepperDataCardModel.cartType) {
        this.cartType = this.stepperDataCardModel.cartType;
      } else {
        this.stepperDataCardModel.cartType = this.cartType;
      }
      if (this.navigatedFromProduct == Constants.productsEnum[1] && this.cartType != 'CARD_PURCHASE') {
        for (let i = 0; i < this.quotationResponse.quotationCurrencyResponses.length; i++) {
          if (this.quotationResponse.quotationCurrencyResponses[i].product==Constants.productsEnum[0]) {
            this.showHomeAddresses = true;
            break;
          }

          
        }
        if (!this.showHomeAddresses ) {
          this.addressType = 'BRANCH';
        }
      }

    }
  }

  getSourceOfFundsList() {
    this.cardService.getSourceOfFundList().subscribe(respFundList => {
      // this.sourceOfFundsListApi = JSON.parse(JSON.stringify(respFundList.body));
      var sourceOfFundsListLocal = JSON.parse(JSON.stringify(respFundList.body));
      this.sourceOfFundsList = [];
      sourceOfFundsListLocal.forEach((sourceOfFundsListItems, index) => {
        if(sourceOfFundsListItems=="EDUCATION LOAN"){
          this.sourceOfFundsList.push(Utils.toTitleCase("LOAN"));
        this.sourceOfFundsListApi.push(Utils.toTitleCase("LOAN"));
        }else{
          this.sourceOfFundsList.push(Utils.toTitleCase(sourceOfFundsListItems));
        this.sourceOfFundsListApi.push(Utils.toTitleCase(sourceOfFundsListItems));
        }
        // this.sourceOfFundsList.push(Utils.toTitleCase(sourceOfFundsListItems));
        // this.sourceOfFundsListApi.push(Utils.toTitleCase(sourceOfFundsListItems));
        
      });
    });
    this.sourceOfFundsListApi = this.sourceOfFundsList;
  }

  getLoanProviderList() {
    this.cardService.getFinanceInstituteList().subscribe(respInstitute => {
      this.instituteList = JSON.parse(JSON.stringify(respInstitute.body));
    });
  }

  chooseAddressLocation(addressType) {
    this.addressType = addressType;
    if (addressType == "HOME") {
      this.getHomeAddress();
    } else if (addressType == "BRANCH") {
      this.getNearByBranchLocation();
    }
  }

  selectDeliveryHomeAddress(chooseHomeAddress) {
    this.selectedHomeAddress = chooseHomeAddress;
    this.addressRequestRequestModel.address = this.selectedHomeAddress.address;
    this.addressRequestRequestModel.deliveryStatus = "TO_BE_SCHEDULED";
    this.addressRequestRequestModel.deliveryAddressType = "HOME";
    this.updateSessionDataAfterSelection();
  }

  selectDeliveryOfficeAddress(chooseOfficeAddress) {
    this.selectedBranchAddress = chooseOfficeAddress;
    this.addressRequestRequestModel.address = this.selectedBranchAddress.address;
    this.addressRequestRequestModel.deliveryStatus = "TO_BE_SCHEDULED";
    this.addressRequestRequestModel.deliveryAddressType = "BRANCH";
    this.updateSessionDataAfterSelection();
  }

  getHomeAddress() {
    this.cardService.getAddressesHome().subscribe(respHomeAddress => {
      this.homeAddressList = JSON.parse(JSON.stringify(respHomeAddress.body));
      if (this.homeAddressList.length == 0) {
        this.noHomeAddressFound = true;
      }
    });
  }

  getNearByBranchLocation() {
    this.cardService.getNearByBranch(0.0, 0.0).subscribe(respBranchList => {
      this.branchAddressList = JSON.parse(JSON.stringify(respBranchList.body));
    });
  }

  getPaxList() {
    this.cardService.getPaxesList().subscribe(respPaxData => {
      var jsonResponse = JSON.parse(JSON.stringify(respPaxData.body));
      this.travellerList = jsonResponse.paxResponses;
      for (let index = 0; index < this.travellerList.length; index++) {
        if (this.travellerList[index].relationship == "SELF") {
          this.traveller = this.travellerList[index];
          this.id = this.traveller["id"];

          var paxDocument = [];
          paxDocument = this.traveller["paxDocuments"];
          this.paxDocument = this.traveller["paxDocuments"];
          for (let index = 0; index < paxDocument.length; index++) {
            if (paxDocument[index]["type"].includes('PASSPORT')) {
              this.isPassportUploaded = true;
              this.showPassportUI = false;
            }
          }
        }
      }
      this.travellerDefaultSelected = this.traveller?.name + " - " + this.traveller?.relationship;
      // this.addressProofList = jsonResponse.addressProof;
    });
  }

  updateSessionDate() {
    if(this.paymentSourceDetails){
      this.step02DataTraveller.paymentSourceDetails = this.paymentSourceDetails.toUpperCase();
    }
    //this.step02DataTraveller.paymentSourceDetails = this.paymentSourceDetails.toUpperCase();
    this.step02DataTraveller.sourceofFunds = this.tcsPaymentSourceBankDetails;

    // payerTravelerRequestModel
    this.payerTravelerRequestModel.payerName = this.traveller?.name;
    this.payerTravelerRequestModel.payerPan = this.traveller?.pan;
    this.payerTravelerRequestModel.payerPaxId = this.traveller?.id;
    this.payerTravelerRequestModel.travellerPaxId = this.traveller?.id;
    this.payerTravelerRequestModel.paxId = this.traveller?.id;
    this.payerTravelerRequestModel.remitterType = "PAYER";

    ///not in trip
    if (this.cartType == "CARD_PURCHASE" || this.cartType == "CASH_PURCHASE") {
      if (this.scheduledDate) {
        this.addressRequestRequestModel.scheduledDate = DateUtils.getNgbDateStructToDate(this.scheduledDate);
      }
      this.addressRequestRequestModel.scheduledTime = this.scheduledTime;
      this.payerTravelerRequestModel.deliveryRequest = this.addressRequestRequestModel;
    } else {
      this.addressRequestRequestModel.address =
        "Unit No.225, 2nd Floor, The Summit -Business Bay, Off. Andheri-Kurla Road, Behind Guru Nanak Pertol Pump, Opp Cinemax Prakashwadi, Andheri (East), Mumbai,Maharashtra - 400093";
      this.addressRequestRequestModel.deliveryStatus = "TO_BE_SCHEDULED";
      this.addressRequestRequestModel.deliveryAddressType = "BRANCH";
      this.addressRequestRequestModel.scheduledDate = this.datepipe.transform(
        new Date(),
        "yyyy-MM-dd"
      );
      this.addressRequestRequestModel.scheduledTime = this.scheduledTimeList[3];
      this.payerTravelerRequestModel.deliveryRequest = this.addressRequestRequestModel;
    }

    this.getSessionData();
    this.step02DataTraveller.payerTravelerRequestModel = this.payerTravelerRequestModel;
    this.stepperDataCardModel.step02Data = this.step02DataTraveller;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));

    this.childCallbackSender.emit('REFRESH_DATA');
  }

  updateSessionDataAfterSelection() {
    this.getSessionData();
    if(this.paymentSourceDetails){
      this.step02DataTraveller.paymentSourceDetails = this.paymentSourceDetails.toUpperCase();
    }
    this.step02DataTraveller.sourceofFunds = this.tcsPaymentSourceBankDetails;
    this.stepperDataCardModel.step02Data = this.step02DataTraveller;
    this.stepperDataCardModel.cartType = this.cartType;

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA_UPDATE_QUOTATION');
    this.getSessionData();
    this.updateSessionDate();
  }

  public showLoader: boolean = false;

  fileUploadLoanDocument(event) {
    console.log("tcsPaymentSourceBankDetails >>", this.tcsPaymentSourceBankDetails);
    if (this.tcsPaymentSourceBankDetails) {
      this.showLoader = true;
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];

        if (file.type.indexOf("pdf") >= 0 || file.type.indexOf("image") >= 0) {
          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('ownerType', "CONSUMER");
          formData.append('type', this.tcsPaymentSourceBankDetails);
          formData.append('description', "");
          formData.append('ownerId', localStorage.getItem(ConstantsKey.CONSUMER_ID));
          formData.append('documentPurpose', "LOAN_PROOF");

          // if (file.size > 1000000) {
          //   this.showLoader = false;
          //   this.toast.error('Please upload a file of up to 1MB only');
          // } else {
            this.cardService.uploadDocument(formData).subscribe((respDoc) => {
              this.showLoader = false;
              this.showTick = true;

              var jsonResponse = JSON.parse(JSON.stringify(respDoc.body));
              this.loanDocument = jsonResponse.cartDocuments[0].location;
              console.log("this.loanDocument ", this.loanDocument);

            }, error => {
              this.showLoader = false;
              this.toast.error(error.error.message);
            });
          
        }
      }
    } else {
      this.toast.error("Please select institution.");
    }
  }

  getProfileList() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.consumerDetail = resp.body;
      if (this.consumerDetail.paxResponse.passportNumber) {
        this.passportUploaded = true;
        sessionStorage.setItem('PASSPORT_AVAILABLE', 'true');
      }else{
        sessionStorage.setItem('PASSPORT_AVAILABLE', 'false');
      }
    });
  }

  parentCallbackForAddAddressForm($event) {
    if ($event == 'ADDRESS_ADDED') {
      this.showAddAddressForm = false;
      this.getHomeAddress();
    }
  }

  viewUploadedLoanDoc() {
    window.open(this.loanDocument, "_blank");
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }
}
