<div class="content-wrapper container-fluid p-2 p-md-0">
    <div class="content-body">
        <button (click)="backClicked()" type="button" class="btn btn-primary btn-back fs-12" rippleEffect>
            <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
            <span class="align-middle">Back</span>
        </button>
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center"><br><br>
                        <img src="assets/images/illustration/call.png" style="height: 100%; width: 100%;"><br>
                        <div class="card-title text-center mt-20 fs-14" style="color: black"><strong>Talk to our Forex expert</strong></div>
                        <button class="btn btn-primary btn-gradient btn-submit text-center fs-12 align-self-center" rippleEffect onclick="window.open('tel:8976707222')"> 8976707222</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center"><br><br>
                        <img src="assets/images/illustration/email.png" style="height: 100%; width: 100%;" alt="email"><br>
                        <div class="card-title text-center mt-20 fs-14" style="color: black"><strong>Email</strong></div>
                        <button class="btn btn-primary text-center btn-gradient btn-submit fs-12 align-self-center" onclick="window.open('mailto:customersupport@wsfx.in')">customersupport@wsfx.in</button>
                        <!-- <br>
                        <a class="card-subtitle text-black  mb-1" href="mailto:WSFXSmartcard@yesbank.in">
                            WSFXSmartcard@yesbank.in
                            <br>
                            (Card Support)
                        </a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center"><br><br>
                        <img src="assets/images/illustration/service.png"  style="height: 100%; width: 100%;" alt="request"><br>
                        <div class="card-title text-center mt-20 fs-14" style="color: black"><strong>Service Request</strong></div>
                        <button class="btn btn-primary btn-gradient btn-submit text-center fs-12 align-self-center" rippleEffect routerLink="/listServiceRequest">Click here</button>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center"><br><br>
                        <img src="assets/images/illustration/feedback.png" style="height: 100%; width: 100%;" alt="feedback"><br>
                        <div class="card-title text-center mt-20 fs-14" style="color: black"><strong>Customer Feedback</strong></div>
                        <button class="btn btn-primary btn-gradient btn-submit text-center fs-12 align-self-center" rippleEffect routerLink="/customerFeedback">Click here</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- <div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body align-m">
                        <img class="icon-p icon-size" src="assets/images/icons/call.png">
                        <h4 class="card-title" style="color: black">Call</h4>
                        <a class="card-subtitle text-black mb-1" href="tel:8976707222">
                            +91 8976707222
                            <br>
                            080-45651100 (24x7 Card Support)
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body align-m">
                        <img class="icon-p icon-size" src="assets/images/icons/email.png" alt="call">
                        <h4 class="card-title" style="color: black">Email</h4>
                        <a class="card-subtitle text-black  mb-1" href="mailto:customersupport@wsfx.in" style="color : #0062ff">customersupport@wsfx.in</a>
                        <br>
                        <a class="card-subtitle text-black  mb-1" href="mailto:WSFXSmartcard@yesbank.in" style="color : #0062ff">
                            WSFXSmartcard@yesbank.in
                            <br>
                            (Card Support)
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body align-m">
                        <img class="icon-p icon-size" src="assets/images/icons/service.png" alt="call">
                        <h4 class="card-title" style="color: black">Service Request</h4>
                        <a class="card-subtitle text-black  mb-1" routerLink="/listServiceRequest" style="color : #0062ff">Click here</a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                    <div class="card-body align-m">
                        <img class="icon-p icon-size" src="assets/images/icons/feedback.png" alt="call">
                        <h4 class="card-title" style="color: black">Customer Feedback</h4>
                        <a class="card-subtitle text-black  mb-1" routerLink="/customerFeedback" style="color : #0062ff">Click here</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->