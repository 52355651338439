import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationRequestModel } from 'app/modules/card/models/card.model';
import { StepperDataCardModel, TripInfoRequestModel } from 'app/modules/card/models/stepperdatacard.model';
import { CardService } from 'app/modules/card/services/card.service';
import { RemittanceService } from 'app/modules/remittance/services/remittance.service';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { MasterDataService } from 'app/shared/services/master-data.service';

@Component({
  selector: 'trip-information-email',
  templateUrl: './trip-information-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EmailTripInformationComponent implements OnInit {
  public return_date: NgbDateStruct;
  public departure_date: NgbDateStruct;
  public selectedCountry: any;
  public countryListItems: any;
  public tripMinDepartureDate: any;
  public tripMinReturnDate: any;
  public tripMaxDepartureDate: any;

  public countryCodeForDueDelligence: string;
  public showCrimerRegionModal: any;
  public showDueDeligenceModal: any;
  public showDueDeligenceForm: boolean = false;
  public sanctionedCountry: any;

  @ViewChild('crimeaRegionDailog') crimeaRegionDailog: ElementRef;
  @ViewChild('dueDiligenceDialog') dueDiligenceDialog: ElementRef;
  @ViewChild('noTransactionAllowedDialog') noTransactionAllowedDialog: ElementRef;
  public modalRef: any;
  public minDateForReturnDate: any;

  public subCategoryListForexCard = [
    {
      purpseTitle: "Leisure",
      purpseCode: "LEISURE"
    },
    {
      purpseTitle: "Immigration",
      purpseCode: "IMMIGRATION"
    },
    {
      purpseTitle: "Medical",
      purpseCode: "MEDICAL"
    },
    {
      purpseTitle: "Student Travel",
      purpseCode: "STUDENT_TRAVEL"
    }
  ];
  public tripInfoRequestModel: TripInfoRequestModel = new TripInfoRequestModel();

  @Output() childCallbackSender = new EventEmitter<string>();

  ///SESSION DATA
  public stepperDataCardModel: StepperDataCardModel = new StepperDataCardModel();
  public quotationRequest = new QuotationRequestModel();
  public quotationResponse: any;

  constructor(
    public masterDataService: MasterDataService,
    public cardService: CardService,
    public remittanceService: RemittanceService,
    public modalService: NgbModal
  ) {
    this.getSessionData();
  }

  ngOnInit() {
    this.getCountryList();
    this.tripInfoRequestModel.purpose = "PRIVATE VISIT";
    this.tripMinDepartureDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.tripMaxDepartureDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 4, day: new Date().getDate() };
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER_CARD);
    if (getActiveOrderData) {
      this.stepperDataCardModel = JSON.parse(getActiveOrderData);
      this.quotationRequest = this.stepperDataCardModel.quotationRequest;
      this.quotationResponse = this.stepperDataCardModel.quotationResponse;
    }
  }

  async updateStep01Data(inputParamater) {
    if (this.departure_date) {
      this.tripInfoRequestModel.departure = this.getProperFormatedDate(this.departure_date);
      this.tripMinReturnDate = { year: this.departure_date.year, month: this.departure_date.month, day: this.departure_date.day };
    }

    if (this.return_date) {
      this.tripInfoRequestModel.returnDate = this.getProperFormatedDate(this.return_date);
    }
    if (inputParamater == 'COUNTRY') {
      if (this.checkCountryDueDelligenceStatus(this.selectedCountry)) {
        this.getSessionData();
        this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;
        if (this.selectedCountry != 'UA') {
          this.stepperDataCardModel.step01Data.hideProceedButton = false;
        }
        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
        this.childCallbackSender.emit('REFRESH_DATA');
      }
    }

    this.getSessionData();
    this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;

    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
  }

  getProperFormatedDate(ngbDateStruct) {
    var returnDate = ngbDateStruct.year;
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.month).slice(-2);
    returnDate = returnDate + "-" + ('0' + ngbDateStruct.day).slice(-2);
    return returnDate;
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  async checkCountryDueDelligenceStatus(countryCode) {
    this.countryCodeForDueDelligence = countryCode;

    const respData = await this.remittanceService.getDueDelligenceStatus(countryCode).toPromise()
      .then((respData) => {
        return respData;
      }).catch((error) => {
        return error;
      });

    if (respData.status == 200) {
      this.sanctionedCountry = respData.body;
      if (countryCode == "UA") {
        this.openCrimeaRegionDailog();
      }

      if (countryCode != "UA" && this.sanctionedCountry.fatfSanctions == true) {
        this.openDueDeligenceDailog();
      }

      if (this.sanctionedCountry.enhanceDueDiligence == true) {
        this.showDueDeligenceForm = true;
      }
      return true;

    } else if (respData.status == 400) {
      this.openNoTransactionAllowedDailog();
      return false;
    }
  }

  openCrimeaRegionDailog() {
    this.modalRef = this.modalService.open(this.crimeaRegionDailog, {
    });
  }

  openDueDeligenceDailog() {
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }

    this.modalRef = this.modalService.open(this.dueDiligenceDialog, {
    });
  }

  openNoTransactionAllowedDailog() {
    this.getSessionData();
    this.stepperDataCardModel.step01Data = this.tripInfoRequestModel;
    this.stepperDataCardModel.step01Data.hideProceedButton = true;
    if (this.countryCodeForDueDelligence == "UA") {
      this.modalRef.close();
    }
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
    this.childCallbackSender.emit('REFRESH_DATA');
    this.modalRef = this.modalService.open(this.noTransactionAllowedDialog, {
    });
  }

  agreeDueDiligence() {
    this.modalRef.close();
    this.showDueDeligenceForm = true;
    this.getSessionData();

    this.stepperDataCardModel.ofacCardData.isOfacRequired = true;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER_CARD, JSON.stringify(this.stepperDataCardModel));
  }
 
}