<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h5 class="content-header-title float-left mb-0">
                            Validate Card Details
                        </h5>
                    </div>
                </div>
                <!-- <div class="row"> -->
                <form class="form-horizontal" (ngSubmit)="getValidateCardDetails(ValidateCard.form.valid)" #ValidateCard="ngForm">
                    <!-- <section class="col-md-12 mt-20"> -->
                    <div class="row mt-20">
                        <div class="form-group col-md-3 col-9">
                            <label class="form-label">Card Expiry</label>
                            <input type="text" name="cardexpiry" class="form-control fs-12" placeholder="Card Expiry" required [(ngModel)]="cardValidationRequestModel.cardExpiry" #cardExpiryRef="ngModel" [class.error]="ValidateCard.submitted && cardExpiryRef.invalid" />
                            <span *ngIf="ValidateCard.submitted && cardExpiryRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="cardExpiryRef.errors.required">Card expiry field is required!</small>
                            </span>
                        </div>
                        <div class="form-group col-md-3 col-9">
                            <label class="form-label">DOB*</label>
                            <div class="input-group">
                                <input class="form-control fs-12" placeholder="yyyy-mm-dd" name="Basic Date Picker" required ngbDatepicker #basicDP="ngbDatepicker" [(ngModel)]="cardValidationRequestModel.dob" (dateSelect)="onDateSelect($event)" [minDate]="{year: 1970, month: 1, day: 1}"
                                    readonly="readonly" #dobRef="ngModel" [class.error]="ValidateCard.submitted && dobRef.invalid" required>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                </div>
                            </div>

                            <span *ngIf="ValidateCard.submitted && dobRef.invalid" class="invalid-form">
                                <small class="form-text text-danger" *ngIf="dobRef.errors.required">Date of birth is required!</small>
                            </span>
                        </div>
                        <div class="col-md-3 col-3 mt-23">
                            <button type="submit" class="btn btn-primary btn-block fs-12" tabindex="4" rippleEffect>Proceed</button>
                        </div>
                    </div>
                    <!-- </section> -->
                </form>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>