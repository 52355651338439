import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { BranchNetworkService } from '../services/branch-network.service';

@Component({
  selector: 'branch-network',
  templateUrl: './branch-network.component.html',
  styleUrls: ['../../../../@core/scss/angular/libs/select.component.scss',
              './branch-network.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BranchNetworkComponent implements OnInit {
  public showLoader: boolean = false;

  constructor(
    public offerRewardsService: BranchNetworkService,
  ) { }

  ngOnInit() { }
}
