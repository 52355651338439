<div class="content-wrapper container-xxl p-0">
    <loader *ngIf="showLoader"></loader>
    <form class="form-horizontal">
        <div class="row mt-15">
            <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMark, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMark}">
                <div class="form-group">
                    <label for="basicInputFile">
                             Passport Front Page*
                        </label>
                    <div class="custom-file">
                        <input type="file" id="customFile" name="frontpage" class="custom-file-input fs-12" (change)="fileUploadPaxDocument($event,'PASSPORT_FRONT_PAGE')" />
                        <label class="custom-file-label" for="customFile">Choose file</label>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-5" *ngIf="showCheckedMark">
                <div class="input-group-append mt-23" style="height: 38px;">
                    <span class="input-group-text" (click)="downloadUploadedImage('PASSPORT_FRONT_PAGE')">
                            <img src="assets/images/icon/view-document.png" class="w-h-20"> 
                        </span>
                    <span class="input-group-text">
                            <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                        </span>
                </div>
            </div>

            <div [ngClass]="{'col-lg-4 col-md-4 col-sm-4 col-xs-12 col-7':showCheckedMarkForPassportBack, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12':!showCheckedMarkForPassportBack}">
                <div class="form-group">
                    <label for="basicInputFile">
                             Passport Address Page*
                        </label>
                    <div class="custom-file">
                        <input type="file" id="customFile" class="custom-file-input fs-12" name="addresspage" (change)="fileUploadPaxDocument($event,'PASSPORT_ADDRESS_PAGE')" />
                        <label class="custom-file-label" for="customFile">Choose file</label>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-5" *ngIf="showCheckedMarkForPassportBack">
                <div class="input-group-append mt-23" style="height: 38px;">
                    <span class="input-group-text">
                            <img src="assets/images/icon/view-document.png"
                                class="w-h-20"  (click)="downloadUploadedImage('PASSPORT_BACK_PAGE')"> 
                        </span>
                    <span class="input-group-text"><img src="assets/images/icon/checkmark.gif"
                                class="w-h-20"></span>
                </div>
            </div>
           
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label class="form-label">Passport Issue Date*</label>
                <div class="input-group">
                    <input class="form-control fs-12" name="passportIssueDate" placeholder="Passport Issue Date" [(ngModel)]="passportIssueDate" (click)="basicDP1.toggle()" ngbDatepicker #basicDP1="ngbDatepicker" required [maxDate]="passportIssueMaxDate" [minDate]="passportIssueMinDate">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP1.toggle()" type="button" rippleEffect></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label class="form-label">Passport Expiry Date*</label>
                <div class="input-group">
                    <input class="form-control fs-12" name="passportExpiryDate" placeholder="Passport Expiry Date" (click)="basicDP2.toggle()" [(ngModel)]="passportExpiryDate" ngbDatepicker #basicDP2="ngbDatepicker" required [minDate]="passportExpiryMinDate" [maxDate]="passportExpiryMaxDate">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP2.toggle()" type="button" rippleEffect></button>
                    </div>
                </div>
               
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label class="form-label">Passport Number*</label>
                <input type="text" id="passport_no" class="form-control fs-12" placeholder="Passport Number*" name="passport_no" [(ngModel)]="updateProfileModel.passportNumber">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label class="form-label">Passport File Number</label>
                <input type="text" id="passport_file_no" class="form-control fs-12" placeholder="Passport File Number" name="passport_file_no" [(ngModel)]="updateProfileModel.passportFileNumber">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label class="form-label">Passport Issue Place*</label>
                <input type="text" name="passport_issue_place" class="form-control fs-12" placeholder="Passport Issue Place*" [(ngModel)]="updateProfileModel.passportIssuePlace">
            </div>
            <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group ">
                <label class="form-label ">Birth Place*</label>
                <input type="text " name="birth_place " class="form-control fs-12 " placeholder="Birth Place* " [(ngModel)]="updateProfileModel.birthCity">
            </div>
        </div>

    </form>
</div>