<div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <div class="content-header">
            <h5 class="mb-10">My Bank Account</h5>
        </div>
        <form class="form-horizontal" (ngSubmit)="addBankAccount(AddBankAccount.form.valid)" #AddBankAccount="ngForm">
            <loader *ngIf="showLoader"></loader>
            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label class="form-label"><b>Name</b></label>
                    <input required type="text" class="form-control fs-12" placeholder="Name" style="border-color:black" [(ngModel)]="addBankAccountRequest.nameAsPerAccountNumber" name="name" #nameRef="ngModel" [class.error]="AddBankAccount.submitted && nameRef.invalid"
                        required (keypress)="letterOnly($event)" (paste)="onPaste($event)" maxlength="100" (keypress)="alphaNumberOnly($event)" />
                    <span *ngIf="AddBankAccount.submitted && nameRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="nameRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label" for="country">Relationship*</label>
                    <ng-select class="fs-12" [items]="bankRelationshipList" bindValue="bankRelationshipList" [(ngModel)]="addBankAccountRequest.relation" required placeholder="Relationship" name="relationship" #relationshipRef="ngModel" [class.error]="AddBankAccount.submitted && relationshipRef.invalid" [clearable]="false" [searchable]="true">
                    </ng-select>
                    <span *ngIf="AddBankAccount.submitted && relationshipRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="relationshipRef.errors.required">This field is required!</small>
                    </span>
                </div>
            </div>

            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label for="country"><b>Bank Name*</b></label>
                    <ng-select [items]="bankDropdownItemList" bindLabel="displayName" style="border-color:black" bindValue="displayName" [(ngModel)]="addBankAccountRequest.bankName" placeholder="Select" name="bank_name" #bankNameRef="ngModel" [class.error]="AddBankAccount.submitted && bankNameRef.invalid"
                        [searchable]="false" required>
                    </ng-select>
                    <span *ngIf="AddBankAccount.submitted && bankNameRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="bankNameRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label"><b>Branch Name</b></label>
                    <input type="text" class="form-control fs-12" name="ifscCode" placeholder="Branch Name" style="border-color:black" [(ngModel)]="addBankAccountRequest.branch" #branchNameRef="ngModel" [class.error]="AddBankAccount.submitted && branchNameRef.invalid" maxlength="100"
                        (keypress)="letterOnly($event)" required />

                    <span *ngIf="AddBankAccount.submitted && branchNameRef.invalid" class="invalid-form">
                        <small class="text-danger" *ngIf="branchNameRef.errors.required">This field is required!</small>
                    </span>

                </div>
            </div>            
            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label class="form-label"><b>Account Number</b></label>
                    <div class="input-group form-password-toggle">
                        <input [type]="showAccountNo ? 'text' : 'password'" class="form-control fs-12" style="border-color:black" [(ngModel)]="addBankAccountRequest.number" placeholder="Account Number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid"
                            (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required />
                        <div class="input-group-append" (click)="showAccountNo = !showAccountNo">
                            <span class="input-group-text cursor-pointer"><i class="feather"
                                    [ngClass]="{'icon-eye-solid': showAccountNo, 'icon-eye-slash-solid': !showAccountNo }"></i></span>
                        </div>
                    </div>
                    <!-- <input required type="password" class="form-control fs-12" placeholder="Account Number" [(ngModel)]="addBankAccountRequest.number" name="account_no" #accountNoRef="ngModel" [class.error]="AddBankAccount.submitted && accountNoRef.invalid" required /> -->
                    <span *ngIf="AddBankAccount.submitted && accountNoRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="accountNoRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label"><b>Confirm Account Number</b></label>
                    <input required type="text" name="confirm_acccount_no" class="form-control fs-12" style="border-color:black" placeholder="Confirm Account Number" [(ngModel)]="addBankAccountRequest.confirmAccNo" #confirmAccountNoRef="ngModel" [class.error]="AddBankAccount.submitted && confirmAccountNoRef.invalid"
                        (keypress)="keyPressNumbers($event)" (paste)="onPaste($event)" maxlength="30" required />
                    <span *ngIf="AddBankAccount.submitted && confirmAccountNoRef.invalid" class="invalid-form">
                        <small class="form-text text-danger" *ngIf="confirmAccountNoRef.errors.required">This field is
                            required!</small>
                    </span>
                </div>
            </div>
            <div class="row mt-15">
                <div class="form-group col-md-6">
                    <label class="form-label"><b>IFSC Number</b></label>
                    <input type="text" class="form-control fs-12" name="ifscCode" placeholder="IFSC Number" style="border-color:black" [(ngModel)]="addBankAccountRequest.ifscCode" #ifscCodeRef="ngModel" [class.error]="AddBankAccount.submitted && ifscCodeRef.invalid" required
                        (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)" maxlength="11" />
                    <span *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid" class="invalid-form">
                        <small class="text-danger" *ngIf="ifscCodeRef.errors.required">This field is required!</small>
                    </span>
                    <div class="text-danger fs-11" *ngIf="AddBankAccount.submitted && ifscCodeRef.invalid">IFSCCode must be in valid format!</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-3">
                    <button type="button" rippleEffect class="btn btn-primary mr-1 fs-12" (click)="backClicked()">Back</button>
                </div>
                <div class="col-md-9 col-9  mb-20 text-right">
                    <button type="submit" rippleEffect class="btn btn-primary mr-1 fs-12">Add</button>
                    <!-- <button type="reset" rippleEffect class="btn btn-outline-secondary fs-12">Reset</button> -->
                </div>
            </div>
        </form>
    </div>
</div>