import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { Router } from '@angular/router';
import { BankAccountService } from '../../services/bank-account.service';
import { VerifyBankAccountRequest } from '../../models/bank-account.model';
import { ToastrService } from 'ngx-toastr';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'list-bank-account',
  templateUrl: './list-bank-account.component.html',
//   styleUrls: ['../../view/beneficiary.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListBankAccoountComponent implements OnInit {
  public bankAccountList : any;
  public verifyBankAccountRequest = new VerifyBankAccountRequest();
  public showLoader  :boolean = false;
  public consumerDetails :any;
  public modalReference : any;
  public bankDetails :any;

  constructor( 
    private _coreSidebarService: CoreSidebarService,
    public router : Router, 
    public bankAccountService : BankAccountService,
    public toast : ToastrService,
    public modalService : NgbModal ) {
      var consumerData  = localStorage.getItem(ConstantsKey.CONSUMER_DATA);
      this.consumerDetails =JSON.parse(consumerData);
  }
 
  ngOnInit(): void {
    this.getMyBankAccountistAll();
  }
 
  getMyBankAccountistAll(){
    this.showLoader = true;
    this.bankAccountService.getMyBankAccountist().subscribe(res=>{
      this.bankAccountList = res.body;
      this.showLoader = false;
      console.log(res.body);
    }), error =>{
      this.showLoader = false;
      this.toast.error(error.error.message);
    };
  }

  verifyBankAccount(bankDetails){
    this.modalReference.close();
    this.showLoader = true;
    this.verifyBankAccountRequest.bankId  = bankDetails.id;
    this.verifyBankAccountRequest.beneficiaryAccountIFSC  = bankDetails.ifscCode;
    this.verifyBankAccountRequest.beneficiaryAccountNo  = bankDetails.number;
    this.verifyBankAccountRequest.mobile  = this.consumerDetails.mobile;
    this.verifyBankAccountRequest.name  = bankDetails.bankName;

    this.bankAccountService.verifyBankAccount(this.verifyBankAccountRequest).subscribe((res=>{
        this.showLoader = false;
        this.toast.success("Account verified successfully");
        this.getMyBankAccountistAll();

    }),error=>{
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  openModalBankVerification(bankDetails, bankAccountVerificationModal) {
   
    this.modalReference = this.modalService.open(bankAccountVerificationModal, { centered: true });
    this.bankDetails = bankDetails;
  }

 
}
