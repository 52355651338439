import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";

@Injectable()
export class SetRateAlertService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  setRateAlert(requestModel) {
    var resourceUrl = "rates/alert ";
    //requestList:Array<any>=[];
    return this.makePostCallWitList(resourceUrl, requestModel);
  }
}