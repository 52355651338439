import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { RequestAadhaarEsignDetail, RequestCartMapPayer, RequestMapBeneficiaryModel } from 'app/modules/email-link/models/cart.model';
import { Step02Data, StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { RemittanceService } from 'app/modules/email-link/services/email.service';
import { RelationshipService } from 'app/modules/relationship/services/relationship.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PagesList } from 'app/shared/constant/page-list';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { OrderConsumerA2AcceptanceRequest } from 'app/modules/email-link/models/email-link.model';

@Component({
  selector: 'app-stepper-3step-email',
  templateUrl: './stepper3step-email.component.html',
  styleUrls: ['../../../stepper.scss'
     ],
  encapsulation: ViewEncapsulation.None
})
export class Stepper3StepEmailComponent implements OnInit {

  public contentHeader: string;
  public horizontalWizardStepper: Stepper;
  public showSelfDeclarationPage: boolean = false;
  public selectedSourceofFund: any;
  public showFinancialOptions: boolean = false;
  public selectedPurpose: SinglePurposeItem;
  public countryListItems: any;
  public universityListItems: any;
  public selectedCountry: string;
  public selectedUniversity: string;
  public bankAccountList: any;
  public orderConsumerA2AcceptanceRequest: OrderConsumerA2AcceptanceRequest = new OrderConsumerA2AcceptanceRequest();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public quotationResponse: any;

  public currentStep: string;

  public sourceofFundItems = Constants.sourceOfFundsItemList;
  public financialInstitutionItem: any;
  public selectedfinancialInstitution: any;
  public selectedInstitution: any;
  public instituteName: any;

  public showLoader: boolean = false;
  public showStepper: boolean = true;

  public documentListResponse: any;
  public transactionDocListAwaiting: any;
  public transactionDocListUploaded: any;

  public modalReference: any;
  public documentUrl: any;
  public documentTitle: any;
  public navigateToFourthStep: boolean = false;
  public bankAccountId: any;

  public activeCartModelStep: any;
  public paymentMode: any;
  public paymentInstruction: any;

  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;
  public showAddBankForm: boolean = false;
  public ALLOW_NEFT_PAYMENT = Constants.ALLOW_NEFT_PAYMENT;

  constructor(private router: Router,
    public masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    public relationshipService: RelationshipService,
    public toast: ToastrService
  ) {
    this.getSessionData();
  }

  ngOnInit() {
    this.getCountryList();
    this.getBankAccountList();

    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this.contentHeader = this.selectedPurpose.title;
  }

  parentCallbackReceiverForListBeneficiary($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
      this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
    }
    if ($event == 'REFRESH_DATA_UPDATE_QUOTATION') {
      this.getSessionData();
      this.hitGetQuotationCall();
    }
  }

  parentCallbackForRelationship($event) {
    if ($event == 'RELATIONSHIP_DATA_ADDED') {
      this.getSessionData();
      this.getQuotationRequestBody();
    }
  }

  parentCallbackForAddBankAccountForm($event) {
    if ($event == 'ADD_BANK_ACCOUNT_ADDED') {
      this.showAddBankForm = false;
      this.getBankAccountList();
    }
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
      console.log("stepperdata mosel++", this.stepperDataModel);
    }
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  getUniversityList(selectedCountry) {
    this.showLoader = true;
    this.universityListItems = [];
    this.masterDataService.getUniversityList(this.selectedPurpose.remittanceType, selectedCountry).subscribe(resp => {
      this.universityListItems = JSON.parse(JSON.stringify(resp.body));
      var universityListItemsLocal = JSON.parse(JSON.stringify(resp.body));
      universityListItemsLocal.forEach((universitySingleItems, index) => {
        universitySingleItems['name'] = this.toTitleCase(universitySingleItems.name);
        this.universityListItems[index] = universitySingleItems;
      });
      this.showLoader = false;
    });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  addNewUniversity = (beneficiaryName: string) => {
    this.navigateToAddBeneficiary(beneficiaryName, this.selectedCountry);
  }

  navigateToAddBeneficiary(beneficiaryName, countryCode) {
    var sharedQueryParams = this.selectedPurpose;
    sharedQueryParams['sourcePage'] = PagesList.REMITTANCE_STEPPER;
    sharedQueryParams['beneficiaryName'] = beneficiaryName;
    sharedQueryParams['countryCode'] = countryCode;

    this.router.navigate(['/add-beneficiary'], { skipLocationChange: false, queryParams: sharedQueryParams });
  }

  getLoanProviderList() {
    this.remittanceService.getLoanProviderList().subscribe(resp => {
      this.financialInstitutionItem = resp.body;
    });
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    });
  }

  getSelectedSourceFund(selectedItem) {
    if (selectedItem == 'Loan') {
      this.showFinancialOptions = true;
      this.getLoanProviderList();
    } else {
      this.showFinancialOptions = false;
    }
  }

  horizontalWizardStepperNext(data) {
    if (data.form.valid === true) {
      this.horizontalWizardStepper.next();
    }
  }

  step01CreateCartItems() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step01Data?.beneficiary) {
        this.showLoader = true;
        this.currentStep = 'BENEFICIARY_STEP1';
        this.getSessionData();

        // let cartItemModel: CartItemModel = new CartItemModel();
        // cartItemModel.currencyAmount = this.quotationResponse.quotationCurrencyResponses[0].currencyAmount;
        // cartItemModel.bidRate =
        //   this.quotationResponse.quotationCurrencyResponses[0].rate;
        // cartItemModel.purpose =
        //   this.quotationResponse.quotationCurrencyResponses[0].purpose;
        // cartItemModel.currencyCode =
        //   this.quotationResponse.quotationCurrencyResponses[0].currencyCode;
        // cartItemModel.product = "REMITTANCE";
        // cartItemModel.nostroChargeType = "REMITTER";
        // cartItemModel.branchId = Constants.wsflReferenceBranchId;
        // cartItemModel.sellType = Constants.sellTypes[0];


        // cartItemModel.countryCode = this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
        // cartItemModel.beneficiaryName = this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
        // let cartItemMapRequestList = [cartItemModel];

        ///cart item port
        // this.remittanceService.cartItemPost(cartItemMapRequestList).subscribe((resp) => {
        // let requestMapBeneficiaryModel: RequestMapBeneficiaryModel = new RequestMapBeneficiaryModel();
        // requestMapBeneficiaryModel.beneficiaryId = this.stepperDataModel.step01Data.beneficiary.id;

        ///map beneficiary
        let requestMapBeneficiaryModel: RequestMapBeneficiaryModel = new RequestMapBeneficiaryModel();
        requestMapBeneficiaryModel.beneficiaryId = this.stepperDataModel.step01Data.beneficiary.id;
        requestMapBeneficiaryModel.orderId = this.stepperDataModel.orderDetails.orderId;
        this.remittanceService.mapBeneficiary(requestMapBeneficiaryModel).subscribe(async (respMapBeneficiary) => {
          ///refresh quotation
          // this.getQuotationRequestBody();
          // await this.hitGetQuotationCall();
          // await this.updateStepNo(1);
          await this.getDocumentList();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.showLoader = false;
          this.horizontalWizardStepper.next(); // move to step 2

        }, error => {
          this.showLoader = false;
          this.toast.error(error.error.message);
        });

        // }, error => {
        //   this.showLoader = false;
        //   this.toast.error(error.error.message);
        // });
      }
      else {
        this.toast.error('Please select beneficiary');
      }
    }
  }

  async step02StudentDetails(data) {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step02Data?.selfResPaxItemSingle) {
        this.showLoader = true;
        if (data.form.valid === true) {
          this.currentStep = 'STUDENT_STEP2';
          this.getSessionData();
          // this.getQuotationRequestBody();
          // await this.hitGetQuotationCall();
          // await this.updateStepNo(2);
          await this.mapPayerToCart();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.showLoader = false;
          this.horizontalWizardStepper.next();

        } else {
          this.showLoader = false;
        }
      } else {
        this.toast.error('Please select relation');
      }
    }
  }

  async step03GetDocumentProceed(data) {
    // this.showLoader = true;
    if (data.form.valid === true) {
      this.currentStep = 'DOCUMENT_STEP03_1';
      this.getSessionData();

      if (this.selectedPurpose.remittanceType == "OTHERS") {

        await this.getRelationshipList();
        // await this.updateStepNo(2);
        await this.mapPayerToCart();
        // this.getQuotationRequestBody();
        // await this.hitGetQuotationCall();
        // await this.updateStepNo(3);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.horizontalWizardStepper.next();

      } else {
        // await this.updateStepNo(3);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.horizontalWizardStepper.next();
      }
    }
  }

  async step03EsignOrOtpValidate(data) {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step03Data?.checkboxChecked == true) {
        // if(this.stepperDataModel?.step03Data?.signatureUpload == true) {
          if (this.stepperDataModel?.step03Data?.otpValue != null && this.stepperDataModel?.step03Data?.otpValue != '') {
            this.showLoader = true;
            this.orderConsumerA2AcceptanceRequest.otp = this.stepperDataModel?.step03Data?.otpValue;
            this.orderConsumerA2AcceptanceRequest.orderId = this.stepperDataModel.orderDetails.orderId;
            this.remittanceService.getA2FormCart(this.orderConsumerA2AcceptanceRequest).subscribe((respDownloadA2) => {
              this.showLoader = false;
              var jsonResult = JSON.parse(JSON.stringify(respDownloadA2.body));
              if (jsonResult.cartDetails.a2FormUrl) {
                let a2FormUrl = jsonResult.cartDetails.a2FormUrl;
                window.open(a2FormUrl, "_blank");
              }
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.updateStepNo(4);
              this.horizontalWizardStepper.next();
            }, error => {
              this.showLoader = false;
              this.toast.error(error.error.message);
            });
        } else {
          this.toast.error("Please enter OTP");
        }
        // } else {
        //   this.toast.error("Please upload signature");
        // }
      }
      else {
        this.toast.error("Please accept declaration");
      }
    } else {
      this.router.navigate(['/home'])
      .then(() => {
      window.location.reload();
       });
    }
  }

  async getDocumentForSign() {
    let orderId = this.activeCartModelStep.cartDetails.refOrderId;
    let cartType = this.selectedPurpose.remittanceType;
    // const respEsign = await this.remittanceService.getDocumentSigned(orderId, cartType).toPromise();
  }

  async aadharEsignDocumentDetails() {
    let requestAadhaarEsignDetail = new RequestAadhaarEsignDetail();
    requestAadhaarEsignDetail.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);;
    requestAadhaarEsignDetail.refOrderId = this.activeCartModelStep.cartDetails.refOrderId;

    this.remittanceService.getA2FormSignedDoc(requestAadhaarEsignDetail).toPromise();
    await this.updateStepNo(4);
  }

  async getRelationshipList() {
    var respRelationShip = await this.relationshipService.getRelationshipList().toPromise();
    var relationshipMemberListItems: Array<any> = JSON.parse(JSON.stringify(respRelationShip.body));
    if (relationshipMemberListItems.length > 0) {
      relationshipMemberListItems.forEach(singlePaxItem => {
        if (singlePaxItem.relationship.toLowerCase() == "self") {
          if (singlePaxItem.pan != null && singlePaxItem.pan.length == 10) {
            let step02Data = new Step02Data();
            step02Data.selfResPaxItemSingle = singlePaxItem;
            this.stepperDataModel.step02Data = step02Data;
            sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
          }
        }
      });
    }
  }

  gotoDashboard() {
    this.router.navigate(['/home'])
      .then(() => {
      window.location.reload();
       });
  }

  async hitGetQuotationCall() {
    var respQuotation = await this.remittanceService.getQuotation(this.requestQuotationModel).toPromise();
    this.stepperDataModel.quotationResponse = respQuotation.body;
    this.stepperDataModel.quotationRequest = this.requestQuotationModel;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    console.log("Quotation called");
  }

  async updateStepNo(stepNo: number) {
    const response = await this.remittanceService.getCartToUpdateStepNumber(stepNo).toPromise();
    this.activeCartModelStep = response.body;

    //[start] update session data
    this.getSessionData();
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.stepperDataModel.cartItemsResponse = this.activeCartModelStep;
    }
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    //[end] update session data

    this.showLoader = false;
    return response;
  }

  horizontalWizardStepperPrevious() {
    this.horizontalWizardStepper.previous();
  }

  openModalForPaymentInfo(paymentInstructionModal) {
    this.modalReference = this.modalService.open(paymentInstructionModal, { centered: true });
    if (this.paymentMode == PaymentMode.NET_BANKING) {
      this.paymentInstruction = "Please note, you are requested to upload a proof of your payment in the Order Details section of My Orders, if you do choose to proceed with this mode of payment.\n";
    } else {
      this.paymentInstruction = "Please note, if a payment is not received within 4 hours, your order will stand cancelled. Please do contact customer care at +91 89767 07222 for any queries.\n";
    }
  }

  step04Payment() {
    this.modalReference.close();
    this.showLoader = true;
    var paymentMode = this.paymentMode;
    var requestBody = {
      "bankAccountId": this.bankAccountId,
      "paymentMode": paymentMode,
      "orderId": this.stepperDataModel.orderDetails.orderId,
    };

    this.remittanceService.placeOrder(requestBody).subscribe(respPayment => {
      var respPaymentBody = JSON.parse(JSON.stringify(respPayment.body));
      this.showLoader = false;
      if (paymentMode == PaymentMode.NET_BANKING) {
        this.getPaymentGatewayLink(respPaymentBody.orderId);
      } else {
        this.router.navigate(['/payment-success', respPaymentBody.orderId]);
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    })
  }

  async mapPayerToCart() {
    this.getSessionData();
    let requestCartMapPayer = new RequestCartMapPayer();

    requestCartMapPayer.remitterType = this.selectedPurpose.remittanceType;
    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPan = this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;

    requestCartMapPayer.paxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    if (this.selectedPurpose.remittanceType != "OTHERS") {
      requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    }

    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    requestCartMapPayer.orderId = this.stepperDataModel.orderDetails.orderId;

    // Map requestCartMapPayerJson = requestCartMapPayer.toJson();
    if (this.selectedPurpose.remittanceType == "OTHERS") {
      // requestCartMapPayer.remove('paidBy');
      // requestCartMapPayer.remove('paxId');
    }

    await this.remittanceService.orderPayerMap(requestCartMapPayer).toPromise();
  }

  getQuotationRequestBody() {
    this.requestQuotationModel = this.quotationRequest;

    if (this.currentStep == 'STUDENT_STEP2') {
      this.requestQuotationModel.pan = this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;
      this.requestQuotationModel.tcsPaymentSource = this.selectedSourceofFund.toUpperCase();
      if (this.selectedSourceofFund == 'Loan') {
        this.requestQuotationModel.tcsPaymentSourceDetails = (this.selectedInstitution != 'Other') ? this.selectedInstitution : this.instituteName;
      } else {
        this.requestQuotationModel.tcsPaymentSourceDetails = '';
      }
      this.requestQuotationModel.paymentSourceDetails = this.selectedSourceofFund;
      this.requestQuotationModel.taxResidentOfIndia = true;
      // this.requestQuotationModel.otherSourcePaymentDetails = '';
    }

    if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS" &&
      this.stepperDataModel?.step02Data?.selfResPaxItemSingle?.pan != null) {
      this.requestQuotationModel.calculateTcs = true;
      this.requestQuotationModel.tcsPaymentSource = "OWN";
      this.requestQuotationModel.taxResidentOfIndia = true;
      this.requestQuotationModel.pan =
        this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;
      this.requestQuotationModel.paymentSourceDetails = "";
    }
  }

  fileUploadCartDocument(event, docType) {
    this.showLoader = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log("File >>>");
      console.log(file);
      if (
        file.type.indexOf("pdf") >= 0 ||
        file.type.indexOf("image") >= 0
      ) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "ORDER");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', this.stepperDataModel.orderDetails.orderId);
        formData.append('documentPurpose', "TRANSACTION");

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.remittanceService.uploadOrderDocument(formData).subscribe((respDoc) => {
            this.showLoader = false;
            if (docType != "LOAN_DOCUMENT") {
              this.getDocumentList();
            }

          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    }
  }

  async getDocumentList() {
    this.remittanceService.getOrderDocuments(this.stepperDataModel.orderDetails.orderId).subscribe((resp => {
      this.documentListResponse = resp.body;
      this.transactionDocListAwaiting = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.AWAITING;
      this.transactionDocListUploaded = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.UPLOADED;

      if (this.documentListResponse.skipDocumentUpload == true) {
        this.navigateToFourthStep = true;
      }
    }), error => {
      this.toast.error(error.error.message);
    });
  }

  showToast() {
    this.toast.error("Please upload mandatory document");
  }

  openModalForTransactionDocumentView(transactionDocumentViewModal, documentDetails) {
    this.modalReference = this.modalService.open(transactionDocumentViewModal, { centered: true });
    this.documentUrl = documentDetails.location;
    this.documentTitle = documentDetails.documentType;
  }

  documentsDownload(documentDetails) {
    this.documentUrl = documentDetails.location;
    window.location.assign(this.documentUrl);
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getPaymentGatewayLink(orderId: string) {
    this.remittanceService.getPaymentGatewayLink(orderId).subscribe(respPaymentLink => {
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      window.location.href = responsePaymentLinkBody.payment_link;
    }, error => {
      console.log("Error in generating payment link", error);
    });
  }
}