export class SetRateAlertRequestModel {
  rateAlertCreateRequest : RateAlertCreateRequestList;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  comment: string;
  companyName: string;
  businessNature: string;
  city: string;
  country: string;
  enquiryAmount: 0;
  passport: string;
  referenceNumber: string;
  contactUsRequestType: string;
}

// export class RateAlertCreateRequest {
  export class  RateAlertCreateRequestList  
    {
      receiversId: string;
      currencyCode: string;
      partnerId: string;
      upperLimit: 0;
      lowerLimit: 0;
      product: string;
      sellType: string;
      receiversType: string;
    }
 