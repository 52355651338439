import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { CartItemModel, RequestAadhaarEsignDetail, RequestCartMapPayer, RequestMapBeneficiaryModel } from 'app/modules/email-link/models/cart.model';
import { Step02Data, StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import { RemittanceService } from 'app/modules/email-link/services/email.service';
import { RelationshipService } from 'app/modules/relationship/services/relationship.service';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { PagesList } from 'app/shared/constant/page-list';
import { PaymentMode } from 'app/shared/constant/payment-mode';
import { SinglePurposeItem } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stepper-payment-email',
  templateUrl: './payment-email.component.html',
  styleUrls: ['../../../stepper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentEmailComponent implements OnInit {

  public contentHeader: string;
  public horizontalWizardStepper: Stepper;
  public showSelfDeclarationPage: boolean = false;
  public selectedSourceofFund: any;
  public showFinancialOptions: boolean = false;
  public selectedPurpose: SinglePurposeItem;
  public countryListItems: any;
  public universityListItems: any;
  public selectedCountry: string;
  public selectedUniversity: string;
  public bankAccountList: any;

  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public quotationRequest = new RequestQuotationModel();
  public quotationResponse: any;

  public currentStep: string;

  public sourceofFundItems = Constants.sourceOfFundsItemList;
  public financialInstitutionItem: any;
  public selectedfinancialInstitution: any;
  public selectedInstitution: any;
  public instituteName: any;

  public showLoader: boolean = false;
  public showStepper: boolean = true;

  public documentListResponse: any;
  public transactionDocListAwaiting: any;
  public transactionDocListUploaded: any;

  public modalReference: any;
  public documentUrl: any;
  public documentTitle: any;
  public navigateToFourthStep: boolean = false;
  public bankAccountId: any;

  public activeCartModelStep: any;
  public paymentMode: any;

  constructor(private router: Router,
    public masterDataService: MasterDataService,
    public remittanceService: RemittanceService,
    private modalService: NgbModal,
    public relationshipService: RelationshipService,
    public toast: ToastrService
  ) {
    this.getSessionData();
  }

  ngOnInit() {
    this.getCountryList();
    this.getBankAccountList();

    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    this.contentHeader = this.selectedPurpose.title;
  }

  parentCallbackReceiverForListBeneficiary($event) {
    if ($event == 'REFRESH_DATA') {
      this.getSessionData();
      this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
      this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
    }
  }

  parentCallbackForRelationship($event) {
    if ($event == 'RELATIONSHIP_DATA_ADDED') {
      this.getSessionData();
      this.getQuotationRequestBody();
    }
  }

  getSessionData() {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      this.selectedPurpose = this.stepperDataModel.purposeItem;
      this.quotationRequest = this.stepperDataModel.quotationRequest;
      this.quotationResponse = this.stepperDataModel.quotationResponse;
    }
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    }, error => {
    });
  }

  getUniversityList(selectedCountry) {
    this.masterDataService.getUniversityList(this.selectedPurpose.remittanceType, selectedCountry).subscribe(resp => {
      this.universityListItems = resp.body;
    }, error => {
    });
  }

  addNewUniversity = (beneficiaryName: string) => {
    this.navigateToAddBeneficiary(beneficiaryName, this.selectedCountry);
  }

  navigateToAddBeneficiary(beneficiaryName, countryCode) {
    var sharedQueryParams = this.selectedPurpose;
    sharedQueryParams['sourcePage'] = PagesList.REMITTANCE_STEPPER;
    sharedQueryParams['beneficiaryName'] = beneficiaryName;
    sharedQueryParams['countryCode'] = countryCode;

    // this.router.navigate(['/add-beneficiary']);
    this.router.navigate(['/add-beneficiary'], { skipLocationChange: false, queryParams: sharedQueryParams });
  }

  getLoanProviderList() {
    this.remittanceService.getLoanProviderList().subscribe(resp => {
      this.financialInstitutionItem = resp.body;
    }, error => {
    });
  }

  getBankAccountList() {
    this.remittanceService.getBankAccountList().subscribe(resp => {
      this.bankAccountList = resp.body;
    }, error => {
    });
  }

  getSelectedSourceFund(selectedItem) {
    if (selectedItem == 'Loan') {
      this.showFinancialOptions = true;
      this.getLoanProviderList();
    } else {
      this.showFinancialOptions = false;
    }
  }

  horizontalWizardStepperNext(data) {
    if (data.form.valid === true) {
      this.horizontalWizardStepper.next();
    }
  }

  step01CreateCartItems() {
    this.showLoader = true;
    this.currentStep = 'BENEFICIARY_STEP1';
    this.getSessionData();

    let cartItemModel: CartItemModel = new CartItemModel();
    cartItemModel.currencyAmount = this.quotationResponse.quotationCurrencyResponses[0].currencyAmount;
    cartItemModel.bidRate =
      this.quotationResponse.quotationCurrencyResponses[0].rate;
    cartItemModel.purpose =
      this.quotationResponse.quotationCurrencyResponses[0].purpose;
    cartItemModel.currencyCode =
      this.quotationResponse.quotationCurrencyResponses[0].currencyCode;
    cartItemModel.product = "REMITTANCE";
    cartItemModel.nostroChargeType = "REMITTER";
    cartItemModel.branchId = Constants.wsflReferenceBranchId;
    cartItemModel.sellType = Constants.sellTypes[0];


    cartItemModel.countryCode = this.selectedCountry = this.stepperDataModel.step01Data.beneficiary.countryCode;
    cartItemModel.beneficiaryName = this.selectedUniversity = this.stepperDataModel.step01Data.beneficiary.viaAgentName;
    let cartItemMapRequestList = [cartItemModel];

    ///cart item port
    this.remittanceService.cartItemPost(cartItemMapRequestList).subscribe((resp) => {
      let requestMapBeneficiaryModel: RequestMapBeneficiaryModel = new RequestMapBeneficiaryModel();
      requestMapBeneficiaryModel.beneficiaryId = this.stepperDataModel.step01Data.beneficiary.id;

      ///map beneficiary
      this.remittanceService.mapBeneficiary(requestMapBeneficiaryModel).subscribe(async (respMapBeneficiary) => {
        ///refresh quotation
        this.getQuotationRequestBody();
        await this.hitGetQuotationCall();
        await this.updateStepNo(1);
        await this.getDocumentList();

        this.showLoader = false;
        this.horizontalWizardStepper.next(); // move to step 2

      }, error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  async step02StudentDetails(data) {
    this.showLoader = true;
    if (data.form.valid === true) {
      this.currentStep = 'STUDENT_STEP2';
      this.getSessionData();
      this.getQuotationRequestBody();
      await this.hitGetQuotationCall();
      await this.updateStepNo(2);
      await this.mapPayerToCart();

      this.showLoader = false;
      this.horizontalWizardStepper.next();

    } else {
      this.showLoader = false;
    }
  }

  async step03GetDocumentProceed(data) {
    this.showLoader = true;
    if (data.form.valid === true) {
      this.currentStep = 'DOCUMENT_STEP03_1';
      this.getSessionData();

      if (this.selectedPurpose.remittanceType == "OTHERS") {

        await this.getRelationshipList();
        await this.updateStepNo(2);
        await this.mapPayerToCart();

        this.getQuotationRequestBody();
        await this.hitGetQuotationCall();
        await this.updateStepNo(3);
        this.horizontalWizardStepper.next();

      } else {
        await this.updateStepNo(3);
        this.horizontalWizardStepper.next();
      }
    }
  }

  async step03EsignOrOtpValidate(data) {
    var getActiveOrderData = sessionStorage.getItem(ConstantsKey.SS_ACTIVE_ORDER);
    if (getActiveOrderData) {
      this.stepperDataModel = JSON.parse(getActiveOrderData);
      if (this.stepperDataModel?.step03Data?.checkboxChecked == true) {
        this.showLoader = true;
        await this.getDocumentForSign();
        /// get addhar esign doc

        this.getQuotationRequestBody();
        await this.hitGetQuotationCall();
        await this.updateStepNo(4);
        this.showLoader = false;
        this.horizontalWizardStepper.next();
      }
      else {
        this.toast.error("Please accept declaration");
      }
    } else {
      this.router.navigate(['/home'])
      .then(() => {
      window.location.reload();
       });
    }
  }

  async getDocumentForSign() {
    let orderId = this.activeCartModelStep.cartDetails.refOrderId;
    let cartType = this.selectedPurpose.remittanceType;
    const respEsign = await this.remittanceService.getDocumentSigned(orderId, cartType).toPromise();
  }

  async aadharEsignDocumentDetails() {
    let requestAadhaarEsignDetail = new RequestAadhaarEsignDetail();
    requestAadhaarEsignDetail.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);;
    requestAadhaarEsignDetail.refOrderId = this.activeCartModelStep.cartDetails.refOrderId;

    this.remittanceService.getA2FormSignedDoc(requestAadhaarEsignDetail).toPromise();
    await this.updateStepNo(4);
  }

  async getRelationshipList() {
    var respRelationShip = await this.relationshipService.getRelationshipList().toPromise();

    var relationshipMemberListItems: Array<any> = JSON.parse(JSON.stringify(respRelationShip.body));
    if (relationshipMemberListItems.length > 0) {
      relationshipMemberListItems.forEach(singlePaxItem => {
        if (singlePaxItem.relationship.toLowerCase() == "self") {
          if (singlePaxItem.pan != null && singlePaxItem.pan.length == 10) {
            let step02Data = new Step02Data();
            step02Data.selfResPaxItemSingle = singlePaxItem;
            this.stepperDataModel.step02Data = step02Data;
          }
        }
      });
    }
  }

  async hitGetQuotationCall() {
    var respQuotation = await this.remittanceService.getQuotation(this.requestQuotationModel).toPromise();
    this.stepperDataModel.quotationResponse = respQuotation.body;
    this.stepperDataModel.quotationRequest = this.requestQuotationModel;
    sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
    console.log("Quotation called");
  }

  async updateStepNo(stepNo: number) {
    const response = await this.remittanceService.getCartToUpdateStepNumber(stepNo).toPromise();
    this.activeCartModelStep = response.body;
    console.log("Update step no called", stepNo);
    this.showLoader = false;
    return response;
  }

  horizontalWizardStepperPrevious() {
    this.horizontalWizardStepper.previous();
  }

  step04Payment() {
    var paymentMode = this.paymentMode;
    var requestBody = {
      "bankAccountId": this.bankAccountId,
      "paymentMode": paymentMode,
      "orderId": this.stepperDataModel.orderDetails.orderId,
    };
    this.remittanceService.placeOrder(requestBody).subscribe(respPayment => {
      var respPaymentBody = JSON.parse(JSON.stringify(respPayment.body));
      if (paymentMode == PaymentMode.NET_BANKING) {
        this.getPaymentGatewayLink(respPaymentBody.orderId);
      } else {
        this.router.navigate(['/payment-success', respPaymentBody.orderId]);
      }
    }, error => {
      console.log(error.error);
    })
  }

  async mapPayerToCart() {
    let requestCartMapPayer = new RequestCartMapPayer();

    requestCartMapPayer.remitterType = this.selectedPurpose.remittanceType;
    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPan = this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;

    requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    if (this.selectedPurpose.remittanceType != "OTHERS") {
      requestCartMapPayer.travellerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    }

    requestCartMapPayer.payerName = this.stepperDataModel.step02Data.selfResPaxItemSingle.name;
    requestCartMapPayer.payerPaxId = this.stepperDataModel.step02Data.selfResPaxItemSingle.id;
    requestCartMapPayer.orderId = this.stepperDataModel.orderDetails.orderId;

    // Map requestCartMapPayerJson = requestCartMapPayer.toJson();
    if (this.selectedPurpose.remittanceType == "OTHERS") {
      // requestCartMapPayer.remove('paidBy');
      // requestCartMapPayer.remove('paxId');
    }

    await this.remittanceService.orderPayerMap(requestCartMapPayer).toPromise();
  }

  getQuotationRequestBody() {
    this.requestQuotationModel = this.quotationRequest;

    if (this.currentStep == 'STUDENT_STEP2') {
      this.requestQuotationModel.pan = this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;
      this.requestQuotationModel.tcsPaymentSource = this.selectedSourceofFund.toUpperCase();
      if (this.selectedSourceofFund == 'Loan') {
        this.requestQuotationModel.tcsPaymentSourceDetails = (this.selectedInstitution != 'Other') ? this.selectedInstitution : this.instituteName;
      } else {
        this.requestQuotationModel.tcsPaymentSourceDetails = '';
      }
      this.requestQuotationModel.paymentSourceDetails = this.selectedSourceofFund;
      this.requestQuotationModel.taxResidentOfIndia = true;
      // this.requestQuotationModel.otherSourcePaymentDetails = '';
    }

    if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS" &&
      this.stepperDataModel?.step02Data?.selfResPaxItemSingle?.pan != null) {
      this.requestQuotationModel.calculateTcs = true;
      this.requestQuotationModel.tcsPaymentSource = "OWN";
      this.requestQuotationModel.taxResidentOfIndia = true;
      this.requestQuotationModel.pan =
        this.stepperDataModel.step02Data.selfResPaxItemSingle.pan;
      this.requestQuotationModel.paymentSourceDetails = "";
    }
  }

  fileUploadCartDocument(event, docType) {
    this.showLoader = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log("File >>>");
      console.log(file);
      if (
        file.type.indexOf("pdf") >= 0 ||
        file.type.indexOf("image") >= 0
      ) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('ownerType', "ORDER");
        formData.append('type', docType);
        formData.append('description', "");
        formData.append('ownerId', this.stepperDataModel.orderDetails.orderId);
        formData.append('documentPurpose', "TRANSACTION");

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.remittanceService.uploadOrderDocument(formData).subscribe((respDoc) => {
            this.showLoader = false;
            if (docType != "LOAN_DOCUMENT") {
              this.getDocumentList();
            }

          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    }
  }

  async getDocumentList() {
    this.remittanceService.getOrderDocuments(this.stepperDataModel.orderDetails.orderId).subscribe((resp => {
      this.documentListResponse = resp.body;
      this.transactionDocListAwaiting = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.AWAITING;
      this.transactionDocListUploaded = this.documentListResponse.documentCategorywiseUploadDetails.TRANSACTION.statusWiseDocuments.UPLOADED;

      if (this.documentListResponse.skipDocumentUpload == true) {
        this.navigateToFourthStep = true;
      }
    }), error => {
      this.toast.error(error.error.message);
    });
  }

  showToast() {
    this.toast.error("Please upload mandatory document");
  }

  openModalForTransactionDocumentView(transactionDocumentViewModal, documentDetails) {
    this.modalReference = this.modalService.open(transactionDocumentViewModal, { centered: true });
    this.documentUrl = documentDetails.location;
    this.documentTitle = documentDetails.documentType;
  }

  getSelectedBankAccountID(id) {
    this.bankAccountId = id;
  }

  getPaymentGatewayLink(orderId: string) {
    this.remittanceService.getPaymentGatewayLink(orderId).subscribe(respPaymentLink => {
      var responsePaymentLinkBody = JSON.parse(JSON.stringify(respPaymentLink.body));
      console.log("responsePaymentLinkBody >>>", responsePaymentLinkBody.payment_link);
    }, error => {
      console.log("Error in generating payment link");
    });
  }
}