import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { OfferRewardsService } from './services/offer-rewards.service';
import { OfferComponent } from './view/offer/offer.component';
import { RedeemVoucherComponent } from './view/redeem-voucher/redeem-voucher.component';
import { ReferAndEarnComponent } from './view/referAndEarn/referAndEarn.component';
import { RewardsComponent } from './view/rewards/rewards.component';

const routes = [
  {
    path: 'offer-coupons',
    component: OfferComponent,
    data: { animation: 'OfferComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'rewards-credit-points',
    component: RewardsComponent,
    data: { animation: 'RewardsComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'refer-and-earn',
    component: ReferAndEarnComponent,
    data: { animation: 'ReferAndEarnComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'redeem-voucher',
    component: RedeemVoucherComponent,
    data: { animation: 'RedeemVoucherComponent' },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    OfferComponent,
    RewardsComponent,
    ReferAndEarnComponent,
    RedeemVoucherComponent
  ],
  imports: [RouterModule.forChild(routes),
    CoreCommonModule,
    CardSnippetModule,
    CoreDirectivesModule,
    LoaderRoutingModule,
    NgSelectModule,
    NgbModule,
  ],
  exports: [
    OfferComponent,
    RewardsComponent,
    ReferAndEarnComponent,
    RedeemVoucherComponent

  ],
  providers: [
    OfferRewardsService
  ]
})
export class OfferRewardsRoutingModule { }
