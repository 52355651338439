<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section>
            <div class="row">
                <div class="col-md-9 mb-2 mb-md-0">
                    <div class="d-flex align-items-center">
                        <div class="avatar avatar-tag bg-light-primary mr-1">
                            <i data-feather="credit-card" class="font-medium-4"></i>
                        </div>
                        <div>
                            <h5 class="mb-0"> Cart </h5>
                            <span> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-20">
                <div class="col-md-12">
                    <div class="card-body grey-box mb-20 cross-button ">
                        <button id="x" (click)="removeItemFromCart()">
                            X
                        </button>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="fw-600">{{plans?.cart[0].companyName}}</div>
                                <div class="fs-10 mt-5">{{plans?.cart[0].planName}} </div>
                                <div class="mt-20">
                                    <div class="custom-control custom-control-primary custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="colorCheck1" checked disabled />
                                        <label class="custom-control-label fs-11" for="colorCheck1">I agree to the <a [href]="tncUrl" target="_blank" class="blue-link">Terms and Conditions</a> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <input type="text" placeholder="Sum Assured" class="form-control fs-12" (keypress)="keyPressNumbers($event)" value={{plans?.cart[0].sumInsuranceAmount}}>
                            </div>
                            <div class="col-md-4 text-right">
                                <div class="blue-link fs-18 pl-0 fw-600 mt-5 float-right"> ₹ {{ plans?.cart[0].premiumAmount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-right">
                    <!-- <button type="button" class="btn btn-outline-secondary blue-link fw-600 fs-11" rippleEffect>
                       Add Passenger
                </button> &nbsp; -->
                    <button type="button" class="btn btn-primary  fs-11" rippleEffect (click)="proceedForPayment()">
                         Proceed for Payment
                </button>
                </div>
            </div>
        </section>
    </div>
</div>