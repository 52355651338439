<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal" (ngSubmit)="addServiceRequest()">
                    <div class="row mt-10">
                        <div class="col-md-6 offset-md-3">
                            <div class="form-group">
                                <label for="disabledInput">Subject*</label>
                                <ng-select class="fs-12" [items]="serviceRequestSubjectListItems" bindLabel="serviceRequestSubjectListItems" bindValue="serviceRequestSubjectListItems" [(ngModel)]="addServiceRequestModel.subject" placeholder="Select" name="servicerequest_list" required
                                    [clearable]="false" [searchable]="true"> </ng-select>
                            </div>
                        </div>

                        <div class="col-md-6 offset-md-3">
                            <label for="basicTextarea">Comments*</label>
                            <fieldset class="form-group">
                                <textarea class="form-control fs-12" name="basicTextarea" rows="5" style="border-color: black;" [(ngModel)]="addServiceRequestModel.comment" (keypress)="alphaNumberOnly($event)" (paste)="onPaste($event)"></textarea>
                            </fieldset>
                        </div>
                    </div>
                    <div class=" row">
                        <div class="col-md-3"></div>
                        <div class="col-md-3 col-3 pl-0">
                            <button type="button" class="btn btn-primary mt-2 mr-1 fs-11" routerLink="/listServiceRequest">Back</button>
                        </div>
                        <div class="col-md-3 col-9 text-right pr-0">
                            <button type="submit" class="btn btn-primary mt-2 mr-1 fs-11">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>