import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'service-request-details',
  templateUrl: './service-request-details.component.html',
})
export class  ServiceRequestDetailsComponent implements OnInit {
  public ticketDetail  :any;
  public ticketDetails  :any;
  public showLoader : boolean = false;
  public ticketID  : any;
  public chatMessage = '';
  public messageDetails : any;
  @ViewChild('scrollMe') scrollMe: ElementRef;
  scrolltop: number = null;

  constructor(
    public supportService : SupportService,
    public toast : ToastrService,
    public route  : ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.ticketDetails = params;
    });
    this.getSingleTicketDetails(this.ticketDetails.id);
  }

  getSingleTicketDetails(ticketID){
    this.showLoader = true;
    this.supportService.getSingleConsumerTicketDetails(ticketID).subscribe((resp => {
      this.ticketDetail = resp.body;
      this.showLoader = false;
      console.log(JSON.stringify(this.ticketDetail));
      
    }), error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  sendMessage(){
    this.supportService.addConsumerTicket(this.chatMessage, this.ticketDetails.subject, this.ticketDetails.primaryTicketId).subscribe((resp=>{
      this.messageDetails = resp.body;
      this.chatMessage = '';
        setTimeout(() => {
          this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
        }, 0);
      this.getSingleTicketDetails(this.ticketDetails.id);
    }),error => {

    });
  }
 
}
