<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">

                <!-- Stepper Head Section -->
                <stepper-header-email></stepper-header-email>
                <!-- End Stepper Head Section -->

                <div class="bs-stepper-content">
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <loader *ngIf="showLoader"></loader>

                        <!-- First Step -->
                        <div id="beneficiary-details" class="content">
                            <form #accountDetailsForm="ngForm">
                                <section>
                                    <div class="row">
                                        <div class="col-md-12  mb-10">
                                            <h6 class="content-header-title float-left mb-0">
                                                {{ contentHeader }}
                                            </h6>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="fs-11">
                                                Fill in university details like university name, country, address, university bank details etc.
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-20">
                                            <div style="background: #b6b1b1;padding: 10px;border-radius: 5px;">
                                                <div class="row">
                                                    <div class="col-md-7 col-7">
                                                        <span>
                                                            <img class="flag-icon"
                                                                src="assets/images/currencyFlag/{{stepperDataModel.orderDetails.orderItems[0].currencyCode}}.png">
                                                        </span>
                                                        <span class="fw-600 text-black fs-10">
                                                            {{
                                                            stepperDataModel.orderDetails.orderItems[0].currencyCode
                                                            }}
                                                            {{
                                                            stepperDataModel.orderDetails.orderItems[0].currencyAmount
                                                            }}
                                                            =
                                                        </span>
                                                        <span><img src="assets/images/logo/india.png"
                                                                class="flag-icon"></span>
                                                        <span class="fw-600 text-black fs-10">
                                                            ₹ {{
                                                            stepperDataModel.orderDetails.orderItems[0].billableAmount
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="col-md-5 col-5 text-right">
                                                        <div class="fs-9 text-black fw-500 middle-text"> 1 {{stepperDataModel.orderDetails.orderItems[0].currencyCode}} = {{stepperDataModel.orderDetails.orderItems[0].bidRate}} INR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="pb-15" style="border-bottom: 1px solid #eee;">
                                    <div class="row" *ngIf="selectedPurpose.purposeCode === 'UNIVERSITY_FEES' || selectedPurpose.purposeCode === 'UNIVERSITY_FEES_OWN_ACCOUNT'">
                                        <div class="form-group col-md-6">
                                            <label class="form-label" for="country">Country</label>
                                            <ng-select name="countryListItems" [items]="countryListItems" bindLabel="countryName" bindValue="countryCode" [(ngModel)]="selectedCountry" (change)="getUniversityList(selectedCountry)" [clearable]="false" class="fs-12" placeholder="Select Country" [searchable]="false">
                                            </ng-select>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label class="form-label" for="country">University</label>
                                            <ng-select name="universityListItems" [items]="universityListItems" bindLabel="name" [(ngModel)]="selectedUniversity" addTagText="Add New University" [addTag]="addNewUniversity" (change)="navigateToAddBeneficiary(selectedUniversity.name, selectedCountry)"
                                                placeholder="Select University" class="fs-12" [clearable]="false" [searchable]="false">
                                            </ng-select>
                                        </div>
                                    </div>
                                </section>

                                <list-beneficiary-stepper-email (childCallbackSender)="parentCallbackReceiverForListBeneficiary($event)">
                                </list-beneficiary-stepper-email>
                                <stepper-footer-email></stepper-footer-email>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" class="btn btn-outline-dark btn-prev fs-12" rippleEffect routerLink="/dashboard">
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button> -->
                                    <button (click)="gotoDashboard()" type="submit" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button>
                                    <button (click)="step01CreateCartItems()" type="submit" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End First Step-->

                        <!-- Second Step -->
                        <div id="student-details" class="content">
                            <form #studentDetailsForm="ngForm">
                                <div class="row mb-20">
                                    <div class="col-md-12  mb-10">
                                        <h6 class="content-header-title
                                        float-left mb-0">
                                            {{ contentHeader }}
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="fs-11">
                                            Fill in remitter details and select source of funds.
                                        </div>
                                    </div>
                                </div>

                                <list-relationship-stepper-email (childCallbackRelationship)="parentCallbackForRelationship($event)">
                                </list-relationship-stepper-email>

                                <section class="grey-box-padding-30">
                                    <loader *ngIf="showLoader"></loader>
                                    <div class="invalid-state fs-10 text-danger mt-10 mb-20" *ngIf="selectedSourceofFund == 'Loan'">
                                        Note :Remittance will be sent from the LRS quota & TCS will be applied to the user (Self)
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label class="form-label" for="sourceofFund">Source of Fund</label>
                                            <ng-select [items]="sourceofFundItems" [(ngModel)]="selectedSourceofFund" (change)="getSelectedSourceFund(selectedSourceofFund)" name="sourceofFund" id="sourceofFund" #sourceofFundRef="ngModel" [class.error]="studentDetailsForm.submitted && sourceofFundRef.invalid"
                                                placeholder="Select Source of Fund" required [clearable]="false" class="fs-12" [searchable]="false">
                                            </ng-select>
                                            <span *ngIf="studentDetailsForm.submitted && sourceofFundRef.invalid" class="invalid-form">
                                                <small class="form-text text-danger fs-10"
                                                    *ngIf="sourceofFundRef.errors.required">
                                                    This field is required!
                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                    <section *ngIf="showFinancialOptions">
                                        <div class="row">
                                            <div class="form-group col-md-6 mt-20" *ngIf="showFinancialOptions">
                                                <label class="form-label">Financial Institution</label>
                                                <ng-select class="fs-12" [items]="financialInstitutionItem" [(ngModel)]="selectedInstitution" id="financialInstitution" name="financialInstitution" #financialInstitutionRef="ngModel" [class.error]="studentDetailsForm.submitted && financialInstitutionRef.invalid"
                                                    required [clearable]="false" placeholder="Select Financial Institution" [searchable]="false">
                                                </ng-select>
                                                <span *ngIf="studentDetailsForm.submitted && financialInstitutionRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger fs-10"
                                                        *ngIf="financialInstitutionRef.errors.required">This field is
                                                        required!</small>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6" *ngIf="selectedInstitution== 'Other'">
                                                <input type="text" class="form-control fs-12" placeholder="Name of the institute*" [(ngModel)]="instituteName" id="instituteName" name="instituteName" #instituteNameRef="ngModel" [class.error]="studentDetailsForm.submitted && instituteNameRef.invalid"
                                                    required />
                                                <span *ngIf="studentDetailsForm.submitted && instituteNameRef.invalid" class="invalid-form">
                                                    <small class="form-text text-danger fs-10"
                                                        *ngIf="instituteNameRef.errors.required">This field is
                                                        required!</small>
                                                </span>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group col-md-6 mt-20">
                                            <label class="form-label">Financial Institution Document</label>
                                            <div class="input-group mb-2">
                                                <input type="file" id="LOAN_DOCUMENT" placeholder="Financial Institution*" (change)="fileUploadCartDocument($event,'LOAN_DOCUMENT')" class="form-control fs-12">
                                                <div class="input-group-append" *ngIf="showTick">
                                                    <span class="input-group-text"><img
                                                            src="assets/images/icon/checkmark.gif"
                                                            style="width:20px;width:20px"></span>
                                                    <span class="input-group-text"><img
                                                            src="assets/images/icon/view-document.png"
                                                            style="width:20px;width:20px" ></span>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="row">
                                            <div class=" mt-20" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-9 ">
                                                <div class="form-group">
                                                    <label for="basicInputFile">Financial Institution Document</label>
                                                    <div class="custom-file">
                                                        <input type="file" (change)="fileUploadCartDocument($event,'LOAN_DOCUMENT')" class="form-control fs-12" id="customFile" placeholder="Financial Institution*" />
                                                        <label class="custom-file-label" for="customFile">Choose
                                                            file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 col-3  mt-23" *ngIf="showTick">
                                                <div class="input-group-append" style="height: 38px;">
                                                    <span class="input-group-text"><img
                                                            src="assets/images/icon/view-document.png"
                                                            style="width:20px;width:20px"
                                                            (click)="downloadUploadedLoanDoc()"></span>
                                                    <span class="input-group-text "><img
                                                            src="assets/images/icon/checkmark.gif"
                                                            class="w-h-20"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </section>

                                <div class="custom-control custom-checkbox mb-20 mt-20" *ngIf="selectedSourceofFund === 'Loan'">
                                    <input type="checkbox" name="checkboxconfirmation" class="custom-control-input" id="checkboxconfirmation" [(ngModel)]="isChecked" (change)="checkLoanConfirmationCheckboxvalue()" />
                                    <label class="custom-control-label text-black fs-11" for="checkboxconfirmation" style="margin-top: 2px;">
                                        I confirm that my loan is from a certified financial institution which is
                                        registered under section 80E
                                    </label>
                                </div>

                                <stepper-footer-email></stepper-footer-email>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button>
                                    <button (click)="step02StudentDetails(studentDetailsForm)" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Second Step -->

                        <!-- Third Step -->
                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <div class="row mb-20">
                                    <div class="col-md-12  mb-10">
                                        <h6 class="content-header-title
                                            float-left mb-0">
                                            {{ contentHeader }}
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="fs-11">Upload your transaction documents.
                                        </div>
                                    </div>
                                </div>
                                <loader *ngIf="showLoader"></loader>

                                <section *ngFor="let singleDocument of transactionDocListUploaded">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-7">
                                            <div class="form-group">
                                                <label for="basicInputFile">{{singleDocument.title}}</label>
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                    <label class="custom-file-label" for="customFile">Choose
                                                        file</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-5" *ngIf="singleDocument.status == 'UPLOADED' ">
                                            <div class="input-group-append mt-23" style="height: 38px;">
                                                <span class="input-group-text link" (click)="downloadUploadedDoc(singleDocument)"><img
                                                        src="assets/images/icon/view-document.png" class="w-h-20">
                                                </span>
                                                <span class="input-group-text link ml-7">
                                                    <img src="assets/images/icon/checkmark.gif" class="w-h-20">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section *ngFor="let singleDocument of transactionDocListAwaiting">
                                    <div class="row form-group" *ngIf="singleDocument.type != 'FORM_A2_DECLARATION_CUM_UNDERTAKING'">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="basicInputFile">{{ singleDocument.title }}</label>
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile" (change)="fileUploadCartDocument($event,singleDocument.type)" placeholder="{{singleDocument.title}}" />
                                                    <label class="custom-file-label" for="customFile">
                                                        Choose file
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <enhance-due-diligence-email *ngIf="showDueDeligenceForm"></enhance-due-diligence-email>

                                <stepper-footer-email></stepper-footer-email>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle
                                           ">Previous</span>
                                    </button>
                                    <button (click)="(navigateToFourthStep) ? step03GetDocumentProceed(kycForm) : showToast()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle
                                           ">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Third Step -->

                        <!-- Fourth Step -->
                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <loader *ngIf="showLoader"></loader>
                                <esign-email></esign-email>
                                <stepper-footer-email></stepper-footer-email>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button>
                                    <button (click)="step03EsignOrOtpValidate(kycselfdeclaration)" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Fourth Step -->

                        <!-- Fifth Step -->
                        <div id="payment-details" class="content">
                            <add-bank-account-for-remittance-email *ngIf="showAddBankForm" (childCallbackForAddBankAccountForm)="parentCallbackForAddBankAccountForm($event)">
                            </add-bank-account-for-remittance-email>
                            <form #paymentDetails="ngForm" *ngIf="!showAddBankForm">
                                <div class="row mb-20">
                                    <div class="col-md-12  mb-10">
                                        <h6 class="content-header-title float-left mb-0">
                                            {{ contentHeader }}
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="fs-11">
                                            Complete your payment successfully to proceed with your remittance request.
                                        </div>
                                    </div>
                                </div>

                                <loader *ngIf="showLoader"></loader>

                                <table class="table table-bordered">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Product</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">IBR</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Quantity</th>
                                            <th scope="col" class="text-center" style="padding-top: 10px">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" class="text-center" style="padding-top: 10px">
                                                {{ stepperDataModel.orderDetails.orderItems[0].product }}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                1 {{ stepperDataModel.orderDetails.orderItems[0].currencyCode}} = INR {{ stepperDataModel.orderDetails.orderItems[0].bidRate }}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                {{ stepperDataModel.orderDetails.orderItems[0].currencyCode}} {{ stepperDataModel.orderDetails.orderItems[0].currencyAmount}}
                                            </td>
                                            <td class="text-center" style="padding-top: 10px">
                                                ₹ {{ stepperDataModel.orderDetails.orderItems[0].billableAmount}}
                                            </td>
                                        </tr>
                                        <tr></tr>
                                    </tbody>
                                </table>

                                <hr class="dashed">

                                <div class="row mb-30">
                                    <div class="col-md-12 col-12">
                                        <div class="fs-14 text-black fw-600">
                                            Choose Your Bank Account
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 mb-20" *ngFor="let singleBank of bankAccountList; let i=index">
                                        <div [ngClass]="(bankAccountList.length - 1) == i ? 'border-bottom-white' : 'border-bottom-grey' ">
                                            <div class="row mb-10">
                                                <div class="col-md-11 col-10">
                                                    <input type="radio" name="radios" id="radio" (change)="getSelectedBankAccountID(singleBank.id)" />
                                                    <span class="card-title fs-12 text-black fw-600 mb-10">
                                                        {{ singleBank.bankName }}
                                                    </span>
                                                    <div class="fs-11 ml-15"> Account No : {{singleBank.number}}</div>
                                                    <div class="fs-11 ml-15"> IFSC Code : {{singleBank.ifscCode}}</div>
                                                    <div class="fs-11 ml-15"> Branch : {{singleBank.branch}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr class="dashed">

                                    <div class="col-md-4 col-12 mt-20">
                                        <button type="button" class="btn btn-primary btn-prev fs-12" rippleEffect (click)="showAddBankForm = true">
                                            Add Bank Account
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-30">
                                    <div class="col-md-12">
                                        <div class="fs-12 text-black fw-600 mb-20">
                                            Payment Method
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-30">
                                    <div class="col-md-2 col-12 fs-12 mt-10">
                                        <input type="radio" name="paymentRadio" id="radio" value='NB' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; Net Banking
                                    </div>
                                    <div class="col-md-3 col-12 fs-12 mt-10" *ngIf="ALLOW_NEFT_PAYMENT">
                                        <input type="radio" name="paymentRadio" id="radio" value='MANUAL' [(ngModel)]="paymentMode" /> &nbsp; &nbsp; NEFT/RTGS/IMPS
                                    </div>

                                    <div class="col-md-12 mt-30" *ngIf="paymentMode == 'MANUAL'">
                                        <div class="fs-12 fw-500  text-black">{{accountName}}
                                        </div>
                                        <div class="fs-11 text-black">{{bankName}}</div>
                                        <div class="fs-11 text-black">{{accountNo}}</div>
                                        <div class="fs-11 text-black">{{bankIFSC}}</div>
                                        <div class="fs-11 text-black">{{bankBranch}}</div>
                                    </div>

                                    <div class="col-md-12 fs-10 text-danger mt-30 mb-30 text-500" *ngIf="paymentMode == 'MANUAL'">
                                        Note: Please send an NEFT/RTGS/IMPS payment towards your order to the above account. The payment is to be transferred within 4 hours of order completion and the reference details of the transfer is to be updated in the My Order section.
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()" class="btn btn-outline-dark btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Previous</span>
                                    </button>
                                    <button class="btn btn-primary btn-gradient btn-submit fs-12" rippleEffect (click)="openModalForPaymentInfo(paymentInstructionModal)">
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!--End Fifth Step -->

                    </form>
                </div>
            </div>
        </section>
    </div>
</div>


<ng-template #transactionDocumentViewModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1">{{ documentTitle }}</h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <img src={{documentUrl}} style="height:400px;display:block;margin:auto;width:100%;object-fit: contain;">
        </div>
    </div>
</ng-template>

<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                {{paymentInstruction}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" (click)="step04Payment()" style="cursor: pointer;">
            OKAY
        </div>
    </div>
</ng-template>

<!-- CrimeaRegionDailog -->
<ng-template #crimeaRegionDailog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                Is this Beneficiary / transaction link to Embargoes sanctions country i.e. CRIMEA - REGION OF UKRAINE
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-6 fw-500 fs-12 text-center blue-text" (click)="openNoTransactionAllowedDailog()" style="cursor: pointer;">
                Yes
            </div>
            <div class="col-md-6 fw-500 fs-12 text-center blue-text" (click)="openDueDeligenceDailog()" style="cursor: pointer;">
                No
            </div>
        </div>
    </div>
</ng-template>


<!-- DueDeligenceDailog -->
<ng-template #dueDiligenceDialog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                Please note: Transaction will undergo compliance for extra due diligence
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" style="cursor: pointer;" (click)="agreeDueDiligence()">
            Agree
        </div>
    </div>
</ng-template>


<!-- NoTransactionsAllowedDailog -->
<ng-template let-modal #noTransactionAllowedDialog>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                No transaction allowed to this country
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
            <a>  OKAY  </a>
        </div>
    </div>
</ng-template>