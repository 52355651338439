import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { InsuranceService } from './services/insurance.service';
import { InsuranceCartItemComponent } from './view/insurance-cart-item/insurance-cart-item.component';
import { InsuranceDetailsComponent } from './view/insurance-details/insurance-details.component';
import { InsuranceListComponent } from './view/insurance-list/insurance-list.component';
import { InsurancePaymentComponent } from './view/insurance-payment/insurance-payment.component';
import { InsurancePlansComponent } from './view/insurance-plans/insurance-plans.component';
import { InsuranceComponent } from './view/insurance/insurance.component';
import { AddBankAccountForRemittanceComponent } from '../remittance/view/add-bank-account-for-remittance/add-bank-account-for-remittance.component';
import { RemittanceRoutingModule } from '../remittance/remittance-routing.module';
import { RemittanceService } from '../remittance/services/remittance.service';
import { InsuranceRetryPaymentComponent } from './view/retry-payment/insurance-retry-payment.component';

const routes = [
  {
    path: 'insurance-list',
    component: InsuranceListComponent,
    data: { animation: 'InsuranceListComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance-details/:insuranceId',
    component: InsuranceDetailsComponent,
    data: { animation: 'InsuranceDetailsComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance',
    component: InsuranceComponent,
    data: { animation: 'InsuranceComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance-plans',
    component: InsurancePlansComponent,
    data: { animation: 'InsurancePlansComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance-cart/:ordercartdetailsId',
    component: InsuranceCartItemComponent,
    data: { animation: 'InsuranceCartItemComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance-payment/:ordercartdetailsId',
    component: InsurancePaymentComponent,
    data: { animation: 'InsurancePaymentComponent' },
    canActivate : [AuthGuard]
  },
  {
    path: 'insurance-retry-payment/:ordercartdetailsId',
    component: InsuranceRetryPaymentComponent,
    data: { animation: 'InsuranceRetryPaymentComponent' },
    canActivate : [AuthGuard]
  }
];

@NgModule({
    declarations: [ 
      InsuranceComponent,
      InsurancePlansComponent,
      InsuranceCartItemComponent,
      InsurancePaymentComponent,
      InsuranceListComponent,
      InsuranceDetailsComponent,
      InsuranceRetryPaymentComponent
      // AddBankAccountForRemittanceComponent
    ],
    imports: [RouterModule.forChild(routes), 
      CoreCommonModule,
      CardSnippetModule,
      CoreDirectivesModule,
      LoaderRoutingModule,
      NgSelectModule,
      NgbModule,
      FormsModule,
      ContentHeaderModule, 
      CommonModule,
      RemittanceRoutingModule
    ],
    exports: [ 
      InsuranceComponent,
      InsurancePlansComponent,
      InsuranceCartItemComponent,
      InsuranceListComponent,
      InsuranceDetailsComponent,
      InsuranceRetryPaymentComponent
      // AddBankAccountForRemittanceComponent
    ],
    providers: [
      InsuranceService,
      RemittanceService
    ]
})
export class InsuranceRoutingModule {}
