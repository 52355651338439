<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row mb-20">
                    <div class="col-md-10">
                        <h5 class="content-header-title float-left mb-0">
                            Link your WSFx Smart Currency Card
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <section class="col-md-12 mt-20">
                        <div class="row">
                            <div class="col-md-12 mb-20">
                                <input type="radio" name="radios" id="radio" value='otpradio' [(ngModel)]="cardLinkType" (change)="getOTP()">
                                <span class="fs-13 ml5"> Link through your registered Indian Phone Number and OTP</span>
                            </div>
                        </div>
                        <section *ngIf="linkCardStep == 1">
                            <div class="row">
                                <div class="col-md-6 col-12 mt-10">
                                    <div class="form-group">
                                        <label for="">Mobile No</label>
                                        <input type="text" class="form-control fs-12" placeholder="Mobile No" [(ngModel)]="linkCardMobileRequest.mobileNumber" name="mobileno" readonly />
                                    </div>
                                </div>

                                <div class="col-md-6  col-12 mt-10">
                                    <div class="form-group">
                                        <label for="">OTP*</label>
                                        <input type="text" name="otp" class="form-control fs-12" placeholder="OTP" maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190" [(ngModel)]="linkCardMobileRequest.otp"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="cardLinkType=='otpradio'">
                                <div class="col-md-12 text-right col-3 mt-20">
                                    <button class="btn btn-primary  fs-12" tabindex="4" rippleEffect (click)="validateCustomerMobile()">Proceed</button>
                                </div>
                            </div>
                        </section>

                        <section *ngIf="linkCardStep == 2">
                            <div class="row">
                                <div class="col-md-6 col-12 mt-10">
                                    <div class="form-group">
                                        <label for="">Date of Birth*</label>
                                        <!-- <div class="input-group">
                                            <input class="form-control fs-11" placeholder="Select Date of Birth"
                                                (click)="startDatePicker.toggle()" name="Start Date Picker"
                                                [(ngModel)]="linkCardRequest02Model.dob" ngbDatepicker
                                                #startDatePicker="ngbDatepicker" readonly>
                                            <div class="input-group-append">
                                                <button
                                                    class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                                    (click)="startDatePicker.toggle()" type="button"
                                                    rippleEffect></button>
                                            </div>
                                        </div> -->
                                        <div class="input-group">
                                            <input class="form-control fs-11" (click)="basicDP.toggle()" readonly name="dob" style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate" [(ngModel)]="linkCardRequest02Model.dob" ngbDatepicker #basicDP="ngbDatepicker" required
                                                [minDate]="{year: 1900, month: 1, day: 1}">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6  col-12 mt-10">
                                    <div class="form-group">
                                        <label for="">Card Expiry*</label>
                                        <input type="text" class="form-control fs-12" name="cardExpiry" placeholder="mmyy" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190" required [(ngModel)]="linkCardRequest02Model.cardExpiry"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right col-3 mt-20">
                                    <button class="btn btn-primary  fs-12" tabindex="4" rippleEffect (click)="linkCardStep02()">Proceed</button>
                                </div>
                            </div>
                        </section>
                    </section>

                    <div class="col-md-12   text-center   mb-20 fs-14 text-black fw-600">
                        <div class="or-text"><span>OR</span></div>
                    </div>
                    <section class="col-md-12 mt-20">
                        <form class="form-horizontal" (ngSubmit)="getValidateCustomer(PANPassport.form.valid)" #PANPassport="ngForm">
                            <loader *ngIf="showLoader"></loader>
                            <div class="row">
                                <div class="col-md-12 mb-20">
                                    <input type="radio" name="radios" [(ngModel)]="cardLinkType" value='panpassradio' id="radio">
                                    <span class="fs-13 ml5"> Link through your PAN and Passport Number</span>
                                </div>
                            </div>
                            
                         <section *ngIf="dobPanPass == 1">
                            <div class="row">
                                <div class="col-md-6 col-12 mt-10">
                                    <div class="form-group">
                                        <label for="">PAN*</label>
                                        <input type="text" class="form-control fs-12" placeholder="PAN" name="pan" [class.error]="panRef.invalid" required [(ngModel)]="customerValidationRequestModel.panNo"  oninput="this.value = this.value.toUpperCase()"
                                            maxlength="10" name="PAN" #panRef="ngModel" [class.error]="PANPassport.submitted && panRef.invalid && cardLinkType=='panpassradio'" />
                                        <span *ngIf="PANPassport.submitted && panRef.invalid  && cardLinkType=='panpassradio'" class="invalid-form">
                                            <small class="text-danger" *ngIf="panRef.errors.required">This field is
                                                required!</small>
                                        </span>
                                        <div class="text-danger fs-11" *ngIf="PANPassport.submitted && panRef.invalid  && cardLinkType=='panpassradio'">
                                            PAN must be in valid format!</div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-12 mt-10 ">
                                    <div class="form-group ">
                                        <label for=" ">Passport Number*</label>
                                        <input type="text " class="form-control fs-12 " placeholder="Passport Number " [class.error]="PANPassport.submitted && passRef.invalid && cardLinkType=='panpassradio'" required [(ngModel)]="customerValidationRequestModel.passport" 
                                            #passRef="ngModel" name="passport" />
                                        <span *ngIf="PANPassport.submitted && passRef.invalid && cardLinkType=='panpassradio'" class="invalid-form">
                                            <small class="text-danger" *ngIf="passRef.errors.required">This field is
                                                required!</small>
                                        </span>
                                        <div class="text-danger fs-11" *ngIf="PANPassport.submitted && passRef.invalid  && cardLinkType=='panpassradio'">
                                            Passport Number must be in valid format!</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row " *ngIf="cardLinkType=='panpassradio'">
                                <div class="col-md-12 text-right col-3 mt-20 ">
                                    <button class="btn btn-primary fs-12 " tabindex="4 " rippleEffect>Proceed</button>
                                    <!-- <button class="btn btn-primary fs-12 " tabindex="4 " rippleEffect (click)="getValidateCustomer() ">Proceed</button> -->
                                </div>
                            </div>
                                                        </section>

                            <section *ngIf="dobPanPass == 2">
                                <div class="row">
                                    <div class="col-md-6 col-12 mt-10">
                                        <div class="form-group">
                                            <label for="">Date of Birth*</label>
                                            <!-- <div class="input-group">
                                                                        <input class="form-control fs-11" placeholder="Select Date of Birth"
                                                                            (click)="startDatePicker.toggle()" name="Start Date Picker"
                                                                            [(ngModel)]="linkCardRequest02Model.dob" ngbDatepicker
                                                                            #startDatePicker="ngbDatepicker" readonly>
                                                                        <div class="input-group-append">
                                                                            <button
                                                                                class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                                                                (click)="startDatePicker.toggle()" type="button"
                                                                                rippleEffect></button>
                                                                        </div>
                                                                    </div> -->
                                            <div class="input-group">
                                                <input class="form-control fs-11" (click)="basicDP.toggle()" readonly name="dob"
                                                    style="border-color:black" placeholder="Select Date of Birth" [maxDate]="dobMaxDate"
                                                    [(ngModel)]="linkCardRequest02Model.dob" ngbDatepicker #basicDP="ngbDatepicker" required
                                                    [minDate]="{year: 1900, month: 1, day: 1}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon"
                                                        (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div class="col-md-6  col-12 mt-10">
                                        <div class="form-group">
                                            <label for="">Card Expiry*</label>
                                            <input type="text" class="form-control fs-12" name="cardExpiry" placeholder="mmyy" maxlength="4"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190"
                                                required [(ngModel)]="linkCardRequest02Model.cardExpiry" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-right col-3 mt-20">
                                        <button class="btn btn-primary  fs-12" tabindex="4" rippleEffect (click)="linkCardStep02()">Proceed</button>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>