import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'sit-back-and-relax',
  templateUrl: './sit-back-and-relax.component.html' 
})
export class SitBackAndRelaxComponent implements OnInit {
  public orderId :any;
  public isFromReload: boolean = false;
  public isFromBuyNewCard: boolean = false;
  public isFromRemittance: boolean = false;
  public paymentMethod : any;
  public isFromRetryPayment : any;

  constructor(
    public router : Router,
    public route : ActivatedRoute,
    public orderService: OrderService,
    ) {
      this.route.params.subscribe((params) => {
        this.orderId = params['orderID'];
      });
      this.isFromReload =  JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RELOAD));
      this.isFromBuyNewCard =  JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_BUYNEWCARD));
      this.isFromRemittance = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW));
      this.isFromRetryPayment = JSON.parse(sessionStorage.getItem(ConstantsKey.IS_FROM_RETRY_PAYMENT));
      this.paymentMethod = sessionStorage.getItem(ConstantsKey.PAYMENT_METHOD);
      
      if (this.isFromReload == false || this.isFromReload == null) {
        this.isFromReload = false;
      } else {
        this.isFromReload = true;
      }
      console.log("isFromReload", this.isFromReload);
  
      if (this.isFromBuyNewCard == false || this.isFromReload == null) {
        this.isFromBuyNewCard = false;
      } else {
        this.isFromBuyNewCard = true;
      }
      console.log("isFromBuyNewCard", this.isFromBuyNewCard);
  
      if (this.isFromRemittance == false  || this.isFromReload == null) {
        this.isFromRemittance = false;
      } else {
        this.isFromRemittance = true;
      }

      console.log("isFromRemittance", this.isFromRemittance);
      
      if (this.isFromRetryPayment == false  || this.isFromRetryPayment == null) {
        this.isFromRetryPayment = false;
      } else {
        this.isFromRetryPayment = true;
      }
      console.log("isFromRetryPayment", this.isFromRetryPayment);
  }
  
  ngOnInit(): void {
    // this.route.params.subscribe((params) => {
    //   this.orderId = params['orderID'];
    // });
    this.getOrderDetails();
  }
  getOrderDetails() {
    this.orderService.getOrderDetailsSingle(this.orderId).subscribe(resp => {

    },
      error => {
        
      });


  }



  goToOrderDetails(){
    this.router.navigate(['/order-details', this.orderId]);
    sessionStorage.removeItem(ConstantsKey.IS_FROM_RELOAD);
    sessionStorage.removeItem(ConstantsKey.IS_FROM_BUYNEWCARD);
    sessionStorage.removeItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW);
    sessionStorage.removeItem(ConstantsKey.PAYMENT_METHOD);
    sessionStorage.removeItem(ConstantsKey.IS_FROM_RETRY_PAYMENT);
  }
}
