<style>
    .table {

        thead,
        tfoot {
            th {
                vertical-align: top;
                text-transform: none;
                font-size: 0.850rem;
                letter-spacing: 0.2px;
            }
        }

        th,
        td {
            padding: 0.6rem 1rem;
            vertical-align: middle;
        }

        &.table-sm {

            th,
            td {
                padding: 0.72rem 0.5rem;

                &:first-child {
                    padding-left: 0.75rem;
                }
            }
        }
    }

    .table-responsive {
        display: inline-table;
        width: 100%;
        overflow-x: auto;
    }
</style>

<div class="content-wrapper  container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-6 col-6 col-sm-12 col-xs-12 mb-20">
                        <div class="col-md-12">
                            <h5 class="content-header-title float-left mb-10 fs-13">
                                Encashment
                            </h5>
                        </div>
                        <div class="col-md-12 text-right">
                            <div (click)="downloadEncashmentForm()">
                                <a class="fs-10 fw-600 sky-blue-text">View encashment form </a>
                            </div>
                        </div>
                        <div class="col-md-12 mt-10">
                            <div class="custom-control custom-checkbox mb-10">
                                <input type="checkbox" style="color: black;" class="custom-control-input"
                                    id="encashmentCheckbox" [(ngModel)]="isChecked"
                                    (change)="otpRequestForEncashment(isChecked)" />
                                <label class="custom-control-label fs-10" for="encashmentCheckbox"> {{tncText}}
                                    <a class="link" target="_blank"
                                        href="https://b2capptnc.s3.ap-south-1.amazonaws.com/ForexCardT%26C.pdf"> <span
                                            class="blue-text"> terms and conditions, </span> </a>
                                </label>
                            </div>
                        </div>


                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 mt-20 fs-12 form-group">
                            <input type="text" class="form-control fs-12" placeholder="Enter OTP"
                                [(ngModel)]='otpForEncashment' />
                            <div class="d-flex justify-content-between mt-5">
                                <label></label>
                                <a (click)="otpRequestForEncashment(true)"><small class="fs-10">Resend OTP</small></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 col-6 col-sm-12 col-xs-12 mb-10 invoice-preview-wrapper">
                        <table class="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Currency</th>
                                    <th>FX Amount</th>
                                    <th>IBR</th>
                                    <th>INR Amount</th>
                                </tr>
                            </thead>
                            <tbody class="fs-10 text-black">
                                <tr *ngFor="let singleTransaction of quotationCurrencyResponsesList; let i = index">
                                    <td>
                                        {{singleTransaction?.product}}
                                    </td>
                                    <td>
                                        {{singleTransaction?.currencyCode}}
                                    </td>
                                    <td>
                                        {{singleTransaction?.currencyAmount}}
                                    </td>
                                    <td>
                                        {{singleTransaction.rateString | number :
                                        '1.2-2'}}
                                    </td>
                                    <td>
                                        {{singleTransaction.inrEquivalentAmountString}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-responsive mt-10 amout-breakup-bg">
                            <table class="table table-condensed table-borderless  fs-12">
                                <tbody>
                                    <!-- <tr style="border-top:1px solid #f4f4f4">
                                        <td colpan="2"></td>
                                    </tr> -->
                                    <tr>
                                        <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong>
                                        </td>
                                        <td class="thick-line text-right text-black fw-600 "> ₹ {{
                                            quotationDisplay?.billableAmount | number : '1.2-2' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.marginChargesString }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>Service Charges </strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.finalChargesString }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="no-line " colspan="3"><strong>GST</strong></td>
                                        <td class="no-line text-right">₹ - {{ quotationDisplay.gst | number : '1.2-2' }}
                                        </td>
                                    </tr>

                                    <tr style="border-top:1px solid #bdbcbc">
                                        <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                        <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{
                                            quotationDisplay.totalBillableAmount | number : '1.2-2' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-between mt-20">
                    <div class="col-11">
                        <button type="button" class="btn btn-primary btn-back fs-12" rippleEffect routerLink="/cashout">
                            <!-- <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i> -->
                            <span class="align-middle">Back</span>
                        </button>
                    </div>
                    <div class="col-1">
                        <button type="submit" class="btn btn-primary btn-next fs-12" (click)="validateOTP()"
                            rippleEffect>
                            <span class="align-middle">Next</span>
                            <!-- <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i> -->
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>