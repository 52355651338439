<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="app-ecommerce-details">
            <loader *ngIf="showLoader"></loader>
            <div class="card">
                <div class="card-body">
                    <div class="row my-2">
                        <div class="col-12 col-md-5 col-lg-4 col-xl-4 mb-2 mb-md-0">
                            <div class="d-flex">
                                <img src="assets/images/smartfx-home/Forex-Card.png" class="img-fluid product-img" alt="product image" />
                            </div>
                        </div>
                        <div class="col-12 col-md-7">
                            <h4 class="mb-10 text-black">Buy the WSFx Smart Currency Card</h4>

                            <span class="card-text item-company text-black">WSFx Smart Currency Card </span>

                            <p class="card-text mt-20 fs-12 text-black" *ngIf="!showMoreContents">
                                The WSFx Smart Currency Card (WSCC) helps you travel smart on your trip abroad.It is accepted globally at over 31.4 million VISA merchant outlets.
                            </p>
                            <p class="card-text mt-20 fs-12 text-black " *ngIf="showMoreContents">
                                The WSFx Smart Currency Card (WSCC) helps you travel smart on your trip abroad.It is accepted globally at over 31.4 million VISA merchant outlets. This prepaid multicurrency forex card is app-enabled, and comes with 14 key international currencies, namely
                                USD, EUR, AED, AUD, GBP, CAD, SAR, SGD, JPY, THB, CHF, ZAR, NZD, and HKD. The card also comes along with an INR wallet, which can be utilized to transfer your leftover currency at the end of your trip, thus enabling you
                                to spend effortlessly on domestic purchases.
                            </p>

                            <ul class="product-features list-unstyled fs-12" *ngIf="showMoreContents">
                                <li>
                                    <i data-feather="smartphone"></i>
                                    <span> Manage your card on the WSFx Global Pay App</span>
                                </li>
                                <li>
                                    <i data-feather="repeat"></i>
                                    <span> Quick wallet to wallet transfer</span>
                                </li>
                                <li>
                                    <i data-feather="rotate-cw"></i>
                                    <span> Easy reloads and encashments</span>
                                </li>
                                <li>
                                    <i data-feather="credit-card"></i>
                                    <span> Use INR wallet domestically</span>
                                </li>
                                <li>
                                    <i data-feather="clock"></i>
                                    <span> 24/7 access to your funds</span>
                                </li>
                                <li>
                                    <i data-feather="map-pin"></i>
                                    <span> Door to door delivery of new cards</span>
                                </li>
                            </ul>
                            <div class="link fs-11 blue-text" (click)="buttonText == 'View More' ? showContents() : hideContents()">{{buttonText}}</div>

                            <hr />

                            <div class="row d-flex flex-column flex-sm-row pt-1">
                                <div class="col-md-4 col-12 mb-1 mt-20">
                                    <ng-select class="fs-11" [clearable]="false" [items]="currencyItems" [(ngModel)]="currencySelected" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency" change='getCurrencyFields()' [searchable]="false">
                                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                            <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="col-md-4 col-12   mt-20">
                                    <!-- <input type="number" class="form-control fs-11" placeholder="Amount*" aria-label="Amount" [(ngModel)]="currencyAmount" name="currencyAmount" (focus)="showHidePricingRange(false)" (focusout)="showHidePricingRange(true)" /> -->
                                    <input type="number" class="form-control fs-11" placeholder="Amount*" aria-label="Amount" style="border-color: black;" [(ngModel)]="currencyAmount" name="currencyAmount" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101  && event.keyCode !== 187 && event.keyCode !== 190"
                                    />
                                </div>
                                <div class="col-md-4 mt-20">
                                    <button type="submit" class="btn btn-primary btn-next fs-11" rippleEffect (click)="callQuotation()">
                                         Add to Cart 
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-10" *ngIf="showPricing && quotationResponse?.quotationCurrencyResponses[0].rateString !=null">
                                <div class="col-md-5 col-12">
                                    <div class="fs-12 ">
                                        1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}}
                                    </div>
                                </div>
                                <!-- <div class="col-md-5 col-8 pl-0 text-right">
                                    <div class="fs-12 ">
                                        ₹ {{quotationResponse?.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-md-12 mt-30" *ngIf="showPricing && quotationResponse?.quotationCurrencyResponses[0].rateString !=null">
                            <div class="table-responsive mt-15">
                                <table class="table table-condensed table-borderless  fs-12">
                                    <tbody>
                                        <tr style="border-top:1px solid #f4f4f4">
                                            <td colpan="2"></td>
                                        </tr>
                                        <tr>
                                            <td class="thick-line text-black fw-600" colspan="3"><strong>Amount</strong></td>
                                            <td class="thick-line text-right text-black fw-600 "> ₹ {{quotationResponse?.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="no-line " colspan="3"><strong>Forex Margin</strong></td>
                                            <td class="no-line text-right">₹ {{ quotationResponse?.marginChargesString }} </td>
                                        </tr>
                                        <tr>
                                            <td class="no-line " colspan="3"><strong>Service Charges</strong></td>
                                            <td class="no-line text-right">₹ {{ quotationResponse?.finalChargesString }} </td>
                                        </tr>
                                        <tr>
                                            <td class="no-line " colspan="3"><strong>GST</strong></td>
                                            <td class="no-line text-right">₹ {{ quotationResponse?.gstString }} </td>
                                        </tr>

                                        <tr style="border-top:1px solid #f4f4f4">
                                            <td class="no-line text-black fw-800 pt-10" colspan="3"> Total Payable </td>
                                            <td class="no-line text-right text-black fw-800 pt-10"> ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="d-flex justify-content-between mt-20">
                                <button type="button" class="btn btn-outline-dark btn-prev fs-12" (click)="gotoDashboard()">
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle ">Back</span>
                                </button>
                                <button type="submit" class="btn btn-primary btn-next fs-12" (click)="proceedToBuySelectedCard()" rippleEffect>
                                    <span class="align-middle ">Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- <div class="content-wrapper container-xxl p-0">
    <div class="content-body card-body card">
        <div class="content-header">
            <h5 class="mb-0 text-black fw-700 fs-14">Buy the WSFx Smart Currency Card</h5>
        </div>
        <div class="row mt-30">
            <div class="col-md-2 mb-20">
                <img src="assets/images/card/wsfxCard.png" style="width:100%">
            </div>
            <div class="col-md-10">
                <div class="fs-13 text-black fw-600">WSFx Smart Currency Card</div>

                <div class="mt-20 fs-12 mb-20 text-black">The WSFx Smart Currency Card (WSCC) helps you travel smart on your trip abroad. It is accepted globally at over 31.4 million VISA merchant outlets. This prepaid multicurrency forex card is app-enabled, and comes with 14 key international
                    currencies, namely USD, EUR, AED, AUD, GBP, CAD, SAR, SGD, JPY, THB, CHF, ZAR, NZD, and HKD. The card also comes along with an INR wallet, which can be utilized to transfer your leftover currency at the end of your trip, thus enabling
                    you to spend effortlessly on domestic purchases.</div>

                <ul class="fs-12 text-black">
                    <li>Manage your card on the WSFx Smart Fx App</li>
                    <li>Quick wallet to wallet transfer</li>
                    <li>Easy reloads and encashments</li>
                    <li>Use INR wallet domestically</li>
                    <li>24/7 access to your funds</li>
                    <li>Door to door delivery of new cards</li>
                </ul>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3 col-4 mb-1 mt-20">
                        <ng-select class="fs-12" [clearable]="false" [items]="currencyItems" [(ngModel)]="currencySelected" bindLabel="currencyCode" bindValue="currencyCode" placeholder="Select Currency" change='getCurrencyFields()'>
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                <img src="assets/images/currencyFlag/{{item.currencyCode}}.png" style="height:18px;width: 18px;"> {{item.currencyCode}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-md-4 col-8 pl-0 mt-20">
                        <input type="number" class="form-control fs-12" placeholder="Amount*" aria-label="Amount" [(ngModel)]="currencyAmount" name="currencyAmount" (change)="callQuotation()" (focus)="showHidePricingRange(false)" (focusout)="showHidePricingRange(true)" />
                    </div>
                </div>
                <div class="row" *ngIf="showPricing && quotationResponse?.quotationCurrencyResponses[0].rateString !=null">
                    <div class="col-md-3 col-4">
                        <div class="fs-12 ">
                                1 {{quotationResponse?.quotationCurrencyResponses[0].currencyCode}} = ₹ {{quotationResponse?.quotationCurrencyResponses[0].rateString}}
                        </div>
                    </div>
                    <div class="col-md-4 col-8 pl-0 ">
                        <div class="fs-12 ">
                            ₹ {{quotationResponse?.quotationCurrencyResponses[0].inrEquivalentAmountString}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-30 ">
            <div class="col-md-12 ">
                <div class="card-body bg-light-grey b-radius-5 ">
                    <div class="d-flex justify-content-between ">
                        <div class="fs-17 text-black fw-600 ">
                            ₹ {{ quotationResponse?.totalBillableAmountString | number }}
                            <div class="fs-11 text-muted ">Includes Taxes and Charges
                                <button rippleEffect class="tooltip-icon " ngbTooltip="Handling Charges: {{ quotationResponse?.finalChargesString }} + GST: {{ quotationResponse?.gstString }} ">
                                    <span [data-feather]=" 'info' " class=" 'ml-1' "></span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button type="submit " rippleEffect class="btn btn-primary fs-12 " (click)="proceedToBuySelectedCard() ">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->