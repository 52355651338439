<style>
    ul.nav-tabs.nav-fill.col-md-8.offset-md-2.nav {
        justify-content: space-evenly;
    }
    
    a#ngb-nav-0 {
        border-radius: 5px 5px 0px 0px !important;
        border: 0px !important
    }
</style>
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <loader *ngIf="showLoader">
            </loader>
            <div class="col-md-12 text-center mb-20">
                <div class="fs-10 fw-600 " style="color:#535353">** Rates are indicative and subject to change Fees.</div>
                <div class="fs-10 fw-600 ">* The below mentioned rates are pertiment to prepaid forex cards and outward remittances.</div>
                <div class="fs-10 fw-600 ">* Fees Service Charges and Government taxes will be applicable.</div>
            </div>
            <div class="col-md-2 col-6 " *ngFor="let singleRate of rateList">
                <div class="card">
                    <div class="card-body box-align align-m">
                        <img src="assets/images/flag/{{singleRate.currencyCode.toLowerCase()}}.png" style="height: 25px;display: block;margin: auto;">
                        <h4 class="fs-13 card-title currency-p text-black fw-500">{{singleRate.currencyCode}}</h4>
                        <span class="fs-10">Buy: <span class="text-black fs-11 fw-500">{{singleRate.rateSell}}</span></span>
                        <div class="fs-10">Sell: <span class="text-black fs-11 fw-500">{{singleRate.rateBuy}}</span></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>