<div class="content-wrapper container-xxl p-0 ">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 text-center mb-20">
                        <h6 class="content-header-title fs-18 mb-0 ml-10" style="color: black">Open Blocked Account</h6>
                        <div class="mb-0 ml-10 fs-13 mt-10" style="color: black">Blocked Account</div>
                    </div>

                    <div class="col-md-12 mb-20 text-center">
                        <img src="assets/images/smartfx-home/germany.png" style="height: 100px;width:100px;">
                    </div>

                    <div class="col-md-6 offset-md-3 text-center">
                        <div class="fs-11"><span class="fw-600">Blocked Account</span> is a <span class="fw-600">German Bank Account</span> where at least €11,208 must be deposited by students as a proof of finnacial.</div>
                        <div class="fs-11 mt-15">This money will be blocked by the bank till the time the student reaches Germany and will get €934 monthly for their living expenses.</div>
                    </div>

                    <div class="col-md-2 offset-md-5 mt-15">
                        <button class="btn btn-primary  btn-block fs-12 text-center" tabindex="4" rippleEffect (click)="openAccountForm('Blocked')">Apply Now</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>