import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
import { EncApiList } from "./enc_api_list";

@Injectable()
export class CryptoUtils {

    public apidDefault = 'api-od926q416295z936kw76v1g9no952064';
    public encApiList: EncApiList = new EncApiList();

    public reqEncrypt(inputText: string) {
        let encKey = this.getDynamicKey();
        var key = CryptoJS.enc.Utf8.parse(encKey);

        console.log("inputText >>", inputText);

        let rawData = inputText;
        var cipherText = CryptoJS.AES.encrypt(rawData,
            key,
            {
                keySize: 16,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString();

        console.log("cipherText >>", cipherText);
        return cipherText;
    }

    public resDecrypt(inputText:string) {
        let encKey = this.getDynamicKey();
        var key = CryptoJS.enc.Utf8.parse(encKey);

        var rawData = CryptoJS.enc.Base64.parse(inputText);

        var plainTextData = CryptoJS.AES.decrypt(
            { ciphertext: rawData },
            key,
            {
                keySize: 16,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
        return plainTextData.toString(CryptoJS.enc.Utf8);
    }

    public getDynamicKey() {
        let xAuthToken = localStorage.getItem('X-AUTH-TOKEN');
        if (xAuthToken) {
        } else {
            xAuthToken = this.apidDefault;
        }

        xAuthToken = xAuthToken.slice(5, 21);
        console.log(xAuthToken);
        return xAuthToken;
    }
}