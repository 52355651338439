<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#trip-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Trip Information</span>
                                <span class="bs-stepper-subtitle" style="color: black">Trip details</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#student-details">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Traveller</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Card's delivery date and address</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">KYC</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Accept A2 Form</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#kyc-self-declaration">
                        <button class="step-trigger">

                        </button>
                    </div>
                    <div class="step" data-target="#payment-details">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title" style="color: black">Payment</span>
                                <span class="bs-stepper-subtitle" style="color: black"> Complete your payment</span>
                            </span>
                        </button>
                    </div>
                </div>

                <div class="bs-stepper-content">
                    <loader *ngIf="showLoader"></loader>
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <!-- First Step -->
                        <div id="trip-info" class="content">
                            <form #tripInfoForm="ngForm">
                                <trip-information (childCallbackSender)="parentCallbackFormChild($event)">
                                </trip-information>
                            </form>

                            <div class="d-flex justify-content-between">
                                <!-- <button routerLink="/view-cart" class="btn
                                btn-outline-secondary btn-prev fs-12" type="button" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button> -->
                                <button *ngIf="!hideProceedButton" routerLink="/view-cart" type="submit"
                                    class="btn btn-primary btn-prev fs-12" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                <button *ngIf="!hideProceedButton" (click)="mapTripDetailsStep01()" type="submit"
                                    class="btn btn-primary btn-next fs-12" rippleEffect>
                                    <span class="align-middle">Next</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                        <!-- End First Step -->

                        <!-- Second Step -->
                        <div id="student-details" class="content">
                            <form #travellerInfoForm="ngForm">
                                <traveller-details (childCallbackSender)="parentCallbackFormChild($event)">
                                </traveller-details>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12"
                                        rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="mapPayerDetailsStep02()" class="btn btn-primary btn-next fs-12"
                                        rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Second Step -->

                        <!-- Third Step -->
                        <div id="kyc" class="content">
                            <form #kycForm="ngForm">
                                <document-upload></document-upload>
                                <enhance-due-diligence *ngIf="showDiligenceForm"></enhance-due-diligence>
                                <card-stepper-footer></card-stepper-footer>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button (click)="checkDocumentUplodStep03()" class="btn btn-primary btn-next fs-12"
                                        rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Third Step -->

                        <!-- Fourth Step -->
                        <div id="kyc-self-declaration" class="content">
                            <form #kycselfdeclaration="ngForm">
                                <a2-form (childCallbackSender)="parentCallbackFormChild($event)"></a2-form>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12"
                                        rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle">Back</span>
                                    </button>
                                    <button (click)="downloadA2FormStep04()" class="btn btn-primary btn-next fs-12"
                                        rippleEffect>
                                        <span class="align-middle">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- End Fourth Step -->

                        <!-- Fifth Step -->
                        <div id="payment-details" class="content">
                            <form #paymentDetails="ngForm">
                                <payment (childCallbackSender)="parentCallbackFormChild($event)"></payment>
                                <div class="d-flex justify-content-between">
                                    <!-- <button type="button" (click)="horizontalWizardStepperPrevious()"
                                        class="btn btn-outline-secondary  btn-prev fs-12" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0 "></i>
                                        <span class="align-middle">Back</span>
                                    </button> -->
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-next fs-12"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle">Back</span>
                                </button>
                                    <button class="btn btn-primary btn-gradient  fs-12" rippleEffect
                                        (click)="openModalForPaymentInfo(paymentInstructionModal)">Proceed</button>
                                </div>
                            </form>
                        </div>
                        <!--End Fifth Step -->

                    </form>
                </div>
            </div>
        </section>
    </div>
</div>

<section>
    <div id="pgContainer" style="visibility:hidden">
        <b> Please wait ! We are redirecting to the Payment Gateway. </b>
        <form id="ts-app-payment-form-redirect"></form>
    </div>
</section>


<ng-template #paymentInstructionModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                {{ paymentInstruction }}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" (click)="step04Payment()" style="cursor: pointer;">
            OKAY
        </div>
    </div>
</ng-template>