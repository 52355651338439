<section *ngIf="!showAddAddressForm">

    <div class="row mb-20">
        <div class="col-md-12">
            <h6 class="content-header-title float-left mb-10">
                Traveller Details
            </h6>
        </div>
        <div class="col-md-12 fs-11 text-black">
            Select your card's delivery date and address or opt to pick it up from a branch near you.
        </div>
    </div>

    <div class="row mb-20">
        <div class="col-md-5">
            <div class="fs-12 note-bg">
                <span class="text-black fw-600">Note: </span> TCS will be applied to the user(Self)
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3 text-right">
            <div class="green-box">{{ quotationResponse?.tcs?.tcsPercentageString }}% TCS is applicable.</div>
        </div>
    </div>

    <div class="row mt-20">
        <div class="form-group col-md-6">
            <label class="form-label" for="country">Source of funds*</label>
            <ng-select name="sourceOfFundsList" (change)="updateSessionDataAfterSelection()" [clearable]="false" [items]="sourceOfFundsList" [(ngModel)]="paymentSourceDetails" bindLabel="name" bindValue="id" placeholder="Select source of fund" class="fs-12" [searchable]="false">
            </ng-select>
        </div>
    </div>

    <div class="row mt-20">
        <div class="form-group col-md-6" *ngIf="(paymentSourceDetails != null && paymentSourceDetails.toUpperCase() == 'EDUCATION LOAN')">
            <label class="form-label">Select Institution</label>
            <ng-select placeholder="Select Institution" [items]="instituteList" [(ngModel)]="tcsPaymentSourceBankDetails" placeholder="Select Institution" class="fs-12" [clearable]="false" [searchable]="false"> </ng-select>
        </div>

        <div class="form-group col-md-6" *ngIf="(paymentSourceDetails != null && paymentSourceDetails.toUpperCase() == 'OTHER')">
            <label class="form-label" for="source_name">Source of funds name</label>
            <input type="text" class="form-control fs-12" [(ngModel)]="otherSourcePaymentDetails" placeholder="Source of funds name" />
        </div>
    </div>

    <div class="row" *ngIf="(paymentSourceDetails != null && paymentSourceDetails.toUpperCase() == 'EDUCATION LOAN')">
        <div class="mt-20" [ngClass]="{'col-lg-5 col-md-5 col-sm-5 col-xs-12 col-9 form-group ':showTick, 'col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12 form-group ':!showTick}">
            <label for="basicInputFile">Upload Loan Document*</label>
            <div class="custom-file">
                <input type="file" class="form-control fs-12" (change)="fileUploadLoanDocument($event)" id="customFile" placeholder="Financial Institution*" />
                <label class="custom-file-label" for="customFile">Choose file</label>
            </div>
        </div>
        <div class="col-md-1 col-3  mt-45" *ngIf="showTick">
            <div class="input-group-append" style="height: 38px;">
                <span class="input-group-text "><img src="assets/images/icon/checkmark.gif" class="w-h-20"></span>
                <span class="input-group-text"><img src="assets/images/icon/view-document.png"
                    style="width:20px;width:20px"></span>
            </div>
        </div>
    </div>

    <div class="invalid-state fs-11 text-black mt-10 note-bg" *ngIf="(paymentSourceDetails != null && paymentSourceDetails.toUpperCase() == 'EDUCATION LOAN')">
        * Please upload documents in either <span class="fw-600">JPEG/PNG/PDF</span>under <span class="fw-600">1MB</span>
    </div>

    <passport-data-form *ngIf="!passportUploaded"></passport-data-form>

    <hr class="hr-color">

    <section class="mb-20" *ngIf="cartType == 'CARD_PURCHASE'">
        <div class="row">
            <div class="col-md-12 mb-20">
                <div class="fs-12 text-black fw-600">Delivery/Pickup Location *</div>
            </div>
            <div class="col-md-5">
                <input type="radio" name="radios" id="radio" (click)="chooseAddressLocation('HOME')">
                <span class="fs-12"> &nbsp;&nbsp; Home Delivery</span>
            </div>
            <div class="col-md-5">
                <input type="radio" name="radios" id="radio" (click)="chooseAddressLocation('BRANCH')" />
                <span class="fs-12"> &nbsp;&nbsp; Pick up from WSFx Branch</span>
            </div>
        </div>

        <div class="row" *ngIf="addressType == 'HOME'">
            <div class="col-md-12 mt-20" *ngIf="noHomeAddressFound">No address found. Please add address</div>
            <div class="col-md-12 mt-20" *ngFor="let singleAddress of homeAddressList">
                <div class="dashed-border-1">
                    <div class="row">
                        <div class="col-md-1">
                            <input type="radio" name="selectHomeAddress" id="radio" (click)="selectDeliveryHomeAddress(singleAddress)" />
                        </div>
                        <div class="col-md-11">
                            <div class="fs-13 text-black fw-600">{{ singleAddress.locationType }}</div>
                            <div class="fs-12 text-black20">{{ singleAddress.address }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="addressType == 'BRANCH'">
            <div class="col-md-12 mt-10" *ngFor="let singleAddressBranch of branchAddressList">
                <div class="dashed-border-1">
                    <div class="row">
                        <div class="col-md-1">
                            <input type="radio" name="selectBranchAddress" id="selectBranchAddress" (click)="selectDeliveryOfficeAddress(singleAddressBranch)" />
                        </div>
                        <div class="col-md-11">
                            <div class="fs-13 text-black fw-600">{{ singleAddressBranch.branchName }}</div>
                            <div class="fs-12 text-black20">{{ singleAddressBranch.address }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br><br>
        <div class="row">
            <div class="col-md-6 form-group">
                <label class="form-label">Delivery Date*</label>
                <div class="input-group">
                    <input class="form-control fs-12" placeholder="Select Delivery Date" name="scheduledDate" [(ngModel)]="scheduledDate" ngbDatepicker #basicDP="ngbDatepicker" (ngModelChange)="updateSessionDate()">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="basicDP.toggle()" type="button" rippleEffect></button>
                    </div>
                </div>
            </div>

            <div class="form-group col-md-6">
                <label class="form-label ">Select Time Slot *</label>
                <ng-select name="scheduledTimeList" [items]="scheduledTimeList" [(ngModel)]="scheduledTime" placeholder="Select Time Slot" class="fs-12" [clearable]="false" (change)="updateSessionDataAfterSelection()" [searchable]="false">
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right mt-20">
                <div class="fs-12 blue-text link" (click)="showAddAddressForm = true">Add Address</div>
            </div>
        </div>
    </section>
    <card-stepper-footer></card-stepper-footer>
</section>

<add-address-card *ngIf="showAddAddressForm" (childCallbackForAddAddressForm)="parentCallbackForAddAddressForm($event)" class="mb-20"></add-address-card>