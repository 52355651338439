import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { DirectSignupComponent } from './view/direct-signup.component';

const routes = [
  {
    path: 'direct-signup/:data',
    component: DirectSignupComponent,
  }
];

@NgModule({
  declarations: [DirectSignupComponent],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    LoaderRoutingModule
  ],
  exports: [DirectSignupComponent],
  providers: [MasterDataService],
})

export class DirectSignupRoutingModule { }
