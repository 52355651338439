import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestQuotationModel } from 'app/modules/dashboard/models/quotation.models';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { BaseProvider } from 'app/shared/helper/http/BaseProvider';

@Injectable()
export class RemittanceService extends BaseProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  ///STEP 1: get quotation
  cartPrepare(cartType: string) {
    var resourceUrl = "cartPrepare?cartType=" + cartType;
    return this.makePostCall(resourceUrl, {});
  }

  getQuotation(requestQuotationModel: RequestQuotationModel) {
    var resourceUrl = "quotationsV2";
    return this.makePostCall(resourceUrl, requestQuotationModel);
  }
  getQuotationEncrypted(requestQuotationModel: RequestQuotationModel) {
    var resourceUrl = "b2c/quotationsV2";
    return this.makePostCall(resourceUrl, requestQuotationModel);
  }
  
  cartItemPost(request) {
    var resourceUrl = "cartItem";
    return this.makePutCall(resourceUrl, request);
  }

  mapBeneficiary(request) {
    var resourceUrl = 'cartBenefeciary';
    return this.makePutCall(resourceUrl, request);
  }

  ///Update cart on every steps
  getCartToUpdateStepNumber(step: number) {
    var resourceUrl = 'cart';
    if (step == 4) {
      resourceUrl = 'cart?stepToComplete=' + step.toString();
    }
    return this.makeGetCall(resourceUrl);
  }

  getLoanProviderList() {
    var resourceUrl = 'tcsLoanProvider';
    return this.makeGetCall(resourceUrl);
  }

  getBankAccountList() {
    var consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    var resourceUrl = 'bankAccount?consumerId=' + consumerId;
    return this.makeGetCall(resourceUrl);
  }

  uploadDocument(imageData) {
    return this.makeUploadCall("cartDocument", imageData);
  }

  cartPlaceOrder(bankAccountId: string, paymentMode: string) {
    var resourceUrl = "cartPlaceOrder?bankAccountId=" + bankAccountId + "&paymentMode=" + paymentMode;
    if (paymentMode=='MANUAL') {
      resourceUrl = "cartPlaceOrder?paymentMode=" + paymentMode;
    }
    return this.makePostCall(resourceUrl, {});
  }
 
  ///map payer
  cartPayerMap(request) {
    var resourceUrl = 'cartPayer';
    return this.makePutCall(resourceUrl, request);
  }

  getDocumentSigned(orderId: string, cartType: string) {
    var serviceURL = "getDocumentSigned/" + orderId + "?cartType=" + cartType;
    return this.makeGetCall(serviceURL);
  }

  getA2FormSignedDoc(orderDetails) {
    var resourceUrl = "aadharEsignDocumentDetails";
    return this.makePostCall(resourceUrl, orderDetails);
  }

  getA2Form(otp, request) {
    var resourceUrl = "cartA2?otp=" + otp;
    return this.makePutCall(resourceUrl, request);
  }

  ///Get document upload and pending
  getDocuments() {
    var resourceUrl = 'cartTotalVersusUploadedDocuments';
    return this.makeGetCall(resourceUrl);
  }

  getPaymentGatewayLink(refOrderId: string, isPartial : boolean,bankId: string) {
    var resourceUrl = "getPaymentGatewayLink?refOrderId=" + refOrderId + '&isPartial=' + isPartial+ '&bankId=' + bankId;
    return this.makeGetCall(resourceUrl);
  }

  // getPaymentGatewayLink(refOrderId: string) {
  //   var resourceUrl = "getPaymentGatewayLink?refOrderId=" + refOrderId;
  //   return this.makeGetCall(resourceUrl);
  // }

  getA2FormAcknowledge() {
    var resourceUrl = 'CartA2OtpInit';
    return this.makePostCall(resourceUrl, {});
  }

  getA2FormCart(a2AcceptanceRequest) {
    var resourceUrl = "cartA2"
    return this.makePutCall(resourceUrl, a2AcceptanceRequest);
  }

  uploadA2FormSignature(refOrderNo, formdata) {
    var resourceUrl = "a2FormSignature?refOrderNo=" + refOrderNo;
    return this.makeUploadCall(resourceUrl, formdata);
  }

  //Call GetCartCountriesSanctionsStatus after country code selected on first step 
  getCartCountriesSanctionsStatus(countryCode) {
    var resourceUrl = "getCartCountriesSanctionsStatus?countryCode=" + countryCode;
    return this.makeGetCall(resourceUrl);
  }

  getDueDelligenceStatus(countryCode) {
    var resourceUrl = 'getCartCountriesSanctionsStatus?countryCode=' + countryCode;
    return this.makeGetCall(resourceUrl);
  }

  postDueDelligenceData(request) {
    var resourceUrl = 'fatfEdd';
    return this.makePostCall(resourceUrl, request);
  }

  getRedeemableCredits() {
    var resourceUrl = 'redeemableCredits';
    return this.makeGetCall(resourceUrl);
  }

  getOffers() {
    var resourceUrl = 'promoCodeFetchActive/CONSUMER';
    return this.makeGetCall(resourceUrl);
  }
}
