import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseProvider } from "app/shared/helper/http/BaseProvider";
import { RequestConsumerOtpSignUp } from "../models/signup.models";

@Injectable()
export class SignUpService extends BaseProvider {

    constructor(public http: HttpClient) {
        super(http);
    }

    signUpWithOtp(requestConsumerOtpSignUp: RequestConsumerOtpSignUp) {
        var resourceUrl = "otpSignup";
        return this.makePostCall(resourceUrl, requestConsumerOtpSignUp);
    }

    getMobileOTPSignUp(mobileNo,email){
        var resourceUrl = 'otpForMobileSignup?mobileNo=' + mobileNo + '&email=' + email;
        return this.makeGetCall(resourceUrl);
    }
}
