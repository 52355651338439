<div class="row mb-20">
    <div class="col-md-12">
        <h6 class="content-header-title float-left mb-10">
            Trip Information
        </h6>
    </div>
    <div class="col-md-12 fs-11">
        Fill in details of your trip and purpose of your travel here.
    </div>
</div>
<div class="row">
    <div class="col-md-6 form-group">
        <label class="form-label">Departure*</label>
        <div class="input-group">
            <input class="form-control fs-12" placeholder="Select Departure Date" (click)="departure.toggle()" readonly name="departure_date" [(ngModel)]="departure_date" (ngModelChange)="updateStep01Data('DEPARTURE_DATE')" ngbDatepicker #departure="ngbDatepicker"
                [minDate]="tripMinDepartureDate" [maxDate]="tripMaxDepartureDate">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="departure.toggle()" type="button" rippleEffect></button>
            </div>
        </div>
    </div>
    <div class="col-md-6 form-group">
        <label class="form-label">Return*</label>
        <div class="input-group">
            <input class="form-control fs-12" placeholder="Select Return Date" name="return_date" [(ngModel)]="return_date" (click)="returnDP.toggle()" readonly (ngModelChange)="updateStep01Data('RETURN_DATE')" ngbDatepicker #returnDP="ngbDatepicker" [minDate]="tripMinReturnDate">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary icomoon icon-calendar_date_day_event_month_icon" (click)="returnDP.toggle()" type="button" rippleEffect></button>
            </div>
        </div>
    </div>
    <div class="form-group col-md-6">
        <label class="form-label" for="country">Destination Country*</label>
        <ng-select [clearable]="false" class="fs-12" name="countryListItems" [clearable]="false" [items]="countryListItems" (change)="updateStep01Data('COUNTRY')" bindLabel="countryName" [(ngModel)]="selectedCountry" bindValue="countryCode" [(ngModel)]="tripInfoRequestModel.travellingCountryCode"
            placeholder="Select Destination Country*" [searchable]="false">
        </ng-select>
    </div>
</div>
<div class="row">
    <div class="form-group col-md-6">
        <label class="form-label" for="country">Travel Type*</label>
        <input type="text" class="form-control fs-12" placeholder="Enter Travel Type" value="Private Visit" readonly />
    </div>
    <div class="form-group col-md-6">
        <label class="form-label" for="country">Purpose of Travel *</label>
        <ng-select [clearable]="false" class="fs-12" name="singlePurpose" [clearable]="false" (change)="updateStep01Data('PURPOSE')" [(ngModel)]="tripInfoRequestModel.subPurpose" [items]="subCategoryListForexCard" bindLabel="purpseTitle" bindValue="purpseCode"
            placeholder="Select Purpose of Travel" [searchable]="false">
        </ng-select>
    </div>

</div>

<card-stepper-footer></card-stepper-footer>


<!-- CrimeaRegionDailog -->
<ng-template #crimeaRegionDailog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                Is this Beneficiary / transaction link to Embargoes sanctions country i.e. CRIMEA - REGION OF UKRAINE
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-6 fw-500 fs-12 text-center blue-text" (click)="openNoTransactionAllowedDailog()" style="cursor: pointer;">
                Yes
            </div>
            <div class="col-md-6 fw-500 fs-12 text-center blue-text" (click)="openDueDeligenceDailog()" style="cursor: pointer;">
                No
            </div>
        </div>
    </div>
</ng-template>


<!-- DueDeligenceDailog -->
<ng-template #dueDiligenceDialog let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                Please note: Transaction will undergo compliance for extra due diligence
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" style="cursor: pointer;" (click)="agreeDueDiligence()">
            Agree
        </div>
    </div>
</ng-template>


<!-- NoTransactionsAllowedDailog -->
<ng-template let-modal #noTransactionAllowedDialog>
    <div class="modal-header">
        <h6 class="modal-title" id="myModalLabel1"></h6>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col-md-12">
            <div class="text-center text-black fw-500 mt-10 mb-10 fs-12">
                No transaction allowed to this country
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 fw-500 fs-12 text-center blue-text" style="cursor: pointer;" (click)="modal.dismiss()">
            OK
        </div>
    </div>
</ng-template>