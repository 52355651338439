import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RelationshipService } from '../../services/relationship.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'list-relationship',
  templateUrl: './list-relationship.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ListRelationshipComponent implements OnInit {

  public relationshipMemberListItems: any;
  public showLoader : boolean = false;
  public paxesList : any;
  public singlePaxItem : any;

  constructor(
    public relationshipService: RelationshipService,
    private toast: ToastrService,
    public router: Router) {
    this.getRelationshipMemberList();
  }

  ngOnInit(): void {
  }

  getRelationshipMemberList() {
    this.showLoader = true;
    this.relationshipService.getRelationshipList().subscribe(resp => {
      this.relationshipMemberListItems = resp.body;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.message);
    });
  }

  deleteRelationshipItem(paxId) {
    this.showLoader  = true;
    this.relationshipService.deleteRelationship(paxId).subscribe((resp => {
      this.showLoader  = false;
      this.toast.success("Relation deleted successfully !");
      this.getRelationshipMemberList();
    }), error => {
      this.showLoader  = false;
      this.toast.error(error.error.message);
    });

  }

  editPax(paxID){
      this.relationshipService.getPaxesList().subscribe((resp) => {
      this.showLoader  = false;
      this.paxesList = resp.body;
      this.paxesList.paxResponses.forEach(element => {
        if(paxID == element.id)
        this.singlePaxItem = element;
      });
      this.singlePaxItem['paxID'] =  paxID;
      this.singlePaxItem['freezeField'] =  true;
      this.router.navigate(['/add-relationship'],{  queryParams: this.singlePaxItem });
    }, error =>{
      this.showLoader  = false;
      this.toast.error(error.error.message);
    });
  }
}
