export const environment = {
  production: false,
  hmr: false,
  //base_url_grid: 'https://api.wsfx.in/',
 // base_url: 'https://apimerged.wsfx.in/',
  api_key: 'api-od926q416295z936kw76v1g9no952064',

    
  base_url_grid: 'https://apiqa.wsfx.in/',
  base_url: 'https://apiqamerged2.wsfx.in/',

  //  base_url: "http://192.168.1.33:5000/",
  //  base_url_grid: "http://192.168.1.33:5000/",

  // base_url: "http://localhost:5000/",
  // base_url_grid:  "http://localhost:5000/",


};
