<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of menu">
        <!-- section -->
        <li core-menu-vertical-collapsible *ngIf="item.type == 'section'" [item]="item" class="dropdown nav-item"></li>

        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li core-menu-vertical-item *ngIf="item.type == 'item'" [item]="item" [ngClass]="{ disabled: item.disabled === true }" [routerLinkActive]="!item.openInNewTab ? 'active' : ''" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
            <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>

        <!-- collapsible -->
        <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible'" [item]="item" class="dropdown dropdown-submenu"></li>
    </ng-container>
</ng-container>
<!--/ Vertical Menu -->




<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal'">
    <ng-container *ngFor="let item of menu">
        <!-- section -->
        <li core-menu-horizontal-collapsible *ngIf="item.type == 'section'" [item]="item" class="dropdown nav-item"></li>

        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li core-menu-horizontal-item *ngIf="item.type == 'item'" [item]="item" [ngClass]="{ disabled: item.disabled === true }" [routerLinkActive]="!item.openInNewTab ? 'active' : ''" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
            <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>

        <!-- collapsible -->
        <li core-menu-horizontal-collapsible *ngIf="item.type == 'collapsible'" [item]="item" class="dropdown dropdown-submenu"></li>
    </ng-container>
    
</ng-container>
<!--/ Horizontal Menu -->