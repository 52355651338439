import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/shared/constant/constants';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { WsfxBankDetails } from 'app/shared/constant/wsfx-bank-details';
import { ToastrService } from 'ngx-toastr';
import { UpdatePaymentDetailsRequestModel } from '../../models/order.model';
import { OrderService } from '../../services/order.service';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { QuotationCurrencyItem, RequestQuotationModel} from 'app/modules/dashboard/models/quotation.models';
import { StepperDataModel } from 'app/modules/email-link/models/stepperdata.model';
import  moment from 'moment';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: [
    './order-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDetailsComponent implements OnInit {
  public orderRating: any;
  public orderID: any;
  public date: any;
  public time: any;
  public orderDetails: any;
  public showLoader: boolean = true;
  public paymentItemList = Constants.paymentItemList;
  public modalReference: any;
  public documentUrl: any;
  public documentTitle: any;
  public a2FormUrl: any;

  public startDate: NgbDateStruct;
  public readOnlyRating: boolean = true;
  public isSurrenderOrder: boolean = false;

  public manualPaymentType: any;
  public accountName = WsfxBankDetails.ACCOUNT_NAME;
  public bankName = WsfxBankDetails.BANK_NAME;
  public accountNo = WsfxBankDetails.ACCOUNT_NUMBER;
  public bankIFSC = WsfxBankDetails.BANK_IFSC;
  public bankBranch = WsfxBankDetails.BANK_BRANCH;
  public selectedPurposeItem: any;
  public paymentDate: any;
  public transactionPaymentDate : any;
  public docType: any;
  public transactionProof: any;

  public updatePaymentDetailsRequestModel = new UpdatePaymentDetailsRequestModel();
  public quotationCurrencyItem: QuotationCurrencyItem = new QuotationCurrencyItem();
  public requestQuotationModel: RequestQuotationModel = new RequestQuotationModel();
  public stepperDataModel: StepperDataModel = new StepperDataModel();
  public readOnlyAmount: boolean = false;
  public transactionDetails: any;
  public forexMarginString: String = '0.00';
  public showCheckedMark: boolean = false;

  public showUploadPaymentProof: boolean = false;
  public showUploadPaymentProofRightSection: boolean = false;
  public showTransactionPaymentForm: boolean = false;
  public transactionPaymentStatus: boolean = false;
  public resGetVcipStatus: any;
  public consumerId :any;
  public transactionDetailsMaxDate : any;
  public transactionID :any;
  public showCheckMarkForPaymentProof : boolean = false;
  public hideBeneficiaryDetails  : boolean = true;
  public showBeneDetails  : boolean = false;
  
  public  moment: any = moment;
  public transaction: any;
  public consumerDetail: any;
  public isVcipComplete: boolean = false;
  @ViewChild('vcipCompleteAfterOrder') vcipCompleteAfterOrder: TemplateRef<any>;
  vkycTimingText: string;
  active : any;
  
  constructor(
    public orderService: OrderService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    public toast: ToastrService,
    public updateProfileService : UpdateProfileService
  ) {
    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.orderID = this.route.snapshot.paramMap.get('orderID');
    this.getSingleOrderDetails();
    // this.getTransactionDetails();
    this.getProfileDetails();
    this.downloadA2Form();

  }

  ngOnInit(): void {
    this.vkycTimingText= Constants.vkycTimingText;
    this.transactionDetailsMaxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
   }

  goToOrderRatingPage() {
    this.router.navigate(['/ratings', this.orderID]);
  }

  getSingleOrderDetails() {
    this.showLoader = true;
    this.orderService.getOrderDetailsSingle(this.orderID).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
      if(this.orderDetails?.orderType?.includes('REMITTANCE')){
        this.hideBeneficiaryDetails = false;
      }

      if (this.orderDetails.orderFeedback.averageRating) {
        this.orderRating = this.orderDetails.orderFeedback.averageRating;
      } else {
        if (this.orderDetails.orderFeedback.onboarding > 0) {
          this.orderRating = (this.orderDetails.orderFeedback.onboarding +
            this.orderDetails.orderFeedback.transaction +
            this.orderDetails.orderFeedback.transparency +
            this.orderDetails.orderFeedback.support +
            this.orderDetails.orderFeedback.overall) / 5;
        } else{
          this.readOnlyRating = false;
        }
      }
      if(this.orderDetails.sellType=='BUY' ){
        this.isSurrenderOrder=true;
        this.hideBeneficiaryDetails=true;
        this.showUploadPaymentProof=false;
      }

      this.updatePaymentDetailsRequestModel.amount = this.orderDetails?.invoice?.totalBillAmountString;
      // if (this.updatePaymentDetailsRequestModel.amount != null || this.updatePaymentDetailsRequestModel.amount != "") {
      //   this.readOnlyAmount = true;
      // }
      if (this.orderDetails.paymentMode!='NB') {
        this.readOnlyAmount = false;        

     }
      if (this.orderDetails?.invoice?.marginCharges!=null) {
        this.forexMarginString = this.orderDetails?.invoice?.marginCharges.toFixed(2) + "";
      }
      if (this.orderDetails?.beneficiaryDetails) {
        this.showBeneDetails = true;
      }
      // if (this.orderRating == -1 || this.orderRating == 0) {
      //   this.readOnlyRating = false;
      // }
      console.log("ORDER DETAILS  >>>>>>>>>>", this.orderDetails);
      console.log("PAYMENT STATUS >>>>>>>>>>", this.orderDetails.orderStatusGroup.consumerPayment);
      if (this.orderDetails.orderStatusGroup.consumerPayment == 'COMPLETED') {
        this.showUploadPaymentProof = false;
        this.showTransactionPaymentForm = false;
      } else if ( this.orderDetails.orderStatusGroup.consumerPayment == 'PARTIAL' || (this.orderDetails.paymentMode != 'TPV' && this.orderDetails.orderStatusGroup.consumerPayment != 'COMPLETED')  ) {
        this.showUploadPaymentProof = true;
        // this.showTransactionPaymentForm = true;
      }
      this.getTransactionDetails();
    },
      error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
  }

  openModalForOrder(orderModal) {
    this.modalReference = this.modalService.open(orderModal, { centered: true });
  }

   gotoOrder() {
    this.modalReference.close();
    this.orderService.getOrderDetailsSingle(this.orderID).subscribe(resp => {
      this.showLoader = false;
      this.orderDetails = resp.body;
      console.log("I am here")
      console.log("orderdetails++", this.orderDetails);
    this.selectedPurposeItem = PurposeList.getPurposeObjectByPurposeCodeRemittance(this.orderDetails.orderItems[0].purpose);
        console.log("this.selectedPurposeItem >>", this.selectedPurposeItem);
        //   /// prepare quotation request
        
        let listQuotationCurrencies: Array<QuotationCurrencyItem> = [];
        this.quotationCurrencyItem.currencyCode = this.orderDetails.orderItems[0].currencyCode;
        this.quotationCurrencyItem.currencyAmount = this.orderDetails.orderItems[0].currencyAmount;
        this.quotationCurrencyItem.bidRate = this.orderDetails.orderItems[0].bidRate;
        this.quotationCurrencyItem.product = Constants.productsEnum[2];
        this.quotationCurrencyItem.purpose = (this.selectedPurposeItem?.purposeCode)
          ? this.selectedPurposeItem.purposeCode
          : "UNIVERSITY_FEES";

        listQuotationCurrencies.push(this.quotationCurrencyItem);

        this.requestQuotationModel.sellType = Constants.sellTypes[0];
        this.requestQuotationModel.partnerId = Constants.partnerId;
        this.requestQuotationModel.nostroChargeType = 'REMITTER';
        this.requestQuotationModel.quotationCurrencies = listQuotationCurrencies;

        /// store active order in session storage
        this.stepperDataModel.purposeItem = this.selectedPurposeItem;
        this.stepperDataModel.quotationRequest = this.requestQuotationModel;
        this.stepperDataModel.orderDetails = resp.body;
        console.log("stepper data +++", this.stepperDataModel.orderDetails);

        sessionStorage.setItem(ConstantsKey.SS_ACTIVE_ORDER, JSON.stringify(this.stepperDataModel));
        this.showLoader = false;

        // console.log("respBody.consumer.vcipKycStatus >>", respBody);

        if (!this.orderDetails.consumer.vcipKycStatus) {
          this.router.navigate(['/update-profile']);
        } else if (this.stepperDataModel.purposeItem.remittanceType == "OTHERS") {
          this.router.navigate(['/emailStepper3step']);
        } else {
          this.router.navigate(['/emailStepper'])
        }
      }, error => {
        this.showLoader = false;
        console.log(error);
      });  
   }

  modalOpenForDocumentView(documentOpenModal, documentDetails) {
    this.modalReference = this.modalService.open(documentOpenModal, { centered: true });
    this.documentUrl = documentDetails.location;
    this.documentTitle = documentDetails.documentType;
  }

  getDateFormat(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.paymentDate = year + "-" + month + "-" + day;
  }

  updatePaymentDetails(formData) {
    if (formData == true) {
      this.showLoader = true;
      this.updatePaymentDetailsRequestModel.orderId = this.orderID;
      this.updatePaymentDetailsRequestModel.paymentDate = this.paymentDate;
      this.updatePaymentDetailsRequestModel.consumerId = this.consumerId;
      this.manualPaymentType = this.updatePaymentDetailsRequestModel.manualPaymentType;
      console.log(JSON.stringify(this.updatePaymentDetailsRequestModel));
      this.orderService.updatePaymentDetails(this.updatePaymentDetailsRequestModel).subscribe((resp => {
        this.showLoader = false;
        this.transactionPaymentStatus = true;
        this.showTransactionPaymentForm = false;
        this.toast.success("Payment updated successfully");
        this.getSingleOrderDetails();
        this.getTransactionDetails();
      }), error => {
        this.showLoader = false;
        this.toast.error(error.error.message);
      });
    }
  }

  getTransactionDetails() {
    this.orderService.getTransactionDetailsList(this.orderID).subscribe((resp => {
      this.transactionDetails = JSON.parse(JSON.stringify(resp.body));
      console.log('transactionDetails >>>> ', this.transactionDetails);
      
      this.transaction = this.transactionDetails[0];
      // for (let i = 0; i < this.transactionDetails.length; i++) {
      //   this.transactionDetails[i].transactionDetails.paymentDate = moment(this.transactionDetails[i]?.transactionDetails?.paymentDate).format('LLL');
        
      // }
      this.transactionProof = this.transaction?.proof;
      console.log("transactionProof++", this.transactionProof);
      
      if(this.transactionDetails.length == 0){
        this.showTransactionPaymentForm = true;
      }else{
        this.showTransactionPaymentForm = false;
      }
      
      this.updatePaymentDetailsRequestModel.manualPaymentType = '';
      this.updatePaymentDetailsRequestModel.referenceNumber = '';
    }), error => {
    });
  }

  showProof() {
    window.open(this.transactionProof, "_blank");
    console.log("proof", this.transactionProof);
  }

  showToastMessage() {
    this.toast.error('Please update transaction details first');
  }

  retryPayment() {
    sessionStorage.setItem(ConstantsKey.IS_FROM_RETRY_PAYMENT, 'true');
    sessionStorage.setItem(ConstantsKey.IS_FROM_REMITTANCE_FLOW, 'false');
    sessionStorage.setItem(ConstantsKey.IS_FROM_BUYNEWCARD, 'false');
    sessionStorage.setItem(ConstantsKey.IS_FROM_RELOAD, 'false');
    this.router.navigate(['/retry-payment', this.orderID]);
  }

  showTransactionForm(){
    this.showTransactionPaymentForm = true;
  }
  downloadA2Form(){
   // this.showLoader=true;
    this.orderService.downloadA2Form(this.orderID).subscribe((resp => {
      //this.showLoader=false;

      var response = JSON.parse(JSON.stringify(resp.body));
      this.a2FormUrl=response.downLoadUrl;
      console.log('a2FormDetails >>>> ', response);
      console.log('a2FormUrl >>>> ', this.a2FormUrl);

    
    }), error => {
      //this.showLoader=false;

    });
  }

  fileUploadTransactionProof(event) {
    this.showLoader = true;
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      if((file.type == 'image/png') || (file.type == 'image/jpg') || (file.type == 'image/jpeg')) {
      if (file.size >= 0) {
        const formData = new FormData();
        formData.append('transactionId', this.transactionID);
        formData.append('file', file, file.name);

        // if (file.size > 1000000) {
        //   this.showLoader = false;
        //   this.toast.error('Please upload a file of up to 1MB only');
        // } else {
          this.orderService.uploadTransactionProof(formData).subscribe((respDoc) => {
            this.showLoader = false;
            this.showCheckMarkForPaymentProof = true;
            this.toast.success("Payment proof uploaded successfully");
            console.log(JSON.stringify(respDoc));
            this.getTransactionDetails();
          }, error => {
            this.showLoader = false;
            this.toast.error(error.error.message);
          });
        
      }
    } else {
      this.toast.error('Please upload image only!!!');
    }
      this.showLoader = false;
    }
  }

  getTransactionID(id){
    this.transactionID = id;
    console.log("this.transactionID >>", this.transactionID);
  }

  initiateVcip() {
   // this.modalReference.close();
    this.updateProfileService.getVCIPRedirectionURL().subscribe((resp => {
      this.showLoader = false;
      var vcipResponse = JSON.parse(JSON.stringify(resp.body));
      let vcipUrl = vcipResponse.mobileAutologinUrl;
      window.open(vcipUrl, '_blank');
    }), error => {
      this.showLoader = false;
      this.toast.error(JSON.stringify(error.error.message));
    });
  }

  
  getProfileDetails() {
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.isVcipComplete = this.consumerDetail?.vcipKyc;
       if (this.consumerDetail?.paxResponse?.consumer?.vcipSkipAllowed) {
        this.isVcipComplete = true;
      } else if (this.consumerDetail?.paxResponse?.consumer?.offlineonboarding == '1') {
        this.isVcipComplete = true;
      }
      if(!this.isVcipComplete){
        this.getVCIPStatus();
      } 
    });
  }
   async getVCIPStatus() {
    this.updateProfileService.getVCIPStaus().subscribe((resp => {
      this.showLoader = false;
       this.resGetVcipStatus = resp.body;
      if (this.resGetVcipStatus.vcipStatus == 'ACCEPTED') {
        this.isVcipComplete=true;
      } else {
        //this.modalReference = this.modalService.open(this.vcipCompleteAfterOrder, { size: 'xl', centered: true });
      }
    }), error => {
      this.toast.error(error.error.message);
    });
    return true;
 }
 


}
