import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/shared/constant/constants';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from '../../services/insurance.service';
import { InsuranceAddRequestModel } from '../../model/insurance.model';
import moment from 'moment';
import { DateUtils } from 'app/shared/utility/date-utils';
import { ConstantsKey } from 'app/shared/constant/constants-keys';
import { UpdateProfileService } from 'app/modules/update-profile/services/update-profile.service';
import { CryptoUtils } from 'app/shared/utility/crypto.utils';

@Component({
  selector: 'insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['../../../../../@core/scss/angular/libs/select.component.scss', './insurance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceComponent implements OnInit {
  public showLoader: boolean = false;
  public insuranceFor: string = 'Leisure';
  public relationDropdownItems = Constants.leisureNomnieeRelationship;
  public relationDropdownItemsAll = Constants.leisureRelationship;
  public departureMinDate: any;
  public arrivalDate: any;
  public dob: any;
  public departureDate: any;
  public insuranceAddRequestModel = new InsuranceAddRequestModel();
  public countryListItems: any;
  public consumerDetail: any;
  public paxId: any;
  public departureDateForFilter: any;
  public selectedDepartureDate: any;
  public enableArrivalDatePicker: any;
  public minDateForArrivalDate: any;
  public arrivalDateForFilter: any;
  public freezeNoofDaysField: any;
  public consumerId: any;
  public requestDOB: any;

  public cryptoUtils: CryptoUtils = new CryptoUtils();

  constructor(
    public insuranceService: InsuranceService,
    public router: Router,
    public toast: ToastrService,
    public masterDataService: MasterDataService,
    public updateProfileService: UpdateProfileService) {
  }

  ngOnInit() {
    this.getPaxId();
    this.getCountryList();

    this.consumerId = localStorage.getItem(ConstantsKey.CONSUMER_ID);
    this.departureMinDate = { 'year': new Date().getFullYear(), 'month': new Date().getMonth() + 1, 'day': new Date().getDate() };
  }

  getCountryList() {
    this.masterDataService.getCountryList().subscribe(resp => {
      this.countryListItems = resp.body;
    });
  }

  getPaxId() {
    this.showLoader = true;
    this.updateProfileService.getProfileListAll().subscribe(resp => {
      this.showLoader = false;
      this.consumerDetail = resp.body;
      this.paxId = this.consumerDetail.paxResponse.id;

      if (this.insuranceAddRequestModel.relationship == 'Self') {
        if (this.consumerDetail.paxResponse.relationship == 'SELF') {
          this.insuranceAddRequestModel.name = this.consumerDetail.paxResponse.name;
          this.insuranceAddRequestModel.email = this.consumerDetail.paxResponse.email;
          this.insuranceAddRequestModel.mobile = this.consumerDetail.paxResponse.mobile;
          this.insuranceAddRequestModel.address1 = this.consumerDetail.paxResponse.address;
          if (this.consumerDetail.paxResponse.dob) {
            let dob = moment(this.consumerDetail.paxResponse.dob).format("YYYY-MM-DD");
            let dateofBirth = DateUtils.ngbDateStructDateConverter(dob);
            this.dob = dateofBirth;
            this.requestDOB = DateUtils.getNgbDateStructToDate(this.dob);
          }
        }
      } else {
        this.insuranceAddRequestModel.name = null;
        this.insuranceAddRequestModel.email = null;
        this.insuranceAddRequestModel.mobile = null;
        this.insuranceAddRequestModel.address1 = null;
      }
    });
  }

  onDateSelect(event, sourceDate) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.requestDOB = year + "-" + month + "-" + day;
    if (sourceDate == 'departureDate') {
      this.departureDateForFilter = year + "-" + month + "-" + day;
      this.selectedDepartureDate = this.departureDateForFilter;
      this.enableArrivalDatePicker = true;
      let minDateForArrivalDate = this.selectedDepartureDate;
      this.minDateForArrivalDate = { year: parseInt(minDateForArrivalDate.split("-")[0]), month: parseInt(minDateForArrivalDate.split("-")[1]), day: parseInt(minDateForArrivalDate.split("-")[2]) };
    } else if (sourceDate == 'arrivalDate') {
      this.arrivalDateForFilter = year + "-" + month + "-" + day;
      this.freezeNoofDaysField = true;
      this.calculateDays();
    }
  }

  showErrorMessage() {
    this.toast.error('Please select departure date first');
  }

  calculateDays() {
    const departureDateModified = DateUtils.getNgbDateStructToDate(this.departureDate);
    const arrivalDateModified = DateUtils.getNgbDateStructToDate(this.arrivalDate);

    const time = new Date(arrivalDateModified).getTime() - new Date(departureDateModified).getTime();
    this.insuranceAddRequestModel.NoOfDaysTravel = time / (1000 * 3600 * 24);
  }

  proceedInsuranceForm(formData) {
    if (this.setInsuranceFormValidation()) {
      if (formData === true) {
        this.showLoader = true;
        this.insuranceAddRequestModel.consumerId = this.consumerId;
        this.insuranceAddRequestModel.paxId = this.paxId;
        this.insuranceAddRequestModel.DepartureDate = this.departureDateForFilter;
        this.insuranceAddRequestModel.ArrivalDate = this.arrivalDateForFilter;
        this.insuranceAddRequestModel.dob = this.requestDOB;
        this.insuranceAddRequestModel.travelTagNumber = null;
        this.insuranceAddRequestModel.ordercartdetailsId = null;
        this.insuranceAddRequestModel.sponsorRelationShip = 'Select';
        this.insuranceAddRequestModel.phoneNo = "";
        this.insuranceAddRequestModel.address2 = "";


        if (this.insuranceAddRequestModel.sponsorName) {
          sessionStorage.setItem(ConstantsKey.INSURANCE_TYPE, 'Student');
        } else {
          sessionStorage.setItem(ConstantsKey.INSURANCE_TYPE, 'Leisure');
        }

        this.insuranceService.submitInsuranceForm(this.insuranceAddRequestModel).subscribe((resp => {
          this.showLoader = false;
          var responseJson = resp.body;
          sessionStorage.setItem(ConstantsKey.INSURANCE_RESPONSE, JSON.stringify(responseJson));
          sessionStorage.setItem(ConstantsKey.INSURANCE_REQUEST, JSON.stringify(this.insuranceAddRequestModel));
          this.router.navigate(['/insurance-plans']);
        }), error => {
          this.showLoader = false;
          if (error.hasOwnProperty("reason")) {
            this.toast.error(error.error.message);
          } else {
            var responseJson = JSON.parse(this.cryptoUtils.resDecrypt(error.error.text));
            sessionStorage.setItem(ConstantsKey.INSURANCE_RESPONSE, JSON.stringify(responseJson));
            sessionStorage.setItem(ConstantsKey.INSURANCE_REQUEST, JSON.stringify(this.insuranceAddRequestModel));
            this.router.navigate(['/insurance-plans']);
          }
        });
      } else {
        this.toast.error("Please fill all the mandatory fields.");
      }
    }
  }

  showLeisureForm() {
    this.insuranceFor = 'Leisure';
  }

  showStudentForm() {
    this.insuranceFor = 'Student';
  }

  setDOBToInput(dateOfBirth): NgbDateStruct {
    let date = { year: parseInt(dateOfBirth.split("-")[0]), month: parseInt(dateOfBirth.split("-")[1]), day: parseInt(dateOfBirth.split("-")[2]) }
    return date;
  }

  letterOnly(event) {
    var charCode = event.keyCode;
    if (((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) || charCode == 32)
      return true;
    else return false;
  }

  

  alphaNumberOnly(e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validatePassportNumber(passportNo) {
    const regex = /^[A-PR-WY-Z][1-9]\d\s?\d{4}[0-9]$/;
    // if (!regex.test(passportNo)) {
    //   return false;
    // } else {
    //   return true;
    // }

    if (regex.test(passportNo) == true) {
      return true;
    } else {
      return false;
    }
  }

  restrictSepcialCharacters(event) {
    var charCode = event.keyCode;
    if ((charCode > 96 && charCode < 123) || (charCode > 64 && charCode < 91) || (charCode >= 48 && charCode <= 57) || charCode <= 31)
      return true;
    else return false;
  }

  validateEmailId(emailId) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(emailId)) {
      return false;
    } else {
      return true;
    }
  }

  setInsuranceFormValidation() {
    if (!this.validateEmailId(this.insuranceAddRequestModel.email)) {
      this.toast.error("Email id is not valid!");
      return false;
    }

    if (!this.validatePassportNumber(this.insuranceAddRequestModel.passportNumber)) {
      this.toast.error("Please enter valid passport no!");
      return false;
    }
    return true;
  }
}
