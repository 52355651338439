<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card card-body">
            <div class="row">
                <div class="col-md-1">
                    <div class="mb-20 " routerLink="/list-beneficiary" style="cursor: pointer;">
                        <i data-feather="chevron-left" class="fs-20"></i> Back
                    </div>
                </div>
                <!-- <div class="col-md-11">
                    <div class="mb-20 fs-15"> {{singleBeneficiaryDetails?.name}} </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row" *ngIf="countryName != null && countryName != '' ">
                        <div class="col-md-7 fw-500 text-black">Country Name</div>
                        <div class="col-md-5 text-black40">
                            {{countryName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.city != null && singleBeneficiaryDetails?.city != '' ">
                        <div class="col-md-7 fw-500 text-black">City</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.city}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.bankAddress != null && singleBeneficiaryDetails?.bankAddress != '' ">
                        <div class="col-md-7 fw-500 text-black">Account Holder Address</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.bankAddress}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.bankName != null && singleBeneficiaryDetails?.bankName != '' ">
                        <div class="col-md-7 fw-500 text-black">Bank Name</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.bankName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.bankAccountNumber != null && singleBeneficiaryDetails?.bankAccountNumber != '' ">
                        <div class="col-md-7 fw-500 text-black">Account number/IBAN</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.bankAccountNumber}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.studentId != null && singleBeneficiaryDetails?.studentId != '' ">
                        <div class="col-md-7 fw-500 text-black">Student ID</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.studentId}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.sortCode != null && singleBeneficiaryDetails?.sortCode != '' ">
                        <div class="col-md-7 fw-500 text-black">SORT/ABA/BSB/TRANS No</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.sortCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.intermediaryBankSwiftCode != null && singleBeneficiaryDetails?.intermediaryBankSwiftCode != '' ">
                        <div class="col-md-7 fw-500 text-black">Intermediary Bank Swift Code</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.intermediaryBankSwiftCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.remittanceForRelationship != null && singleBeneficiaryDetails?.remittanceForRelationship != '' ">
                        <div class="col-md-7 fw-500 text-black">Relationship with Beneficiary</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.remittanceForRelationship}}
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.name != null && singleBeneficiaryDetails?.name != '' ">
                        <div class="col-md-7 fw-500 text-black">Beneficiary Name</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.name}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.viaAgentName != null && singleBeneficiaryDetails?.viaAgentName != '' ">
                        <div class="col-md-7 fw-500 text-black">University Name</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.viaAgentName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.currencyCode != null && singleBeneficiaryDetails?.currencyCode != '' ">
                        <div class="col-md-7 fw-500 text-black">Currency</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.currencyCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.address != null && singleBeneficiaryDetails?.address != '' ">
                        <div class="col-md-7 fw-500 text-black">Bank Address</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.address}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.swiftCode != null && singleBeneficiaryDetails?.swiftCode != '' ">
                        <div class="col-md-7 fw-500 text-black">Swift Code</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.swiftCode}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.flyWireReferenceNumber != null && singleBeneficiaryDetails?.flyWireReferenceNumber != '' ">
                        <div class="col-md-7 fw-500 text-black">GIC/FLYWIRE/WU Reference number</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.flyWireReferenceNumber}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.intermediaryBankName != null && singleBeneficiaryDetails?.intermediaryBankName != '' ">
                        <div class="col-md-7 fw-500 text-black">Intermediary Bank Name</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.intermediaryBankName}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.additionalDetails != null && singleBeneficiaryDetails?.additionalDetails != '' ">
                        <div class="col-md-7 fw-500 text-black">Special Instructions</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.additionalDetails}}
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="singleBeneficiaryDetails?.mobileNo != null && singleBeneficiaryDetails?.mobileNo != '' ">
                        <div class="col-md-7 fw-500 text-black">Beneficiary Mobile No</div>
                        <div class="col-md-5 text-black40">
                            {{singleBeneficiaryDetails?.mobileNo}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>