import { Component, OnInit, ViewEncapsulation } from '@angular/core' 
import { Router } from '@angular/router';
import { Constants } from 'app/shared/constant/constants';
import { PurposeList } from 'app/shared/constant/purpose-list';
import { ReqGetCurrencyMaster } from 'app/shared/models/shared-models';
import { MasterDataService } from 'app/shared/services/master-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'open-gic-account',
  templateUrl: './open-gic-account.component.html',
  styleUrls: ['./open-gic-account.component.scss'],
  providers:[],
  encapsulation: ViewEncapsulation.None
})
export class OpenGICAccountComponent implements OnInit {

  constructor(
     public router : Router,
     public toast : ToastrService
  ) {}

  ngOnInit() { 
  }
 
  openAccountForm(title){
    this.router.navigate(['/open-account-form',title]);
  }

}
