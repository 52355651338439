export class RequestEmailLinkDataModel {
    mobile: string;
    email: string;
    quotationId: string
}

export class OrderConsumerA2AcceptanceRequest {
    orderId : string;
    otp: string;
    signatureUrl: string;
    initiateAadharSigning: true;
} 