import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BuyForexLandingComponent } from './views/buy-forex-landing.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderRoutingModule } from 'app/shared/utility/utility-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NouisliderModule } from 'ng2-nouislider';
import { CardService } from '../card/services/card.service';
import { TripInformationComponent } from '../card/view/buy-card/1-trip-information/trip-information.component';
import { TravellerDetailsComponent } from '../card/view/buy-card/2-traveller-details/traveller-details.component';
import { KYCComponent } from '../card/view/buy-card/3-document/document.component';
import { A2FormComponent } from '../card/view/buy-card/4-a2-form/a2-form.component';
import { PaymentComponent } from '../card/view/buy-card/5-payment/payment.component';
import { AddAddressCardComponent } from '../card/view/buy-card/add-address-card/add-address-card.component';
import { AddBankAccountForCardComponent } from '../card/view/buy-card/add-bank-account-card/add-bank-account-card.component';
import { BuyNewCardComponent } from '../card/view/buy-card/buy-new-card/buy-new-card.component';
import { CardStepperFooterComponent } from '../card/view/buy-card/card-stepper-footer/card-stepper-footer.component';
import { CardStepperComponent } from '../card/view/buy-card/card-stepper/card-stepper.component';
import { ViewCartComponent } from '../card/view/buy-card/view-cart/view-cart.component';
import { MyCardComponent } from '../card/view/card-dashboard/my-card.component';
import { CashoutComponent } from '../card/view/cashout/cashout.component';
import { EncashmentComponent } from '../card/view/encashment/encashment.component';
import { LinkExistingCardComponent } from '../card/view/link-existing-card/link-existing-card.component';
import { SetDailyLimitComponent } from '../card/view/manage-card/set-daily-limit/set-daily-limit.component';
import { OrderSuccessComponent } from '../card/view/order-success/order-success.component';
import { PassportDataFormComponent } from '../card/view/passport-data-form/passport-data-form.component';
import { RaiseDisputeTicketComponent } from '../card/view/raise-dispute-ticket/raise-dispute-ticket.component';
import { StatementComponent } from '../card/view/statement/statement.component';
import { TransferBalanceComponent } from '../card/view/transfer-balance/transfer-balance.component';
import { ValidateCardDetailsComponent } from '../card/view/validate-card-details/validate-card-details';
import { UpdateProfileService } from '../update-profile/services/update-profile.service';
 

const routes = [
  {
    path: 'buy-forex-landing',
    component: BuyForexLandingComponent,
    data: { animation: 'BuyForexLandingComponent' },
    canActivate: [AuthGuard]
  },
 
];

@NgModule({
  declarations: [
    BuyForexLandingComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbModule,
    LoaderRoutingModule,
    CommonModule,
    CardSnippetModule,
    NouisliderModule
  ],
  exports: [
    BuyForexLandingComponent,
  ],
  providers: [CardService, UpdateProfileService, DatePipe]
})
export class BuyForexLandingRoutingModule { }
