<loader *ngIf="showLoader"></loader>

<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Login v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="58" />
                </a>

                <h4 class="text-center fs-14">Create Account</h4>
                <form class="auth-login-form mt-2" (ngSubmit)="onSubmit(SignUpForm.form.valid)" #SignUpForm="ngForm">
                    <div class="form-group">
                        <label class="form-label" for="full-name">Name as per PAN</label>
                        <input type="text" name="id" class="form-control fs-12"  minlength="5" maxlength="100" [(ngModel)]="requestConsumerOtpSignUp.panName" placeholder="Name as per PAN" name="nameAsperPAN" #nameAsperPANRef="ngModel" [class.error]="SignUpForm.submitted && nameAsperPANRef.invalid"
                        (keypress)="letterOnly($event)" (paste)="onPaste($event)" style="border-color: black;" required />
                        <span *ngIf="SignUpForm.submitted && nameAsperPANRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="nameAsperPANRef.errors.required">This field is
                                required!</small>
                        </span>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="login-email">Email Id</label>
                        <input type="email" name="id" class="form-control fs-12" [(ngModel)]="requestConsumerOtpSignUp.email" placeholder="Enter Email Id" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="emailID" #emailIDRef="ngModel" [class.error]="SignUpForm.submitted && emailIDRef.invalid"
                          style="border-color: black;" required />
                        <!-- <span *ngIf="SignUpForm.submitted && emailIDRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="emailIDRef.errors.required">This field is required!</small>
                        </span> -->
                        <small class="form-text text-danger" *ngIf="emailIDRef.invalid && SignUpForm.submitted">Must be
                            a valid email!</small>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="login-email">Mobile</label>
                        <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type = "number" maxlength="10"
                         name="id" class="form-control fs-12" [(ngModel)]="requestConsumerOtpSignUp.mobile" placeholder="Enter Mobile"
                        style="border-color: black;" name="mobile" #mobileRef="ngModel" [class.error]="SignUpForm.submitted && mobileRef.invalid" required />
                        <span *ngIf="SignUpForm.submitted && mobileRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="mobileRef.errors.required">This field is
                                required!</small>
                        </span>
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">OTP</label>
                            <a (click)="getOTPForSignUp()"><small>Send OTP</small></a>
                        </div>

                        <div class="input-group input-group-merge form-password-toggle">
                            <input type='number' name="otp" placeholder="Enter OTP" class="form-control fs-12 form-control-merge" [(ngModel)]="requestConsumerOtpSignUp.otp" aria-describedby="login-password" tabindex="2" #otpRef="ngModel" [class.error]="SignUpForm.submitted && otpRef.invalid"
                            style="border-color: black;"  required onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187  && event.keyCode !== 190" />
                        </div>
                        <span *ngIf="SignUpForm.submitted && otpRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="otpRef.errors.required">This field is
                                required!</small>
                        </span>
                    </div>
                    <button class="btn btn-primary btn-block fs-12" tabindex="4" rippleEffect>Sign Up</button>
                </form>
                <p class="text-center mt-2 fs-12">
                    <span>Already have an account?</span>
                    <a routerLink="/login">
                        <span>&nbsp;Login here!</span>
                    </a>
                </p>

            </div>
        </div>
    </div>
</div>