<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <loader *ngIf="showLoader"></loader>

                    <div class="col-md-12 mb-20">
                        <h6 class="content-header-title float-left mb-0">Address</h6>
                    </div>

                    <div class="col-md-12 text-center">
                        <div *ngIf="noDataFound">No address found</div>
                    </div>

                    <div class="col-md-12" *ngFor="let singleAddress of addressList; let i=index">
                        <div style="box-shadow: 0 0 0 0 !important" [ngClass]="(addressList.length-1) == i ? 'border-bottom-white' : 'border-bottom-grey'">
                            <div class="row mt-20">
                                <div class="col-md-8 col-8 mb-10">
                                    <span class="card-title fs-13 fw-600 text-black">{{singleAddress.locationType}}</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <span class="fs-11 text-black"> {{singleAddress.address}}</span>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-8 col-12">
                                    <span class="fs-11 text-black"> {{singleAddress.city}} - {{singleAddress.postalCode}}</span>
                                </div>
                            </div>
                            <div class="row mb-20">
                                <div class="col-md-2 col-5">
                                    <button type="button" class="btn btn-outline-dark btn-prev fs-11" rippleEffect (click)="editAddress(singleAddress)" style="width:100%">
                                        <i class="icomoon icon-edit_mode_icon"></i>
                                        Edit
                                    </button>
                                </div>
                                <div class="col-md-2 col-5 pl-0">
                                    <button type="button" class="btn btn-outline-danger btn-prev fs-11" rippleEffect (click)="deleteAddress(singleAddress.id)" style="width:100%">
                                        <i class="icomoon icon-bin_delete_dust_erace_garbage_icon "></i>
                                        Delete 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-20">
                        <button type="button" class="btn btn-primary btn-prev fs-11" routerLink="/add-address" rippleEffect>
                                <span class="align-middle">Add Address</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>