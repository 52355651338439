<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

</div>

<div class="navbar-container ">

    <div class="d-flex justify-content-between">
        <span class="brand-logo">
            <img src="assets/images/logo/logo-new.png" style="height: 32px;" (click)="navigateToDashboard()"/>
        </span>

        <div class="bookmark-wrapper d-flex align-items-center">
            <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
                <li class="nav-item">
                    <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                        <span [data-feather]="'menu'" [class]="'ficon'"></span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- <span class="brand-logo">
            <img src="assets/images/logo/logo.png" style="height: 30px;"/>
        </span> -->
    </div>

</div>