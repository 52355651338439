<loader *ngIf="showLoader"></loader>

<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Login v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="assets/images/logo/logo.png" alt="brand-logo" height="100" width="100" />
                </a>
                <ngb-alert [type]="'danger'" *ngIf="error != '' " class="mt-1" [dismissible]="false">
                    <div class="alert-body">
                        <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                        <span class="fs-12">{{error}}</span>
                    </div>
                </ngb-alert>

                <p class="otp-send-message">
                    Hi, We have send otp on <b> {{ mobile }} </b> and <b>{{ email }}</b> please enter and proceed!
                </p>
                <form class="auth-login-form mt-2">
                    <div class="form-group">
                        <div class="input-group input-group-merge form-password-toggle">
                            <input type="number" maxlength="6" name="otp"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                placeholder="Enter OTP" class="form-control fs-12 form-control-merge" [(ngModel)]="requestLoginModel.otp" aria-describedby="login-password" style="border-color: black;"
                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 101 && event.keyCode !== 187 && event.keyCode !== 190" />
                        </div>
                        <br>
                    </div>
                    <button class="btn btn-primary btn-block" style="color: #1D2A62;" (click)="onProceedToLogin()" rippleEffect>Proceed</button>
                    <br>
                </form>
            </div>
        </div>
    </div>
</div>