export class RequestDataModel {
    mobileNo: string;
    password: string;
    agentCode: string;
    agentKey: string;
    fullname: string;
    email: string;
    arg01: string;
    arg02: string;
    vcipStatus: boolean
}

export class RequestDirectLogin {
    mobile: string;
    email: string;
    name: string;
    agentId: string
}

// var testData =
// {
//     "mobileNo": "9420796077",
//     "password": "986532",
//     "agentCode": "wsfx0058",
//     "agentKey": "adjfak4635",
//     "email": "pgfepale@gmail.com",
//     "fullname": "Prashant Fepale",
//     "arg01": "test value",
//     "arg02": "test 2 value",
//     "vcipStatus": true
//   }